import { httpClient } from 'services/api/httpClient'
import { GraphicConsumptionGeneralType } from 'types/invoice/dashboard/energy/graphic-consumption-general'
import { PaginationType } from 'types/pagination'

export const dashboardGenerate = async (
  year: string,
): Promise<PaginationType<GraphicConsumptionGeneralType>> => {
  // if yer null or undefined, set year to current year
  if (!year) {
    const date = new Date()
    year = date.getFullYear().toString()
  }
  const response = await httpClient.get(
    `/energy-invoice/dashboard/consumption-general/by-year/${year}`,
    {
      params: {
        page: 0,
        numberPerPage: 1000,
      },
    },
  )

  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  return {
    results: items,
    limit: perPage,
    count: total,
  }
}
