import { Chip } from '@mui/material'
import moment from 'moment'

import { SensorHistoricType } from 'types/sensor/sensor-historic'

const chips = {
  LIGADO: (
    <Chip label='Ligado' color='success' variant='outlined' size='small' />
  ),
  DESLIGADO: (
    <Chip label='Desligado' color='error' variant='outlined' size='small' />
  ),
}

export function sensorHistoricMapper(sensors: SensorHistoricType[]) {
  return sensors.map((item) => ({
    ...item,
    equipment: item.equipment.name,
    muiStatus: chips[item.status ? 'LIGADO' : 'DESLIGADO'],
    formattedDate: moment(item.date).format('DD/MM/YYYY'),
    formattedHour: moment(item.date).format('HH:mm'),
  }))
}
