import styled from '@emotion/styled'

export const ControlWrapper = styled.div<{
  temperature: number
  colors: string[]
  airConditioningIsOn?: boolean
}>(
  {
    width: '12.5rem',
    height: '12.5rem',
    borderRadius: '50%',
    padding: '1.25rem',
  },
  ({ colors }) => ({
    background: `linear-gradient(to bottom, ${colors[0]}, ${colors[1]})`,
  }),
)

export const ControlContent = styled.div({
  backgroundColor: '#0C0C25',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
})

export const FanProgress = styled.div({
  display: 'flex',
  gap: '0.75rem',
  alignItems: 'center',
  flexDirection: 'column',
  '& > *': {
    animation: 'changeColor 3s linear infinite',
  },
  '& > *:nth-of-type(1)': {
    animationDelay: '0s',
  },
  '& > *:nth-of-type(2)': {
    animationDelay: '1s',
  },
  '& > *:nth-of-type(3)': {
    animationDelay: '2s',
  },
  '@keyframes changeColor': {
    '0%': {
      backgroundColor: '#24243b',
    },
    '50%': {
      backgroundColor: '#fff',
    },
    '60%': {
      backgroundColor: '#24243b',
    },
    '100%': {
      backgroundColor: '#24243b',
    },
  },
})

export const FanProgressItem = styled.div({
  height: 7,
  width: 32,
  borderRadius: 4,
  backgroundColor: '#24243b',
})
