import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Stack } from '@mui/material'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Autocomplete } from 'components/Select/Autocomplete'
import { weekDays } from 'constants/weekDays'
import { uuid } from 'helpers/uuid'
import { schemaIntervals } from 'pages/map/validations/interval.validation'
import { ActionMeta, OnChangeValue } from 'react-select'
import { ModalType } from 'types/modal'
import { CreateIntervalType } from 'types/shift/create-interval'

interface WeekDay {
  value: string
  label: string
  isFixed?: boolean
}

interface Props extends ModalType {
  data: CreateIntervalType | null
  fixedDay: string
  onSubmitSuccess: (formData: CreateIntervalType) => void
}

export function FormRegisterIntervalModal({
  data,
  fixedDay,
  onSubmitSuccess,
  onClose,
  open,
}: Props) {
  const { t } = useTranslation('common')
  const [valueWeekDays, setValueWeekDays] = useState<WeekDay[]>(() => {
    const weekDay = weekDays.find((day) => day === fixedDay)

    if (weekDay) {
      return [
        {
          value: weekDay,
          label: t(weekDay),
          isFixed: true,
        },
      ]
    }

    return []
  })

  const weekdays = weekDays.map((day) => ({
    value: day,
    label: t(day),
  }))

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<CreateIntervalType>({
    resolver: yupResolver(schemaIntervals()),
    defaultValues: {
      repeat: fixedDay ? [{ value: fixedDay, label: t(fixedDay) }] : [],
      intervals: data?.intervals ?? [
        {
          name: '',
          hourInitial: '00:00',
          hourFinal: '00:00',
          _id: uuid(),
        },
      ],
    },
  })

  const onSubmit: SubmitHandler<CreateIntervalType> = (formData) => {
    onSubmitSuccess(formData)
  }

  const handleAddInterval = () => {
    const intervals = getValues('intervals')

    if (!intervals) {
      setValue('intervals', [
        {
          name: '',
          hourInitial: '00:00',
          hourFinal: '00:00',
          _id: uuid(),
        },
      ])
      return
    }

    setValue('intervals', [
      ...intervals,
      {
        name: '',
        hourInitial: '00:00',
        hourFinal: '00:00',
        _id: uuid(),
      },
    ])
  }

  const handleRemove = (id?: string) => {
    const intervals = watch('intervals')
    const newIntervals = intervals?.filter((item) => item?._id !== id) ?? [
      {
        name: '',
        hourInitial: '00:00',
        hourFinal: '00:00',
        _id: uuid(),
      },
    ]

    setValue('intervals', newIntervals)
  }

  const orderOptions = (values: readonly WeekDay[]) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed))
  }

  const onChange = (
    newValue: OnChangeValue<WeekDay, true>,
    actionMeta: ActionMeta<WeekDay>,
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        newValue = valueWeekDays.filter((v) => v.isFixed)
        break
    }

    setValueWeekDays(orderOptions(newValue))
    setValue('repeat', orderOptions(newValue))
  }

  return (
    <>
      <Modal
        maxWidth='sm'
        title={t('shift.registerInterval') ?? ''}
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiPaper-root, & .MuiDialogContent-root': {
            overflowY: 'unset !important',
          },
        }}
      >
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          {watch('intervals')?.map((day, index) => (
            <Grid item xs={12} key={day._id}>
              <Stack
                direction={'row'}
                spacing={2}
                marginTop={3}
                alignItems='center'
              >
                <Stack direction={'row'} flex={1} spacing={2}>
                  <Input
                    name={`intervals.[${index}].name`}
                    label={t('input.intervalName')}
                    control={control}
                    fullWidth
                    error={!!errors?.intervals?.[index]?.name}
                    errorMessage={errors?.intervals?.[index]?.name?.message}
                  />
                  <Input
                    name={`intervals.[${index}].hourInitial`}
                    label={t('input.startTime')}
                    type='time'
                    control={control}
                    fullWidth
                    error={!!errors?.intervals?.[index]?.hourInitial}
                    errorMessage={
                      errors?.intervals?.[index]?.hourInitial?.message
                    }
                  />
                  <Input
                    name={`intervals.[${index}].hourFinal`}
                    label={t('input.endTime')}
                    type='time'
                    control={control}
                    fullWidth
                    error={!!errors?.intervals?.[index]?.hourFinal}
                    errorMessage={
                      errors?.intervals?.[index]?.hourFinal?.message
                    }
                  />
                </Stack>

                <IconButton
                  iconName='delete'
                  variant='contained'
                  color='secondary'
                  title={t('button.title.delete') ?? ''}
                  disabled={Number(watch('intervals')?.length) <= 1}
                  onClick={() => handleRemove(day._id)}
                />
              </Stack>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              variant='contained'
              startIcon={<Icon name='add' />}
              onClick={handleAddInterval}
            >
              {t('button.title.addInterval')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Autocomplete
                name='repeat'
                label='Repetir'
                options={weekdays}
                isMulti
                value={valueWeekDays}
                isClearable={valueWeekDays.some((v) => !v.isFixed)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(newValue: any, actionMeta: any) =>
                  onChange(newValue, actionMeta)
                }
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <ModalAction onCancel={onClose} isSubmit />
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}
