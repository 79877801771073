import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { TableActionType } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { userService } from 'services/user.service'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'
import { UsersType } from 'types/user/user'

import { usersMapper } from '../mappers/users.mapper'

export function useUsers() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [selectedUser, setSelectedUser] = useState<UsersType | null>(null)

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<UsersType>,
    Error
  >({
    queryKey: ['users', page, search, orderBy],
    queryFn: () => userService.findAll({ page, search, columnSort: orderBy }),
  })

  const users = usersMapper(data?.items ?? [])

  const handleCreate = () => setOpenForm(true)

  const handleCancel = () => {
    setOpenForm(false)
    setOpenFormView(false)
    setSelectedUser(null)
  }

  const handleEdit = (item: UsersType) => {
    setSelectedUser(item)
    handleCreate()
  }

  const handleView = (item: UsersType) => {
    setSelectedUser(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: UsersType) => {
    httpDelete(`/user-account/${item.id}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'id',
      label: 'ID/Login',
    },
    {
      id: 'profileName',
      label: t('table.user.profile'),
    },
    {
      id: 'role',
      label: t('table.user.role'),
    },
    {
      id: 'name',
      label: t('table.user.name'),
    },
    {
      id: 'email',
      label: t('table.user.email'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
  ]

  const tableActions: TableActionType<UsersType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.ACC_USER,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.ACC_USER,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.ACC_USER,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return {
    t,
    sweetAlert,
    openForm,
    openFormView,
    setSearch,
    columns,
    page,
    setPage,
    orderBy,
    setOrderBy,
    data,
    users,
    error,
    isLoading,
    isFetching,
    handleCancel,
    handleCreate,
    handleDelete,
    handleEdit,
    handleView,
    selectedUser,
    tableActions,
  }
}
