import { ElectricalQuantitiesEnum, getKeyByValue } from 'core/enums/ElectricalQuantitiesEnum'

interface Props {
  type?: string
}

export function TypeElectricName({ type }: Props) {
  function names(type?: string) {
    switch (type) {      
      case getKeyByValue(ElectricalQuantitiesEnum, ElectricalQuantitiesEnum.A_SYS):
        return ElectricalQuantitiesEnum.A_SYS.concat(' :')
      case getKeyByValue(ElectricalQuantitiesEnum, ElectricalQuantitiesEnum.V_L_L_SYS):
        return ElectricalQuantitiesEnum.V_L_L_SYS.concat(' :')
      case getKeyByValue(ElectricalQuantitiesEnum, ElectricalQuantitiesEnum.PF_SYS):
        return ElectricalQuantitiesEnum.PF_SYS.concat(' :')
      default:
        type ?? ''
    }
  }

  return <>{names(type)}</>
}
