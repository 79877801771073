import { IconButton, IconButtonProps } from 'components/Button/IconButton'

interface MyAccountModalButtonProps extends Omit<IconButtonProps, 'title'> {
  title: string | null
}

export function MyAccountModalButton({
  title,
  ...rest
}: MyAccountModalButtonProps) {
  return (
    <IconButton
      variant='outlined'
      iconName='personOutline'
      title={title ?? ''}
      aria-haspopup='true'
      {...rest}
    />
  )
}
