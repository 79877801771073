export function getTemperatureList() {
  return [
    { name: '16', id: 16 },
    { name: '17', id: 17 },
    { name: '18', id: 18 },
    { name: '19', id: 19 },
    { name: '20', id: 20 },
    { name: '21', id: 21 },
    { name: '22', id: 22 },
    { name: '23', id: 23 },
    { name: '24', id: 24 },
    { name: '25', id: 25 },
    { name: '26', id: 26 },
    { name: '27', id: 27 },
    { name: '28', id: 28 },
    { name: '29', id: 29 },
    { name: '30', id: 30 },
  ]
}
