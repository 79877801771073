import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'

type ParamsFormatNumber = {
  context: any
  suffix?: DashboardTooltipSuffixEnum | string
  prefix?: DashboardTooltipSuffixEnum | string
}

export function formatNumbersDashboard(params: ParamsFormatNumber) {
  const { context, suffix = '', prefix = '' } = params
  const valueParsed = Number(context.parsed.y)
  return `${prefix} ${valueParsed.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} ${suffix}`.trim()
}
