export function DashboardWaterTankLevelRealTimeContent() {
  return (
    <>
      <p>
        Esse gráfico informa o nível da caixa d´água em tempo real, diferente do
        de vasão que mostra a vasão para cada setor ou de nível da caixa d’água,
        esse gráfico mostra apenas o nível atual sem filtros para verificar em
        datas anteriores.
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        nível de caixa d’água, caso esse gráfico não esteja disponibilizando o
        valor, será necessário realizar o cadastro dos sensores em Configurações{' '}
        {'->'} Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
