/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Stack, TableCell, TableRow, useTheme } from '@mui/material'
import { LatLngExpression } from 'leaflet'
import debounce from 'lodash.debounce'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { IconButton } from 'components/Button/IconButton'
import { InputSearch } from 'components/Input/InputSearch'
import { Map } from 'components/MapContainer'
import { Table, TableActionType } from 'components/Table'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { shiftMapper } from 'pages/map/mappers/shift.mapper'
import { shiftService } from 'services/shift.service'
import { PaginationType } from 'types/pagination'
import { ShiftType } from 'types/shift/shift'
import { ColumnType } from 'types/table/column'

export function MapScreen() {
  const { t } = useTranslation('common')

  const [openForm, setOpenForm] = useState(false)

  const [position, setPosition] = useState<LatLngExpression>([
    -23.5489, -46.6388,
  ])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    // TODO: Implement search
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '600px',
            position: 'absolute',
            top: 40,
            left: 20,
            zIndex: 1200,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            width='100%'
            maxWidth={400}
            ml={2}
            sx={{
              borderRadius: 2,
              p: 1,
              background:
                'linear-gradient(137deg, #00000009 0%, #00000025 100%)',
              backdropFilter: 'blur(10.499999046325684px)',
            }}
          >
            <InputSearch
              name='search'
              type='search'
              placeholder={t('input.search') ?? ''}
              sx={{ '& fieldset': { borderColor: 'transparent !important' } }}
              onChange={debouncedResults}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Map.Root
          center={position}
          zoom={6}
          scrollWheelZoom={true}
          style={{ height: 'calc(100vh - 104px)' }}
          attributionControl={false}
          zoomControl={false}
          boxZoom={true}
        >
          <Map.Layer />
          <Map.ZoomControl position='bottomright' />
          <Map.Pin
            variant='primary'
            position={position}
            isEditMode={false}
            onChangeLocation={(event) => {
              setPosition([event.lat, event.lng])
            }}
          >
            <Map.Tooltip>
              <p>Popup 01</p>
            </Map.Tooltip>
          </Map.Pin>
          <Map.Pin
            variant='primary'
            position={[10.0074, 90.761]}
            isEditMode={false}
            onChangeLocation={(event) => {
              setPosition([event.lat, event.lng])
            }}
          >
            <Map.Tooltip>
              <p>Popup 02</p>
            </Map.Tooltip>
          </Map.Pin>
          <Map.Pin
            variant='primary'
            position={[18.5487, 160.0608]}
            isEditMode={false}
            onChangeLocation={(event) => {
              setPosition([event.lat, event.lng])
            }}
          >
            <Map.Tooltip>
              <p>Popup 03</p>
            </Map.Tooltip>
          </Map.Pin>
          <Map.FlyToCoords
            coords={{
              latitude: Number(position.toString().split(',')[0]),
              longitude: Number(position.toString().split(',')[1]),
            }}
            zoom={13}
          />
        </Map.Root>
      </Box>

      {openForm && (
        <>
          <p>Monitoring</p>
        </>
      )}
    </>
  )
}
