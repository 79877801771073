import { UseFormReturn } from 'react-hook-form'

import { StepsEquipmentConfigurationCommand } from 'pages/setting/components/Steps/StepsEquipmentConfigurationCommand'
import { StepsEquipmentConfigurationInitial } from 'pages/setting/components/Steps/StepsEquipmentConfigurationInitial'
import { CreateControllerType } from 'types/equipment/create-equipment'

type Props = {
  activeStep: number
  formConfiguration: UseFormReturn<CreateControllerType>
  formCommand: UseFormReturn<CreateControllerType>
  isView?: boolean
  isLoading?: boolean
}

export function FormControllerScreen({
  activeStep,
  formCommand,
  formConfiguration,
  isView,
  isLoading,
}: Props) {
  return activeStep === 0 ? (
    <StepsEquipmentConfigurationInitial
      control={formConfiguration.control}
      errors={formConfiguration.formState.errors}
      setValue={formConfiguration.setValue}
      getValues={formConfiguration.getValues}
      watch={formConfiguration.watch}
      isView={isView}
      isLoading={isLoading}
    />
  ) : (
    <StepsEquipmentConfigurationCommand
      control={formCommand.control}
      errors={formCommand.formState.errors}
      setValue={formCommand.setValue}
      getValues={formCommand.getValues}
      watch={formCommand.watch}
      clearErrors={formCommand.clearErrors}
      isView={isView}
    />
  )
}
