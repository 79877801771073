import { ChartArea } from 'chart.js'

import { addAlpha } from './addAlpha'

export function createGradient(
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  color: { start: string; end: string },
  alpha?: number,
) {
  const colorStart = alpha ? addAlpha(color.start, alpha) : color.start
  const colorMid = alpha ? addAlpha(color.end, alpha) : color.end
  const colorEnd = alpha ? addAlpha(color.start, alpha) : color.start

  const gradient = ctx.createLinearGradient(0, area.left, 0, area.right)

  gradient.addColorStop(0, colorStart)
  gradient.addColorStop(0.5, colorMid)
  gradient.addColorStop(1, colorEnd)

  return gradient
}
