/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { converterStringToNumber } from 'helpers/converter.helper'
import { createGradient } from 'helpers/createGradient'
import { formatNumbersDashboard } from 'helpers/formatNumbersDashboard'
import { getMonthDays } from 'helpers/getMonthDays'
import { getWeekDays } from 'helpers/getWeekDays'
import { getElectricalQuantities } from 'services/dashboard/energyDashboard.service'
import { sensorService } from 'services/sensor.service'

import {
  getKeyByIndex,
  length,
} from '../../../../core/enums/ElectricalQuantitiesEnum'

interface Props {
  isPreview?: boolean
}

export function useElectricalQuantitiesChart(props: Props) {
  const { isPreview } = props
  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [selectedSensors, setSelectedSensors] = useState<any[]>([])
  const [selectedGreatness, setSelectedGreatness] = useState<any>()
  const [sensorId, setSelectSensor] = useState<Array<string>>()

  const [period, setPeriod] = useState<PeriodType>('month')
  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data: electricalQuantities } = useQuery({
    queryKey: [
      'electricalQuantities',
      startDate,
      endDate,
      period,
      sensorId,
      selectedGreatness,
    ],
    queryFn: () => {
      return getElectricalQuantities(
        startDate,
        endDate,
        period,
        sensorId,
        selectedGreatness?.value,
      )
    },
  })

  const { data: sensors } = useQuery({
    queryKey: ['sensor', 'electric'],
    queryFn: async () => {
      return await sensorService.listAllElectricType()
    },
    select: (data) => {
      return data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }))
    },
  })

  useEffect(() => {
    if (sensors?.length) {
      setSelectedSensors([
        {
          label: sensors[0]?.label,
          value: sensors[0]?.value,
        },
      ])
    }
  }, [sensors])

  const getGreatness = () => {
    const greatness = []
    for (let index = 0; index < length; index++) {
      greatness.push({
        // label: getValueByIndex(index),
        label: t(getKeyByIndex(index)),
        value: getKeyByIndex(index),
      })
    }
    return greatness
  }
  const greatness = getGreatness()

  useEffect(() => {
    if (greatness?.length) {
      setSelectedGreatness(greatness[0])
    }
  }, [])

  useEffect(() => {
    if (selectedSensors?.length > 0) {
      const ids = selectedSensors.map((sensor) => sensor.value)
      setSelectSensor(ids)
    }
  }, [selectedSensors])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const unitMeasurement =
              electricalQuantities?.[0]?.values?.[0]?.unitMeasurement
            const suffix = unitMeasurement ?? DashboardTooltipSuffixEnum.AMP

            return formatNumbersDashboard({
              context,
              suffix,
            })
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          display: isPreview ? false : true,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return (
              value +
              ' ' +
              (electricalQuantities?.[0]?.values?.[0]?.unitMeasurement ?? '')
            )
            // return value + ' k'
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  const getLabels = useCallback(() => {
    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    return getWeekDays(date, 'DD/MMM')
  }, [period])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()
    const chartData = {
      labels,
      datasets: electricalQuantities?.map((item, index) => ({
        type: 'line' as const,
        label: item?.nameSensor,
        data: item.values.map((value: any) => {
          return {
            x: String(value?.day),
            y: converterStringToNumber(value?.value),
          }
        }),
        borderRadius: 20,
        backgroundColor: createGradient(
          chart.ctx,
          chart.chartArea,
          colors.energy[index > colors.energy.length ? 0 : index],
        ),
        borderColor: createGradient(
          chart.ctx,
          chart.chartArea,
          colors.energy[index > colors.energy.length ? 0 : index],
        ),
      })),
    }

    setChartData(chartData)
  }, [i18n.language, date, electricalQuantities, getLabels])

  return {
    isDesktop,
    date,
    selectedSensors,
    options,
    setDate,
    setSelectedSensors,
    chartData,
    chartRef,
    greatness,
    sensors,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
    selectedGreatness,
    setSelectedGreatness,
  }
}
