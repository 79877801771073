import * as yup from 'yup'

const REQUIRED_MESSAGE = 'validations.required'
export const schemaClientSensor = (status: string) =>
  yup.object({
    name: yup.string().when(status, {
      is: 'ACCEPTED',
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string().nullable(true),
    }),
    nameEquipment: yup.string().min(2).max(35).required(REQUIRED_MESSAGE),
    description: yup.string().min(2).max(245).required(REQUIRED_MESSAGE),
    serialNumber: yup.string().required(REQUIRED_MESSAGE),
    type: yup.string().required(REQUIRED_MESSAGE),
    location: yup.string().required(REQUIRED_MESSAGE),
    reasonRejection: yup.string().max(650),
  })
