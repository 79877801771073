/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartData, ChartOptions } from 'chart.js'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { createGradient } from 'helpers/createGradient'
import { generateIntermediateColors } from 'helpers/generateIntermediateColors'
import { httpClient } from 'services/api/httpClient'

import { WaterVaseResponseType } from './@types/water.types'

const colors = [
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
]

export function useWaterVaseChart() {
  const { t, i18n } = useTranslation('common')
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<ChartData<'polarArea'>>({
    datasets: [],
  })
  const [generatingFile, setGeneratingFile] = useState(false)

  const { data, refetch } = useQuery({
    queryKey: 'water-vase',
    queryFn: async () => {
      const response = await httpClient.get<WaterVaseResponseType[]>(
        '/dashboard-water/water-vase',
      )

      return response.data as WaterVaseResponseType[]
    },
  })

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
      },
      title: {
        display: false,
        text: 'Chart.js',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.r} m³`
          },
        },
      },
    },
  }

  const bgColors =
    data?.map((_, index) => generateIntermediateColors(colors, index)) ?? []

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      labels: data?.map((item) => item.area) ?? [],
      datasets: [
        {
          data: data?.map((item) => Number((item.value || 0).toFixed(2))) ?? [],
          backgroundColor: data?.map((_, index) =>
            createGradient(
              chart.ctx,
              chart.chartArea,
              bgColors[index] ?? colors[index],
            ),
          ),
          borderWidth: 1,
        },
      ],
    }

    setChartData(chartData)
  }, [i18n.language, data])

  return {
    chartData,
    chartRef,
    options,
    t,
    generatingFile,
    setGeneratingFile,
    data,
    bgColors,
  }
}
