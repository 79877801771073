import { Stack, useTheme } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { PanicButtonTypeEnum } from 'core/enums/PanicButtonTypeEnum'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ReactFlow, {
  Node,
  Background,
  BackgroundVariant,
  useNodesState,
} from 'reactflow'

import { ResizableNode } from 'components/reactFlow/ResizableNode'
import { subareaType } from 'constants/subarea'
import { addAlpha } from 'helpers/addAlpha'
import { useMonitoring } from 'hooks/map/useMonitoring'
import { FormAlertPanicModal } from 'pages/btnPanicAlert/components/FormAlertPanicModal'
import { useMonitoringStore } from 'store/monitoring/monitoring.store'
import { panicMonitoringAreaSidebarStore } from 'store/panic-monitoring/panicMonitoringAreaSidebar.store'
import { SubareaType } from 'types/subarea/subarea'

import { MapTabNavigation } from '../MapTabNavigation'
import { PanicBackgroundNode } from '../Nodes/PanicBackgroundNode'
import { PanicNode } from '../Nodes/PanicNode'

const NODE_TYPES = {
  panic: PanicNode,
  group: ResizableNode,
  background: PanicBackgroundNode,
}

const INITIAL_NODES = [
  {
    id: 'id-background',
    type: 'background',
    position: {
      x: 0,
      y: 0,
    },
    data: {},
    draggable: false,
    selectable: false,
    style: {
      zIndex: '-1 !important',
    },
  },
] satisfies Node[]

type CurrentMap = {
  id: string
  image: {
    fileName: string
  }
}

export const UnitAreaScreen = () => {
  const theme = useTheme()
  const [nodes, setNodes] = useNodesState(INITIAL_NODES)
  const [searchParams] = useSearchParams()
  const {
    companyId = '',
    panicButtonTypes,
    setTotalEquipment,
    setTotalTriggered,
  } = panicMonitoringAreaSidebarStore()

  const [currentMap, setCurrentMap] = useState<CurrentMap | null>(null)

  const { maps } = useMonitoringStore()
  const { sensorsAndEquipments, subareas, refetchData } = useMonitoring({
    companyId,
    mapType: subareaType.PANIC_BUTTON_PLANT,
    typesEquipment: [EquipmentTypeEnum.BTN_PANIC],
  })
  const [sensorSelected, setSensorSelected] = useState<SubareaType | null>(null)
  const openForm = Boolean(sensorSelected)

  useEffect(() => {
    const errorHandler = (event: any) => {
      const messages = [
        'ResizeObserver loop completed with undelivered notifications',
        'ResizeObserver loop limit exceeded',
      ]

      if (messages.some((message) => event.message?.includes?.(message))) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
      }
    }

    window.addEventListener('error', errorHandler)

    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  useEffect(() => {
    const mapId = searchParams.get('mapId')
    const map = maps.find((row) => row.entityId.value === mapId)

    if (map) {
      setCurrentMap({
        id: map?.entityId?.value,
        image: {
          fileName: map?.mapImage?.fileName,
        },
      })
    } else {
      setCurrentMap(null)
    }
  }, [searchParams, maps])

  useEffect(() => {
    const backgroundNode = nodes.find((row) => row.id === 'id-background')
    if (backgroundNode) {
      backgroundNode.data = {
        ...backgroundNode.data,
        fileName: currentMap?.image?.fileName ?? '',
      }
    }

    if (backgroundNode) {
      const newNodes = subareas
        .map<Node>((subarea) => {
          return {
            id: subarea.idMap,
            type: subarea.type,
            data: {
              label: subarea.nomeSubArea,
              resizable: false,
            },
            position: {
              x: subarea.coordinateX,
              y: subarea.coordinateY,
            },
            style: {
              width: subarea.width ?? 20,
              height: subarea.height ?? 20,
              background: subarea.color
                ? addAlpha(subarea.color, 0.5)
                : '#F2578366',
              borderRadius: 5,
              fontSize: 14,
            },
          }
        })
        .concat(backgroundNode)

      sensorsAndEquipments.forEach((row) => {
        const subarea = subareas.find(
          (item) => item.areaID.value === row.area?.id?.value,
        )

        newNodes.push({
          id: row.idMap,
          hidden: row.equipment?.triggered
            ? !panicButtonTypes.includes(PanicButtonTypeEnum.Activated)
            : !panicButtonTypes.includes(PanicButtonTypeEnum.Operated),
          type: 'panic',
          position: {
            x: row.coordinateX,
            y: row.coordinateY,
          },
          ...(subarea?.idMap && {
            extent: 'parent',
            parentNode: subarea?.idMap,
          }),
          data: {
            label: row.equipment?.name,
            type: row.equipment?.type,
            subareaName: subarea?.nomeSubArea,
            triggered: row.equipment?.triggered,
            triggerDate: row.equipment?.triggerDate,
            lastUpdated: row.equipment?.lastUpdated,
            sensorAlert: row.equipment?.sensors?.[0]?.sensor?.id?.value,
            callback: { showDetails: () => setSensorSelected(row) },
          },
        })
      })

      const triggeredSensors = sensorsAndEquipments.filter(
        (item) => item.equipment?.triggered,
      )
      setTotalEquipment(triggeredSensors.length)
      setTotalTriggered(sensorsAndEquipments.length)

      setNodes(newNodes)
    }
  }, [subareas, currentMap, panicButtonTypes, sensorsAndEquipments])

  return (
    <div>
      <MapTabNavigation
        parentElementIsOpen={true}
        parentElementSize={{
          large: 280,
          small: 40,
        }}
        hideActionNewArea
        maps={maps}
      />
      <Stack
        sx={{
          position: 'relative',
          height: 'calc(100vh - 104px)',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <ReactFlow
            nodeTypes={NODE_TYPES}
            nodes={nodes}
            zoomOnDoubleClick={false}
            minZoom={0.2}
            fitView
          >
            <Background
              gap={32}
              color={theme.palette.background.paper}
              variant={BackgroundVariant.Lines}
            />
          </ReactFlow>
        </Stack>

        {openForm && (
          <FormAlertPanicModal
            open={openForm}
            onClose={() => setSensorSelected(null)}
            onSuccess={refetchData}
            data={sensorSelected}
            isView={false}
          />
        )}
      </Stack>
    </div>
  )
}
