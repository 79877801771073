import {
  CreateControllerType,
  CreateEquipmentType,
} from 'types/equipment/create-equipment'

import { httpClient } from '../api/httpClient'

export const createEquipment = (data: CreateEquipmentType) => {
  const { file, ...rest } = data
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
  }
  formData.append('request', JSON.stringify(rest))

  return httpClient.post('/equipment/create', formData)
}

export const createEquipmentController = async (data: CreateControllerType) => {
  return httpClient.post('/control', data)
}

export const updateEquipmentController = async (
  id: number,
  data: CreateControllerType,
) => {
  return httpClient.put(`/control/${id}`, data)
}
