import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams, useLocation } from 'react-router-dom'

import { subareaType } from 'constants/subarea'
import { branchService } from 'services/branch.service'
import { linkedAreaService } from 'services/linkedArea/linked-area.service'
import { subAreaService } from 'services/subarea/subArea.service'
import { useMonitoringStore } from 'store/monitoring/monitoring.store'
import { findBySensorsAndValueTypes } from 'services/sensor-monitor/sensor-monitor-service'
import { filter, map, includes, isEmpty } from 'lodash'
import { SensorAndValueType } from 'types/sensor-monitor/find-sensor-monitor'

type Props = {
  companyId: string
  mapType?: subareaType
  sensorTypes?: SensorTypeEnum[]
  typesEquipment?: EquipmentTypeEnum[]
  visibleSensorTypes?: SensorTypeEnum[]
  setLoading?: (loading: boolean) => void
}

export const useMonitoring = ({
  companyId,
  sensorTypes,
  typesEquipment,
  mapType = subareaType.SENSOR_PLANT,
  visibleSensorTypes = [],
  setLoading,
}: Props) => {
  const queryClient = useQueryClient()
  const { setMaps } = useMonitoringStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const isPanicAlert = pathname === '/btn-panic-alert/monitoring/area'
  const currentMapId = searchParams.get('mapId')

  const { data: companies = [] } = useQuery({
    queryKey: 'list-companies',
    queryFn: branchService.findListBranchesSelect,
    select: (data) =>
      data.map((item) => ({
        id: item.id.value,
        name: item.name,
      })),
  })

  const handleLoading = (loading: boolean) => setLoading && setLoading(loading)

  useQuery({
    queryKey: ['list-maps', companyId],
    refetchOnReconnect: true,
    queryFn: () => branchService.findCompanyById(companyId),
    enabled: Boolean(companyId.length),
    cacheTime: Infinity,
    onSuccess(data) {
      setMaps(data.areas)

      const firstMap = data.areas.at(0)

      if (firstMap) {
        const mapId = currentMapId?.length
            ? currentMapId
            : firstMap?.entityId?.value;

        setSearchParams({
          mapId: mapId,
        })
      } else {
        setSearchParams({
          mapId: '',
        })
      }
    },
  })

  const { data: mapDetails } = useQuery({
    queryKey: ['map-details', currentMapId],
    queryFn: () => {
      return subAreaService.listSubareaById({
        id: currentMapId as string,
        type: { mapType },
      })
    },
    enabled: Boolean(currentMapId && companyId),
  })

  const { data: dataSensorsAndEquipments } = useQuery({
    queryKey: ['list-sensors-and-equipment', currentMapId],
    queryFn: () => {
      handleLoading(true)
      return linkedAreaService.findAllByMapId({
        mapId: currentMapId as string,
        typesEquipment,
        sensorTypes,
      })
    },
    enabled: Boolean(currentMapId && companyId),
    onSettled: () => {
      if(!isEmpty(visibleSensorTypes)) refetchSensorsMonitorData()
      handleLoading(false)
    },
    refetchInterval: isPanicAlert ? 5 * 1000 : false,
  })

  const { data: dataSensorsMonitorData, refetch: refetchSensorsMonitorData } = useQuery({
    queryKey: ['sensors-monoritor-data', 'list-sensors-and-equipment', dataSensorsAndEquipments],
    queryFn: () => {
      handleLoading(true)
      const sensorTypesFiltered = filter(visibleSensorTypes, st => st !== SensorTypeEnum.ALL && st !== SensorTypeEnum.BTN_PANIC)
      const sensorsFiltered = filter(dataSensorsAndEquipments, s => s.type === 'sensor' && includes(sensorTypesFiltered, s.sensor.type) )
      const sensorsMapped: SensorAndValueType[] = map(sensorsFiltered, ({ sensor: { id : { value }, type } }) => ({ sensor: +value, valueType: type }))
      return findBySensorsAndValueTypes(sensorsMapped)
    },
    enabled: Boolean(!isEmpty(visibleSensorTypes) && !isEmpty(dataSensorsAndEquipments)),
    refetchInterval: 60 * 1000,
    onSettled: () => handleLoading(false),
  })

  const refetchData = () => {
    queryClient.invalidateQueries('list-maps')
    queryClient.invalidateQueries('map-details')
    queryClient.invalidateQueries('list-companies')
    queryClient.invalidateQueries('list-sensors-and-equipment')
    queryClient.invalidateQueries('sensors-monoritor-data')
  }

  useEffect(() => {
    refetchData()

    return () => {
      refetchData()
      setMaps([])
    }
  }, [companyId])

  const subareas = useMemo(
    () => mapDetails?.subAreas ?? [],
    [mapDetails?.subAreas],
  )

  const sensorsAndEquipments = useMemo(
    () => dataSensorsAndEquipments ?? [],
    [dataSensorsAndEquipments],
  )

  const sensorsMonitorData = useMemo(
    () => dataSensorsMonitorData ?? [],
    [dataSensorsMonitorData],
  )

  return {
    subareas,
    sensorsAndEquipments,
    company: {
      items: companies,
    },
    refetchData,
    sensorsMonitorData,
  }
}
