/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { TableActionType } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { notificationSettingService } from 'services/notification/notification-setting.service'
import { ColumnType } from 'types/table/column'

import { notificationsMapper } from '../mappers/notifications.mapper'

export function useNotification() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState<any | null>(
    null,
  )

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')

  const { data, error, isLoading, isFetching, refetch } = useQuery<any, Error>({
    queryKey: ['notifications', page, search, orderBy, order],
    queryFn: () =>
      notificationSettingService.findAll({
        page,
        search,
        columnSort: order,
        columnName: orderBy,
      }),
  })

  const notifications = notificationsMapper(data?.items ?? [])

  const handleOpen = () => setOpenForm(true)

  const handleCancel = () => {
    setOpenForm(false)
    setOpenFormView(false)
    setSelectedNotification(null)
  }

  const handleEdit = (item: any) => {
    setSelectedNotification(item)
    handleOpen()
  }

  const handleView = (item: any) => {
    setSelectedNotification(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: any) => {
    httpDelete(`/notification-setting/${item.id.value}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'name',
      label: t('table.notifications.name'),
    },
    {
      id: 'type',
      label: t('table.notifications.type'),
    },
    {
      id: 'moduleMapper',
      label: t('table.notifications.module'),
    },
    {
      id: 'usersMapper',
      label: t('table.notifications.users'),
    },
    {
      id: 'status',
      label: 'Status',
    },
  ]

  const tableActions: TableActionType<any>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_NOTIFICATIONS,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_NOTIFICATIONS,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_NOTIFICATIONS,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return {
    t,
    tableActions,
    openForm,
    openFormView,
    selectedNotification,
    columns,
    notifications,
    data,
    handleCancel,
    handleOpen,
    search,
    setSearch,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    isFetching,
    isLoading,
    error,
    page,
    setPage,
  }
}
