import { IconButton } from 'components/Button/IconButton'
import { Sensor } from 'components/Sensor'

export function MonitoramentoSensoresContent() {
  return (
    <>
      <p>
        Nesse submódulo são monitorados os sensores e equipamentos de fábrica
        que foram cadastrados em sensores (Configurações {'->'} Sensores) e
        equipamentos (Configurações {'->'} Equipamentos). Para realizar a
        visualização e utilização, é necessário montar o mapa em Mapa {'->'}{' '}
        Montar Mapa. Caso esse submódulo não apareça no seu sistema atualmente,
        provavelmente o seu tipo de perfil não permite o acesso. Caso necessite
        o uso desse submódulo, solicite ao administrador do sistema.
      </p>
      <p>Para realizar o controle dos ar-condicionado, basta:</p>
      <p>
        <strong>Passo 1:</strong> Encontre um Sensor de Temperara Branco{' '}
        <IconButton
          iconName='temperature'
          variant='contained'
          size='small'
          sx={{
            background: '#FFF',
            '&:hover': {
              background: '#FFF',
            },
          }}
        />
        , normalmente ícones de cores brancas são alimentadores, se o ícone é
        branco e com um termômetro nele, é um ar-condicionado.
      </p>
      <p>
        <strong>Passo 2:</strong> Passe o mouse por cima desse ícone e um
        pequeno balão surgirá, nele são informado o nome do equipamento, o
        código, área, opção de ver a foto, sugestão de temperatura, criar uma OS
        a partir desse equipamento e o controle de mudança de temperatura .
      </p>
      <p>
        <strong>Passo 3:</strong> Coloque a temperatura que deseja.{' '}
      </p>
      <p>
        Vale ressaltar que, o parâmetro de menor temperatura e maior deve ser
        definida em (Configurações {'->'} Metas e Parâmetros {'->'} Parâmetros).
      </p>
      <p>
        Para verificar a energia, temperatura, umidade, água e o gás, basta:
      </p>
      <p
        style={{
          display: 'inline-flex',
        }}
      >
        <strong>Passo 1:</strong> Encontre um sensor de energia{' '}
        <Sensor icon='ENERGY' size='small' /> , umidade{' '}
        <Sensor icon='HUMIDITY' size='small' /> , temperatura{' '}
        <Sensor icon='TEMPERATURE' size='small' /> , água{' '}
        <Sensor icon='WATER' size='small' /> , temperatura e umidade{' '}
        <Sensor icon='TEMPERATURE_HUMIDITY' size='small' /> ou gás{' '}
        <Sensor icon='GAS' size='small' /> .{' '}
      </p>
      <p>
        Passo 2: Passe o mouse por cima desse ícone, um pequeno balão irá
        surgir, nele são informado o nome do equipamento, área, setor, o código
        (caso esteja atrelado a algum equipamento), opção de ver a foto e criar
        uma OS a partir desse sensor ou equipamento.
      </p>
      <p>
        A diferença nos sensores de energia{' '}
        <div style={{ maxWidth: '40px' }}>
          <Sensor icon='ENERGY' size='small' />
        </div>
        , é que você poderá ligar ou desligar o equipamento por meio do sistema.
        Um equipamento pode ter mais de um sensor atrelado a ele, por tanto,
        dentro de balão, serão mostrados todos os sensores que estão nele com
        seus ícones e informações individuais. Exemplo: água mostrará os litros
        por segundo, temperatura os graus celsius, gás e umidade em porcentagem
        e energia os volts.
      </p>
    </>
  )
}
