import { Stack } from '@mui/material'

import { IconButton } from 'components/Button/IconButton'
import { SensorIconType } from 'components/Sensor'

import { DashboardButton } from './DashboardButton'

const routes = {
  WATER: '/dashboard-water/all/export-excel',
  ENERGY: '/dashboard-energy/all/export-excel',
  GAS: '/dashboard-gas/all/export-excel',
  TEMPERATURE_HUMIDITY: '/dashboard-temperature-and-humidity/all/export-excel',
}

interface Props {
  mode: 'grid' | 'presentation'
  onMode: (value: 'grid' | 'presentation') => void
  buttons: SensorIconType[]
  activeButtonIndex: number
  onClickButton: (index: number) => void
}

export function DashboardHeader({
  mode,
  onMode,
  buttons,
  activeButtonIndex,
  onClickButton,
}: Props) {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      flexWrap={['wrap', 'nowrap']}
      marginBottom={3}
    >
      <Stack
        flex={1}
        direction='row'
        spacing={2}
        alignItems='center'
        marginBottom={1}
      >
        {buttons.map((button, index) => (
          <DashboardButton
            key={button}
            active={activeButtonIndex === index ? button : undefined}
            icon={button}
            onClick={() => onClickButton(index)}
          />
        ))}
      </Stack>

      <Stack
        flex={1}
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent={['flex-start', 'flex-end']}
        marginBottom={1}
      >
        {/* <IconButton
          iconName='download'
          variant='contained'
          color='secondary'
          onClick={() =>
            httpClientDownloadExcel({
              url: routes[buttons[activeButtonIndex] as keyof typeof routes],
              fileName: buttons[activeButtonIndex],
              startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
              endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
              year: dayjs().year(),
            })
          }
        /> */}
        <IconButton
          iconName={mode === 'grid' ? 'presentation' : 'grid'}
          variant='contained'
          color='secondary'
          onClick={() => onMode(mode === 'grid' ? 'presentation' : 'grid')}
        />
      </Stack>
    </Stack>
  )
}
