import { Position, Handle as RFHandle } from 'reactflow'

interface Props {
  left?: boolean
  right?: boolean
  top?: boolean
  bottom?: boolean
}

export function Handles({
  left = true,
  right = true,
  top = true,
  bottom = true,
}: Props) {
  return (
    <>
      {left && (
        <RFHandle
          id='left'
          type='source'
          position={Position.Left}
          style={{
            background: '#78C7CD',
            borderColor: '#78C7CD',
            opacity: 0,
          }}
        />
      )}
      {right && (
        <RFHandle
          id='right'
          type='source'
          position={Position.Right}
          style={{
            background: '#78C7CD',
            borderColor: '#78C7CD',
            opacity: 0,
          }}
        />
      )}
      {top && (
        <RFHandle
          id='top'
          type='source'
          position={Position.Top}
          style={{
            background: '#78C7CD',
            borderColor: '#78C7CD',
            opacity: 0,
          }}
        />
      )}
      {bottom && (
        <RFHandle
          id='bottom'
          type='source'
          position={Position.Bottom}
          style={{
            background: '#78C7CD',
            borderColor: '#78C7CD',
            opacity: 0,
          }}
        />
      )}
    </>
  )
}
