import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { converterKwhToMwh } from 'helpers/converter.helper'
import { SensorDataRecordService } from 'services/sensor-data-record/sensor-data-record.service'
import {
  SensorDataRecordDefaultType,
  SensorDataRecordTempAndHumType,
  SensorDataRecordType,
  SensorDataRecordWaterType,
} from 'types/sensor/sensor-data-record'

type FormattedData = {
  label: string
  value: string
}

type FormatterType = (
  row: SensorDataRecordType,
) => FormattedData | FormattedData[]

type Params = {
  sensor: {
    id?: string
    type: SensorTypeEnum
  }
}

export const useSensorDetails = (params: Params) => {
  const { t } = useTranslation('common')
  const [isLoading, setIsLoading] = useState(false)
  const [dataRecord, setDataRecord] = useState<SensorDataRecordType[]>([])
  const sensorId = useMemo(() => params.sensor?.id ?? '', [params.sensor.id])

  const fetchSensorData = useCallback(() => {
    if (sensorId) {
      setIsLoading(true)
      SensorDataRecordService.findAllBySensorId(sensorId)
        .then(({ data }) => setDataRecord(data))
        .catch(() => {
          toast.error(t('information.list'))
        })
        .finally(() => setIsLoading(false))
    }
  }, [sensorId])

  useEffect(() => {
    fetchSensorData()
  }, [fetchSensorData])

  const formatValueToDecimal = (value: number | null, fixed = 2) => {
    return (value || 0).toFixed(fixed).replace('.', ',')
  }

  const formatDataWater = (row: SensorDataRecordWaterType) => {
    const dailyConsumption = formatValueToDecimal(row.totalValue)
    const dailyConsumptionCubicMeters = formatValueToDecimal(
      converterKwhToMwh(row.totalValue || 0),
      3,
    )

    return [
      {
        label: 'Vazão',
        value: `${formatValueToDecimal(row.value)} l/s`,
      },
      {
        label: 'Vazão média/dia',
        value: `${formatValueToDecimal(row.averageValue)} l/s`,
      },
      {
        label: 'Consumo/dia',
        value: `${dailyConsumption} L ou ${dailyConsumptionCubicMeters}m³`,
      },
    ]
  }

  const formatDataEletric = (row: SensorDataRecordDefaultType) => {
    const types: Record<string, string> = {
      A_SYS: 'Corrente total',
      V_L_L_SYS: 'Corrente total',
      PF_SYS: 'Fator de potencia',
    }

    const electricType = types[row.type] ?? row.type

    return {
      label: electricType,
      value: `${row?.value} ${row.unitMeasurement}`,
    }
  }

  const formatDataTemperatureOrHumidity = (
    row: SensorDataRecordDefaultType,
  ) => {
    return {
      label: row.value ?? 0,
      value: `${row?.unitMeasurement} ${t(`sensor.${params.sensor?.type}`)}`,
    }
  }

  const formatDataAir = (row: SensorDataRecordDefaultType) => {
    return {
      label: `${row.type} - ${row.value}`,
      value: row.unitMeasurement,
    }
  }

  const formatDataTemperatureAndHumidity = (
    row: SensorDataRecordTempAndHumType,
  ) => {
    return [
      {
        label: 'Temperatura',
        value: `${(row?.valueTemp || 0).toFixed(0)} ºC`,
      },
      {
        label: 'Umidade',
        value: `${(row?.valueHum || 0).toFixed(0)}% ${t('sensor.HUM')}`,
      },
    ]
  }

  const getFormatter = useCallback(() => {
    const methods = {
      [SensorTypeEnum.AIR]: formatDataAir,
      [SensorTypeEnum.WATER]: formatDataWater,
      [SensorTypeEnum.ELECTRIC]: formatDataEletric,
      [SensorTypeEnum.HUM]: formatDataTemperatureOrHumidity,
      [SensorTypeEnum.TEMP]: formatDataTemperatureOrHumidity,
      [SensorTypeEnum.TEMP_HUM]: formatDataTemperatureAndHumidity,
    }

    const method = Object.entries(methods).find(
      ([key]) => key === params.sensor.type,
    )
    return method?.[1] as FormatterType | undefined
  }, [params.sensor.type])

  const dataFormated = useMemo(() => {
    const method = getFormatter()
    return method ? dataRecord.map(method).flat() : []
  }, [dataRecord])

  return {
    isLoading,
    dataRecord,
    dataFormated,
  }
}
