/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateLevelType } from 'types/level/create-level'
import { PaginationType } from 'types/pagination'
import { CreateScalabilityType } from 'types/scalability/create-scalability'
import { ScalabilityType } from 'types/scalability/scalability'

import { httpClient } from './api/httpClient'

export const create = (data: CreateScalabilityType) => {
  return httpClient.post('/scalability/create-all', data)
}
export const createLevel = (data: any) => {
  return httpClient.post('/scalability/level', data)
}

type Newtype = PaginationType<ScalabilityType>
export const findAll = async (
  page = 1,
  search = '',
  orderBy?: string,
): Promise<Newtype> => {
  const response = await httpClient.get('/scalability/get-all-order-by-level')
  const { total } = response.data
  const { perPage } = response.data
  const { items } = response.data
  const results = items.filter((item: ScalabilityType) =>
    item.level.name.toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
    order: orderBy,
  }
}

export const update = (id: number | string, data: any) => {
  return httpClient.put(`/scalability/${id}`, data)
}

export const updateLevel = (id: number, data: CreateLevelType[]) => {
  return httpClient.put(`/level/${id}`, data)
}

export const updateByLevel = (id: number, data: any) => {
  return httpClient.put(`/scalability/level/${id}`, data)
}

export const scalabilityService = {
  create,
  createLevel,
  findAll,
  update,
  updateLevel,
  updateByLevel,
}
