import { create } from 'zustand'

import { MonitoringCompanies } from 'types/monitoring-companies'

type MonitoringSensorStore = {
  showDataCompanyDetail: boolean | null
  setShowDataCompanyDetail: (value: boolean) => void
  currentCompany: MonitoringCompanies | null
  setCurrentCompany: (company: MonitoringCompanies) => void
}

export const useMonitoringSensorStore = create<MonitoringSensorStore>(
  (set) => ({
    showDataCompanyDetail: true,
    setShowDataCompanyDetail: (showDataCompanyDetail) => {
      set({ showDataCompanyDetail })
    },
    currentCompany: null,
    setCurrentCompany: (currentCompany) => {
      set({ currentCompany })
    },
  }),
)
