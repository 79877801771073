import * as yup from 'yup'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

const REQUIRED_FIELD = 'validations.required'
const ARRAY_MIN = 'validations.arrayMin'

export const schemaCreateParameter = yup.object({
  unit: yup.string().required(),
  area: yup.string(),
  temperatureMax: yup.string().when('isOnlyMax', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
  }),
  sensors: yup.array().when('unit', {
    is: SensorTypeEnum.OPENING,
    then: yup.array().min(1, ({ min }) => ({
      key: ARRAY_MIN,
      values: { min },
    })).required(REQUIRED_FIELD),
  }),
  qtdTimeChecked: yup.boolean(),
  numberOfTimes: yup.string().when('qtdTimeChecked', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
  }),
  periodNUmber: yup.string().when('qtdTimeChecked', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
  }),
  periodType: yup.string().when('qtdTimeChecked', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
  }),
  openingTimeChecked: yup.boolean(),
  openingTimeNumber: yup.string().when('openingTimeChecked', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
  }),
  openingTimeType: yup.string().when('openingTimeChecked', {
    is: true,
    then: yup.string().required(REQUIRED_FIELD),
  }),
  hours: yup.array().of(
    yup.object({
      status: yup.boolean(),
      hourInitial: yup.string().when('status', {
        is: true,
        then: yup.string().required(REQUIRED_FIELD),
      }),
      hourFinal: yup.string().when('status', {
        is: true,
        then: yup
          .string()
          .required(REQUIRED_FIELD)
          .test('hourFinal', 'endTimeNoStartTime', function (value) {
            const { hourInitial } = this.parent
            if (hourInitial && value) {
              return (
                new Date(`01/01/2000 ${value}`) >
                new Date(`01/01/2000 ${hourInitial}`)
              )
            }
            return true
          }),
      }),
    }),
  ),
  dates: yup.array().when('openingTimeChecked', {
    is: true,
    then: yup.array().min(1, ({ min }) => ({
      key: ARRAY_MIN,
      values: { min },
    })).required(REQUIRED_FIELD),
  })
  .of(
    yup.object({
      status: yup.boolean(),
      startDate: yup
        .date()
        .nullable()
        .when('status', {
          is: true,
          then: yup.date().nullable().required(REQUIRED_FIELD),
        }),
      endDate: yup
        .date()
        .nullable()
        .when('status', {
          is: true,
          then: yup
            .date()
            .nullable()
            .required(REQUIRED_FIELD)
            .test('endDate', 'endDateNoStartDate', function (value) {
              const { startDate } = this.parent
              if (startDate && value) {
                return new Date(value) > new Date(startDate)
              }
              return true
            }),
        }),
    }),
  ),
})
