import { Box } from '@mui/material'
import { ReactNode } from 'react'

import { ModeType } from 'types/mode'

interface Props {
  mode: ModeType
  type: ModeType
  children?: ReactNode
}

export function ModeContainer(props: Props) {
  const { mode, type, children } = props
  return (
    <>
      {mode === 'grid' && type === mode && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['auto', '40% 1fr 40%'],
            gridTemplateAreas: [
              '"top-left""top-right""medium""bottom-left""bottom-right"',
              '"top-left top-left top-right""medium medium medium""bottom-left bottom-right bottom-right"',
            ],
            gap: 3,
          }}
        >
          {children}
        </Box>
      )}

      {mode === 'presentation' && type === mode && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 3,
          }}
        >
          {children}
        </Box>
      )}
    </>
  )
}
