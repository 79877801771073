// import { LIMIT_PER_PAGE } from 'constants/pagination'
import { httpClient } from 'services/api/httpClient'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'
import { CreateUserType, UsersType } from 'types/user/user'

const create = (data: CreateUserType) => {
  return httpClient.post('/user-account', {
    // active: data.active,
    profile: data.profile,
    contact: data.contact,
    email: data.email,
    name: data.name,
    rfid: data.rfid,
    role: data.role,
    password: 123,
  })
}

const update = (id: number, data: CreateUserType) => {
  return httpClient.put(`/user-account/${id}`, {
    // active: data.active,
    profile: data.profile,
    contact: data.contact,
    email: data.email,
    name: data.name,
    rfid: data.rfid,
    role: data.role,
    // password: 123,
  })
}

const updateStatus = (id?: number | string) => {
  return httpClient.patch(`/user-account/status/${id}`)
}

type NewType = PaginationType<UsersType>
export const findAll = async ({
  page = 1,
  search = '',
  columnSort,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  if (search?.length) {
    const { data } = await httpClient.post<PaginationResponseType>(
      '/user-account/findcustom',
      { filter: { name: search, email: search } },
      {
        params: {
          search,
          page: page - 1,
          columnSort,
          numberPerPage,
        },
      },
    )

    return {
      items: data?.items ?? [],
      currentPage: page,
      perPage: data?.perPage,
      total: data?.total,
    }
  }

  const { data } = await httpClient.get<PaginationResponseType>(
    'user-account',
    {
      params: {
        page: page - 1,
        columnSort,
        numberPerPage,
      },
    },
  )

  return {
    items: data?.items ?? [],
    currentPage: page,
    perPage: data?.perPage,
    total: data?.total,
  }
}

export const list = async () => {
  const { data } = await httpClient.get<PaginationResponseType>(
    'user-account',
    {
      params: {
        page: 0,
        numberPerPage: 10000,
      },
    },
  )

  return (
    data?.items?.map((item: UsersType) => ({
      id: item?.entityId?.value ?? item?.id,
      name: item.name,
    })) ?? []
  )
}

export const userService = {
  create,
  findAll,
  update,
  updateStatus,
  list,
}
