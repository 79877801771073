import { CreateProceduresType } from 'types/procedures/create-procedures'

import { httpClient } from '../api/httpClient'

export const updateProcedures = (id: number, data: CreateProceduresType) => {
  const { ...rest } = data
  const formData = new FormData()

  formData.append('request', JSON.stringify(rest))

  return httpClient.put(`/procedures/update/${id}`, formData)
}
