/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { ValidationError } from 'yup'

import { ErrorMessage } from 'components/Input/ErrorMessage'

interface Props extends TextareaAutosizeProps {
  control?: any
  name?: string
  label?: string
  helperText?: string
  errorMessage?: any | ValidationError | string
  error?: boolean
}

export function Textarea({
  control,
  name,
  label,
  errorMessage,
  helperText,
  error,
  ...rest
}: Props) {
  const theme = useTheme()
  const [messageLength, setMessageLength] = useState(0)

  return (
    <FormControl
      fullWidth
      sx={{
        position: 'relative',

        '& .MuiFormLabel-root': {
          position: 'absolute',
          top: '10px',
          left: '16px',
          zIndex: 1,
          fontSize: '10px',
          backgroundColor: theme.palette.background.default,
          padding: '0 4px',
          display: 'none',
        },

        // adicionar o label acima do textarea ao focar ou quando o textarea tiver conteúdo
        '&:not(:empty) .MuiFormLabel-root': {
          display: 'block',
          backgroundColor: theme.palette.background.default,
          padding: '0 4px',
          color: 'white',
          transition: 'all 0.2s ease', // Adiciona uma transição suave
        },
      }}
    >
      {label && <FormLabel>{label}</FormLabel>} {/* Adicionando o label aqui */}
      <Stack
        width={'100%'}
        sx={{
          '& textarea::placeholder': {
            color: alpha(theme.palette.secondary.contrastText, 0.8),
          },
        }}
      >
        {control && name ? (
          <Controller
            name={name}
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextareaAutosize
                className='MuiTextarea-root'
                {...rest}
                {...field}
                onChange={(e) => {
                  field.onChange(e)
                  setMessageLength(e.target.value.length)
                }}
                style={{
                  width: '100%',
                  border: `2px solid ${
                    rest?.disabled
                      ? 'rgba(255, 255, 255, 0.3)'
                      : error
                      ? theme.palette.error.main
                      : theme.palette.primary.main
                  }`,
                  borderRadius: '4px',
                  backgroundColor: rest?.disabled
                    ? 'transparent'
                    : theme.palette.background.paper,
                  padding: '16px 14px',
                  outline: 'none',
                  color: theme.palette.getContrastText(
                    theme.palette.background.paper,
                  ),
                  fontSize: '14px',
                  fontFamily: 'inherit',
                }}
              />
            )}
          />
        ) : (
          <TextareaAutosize
            name={name}
            {...rest}
            onChange={(e) => {
              setMessageLength(e.target.value.length)
              rest?.onChange && rest.onChange(e)
            }}
            style={{
              width: '100%',
              border: `2px solid ${
                rest?.disabled
                  ? 'rgba(255, 255, 255, 0.3)'
                  : theme.palette.primary.main
              }`,
              borderRadius: '4px',
              backgroundColor: rest?.disabled
                ? 'transparent'
                : theme.palette.background.paper,
              padding: '16px 14px',
              outline: 'none',
              color: theme.palette.getContrastText(
                theme.palette.background.paper,
              ),
              fontSize: '14px',
              fontFamily: 'inherit',
            }}
          />
        )}

        {rest?.maxLength && (
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'flex-start'}
          >
            <Typography variant={'caption'}>
              {messageLength}/{rest.maxLength}
            </Typography>
          </Stack>
        )}
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errorMessage ? <ErrorMessage error={errorMessage} /> : helperText}
        </FormHelperText>
      </Stack>
    </FormControl>
  )
}
