import { styled } from '@mui/material/styles'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableContainer from '@mui/material/TableContainer'

import { addAlpha } from 'helpers/addAlpha'

export const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  maxHeight: 'auto',
  '&::-webkit-scrollbar': {
    width: 6,
    height: 6,
    background: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.background.default,
  },
}))

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  textTransform: 'uppercase',
  background: addAlpha(theme.palette.secondary.light, 0.6),
  color: theme.palette.secondary.contrastText,
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  verticalAlign: 'top',
}))
