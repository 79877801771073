import { Box, Popover, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import { Sensor, SensorIconType } from '..'

import { Button } from '../../Button'
import { SensorIconButton } from '../SensorIconButton'

interface Props {
  icon: SensorIconType
  title?: string
  popoverTitle: string
  cod?: string
  mod?: string
  children?: React.ReactNode
}

export function SensorScheme({
  icon,
  title,
  popoverTitle,
  cod,
  mod,
  children,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      {title ? (
        <Button
          variant='contained'
          color='secondary'
          startIcon={<Sensor size='small' icon={icon} />}
          sx={{ paddingX: 2 }}
          onClick={handleClick}
        >
          {title}
        </Button>
      ) : (
        <SensorIconButton size='small' icon={icon} onClick={handleClick} />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& > .MuiPaper-root': {
            borderRadius: '6px',
            border: (theme) =>
              theme.palette.mode === 'dark'
                ? '1px solid #5858B2'
                : '1px solid #ccc',
            background: (theme) => theme.palette.secondary.light,
            padding: 1,
          },
        }}
      >
        {popoverTitle && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={1}
            minWidth={180}
          >
            <Typography variant='body1'>{popoverTitle}</Typography>

            <Box
              sx={{
                height: 10,
                width: 10,
                borderRadius: 5,
                background: (theme) => theme.palette.success.main,
              }}
            />
          </Stack>
        )}

        {(cod || mod) && (
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={1}
          >
            {cod && <Typography variant='body1'>Cód.: {cod}</Typography>}
            {cod && mod && <Typography variant='body1'>|</Typography>}

            {mod && <Typography variant='body1'>Mod.: {mod}</Typography>}
          </Stack>
        )}

        {children && (
          <Stack spacing={1} width='100%' paddingTop={2}>
            {children}
          </Stack>
        )}
      </Popover>
    </>
  )
}
