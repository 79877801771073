import { Box, useMediaQuery, useTheme } from '@mui/material'

import { IconButton } from 'components/Button/IconButton'

interface Props {
  onPrevious?: () => void
  onNext?: () => void
}

export function PreviousAndNextButtons(props: Props) {
  const { onPrevious, onNext } = props

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      {isDesktop && (
        <>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              left: -16,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9,
            }}
          >
            <IconButton
              iconName='chevronLeft'
              color='secondary'
              variant='contained'
              onClick={onPrevious}
              sx={{
                boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                borderRadius: '50%',
              }}
            />
          </Box>

          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              right: -16,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9,
            }}
          >
            <IconButton
              iconName='chevronRight'
              color='secondary'
              variant='contained'
              onClick={onNext}
              sx={{
                boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                borderRadius: '50%',
              }}
            />
          </Box>
        </>
      )}
    </>
  )
}
