import { Stack } from '@mui/material'
import { useQuery } from 'react-query'

import { monitoringUnitService } from 'services/monitoring-unit/monitoring-unit.service'
import { useMonitoringSensorStore } from 'store/monitoring-sensor.store'

import { MapMonitoring } from './components/MapMonitoring'
import { dataFactory } from './mock'
import { SensorSidebar } from './SensorSidebar'
import { UnitSidebar } from './UnitSidebar'

export const MonitoringCompanies = () => {
  const { showDataCompanyDetail } = useMonitoringSensorStore()
  const { data: companies } = useQuery({
    queryKey: 'company-sensors-information',
    queryFn: () => monitoringUnitService.listSensorsCompanyInformation(),
    cacheTime: 0,
    staleTime: 0,
  })

  return (
    <Stack
      direction='row'
      flex={1}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <SensorSidebar />
      <MapMonitoring data={companies} />
      {!showDataCompanyDetail && <UnitSidebar data={dataFactory} />}
    </Stack>
  )
}
