import { Stack } from '@mui/material'
import { AirConditioningActionEnum } from 'core/enums/AirConditioningActionEnum'
import { FC } from 'react'

import { ReactComponent as ConnectAirIcon } from 'assets/icons/air-conditioning/connect-air.svg'
import { ReactComponent as ControlSpeedIcon } from 'assets/icons/air-conditioning/control-speed.svg'
import { ReactComponent as VentilateIcon } from 'assets/icons/air-conditioning/ventilate-with-border.svg'
import { Loading } from 'components/Loading'
import { useAirConditioningControl } from 'hooks/map/useAirConditioningControl'

import { AirConditioningControlPanel } from './AirConditioningControlPanel'
import { ActionItemAirConditioning } from './styles'

type Props = {
  equipmentId?: string
}

export const AirConditioningControl: FC<Props> = ({ equipmentId }) => {
  const {
    onOf,
    values,
    isLoading,
    levelFanMode,
    getColorIcon,
    changeFanMode,
    controlPanelRef,
    airConditioningIsOn,
    airConditioningFanMode,
    switchOnVentilationMode,
    executeCoolingOrAutomaticCommand,
  } = useAirConditioningControl({
    equipmentId,
  })

  return (
    <>
      <Loading
        mode='linear'
        sx={{
          opacity: isLoading ? 1 : 0,
          margin: 0,
          padding: 0,
          width: '100%',
          marginBottom: 1.5,
          '> span': {
            width: '100%',
          },
        }}
      />

      <Stack
        sx={{
          ...(isLoading && {
            opacity: '0.7',
            pointerEvents: 'none',
          }),
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={2}
          justifyContent={'space-around'}
        >
          <AirConditioningControlPanel
            ref={controlPanelRef}
            enabledActions={values}
            levelFanMode={levelFanMode}
            onIncreaseEvent={executeCoolingOrAutomaticCommand}
            onDecreaseEvent={executeCoolingOrAutomaticCommand}
            airConditioningFanMode={airConditioningFanMode}
          />

          <Stack gap={2}>
            <ActionItemAirConditioning onClick={onOf}>
              <ConnectAirIcon
                style={{
                  color: getColorIcon(AirConditioningActionEnum.ON),
                }}
                stroke={getColorIcon(AirConditioningActionEnum.ON)}
                height={36}
                width={36}
              />
            </ActionItemAirConditioning>
            <ActionItemAirConditioning
              disabled={!airConditioningIsOn}
              onClick={changeFanMode}
            >
              <ControlSpeedIcon
                style={{
                  color: getColorIcon(AirConditioningActionEnum.ON),
                }}
                stroke={getColorIcon(AirConditioningActionEnum.ON)}
                height={36}
                width={36}
              />
            </ActionItemAirConditioning>
            <ActionItemAirConditioning
              disabled={!airConditioningIsOn}
              onClick={switchOnVentilationMode}
            >
              <VentilateIcon
                style={{
                  color: getColorIcon(AirConditioningActionEnum.VENTILATE),
                }}
                stroke={getColorIcon(AirConditioningActionEnum.VENTILATE)}
                height={36}
                width={36}
              />
            </ActionItemAirConditioning>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
