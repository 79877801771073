import { httpClient } from 'services/api/httpClient'
import { EquipmentType } from 'types/equipment/equipment'

type ResponseType = EquipmentType & {
  id: number
}

export const getAllEquipmentTypeWaterTank = async (): Promise<
  ResponseType[]
> => {
  const { data } = await httpClient.get('equipment/all-water-tank')

  return (
    data?.map((item: EquipmentType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}
