import jwtDecode from 'jwt-decode'

export function getExpTokenDateHour(jwtToken: string) {
  const payloadData = jwtDecode(jwtToken) as { exp: number }

  const expDateToken = new Date((payloadData.exp - 15) * 1000) // Multiplicar por 1000 para converter para milissegundos

  // Formate a data e hora conforme desejado (por exemplo, em formato de string UTC)
  return expDateToken.toUTCString()
}
