import { Chip, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Modal'
import { Sensor } from 'components/Sensor'
import { ModalType } from 'types/modal'

interface Props extends ModalType {
  data: any
}

export function ViewAllSensors({ data, open, onClose }: Props) {
  const { t } = useTranslation('common')
  return (
    <>
      <Modal
        maxWidth={'sm'}
        title={t('sensorsLinked')}
        description={t('viewAllSensorsDescription')}
        open={open}
        onClose={onClose}
      >
        <Stack spacing={2} width={'100%'}>
          {data?.sensors?.map((sensor: any, index: number) => (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              spacing={1}
              key={index}
              width={'100%'}
            >
              <Stack>
                <Typography
                  variant={'body2'}
                  color={'textSecondary'}
                  fontWeight={'bold'}
                >
                  {sensor?.sensor?.name}
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Sensor
                  icon={sensor?.sensor?.type.toUpperCase() as any}
                  size='small'
                />
                {sensor?.sensor?.status ? (
                  <Chip
                    label={t('Online')}
                    color='success'
                    variant='outlined'
                    size='small'
                  />
                ) : (
                  <Chip
                    label={t('Offline')}
                    color='error'
                    variant='outlined'
                    size='small'
                  />
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Modal>
    </>
  )
}
