import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { Stack, Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'

import { useCheckboxSelection } from 'hooks/useCheckboxSelection'
import { useHeatAndHumidityMapStore } from 'store/heatAndHumidityMap.store'

import { MonitoringSidebarCard } from '../MonitoringSidebarCard'
import { TemperatureHumidityLabel } from './styles'

enum MapTypeEnum {
  HEAT,
  HUMIDITY,
}

export const TemperatureHumidityCard = () => {
  const theme = useTheme()
  const { updateShowHeatMap, updateShowHumidityMap } =
    useHeatAndHumidityMapStore()

  const mapsSelection = useCheckboxSelection<MapTypeEnum>()
  const { values, checkValueIsActive, changeValue } = mapsSelection

  const items = [
    {
      title: 'Mapa de calor',
      value: MapTypeEnum.HEAT,
    },
    {
      title: 'Mapa de umidade',
      value: MapTypeEnum.HUMIDITY,
    },
  ]

  useEffect(() => {
    updateShowHeatMap(checkValueIsActive(MapTypeEnum.HEAT))
    updateShowHumidityMap(checkValueIsActive(MapTypeEnum.HUMIDITY))
  }, [values])

  return (
    <MonitoringSidebarCard title='Temperatura e umidade'>
      <Stack gap={'8px'}>
        {items.map((item) => (
          <TemperatureHumidityLabel
            bgColor={theme.palette.secondary.main}
            borderColor={theme.palette.primary.main}
            key={item.title}
            onClick={() => changeValue(item.value)}
          >
            {checkValueIsActive(item.value) ? (
              <RemoveRedEyeOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
            <Typography>{item.title}</Typography>
          </TemperatureHumidityLabel>
        ))}
      </Stack>
    </MonitoringSidebarCard>
  )
}
