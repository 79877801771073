enum ModeEnum {
  OFF = 'OFF',
  FAN = 'FAN',
  AUTO = 'AUTO',
  COOL = 'COOL',
}

const findMode = (mode: string): ModeEnum => {
  return ModeEnum[mode as keyof typeof ModeEnum]
}

export { ModeEnum, findMode }
