import { httpClient } from 'services/api/httpClient'
import { EquipmentType } from 'types/equipment/equipment'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'

type NewType = PaginationType<EquipmentType>

export const listEquipments = async ({
  page,
  search,
  columnSort,
  columnName,
  numberPerPage,
}: PaginationParamType): Promise<NewType> => {
  const { data } = search?.length
    ? await httpClient.get<PaginationResponseType>('/equipment/findcustom', {
        params: {
          page: page - 1,
          columnSort,
          columnName,
          numberPerPage,
          isLinked: false,
          name: search,
        },
      })
    : await httpClient.get<PaginationResponseType>('/equipment', {
        params: {
          page: page - 1,
          columnSort,
          columnName,
          numberPerPage,
        },
      })

  return {
    items: data?.items ?? [],
    currentPage: data?.currentPage,
    perPage: data?.perPage,
    total: data?.total,
  }
}
