import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { TableActionType } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { listProfiles } from 'services/profiles/listProfiles'
import { PaginationType } from 'types/pagination'
import { ProfileType } from 'types/profile/profile'
import { ColumnType } from 'types/table/column'

import { profilesMapper } from '../mappers/profiles.mapper'

export function useProfilesPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const navigate = useNavigate()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [selectedProfile, setSelectedProfile] = useState<ProfileType | null>(
    null,
  )

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<ProfileType>,
    Error
  >({
    queryKey: ['profiles', page, search, orderBy],
    queryFn: () => listProfiles({ page, search, columnSort: orderBy }),
  })

  const profiles = profilesMapper(data?.items ?? [])

  const handleCreate = () => navigate('/access-control/profiles/create')

  const handleCancel = () => {
    setOpenForm(false)
    setOpenFormView(false)
    setSelectedProfile(null)
  }

  const handleEdit = (item: ProfileType) => {
    navigate('/access-control/profiles/edit', {
      state: {
        isView: false,
        profile: {
          id: item.id,
          name: item.name,
          active: item.active,
          permissions: item.permissions,
        },
      },
    })
  }

  const handleView = (item: ProfileType) => {
    navigate('/access-control/profiles/view', {
      state: {
        isView: true,
        profile: {
          id: item.id,
          name: item.name,
          active: item.active,
          permissions: item.permissions,
        },
      },
    })
  }

  const handleDelete = (item: ProfileType) => {
    httpDelete(`/profile-all/${item?.id?.value ?? item?.id}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'name',
      label: t('table.profiles.name'),
    },
    {
      id: 'countUser',
      label: t('table.profiles.linkedUsers'),
    },
    {
      id: 'formattedCreatedAt',
      label: t('table.profiles.createdAt'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
  ]

  const tableActions: TableActionType<ProfileType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.ACC_PROFILE,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (profile) => handleEdit(profile),
      module: ModuleEnum.ACC_PROFILE,
      permission: PermissionEnum.EDIT,
    },
    // {
    //   type: 'delete',
    //   module: ModuleEnum.ACC_PROFILE,
    //   permission: PermissionEnum.DELETE,
    //   onClick: (row) =>
    //     sweetAlert({
    //       onSuccess: () => handleDelete(row),
    //     }),
    // },
  ]

  return {
    t,
    sweetAlert,
    openForm,
    openFormView,
    setSearch,
    columns,
    page,
    setPage,
    orderBy,
    setOrderBy,
    data,
    profiles,
    error,
    isLoading,
    isFetching,
    handleCancel,
    handleCreate,
    handleDelete,
    handleEdit,
    handleView,
    selectedProfile,
    tableActions,
  }
}
