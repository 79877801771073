import { Stack, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

import { DetailsBox, SmallBall } from './styles'

type Props = {
  label: string
  children?: ReactNode
  metaData: Array<{
    label: string
    value: string
  }>
}

export const NodeDetailing: FC<Props> = (props) => {
  return (
    <DetailsBox>
      <Stack
        gap={1}
        alignItems={'center'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Typography fontSize={10}>{props.label}</Typography>
        <SmallBall />
      </Stack>
      {props.children}
      <Stack gap={'2px'} marginTop={'6px'}>
        {props.metaData.map((data) => (
          <Typography key={data.label} fontSize={8} color={'#8e8e8e'}>
            {data.label}: {data.value}
          </Typography>
        ))}
      </Stack>
    </DetailsBox>
  )
}
