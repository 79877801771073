import { FanSpeedEnum } from 'core/enums/FanSpeedEnum'
import { ModeEnum } from 'core/enums/ModeEnum'

import { CommandType } from 'types/equipment/command'

import { httpClient } from './api/httpClient'

const baseUrl = 'control'

export type ParamsExecuteCommand = {
  equipmentId: string
  mode: ModeEnum
  fan?: FanSpeedEnum
  temperature?: number
}

const findByEquipment = (equipmentId: string) => {
  return httpClient.get(`${baseUrl}/equipment`, {
    params: {
      equipmentId,
    },
  })
}

const findLastCommandByEquipment = (equipmentId: string) => {
  return httpClient.get<CommandType>(`${baseUrl}/last-command/equipment`, {
    params: {
      equipmentId,
    },
  })
}

const executeCommand = (params: ParamsExecuteCommand) => {
  const { mode, equipmentId, temperature, fan } = params
  return httpClient.put(`${baseUrl}/execute-command`, null, {
    params: {
      mode,
      equipmentId,
      ...(fan && { fan }),
      ...(temperature && { temperature }),
    },
  })
}

export const ControllerArconService = {
  executeCommand,
  findByEquipment,
  findLastCommandByEquipment,
}
