import { create } from 'zustand'

import { DataCompanyType } from 'types/branch/branch'

type State = {
  maps: DataCompanyType['areas']
  setMaps: (values: DataCompanyType['areas']) => void
}

export const useMonitoringStore = create<State>((set) => ({
  maps: [],
  setMaps(maps) {
    set({
      maps,
    })
  },
}))
