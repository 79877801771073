import { Menu, MenuProps } from '@mui/material'

interface MyAccountModalRootProps extends Omit<MenuProps, 'children'> {
  children: React.ReactNode
  anchorEl: null | HTMLElement
}

export function MyAccountModalRoot({
  anchorEl,
  children,
  ...rest
}: MyAccountModalRootProps) {
  return (
    <Menu
      id='long-menu'
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      PaperProps={{
        style: {
          padding: 8,
          width: 'auto',
        },
      }}
      sx={{
        '& .MuiPaper-root': {
          background: (theme) => `${theme.palette.background.paper} !important`,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...rest}
    >
      {children}
    </Menu>
  )
}
