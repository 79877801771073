/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material'
import { findModule } from 'core/enums/Module'
import { Dispatch, SetStateAction } from 'react'
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CheckboxGroup } from 'components/Checkbox/CheckboxGroup'
import { Autocomplete } from 'components/Select/Autocomplete'
import { Textarea } from 'components/Textarea'
import { NotificationSettingFormType } from 'types/notification-setting'

import { SensorSelectType } from '../../FormParameterModal'

interface IFormStepUsersProps {
  isView?: boolean
  types?: {
    id: any
    name: string
  }[]

  actions?: {
    id: any
    name: string
  }[]
  modulesData: any
  userData: any
  control: Control<NotificationSettingFormType, any>
  watch: UseFormWatch<NotificationSettingFormType>
  setValue: UseFormSetValue<NotificationSettingFormType>
  errors?: FieldErrors<NotificationSettingFormType>
  clearErrors: UseFormClearErrors<NotificationSettingFormType>
  setSensorsSelected: Dispatch<SetStateAction<never[]>>
  sensorSelected: SensorSelectType[]
  sensors?: any[]
}

export const FormStepUsers = ({
  isView,
  actions,
  modulesData,
  types,
  userData,
  errors,
  clearErrors,
  sensors,
  control,
  setValue,
  watch,
}: IFormStepUsersProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='body2'>{t('input.typeNotification')}</Typography>

        <CheckboxGroup
          control={control}
          name='typeNotification'
          row
          options={
            [
              ...(types ?? []),
              { id: '5', name: String(t('input.call')) },
            ] ?? []
          }
          error={!!errors?.typeNotification}
          helperText={String(t(errors?.typeNotification?.message ?? ''))}
          isDisabled={isView}
        />
      </Grid>

      <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
        <Autocomplete
          name='users'
          placeholder={t('input.selectUsers')}
          options={
            userData?.data?.map((item: any) => ({
              value: item?.entityId?.value ?? item.id,
              label: item.name,
            })) ?? []
          }
          isMulti
          isDisabled={isView}
          closeMenuOnSelect={false}
          value={watch('users')}
          isClearable
          onChange={(newValue) => {
            clearErrors('users')
            setValue('users', (newValue as Array<{ value: string }>) ?? [])
          }}
          error={!!errors?.users}
          errorMessage={errors?.users?.message}
          required
        />
      </Grid>

      {/* TODO: trazer os dados de BTN_PROCEDURE e SENSOR do backend */}
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Autocomplete
          name='modules'
          options={[
            ...(modulesData?.map((item: any) => ({
              value: item?.name,
              label: findModule(item.name) ?? `-- ${item.name} --`,
            })) ?? []),
          ]}
          placeholder={t('input.selectModule')}
          control={control}
          error={!!errors?.modules}
          errorMessage={errors?.modules?.message}
          isClearable
          isMulti
          isDisabled={isView}
          closeMenuOnSelect={false}
          value={watch('modules')}
          onChange={(newValue) => {
            clearErrors('modules')
            setValue('modules', (newValue as Array<{ value: string }>) ?? [])
          }}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='body2'>{t('input.actions')}</Typography>
        <Box
          sx={{
            '& .MuiFormControl-root': {
              width: '100% !important',

              '& .MuiFormGroup-root': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',

                '& .MuiFormControlLabel-root': {
                  flex: '0 0 auto',
                },
              },
            },
          }}
        >
          <CheckboxGroup
            name='notificationAction'
            control={control}
            options={actions ?? []}
            error={!!errors?.notificationAction}
            helperText={String(t(errors?.notificationAction?.message ?? ''))}
          />
        </Box>
      </Grid>
      {watch('modules').some(
        (module) =>
          module.value === 'BTN_PROCEDURE' || module.value === 'SENSOR_DOOR',
      ) && (
        <>
          <Grid item xs={12}>
            <Autocomplete
              name='sensors'
              placeholder={t('input.selectSensors')}
              isMulti
              value={watch('sensors')}
              onChange={(values) => {
                clearErrors('sensors')
                setValue('sensors', (values as Array<{ value: string }>) ?? [])
              }}
              options={
                sensors?.map((sensor) => ({
                  label: sensor.name,
                  value: String(sensor.id),
                  type: sensor.type,
                })) ?? []
              }
              error={!!errors?.sensors}
              errorMessage={errors?.sensors?.message}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Textarea
          name='message'
          value={watch('message')}
          placeholder={t('input.message') ?? ''}
          control={control}
          minRows={3}
          maxLength={200}
          error={!!errors?.message}
          errorMessage={errors?.message?.message}
          disabled={isView}
          required
        />
      </Grid>
    </>
  )
}
