import { useEffect, useRef, useState } from 'react';

export function useDebounced(value: string , delay = 500) {
    const [debouncedValue, setDebouncedValue] = useState('');
    const timerRef = useRef(setTimeout(()=>{}));
  
    useEffect(() => {
      timerRef.current = setTimeout(() => setDebouncedValue(value), delay);
  
      return () => {
        clearTimeout(timerRef.current);
      };
    }, [value, delay]);
  
    return debouncedValue;
}