import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from 'reactflow'

import { IconButton } from 'components/Button/IconButton'
import { httpClient } from 'services/api/httpClient'

export default function CustomConnection({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const { setEdges, getEdge } = useReactFlow()
  const { t } = useTranslation('common')
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  async function deleteConnection() {
    try {
      const edge = getEdge(id)

      if (!edge?.data?.id?.value) {
        toast.error(t('map.connection.messageDeleteError'))
        return
      }

      await httpClient.delete(`linked-area/${edge?.data?.id?.value}`)

      setEdges((es) => es.filter((e) => e.id !== id))
    } catch (error) {
      toast.error(t('map.connection.messageDeleteError'))
    }
  }

  return (
    <>
      <path
        id={id}
        style={{
          ...style,
          strokeWidth: 4,
          stroke: '#9A47F6',
          zIndex: 4,
        }}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />

      <EdgeLabelRenderer>
        <IconButton
          variant='contained'
          size='small'
          iconName='delete'
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: 'all',
            zIndex: 1,
          }}
          sx={{
            minHeight: 14,
            minWidth: 14,
            '& > svg': {
              height: 9,
              width: 9,
            },
          }}
          onClick={deleteConnection}
        />
      </EdgeLabelRenderer>
    </>
  )
}
