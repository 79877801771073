import { httpClient } from 'services/api/httpClient'
import { CreateInvoiceContractType } from 'types/invoice/create-invoice-contract'

export const updateInvoiceContract = (
  id: number,
  data: CreateInvoiceContractType,
) => {
  return httpClient.put(`/contract/${id}`, data)

  const formData = new FormData()

  formData.append('regarding', data.regarding)
  formData.append('company', data.company)
  formData.append('responsible', data.responsible)
  formData.append('contact', data.contact)
  formData.append('serviceTime', data.serviceTime)
  formData.append('totalPayable', String(data.totalPayable))
  formData.append('expiration', data.expiration ?? '')
  formData.append('date', data.date ?? '')
  formData.append('file', data.pathImage ?? '')
  formData.append('status', String(data.status))

  httpClient.defaults.headers.common['Content-Type'] = 'multipart/form-data'

  return httpClient.put(`/contract/${id}`, formData)
}
