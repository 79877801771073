import i18next from 'i18next'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'

import { ENVIRONMENT } from 'constants/environment'
import enCommon from 'translations/en/common.json'
import ptCommon from 'translations/pt/common.json'

import { App } from './App'

const defaultLng = localStorage.getItem(
  `@${ENVIRONMENT.APP_NAME}:application.language`,
)

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: defaultLng ?? 'pt', // language to use
  resources: {
    en: {
      common: enCommon, // 'common' is our custom namespace
    },
    pt: {
      common: ptCommon,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
)
