import moment from 'moment'

import { Toggle } from 'components/Toggle'
import { ProfileType } from 'types/profile/profile'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { updateProfileStatus } from 'services/profiles/updateProfileStatus'

export function profilesMapper(profiles: ProfileType[]) {
  const queryClient = useQueryClient()
  const handleToggleStatus = async (id: any) => {
    try {
      await updateProfileStatus(id)
      queryClient.invalidateQueries('profiles')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }
  return profiles.map((item) => ({
    ...item,
    formattedCreatedAt: moment(item.creation).format('DD/MM/YYYY'),
    linkedUsers: 0,
    muiStatus: (
      <Toggle
        name='toggle'
        checked={item.active}
        // eslint-disable-next-line no-console
        onChange={() => handleToggleStatus(item?.id?.value)}
      />
    ),
  }))
}
