import * as yup from 'yup'

export const schemaIntervals = () => {
  return yup.object({
    intervals: yup.array().of(
      yup.object({
        name: yup.string().required(),
        hourInitial: yup.string().required(),
        hourFinal: yup.string().required(),
      }),
    ),
  })
}
