import { EquipmentTypeEnum } from '../core/enums/EquipmentTypeEnum'

export const typeEquipmentOptions: { id: any; name: string }[] = [
  { id: EquipmentTypeEnum.AIR_CONDITIONING, name: 'Ar-Condicionado' },
  { id: EquipmentTypeEnum.CONDENSER, name: 'Condensador' },
  { id: EquipmentTypeEnum.WATER_TANK, name: 'Caixa Dágua' },
  { id: EquipmentTypeEnum.COMPRESSOR_AIR, name: 'Compressor de ar' },
  { id: EquipmentTypeEnum.GENERATOR, name: 'Gerador' },
  { id: EquipmentTypeEnum.DEHUMIDIFIER, name: 'Desumidificador' },
  { id: EquipmentTypeEnum.EXHAUST, name: 'Exaustor' },
  { id: EquipmentTypeEnum.SPLITTER, name: 'Splitão' },
  { id: EquipmentTypeEnum.NOBREAK, name: 'Nobreak' },
  { id: EquipmentTypeEnum.FUEL_TANK, name: 'Tanque de combustível' },
  { id: EquipmentTypeEnum.NITROGEN_PLANT, name: 'Usina de nitrogênio' },
  { id: EquipmentTypeEnum.DRYBOX, name: 'Drybox' },
  { id: EquipmentTypeEnum.BTN_PANIC, name: 'Alerta e Botão de pânico' },
  { id: EquipmentTypeEnum.OPENING, name: 'Abertura' },
  { id: EquipmentTypeEnum.CONTROLLER, name: 'Controlador' },
  { id: EquipmentTypeEnum.OTHER, name: 'Outro' },
]

export enum TypeEquipmentEnum {
  AIR_CONDITIONING = 'Ar-condicionado',
  CONDENSER = 'Condensador',
  WATER_TANK = 'Caixa Dágua',
  COMPRESSOR_AIR = 'Compressor de ar',
  GENERATOR = 'Gerador',
  DEHUMIDIFIER = 'Desumidificador',
  EXHAUST = 'Exaustor',
  SPLITTER = 'Splitão',
  NOBREAK = 'Nobreak',
  FUEL_TANK = 'Tanque de combustível',
  NITROGEN_PLANT = 'Usina de nitrogênio',
  DRYBOX = 'Drybox',
  BTN_PANIC = 'Alerta e Botão de pânico',
  OPENING = 'Abertura',
  OTHER = 'Outro',
}

export const findNameTypeEquipment = (type: string) => {
  const enumValues = EquipmentTypeEnum[type as keyof typeof EquipmentTypeEnum]
  return typeEquipmentOptions.find((type) => type.id === enumValues)
}
