import { Box, Stack, Typography } from '@mui/material'
import { useMemo, useState } from 'react'

import { Icon, IconNamesProps } from 'components/Icon'
import { SidebarMapMonitoring } from 'pages/btnPanicAlert/components/SidebarMapMonitoring'

import { PanicMonitoringAreaSidebar } from './components/PanicMonitoringAreaSidebar'
import { AlertPanicScreen } from './components/screen/AlertPanicScreen'
import { UnitAreaScreen } from './components/screen/UnitAreaScreen'

type ComponentType = 'alert' | 'area' | 'map'

// TODO: Geolocalização temporariamente desabilitado
const links = [
  // {
  //  id: 1,
  //   title: 'Geolocalização',
  //   icon: 'pin',
  //   route: 'map',
  //   description:
  //     'Organiza a visualização dos botoes de panico de livre circulação',
  //   isActive: false,
  // },
  {
    id: 0,
    icon: 'area',
    title: 'Unidades & Areas',
    route: 'area',
    description:
      'Organiza a visualização dos botões de pânico por mapas de unidades',
    isActive: true,
  },
  {
    id: 1,
    icon: 'grid',
    title: 'Acionados & histórico',
    route: 'alert-panic',
    description:
      'Visualização rápida dos botões de pânico acionados e histórico de ações',
    isActive: false,
  },
]

const componentMount = {
  alert: { component: AlertPanicScreen },
  area: { component: UnitAreaScreen },
}

export function MonitoringPage() {
  const [cardLink, setCardLink] = useState(links)
  const [typeComponent, setTypeComponent] = useState<ComponentType>('area')

  const handleActive = (id: number) => {
    setCardLink(
      cardLink.map((card) =>
        card.id === id
          ? { ...card, isActive: true }
          : { ...card, isActive: false },
      ),
    )
  }

  const handleCallComponent = (route: string) => {
    if (route === 'alert-panic') {
      setTypeComponent('alert')
      return
    }
    setTypeComponent(route as ComponentType)
  }

  const Component =
    componentMount[typeComponent as keyof typeof componentMount].component

  return (
    <Stack
      direction='row'
      sx={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 104px)',
        overflow: 'auto',
      }}
    >
      <SidebarMapMonitoring>
        {cardLink.map((link) => (
          <Stack
            key={link.id}
            direction={'row'}
            spacing={2}
            justifyContent={'left'}
            alignItems={'center'}
            p={1}
            onClick={() => {
              handleCallComponent(link.route)
              handleActive(link.id)
            }}
            sx={{
              backgroundColor: link.isActive ? '#66009d50' : 'none',
              cursor: 'pointer',
              borderRadius: 2,
              borderWidth: link.isActive ? 2 : 1,
              borderColor: `${
                link.isActive ? '#66009D' : 'rgba(204,204,204,0.09)'
              }`,
              borderStyle: 'solid',
              '&:hover': {
                backgroundColor: 'rgba(77,39,131,0.09)',
              },
            }}
          >
            <Box>
              <Icon name={link.icon as IconNamesProps} />
            </Box>
            <Box>
              <Typography
                variant={'subtitle1'}
                sx={{
                  fontSize: 14,
                  fontWeight: 900,
                }}
              >
                {link.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: 10,
                }}
              >
                {link.description}
              </Typography>
            </Box>
          </Stack>
        ))}
        {typeComponent === 'area' && <PanicMonitoringAreaSidebar />}
      </SidebarMapMonitoring>

      <Stack
        sx={{
          width: '100%',
          height: 'calc(100vh - 104px)',
          overflowY: 'scroll',
          paddingRight: '8px',
        }}
      >
        <Component />
      </Stack>
    </Stack>
  )
}
