import { Check } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

import authImg from 'assets/images/auth.svg'
import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'

import { useForgotPassword } from './hooks/useForgotPassword'

export function ForgotPasswordPage() {
  const { control, errors, handleSubmit, onSubmit, t, navigate, emailSent } =
    useForgotPassword()
  return (
    <Stack
      width='100%'
      direction={'row'}
      justifyContent={['center', 'space-around']}
      alignItems={'center'}
    >
      {!emailSent && (
        <Box
          sx={{
            display: ['none', 'flex'],
            minWidth: 350,
            minHeight: 400,
            background: `url(${authImg})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}

      <Stack
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        spacing={5}
        sx={{
          padding: [6],
          border: (theme) => [
            'none',
            `1px solid ${theme.palette.secondary.light}`,
          ],
          background: (theme) => [
            'transparent',
            theme.palette.background.default,
          ],
          borderRadius: 1,
          width: ['100%', 500],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!emailSent ? (
          <Stack spacing={5} width='100%' alignItems={'flex-start'}>
            <IconButton
              iconName='chevronLeft'
              variant='outlined'
              onClick={() => navigate('/login')}
            />

            <Stack>
              <Typography variant='h1'>{t('enterYourEmail')}</Typography>
              <Typography variant='body2'>{t('sendLink')}</Typography>
            </Stack>

            <Stack spacing={3} width='100%'>
              <Input
                name='email'
                label={t('input.email')}
                type='email'
                control={control}
                error={!!errors?.email}
                errorMessage={errors?.email?.message}
                required
              />
            </Stack>

            <Button variant='contained' fullWidth type='submit'>
              {t('button.title.send')}
            </Button>
          </Stack>
        ) : (
          <Stack
            spacing={5}
            width='100%'
            alignItems={'center'}
            textAlign={'center'}
          >
            <Stack
              sx={{
                height: 44,
                width: 44,
                borderRadius: 22,
                background: (theme) => theme.palette.primary.main,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Check fontSize='large' />
            </Stack>

            <Stack>
              <Typography variant='h1'>{t('emailSuccessfullySent')}</Typography>
              <Typography variant='body2'>{t('checkYourInbox')}</Typography>
            </Stack>

            <Button
              variant='contained'
              fullWidth
              onClick={() => navigate('/login')}
              sx={{
                textTransform: 'initial',
              }}
            >
              {t('button.title.backToLogin')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
