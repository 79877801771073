import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'

import OpeningIcon from 'assets/icons/equipment-icons/abertura.svg'
import AirConditioningIcon from 'assets/icons/equipment-icons/air-conditioning.svg'
import BtnPanicIcon from 'assets/icons/equipment-icons/botao-de-panico.svg'
import CompressorIcon from 'assets/icons/equipment-icons/compressor.svg'
import CondenserIcon from 'assets/icons/equipment-icons/condenser.svg'
import DehumidifierIcon from 'assets/icons/equipment-icons/desumidificador.svg'
import DryboxIcon from 'assets/icons/equipment-icons/drybox.svg'
import ExhaustIcon from 'assets/icons/equipment-icons/exaustor.svg'
import FuelTankIcon from 'assets/icons/equipment-icons/fuel-tank.svg'
import GeneratorIcon from 'assets/icons/equipment-icons/gerador.svg'
import NitrogenPlantIcon from 'assets/icons/equipment-icons/nitrogen-plant.svg'
import NobreakIcon from 'assets/icons/equipment-icons/nobreak.svg'
import OtherIcon from 'assets/icons/equipment-icons/other.svg'
import SplitIcon from 'assets/icons/equipment-icons/split.svg'
import WaterTankIcon from 'assets/icons/equipment-icons/water-tank.svg'

export const getDescriptionByEquipmentType = (type: EquipmentTypeEnum) => {
  const descriptions = {
    [EquipmentTypeEnum.WATER_TANK]: 'Caixa d’água',
    [EquipmentTypeEnum.COMPRESSOR_AIR]: 'Compressor',
    [EquipmentTypeEnum.CONDENSER]: 'Condensador',
    [EquipmentTypeEnum.DRYBOX]: 'Drybox',
    [EquipmentTypeEnum.NOBREAK]: 'Nobreak',
    [EquipmentTypeEnum.SPLITTER]: 'Splitão',
    [EquipmentTypeEnum.FUEL_TANK]: 'Tanque de combustível',
    [EquipmentTypeEnum.NITROGEN_PLANT]: 'Usina nitrogênio',
    [EquipmentTypeEnum.OTHER]: 'Outro',
    [EquipmentTypeEnum.ALL]: 'Todos',
    [EquipmentTypeEnum.AIR_CONDITIONING]: 'Ar-Condicionado',
    [EquipmentTypeEnum.BTN_PANIC]: 'Botão do pânico',
    [EquipmentTypeEnum.GENERATOR]: 'Gerador',
    [EquipmentTypeEnum.DEHUMIDIFIER]: 'Desumidificador',
    [EquipmentTypeEnum.EXHAUST]: 'Exaustor',
    [EquipmentTypeEnum.OPENING]: 'Abertura',
    [EquipmentTypeEnum.CONTROLLER]: 'Controlador',
  }

  return descriptions[type as keyof typeof descriptions]
}

export const getIconByEquipmentType = (type: EquipmentTypeEnum) => {
  const icons = {
    [EquipmentTypeEnum.WATER_TANK]: WaterTankIcon,
    [EquipmentTypeEnum.COMPRESSOR_AIR]: CompressorIcon,
    [EquipmentTypeEnum.CONDENSER]: CondenserIcon,
    [EquipmentTypeEnum.DRYBOX]: DryboxIcon,
    [EquipmentTypeEnum.NOBREAK]: NobreakIcon,
    [EquipmentTypeEnum.SPLITTER]: SplitIcon,
    [EquipmentTypeEnum.FUEL_TANK]: FuelTankIcon,
    [EquipmentTypeEnum.NITROGEN_PLANT]: NitrogenPlantIcon,
    [EquipmentTypeEnum.OTHER]: OtherIcon,
    [EquipmentTypeEnum.ALL]: OtherIcon,
    [EquipmentTypeEnum.AIR_CONDITIONING]: AirConditioningIcon,
    [EquipmentTypeEnum.BTN_PANIC]: BtnPanicIcon,
    [EquipmentTypeEnum.DEHUMIDIFIER]: DehumidifierIcon,
    [EquipmentTypeEnum.GENERATOR]: GeneratorIcon,
    [EquipmentTypeEnum.EXHAUST]: ExhaustIcon,
    [EquipmentTypeEnum.OPENING]: OpeningIcon,
    [EquipmentTypeEnum.CONTROLLER]: AirConditioningIcon,
  }

  return icons[type as keyof typeof icons]
}
