import { Box } from '@mui/material'

import minhaContaImg from 'assets/images/prints/minha-conta.png'
import { Icon } from 'components/Icon'

export function MyAccountContent() {
  return (
    <>
      <Box
        component='img'
        src={minhaContaImg}
        sx={{
          borderRadius: 1,
          width: 'auto',
        }}
      />
      <p>
        Na opção no cabeçalho, canto superior direito{' '}
        <Icon name='personOutline' />, é disponibilizada a opção de Minha Conta
        e Editar Conta , onde o usuário poderá realizar mudanças no nome,
        contato, login, cargo, foto, não podendo editar o seu e-mail e perfil,
        onde só poderá ser mudado no Módulo de Usuário pelos usuários com perfil
        de Administradores.
      </p>

      <p>
        <strong>Alterar Senha</strong>
      </p>

      <p>
        Para realizar a mudança de senha dentro do sistema, clique na opção
        “Trocar minha Senha” dentro de “Minha Conta”. Digite a senha atual,
        digite a nova senha e repita. Um e-mail será enviado para o e-mail
        cadastrado para confirmar a troca de senha, só depois da confirmação de
        troca de senha que o sistema validará e realizará a mudança.
      </p>
    </>
  )
}
