import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { getOrderServices } from 'services/order-service/getOrderServices'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { IOrderService } from '../../core/interfaces/order-service/IOrderService'
import { FormClientModal } from './components/FormClientModal'
import { clientsMapper } from './mappers/client.mapper'

export function ClientPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [orderService, setOrderService] = useState<IOrderService | null>(null)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState(true)
  const [orderBy, setOrderBy] = useState('nameEquipment')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<IOrderService>,
    Error
  >({
    queryKey: ['clients', page, search, orderBy],
    queryFn: () => getOrderServices(page, search, orderBy),
  })

  const sensors = clientsMapper(data?.results ?? [])

  const handleCreate = () => setOpenForm(true)

  const handleCancel = () => {
    setOpenForm(false)
    setOpenFormView(false)
    setOrderService(null)
  }

  const handleEdit = (item: IOrderService) => {
    setOrderService(item)
    handleCreate()
  }

  const handleView = (item: IOrderService) => {
    setOrderService(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: IOrderService) => {
    httpDelete(`/order-service/${item.entityId?.value}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'photo',
      label: t('table.client.photo'),
    },
    {
      id: 'date',
      label: t('table.client.dateOccurrence'),
    },
    {
      id: 'nameEquipment',
      label: t('table.client.equipment'),
    },
    {
      id: 'location',
      label: t('table.client.location'),
    },
    {
      id: 'serialNumber',
      label: t('table.client.serialNumber'),
    },
    {
      id: 'typeFormatted',
      label: t('table.client.type'),
    },
    {
      id: 'shortDescription',
      label: t('table.client.description'),
    },
    {
      id: 'status',
      label: 'Status',
    },
  ]

  return (
    <>
      <HeaderAction
        onCreate={handleCreate}
        onSearch={(search) => setSearch(search)}
        module={ModuleEnum.CLI_CLIENT}
        permission={PermissionEnum.CREATE}
      />

      <Table<IOrderService>
        columns={columns}
        rows={sensors}
        order={order ? 'ASC' : 'DESC'}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrder((state) => (newOrderBy === orderBy ? !state : true))
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={[
          {
            type: 'show',
            onClick: (row) => handleView(row),
            module: ModuleEnum.CLI_CLIENT,
            permission: PermissionEnum.VIEW,
          },
          {
            type: 'edit',
            onClick: (row) => handleEdit(row),
            module: ModuleEnum.CLI_CLIENT,
            permission: PermissionEnum.EDIT,
          },
          {
            type: 'delete',
            module: ModuleEnum.CLI_CLIENT,
            permission: PermissionEnum.DELETE,
            onClick: (row) =>
              sweetAlert({
                onSuccess: () => handleDelete(row),
              }),
          },
        ]}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView) && (
        <FormClientModal
          data={orderService}
          isView={openFormView}
          open={openForm || openFormView}
          onClose={handleCancel}
        />
      )}
    </>
  )
}
