import dayjs from 'dayjs'

export function getTimesOfDay(date: Date | null, format?: string) {
  const value = dayjs(date)

  const start = value.startOf('day')
  const end = value.endOf('day')

  const dates = []
  dates.push(start)
  Array(23)
    .fill(null)
    .forEach((_, index) => {
      const newDate = start.add(index + 1, 'hour')

      if (
        newDate.isBetween(start, end, null, '[]') ||
        newDate.isSame(end, 'hour')
      ) {
        dates.push(newDate)
      }
    })

  if (format) {
    return dates.map((item) => item.format(format))
  }

  return dates
}
