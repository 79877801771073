/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'
import dayjs from 'dayjs'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { createGradient } from 'helpers/createGradient'
import { formatNumbersDashboard } from 'helpers/formatNumbersDashboard'
import { getMonthDays } from 'helpers/getMonthDays'
import { getWeekDays } from 'helpers/getWeekDays'
import { httpClient } from 'services/api/httpClient'

interface DataItem {
  id: {
    value: string
  }
  monthYear: string
  consumption: number
  consumptionDays: number
  generalCost: number
  status: boolean
  dueDate: string
  pathFile: null
  goal: number
  totalPayment: null
  generalCostSewerage: number
}

interface DailyTotals {
  date: string
  totalGoal: number
  totalGeneralCost: number
  totalGeneralCostSewerage: number
  totalConsumption: number
  totalPayment: number
}

interface MonthlyTotals {
  month: string
  totalGoal: number
  totalGeneralCost: number
  totalGeneralCostSewerage: number
  totalConsumption: number
  totalPayment: number
}

const colors = [
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
]

interface Props {
  isPreview?: boolean
}

export function useGeneralConsumptionChart(props: Props) {
  const { isPreview } = props

  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')

  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['dashboard-water-general-consumption', startDate, endDate],
    queryFn: () =>
      httpClient.get('dashboard-water/general-consumption', {
        params: {
          startDate,
          endDate,
        },
      }),
  })

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const isCubicMetersAxis = context.dataset.yAxisID === 'y2'
            const key = isCubicMetersAxis ? 'prefix' : 'suffix'
            const { CUBIC_METERS, REAL } = DashboardTooltipSuffixEnum
            const value = isCubicMetersAxis ? REAL : CUBIC_METERS
            return formatNumbersDashboard({ context, [key]: value })
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return value + ' m³'
          },
        },
      },
      y2: {
        position: 'right',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return 'R$ ' + value
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  const getLabels = useCallback(() => {
    if (period === 'year') {
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    return getWeekDays(date, 'DD/MMM')
  }, [period, date])

  function calculateDailyTotals(data: DataItem[]): DailyTotals[] {
    const dailyTotals: DailyTotals[] = []
    const startDate = moment(date).startOf('month') // Obtém o primeiro dia do mês atual
    const endDate = moment(date).endOf('month') // Obtém o último dia do mês atual

    // Preenche o array dailyTotals com todos os dias do mês
    for (
      let date = startDate.clone();
      date.isSameOrBefore(endDate);
      date.add(1, 'day')
    ) {
      const dateString = date.format('YYYY-MM-DD')
      dailyTotals.push({
        date: dateString,
        totalGoal: 0,
        totalGeneralCost: 0,
        totalGeneralCostSewerage: 0,
        totalConsumption: 0,
        totalPayment: 0,
      })
    }

    for (const item of data) {
      const date = moment(item.monthYear).format('YYYY-MM-DD')

      const existingItem = dailyTotals.find((x) => x.date === date)

      if (existingItem) {
        existingItem.totalGoal += item.goal
        existingItem.totalGeneralCost += item.generalCost
        existingItem.totalGeneralCostSewerage += item.generalCostSewerage
        existingItem.totalConsumption += item.consumption
        existingItem.totalPayment += item.totalPayment ?? 0
      }
    }

    return dailyTotals
  }

  function calculateMonthlyTotals(data: DataItem[]): MonthlyTotals[] {
    const monthlyTotals: MonthlyTotals[] = []
    const year = moment(date).year() // Obtém o ano atual

    // Inicializa o array monthlyTotals com os meses do ano
    for (let month = 1; month <= 12; month++) {
      const monthString = moment()
        .month(month - 1)
        .year(year)
        .format('YYYY-MM')
      monthlyTotals.push({
        month: monthString,
        totalGoal: 0,
        totalGeneralCost: 0,
        totalGeneralCostSewerage: 0,
        totalConsumption: 0,
        totalPayment: 0,
      })
    }

    for (const item of data) {
      const month = moment(item.monthYear).format('YYYY-MM')

      const existingItem = monthlyTotals.find((x) => x.month === month)

      if (existingItem) {
        existingItem.totalGoal += item.goal
        existingItem.totalGeneralCost += item.generalCost
        existingItem.totalGeneralCostSewerage += item.generalCostSewerage
        existingItem.totalConsumption += item.consumption
        existingItem.totalPayment += item.totalPayment ?? 0
      }
    }

    return monthlyTotals
  }

  const getData = (type: keyof Omit<MonthlyTotals, 'month'>) => {
    if (period === 'year') {
      return calculateMonthlyTotals(data?.data?.items ?? data?.data ?? [])?.map(
        (item) => item[type],
      )
    }

    return calculateDailyTotals(data?.data?.items ?? data?.data ?? [])?.map(
      (item) => item[type],
    )
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()

    const chartData = {
      labels,
      datasets: [
        {
          type: 'line' as const,
          label: t('chart.water.goal'),
          data: getData('totalGoal'),
          yAxisID: 'y2',
          // data: [
          //   1600, 1600, 1600, 1600, 1600, 1600, 1600, 1600, 1600, 1600, 1600,
          //   1600,
          // ],
          backgroundColor: 'transparent',
          borderColor: '#5861FA',
        },
        {
          type: 'line' as const,
          label: t('chart.water.overallCostOfWater'),
          data: getData('totalGeneralCost'),
          yAxisID: 'y2',
          // data: Array(labels.length)
          //   .fill(null)
          //   .map(() => Math.floor(Math.random() * 2000)),
          backgroundColor: 'transparent',
          borderColor: '#B690FC',
        },
        {
          type: 'bar' as const,
          label: t('chart.water.consumption'),
          data: getData('totalConsumption'),
          // data: Array(12)
          //   .fill(null)
          //   .map(() => Math.floor(Math.random() * 2000)),
          borderRadius: 20,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors[0],
          ),
        },
        {
          type: 'bar' as const,
          label: t('chart.water.invoice'),
          yAxisID: 'y2',
          data: getData('totalPayment'),
          // data: Array(12)
          //   .fill(null)
          //   .map(() => Math.floor(Math.random() * 2000)),
          borderRadius: 20,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors[1],
          ),
        },
      ],
    }

    setChartData(chartData)
  }, [i18n.language, date, getLabels, data?.data?.items, data?.data])

  return {
    chartData,
    setChartData,
    options,
    t,
    date,
    setDate,
    chartRef,
    isDesktop,
    period,
    setPeriod,
    isLoading,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
  }
}
