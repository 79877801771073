import { Stack, Typography } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'

import { createGradient } from 'helpers/createGradient'
import { SensorDataRecordInHourlyIntervals } from 'types/sensordata/sensor-data-hourly'

import {
  CircleRange,
  ConsumptionRange,
  ConsumptionRangeWrapper,
} from './styles'

type Props = {
  averageTemperature: number
  data: SensorDataRecordInHourlyIntervals[]
}

const ranges = [
  {
    label: 'BOM',
    color: '#689F20',
    range: [
      {
        min: 18,
        max: 24,
      },
    ],
  },
  {
    label: 'MODERADO',
    color: '#F9D47F',
    range: [
      {
        min: 15,
        max: 17,
      },
      {
        min: 25,
        max: 27,
      },
    ],
  },
  {
    label: 'ATENÇÃO',
    color: '#FF8F08',
    range: [
      {
        min: 12,
        max: 14,
      },
      {
        min: 28,
        max: 32,
      },
    ],
  },
  {
    label: 'PREJUDICIAL',
    color: '#FF2929',
    range: [
      {
        min: 9,
        max: 11,
      },
      {
        min: 33,
        max: 37,
      },
    ],
  },
  {
    label: 'MUITO PREJUDICIAL',
    color: '#9D154E',
    range: [
      {
        min: 0,
        max: 8,
      },
      {
        min: 38,
        max: 40,
      },
    ],
  },
]

const rangeRiskToLife = {
  color: '#79211C',
  label: 'Risco à vida',
}

export const ConsumptionGraph: FC<Props> = ({
  averageTemperature,
  ...props
}) => {
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })

  const currentRange = useMemo(() => {
    if (averageTemperature < 0 || averageTemperature > 40) {
      return rangeRiskToLife
    }

    return ranges.find((row) =>
      row.range.some(
        ({ min, max }) =>
          averageTemperature >= min && averageTemperature <= max,
      ),
    )
  }, [averageTemperature])

  const optionsChart: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        tension: 0.7,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => `${value}ºC`,
        },
      },
    },
    plugins: {
      tooltip: {
        boxPadding: 2,
        callbacks: {
          labelColor: function () {
            return {
              borderColor: '#3562ff',
              backgroundColor: '#fff',
              borderWidth: 2,
              borderRadius: 6,
            }
          },
          label: (context) => {
            return `${context.raw}ºC`
          },
        },
      },
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      title: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const { data, labels } = props.data.reduce(
      (acumulator, row) => ({
        data: acumulator.data.concat(row.value),
        labels: acumulator.labels.concat(`${row.creationTime}hr`),
      }),
      { labels: [] as string[], data: [] as number[] },
    )

    setChartData({
      labels,
      datasets: [
        {
          fill: true,
          type: 'line' as const,
          label: 'Consumo',
          showLine: false,
          data,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            {
              start: '#161b47',
              end: '#0c0c25',
            },
            0.85,
          ),
          pointBackgroundColor: '#e8dcdc',
        },
      ],
    })
  }, [props.data])

  return (
    <>
      <ConsumptionRangeWrapper>
        {ranges.map((range) => (
          <ConsumptionRange
            indicatorPercentage={50}
            indicator={currentRange?.label === range.label}
            key={range.label}
            bgColor={range.color}
          />
        ))}
      </ConsumptionRangeWrapper>

      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        gap={'4px'}
        marginBottom={1.5}
      >
        {ranges.map((range) => (
          <Stack
            key={range.color}
            flexDirection={'row'}
            gap={'2px'}
            alignItems={'center'}
          >
            <CircleRange borderColor={range.color} />
            <Typography fontSize={10} color={'text.primary'}>
              {range.label}
            </Typography>
          </Stack>
        ))}
      </Stack>

      <Chart
        type='line'
        ref={chartRef}
        options={optionsChart}
        data={chartData}
        height={120}
      />
    </>
  )
}
