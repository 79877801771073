import { AirConditioningActionEnum } from 'core/enums/AirConditioningActionEnum'
import { AirConditioningFanModeEnum } from 'core/enums/AirConditioningFanModeEnum'
import { FanSpeedEnum } from 'core/enums/FanSpeedEnum'
import { ModeEnum } from 'core/enums/ModeEnum'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { RefAirConditioningControlPanel } from 'components/AirConditioningControl/AirConditioningControlPanel'
import { useCheckboxSelection } from 'hooks/useCheckboxSelection'
import {
  ControllerArconService,
  ParamsExecuteCommand,
} from 'services/controller-arcon.service'

type Params = {
  equipmentId?: string
}

export const useAirConditioningControl = ({ equipmentId }: Params) => {
  const [levelFanMode, setLevelFanMode] = useState(1)
  const controlPanelRef = useRef<RefAirConditioningControlPanel>(null)
  const [isLoadingRunCommand, setIsLoadingRunCommand] = useState(false)
  const [isLoadingStartCommand, setIsLoadingStartCommand] = useState(false)
  const [airConditioningFanMode, setAirConditioningFanMode] = useState(
    AirConditioningFanModeEnum.AUTO,
  )

  const getTemperature = () => controlPanelRef.current?.getTemperature() ?? 0

  const { values, setValues, changeValue, checkValueIsActive } =
    useCheckboxSelection<AirConditioningActionEnum>()

  const executeCoolingOrAutomaticCommand = (
    temperature: number,
    fanMode?: AirConditioningFanModeEnum,
  ) => {
    const { COOL } = AirConditioningFanModeEnum
    const newMode =
      (fanMode ?? airConditioningFanMode) === COOL
        ? ModeEnum.COOL
        : ModeEnum.AUTO

    executeCommand({
      temperature,
      mode: newMode,
      ...(newMode === ModeEnum.COOL && {
        fan: getCurrentFanSpeed(levelFanMode),
      }),
    })
  }

  const getCurrentFanSpeed = (index: number) => {
    const currentFanSpeed: Record<number, FanSpeedEnum> = {
      1: FanSpeedEnum.LOW,
      2: FanSpeedEnum.MEDIUM,
      3: FanSpeedEnum.HIGH,
    }

    return currentFanSpeed[index]
  }

  const toggleLevelFanMode = (mode: ModeEnum, temperature?: number) => {
    const newLevelFanMode = levelFanMode === 3 ? 1 : levelFanMode + 1

    setLevelFanMode(newLevelFanMode)
    executeCommand({
      mode,
      temperature,
      fan: getCurrentFanSpeed(newLevelFanMode) ?? FanSpeedEnum.LOW,
    })
  }

  const changeFanMode = () => {
    if (checkValueIsActive(AirConditioningActionEnum.VENTILATE)) {
      toggleLevelFanMode(ModeEnum.FAN)
    } else {
      const isCool = airConditioningFanMode === AirConditioningFanModeEnum.COOL

      if (isCool && levelFanMode < 3) {
        toggleLevelFanMode(ModeEnum.COOL, getTemperature())
      } else {
        const { COOL, AUTO } = AirConditioningFanModeEnum
        const newFanMode = isCool ? AUTO : COOL

        setLevelFanMode(1)
        setAirConditioningFanMode(newFanMode)
        executeCoolingOrAutomaticCommand(getTemperature(), newFanMode)
      }
    }
  }

  const executeCommand = (
    params: Omit<ParamsExecuteCommand, 'equipmentId'>,
  ) => {
    if (!equipmentId) return

    setIsLoadingRunCommand(true)
    ControllerArconService.executeCommand({
      ...params,
      equipmentId,
    })
      .catch(() => {
        toast.info('Command not found')
      })
      .finally(() => setIsLoadingRunCommand(false))
  }

  const turnOffAirConditioning = () => {
    setAirConditioningFanMode(AirConditioningFanModeEnum.AUTO)
    setValues([])
  }

  const turnOnAirConditioning = () => {
    changeValue(AirConditioningActionEnum.ON)
  }

  const onOf = () => {
    if (checkValueIsActive(AirConditioningActionEnum.ON)) {
      turnOffAirConditioning()
      executeCommand({
        mode: ModeEnum.OFF,
      })
    } else {
      turnOnAirConditioning()
      executeCommand({
        mode: ModeEnum.AUTO,
      })
    }
  }

  const switchOnVentilationMode = () => {
    if (checkValueIsActive(AirConditioningActionEnum.VENTILATE)) {
      setAirConditioningFanMode(AirConditioningFanModeEnum.COOL)
      executeCommand({
        mode: ModeEnum.COOL,
        temperature: getTemperature(),
      })
    } else {
      setAirConditioningFanMode(AirConditioningFanModeEnum.LEVEL)
      executeCommand({
        mode: ModeEnum.FAN,
        fan: FanSpeedEnum.LOW,
      })
    }

    changeValue(AirConditioningActionEnum.VENTILATE)
    setLevelFanMode(1)
  }

  const fetchStartCommand = () => {
    if (!equipmentId) return

    setIsLoadingStartCommand(true)
    ControllerArconService.findLastCommandByEquipment(equipmentId)
      .then(({ data }) => {
        const isOff = data.mode === ModeEnum.OFF
        const modes = [
          {
            value: AirConditioningActionEnum.ON,
            modes: [ModeEnum.AUTO, ModeEnum.COOL, ModeEnum.FAN],
          },
          {
            value: AirConditioningActionEnum.VENTILATE,
            modes: [ModeEnum.FAN],
          },
        ]

        const fanModes = {
          [ModeEnum.AUTO]: AirConditioningFanModeEnum.AUTO,
          [ModeEnum.COOL]: AirConditioningFanModeEnum.COOL,
          [ModeEnum.FAN]: AirConditioningFanModeEnum.LEVEL,
          [ModeEnum.OFF]: AirConditioningFanModeEnum.AUTO,
        }

        const levelsFanMode = {
          [FanSpeedEnum.LOW]: 1,
          [FanSpeedEnum.MEDIUM]: 2,
          [FanSpeedEnum.HIGH]: 3,
        }

        const currentFanMode = fanModes[data.mode]

        const currentModes = modes
          .filter((row) => row.modes.includes(data.mode))
          .map((row) => row.value)

        setLevelFanMode(levelsFanMode[data.fan] ?? 1)
        setValues(isOff ? [] : currentModes)
        setAirConditioningFanMode(currentFanMode)
        controlPanelRef.current?.setTemperature?.(
          data.temperature >= 16 ? data.temperature : 16,
        )
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('command initial not found')
      })
      .finally(() => setIsLoadingStartCommand(false))
  }

  useEffect(() => {
    fetchStartCommand()
  }, [])

  const airConditioningIsOn = checkValueIsActive(AirConditioningActionEnum.ON)

  const getColorIcon = (value: AirConditioningActionEnum) =>
    checkValueIsActive(value) ? '#00FF69' : '#B0B0B0'

  return {
    onOf,
    values,
    levelFanMode,
    getColorIcon,
    changeFanMode,
    controlPanelRef,
    airConditioningIsOn,
    airConditioningFanMode,
    switchOnVentilationMode,
    executeCoolingOrAutomaticCommand,
    isLoading: isLoadingRunCommand || isLoadingStartCommand,
  }
}
