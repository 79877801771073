import { IconButton } from 'components/Button/IconButton'

export function NotificacoesContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros das notificações. Essa
        informação será importante apenas para notificar os usuários. Para
        realizar o cadastro desse submódulo, é importante cadastrar
        anteriormente o nível (Configurações {'->'} Escalabilidade {'->'}{' '}
        Nível).
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>
        <strong>Cadastrar Notificações</strong>
      </p>

      <p>Para realizar o cadastro de uma nova notificação, basta:</p>
      <p>
        <strong>
          <strong>Passo 1:</strong>
        </strong>{' '}
        Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Notificações
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os campos que são: Tipo de
        notificação se é manual ou automática (notificações automáticas são
        aquelas geradas diretamente pelo Monitoramento de Sensores), status da
        notificação, nome da notificação, alerta de pre OS ou Chamado, tipo de
        notificação por sms, e-mail ou sistema, seleção de níveis e mensagem da
        notificação.
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>Editar Notificações</strong>
      </p>
      <p>Para realizar a edição de uma notificação, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Notificações
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a notificação que vai editar dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos que aparecerão: tipo de
        notificação se é manual ou automática (notificações automáticas são
        aquelas geradas diretamente pelo Monitoramento de Sensores), status da
        notificação, nome da notificação, alerta de pré OS ou Chamado, tipo de
        notificação por sms, e-mail ou sistema, seleção de níveis e mensagem da
        notificação.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>Excluir Notificações</strong>
      </p>

      <p>Para realizar a exclusão de uma notificação, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Notificações
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a notificação que vai excluir
        dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a notificação será permanentemente excluída
        do sistema.
      </p>
      <p>
        <strong>Visualizar Notificações</strong>
      </p>
      <p>Para realizar visualizar os dados de uma notificação, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Notificações
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a notificação que vai visualizar
        dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro. Esses dados serão mostrados sem poder realizar edição
        neles.
      </p>
    </>
  )
}
