/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartData, ChartOptions } from 'chart.js'
import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colorsDashboard } from 'constants/colors-dashboard'
import { createGradient } from 'helpers/createGradient'
import { formatNumbersDashboard } from 'helpers/formatNumbersDashboard'
import { generateIntermediateColors } from 'helpers/generateIntermediateColors'
import { getMonthDays } from 'helpers/getMonthDays'
import { getWeekDays } from 'helpers/getWeekDays'
import { httpClient } from 'services/api/httpClient'

import { GeneralConsumptionVSGoalChartType } from './@types/water.types'

const colors = [
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
]

interface Props {
  isPreview?: boolean
}

export function useGeneralConsumptionVSGoalChart(props: Props) {
  const { isPreview } = props

  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')

  const [generatingFile, setGeneratingFile] = useState(false)
  const [areas, setAreas] = useState<string[]>([])

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data, refetch } = useQuery({
    queryKey: ['general-consumption-vs-goal', date, period],
    queryFn: async () => {
      const response = await httpClient.get<GeneralConsumptionVSGoalChartType>(
        '/dashboard-water/general-consumption-vs-goal',
        {
          params: {
            year: dayjs(date).format('YYYY'),
            month: period === 'month' ? dayjs(date).format('MM') : undefined,
          },
        },
      )
      setAreas(response.data.areas)
      return response.data as GeneralConsumptionVSGoalChartType
    },
  })

  const { data: goals, refetch: refetchGoal } = useQuery({
    queryKey: 'goal-water',
    queryFn: async () => {
      return await httpClient.get('/goal/goal-water')
    },
    select: (data) => {
      return data?.data?.map((item: { area: string; goalWater: string }) => ({
        area: item.area,
        goal: Number(item?.goalWater),
      })) as { area: string; goal: number }[]
    },
  })

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
      refetchGoal()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return formatNumbersDashboard({
              context,
              suffix: DashboardTooltipSuffixEnum.CUBIC_METERS,
            })
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          display: isPreview ? false : true,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value + ' m³'
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  const getLabels = useCallback(() => {
    if (period === 'year') {
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    return getWeekDays(date, 'DD/MMM')
  }, [period, date])

  const bgColors =
    areas?.map((_, index) => generateIntermediateColors(colors, index)) ?? []

  const getDatasets = useCallback(
    (labels: string[] | dayjs.Dayjs[]) => {
      let datasets: any[] = []

      if (goals?.length) {
        datasets = [
          ...goals.map((goal, index) => ({
            type: 'line' as const,
            label: `${t('chart.water.goal')} ${goal?.area}`,
            data: labels.map(() => {
              return Number(goal?.goal || 0).toFixed(2)
            }),
            // backgroundColor: 'transparent',
            borderColor: colorsDashboard[index || 0]?.start,
          })),
        ] // gerar o gráfico de metas
      }

      if (period === 'year') {
        datasets = [
          ...(areas?.map((area, index) => ({
            label: area,
            data: labels.map((_, indexMonth) => {
              const dataItem = data?.datasets?.find(
                (item) =>
                  item.area === area &&
                  item.month === (indexMonth + 1).toString(),
              )

              return Number(dataItem?.value || 0).toFixed(2)
            }),
            borderRadius: 20,
            backgroundColor: createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              bgColors[index] ?? colors[index],
            ),
          })) || []),
          ...datasets,
        ] // gera o gráfico por ano
      } else if (period === 'month') {
        datasets = [
          ...(areas?.map((area, index) => ({
            label: area,
            data: labels.map((day) => {
              const dataItem = data?.datasets?.find(
                (item) => item.area === area && item.day === day.toString(),
              )

              return dataItem?.value || 0
            }),
            borderRadius: 20,
            backgroundColor: createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              bgColors[index] ?? colors[index],
            ),
          })) || []),
          ...datasets,
        ] // gera o gráfico por mês
      }

      return datasets
    },
    [period, date, data],
  )

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()
    const datasets = getDatasets(labels)

    const chartData = {
      labels,
      datasets,
    }

    setChartData(chartData) // gera o gráfico
  }, [i18n.language, getLabels, getDatasets])

  return {
    date,
    setDate,
    chartData,
    chartRef,
    t,
    options,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
  }
}
