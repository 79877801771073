import { Typography } from '@mui/material'
import { FC, memo } from 'react'
import { Handle, NodeResizer, Position } from 'reactflow'

type Props = {
  resizing?: boolean
  data: { label: string; resizable?: boolean } & Record<string, unknown>
}

export const ResizableNode: FC<Props> = memo(({ data }) => {
  const { resizable = true } = data ?? {}
  const opacityHandle = resizable ? 1 : 0

  return (
    <>
      <NodeResizer isVisible={resizable} minWidth={50} minHeight={30} />
      <Handle
        style={{ opacity: opacityHandle }}
        type='target'
        position={Position.Left}
      />
      <Typography
        sx={{
          color: '#FFFFFF',
          fontSize: 12,
          fontWeight: 600,
          maxWidth: `${data.width ? data.width : 50}`,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        title={data.label}
      >
        {data.label}
      </Typography>
      <Handle
        style={{ opacity: opacityHandle }}
        type='source'
        position={Position.Right}
      />
    </>
  )
})
