import * as yup from 'yup'

export const schemaCreateInvoiceEnergy = yup.object({
  monthYear: yup.string().required(),
  dueDate: yup.string().required(),
  consumption: yup.string().min(1).max(20).required(),
  consumptionDays: yup.string().min(1).max(2).required(),
  generalCost: yup.string().required(),
  goal: yup.string().required(),
})
