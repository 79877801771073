import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import { FC, useMemo } from 'react'

type Props = {
  barNumber: number
}

const SpeedWrapper = styled.div({
  width: 24,
  height: 10,
  display: 'grid',
  gap: 2.5,
  gridTemplateColumns: 'repeat(3, 1fr)',
  clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
})

export const SpeedProgress: FC<Props> = (props) => {
  const bars = useMemo(
    () => Array(props.barNumber).fill(null),
    [props.barNumber],
  )

  return (
    <SpeedWrapper>
      {bars.map((_, index) => (
        <Stack key={index} bgcolor={'#00FF69'} flex={1} />
      ))}
    </SpeedWrapper>
  )
}
