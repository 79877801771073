import { useTheme } from '@mui/material'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { FC } from 'react'

import { Loading } from 'components/Loading'
import { useSensorDetails } from 'pages/map/hook/useSensorDetails'

import { ModalContent } from '../../styles'
import { EquipmentMetaData } from '../EquipmentMetaData'

type Props = {
  sensorId: string
  sensorType: SensorTypeEnum
}

export const EquipmentSensorShowcase: FC<Props> = ({
  sensorId,
  sensorType,
}) => {
  const theme = useTheme()

  const { dataFormated, isLoading } = useSensorDetails({
    sensor: {
      id: sensorId,
      type: sensorType,
    },
  })

  return (
    <ModalContent
      padding='0'
      background={
        theme.palette.mode === 'light'
          ? theme.palette.background.paper
          : '#0C0C25'
      }
    >
      {isLoading ? (
        <Loading mode='circular' />
      ) : (
        <EquipmentMetaData sensorType={sensorType} data={dataFormated} />
      )}
    </ModalContent>
  )
}
