import { Checkbox, Stack, Typography } from '@mui/material'
import { FC } from 'react'

type Props = {
  title: string
  description?: string
  checked: boolean
  onChangeCheckBox: () => void
  isView?: boolean
  disabled?: boolean
}

export const ActionNotification: FC<Props> = (props) => {
  return (
    <Stack
      component='div'
      direction='row'
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        padding: '10px',
        borderRadius: '6px',
        marginBottom: '16px',
        display: 'flex',
        gap: '8px',
      }}
    >
      <Checkbox
        checked={props.checked}
        onClick={props.onChangeCheckBox}
        disabled={props.disabled}
      />
      <Stack>
        <Typography marginBottom={1} fontWeight={700}>
          {props.title}
        </Typography>
        <Typography fontWeight={400} fontSize={14}>
          {props.description}
        </Typography>
      </Stack>
    </Stack>
  )
}
