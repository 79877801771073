import { Warning } from '@mui/icons-material'
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import { Actions } from 'components/Actions'
import { IconButton } from 'components/Button/IconButton'
import { Loading } from 'components/Loading'
import { httpClientDownloadExcel } from 'helpers/httpClientDownloadExcel'
import { ModalSensorsHumidity } from 'pages/dashboard/components/list/ModalSensorsHumidity'
import { useRealTimeHumidityChart } from 'pages/dashboard/hooks/temperature/useRealTimeHumidityChart'

import { LinearHumidity } from '../../graphic/LinearHumidity'

interface Props {
  isPreview?: boolean
}

export function RealTimeHumidityChart(props: Props) {
  const { isPreview } = props

  const {
    t,
    items,
    setGeneratingFile,
    generatingFile,
    onOpenModal,
    onCloseModal,
    openModal,
    selectedArea,
    isLoadingHumidity,
  } = useRealTimeHumidityChart()

  return (
    <Box
      sx={{
        gridArea: 'bottom-right',
        borderRadius: 1,
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        overFlowY: 'hidden',
        minHeight: 200,
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        position: 'relative',
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>

      <Stack
        direction={['row']}
        justifyContent={['space-between']}
        alignItems={['center']}
        marginBottom={3}
      >
        <Typography
          variant='h2'
          fontSize='1.125rem'
          fontWeight='bold'
          whiteSpace={['normal', isPreview ? 'normal' : 'nowrap']}
        >
          {t('chart.temperature.realTimeHumidity')}
        </Typography>

        {!isPreview && (
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            spacing={2}
          >
            <Actions
              color='inherit'
              title='button.title.export'
              options={[
                {
                  type: 'excel',
                  action: () =>
                    httpClientDownloadExcel({
                      url: '/dashboard-temperature-and-humidity/humidity/export-excel',
                      fileName: 'umidade',
                      setGeneratingFile,
                    }),
                },
              ]}
            />
          </Stack>
        )}
      </Stack>

      {isLoadingHumidity ? (
        <Loading />
      ) : (
        <>
          {!items?.length && (
            <>
              <Stack
                width={'100%'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  flexDirection={'column'}
                  mb={3}
                >
                  <Warning color='disabled' fontSize='large' />
                  <Typography variant='subtitle1'>{t('noData')}</Typography>
                </Box>
              </Stack>
            </>
          )}

          <Grid container direction='row' spacing={2}>
            {items?.map((item) => (
              <Grid item key={item.id} xs={6} sx={{ mb: 1 }}>
                <Stack
                  direction={'row'}
                  spacing={2}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <LinearHumidity label={item.name} value={item.value} />
                  <IconButton
                    variant={'outlined'}
                    iconName={'show'}
                    onClick={() => onOpenModal(item)}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <ModalSensorsHumidity
        open={openModal}
        onClose={onCloseModal}
        data={selectedArea}
      />
    </Box>
  )
}
