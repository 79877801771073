import { Avatar, AvatarProps } from '@mui/material'

interface MyAccountItemAvatarProps extends AvatarProps {
  src?: string
}

export function MyAccountItemAvatar({
  src,
  ...rest
}: MyAccountItemAvatarProps) {
  return <Avatar src={src} sx={{ borderRadius: 1 }} {...rest} />
}
