import { FormControl, FormHelperText, Typography, alpha } from '@mui/material'
import Select, { StylesConfig } from 'react-select'
import makeAnimated from 'react-select/animated'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'

import { ErrorMessage } from 'components/Input/ErrorMessage'
import { ValidationError } from 'types/ValidationError'

import { useCustomTheme } from '../../../hooks/useCustomTheme'

const animatedComponents = makeAnimated()

interface Props extends StateManagerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  label?: string
  helperText?: string
  error?: boolean
  options: Array<{
    value: string | number
    label: string
    [x: string]: string | number | object
  }>
  errorMessage?: ValidationError | string
}

export function Autocomplete({
  options,
  helperText,
  errorMessage,
  ...rest
}: Props) {
  const { theme } = useCustomTheme()

  const colorStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      borderWidth: 2,
      minHeight: 44,
      borderColor: rest?.error
        ? theme.palette.error.main
        : theme.palette.primary.main,
      ':hover': {
        backgroundColor: theme.palette.secondary.light,
        borderColor: rest?.error
          ? theme.palette.error.main
          : theme.palette.primary.main,
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: theme.palette.background.paper,
      zIndex: 2,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? 'transparent'
          : isSelected
          ? theme.palette.background.default
          : isFocused
          ? theme.palette.secondary.light
          : 'transparent',
        color: isDisabled
          ? '#555567'
          : isSelected
          ? theme.palette.mode === 'dark'
            ? '#FFF'
            : '#000'
          : theme.palette.mode === 'dark'
          ? '#FFF'
          : '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? theme.palette.background.default
              : 'transparent'
            : undefined,
          color: isDisabled
            ? undefined
            : isSelected
            ? theme.palette.mode === 'dark'
              ? '#FFF'
              : '#000'
            : theme.palette.mode === 'dark'
            ? '#FFF'
            : '#000',
        },
      }
    },
    input: (styles) => ({
      ...styles,
      color: theme.palette.secondary.contrastText,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: alpha(theme.palette.secondary.contrastText, 0.8),
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme.palette.secondary.contrastText,
    }),
    multiValue: (styles) => ({
      ...styles,
      background: theme.palette.background.paper,
      '> div': {
        color: theme.palette.secondary.contrastText,
      },
    }),
  }

  return (
    <FormControl sx={{ minWidth: '100%' }} error={rest?.error}>
      {rest?.label && <Typography>{rest?.label}</Typography>}
      <Select
        closeMenuOnSelect
        components={animatedComponents}
        // defaultValue={[]}
        isMulti={false}
        options={options}
        styles={colorStyles}
        {...rest}
      />
      {(rest?.error || helperText) && (
        <FormHelperText>
          {errorMessage ? <ErrorMessage error={errorMessage} /> : helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
