/* eslint-disable @typescript-eslint/no-explicit-any */
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Toggle } from 'components/Toggle'
import { updateParameterStatus } from 'services/goalAndParameter/parameter/updateParameterStatus'
import { ParameterType } from 'types/goalAndParameter/parameter'

export function parameterMapper(data: ParameterType[]) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const handleToggleStatus = async (id: string) => {
    try {
      await updateParameterStatus(id)
      queryClient.invalidateQueries('parameter')
      toast.success(t('changeStatusSuccess'))
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  return data.map((item: ParameterType) => ({
    ...item,
    id: typeof item?.id !== 'number' ? item?.id?.value : item?.id,
    unitName: t(item.unit.name),
    company: item?.area?.mapImageCompany?.company.name ?? '-',
    formattedArea: item?.area?.mapImageCompany.areaName ?? '-',
    subarea: item?.area?.name ?? '-',
    sensor: item.sensors?.map((sensor, index) => {
      return (
        <Chip
          label={sensor.sensor?.name ?? '-'}
          size='small'
          sx={{
            px: 1,
            '&::before': {
              content: 'none',
            },
          }}
          key={index}
        />
      )
    }),
    muiStatus: (
      <Toggle
        name='toggle'
        checked={item.status}
        onChange={() =>
          handleToggleStatus(
            typeof item?.id !== 'number' ? item?.id?.value : item?.id,
          )
        }
      />
    ),
  }))
}
