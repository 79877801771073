export const gradientColors = {
  TEMPERATURE_HUMIDITY: 'linear-gradient(220deg, #e386d6 , #f877bd)',
  TEMPERATURE: 'linear-gradient(220deg, #4F7ED1 , #7ED1CD)',
  HUMIDITY: 'linear-gradient(220deg, #81FFB5 , #41B16F)',
  GAS: 'linear-gradient(220deg, #6A5EF5 , #A29BF9)',
  ENERGY: 'linear-gradient(220deg, #FFBA5E , #FFE375)',
  TEMP_HUM: 'linear-gradient(220deg, #e386d6 , #f877bd)',
  TEMP: 'linear-gradient(220deg, #4F7ED1 , #7ED1CD)',
  HUM: 'linear-gradient(220deg, #81FFB5 , #41B16F)',
  AIR: 'linear-gradient(220deg, #6A5EF5 , #A29BF9)',
  ELECTRIC: 'linear-gradient(220deg, #FFBA5E , #FFE375)',
  ELETRIC: 'linear-gradient(220deg, #FFBA5E , #FFE375)',
  WATER: 'linear-gradient(220deg, #90CFFC , #574AF9)',
  ERROR: 'linear-gradient(220deg, #DE4D53 , #c74349)',
  SENSOR: 'linear-gradient(220deg, #fe7c2d , #fd873f)',
  OPENING: 'linear-gradient(220deg, #fe7c2d , #fd873f)',
  BTN_PANIC: 'linear-gradient(220deg, #F24949 , #F78F83)',
}

export const colors = {
  TEMPERATURE_HUMIDITY: '#e386d6',
  TEMPERATURE: '#4F7ED1',
  HUMIDITY: '#81FFB5',
  GAS: '#6A5EF5',
  ENERGY: '#FFBA5E',
  TEMP_HUM: '#e386d6',
  TEMP: '#4F7ED1',
  HUM: '#81FFB5',
  AIR: '#6A5EF5',
  ELECTRIC: '#FFBA5E',
  ELETRIC: '#FFBA5E',
  WATER: '#90CFFC',
  ERROR: '#DE4D53',
  SENSOR: '#fe7c2d',
  OPENING: '#fe7c2d',
  BTN_PANIC: '#F24949',
}

export const sensorColors = {
  GRADIENT: gradientColors,
  COLORS: colors,
}
