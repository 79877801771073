import { IconButton } from 'components/Button/IconButton'

export function FaturasEnergiaContent() {
  return (
    <>
      <p>
        <strong>o Cadastrar Faturas de Energia</strong>
      </p>

      <p>Para realizar o cadastro de uma nova fatura de energia, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Faturas
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Energia”
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 6:</strong> Preencher os campos: mês de referência da
        fatura, o vencimento, consumo registrado pela concessionária, dias de
        consumo, custo geral de energia, a meta estipulada da empresa sobre o
        consumo de energia e o upload da fatura.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar.{' '}
      </p>
      <p>Importante: Esses dados afetam nos valores do dashboard de energia.</p>
      <p>
        <strong>o Editar Faturas de Energia</strong>
      </p>
      <p>Para realizar a edição de uma fatura de energia, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Faturas
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Energia”
      </p>
      <p>
        <strong>Passo 5:</strong> Selecionar a fatura de energia que vai editar
        dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 6:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 7:</strong> Edite os campos: mês de referência da fatura,
        o vencimento, consumo registrado pela concessionária, dias de consumo,
        custo geral de energia, a meta estipulada da empresa sobre o consumo de
        energia e o upload da fatura.
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>o Excluir Faturas de Energia</strong>
      </p>

      <p>Para realizar a exclusão de uma fatura de energia, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Faturas
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a fatura de energia que vai excluir
        dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a fatura de energia será permanentemente
        excluída do sistema.
      </p>
      <p>
        <strong>o Visualizar Faturas de Energia</strong>
      </p>
      <p>Para realizar visualizar os dados de uma fatura de energia, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Faturas
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a fatura de energia que vai
        visualizar dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, sendo eles: mês de referência da fatura, o vencimento,
        consumo registrado pela concessionária, dias de consumo, custo geral de
        energia, a meta estipulada da empresa sobre o consumo de energia e o
        upload da fatura.
      </p>
    </>
  )
}
