import { Stack } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { useMapSubarea } from 'store/map-subarea.store'

interface Props {
  cancel: () => void
  save: () => void
}

export const ActionsSubarea: FC<Props> = ({ cancel, save }) => {
  const { showActionsNewSubarea } = useMapSubarea()
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  return showActionsNewSubarea() ? (
    <Stack
      spacing={2}
      direction={'row'}
      justifyContent={'flex-end'}
      sx={{
        position: 'absolute',
        zIndex: 4,
        right: '0.75rem',
        top: '2.75rem',
      }}
    >
      <Button
        onClick={() => {
          sweetAlert({
            title: t('map.subArea.cancelEdition').toString(),
            text: t('map.subArea.message.textCancel').toString(),
            onSuccess: () => cancel(),
          })
        }}
        variant='outlined'
      >
        {t('button.title.cancel')}
      </Button>
      <Button onClick={save} type='submit' variant='contained'>
        {t('button.title.save')}
      </Button>
    </Stack>
  ) : null
}
