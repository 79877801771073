import { Grid } from '@mui/material'
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Select } from 'components/Select'
import { subareaType } from 'constants/subarea'
import { AreaService } from 'services/area/area.service'
import { findSubareaByAreaId } from 'services/area/getArea'
import { getCompany } from 'services/company/getCompany'
import { AreaType } from 'types/area/area'
import { CompanyType } from 'types/company/company'
import { CreateParameterType } from 'types/goalAndParameter/create-parameter'

import { AirConditionerCard } from '../AirConditionerCard'

type Props = {
  control: Control<CreateParameterType>
  watch: UseFormWatch<CreateParameterType>
  errors: FieldErrors
  isView?: boolean
}

export const CompanyAreaSubAreaForm = ({
  control,
  errors,
  watch,
  isView,
}: Props) => {
  const { t } = useTranslation('common')

  const { data: companies } = useQuery({
    queryKey: ['companies-air-step-first'],
    queryFn: () => getCompany(),
    select: (data) => data.results,
    refetchOnWindowFocus: true,
  })

  const { data: areas } = useQuery({
    queryKey: ['all-area-air-step-first', watch('company')],
    queryFn: () => AreaService.findAreaByCompanyId(String(watch('company'))),
    enabled: !!watch('company'),
    select: (data) =>
      data.map(
        (area: { mapImageCompanyID: { value: number }; area: string }) => ({
          id: area.mapImageCompanyID.value,
          name: area.area,
        }),
      ),
  })
  const { data: subareas } = useQuery({
    queryKey: ['all-subarea-air-step-first', watch('mapImage')],
    queryFn: () =>
      findSubareaByAreaId({
        mapImageCompanyId: String(watch('mapImage')),
        type: subareaType.SENSOR_PLANT,
      }),
    enabled: !!watch('mapImage'),
  })

  return (
    <Grid item xs={12}>
      <AirConditionerCard title={t('modal.titleAir')}>
        <Select
          name='company'
          label={t('input.company')}
          options={companies?.map((company: CompanyType) => ({
            id: String(company.id.value),
            name: company.name,
          }))}
          fullWidth
          disabled={isView}
          control={control}
          required
          sx={{ marginBottom: 2 }}
        />
        <Select
          name='mapImage'
          label={t('input.area')}
          options={areas?.map((area: any) => ({
            id: String(area.id),
            name: area.name,
          }))}
          fullWidth
          disabled={isView}
          control={control}
          required
          sx={{ marginBottom: 2 }}
        />
        <Select
          name='area'
          label={t('input.subarea')}
          options={subareas?.map((area: AreaType) => ({
            id: String(area.id.value),
            name: area.name,
          }))}
          error={!!errors?.area}
          errorMessage={errors?.area?.message}
          disabled={isView}
          control={control}
          required
          fullWidth
          sx={{ marginBottom: 2 }}
        />
      </AirConditionerCard>
    </Grid>
  )
}
