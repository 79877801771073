import { httpClient } from 'services/api/httpClient'
import { InvoiceContractType } from 'types/invoice/invoice-contract'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'

type NewType = PaginationType<InvoiceContractType>

export const listInvoiceContract = async ({
  page = 1,
  search = '',
  columnSort,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  const { data } = await httpClient.get<PaginationResponseType>(
    search?.length ? '/contract/findcustom' : 'contract',
    {
      params: {
        page: page - 1,
        columnSort,
        numberPerPage,
        ...(search?.length ? { filter: search } : {}),
      },
    },
  )

  return {
    items: data?.items ?? [],
    currentPage: data?.currentPage,
    perPage: data?.perPage,
    total: data?.total,
  }
}
