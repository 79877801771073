enum StatusOrderServiceEnum {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  IN_PROGRESS = 'IN_PROGRESS',
  REFUSED = 'REFUSED',
  NOT_SOLVED = 'NOT_SOLVED',
  FINISHED = 'FINISHED',
}

const findStatusOS = (status: string) => {
  return StatusOrderServiceEnum[status as keyof typeof StatusOrderServiceEnum]
}

function getKeyByValue(value: string): string {
  const indexOfS = Object.values(StatusOrderServiceEnum).indexOf(
    value as unknown as StatusOrderServiceEnum,
  )

  const key = Object.keys(StatusOrderServiceEnum)[indexOfS]

  return key
}

export { StatusOrderServiceEnum, findStatusOS, getKeyByValue }
