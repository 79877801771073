import { useTranslation } from 'react-i18next'

import { ValidationError } from 'types/ValidationError'

export interface ErrorMessageProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any | ValidationError | string
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      {typeof error === 'string' && t(error)}
      {typeof error === 'object' && error?.key && (
        <>{t(error?.key, error?.values)}</>
      )}
    </>
  )
}
