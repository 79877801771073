import { httpClient } from 'services/api/httpClient'
import { ProfileType } from 'types/profile/profile'

type ResponseType = ProfileType & {
  id: number
}

export const getAllProfiles = async (): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('profile-all/list')

  return (
    data?.map((item: ProfileType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}
