export enum typeEquipmentOrArCon {
  EQUIPMENT = 'EQUIPMENT',
  SENSOR = 'SENSOR',
  AIR_CONDITIONING_SENSOR = 'AIR_CONDITIONING_SENSOR',
  FEEDER = 'FEEDER',
  BTN_PANIC = 'BTN_PANIC',
  AIR_CONDITIONING = 'AIR_CONDITIONING',
}

export enum ordenateEquipment {
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  CONDENSER = 'CONDENSER',
  WATER_TANK = 'WATER_TANK',
  COMPRESSOR_AIR = 'COMPRESSOR_AIR',
  GENERATOR = 'GENERATOR',
  DEHUMIDIFIER = 'DEHUMIDIFIER',
  EXHAUST = 'EXHAUST',
  SPLITTER = 'SPLITTER',
  NOBREAK = 'NOBREAK',
  FUEL_TANK = 'FUEL_TANK',
  NITROGEN_PLANT = 'NITROGEN_PLANT',
  DRYBOX = 'DRYBOX',
  OPENING = 'OPENING',
  BTN_PANIC = 'BTN_PANIC',
  OTHER = 'OTHER',
}

export enum ordenateSensores {
  TEMP = 'TEMP',
  HUM = 'HUM',
  TEMP_HUM = 'TEMP_HUM',
  ELECTRIC = 'ELECTRIC',
  WATER = 'WATER',
  AIR = 'AIR',
  BTN_PANIC = 'BTN_PANIC',
  OPENING = 'OPENING',
}

export const typeEquipmentOrArConLabel = {
  [typeEquipmentOrArCon.FEEDER]: 'Equipamento Alimentador',
  [typeEquipmentOrArCon.EQUIPMENT]: 'Equipamento',
  [typeEquipmentOrArCon.SENSOR]: 'Sensor',
  [typeEquipmentOrArCon.AIR_CONDITIONING]: 'Ar condicionado',
  [typeEquipmentOrArCon.AIR_CONDITIONING_SENSOR]: 'Sensor',
  [typeEquipmentOrArCon.BTN_PANIC]: 'Alerta e Botão do Pânico',
}

export const listTypeEquipmentIcons = {
  [ordenateEquipment.OPENING]: 'openingIconSVG',
  [ordenateEquipment.AIR_CONDITIONING]: 'airConditioningSVG',
  [ordenateEquipment.BTN_PANIC]: 'btnPanicSVG',
  [ordenateEquipment.COMPRESSOR_AIR]: 'compressorSVG',
  [ordenateEquipment.CONDENSER]: 'condenserSVG',
  [ordenateEquipment.DEHUMIDIFIER]: 'dehumidifierSVG',
  [ordenateEquipment.DRYBOX]: 'dryBoxSVG',
  [ordenateEquipment.EXHAUST]: 'exhaustSVG',
  [ordenateEquipment.FUEL_TANK]: 'fuelTankSVG',
  [ordenateEquipment.GENERATOR]: 'generatorSVG',
  [ordenateEquipment.NITROGEN_PLANT]: 'nitrogenPlantSVG',
  [ordenateEquipment.NOBREAK]: 'noBreakSVG',
  [ordenateEquipment.OTHER]: 'otherSVG',
  [ordenateEquipment.SPLITTER]: 'splitSVG',
  [ordenateEquipment.WATER_TANK]: 'waterTankSVG',
}
