import { SelectChangeEvent, Stack, StackProps } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { Container } from 'components/Container'
import { Icon } from 'components/Icon'
import { Select } from 'components/Select'
import { ToggleTheme } from 'components/ToggleTheme'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { ENVIRONMENT } from 'constants/environment'
import { useMenu } from 'hooks/useMenu'
import { useAuthStore } from 'store/auth.store'

import { DefaultMenu } from '../DefaultMenu'
import { MyAccount } from '../MyAccount'
import { FormMyAccount } from '../MyAccount/FormMyAccount'
import { Navbar } from '../Navbar'
import { Notifications } from '../Notifications'
import { HeaderOptions } from './HeaderOptions'

type Props = StackProps & {
  marginBottom?: number
  paddingLeft?: number
}

export function Header(props: Props) {
  const { i18n, t } = useTranslation('common')
  const { menus, activeMenu } = useMenu()
  const { signOut, user } = useAuthStore()
  const queryClient = useQueryClient()

  const [openForm, setOpenForm] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleLanguage = (event: SelectChangeEvent<unknown>) => {
    const language = event.target.value as string
    i18n.changeLanguage(language)
    localStorage.setItem(
      `@${ENVIRONMENT.APP_NAME}:application.language`,
      language,
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseForm = () => {
    setOpenForm(false)
    setAnchorEl(null)
  }

  const handleLogout = () => {
    queryClient.clear()
    signOut()
  }

  return (
    <Stack
      component='header'
      direction='row'
      alignItems='center'
      sx={{
        minHeight: 104,
        borderBottom: (theme) => `2px solid ${theme.palette.secondary.light}`,
        marginBottom: props?.marginBottom ?? 3,
        paddingLeft: props?.paddingLeft ?? 0,
      }}
    >
      <Container>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={props?.sx}
        >
          <Stack direction='row' alignItems='center' spacing={2}>
            <DefaultMenu options={menus} />

            <Navbar menu={activeMenu} />
          </Stack>

          <HeaderOptions>
            <Select
              name='lang'
              value={i18n.language}
              options={[
                { id: 'pt', name: 'PT' },
                { id: 'en', name: 'ENG' },
              ]}
              onChange={handleLanguage}
              sx={{ '& fieldset': { borderColor: 'transparent !important' } }}
            />

            <ToggleTheme />

            <Notifications />

            <MyAccount.Root>
              <MyAccount.Modal.Button
                title={t('button.title.myAccount')}
                onClick={handleClick}
              />
              <MyAccount.Modal.Root
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MyAccount.Content>
                  <MyAccount.Item.Root>
                    <MyAccount.Item.Avatar
                      src={
                        user?.fileName
                          ? `${BASE_URL_IMAGES}${user?.fileName}`
                          : undefined
                      }
                    />
                    <MyAccount.Item.Content
                      name={user?.name}
                      email={user?.email}
                    />
                  </MyAccount.Item.Root>

                  <MyAccount.Actions>
                    <MyAccount.Action
                      title={t('button.title.editAccount')}
                      variant='contained'
                      onClick={() => setOpenForm(true)}
                    />
                    <MyAccount.Action
                      title={t('button.title.logout')}
                      icon={<Icon name='logout' />}
                      onClick={handleLogout}
                    />
                  </MyAccount.Actions>
                </MyAccount.Content>
              </MyAccount.Modal.Root>

              {openForm && (
                <FormMyAccount open={openForm} onClose={handleCloseForm} />
              )}
            </MyAccount.Root>
          </HeaderOptions>
        </Stack>
      </Container>
    </Stack>
  )
}
