import { Grid, Stack, Typography, useTheme } from '@mui/material'
import uniqBy from 'lodash.uniqby'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import NovaSubAreaImg from 'assets/icons/blue-print.svg'
import NovaSubAreaLightImg from 'assets/icons/light/nova-subarea-light.svg'
import { Actions } from 'components/Actions'
import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Icon } from 'components/Icon'
import { Loading } from 'components/Loading'
import { Select } from 'components/Select'
import { TableActionType } from 'components/Table'
import { subareaType } from 'constants/subarea'
import { TEXT_TITLE_14, TEXT_SUBTEXT } from 'constants/textTypes'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { IBranchList } from 'services/branch.service'
import { subAreaService } from 'services/subarea/subArea.service'
import { useMapSubarea } from 'store/map-subarea.store'
import { useMapStore } from 'store/map.store'
import { SubareaType } from 'types/subarea/subarea'

import { FormSubAreaModal } from '../FormSubAreaModal'
import { MapForm } from '../MapForm'
import { SubareaRow } from './style'

interface IProps {
  data?: IBranchList
}

export const SubareaSidebar = ({ data }: IProps) => {
  const {
    setCurrentCompany,
    currentCompany,
    sidebarVisibility,
    toggleSidebarVisibility,
  } = useMapStore()
  const {
    createSubarea,
    updateSubareas,
    subareas,
    hiddenSubareasId,
    updateHiddenSubareasId,
  } = useMapSubarea()
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const { sweetAlert } = useSweetAlert()
  const { t } = useTranslation('common')
  const theme = useTheme()

  const [openModal, setOpenModal] = useState(false)
  const [editionSubarea, setEditionSubarea] = useState<SubareaType | null>(null)

  const mapId = searchParams.get('mapId') ?? ''
  const isDark = theme.palette.mode === 'dark'

  const { data: listSubareas, isLoading: isLoadingSubareas } = useQuery({
    queryKey: ['subareas', currentCompany, mapId],
    queryFn: () => {
      if (mapId) {
        return subAreaService.listSubareaById({
          id: mapId,
          type: { mapType: subareaType.PANIC_BUTTON_PLANT },
        })
      }

      return Promise.resolve(null)
    },
    cacheTime: 0,
    staleTime: 0,
  })

  const subAreasIsEmpty = subareas?.length === 0
  const subareasGroup = uniqBy(subareas ?? [], 'nomeSubArea')

  const iconBottom = sidebarVisibility ? 'chevronLeft' : 'chevronRight'

  function handleClose() {
    setOpenModal(false)
  }

  useEffect(() => {
    queryClient.invalidateQueries('subareas')
  }, [])

  useEffect(() => {
    if (currentCompany) {
      setCurrentCompany(currentCompany)
    }
  }, [currentCompany])

  useEffect(() => {
    if (listSubareas) {
      updateSubareas(listSubareas.subAreas)
    }
  }, [listSubareas])

  const enableSidebar = sidebarVisibility

  const deleteSubarea = async (id: string) => {
    subAreaService
      .remove(id)
      .then(() => {
        queryClient.invalidateQueries('subarea-all')
        queryClient.invalidateQueries('all-colors-subareas')
        queryClient.invalidateQueries('subareas')
        toast.success(t('information.delete.success'))
      })
      .catch(() => {
        toast.error(t('information.delete.error'))
      })
  }

  const toggleHiddenSubareas = (id: string) => {
    if (hiddenSubareasId.includes(id)) {
      updateHiddenSubareasId(hiddenSubareasId.filter((value) => value !== id))
    } else {
      updateHiddenSubareasId(hiddenSubareasId.concat(id))
    }
  }

  const visibleSubareaForm = useMemo(
    () => editionSubarea !== null,
    [editionSubarea],
  )

  const actions: TableActionType<SubareaType>[] = [
    {
      type: 'edit',
      onClick: (row) => setEditionSubarea(row),
    },
    {
      type: 'delete',
      onClick: (row) =>
        sweetAlert({
          text: t('map.subArea.message.confirmCancel').toString(),
          onSuccess: () => {
            deleteSubarea(row.areaID.value)
          },
        }),
    },
  ]

  return (
    <>
      {visibleSubareaForm && (
        <FormSubAreaModal
          open={visibleSubareaForm}
          onClose={() => setEditionSubarea(null)}
          data={editionSubarea}
        />
      )}

      <Stack
        direction='column'
        sx={{
          width: '100%',
          maxWidth: enableSidebar ? 280 : 30,
          zIndex: 1,
          background: (theme) => theme.palette.background.default,
          borderRight: '2px solid',
          borderColor: (theme) => theme.palette.secondary.light,
          p: 2,
          position: 'relative',
          transition: 'max-width 0.3s',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: enableSidebar ? 'initial' : 'none',
          }}
        >
          <Grid item xs={12}>
            <Select
              label='Selecione uma unidade'
              name='branch'
              value={currentCompany}
              options={data?.map((branch) => ({
                id: branch.id.value,
                name: branch.name,
              }))}
              onChange={(event) => {
                queryClient.invalidateQueries('company')
                setCurrentCompany(String(event.target.value))
              }}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                ...TEXT_TITLE_14,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Sub áreas
            </Typography>
            <Typography
              sx={{
                ...TEXT_SUBTEXT,
                color: (theme) => theme.palette.text.disabled,
                mb: 1,
              }}
            >
              Localizações internas da unidade
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' alignItems='center' justifyContent='center'>
              <Button
                variant='contained'
                sx={{ width: '100%', mb: 2 }}
                onClick={createSubarea}
              >
                + Nova Sub Área
              </Button>
            </Stack>
          </Grid>

          {subAreasIsEmpty && (
            <Grid item xs={12}>
              <Stack
                direction='column'
                alignItems='center'
                justifyContent='center'
              >
                <Stack
                  component='img'
                  src={isDark ? NovaSubAreaImg : NovaSubAreaLightImg}
                  alt='planta baixa'
                  width={154}
                  mb={2}
                />

                <Typography
                  sx={{
                    ...TEXT_TITLE_14,
                    color: (theme) => theme.palette.primary.light,
                    mb: 1,
                  }}
                >
                  Criar Sub área
                </Typography>
                <Typography
                  sx={{
                    ...TEXT_SUBTEXT,
                    color: (theme) => theme.palette.text.disabled,
                    textAlign: 'center',
                  }}
                >
                  As subáreas compõem o interior da área principal criada,
                  organizando os equipamentos e sensores que estarão vinculados
                  a ela.
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoadingSubareas && <Loading />}
            {!subAreasIsEmpty &&
              subareasGroup.map((subarea) => (
                <SubareaRow key={subarea.areaID.value}>
                  <Stack
                    direction='row'
                    onClick={() => toggleHiddenSubareas(subarea.areaID.value)}
                    mr={2}
                  >
                    {!hiddenSubareasId.includes(subarea.areaID.value) ? (
                      <Icon name='visibility' />
                    ) : (
                      <Icon name='visibilityOff' />
                    )}
                    <Typography
                      sx={{
                        maxWidth: 150,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        ml: 2,
                      }}
                      title={subarea.nomeSubArea}
                    >
                      {subarea.nomeSubArea}
                    </Typography>
                  </Stack>
                  <Actions
                    options={actions.map((action) => ({
                      ...action,
                      action: () => action.onClick?.(subarea),
                    }))}
                  />
                </SubareaRow>
              ))}
          </Grid>
        </Grid>

        <IconButton
          size='small'
          variant='contained'
          iconName={iconBottom}
          sx={{
            width: 24,
            position: 'absolute',
            right: 0,
            bottom: 60,
            transform: 'translateX(50%)',
          }}
          onClick={toggleSidebarVisibility}
        />
      </Stack>

      {openModal && <MapForm open={openModal} onClose={handleClose} />}
    </>
  )
}
