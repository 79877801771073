import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Table, TableActionType } from 'components/Table'
import { TabPanel, TabPanelProps } from 'components/Tabs/TabPanel'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { invoiceMercadoLivreMapper } from 'pages/setting/mappers/invoice-mercado-livre.mapper'
import { listInvoiceMercadoLivre } from 'services/invoice/mercadoLivre/listInvoiceMercadoLivre'
import { InvoiceMercadoLivreType } from 'types/invoice/invoice-mercado-livre'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { FormInvoiceMercadoLivreModal } from '../FormInvoiceMercadoLivreModal'

type DataType = InvoiceMercadoLivreType

type Props = Omit<TabPanelProps, 'children'> & {
  search?: string
  openForm: boolean
  onCloseForm: () => void
}

export function InvoiceMercadoLivre(props: Props) {
  const { search, openForm, onCloseForm, ...rest } = props
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openFormEdit, setOpenFormEdit] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<DataType | null>(null)

  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('contractedMW')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<DataType>,
    Error
  >({
    queryKey: ['invoice-mercado-livre', page, search, orderBy],
    queryFn: () =>
      listInvoiceMercadoLivre({ page, search, columnSort: orderBy }),
  })

  const invoiceWater = invoiceMercadoLivreMapper(data?.results ?? [])

  const handleCancel = () => {
    onCloseForm()
    setOpenFormView(false)
    setSelected(null)
    setOpenFormEdit(false)
  }

  const handleEdit = (item: DataType) => {
    setSelected(item)
    setOpenFormEdit(true)
  }

  const handleView = (item: DataType) => {
    setSelected(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: DataType) => {
    httpDelete(`/free-market-invoice/${item.id}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'formattedMonth',
      label: t('table.invoice.mercadoLivre.monthAndYear'),
    },
    {
      id: 'formattedExpiration',
      label: t('table.invoice.mercadoLivre.expiration'),
    },
    {
      id: 'mwContracted',
      label: t('table.invoice.mercadoLivre.contractedMW'),
    },
    {
      id: 'formattedTotalValue',
      label: t('table.invoice.mercadoLivre.totalValue'),
    },
    {
      id: 'serviceTime',
      label: t('table.invoice.mercadoLivre.serviceTime'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
  ]

  const tableActions: TableActionType<DataType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_INVOICE,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_INVOICE,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_INVOICE,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <TabPanel {...rest}>
      <Table<DataType>
        columns={columns}
        rows={invoiceWater}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView || openFormEdit) && (
        <FormInvoiceMercadoLivreModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView || openFormEdit}
          onClose={handleCancel}
        />
      )}
    </TabPanel>
  )
}
