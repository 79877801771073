import { Grid, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Edge, Node } from 'reactflow'

import AirConditioningImg from 'assets/icons/maps/icons-modal/ar-condicionado-add.svg'
import FeederEquipmentImg from 'assets/icons/maps/icons-modal/equipamento-alimentador.svg'
import EquipmentImg from 'assets/icons/maps/icons-modal/equipamento.svg'
import SensorImg from 'assets/icons/maps/icons-modal/sensor.svg'
import { Modal } from 'components/Modal'
import { typeEquipmentOrArCon } from 'constants/maps'
import { useMapSubarea } from 'store/map-subarea.store'
import { useMapStore } from 'store/map.store'
import { AddElementType } from 'types/area/create-linked-area'
import { ModalType } from 'types/modal'

import { CardOption } from './CardOption'
import { ListModal } from './ListModal'

type NodesType = { nodes: Node<any, string | undefined>[] }

interface Props extends ModalType {
  onClick: (data: AddElementType) => void
  nodes: Node<any, string | undefined>[]
  handleSave?: (nodes: NodesType) => void
  data?: any
  edges?: Edge<any>[]
}

type equipmentOrArConType = {
  img: string
  title: string
  description: string
  type: typeEquipmentOrArCon
}

export const SearchModalElement = ({
  open,
  onClose,
  data,
  onClick,
  nodes,
  edges,
}: Props) => {
  const { currentMap, closeModal, isCloseModal } = useMapStore()
  const { isAirConditioning } = useMapSubarea()

  const [openModal, setOpenModal] = useState(false)
  const [type, setType] = useState<typeEquipmentOrArCon>(
    '' as typeEquipmentOrArCon,
  )

  const handleOpenModal = (type: typeEquipmentOrArCon) => {
    setOpenModal(true)
    setType(type)
  }

  const dataSubarea = data?.data

  const equipment = {
    equipment: [
      {
        img: FeederEquipmentImg,
        title: 'Equipamento Alimentador',
        description:
          'Vincule um equipamento alimentador à uma subárea selecionada no mapa.',
        type: typeEquipmentOrArCon.FEEDER,
      },
      {
        img: EquipmentImg,
        title: 'Equipamento',
        description:
          'Vincule um equipamento à uma subárea selecionada no mapa.',
        type: typeEquipmentOrArCon.EQUIPMENT,
      },
      {
        img: SensorImg,
        title: 'Sensor',
        description: 'Vincule um sensor à uma subárea selecionada no mapa.',
        type: typeEquipmentOrArCon.SENSOR,
      },
    ],
    airConditioning: [
      {
        img: AirConditioningImg,
        title: 'Ar condicionado',
        description:
          'Vincule um ar condicionado à subárea selecionada no mapa.',
        type: typeEquipmentOrArCon.AIR_CONDITIONING,
      },
      {
        img: SensorImg,
        title: 'Sensor',
        description: 'Vincule um sensor à uma subárea selecionada no mapa.',
        type: typeEquipmentOrArCon.AIR_CONDITIONING_SENSOR,
      },
    ],
  }

  useEffect(() => {
    if (isCloseModal) {
      onClose()
    }
  }, [isCloseModal])

  return (
    <>
      <Modal
        title={'Selecione entre equipamentos e sensores'}
        icon='timeLineSvg'
        open={open}
        onClose={onClose}
        maxWidth={isAirConditioning ? 'md' : 'lg'}
        sx={{
          width: '100%',
          zIndex: 4,
        }}
      >
        <Stack direction='row' justifyContent='space-between' mb={2}>
          <Grid
            sx={{
              background: (theme) => theme.palette.primary.dark,
              px: 1.5,
              borderRadius: 1,
              font: 'normal normal normal 12px/20px Noto Sans',
            }}
          >
            SELECIONAR TIPO
          </Grid>
          <Grid
            sx={{
              background: (theme) => theme.palette.background.paper,
              px: 1.5,
              borderRadius: 1,
              font: 'normal normal normal 12px/20px Noto Sans',
            }}
          >
            {`${currentMap?.nameArea.toUpperCase()} - ${dataSubarea.nomeSubArea.toUpperCase()}`}
          </Grid>
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          spacing={2}
        >
          <Grid container spacing={2}>
            {equipment[
              !isAirConditioning ? 'equipment' : 'airConditioning'
            ].map((item: equipmentOrArConType, index: number) => (
              <CardOption
                key={index}
                img={item.img}
                title={item.title}
                description={item.description}
                type={item.type}
                handleOpenModal={handleOpenModal}
              />
            ))}
          </Grid>
        </Stack>
      </Modal>

      {openModal && (
        <ListModal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
            closeModal(false)
          }}
          data={{ dataSubarea, type }}
          onClick={onClick}
          nodes={nodes}
          edges={edges}
        />
      )}
    </>
  )
}
