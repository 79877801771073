import { CreateLinkedAreaType } from 'types/area/create-linked-area'
import { CreateMapAreaType } from 'types/map/create-map'

import { httpClient } from '../api/httpClient'

export type UpdateMapArea = {
  mapImageId: string
  areaName: string
  file: File | null
  companyId: string
}

const createAreaBtn = (data: CreateMapAreaType) => {
  const formData = new FormData()
  if (data.file) {
    formData.append('file', data.file)
  } else {
    formData.append('file', '')
  }
  formData.append('areaName', data.areaName)
  formData.append('companyId', data.companyId)

  return httpClient.post('/map-image-company/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const deleteArea = async (id: string) => {
  const response = await httpClient.delete(`/map-image-company/${id}`)
  return response
}

const updateArea = async (id: string, data: UpdateMapArea) => {
  const formData = new FormData()
  if (data.file) {
    formData.append('file', data.file)
  } else {
    formData.append('file', new Blob(['']))
  }
  formData.append('areaName', data.areaName)
  formData.append('companyId', data.companyId)
  formData.append('mapImageId', data.mapImageId)
  const response = await httpClient.put(
    `/map-image-company/update/${id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )

  return response?.data
}

const createLinkedArea = async (data: CreateLinkedAreaType) => {
  const response = await httpClient.post('/linked-area', data)

  return response.data
}

const findAreaByCompanyId = async (id: string) => {
  const response = await httpClient.get(`/map-image-company/company/${id}`)

  return response.data
}

export const AreaService = {
  createAreaBtn,
  deleteArea,
  updateArea,
  createLinkedArea,
  findAreaByCompanyId,
}
