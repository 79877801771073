/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import dayjs from 'dayjs'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { getMonthDays } from 'helpers/getMonthDays'
import { getTimesOfDay } from 'helpers/getTimesOfDay'
import { httpClient } from 'services/api/httpClient'
import { getAllAreasBySensorTempHum } from 'services/area/getAllAreas'

import { TempAndHumMonthlyTotals } from './@types/temperatureAndHumidity.types'

type TempAndHumiType = {
  area: string
  month: string
  type: 'TEMP' | 'HUM'
  unitMeasurement: string
  value: number
  valueMax: number
  valueMin: number
  year: string
  day: string
  hour?: string
}

interface Props {
  isPreview?: boolean
}

export function useTemperatureHumidityByAreaChart(props: Props) {
  const { isPreview } = props
  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')

  const [generatingFile, setGeneratingFile] = useState(false)

  const year = dayjs(date).get('year')
  const month = dayjs(date).get('month') + 1

  const [areaId, setSelectArea] = useState<string | null>(null)

  const { data, refetch } = useQuery({
    queryKey: [
      'temperature-humidity-by-area',
      year,
      month,
      period,
      areaId,
      date,
    ],
    queryFn: async () => {
      const response = await httpClient.get(
        '/dashboard-temperature-and-humidity/period/by-area',
        {
          params: {
            year: period === 'day' ? null : year,
            month: period === 'month' ? month : null,
            date: period === 'day' ? dayjs(date).format('YYYY-MM-DD') : null,
            areaId: areaId,
          },
        },
      )

      return response.data?.map((item: any) => ({
        ...item,
        value: Number(item.value.toFixed(0)),
      })) as TempAndHumiType[]
    },
    enabled: !!areaId && !!date && !!period,
  })

  const { data: areas, refetch: refetchAreas } = useQuery({
    queryKey: 'all-areas-temp-hum',
    queryFn: async () => {
      return await getAllAreasBySensorTempHum()
    },
    select: (data) => {
      return data?.map((item) => ({
        label: item.name,
        value: String(item.id.value),
      }))
    },
  })

  useEffect(() => {
    if (areas?.length) {
      setSelectArea(areas[0].value)
    }
  }, [areas])

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
      refetchAreas()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  function calculateDailyTotals(data: TempAndHumiType[]): any[] {
    const dailyTotals: Array<any> = []
    const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD') // Obtém o primeiro dia do período
    const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD') // Obtém o último dia do período

    // Preenche o array dailyTotals com todos os dias do período
    for (
      let date = moment(startDate).clone();
      date.isSameOrBefore(endDate);
      date.add(1, 'day')
    ) {
      const findTemp = data?.find(
        (item) =>
          item.month === date.format('M') &&
          item.type === 'TEMP' &&
          item.day === date.format('D'),
      )
      const findHumi = data?.find(
        (item) =>
          item.month === date.format('M') &&
          item.type === 'HUM' &&
          item.day === date.format('D'),
      )

      const dateString = date.format('YYYY-MM-DD')
      dailyTotals.push({
        date: dateString,
        totalTemperature: findTemp?.value ?? 0,
        totalHumidity: findHumi?.value ?? 0,
        valueMaxTemperature: findTemp?.valueMax ?? 0,
        valueMinTemperature: findTemp?.valueMin ?? 0,
        valueMaxHumidity: findHumi?.valueMax ?? 0,
        valueMinHumidity: findHumi?.valueMin ?? 0,
      })
    }

    return dailyTotals
  }

  function calculateMonthlyTotals(data: TempAndHumiType[] = []): any[] {
    if (!data.length) return []

    const monthlyTotals: Array<any> = []
    const year = moment(date).year() // Obtém o ano atual

    // Inicializa o array monthlyTotals com os meses do ano
    for (let month = 1; month <= 12; month++) {
      const findTemp = data?.find(
        (item) => item.month === String(month) && item.type === 'TEMP',
      )
      const findHumi = data?.find(
        (item) => item.month === String(month) && item.type === 'HUM',
      )

      const monthString = moment()
        .month(month - 1)
        .year(year)
        .format('YYYY-MM')
      monthlyTotals.push({
        month: monthString,
        totalTemperature: findTemp?.value ?? 0,
        totalHumidity: findHumi?.value ?? 0,
        valueMaxTemperature: findTemp?.valueMax ?? 0,
        valueMinTemperature: findTemp?.valueMin ?? 0,
        valueMaxHumidity: findHumi?.valueMax ?? 0,
        valueMinHumidity: findHumi?.valueMin ?? 0,
      })
    }

    return monthlyTotals
  }

  const calculateHourlyTotals = (data: TempAndHumiType[]): any[] => {
    const hourlyTotals: Array<any> = []

    // Inicializa o array hourlyTotals com as horas do dia
    for (let hour = 0; hour < 24; hour++) {
      const findTemp = data?.find(
        (item) => item.type === 'TEMP' && String(item.hour) === String(hour),
      )
      const findHumi = data?.find(
        (item) => item.type === 'HUM' && String(item.hour) === String(hour),
      )

      const hourString = moment().hour(hour).minute(0).second(0).format('HH:mm')
      hourlyTotals.push({
        hour: hourString,
        totalTemperature: findTemp?.value ?? 0,
        totalHumidity: findHumi?.value ?? 0,
        valueMaxTemperature: findTemp?.valueMax ?? 0,
        valueMinTemperature: findTemp?.valueMin ?? 0,
        valueMaxHumidity: findHumi?.valueMax ?? 0,
        valueMinHumidity: findHumi?.valueMin ?? 0,
      })
    }

    return hourlyTotals
  }

  const getData = (type: keyof Omit<TempAndHumMonthlyTotals, 'month'>) => {
    if (period === 'year') {
      return calculateMonthlyTotals(data ?? [])?.map((item) => item[type])
    }

    if (period === 'day') {
      return calculateHourlyTotals(data ?? [])?.map((item) => item[type])
    }

    return calculateDailyTotals(data ?? [])?.map((item) => item[type])
  }

  const getDataTotalByLabel = (label: string) => {
    if (period === 'year') {
      return calculateMonthlyTotals(data ?? [])?.find((item) => {
        return (
          String(moment(`${item.month}-01`).format('MMMM'))?.toUpperCase() ===
          label?.toUpperCase()
        )
      })
    }

    if (period === 'day') {
      return calculateHourlyTotals(data ?? [])?.find(
        (item) => item.hour?.toUpperCase() === label?.toUpperCase(),
      )
    }

    return calculateDailyTotals(data ?? [])?.find(
      (item) => item.date?.toUpperCase() === label?.toUpperCase(),
    )
  }

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const findTotals = getDataTotalByLabel(context.label)
            if (context.dataset.yAxisID === 'y2') {
              return `${context.parsed.y}% ${
                findTotals
                  ? `(min: ${Number(findTotals?.valueMinHumidity || 0).toFixed(
                      0,
                    )}%, max: ${Number(
                      findTotals?.valueMaxHumidity || 0,
                    ).toFixed(0)}%)`
                  : ''
              }`
            }
            // let label = context.dataset.label || ''

            return `${context.parsed.y} ºC ${
              findTotals
                ? `(min: ${Number(findTotals?.valueMinTemperature || 0).toFixed(
                    0,
                  )} ºC, max: ${Number(
                    findTotals?.valueMaxTemperature || 0,
                  ).toFixed(0)} ºC)`
                : ''
            }`
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return value + 'ºC'
          },
        },
      },
      y2: {
        position: 'right',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return value + '%'
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  const getLabels = useCallback(() => {
    if (period === 'year') {
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    // return getWeekDays(date, 'DD/MMM')
    return getTimesOfDay(date, 'HH:mm')
  }, [period, date])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()

    const chartData = {
      labels,
      datasets: [
        {
          type: 'bar' as const,
          label: t('chart.temperature.temperature'),
          borderRadius: 20,
          data: getData('totalTemperature') ?? [],
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[0],
          ),
          borderColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[0],
          ),
        },
        {
          type: 'bar' as const,
          label: t('chart.temperature.humidity'),
          yAxisID: 'y2',
          borderRadius: 20,
          data: getData('totalHumidity') ?? [],
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[1],
          ),
          borderColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[1],
          ),
        },
      ],
    }

    setChartData(chartData)
  }, [i18n.language, date, getLabels, data, period, areaId])

  return {
    isDesktop,
    date,
    options,
    setDate,
    chartData,
    chartRef,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    areas,
    setSelectArea,
    areaId,
    year,
    month,
  }
}
