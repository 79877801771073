/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { StatusOrderServiceEnum } from 'core/enums/StatusOrderServiceEnum'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import InputBase from 'components/Input/InputBase'
import { InputFile } from 'components/Input/InputFile'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { createCommentOrderService } from 'services/order-service/createComment'
import { getOrderService } from 'services/order-service/getOrderServices'
import { updateActionOSCalendar } from 'services/order-service/updateOrderService'
import { CreateCommentType } from 'types/pre-os/comment/create-comment'
import { OSType, StatusOSType } from 'types/pre-os/pre-os'

import { FormRecuseOSModal } from './components/FormRecuseOSModal'
import { VerticalTimeline } from './components/VerticalTimeline'

export function ScheduleDetailsPage() {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { sweetAlert } = useSweetAlert()

  const [status, setStatus] = useState<string | null>(null)
  const [openForm, setOpenForm] = useState(false)
  const [comment, setComment] = useState<string>('')

  const { search } = useLocation()
  const idOrderService = search?.replace('?id=', '')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    OSType,
    Error
  >({
    queryKey: ['order-service-find', idOrderService],
    queryFn: () => getOrderService(Number(idOrderService)),
  })

  const comments = data?.comments ?? []
  const logs = data?.logs ?? []
  const dia = moment(data?.dateOccurrence).format('DD/MM/YYYY')
  const hora = moment(data?.dateOccurrence).format('HH:mm:ss')
  const reason = data?.comment ?? ''

  const sendComment = async () => {
    const commentRequest: CreateCommentType = {
      comment: comment,
      orderService: data?.entityId.value,
    }
    try {
      await createCommentOrderService(commentRequest)
      refetch()
      toast.success(t('alert.success'))
      setComment('')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  const sendAction = async (status: string) => {
    const updateOS: StatusOSType = {
      status: status,
    }
    try {
      await updateActionOSCalendar(Number(idOrderService), updateOS)
      toast.success(t('alert.success'))
      queryClient.invalidateQueries('order-service-find')
      navigate('/maintenance/schedule')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  return (
    <>
      <Stack direction={['column', 'row']}>
        <Box
          flex={1}
          display='flex'
          flexDirection='column'
          padding={2}
          borderRadius={1}
          bgcolor='background.paper'
        >
          <Stack
            direction={['column', 'row']}
            alignItems={['flex-start', 'center']}
            justifyContent={'space-between'}
          >
            {!isLoading && (
              <Stack marginRight={[0, 2]} marginBottom={[2, 0]}>
                <Typography variant='body2'>{`Criado ${dia} às ${hora}`}</Typography>
                <Typography variant='h1'>{data?.name ?? ''}</Typography>
              </Stack>
            )}
            <Stack
              direction={'row'}
              justifyContent={['space-between', 'flex-start']}
              spacing={2}
            >
              {(data?.status === StatusOrderServiceEnum.NEW ||
                data?.status === StatusOrderServiceEnum.ACCEPTED ||
                data?.status === StatusOrderServiceEnum.REFUSED ||
                data?.status === StatusOrderServiceEnum.NOT_SOLVED) && (
                <>
                  <Button
                    startIcon={
                      <Box
                        color='warning.main'
                        display='flex'
                        alignItems='center'
                      >
                        <Icon name='timelapse' />
                      </Box>
                    }
                    variant='outlined'
                    color='warning'
                    size='medium'
                    onClick={() =>
                      sendAction(StatusOrderServiceEnum.IN_PROGRESS)
                    }
                  >
                    {t('button.title.accept')}
                  </Button>

                  <Button
                    startIcon={
                      <Box color='info.main' display='flex' alignItems='center'>
                        <Icon name='warning' />
                      </Box>
                    }
                    variant='outlined'
                    color='info'
                    size='medium'
                    onClick={() =>
                      sweetAlert({
                        title: 'O.S Recusada',
                        text: 'Não esqueça de registrar o motivo nos comentários',
                        onSuccess: () =>
                          sendAction(StatusOrderServiceEnum.REFUSED),
                      })
                    }
                  >
                    {t('button.title.recuse')}
                  </Button>
                </>
              )}
              {data?.status === StatusOrderServiceEnum.IN_PROGRESS && (
                <>
                  <Button
                    startIcon={
                      <Box
                        color='success.main'
                        display='flex'
                        alignItems='center'
                      >
                        <Icon name='check' />
                      </Box>
                    }
                    variant='outlined'
                    color='success'
                    size='medium'
                    onClick={() =>
                      sweetAlert({
                        title: 'A O.S foi resolvida?',
                        onSuccess: () =>
                          sendAction(StatusOrderServiceEnum.FINISHED),
                      })
                    }
                  >
                    {t('button.title.resolved')}
                  </Button>
                  <Button
                    startIcon={
                      <Box
                        color='warning.main'
                        display='flex'
                        alignItems='center'
                      >
                        <Icon name='error' />
                      </Box>
                    }
                    variant='outlined'
                    color='warning'
                    size='medium'
                    onClick={() =>
                      sweetAlert({
                        title: 'O.S não resolvida',
                        text: 'Não esqueça de registrar o motivo nos comentários',
                        onSuccess: () =>
                          sendAction(StatusOrderServiceEnum.NOT_SOLVED),
                      })
                    }
                  >
                    {t('button.title.notResolved')}
                  </Button>
                </>
              )}
            </Stack>
          </Stack>

          {!isLoading && (
            <Grid container spacing={3} component='form' marginTop={2}>
              <Grid item xs={12}>
                <Input
                  name='requester'
                  label={t('input.requester') ?? ''}
                  value={data?.user}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name='type'
                  label={t('input.type') ?? ''}
                  value={t(`orderService.type.${data?.type}`).toString()}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name='priority'
                  label={t('input.priority') ?? ''}
                  value={t(
                    `orderService.priority.${data?.priority}`,
                  ).toString()}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name='equipment'
                  label={t('input.equipment') ?? ''}
                  value={data?.nameEquipment}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name='serialNumber'
                  label={t('input.serialNumber') ?? ''}
                  value={data?.serialNumber}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name='model'
                  label={t('input.model') ?? ''}
                  value={data?.model}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name='location'
                  label={t('input.localization') ?? ''}
                  value={data?.location}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} display='none'>
                <InputFile />
              </Grid>
              <Grid item xs={12} sm={6}>
                {isDesktop ? (
                  <DesktopDatePicker
                    label={t('input.period')}
                    views={['day']}
                    value={data?.dateOccurrence}
                    disabled
                    onChange={(value) => {
                      //
                    }}
                    renderInput={(params) => (
                      <InputBase fullWidth {...params} required />
                    )}
                  />
                ) : (
                  <MobileDatePicker
                    label={t('input.period')}
                    views={['day']}
                    value={data?.dateOccurrence}
                    disabled
                    onChange={(value) => {
                      //
                    }}
                    renderInput={(params) => (
                      <InputBase fullWidth {...params} required />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name='hour'
                  type='time'
                  value={hora}
                  label={t('input.hour') ?? ''}
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name='description'
                  label={t('input.description') ?? ''}
                  value={data?.description}
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={6}
                  required
                  disabled
                />
              </Grid>
            </Grid>
          )}

          <Stack spacing={2} marginTop={3}>
            <Typography variant='body1' fontWeight='bold'>
              {t('comments')}
            </Typography>

            <Stack width='100%' direction='row' spacing={2}>
              <Avatar />

              <Stack spacing={1} width='100%'>
                <Input
                  name='addComment'
                  label={t('input.addComment') ?? ''}
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={6}
                  onChange={(event) => setComment(event.target.value)}
                  value={comment}
                />

                <Stack direction='row' spacing={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={sendComment}
                  >
                    {t('button.title.send')}
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            {comments?.map((item, index) => (
              <Stack width='100%' direction='row' spacing={2} key={index}>
                <Avatar />
                <Stack spacing={1}>
                  <Typography variant='body1'>
                    <strong>{item.user}</strong>
                    <Box
                      component='span'
                      sx={{ opacity: 0.5, fontSize: '0.75rem', marginLeft: 3 }}
                    >
                      {/* {`Há ${moment(new Date()).diff(
                        moment(item.creation),
                        'days',
                      )} dia(s)`} */}
                      {moment(item.creation).format('DD/MM/YYYY HH:mm')}
                    </Box>
                  </Typography>

                  <Typography variant='body2'>{item.comment}</Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>

        <Box
          flex={1}
          display='flex'
          flexDirection='column'
          padding={2}
          borderRadius={1}
          bgcolor='background.paper'
          marginTop={[2, 0]}
          marginLeft={[0, 2]}
        >
          <Typography variant='h1'>{t('progressOS')}</Typography>

          <Box display='flex' flexDirection='column' width='100%' marginTop={3}>
            <VerticalTimeline data={logs} reason={reason} />
          </Box>
        </Box>
      </Stack>

      {openForm && (
        <FormRecuseOSModal
          id={null}
          open={openForm}
          onClose={() => setOpenForm(false)}
        />
      )}
    </>
  )
}
