import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, Grid, InputAdornment, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'
import { InputDeviceID } from 'components/Input/InputDeviceID'
import { InputIpAddress } from 'components/Input/InputIpAddress'
import { InputPort } from 'components/Input/InputPort'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { SensorIconType } from 'components/Sensor'
import { SensorIconButton } from 'components/Sensor/SensorIconButton'
import { Toggle } from 'components/Toggle'
import { typesOfSensorsOptions } from 'constants/typesOfSensorsOptions'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { removeAccent } from 'helpers/removeAccent'
import { sensorService } from 'services/sensor.service'
import { ModalType } from 'types/modal'
import { CreateSensorDto } from 'types/sensor/create-sensor.dto'
import { ModeOption, SensorType } from 'types/sensor/sensor'
import { UpdateSensorDto } from 'types/sensor/update-sensor.dto'

import { schemaCreateSensor } from '../validations/create-sensor.validation'
import convertTime from 'helpers/convert-time.helper'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

interface Props extends ModalType {
  data: SensorType | null
  isView?: boolean
}

export function FormSensorModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateSensorDto>({
    resolver: yupResolver(schemaCreateSensor()),
    defaultValues: {
      name: data?.name,
      type: data?.type ?? undefined,
      ip: data?.ip,
      address: data?.address,
      port: data?.port,
      unit: data?.unit ?? '',
      dataUnifier: data?.dataUnifier ?? false,
      mode: data?.mode ?? ModeOption.SENSOR,
      time: convertTime(data?.time ?? 0),
    },
  })

  useEffect(() => {
    if (watch('mode') === 'DATALOG' && !data?.ip && !data?.port) {
      setValue('ip', undefined)
      setValue('port', undefined)
      setValue('dataUnifier', true)
    } else if (watch('mode') === 'CONCENTRATOR' && !data?.unit) {
      setValue('unit', undefined)
    }
  }, [watch('mode')])

  const getPayload = (payload: CreateSensorDto) => {
    if (payload.mode === ModeOption.DATALOG) {
      return {
        name: payload.name,
        type: payload.type,
        status: true,
        address: payload.address,
        dataUnifier: payload.dataUnifier,
        keyUnique: data?.keyUnique,
        ip: payload.ip,
        port: payload.port,
        time: payload.time,
        mode: payload.mode,
      }
    }
    if (payload.mode === ModeOption.CONCENTRATOR) {
      return {
        name: payload.name,
        type: payload.type,
        status: true,
        address: payload.address,
        dataUnifier: payload.dataUnifier,
        keyUnique: data?.keyUnique,
        unit: payload.unit,
        time: payload.time,
        mode: payload.mode,
      }
    }
    return {
      name: payload.name,
      type: payload.type,
      status: true,
      address: payload.address,
      dataUnifier: payload.dataUnifier,
      keyUnique: data?.keyUnique,
      time: payload.time,
      mode: payload.mode,
    }
  }

  const onSetSuccessfully = () => {
    // Show success message
    toast.success(t('savedInformation'))
    // Invalidate all queries
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'sensor' ||
        query.queryKey[0] === 'sensors' ||
        query.queryKey[0] === 'all-sensors-equipments' ||
        query.queryKey[0] === 'all-sensors',
    })
    // Reset form
    reset()
    // Close modal
    onClose()
  }

  const mutationCreate = useMutation({
    mutationFn: (formData: CreateSensorDto) => {
      return sensorService.create({
        data: getPayload(formData),
      })
    },
    onSuccess: onSetSuccessfully,
    onError: (error: Error) => {
      getErrorMessage(error)
    },
  })

  const mutationUpdate = useMutation({
    mutationFn: (formData: UpdateSensorDto) => {
      return sensorService.update({
        id: data?.id?.value ?? 0,
        data: {
          name: formData.name,
          type: formData.type,
          status: true,
          address: formData.address,
          dataUnifier: formData.dataUnifier,
          keyUnique: data?.keyUnique,
          ip: formData.ip,
          port: formData.port,
          unit: formData.unit,
          mode: formData.mode,
          time: formData.time,
        },
      })
    },
    onSuccess: onSetSuccessfully,
    onError: (error: Error) => {
      getErrorMessage(error)
    },
  })

  const onSubmit: SubmitHandler<CreateSensorDto> = (formData) => {
    if (!data?.id) {
      return mutationCreate.mutate(formData)
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('sensorForm.view')

    if (data?.id) return t('sensorForm.edit')

    return t('sensorForm.new')
  }

  const title = getTitle()

  return (
    <Modal title={title} open={open} onClose={onClose}>
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid item xs={12}>
          <Input
            name='name'
            label={t('input.sensorName') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.name}
            errorMessage={errors?.name?.message}
            required
            isDisabled={isView}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={3} direction='row' alignItems='center'>
            {watch('type') ? (
              <SensorIconButton
                icon={
                  removeAccent(
                    typesOfSensorsOptions?.find(
                      (item) => item.id === getValues('type'),
                    )?.id ?? '',
                  )?.toUpperCase() as SensorIconType
                }
              />
            ) : (
              <IconButton variant='outlined' disabled />
            )}
            <Select
              name='type'
              label={t('input.utility')}
              options={typesOfSensorsOptions?.map((item) => ({
                name: t(item.id) ?? '',
                id: item.id,
              }))}
              control={control}
              fullWidth
              error={!!errors?.type}
              errorMessage={errors?.type?.message}
              required
              disabled={isView}
            />
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              fontWeight: 700,
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {t('responseTime')}
          </Typography>
          <Typography
            sx={{
              fontSize: 10,
              fontWeight: 300,
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {t('responseTimeDescription')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Input
            name='time'
            label={t('input.time')}
            control={control}
            placeholder={'Ex: 1000'}
            fullWidth
            error={!!errors?.time}
            errorMessage={errors?.time?.message}
            required
            type={'number'}
            isDisabled={isView || (watch('type') === SensorTypeEnum.BTN_PANIC)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>min</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            name='mode'
            label={t('input.communication')}
            options={[
              { id: ModeOption.SENSOR, name: 'Sensor' },
              { id: ModeOption.DATALOG, name: 'Datalog' },
              { id: ModeOption.CONCENTRATOR, name: 'Concentrador' },
            ]}
            control={control}
            fullWidth
            error={!!errors?.type}
            errorMessage={errors?.type?.message}
            required
            disabled={isView}
          />
        </Grid>

        {watch('mode') === ModeOption.SENSOR ? (
          <Grid item xs={12}>
            <InputDeviceID
              name='address'
              label='ID do Dispositivo'
              control={control}
              fullWidth
              error={!!errors?.address}
              errorMessage={errors?.address?.message}
              required
              disabled={isView}
              helperText={!isView ? 'Ex: 00:1B:44:11:3A:B7' : undefined}
            />
          </Grid>
        ) : watch('mode') === ModeOption.DATALOG ? (
          <>
            <Grid item xs={12}>
              <InputDeviceID
                name='address'
                label='ID do Dispositivo'
                control={control}
                fullWidth
                error={!!errors?.address}
                errorMessage={errors?.address?.message}
                disabled={isView}
                helperText={!isView ? 'Ex: 00:1B:44:11:3A:B7' : undefined}
                required
              />
            </Grid>
            <Grid item xs={8}>
              <InputIpAddress
                name='ip'
                label={t('input.ipAddress')}
                control={control}
                fullWidth
                error={!!errors?.ip}
                errorMessage={errors?.ip?.message}
                disabled={isView}
                helperText={!isView ? 'Ex: 173.0.194.97' : undefined}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <InputPort
                name='port'
                label={t('input.port') ?? ''}
                control={control}
                fullWidth
                error={!!errors?.port}
                errorMessage={errors?.port?.message}
                disabled={isView}
                required
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <InputDeviceID
                name='address'
                label='ID do Dispositivo'
                control={control}
                fullWidth
                error={!!errors?.address}
                errorMessage={errors?.address?.message}
                disabled={isView}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputDeviceID
                name='unit'
                label={t('input.unit') ?? ''}
                control={control}
                fullWidth
                error={!!errors?.unit}
                errorMessage={errors?.unit?.message}
                disabled={isView}
                required
              />
            </Grid>
          </>
        )}
        {data?.keyUnique && (
          <>
            <Grid item xs={12}>
              <Divider
                sx={{ background: (theme) => theme.palette.primary.dark }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                ID Único (gerado automaticamente e inalterável)
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {data?.keyUnique}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <ModalAction
            onCancel={onClose}
            isSubmit
            isView={isView}
            isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}
