import { CreateLevelType } from 'types/level/create-level'

import { httpClient } from '../api/httpClient'

export const createLevel = (data: CreateLevelType) => {
  return httpClient.post('/level', data)
}

export const createAllLevel = (data: CreateLevelType[]) => {
  return httpClient.post('/level/create-all', data)
}
