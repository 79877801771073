/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Photo } from 'components/Photo'
import { Sensor } from 'components/Sensor'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { findNameTypeEquipment } from 'constants/typeEquipmentOptions'
import { EquipmentType } from 'types/equipment/equipment'

export function equipmentsMapper(
  equipments: EquipmentType[],
  setSelected: (item: any) => void,
  setIsViewAllSensor: (value: boolean) => void,
) {
  const { t } = useTranslation('common')

  const handleView = (item: EquipmentType) => {
    setSelected(item)
    setIsViewAllSensor(true)
  }

  return equipments.map((item) => ({
    ...item,
    filePhoto: item?.file?.name ? (
      <Photo src={`${BASE_URL_IMAGES.concat(item?.file?.name)}`} />
    ) : (
      '-'
    ),
    idFile: item?.file?.id?.value,
    typeName: findNameTypeEquipment(item.type)?.name,
    typeId: findNameTypeEquipment(item.type)?.id,
    feeder: item.feeder ? 'Sim' : 'Não',
    feederData: item.feeder,
    sensorData: item.sensors,
    procedureID:
      item?.procedures?.length && item?.procedures[0]?.procedures?.id?.value,
    sensors:
      item.sensors?.length > 1 ? (
        <Chip
          label={t('table.equipment.sensors', { count: item.sensors.length })}
          color='default'
          variant='outlined'
          size='small'
          onClick={() => {
            handleView(item)
            setIsViewAllSensor(true)
          }}
        />
      ) : (
        <Stack spacing={1}>
          {item?.sensors?.map((sensor, index) => (
            <Stack
              direction={'row'}
              spacing={1}
              key={index}
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Sensor
                icon={sensor?.sensor?.type?.toUpperCase() as any}
                size='small'
              />
              {sensor?.sensor?.status ? (
                <Chip
                  label={t('on')}
                  color='success'
                  variant='outlined'
                  size='small'
                />
              ) : (
                <Chip
                  label={t('off')}
                  color='error'
                  variant='outlined'
                  size='small'
                />
              )}
            </Stack>
          ))}
        </Stack>
      ),
    statusData: item.status,
  }))
}
