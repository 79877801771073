import { Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { SensorIconType } from 'components/Sensor'
import { SensorIconButton } from 'components/Sensor/SensorIconButton'
import { httpClient } from 'services/api/httpClient'
import { shiftService } from 'services/shift.service'
import { SensorType } from 'types/sensor/sensor'

import { TypeElectricName } from '../TypeElectricName'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

interface Props {
  sensor: SensorType
  validShiftRangeTime?: boolean
}

export function SensorData({ sensor, validShiftRangeTime }: Props) {
  const { t } = useTranslation('common')
  // Busca os dados do sensor
  const { data: sensordata } = useQuery({
    queryKey: ['sensor', sensor?.id?.value],
    queryFn: () => {
      if (!sensor?.id?.value) return Promise.resolve(undefined)

      if (sensor?.type?.toUpperCase() !== 'WATER') {
        return httpClient.get<any[]>(`/sensor/datarecord/${sensor?.id?.value}`)
      }

      if (sensor?.type?.toUpperCase() === 'WATER') {
        return httpClient.get<any[]>(
          `/sensor/datarecord/water/${sensor?.id?.value}`,
        )
      }

      return Promise.resolve(undefined)
    },
    select: (data) => data?.data?.[0],
  })

  // validar se o sensor está no turno ativo
  const { data: shifts } = useQuery({
    queryKey: ['shifts-list'],
    queryFn: () => shiftService.list(),
    staleTime: 1 * (60 * 1000), // 1 mins
    cacheTime: 1 * (60 * 1000), // 1 mins
  })
  const data =
    sensor?.type?.toUpperCase() !== 'AIR' &&
    sensor?.type?.toUpperCase() !== 'ELECTRIC'
      ? sensordata?.data?.[0]
      : sensordata?.data

  const todayName = dayjs().format('dddd')
  const currentHour = dayjs().format('HH:mm')
  const hasShift = shifts
    ?.find((shift) => shift.id === data?.shift)
    ?.days?.find((day) => day.name === todayName?.toUpperCase())
  const validShiftTime =
    hasShift &&
    currentHour > hasShift?.hourInitial &&
    currentHour < hasShift?.hourFinal

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      sx={{
        filter:
          validShiftRangeTime || (hasShift && validShiftTime)
            ? undefined
            : 'grayscale(100%)', // verificar o turno, caso não tenha turno ativo, para a hora e dia atual, desabilitar o sensor
      }}
    >
      {sensor?.type?.toUpperCase() === SensorTypeEnum.TEMP_HUM ? (
        <Stack spacing={1}>
          <>
          {data?.map((item: any, index: number) => (
            item?.valueType === SensorTypeEnum.TEMP ? (
              <Stack direction={'row'} spacing={1} key={index}>
                <SensorIconButton
                  size='small'
                  icon={sensor?.type?.toUpperCase() as SensorIconType} />
                <Typography>
                  {Number(Number(item?.value || 0).toFixed(0))}ºC
                </Typography>
              </Stack>
            ) : (
              <Stack direction={'row'} spacing={1} key={index}>
                <SensorIconButton
                  size='small'
                  icon={sensor?.type?.toUpperCase() as SensorIconType} />
                <Typography>
                  {Number(Number(item?.value || 0).toFixed(0))}%{' '}
                  {t('sensor.HUM')}
                </Typography>
              </Stack>
            )
          ))} 
          </>         
        </Stack>
      ) : (
        <SensorIconButton
          size='small'
          icon={sensor?.type?.toUpperCase() as SensorIconType}
        />
      )}
      {sensor?.type?.toUpperCase() === SensorTypeEnum.TEMP && (
        <Typography>
          {data?.value ?? '--'}
          {data?.unitMeasurement} {t(`sensor.${sensor?.type}`)}
        </Typography>
      )}
      {sensor?.type?.toUpperCase() === SensorTypeEnum.HUM && (
        <Typography>
          {data?.value ?? '--'}
          {data?.unitMeasurement} {t(`sensor.${sensor?.type}`)}
        </Typography>
      )}
      {sensor?.type?.toUpperCase() === SensorTypeEnum.WATER && (
        <Stack>
          <Typography>
            Vazão: {data?.value?.toFixed(2)?.replace('.', ',')} l/s
          </Typography>
          <Typography>
            Vazão média/dia:{' '}
            {Number(data?.averageValue)
              .toFixed(2)
              ?.replace('.', ',')}{' '}
            l/s
          </Typography>
          <Typography>
            Consumo/dia:{' '}
            {Number(data?.totalValue)
              .toFixed(2)
              ?.replace('.', ',')}{' '}
            L ou{' '}
            {(Number(data?.totalValue || 0) / 1000)
              .toFixed(3)
              .replace('.', ',')}{' '}
            m³
          </Typography>
        </Stack>
      )}
      {sensor?.type?.toUpperCase() === SensorTypeEnum.ELECTRIC && (
        <Stack>
          {data?.map((item: any, index: number) => (
            <Typography key={index}>
              <TypeElectricName type={item?.type} /> {item?.value}{' '}
              {item?.unitMeasurement}
            </Typography>
          ))}
        </Stack>
      )}
      {sensor?.type?.toUpperCase() === SensorTypeEnum.AIR && (
        <Stack>
          {data?.map((item: any, index: number) => (
            <Typography key={index}>
              {item?.type} - {item?.value} {item?.unitMeasurement}
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
