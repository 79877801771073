import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { sensorHistoricService } from 'services/sensorHistoric.service'
import { PaginationType } from 'types/pagination'
import { SensorType } from 'types/sensor/sensor'
import { SensorHistoricType } from 'types/sensor/sensor-historic'
import { ColumnType } from 'types/table/column'

import { sensorHistoricMapper } from './mappers/sensor-historic.mapper'

export function SensorHistoryPage() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { state } = useLocation() as { state: { sensor?: SensorType } }

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState(true)
  const [orderBy, setOrderBy] = useState('occurrence')

  const { data, error, isLoading, isFetching } = useQuery<
    PaginationType<SensorHistoricType>,
    Error
  >(
    ['sensors-historic', page, search, orderBy],
    () =>
      sensorHistoricService.findAll(
        state?.sensor?.id?.value ?? 0,
        page,
        search,
        orderBy,
      ),
    {
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (!state?.sensor?.id) navigate(-1)
  }, [state?.sensor?.id])

  const sensors = sensorHistoricMapper(data?.results ?? [])

  const columns: ColumnType[] = [
    {
      id: 'id',
      label: 'id',
    },
    {
      id: 'formattedDate',
      label: t('table.sensorHistoric.date'),
    },
    {
      id: 'formattedHour',
      label: t('table.sensorHistoric.hour'),
    },
    {
      id: 'occurrence',
      label: t('table.sensorHistoric.occurrence'),
    },
    {
      id: 'equipment',
      label: t('table.sensorHistoric.equipment'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
    {
      id: 'value',
      label: t('table.sensorHistoric.value'),
    },
  ]

  return (
    <>
      <HeaderAction
        onSearch={(search) => setSearch(search)}
        sx={{ justifyContent: 'space-between' }}
      >
        <Typography variant='h1'>{state?.sensor?.name}</Typography>
      </HeaderAction>

      <Table<SensorHistoricType>
        columns={columns}
        rows={sensors}
        order={order ? 'ASC' : 'DESC'}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrder((state) => (newOrderBy === orderBy ? !state : true))
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      />
    </>
  )
}
