import { Grid, Stack, Typography } from '@mui/material'
import { AlertTypeEquipment } from 'core/enums/AlertTypeEquipment'
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormSetValue,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { ActionMeta, OnChangeValue } from 'react-select'

import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'
import { InputFile } from 'components/Input/InputFile'
import { InputMacAddress } from 'components/Input/InputMacAddress'
import { MaskedInputMAC } from 'components/Input/mask/MaskedInputMAC'
import { PhotoExpand } from 'components/Photo/Expand'
import { Select } from 'components/Select'
import { Autocomplete } from 'components/Select/Autocomplete'
import { Toggle } from 'components/Toggle'
import { listProceduresSelect } from 'services/procedures/listProcedures'
import { CreateEquipmentType } from 'types/equipment/create-equipment'
import { ID } from 'types/id'

import { SensorTypeEnum } from '../../../../core/enums/SensorTypeEnum'

interface SensorSelectType {
  value: string
  label: string
  isFixed?: boolean
  waterInlet?: boolean
  waterOutlet?: boolean
  type?: string
  floor?: string
}

interface Props {
  control: Control<CreateEquipmentType>
  isView?: boolean
  errors: FieldErrors<CreateEquipmentType>
  setValue: UseFormSetValue<CreateEquipmentType>
  useFieldSpecification: UseFieldArrayReturn<
    CreateEquipmentType,
    'specifications'
  >
  sensors?: { id: ID; name: string; type: string }[]
  selectedSensors: SensorSelectType[]
  onChange: (
    newValue: OnChangeValue<SensorSelectType, true>,
    actionMeta: ActionMeta<SensorSelectType>,
  ) => void
  file?: {
    name: string
    url: string
  }
}

export function FormPanicButtonScreen({
  control,
  isView,
  errors,
  setValue,
  useFieldSpecification,
  sensors,
  selectedSensors,
  onChange,
  file,
}: Props) {
  const { t } = useTranslation('common')

  const { data: proceduresList } = useQuery({
    queryKey: ['all-procedures'],
    queryFn: () => listProceduresSelect(),
    select: (data) =>
      data.items.map((data: { id: ID; name: string }) => ({
        id: data.id,
        name: data.name,
      })) ?? [],
  })

  const {
    fields: fieldsCameras,
    append: appendCameras,
    remove: removeCameras,
  } = useFieldArray({
    control,
    name: 'cameras',
  })

  return (
    <>
      <Grid item xs={12} direction={'row'}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Toggle
            name='mobile'
            control={control}
            label={t('toggle.mobile') ?? ''}
            disabled={isView}
          />
          <Toggle
            name='gps'
            control={control}
            label={t('toggle.gps') ?? ''}
            disabled={isView}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Input
          name='name'
          label={t('input.name')}
          control={control}
          fullWidth
          error={!!errors?.name}
          errorMessage={errors?.name?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='code'
          label={t('input.code')}
          control={control}
          fullWidth
          error={!!errors?.code}
          errorMessage={errors?.code?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='model'
          label={t('input.model') ?? ''}
          control={control}
          fullWidth
          error={!!errors?.model}
          errorMessage={errors?.model?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='brand'
          label={t('input.brand') ?? ''}
          control={control}
          fullWidth
          error={!!errors?.brand}
          errorMessage={errors?.brand?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      {!isView && (
        <Grid item xs={12} sm={6}>
          <InputFile
            defaultValue={file?.name}
            onChange={(file) => {
              setValue('file', file)
            }}
            onDelete={() => {
              setValue('file', null)
            }}
          />
        </Grid>
      )}
      {isView && !!file?.url && (
        <Grid item xs={12} sm={6}>
          <PhotoExpand url={file?.url} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: 'bold',
          }}
        >
          {t('equipment.configurationsSpecific')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          name='alertType'
          label={t('input.alertType')}
          options={[
            {
              name: t('input.panic') ?? '',
              id: AlertTypeEquipment.PANIC,
            },
            {
              name: t('input.emergency') ?? '',
              id: AlertTypeEquipment.EMERGENCY,
            },
            {
              name: t('input.warning') ?? '',
              id: AlertTypeEquipment.WARNING,
            },
          ]}
          control={control}
          fullWidth
          error={!!errors?.alertType}
          errorMessage={errors?.alertType?.message}
          required
          disabled={isView}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name='procedures'
          label={t('input.procedure')}
          options={proceduresList?.map((item) => ({
            name: t(item.name) ?? '',
            id: item.id?.value,
          }))}
          control={control}
          fullWidth
          error={!!errors?.procedures}
          errorMessage={errors?.procedures?.message}
          required
          disabled={isView}
        />
      </Grid>
      <Stack
        spacing={2}
        sx={{
          marginLeft: '16px',
          marginTop: '16px',
          width: '100%',
          padding: '8px',
          backgroundColor: (theme) => theme.palette.background.paper + '99',
        }}
      >
        <Grid item xs={12}>
          <Typography variant='caption'>{t('equipment.camera')}</Typography>
        </Grid>
        {fieldsCameras?.map((field, index) => (
          <Grid item xs={12} key={index}>
            <Stack direction='row' alignItems='flex-start' spacing={2}>
              <Input
                name={`cameras.[${index}].ip`}
                label={t('input.ipCamera') ?? ''}
                control={control}
                fullWidth
                disabled={isView}
                helperText={!isView ? 'Ex: 173.0.194.97' : undefined}
                error={!!errors?.cameras?.[index]?.ip}
                errorMessage={errors?.cameras?.[index]?.ip?.message}
                isDisabled={isView}
              />
              <InputMacAddress
                name={`cameras.[${index}].mac`}
                label={t('input.mac') ?? ''}
                control={control}
                fullWidth
                disabled={isView}
                InputProps={{
                  inputComponent: MaskedInputMAC as any,
                }}
                error={!!errors?.cameras?.[index]?.mac}
                errorMessage={errors?.cameras?.[index]?.mac?.message}
              />

              {index === 0 && !isView ? (
                <IconButton
                  variant='contained'
                  iconName='add'
                  color={'secondary'}
                  title={t('button.title.add') ?? ''}
                  onClick={() =>
                    appendCameras(
                      {
                        ip: undefined,
                        mac: undefined,
                      },
                      {
                        shouldFocus: true,
                      },
                    )
                  }
                />
              ) : (
                <IconButton
                  variant='contained'
                  iconName='delete'
                  title={t('button.title.delete') ?? ''}
                  color={'error'}
                  disabled={isView}
                  onClick={() => removeCameras(index)}
                />
              )}
            </Stack>
          </Grid>
        ))}
      </Stack>
      <Stack
        spacing={2}
        sx={{
          marginLeft: '16px',
          marginTop: '16px',
          width: '100%',
          padding: '8px',
          backgroundColor: (theme) => theme.palette.background.paper + '99',
        }}
      >
        <Grid item xs={12}>
          <Typography variant='caption'>
            {t('equipment.specification')}
          </Typography>
        </Grid>
        {useFieldSpecification.fields?.map((field, index) => (
          <Grid item xs={12} key={index}>
            <Stack direction='row' alignItems='flex-start' spacing={3}>
              <Input
                name={`specifications.[${index}].key`}
                label={t('input.name') ?? ''}
                control={control}
                fullWidth
                isDisabled={isView}
                error={!!errors?.specifications?.[index]?.key}
                errorMessage={errors?.specifications?.[index]?.key?.message}
              />
              <Input
                name={`specifications.[${index}].value`}
                label={t('input.value') ?? ''}
                control={control}
                fullWidth
                isDisabled={isView}
                error={!!errors?.specifications?.[index]?.value}
                errorMessage={errors?.specifications?.[index]?.value?.message}
              />

              {index === 0 && !isView ? (
                <IconButton
                  variant='contained'
                  color={'secondary'}
                  iconName='add'
                  title={t('button.title.add') ?? ''}
                  onClick={() =>
                    useFieldSpecification.append({
                      key: '',
                      value: undefined,
                    })
                  }
                />
              ) : (
                <IconButton
                  variant='contained'
                  iconName='delete'
                  color={'error'}
                  title={t('button.title.delete') ?? ''}
                  disabled={isView}
                  onClick={() => useFieldSpecification.remove(index)}
                />
              )}
            </Stack>
          </Grid>
        ))}
      </Stack>
      <Grid item xs={12}>
        <Typography variant='body2'>{t('equipment.linkSensor')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction='row' alignItems='flex-start' spacing={3}>
          <Autocomplete
            name={'sensors'}
            options={
              sensors
                ?.filter((i) => i?.type?.includes(SensorTypeEnum.BTN_PANIC))
                ?.map((sensor) => ({
                  label: sensor.name,
                  value: String(sensor.id),
                })) ?? []
            }
            isMulti
            control={control}
            value={selectedSensors}
            isClearable={selectedSensors.some((v) => !v.isFixed)}
            isDisabled={isView}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(newValue: any, actionMeta: any) => {
              onChange(newValue, actionMeta)
            }}
            error={!!errors?.sensors}
            errorMessage={errors?.sensors?.message}
            required
          />
        </Stack>
      </Grid>
    </>
  )
}
