import styled from '@emotion/styled'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { Menu, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { linkedAreaService } from 'services/linkedArea/linked-area.service'

const Pin = styled.div<{
  bgColor?: string
}>(
  {
    cursor: 'pointer',
    transform: 'perspective(40px) rotateX(20deg) rotateZ(-45deg)',
    transformOrigin: '50% 50%',
    borderRadius: '50% 50% 50% 0',
    padding: '0 3px 3px 0',
    width: 26,
    height: 26,
    left: '50%',
    top: '50%',
    position: 'relative',
  },
  ({ bgColor }) => ({
    background: bgColor,
  }),
)

const AlarmVector = styled.div<{
  bgColor?: string
  animationFrequency?: string
}>(
  {
    height: 16,
    width: 16,
    top: 5,
    left: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    borderRadius: '50%',
    '@keyframes changeOpacity': {
      '50%': {
        opacity: 0,
      },
    },
  },
  ({ bgColor, animationFrequency }) => ({
    background: bgColor,
    animation: animationFrequency,
  }),
)

type Props = {
  data: {
    label: string
    subareaName?: string
    triggered: boolean
    triggerDate: string
    lastUpdated: string
    sensorAlert: string
    callback?: { showDetails?: () => void }
  }
}

interface AlertData {
  id: number
  creation: string
  sensor: string
  battery: number
}

export const PanicNode = ({ data }: Props) => {
  const activedColor = '#66009D'
  const triggeredColor = '#FF0000'
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const fetchDataForSensor = (sensorId: any): AlertData | undefined => {
    const response = useQuery({
      queryKey: ['last-sensors-alert', sensorId],
      refetchOnReconnect: true,
      queryFn: () => linkedAreaService.getSensorAlertDate(sensorId),
      cacheTime: Infinity,
      refetchInterval: 5 * 60 * 1000,
    })

    return response.data as AlertData | undefined
  }

  const alertData: AlertData | undefined = fetchDataForSensor(
    Number(data.sensorAlert),
  )

  return (
    <>
      <Pin
        bgColor={data.triggered ? triggeredColor : activedColor}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <AlarmVector
          bgColor={data.triggered ? '#f0db51' : '#fff'}
          animationFrequency={
            data.triggered
              ? 'changeOpacity 1s infinite'
              : 'changeOpacity 2.5s infinite'
          }
        >
          <PriorityHighIcon
            style={{
              height: 12,
              width: 12,
              transform: 'rotate(45deg)',
              color: data.triggered ? triggeredColor : activedColor,
            }}
            fill={data.triggered ? triggeredColor : activedColor}
          />
        </AlarmVector>
      </Pin>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        {...(data.triggered
          ? {
              PaperProps: {
                sx: {
                  '&.MuiPaper-root.MuiPaper-elevation': {
                    background: '#5A1717 !important',
                  },
                },
              },
            }
          : {})}
      >
        <Stack padding={1.5}>
          <Typography marginBottom={1} fontWeight={600}>
            {data.label}
          </Typography>
          {data.subareaName && (
            <Typography marginBottom={1} fontWeight={600}>
              Local: {data.subareaName}
            </Typography>
          )}
          {data.triggered && (
            <Typography marginBottom={1}>
              Acionamento:{' '}
              {moment(data.triggerDate).format('DD/MM/YYYY HH[h]:mm')}
            </Typography>
          )}
          {
            <Typography marginBottom={1}>
              Nivel de bateria:{' '}
              {alertData?.battery ? `${alertData?.battery}%` : 'Sem informação'}
            </Typography>
          }
          {data.triggered ? (
            <Typography
              fontWeight={600}
              component='div'
              onClick={data.callback?.showDetails}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Ver detalhes
            </Typography>
          ) : null}
        </Stack>
      </Menu>
    </>
  )
}
