enum PriorityEnum {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

const findPriority = (priority: string) => {
  return PriorityEnum[priority as keyof typeof PriorityEnum]
}

export { PriorityEnum, findPriority }
