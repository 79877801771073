import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { CircularGraphic } from 'pages/dashboard/components/graphic/Circular'
import { monitoringUnitService } from 'services/monitoring-unit/monitoring-unit.service'

export const NumberOfSensor = () => {
  const { i18n } = useTranslation('common')
  const lang = i18n.language

  const { data } = useQuery({
    queryKey: 'sensor-find-information',
    queryFn: () => monitoringUnitService.listSensorsFindInformation(),
    cacheTime: 0,
    staleTime: 0,
  })

  const percentage = () => {
    if (data?.total && data?.totalOnline) {
      const result = (data.totalOnline * 100) / data.total

      return result.toLocaleString(lang === 'pt' ? 'pt-BR' : 'en', {
        maximumFractionDigits: 2,
      })
    }

    return 0
  }

  return (
    <Stack
      direction='column'
      sx={{
        background: (theme) => theme.palette.background.paper,
        borderRadius: 1,
        p: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: 24,
          fontWeight: 'bold',
          color: (theme) => theme.palette.text.primary,
          mb: 2,
        }}
      >
        Quantitativo de sensores
      </Typography>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='column' sx={{ width: '50%' }}>
          <CircularGraphic
            isPreview={true}
            color='#41B1AA'
            label='sensores ligados'
            value={percentage() ?? 0}
            isHoverDisabled={true}
            fontSizeLabel='0.678rem'
            type='percentage'
          />
        </Stack>
        <Stack direction='column' sx={{ width: '50%', heigth: 200 }}>
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={10} sx={{ mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Total de sensores
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {data?.total ?? 0}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Sensores ligados
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {data?.totalOnline ?? 0}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Sensores desligados
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {data?.totalOffline ?? 0}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Sensores alarmados
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mb: 4 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {data?.totalAlert ?? 0}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  )
}
