import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  onClick?: () => void
}

export function CardButton(props: Props) {
  const { children, onClick } = props
  return (
    <Box
      component='button'
      sx={{
        cursor: 'pointer',
        borderRadius: 1,
        width: 350,
        minWidth: 350,
        minHeight: 175,
        background: (theme) => theme.palette.background.paper,
        color: 'inherit',
        border: 'none',
        display: 'flex',
        alignItems: 'flex-start',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}
