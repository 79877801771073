import { Stack, Typography } from '@mui/material'

import RainRedImg from 'assets/icons/rain-red.svg'
import { Icon, IconNamesProps } from 'components/Icon'

interface Props {
  icon?: IconNamesProps
  data?: {
    title?: string
    subtitle?: string
    local?: string
  }
  typeAlert: 'sensor' | 'unit'
}

export const CardAlertSensor = ({ icon, data }: Props) => {
  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          borderRadius: 1,
          border: '1px solid',
          borderColor: '#FF0000',
          background: '#FF000020',
          my: 0.6,
          px: 0.5,
          py: 1,
          cursor: 'pointer',
        }}
      >
        <Stack sx={{ mx: 0.5, width: 28, height: 28, color: '#FF0000' }}>
          {icon ? (
            <Icon name={icon} />
          ) : (
            <img src={RainRedImg} width={28} height={28} alt='rain-red' />
          )}
        </Stack>
        <Stack>
          <Typography
            variant='body1'
            color={(theme) => theme.palette.text.primary}
            sx={{
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {data?.title ?? 'Perda de dados'}
          </Typography>
          <Typography
            color={(theme) => theme.palette.text.primary}
            sx={{
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            {data?.subtitle ?? 'Unidade Fábrica Manaus'}
          </Typography>
          <Typography
            color={(theme) => theme.palette.text.primary}
            sx={{
              fontSize: 10,
            }}
          >
            {data?.local ??
              'Avenida Jhonatam, Nº123, Bairro da inovação, Manaus, AM'}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
