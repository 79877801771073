import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Chart as ChartJS } from 'chart.js/auto'
import dayjs from 'dayjs'
import { Chart } from 'react-chartjs-2'

import { Actions } from 'components/Actions'
import { DatePeriod } from 'components/DatePeriod'
import { Autocomplete } from 'components/Select/Autocomplete'
import { httpClientDownloadExcel } from 'helpers/httpClientDownloadExcel'
import { useWaterTankLevelChart } from 'pages/dashboard/hooks/water/useWaterTankLevelChart'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
)

interface Props {
  isPreview?: boolean
}

export function WaterTankLevelChart(props: Props) {
  const { isPreview } = props
  const {
    chartData,
    chartRef,
    date,
    options,
    setDate,
    t,
    period,
    setPeriod,
    setGeneratingFile,
    generatingFile,
    equipments,
    setSelectEquipment,
    equipment,
  } = useWaterTankLevelChart({ isPreview })

  return (
    <Box
      sx={{
        gridArea: 'bottom-right',
        borderRadius: 1,
        width: '100%',
        height: '100%',
        minHeight: 200,
        overflowX: 'auto',
        maxWidth: '100vw',
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        position: 'relative',
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>

      <Stack
        direction={['column', 'row']}
        justifyContent={['space-between']}
        alignItems={'flex-start'}
        marginBottom={3}
      >
        <Typography variant='h2' fontSize='1.125rem' fontWeight='bold'>
          {t('chart.water.waterTankLevel')}
        </Typography>

        {!isPreview && (
          <Stack
            direction={['column', 'row']}
            justifyContent={'flex-end'}
            alignItems={['flex-end', 'center']}
            spacing={2}
            width={['100%', 'auto']}
            marginLeft={'auto'}
          >
            <Stack
              direction={['column', 'row']}
              justifyContent={'flex-end'}
              alignItems={['flex-end', 'center']}
              spacing={2}
              width={['100%', 'auto']}
              flexWrap='wrap'
              gap={2}
            >
              <Box minWidth={200} width={['100%', 'auto']}>
                <DatePeriod
                  date={date}
                  defaultPeriod={period}
                  periods={['month', 'year']}
                  onChangeDate={setDate}
                  onChangePeriod={(value) => setPeriod(value)}
                />
              </Box>

              <Box minWidth={200} width={['100%', 'auto']}>
                {!!equipments?.find(
                  (item: any) => item.value === equipment?.value,
                ) && (
                  <Autocomplete
                    name='equipments'
                    placeholder={t('input.selectEquipment')}
                    value={equipments?.find(
                      (item: any) => item.value === equipment?.value,
                    )}
                    options={equipments ?? []}
                    onChange={(value: any) => {
                      setSelectEquipment(value)
                    }}
                  />
                )}
              </Box>
            </Stack>

            <Stack paddingTop={[2, 0]}>
              <Actions
                color='inherit'
                options={[
                  {
                    type: 'excel',
                    action: () =>
                      httpClientDownloadExcel({
                        url: '/dashboard-water/water-tank-level/export-excel',
                        fileName: 'nivel-da-caixa-dagua',
                        params: {
                          year: dayjs(date).format('YYYY'),
                          month:
                            period === 'month'
                              ? dayjs(date).format('MM')
                              : undefined,
                          equipmentId: equipment?.value,
                          equipmentType: equipment?.type,
                        },
                        setGeneratingFile,
                      }),
                  },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </Stack>

      <Chart
        type='bar'
        ref={chartRef}
        options={options}
        data={chartData}
        height={120}
      />
    </Box>
  )
}
