import { Node } from 'reactflow'
import { create } from 'zustand'

import { uuid } from 'helpers/uuid'
import { SubareaType } from 'types/subarea/subarea'

type CurrentSubareaStore = null | Node

type MapSubareaStore = {
  hiddenSubareasId: string[]
  updateHiddenSubareasId: (values: string[]) => void
  subareas: SubareaType[]
  currentSubarea: CurrentSubareaStore
  updateSubareas: (values: SubareaType[]) => void
  resetCurrentSubarea: () => void
  createSubarea: () => void
  showActionsNewSubarea: () => boolean
  isAirConditioning: boolean
  toggleIsAirConditioning: () => void
}

export const useMapSubarea = create<MapSubareaStore>((set, get) => ({
  hiddenSubareasId: [],
  updateHiddenSubareasId: (values) => {
    set({
      hiddenSubareasId: values,
    })
  },
  subareas: [],
  currentSubarea: null,
  updateSubareas: (subareas) => {
    set({ subareas })
  },
  resetCurrentSubarea: () => set({ currentSubarea: null }),
  createSubarea: () => {
    set({
      currentSubarea: {
        id: uuid(),
        type: 'group',
        data: {
          label: '',
        },
        position: {
          x: 0,
          y: 0,
        },
        style: {
          width: 128,
          height: 38,
          background: '#5B20CE55',
          borderRadius: 5,
          fontSize: 14,
        },
      },
    })
  },
  showActionsNewSubarea: () => get().currentSubarea !== null,
  isAirConditioning: false,
  toggleIsAirConditioning: () => {
    set((state) => ({ isAirConditioning: !state.isAirConditioning }))
  },
}))
