import { Grid, Stack, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Checkbox } from 'components/Checkbox'
import { Input } from 'components/Input'
import { MaskedInputNumber } from 'components/Input/mask/MaskedInputNumber'
import { Select } from 'components/Select'
import { Autocomplete } from 'components/Select/Autocomplete'
import { times } from 'constants/units'
import { CreateParameterType } from 'types/goalAndParameter/create-parameter'
interface SensorSelectType {
  value: string | number
  label: string
  isFixed: boolean
}
interface FormFirstStepOpeningDoorProps {
  isView?: boolean
  getValues: UseFormGetValues<CreateParameterType>
  setValue: UseFormSetValue<CreateParameterType>
  control: Control<CreateParameterType, any>
  register: UseFormRegister<CreateParameterType>
  errors: FieldErrors<CreateParameterType>
  sensors?: any[]
  sensorSelected: SensorSelectType[]
  setSensorsSelected: Dispatch<SetStateAction<SensorSelectType[]>>
  clearErrors: UseFormClearErrors<CreateParameterType>
  watch: UseFormWatch<CreateParameterType>
}

export const FormFirstStepOpeningDoor = ({
  control,
  errors,
  isView,
  sensors,
  sensorSelected,
  setSensorsSelected,
  watch,
  clearErrors,
}: FormFirstStepOpeningDoorProps) => {

  const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          name='sensors'
          placeholder={t('input.selectSensors')}
          isMulti
          isDisabled={isView}
          value={sensorSelected}
          onChange={(values) => {
            setSensorsSelected(values as SensorSelectType[])
          }}
          options={
            sensors?.map((sensor) => ({
              label: sensor.name,
              value: String(sensor.id),
              type: sensor.type,
              sensor: sensor?.id,
              waterInlet: sensor?.waterInlet,
              waterOutlet: sensor?.waterOutlet,
              floor: sensor?.floor,
            })) ?? []
          }
          error={!!errors?.sensors}
          errorMessage={errors?.sensors?.message}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography>{t('openingRules')}</Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Checkbox
            control={control}
            name='qtdTimeChecked'
            disabled={isView}
            onClick={() => clearErrors()}
          />
          <Typography>{t('qtyTimes')}</Typography>
          <Input
            name='numberOfTimes'
            type='number'
            control={control}
            error={!!errors?.numberOfTimes}
            errorMessage={errors?.numberOfTimes?.message}
            InputProps={{
              inputComponent: MaskedInputNumber as any,
              disabled: !watch('qtdTimeChecked') || isView,
            }}
            sx={{ width: '80px', ml: 2 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Stack direction='row' alignItems='center'>
          <Typography>
            {t('period')} {t('from')}{' '}
          </Typography>
          <Input
            name='periodNUmber'
            type='number'
            control={control}
            error={!!errors?.periodNUmber}
            errorMessage={errors?.periodNUmber?.message}
            disabled={isView}
            InputProps={{
              inputComponent: MaskedInputNumber as any,
              disabled: !watch('qtdTimeChecked') || isView,
            }}
            sx={{ width: '120px', ml: 2 }}
          />
          <Select
            name='periodType'
            options={times.map((time) => ({
              id: time.id,
              name: String(t(time.name)),
            }))}
            control={control}
            fullWidth
            error={!!errors?.periodType}
            errorMessage={errors?.periodType?.message}
            sx={{ width: '120px', ml: 2 }}
            disabled={!watch('qtdTimeChecked') || isView}
          />
        </Stack>
      </Grid>

      <Grid item xs={6} sm={6}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Checkbox
            control={control}
            name='openingTimeChecked'
            disabled={isView}
            onClick={() => clearErrors()}
          />
          <Typography whiteSpace={'nowrap'}>{t('openingTime')}</Typography>
          <Input
            name='openingTimeNumber'
            type='number'
            control={control}
            error={!!errors?.openingTimeNumber}
            errorMessage={errors?.openingTimeNumber?.message}
            InputProps={{
              inputComponent: MaskedInputNumber as any,
              disabled: !watch('openingTimeChecked') || isView,
            }}
            disabled={isView}
            sx={{ width: '80px', ml: 2 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Select
            name='openingTimeType'
            options={times.map((time) => ({
              id: time.id,
              name: String(t(time.name)),
              value: time.value,
            }))}
            control={control}
            fullWidth
            error={!!errors?.openingTimeType}
            errorMessage={errors?.openingTimeType?.message}
            disabled={!watch('openingTimeChecked') || isView}
          />
        </Stack>
      </Grid>
    </>
  )
}
