import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material'
import MuiCheckbox from '@mui/material/Checkbox'
import { Controller } from 'react-hook-form'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any
  name: string
  label?: string
  options: Array<{
    id: string | number
    name: string | number
    [x: string]: string | number | object
  }>
  row?: boolean
  error?: boolean
  helperText?: string
  isDisabled?: boolean
}

export function CheckboxGroup({
  control,
  name,
  label,
  options,
  row = false,
  error,
  helperText,
  isDisabled = false,
}: Props) {
  return (
    <FormControl component='fieldset' error={error}>
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <FormGroup row={row}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              {options.map((option, index) => (
                <FormControlLabel
                  {...field}
                  key={index}
                  value={option.id}
                  control={
                    <MuiCheckbox
                      checked={field?.value?.includes(option.id)}
                      onChange={() => {
                        if (!field?.value?.includes(option.id)) {
                          field.onChange([...field.value, option.id])
                          return
                        }
                        const newValues = field.value.filter(
                          (value: string | number) => value !== option.id,
                        )
                        field.onChange(newValues)
                      }}
                      disabled={isDisabled}
                    />
                  }
                  label={option.name}
                  name={`options[${option.id}]`}
                  // onChange={(event)}
                  inputRef={field.ref}
                />
              ))}
            </>
          )}
        />
      </FormGroup>
      {(error || helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
