import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { Actions } from 'components/Actions'
import { DatePeriod } from 'components/DatePeriod'
import { httpClientDownloadExcel } from 'helpers/httpClientDownloadExcel'
import { useGeneralConsumptionVSGoalChart } from 'pages/dashboard/hooks/water/useGeneralConsumptionVSGoalChart'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface Props {
  isPreview?: boolean
}

export function GeneralConsumptionVSGoalChart(props: Props) {
  const { isPreview } = props
  const {
    chartData,
    chartRef,
    date,
    options,
    setDate,
    t,
    period,
    setPeriod,
    startDate,
    endDate,
    setGeneratingFile,
    generatingFile,
  } = useGeneralConsumptionVSGoalChart({ isPreview })
  return (
    <Box
      sx={{
        gridArea: 'medium',
        borderRadius: 1,
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        maxWidth: '100vw',
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>

      <Stack
        direction={['column', 'row']}
        justifyContent={['space-between']}
        alignItems={'flex-start'}
        marginBottom={3}
      >
        <Typography variant='h2' fontSize='1.125rem' fontWeight='bold'>
          {t('chart.water.generalConsumptionVSTarget')}
        </Typography>

        {!isPreview && (
          <Stack
            direction={['column', 'row']}
            justifyContent={'flex-end'}
            alignItems={['flex-end', 'center']}
            spacing={2}
            width='100%'
          >
            <DatePeriod
              date={date}
              defaultPeriod={period}
              periods={['month', 'year']}
              onChangePeriod={setPeriod}
              onChangeDate={setDate}
            />

            <Stack paddingTop={[2, 0]}>
              <Actions
                color='inherit'
                title='button.title.export'
                options={[
                  {
                    type: 'excel',
                    action: () =>
                      httpClientDownloadExcel({
                        url: '/dashboard-water/general-consumption-vs-goal/export-excel',
                        fileName: 'agua-consumo-geral-vs-meta',
                        endDate,
                        startDate,
                        setGeneratingFile,
                      }),
                  },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </Stack>

      <Chart
        type='bar'
        ref={chartRef}
        options={options}
        data={chartData}
        height={120}
      />
    </Box>
  )
}
