import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { Toggle } from 'components/Toggle'
import { updateInvoiceContractStatus } from 'services/invoice/contract/updateInvoiceContractStatus'
import { InvoiceContractType } from 'types/invoice/invoice-contract'

export function invoiceContractMapper(data: InvoiceContractType[]) {
  const queryClient = useQueryClient()
  const { t } = useTranslation('common')
  return data.map((item) => ({
    ...item,
    id: typeof item?.id !== 'number' ? item?.id?.value : item?.id,
    formattedMonth: moment(item.date).format('MMM/YYYY'),
    formattedExpiration: moment(item.expiration).format('DD/MM/YYYY'),
    formattedTotalPayable: `R$ ${Number(item.totalPayable).toLocaleString(
      'pt-BR',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    )}`,
    muiStatus: (
      <Toggle
        name='toggle'
        label={item.status ? t('paid') : t('notPaid')}
        checked={item.status}
        onChange={async (_, checked) => {
          await updateInvoiceContractStatus(
            typeof item?.id !== 'number' ? item?.id?.value : item?.id,
            {
              date: item?.date,
              expiration: item?.expiration,
              company: item?.company,
              contact: item?.contact,
              regarding: item?.regarding,
              responsible: item?.responsible,
              serviceTime: item?.serviceTime,
              totalPayable: item?.totalPayable,
              pathImage: item?.pathImage,
              status: checked,
            },
          )

          queryClient.invalidateQueries('invoice-contract')
        }}
      />
    ),
  }))
}
