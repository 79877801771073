enum FanSpeedEnum {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

const findFanSpeed = (type: string) => {
  return FanSpeedEnum[type as keyof typeof FanSpeedEnum]
}

export { FanSpeedEnum, findFanSpeed }
