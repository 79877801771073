import { IconButton } from 'components/Button/IconButton'

import { turno1Img } from '../tutorial.images'

export function TurnoContent() {
  return (
    <>
      <p>
        Nesse submódulo são realizados os cadastros dos turnos da empresa para
        que os sensores não registrem mal funcionamento de equipamentos que, na
        realidade, foram desligados pelo fim do expediente. Ele será utilizado
        no cadastro de áreas (Módulo de Mapas {'->'} áreas de Medição), então
        sugerimos que antes de realizar o cadastro de uma área, sempre verifique
        se os turnos já estejam cadastrados.
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>
        <strong>Cadastrar Turno</strong>
      </p>
      <p>
        <img
          src={turno1Img}
          alt='cadastrar turno'
          style={{
            maxWidth: 300,
          }}
        />
      </p>
      <p>Para realizar o cadastro de um novo turno, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Mapa
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Turno
      </p>
      <strong>Passo 4:</strong> Clicar no botão “+ Novo”{' '}
      <IconButton iconName='add' variant='text' color='inherit' />.
      <p>
        <strong>Passo 5:</strong> Preencher os campos que são: nome do turno,
        selecionar os dias da semana do trabalho.
      </p>
      <p>
        <strong>Passo 6:</strong> Dentro de cada dia da semana, deve informar o
        horário início do turno e o final.{' '}
      </p>
      <p>
        <strong>Passo 7:</strong> Caso tenha intervalo, deve ser clicado o
        botão, onde vai aparecer um novo cadastro, onde é informado o nome do
        intervalo, o horário início e o horário final, também pode ser
        cadastrado outros intervalos e a opção de repetir esses intervalos para
        outros dias da semana.
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        <strong>Importante:</strong> Essa informação é importante para áreas.
      </p>
      <p>
        <strong>Editar Turno</strong>
      </p>
      <p>Para realizar a edição de um turno, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Mapa
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Turnos
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a área que vai editar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos que aparecerão: nome do turno,
        horário início e fim do dia da semana, intervalo e configurações de
        intervalo, horário início e fim do intervalo, adicionais mais de um
        intervalo e repeti-lo para outros dias da semana.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        <strong>Excluir Turno</strong>
      </p>
      <p>Para realizar a exclusão de um turno, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Mapa
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Turno
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a área que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-lo, selecione sim e o turno será permanentemente excluído do
        sistema.
      </p>
      <p>
        <strong>Visualizar Turno</strong>
      </p>
      <p>Para realizar visualizar os dados de um turno, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Mapa
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Turno
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o turno que vai visualizar dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro. Esses dados serão mostrados sem poder realizar edição
        neles.
      </p>
    </>
  )
}
