import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

type MessageType = {
  message: string
}

interface ErrorResponse {
  message?: string | string[]
  errors?: string[] | string | MessageType[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(error: AxiosError | any): void {
  if (typeof error?.response?.data === 'string') {
    toast.error(error?.response?.data)
    return
  }

  const data = error?.response?.data as ErrorResponse

  if (data?.message) {
    const message = data.message
    if (Array.isArray(message)) {
      message.forEach((msg) => {
        toast.error(msg)
      })

      return
    }

    const stringMessage = message?.includes('Cannot')
      ? 'Error! The Server is offline or the called route does not exist.'
      : message

    toast.error(stringMessage)
    return
  }

  if (data?.errors) {
    const message = data.errors
    if (Array.isArray(message)) {
      message.forEach((msg) => {
        toast.error(typeof msg === 'string' ? msg : msg?.message)
      })

      return
    }

    const stringMessage = message?.includes('Cannot')
      ? 'Error! The Server is offline or the called route does not exist.'
      : message

    toast.error(stringMessage)
    return
  }

  if (error?.response?.data) {
    const code = error?.response?.status
    const data = error?.response?.data
    if (code === 400) {
      if (Array.isArray(data)) {
        data.forEach((msg) => {
          toast.error(msg.message)
        })
        return
      }
      toast.error(data)
      return
    }
    toast.error(JSON.stringify(error?.response?.data, undefined, 2))
    return
  }
  const stringMessage = JSON.stringify(error?.message, undefined, 2)

  switch (stringMessage) {
    case '"Network Error"':
      toast.error('Server communication error.')
      break
    default:
      toast.error(stringMessage)
  }
}
