import { Warning } from '@mui/icons-material'
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'

import { Autocomplete } from 'components/Select/Autocomplete'
import { useGroupAirQualityChart } from 'pages/dashboard/hooks/gas/useGroupAirQualityChart'

interface Props {
  isPreview?: boolean
}

export function GroupAirQualityChart(props: Props) {
  const { isPreview } = props

  const formatNumber = (number: any) => {
    const parts = number.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return parts.join(',')
  }

  const { t, items, generatingFile, areas, setSelectArea, areaId } =
    useGroupAirQualityChart()

  return (
    <Box
      sx={{
        gridArea: 'top-right',
        borderRadius: 1,
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        overFlowY: 'auto',
        minHeight: !isPreview ? 400 : 200,
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        position: 'relative',
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>

      <Stack
        direction={['row']}
        justifyContent={['space-between']}
        alignItems={['flex-start']}
        marginBottom={3}
      >
        <Typography variant='h2' fontSize='1.125rem' fontWeight='bold'>
          {t('chart.gas.airQualityRealTime')}
        </Typography>

        {!isPreview && (
          <Stack
            direction={['column', 'row']}
            justifyContent={'flex-end'}
            alignItems={['flex-end', 'flex-start']}
            spacing={2}
            width={['100%', 'auto']}
            marginLeft={'auto'}
          >
            <Stack
              direction={['column', 'row']}
              justifyContent={'flex-end'}
              alignItems={['flex-end', 'center']}
              spacing={2}
              width={['100%', 'auto']}
              flexWrap='wrap'
              gap={2}
            >
              <Box minWidth={200} width={['100%', 'auto']}>
                <Autocomplete
                  name='areas'
                  placeholder={t('input.selectArea')}
                  value={areas?.find((item) => item.value === areaId)}
                  options={areas ?? []}
                  onChange={(value: any) => {
                    setSelectArea(value?.value as string)
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>

      {!items?.length && (
        <>
          <Stack width={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
              mb={3}
            >
              <Warning color='disabled' fontSize='large' />
              <Typography variant='subtitle1'>{t('noData')}</Typography>
            </Box>
          </Stack>
        </>
      )}

      <Box
        id='groupAirQuality'
        display='flex'
        flexWrap='wrap'
        paddingTop={isPreview ? 1 : 3}
        gap={2}
        sx={{
          justifyContent: 'space-between',
          maxHeight: isPreview ? 180 : 900,
          overflowX: 'auto',
          overFlowY: 'auto',
          maxWidth: ['100vw', 'calc(100vw - 150px)'],
          '&::-webkit-scrollbar': {
            width: 6,
            height: 6,
            background: 'transparent',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => theme.palette.background.default,
          },
        }}
      >
        {items.map((item: any, index: any) => (
          <Stack key={index} alignItems={'center'} justifyContent={'center'}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-flex',
                width: isPreview ? 160 : 180,
                height: isPreview ? 160 : 180,
              }}
            >
              <CircularProgress
                variant='determinate'
                sx={{
                  position: 'absolute',
                  left: 0,
                  color: '#4a4b68',
                }}
                thickness={4}
                value={100}
                size={isPreview ? 160 : 180}
              />
              <CircularProgress
                variant='determinate'
                value={Number(item.value)}
                size={isPreview ? 160 : 180}
                thickness={4}
                sx={{
                  color: item.color,
                  position: 'absolute',
                  left: 0,
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant={isPreview ? 'h1' : 'h2'}
                  component='div'
                  color='text.primary'
                  fontWeight={'bold'}
                  fontSize='1.15rem'
                >
                  {formatNumber(item.value)}
                  <small>ppm</small>
                </Typography>
                <Typography
                  variant='body1'
                  component='div'
                  color='text.secondary'
                  fontSize='0.75rem'
                  whiteSpace={'pre-wrap'}
                  textAlign={'center'}
                  maxWidth={isPreview ? 160 : 150}
                >
                  {item.name}
                </Typography>
              </Box>
            </Box>
          </Stack>
        ))}
      </Box>
    </Box>
  )
}
