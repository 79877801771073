import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  getDescriptionBySensorCompanyType,
  getIconBySensorCompanyType,
} from 'helpers/sensor-company-type.helper'
import { CircularGraphic } from 'pages/dashboard/components/graphic/Circular'
import { useMonitoringSensorStore } from 'store/monitoring-sensor.store'

export const NumberSensorsCompany = () => {
  const { currentCompany } = useMonitoringSensorStore()
  const { i18n } = useTranslation('common')
  const lang = i18n.language

  const percentage = () => {
    const totalSensor = currentCompany?.information?.totalSensor
    const totalSensorOnline = currentCompany?.information?.totalSensorOnline
    if (totalSensor && totalSensorOnline) {
      const result = (totalSensorOnline * 100) / totalSensor

      return result.toLocaleString(lang === 'pt' ? 'pt-BR' : 'en', {
        maximumFractionDigits: 2,
      })
    }

    return 0
  }

  return (
    <Stack
      direction='column'
      sx={{
        background: (theme) => theme.palette.background.paper,
        borderRadius: 1,
        p: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: 24,
          fontWeight: 'bold',
          color: (theme) => theme.palette.text.primary,
          mb: 2,
        }}
      >
        Quantitativo de sensores
      </Typography>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='column'>
          <CircularGraphic
            isPreview={true}
            color='#FFD933'
            label='sensores ligados'
            value={percentage() ?? 0}
            isHoverDisabled={true}
            fontSizeLabel={10}
            fontSizeValue={16}
            size={120}
            type='percentage'
          />
        </Stack>
        <Stack direction='column' sx={{ width: '50%' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={10} sx={{ mb: 2 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Total de sensores
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {currentCompany?.information.totalSensor}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ mb: 2 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Sensores ligados
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {currentCompany?.information.totalSensorOnline}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ mb: 2 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Sensores desligados
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {currentCompany?.information.totalSensorOffline}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>

      <Stack>
        <Typography sx={{ my: 2 }}>Tipos de sensores da unidade</Typography>

        <Grid
          container
          spacing={1}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          {currentCompany?.information.sensors.map((sensor) => {
            const IconComponent = getIconBySensorCompanyType(sensor.type)
            const description = getDescriptionBySensorCompanyType(sensor.type)

            return (
              <Grid item xs={5} key={sensor.type}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid
                    container
                    direction='row'
                    alignItems='flex-start'
                    justifyContent='center'
                  >
                    <Grid item xs={2}>
                      <IconComponent
                        height={18}
                        width={18}
                        style={{ marginRight: '5px' }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.text.primary,
                          ml: 1,
                          fontSize: 11,
                        }}
                      >
                        {description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                      ml: 1,
                      fontSize: 11,
                    }}
                  >
                    {sensor.count}
                  </Typography>
                </Stack>
              </Grid>
            )
          })}
        </Grid>
      </Stack>
    </Stack>
  )
}
