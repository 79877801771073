/**
 * Este arquivo é um ENUM que contém os tipos de equipamentos que podem ser cadastrados no sistema.
 * Estes enums precisam ser mantidos sincronizados com os valores do backend.
 */
export enum EquipmentTypeEnum {
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  CONDENSER = 'CONDENSER',
  WATER_TANK = 'WATER_TANK',
  COMPRESSOR_AIR = 'COMPRESSOR_AIR',
  GENERATOR = 'GENERATOR',
  DEHUMIDIFIER = 'DEHUMIDIFIER',
  EXHAUST = 'EXHAUST',
  SPLITTER = 'SPLITTER',
  NOBREAK = 'NOBREAK',
  FUEL_TANK = 'FUEL_TANK',
  NITROGEN_PLANT = 'NITROGEN_PLANT',
  DRYBOX = 'DRYBOX',
  OPENING = 'OPENING',
  BTN_PANIC = 'BTN_PANIC',
  ALL = 'ALL',
  OTHER = 'OTHER',
  CONTROLLER = 'CONTROLLER',
}

export const getAllEquipmentTypeEnum = () => {
  return [
    EquipmentTypeEnum.AIR_CONDITIONING,
    EquipmentTypeEnum.CONDENSER,
    EquipmentTypeEnum.WATER_TANK,
    EquipmentTypeEnum.COMPRESSOR_AIR,
    EquipmentTypeEnum.GENERATOR,
    EquipmentTypeEnum.DEHUMIDIFIER,
    EquipmentTypeEnum.EXHAUST,
    EquipmentTypeEnum.SPLITTER,
    EquipmentTypeEnum.NOBREAK,
    EquipmentTypeEnum.FUEL_TANK,
    EquipmentTypeEnum.NITROGEN_PLANT,
    EquipmentTypeEnum.DRYBOX,
    EquipmentTypeEnum.OPENING,
    EquipmentTypeEnum.BTN_PANIC,
    EquipmentTypeEnum.ALL,
    EquipmentTypeEnum.OTHER,
    EquipmentTypeEnum.CONTROLLER,
  ]
}
