/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Visibility } from '@mui/icons-material'
import { ButtonGroup, Stack, TableCell, TableRow } from '@mui/material'
import { findModule } from 'core/enums/Module'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Actions } from 'components/Actions'
import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Checkbox } from 'components/Checkbox'
import { Table, TableActionType } from 'components/Table'
import { MenuProps, mainMenu } from 'configs/menuConfig'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { httpClient } from 'services/api/httpClient'
import { notificationService } from 'services/notification.service'
import { NotificationType } from 'types/notification'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

export function GeneralNotificationPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [activeButton, setActiveButton] = useState<
    'all' | 'unread' | 'starred'
  >('unread')
  const [selectedChecked, setSelectedChecked] = useState<number[]>([])
  const [page, setPage] = useState(1)

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<NotificationType>,
    Error
  >({
    queryKey: ['general-notifications', page, search, activeButton],
    queryFn: () =>
      notificationService.findAllByUser({ page, search, type: activeButton }),
    staleTime: 0,
    cacheTime: 0,
  })
  const notifications = data?.items

  useEffect(() => {
    setPage(1)
  }, [activeButton])

  const handleDelete = (item: NotificationType) => {
    httpDelete(`/notification/${item.id.value}`, refetch)
  }

  const handleRead = async (item: NotificationType) => {
    try {
      await httpClient.put(`/notification/read/${item?.id.value}`)
      refetch()
    } catch (error: any) {
      getErrorMessage(error)
    }
  }

  const getRoute = (menus: MenuProps[], module: string, route: string) => {
    let findRoute = route

    if (findRoute?.length > 0) return findRoute

    menus?.forEach((menu) => {
      if (findRoute?.length > 0) return findRoute

      findRoute =
        menu?.module && module?.toLowerCase() === menu?.module?.toLowerCase()
          ? menu?.route
          : findRoute

      if (findRoute?.length > 0) return findRoute

      if (menu?.submenu && findRoute?.length <= 0) {
        findRoute = getRoute(menu.submenu, module, findRoute) ?? findRoute
      }
    })

    return findRoute
  }

  const handleViewModule = async (item: NotificationType) => {
    const route = getRoute(mainMenu, item?.feature?.name ?? '', '')

    if (!route?.length) {
      return toast.warning('Módulo não encontrado.')
    }

    navigate(route)
  }

  const tableActions: TableActionType<NotificationType>[] = [
    {
      type: 'show',
      onClick: (row) => handleViewModule(row),
    },
    {
      type: 'check',
      onClick: (row) => handleRead(row),
      title: t('button.title.read') ?? '',
    },
    {
      type: 'delete',
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  const buttons = [
    { name: t('all'), id: 'all' },
    { name: t('unread'), id: 'unread' },
    { name: t('starred'), id: 'starred' },
  ]

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedChecked(
        notifications?.map((item: NotificationType) =>
          Number(item?.id.value),
        ) ?? [],
      )
      return
    }

    setSelectedChecked([])
  }

  const columns: ColumnType[] = [
    {
      id: 'id',
      label: (
        <Checkbox
          sx={{ padding: 0 }}
          name='all'
          onChange={(_, checked) => handleSelectAll(checked)}
        />
      ),
    },
    {
      id: 'name',
      label: t('nameNotification'),
    },
    {
      id: 'module',
      label: t('module'),
    },
  ]

  const handleToggleSelect = (item: NotificationType) => {
    if (selectedChecked?.find((n) => n === Number(item?.id.value))) {
      setSelectedChecked(
        (state) => state?.filter((s) => s !== Number(item?.id.value)),
      )
      return
    }

    return setSelectedChecked((state) => [...state, Number(item.id.value)])
  }

  const handleToggleStar = async (item: NotificationType) => {
    try {
      await httpClient.put(`/notification/starred/${item?.id.value}`)
      refetch()
    } catch (error: any) {
      getErrorMessage(error)
    }
  }

  const handleDeleteAll = async () => {
    try {
      await httpClient.post('/notification/delete/all', {
        notifications: selectedChecked,
      })
      toast.success('Notificações excluídas com sucesso.')
      refetch()
    } catch (error: any) {
      getErrorMessage(error)
    }
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <ButtonGroup
          variant='outlined'
          size='large'
          aria-label='outlined button group'
          sx={{
            '& button': {
              borderColor: (theme) => theme.palette.primary.main,
            },
          }}
        >
          {buttons.map((item, index) => (
            <Button
              key={index}
              variant={activeButton === item.id ? 'contained' : undefined}
              onClick={() =>
                setActiveButton(item.id as 'all' | 'unread' | 'starred')
              }
              sx={{
                textTransform: 'inherit',
              }}
            >
              {item.name}
            </Button>
          ))}
        </ButtonGroup>

        {!!selectedChecked?.length && (
          <IconButton
            iconName='delete'
            variant='outlined'
            title={t('button.title.delete') ?? ''}
            onClick={handleDeleteAll}
          />
        )}
      </Stack>

      <Table<NotificationType>
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.total,
          onChangePage: setPage,
        }}
      >
        {notifications?.map((row, index: number) => (
          <TableRow hover key={index}>
            <TableCell align='left' sx={{ border: 'none' }}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={selectedChecked.includes(Number(row?.id.value))}
                  onChange={() => handleToggleSelect(row)}
                />
                <IconButton
                  iconName={row?.starred ? 'star' : 'starOutline'}
                  color={row?.starred ? 'warning' : 'inherit'}
                  size='small'
                  onClick={() => handleToggleStar(row)}
                />
                <Visibility color={row.read ? 'success' : 'inherit'} />
              </Stack>
            </TableCell>
            <TableCell align='left' sx={{ border: 'none' }}>
              {row?.message}
            </TableCell>
            <TableCell align='left' sx={{ border: 'none' }}>
              {row?.feature?.name ? findModule(row?.feature?.name) : ''}
            </TableCell>
            <TableCell align='right' sx={{ border: 'none' }}>
              <Actions
                color='inherit'
                options={tableActions?.map((item) => ({
                  type: item?.type ?? 'add',
                  disabled: item?.disabled,
                  router: item?.router,
                  title: item?.title,
                  action: item?.onClick ? () => item.onClick?.(row) : undefined,
                }))}
              />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  )
}
