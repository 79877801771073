export function EquipamentosContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros dos equipamentos da
        empresa. Essa informação será importante para identificar onde ficarão
        cada equipamento da fábrica e verificação sobre. Para realizar o
        cadastro de equipamentos, é necessário cadastrar inicialmente os
        sensores que irão medir esses equipamentos. Ele será utilizado no Montar
        Mapa (Módulo de Mapas {'->'} Montar Mapa), Monitoramento de sensores
        (Módulo de Mapa {'->'} Monitoramento de Sensores) Dashboard e Calendário
        de Manutenção (Manutenção {'->'} Calendário de Manutenção e Pré OS),
        então sugerimos que antes de realizar o cadastro desses módulos, sempre
        verifique se os equipamentos já foram cadastrados. Caso esse submódulo
        não apareça no seu sistema atualmente, provavelmente o seu tipo de
        perfil não permite o acesso. Caso necessite o uso desse submódulo,
        solicite ao administrador do sistema.
      </p>
      <p>
        <strong>Cadastrar Equipamento</strong>
      </p>

      <p>Para realizar o cadastro de um novo equipamento, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Equipamentos
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os campos que são: informar se é
        alimentador (que é um equipamento que atribui algo a outro equipamento),
        o status dele de ativo ou não, nome do equipamento, código, modelo,
        marca, upload da imagem do equipamento, especificações especiais, nome
        da especificação, valor, colocar mais especificações e vincular um ou
        mais sensor no equipamento.
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar.{' '}
      </p>
      <i>
        <strong>Importante:</strong> Caso o sensor não seja adicionado, não será
        possível identificar caso de problemas ou medir algo do equipamento.
      </i>
      <p>
        <strong>Editar Equipamentos</strong>
      </p>

      <p>Para realizar a edição de um equipamento, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Equipamentos
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o equipamento que vai editar dentro
        da listagem e selecionar o botão de ações{' '}
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar” .
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos que aparecerão: alimentador
        (que é um equipamento que atribui algo a outro equipamento), o status de
        ativo ou não, nome do equipamento, código, modelo, marca, upload da
        imagem do equipamento, especificações especiais, nome da especificação,
        valor, colocar mais especificações e vincular um ou mais sensor no
        equipamento.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>Importante:</strong> Caso o sensor não seja adicionado, não será
        possível identificar caso de problemas ou medir algo do equipamento.
      </p>
      <p>
        <strong>Excluir Equipamento</strong>
      </p>

      <p>Para realizar a exclusão de um equipamento, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Equipamentos
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o equipamento que vai excluir
        dentro da listagem e selecionar o botão de ações{' '}
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir” .
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-lo, selecione sim e o equipamento será permanentemente excluído
        do sistema.
      </p>
      <p>
        <strong>Visualizar Equipamento</strong>
      </p>

      <p>Para realizar visualizar os dados de um equipamento, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Equipamentos
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o equipamento que vai visualizar
        dentro da listagem e selecionar o botão de ações{' '}
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar” .
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro. Esses dados serão mostrados sem poder realizar edição
        neles.
      </p>
    </>
  )
}
