import { httpClient } from '../api/httpClient'

const listSensorsFindInformation = async () => {
  const response = await httpClient.get('/sensor/information')

  return response.data
}

const listSensorsCompanyInformation = async () => {
  const response = await httpClient.get('/company/list')

  return response.data
}

export const monitoringUnitService = {
  listSensorsFindInformation,
  listSensorsCompanyInformation,
}
