import { httpClient } from 'services/api/httpClient'
import { FeatureType } from 'types/feature/feature'

const getAllFeature = async (): Promise<FeatureType[]> => {
  const response = await httpClient.get('/feature/find-all')
  return response.data
}

export const featureService = {
  getAllFeature,
}
