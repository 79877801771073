import { styled } from '@mui/system'
import React, { useEffect, useRef } from 'react'

import { addAlpha } from 'helpers/addAlpha'

interface StyledTemperatureBoxProps {
  temperature: number
}

const StyledTemperatureBox = styled('div')<StyledTemperatureBoxProps>(({
  theme,
}) => {
  return {
    position: 'absolute',
    top: -130,
    left: -130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    zIndex: 1,
    opacity: 0.8,
    filter: 'brightness(150%)',
  }
})

interface TemperatureBoxProps {
  temperature: number
}

const TemperatureBox: React.FC<TemperatureBoxProps> = ({ temperature }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')

      if (context) {
        const gradient = context.createRadialGradient(
          130,
          130,
          0,
          150,
          150,
          100,
        )

        const colors = [
          { threshold: 40, color: '#df5751' },
          { threshold: 36, color: '#f09e56' },
          { threshold: 32, color: '#f9e47e' },
          { threshold: 28, color: '#3cb470' },
          { threshold: 24, color: '#275fbd' },
          { threshold: 20, color: '#8a60d3' },
          { threshold: 0, color: '#ce74c2' },
        ]

        let selectedColor = colors[0].color
        for (const { threshold, color } of colors) {
          if (temperature <= threshold) {
            selectedColor = color
            // break
          }
        }

        gradient.addColorStop(0, addAlpha(selectedColor, 0.7))
        gradient.addColorStop(0.3, addAlpha(selectedColor, 0.5))
        gradient.addColorStop(0.5, addAlpha(selectedColor, 0.2))
        gradient.addColorStop(1, 'rgba(255,255,255,0)')

        context.fillStyle = gradient
        context.fillRect(0, 0, canvas.width, canvas.height)
      }
    }
  }, [temperature])

  return (
    <StyledTemperatureBox temperature={temperature}>
      <canvas ref={canvasRef} width={300} height={300} />
    </StyledTemperatureBox>
  )
}

export default TemperatureBox
