export function DashboardWaterTankLevelContent() {
  return (
    <>
      <p>
        Nesse gráfico é possível verificar o nível da caixa d’água por meio de
        filtros de semana, mês e ano. Ao passar o mouse por cima do gráfico, é
        mostrado o valor que o gráfico aponta de nível da caixa naquele período.
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        nível de caixa d’água, caso esse gráfico não esteja disponibilizando o
        valor, será necessário realizar o cadastro dos sensores em Configurações{' '}
        {'->'} Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
