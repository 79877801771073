import { Dispatch, SetStateAction } from 'react'
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import { NotificationSettingFormType } from 'types/notification-setting'

import { SensorSelectType } from '../FormParameterModal'
import { FormStepNotification } from './components/FormStepNotification'
import { FormStepUsers } from './components/FormStepUsers'
import { StepperFormNotification } from './components/Stepper'

interface IFormNotificationProps {
  isView?: boolean
  errors?: FieldErrors<NotificationSettingFormType>
  clearErrors: UseFormClearErrors<NotificationSettingFormType>
  control: Control<NotificationSettingFormType, any>
  watch: UseFormWatch<NotificationSettingFormType>
  setValue: UseFormSetValue<NotificationSettingFormType>
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  sensorSelected: SensorSelectType[]
  setSensorsSelected: Dispatch<SetStateAction<never[]>>
  sensors?: any[]
  types?: {
    id: any
    name: string
  }[]
  actions?: {
    id: any
    name: string
  }[]
  modulesData: any
  userData: any
}

const steps = ['notifications.notification', 'notifications.users']

export const FormNotification = ({
  activeStep,
  setActiveStep,
  isView,
  sensorSelected,
  setSensorsSelected,
  sensors,
  errors,
  clearErrors,
  control,
  setValue,
  watch,
  actions,
  modulesData,
  types,
  userData,
}: IFormNotificationProps) => {
  const formNotification = [
    {
      id: 0,
      component: (
        <FormStepNotification
          isView={isView}
          errors={errors}
          control={control}
        />
      ),
    },
    {
      id: 1,
      component: (
        <FormStepUsers
          isView={isView}
          actions={actions}
          modulesData={modulesData}
          types={types}
          userData={userData}
          errors={errors}
          sensorSelected={sensorSelected}
          setSensorsSelected={setSensorsSelected}
          sensors={sensors}
          control={control}
          setValue={setValue}
          watch={watch}
          clearErrors={clearErrors}
        />
      ),
    },
  ]

  return (
    <>
      <StepperFormNotification
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
      />
      {activeStep === steps.length ? null : (
        <>{formNotification[activeStep].component}</>
      )}
    </>
  )
}
