const converterMwhToKwh = (value: number): number => {
  return value * 1000
}
const converterKwhToMwh = (value: number): number => {
  return value / 1000
}

// converter ex: 1000 to 1k
const converterToK = (num: number): string => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1)
  } else {
    return num.toString()
  }
}

// converter string ex: 2.000,00 to number 2000.00
const converterStringToNumber = (value: string): number => {
  return Number(value.replace(/\./g, '').replace(',', '.'))
}

export {
  converterMwhToKwh,
  converterKwhToMwh,
  converterToK,
  converterStringToNumber,
}
