import { useAuthStore } from 'store/auth.store'

export function usePermission(module?: string, permission?: string) {
  const { user } = useAuthStore((state) => state)

  if (!module || !permission) {
    return { allowed: true }
  }

  if (!user || !user?.profile) {
    return { allowed: false }
  }

  if (user && user?.profile?.name?.toLowerCase()?.includes('admin')) {
    return { allowed: true }
  }

  const findModule = user?.profile?.permissions?.filter((item: any) =>
    item?.feature?.name?.includes(module),
  )

  if (!findModule?.length) {
    return { allowed: false }
  }

  const findPermission = findModule?.find((item: any) =>
    item?.permissionType?.includes(permission),
  )

  if (!findPermission) {
    return { allowed: false }
  }

  return { allowed: true }
}
