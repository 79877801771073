import { Stack } from '@mui/material'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { DropzoneFileUploading } from 'components/Dropzone/FileUploading'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'

export function MapUploadPage() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const handleUploadFile = async (file: File) => {
    let findOldFileId = ''
    const response = await httpClient.get('/map-image')

    findOldFileId = response.data?.items?.find(
      (item: { active: boolean }) => item?.active === true,
    )?.entityId?.value

    setIsUploading(true)

    const formData = new FormData()
    formData.append('file', file)

    httpClient
      .post('/map-image/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1),
          )
          setProgress(progress)
        },
      })
      .then(() => {
        queryClient.invalidateQueries('map-image')
        if (findOldFileId) {
          httpClient
            .delete(`/map-image/${findOldFileId}`)
            .then(() => {
              navigate('/map/mount-map')
            })
            .catch(() => {
              navigate('/map/mount-map')
            })
        } else {
          navigate('/map/mount-map')
        }
      })
      .catch((error) => {
        getErrorMessage(error)
        setIsUploading(false)
      })
  }

  return (
    <>
      <Stack
        alignItems={'center'}
        justifyContent='center'
        sx={{ minHeight: 'calc(100vh - 148px)', height: '100%' }}
      >
        <DropzoneFileUploading
          progress={progress}
          isUploading={isUploading}
          onChangeFile={(file) => handleUploadFile(file)}
        />
      </Stack>
    </>
  )
}
