import { FC, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { Node } from 'reactflow'

import { IconButton } from 'components/Button/IconButton'
import { httpClient } from 'services/api/httpClient'

export const typesNodesValid = ['feeder', 'equipment', 'sensor', 'scheme']

interface Props {
  nodes: Node<any, string | undefined>[]
  setNodes: (data: Node[]) => void
  onSuccess: () => void
}

export const SaveMapChanges: FC<Props> = memo(
  ({ nodes, setNodes, onSuccess }) => {
    const { t } = useTranslation('common')
    const queryClient = useQueryClient()
    const [isLoading, setIsLoading] = useState(false)

    const updateOrCreateEquipmentAndSensor = async () => {
      const createNodes = nodes.filter((node) =>
        typesNodesValid.includes(node?.type ?? ''),
      )

      for (const node of createNodes) {
        const linkedAreaId = node.data?.linkedAreaId

        const response = await httpClient({
          method: linkedAreaId ? 'PUT' : 'POST',
          url: 'linked-area'.concat(linkedAreaId ? `/${linkedAreaId}` : ''),
          data: {
            idMap: node.data?.idMap ?? node.id,
            type: node.type,
            coordinateX: node.position.x,
            coordinateY: node.position.y,
            area: {
              value:
                node.data?.area?.areaID.value ??
                node.data?.parent?.areaID?.value ??
                '',
            },
            sensor: {
              value:
                node.data?.equipment?.sensors?.[0]?.sensor?.id?.value ??
                node.data?.equipment?.sensors?.[0]?.sensor?.id ??
                '',
            },
            equipment: {
              value:
                node.data?.equipment?.id?.value ?? node.data?.equipment?.id,
            },
          },
        })

        node.data.id = response.data.id
      }

      setNodes(nodes as Node[])
    }

    const updateSubareas = async () => {
      const subareas = nodes.filter((node) => node.type === 'group')
      for (const subarea of subareas) {
        const linkedAreaId = subarea?.data?.areaID?.value
        await httpClient.put(`/area/${linkedAreaId}`, {
          coordinateX: subarea.position.x,
          coordinateY: subarea.position.y,
          width: subarea.style?.width ?? 0,
          height: subarea.style?.height ?? 0,
          idShift: subarea.data.shiftID.value,
          idMap: subarea.data?.idMap ?? '',
          color: subarea.data?.color ?? '#5B20CE60',
          name: subarea.data?.nomeSubArea ?? '',
        })
      }
    }

    const handleSaveMap = async () => {
      setIsLoading(true)

      try {
        await updateOrCreateEquipmentAndSensor()
        await updateSubareas()

        toast.success(t('savedInformation'))
        queryClient.invalidateQueries('company-map')
        queryClient.invalidateQueries('subareas')
        queryClient.invalidateQueries('map-image')
        queryClient.invalidateQueries('linked-areas')
        queryClient.invalidateQueries('list-branches')
        onSuccess()
      } catch (error: any) {
        toast.error(error?.response?.data?.message ?? error?.message)
        setIsLoading(false)
      }
    }

    return (
      <IconButton
        iconName='check'
        title={t('button.title.save') ?? ''}
        variant='contained'
        loading={isLoading}
        onClick={handleSaveMap}
        sx={{
          position: 'fixed',
          right: 310,
          bottom: 14,
        }}
      />
    )
  },
)
