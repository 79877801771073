import { Control, FieldErrors, UseFormWatch } from 'react-hook-form'

import { CreateParameterType } from 'types/goalAndParameter/create-parameter'

import { CompanyAreaSubAreaForm } from '../../CompanyAreaSubAreaFom'

type Props = {
  control: Control<CreateParameterType>
  watch: UseFormWatch<CreateParameterType>
  errors: FieldErrors
  isView?: boolean
}

export const AirConditioningFirst = ({
  control,
  errors,
  watch,
  isView,
}: Props) => {
  return (
    <>
      <CompanyAreaSubAreaForm
        control={control}
        errors={errors}
        watch={watch}
        isView={isView}
      />
    </>
  )
}
