import * as yup from 'yup'

export const schemaCreatePassword = () =>
  yup.object().shape({
    password: yup.string().required().min(6),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas precisam ser iguais')
      .required(),
  })
