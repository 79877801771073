import { CircularProgress, DialogActions, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Button } from '../../Button'

interface Props {
  children?: React.ReactNode
  onCancel?: () => void
  onSave?: () => void
  onSaveMutation?: () => void
  isView?: boolean
  isSubmit?: boolean
  isLoading?: boolean
  disabledSubmit?: boolean
  sx?: SxProps
  handleNext?: () => void
  handleBack?: () => void
  activeStep?: number
}

export function ModalAction({
  children,
  onCancel,
  onSave,
  onSaveMutation,
  handleNext,
  handleBack,
  isSubmit = true,
  isView = false,
  isLoading,
  disabledSubmit,
  activeStep,
  sx,
}: Props) {
  const { t } = useTranslation('common')
  const handleSave = () => {
    if (isSubmit && onSaveMutation) {
      onSaveMutation()
    } else if (onSave) {
      onSave()
    }
  }
  return (
    <>
      {(onCancel || onSave || handleNext) && !isView && (
        <DialogActions
          sx={{
            '&>:not(:first-of-type)': { marginLeft: 2 },
            justifyContent: ['space-between', 'flex-end'],
            ...sx,
          }}
        >
          {onCancel && handleBack && !handleNext ? (
            activeStep && activeStep === 0 ? (
              <Button
                variant='outlined'
                onClick={onCancel}
                sx={{
                  background: 'transparent',
                  borderColor: (theme) => theme.palette.primary.main,
                }}
                disabled={isLoading}
              >
                {t('button.title.back')}
              </Button>
            ) : (
              <Button
                variant='outlined'
                onClick={handleBack}
                sx={{
                  background: 'transparent',
                  borderColor: (theme) => theme.palette.primary.main,
                }}
                disabled={isLoading}
              >
                {t('button.title.cancel')}
              </Button>
            )
          ) : (
            <Button
              variant='outlined'
              onClick={onCancel}
              sx={{
                background: 'transparent',
                borderColor: (theme) => theme.palette.primary.main,
              }}
              disabled={isLoading}
            >
              {t('button.title.cancel')}
            </Button>
          )}
          {handleNext && (
            <Button
              variant='contained'
              onClick={handleNext}
              type={'button'}
              disabled={isLoading || disabledSubmit}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('button.title.save')
              )}
            </Button>
          )}
          {(onSave || isSubmit) && (
            <Button
              variant='contained'
              onClick={handleSave}
              type={isSubmit ? 'submit' : 'button'}
              disabled={isLoading || disabledSubmit}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('button.title.save')
              )}
            </Button>
          )}

          {children}
        </DialogActions>
      )}
    </>
  )
}
