import { Avatar, AvatarProps, useTheme } from '@mui/material'

import logoHorizontal from '../../assets/images/logo-horizontal.png'
import logoVerticalDark from '../../assets/images/logo-vertical-dark.png'
import logoVertical from '../../assets/images/logo-vertical.png'
import logo from '../../assets/images/logo.png'
import logotipo from '../../assets/images/logotipo.png'

interface Props extends AvatarProps {
  mode?: 'NORMAL' | 'LOGOTIPO' | 'VERTICAL' | 'HORIZONTAL'
}

export function Logo({ mode = 'NORMAL', ...rest }: Props) {
  const theme = useTheme()

  const currentTheme = theme ? theme?.palette?.mode : 'dark'

  const getLogo = (mode: 'NORMAL' | 'LOGOTIPO' | 'VERTICAL' | 'HORIZONTAL') => {
    switch (mode) {
      case 'LOGOTIPO':
        return (
          <Avatar
            src={logotipo}
            alt='Logotipo'
            {...rest}
            sx={{
              borderRadius: 0,
              width: 'auto',
              height: (theme) => theme.spacing(5),
              ...rest?.sx,
            }}
          />
        )
      case 'NORMAL':
        return (
          <Avatar
            src={logo}
            alt='Logo'
            {...rest}
            sx={{
              borderRadius: 0,
              width: (theme) => theme.spacing(26),
              height: (theme) => theme.spacing(5),
              '& img': {
                objectFit: 'contain',
              },
              ...rest?.sx,
            }}
          />
        )
      case 'VERTICAL':
        return (
          <Avatar
            src={currentTheme === 'light' ? logoVerticalDark : logoVertical}
            alt='Logo vertical'
            {...rest}
            sx={{
              borderRadius: 0,
              width: (theme) => theme.spacing(19),
              height: (theme) => theme.spacing(19),
              '& img': {
                objectFit: 'contain',
              },
              ...rest?.sx,
            }}
          />
        )
      case 'HORIZONTAL':
        return (
          <Avatar
            src={logoHorizontal}
            alt='Logo horizontal'
            {...rest}
            sx={{
              borderRadius: 0,
              width: (theme) => theme.spacing(26),
              height: (theme) => theme.spacing(11),
              '& img': {
                objectFit: 'contain',
              },
              ...rest?.sx,
            }}
          />
        )
      default:
        return (
          <Avatar
            src={logotipo}
            alt='Logo'
            {...rest}
            sx={{
              borderRadius: 0,
              width: (theme) => theme.spacing(5),
              height: (theme) => theme.spacing(5),
              ...rest?.sx,
            }}
          />
        )
    }
  }

  const logoComponent = getLogo(mode)

  return logoComponent
}
