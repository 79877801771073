/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { DatePicker } from 'components/DatePicker'
import { Input } from 'components/Input'
import { InputFile } from 'components/Input/InputFile'
import { InputPrice } from 'components/Input/InputPrice'
import { MaskedInputNumber } from 'components/Input/mask/MaskedInputNumber'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { priceConvertStringToNumber } from 'helpers/priceConvertStringToNumber'
import { createInvoiceEnergy } from 'services/invoice/energy/createInvoiceEnergy'
import { updateInvoiceEnergy } from 'services/invoice/energy/updateInvoiceEnergy'
import { CreateInvoiceEnergyType } from 'types/invoice/create-invoice-energy'
import { InvoiceEnergyType } from 'types/invoice/invoice-energy'
import { ModalType } from 'types/modal'

import { schemaCreateInvoiceEnergy } from '../validations/create-invoice-energy.validation'

interface Props extends ModalType {
  data: InvoiceEnergyType | null
  isView?: boolean
}

export function FormInvoiceEnergyModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateInvoiceEnergyType>({
    resolver: yupResolver(schemaCreateInvoiceEnergy),
    defaultValues: {
      monthYear: data?.monthYear ? String(data?.monthYear) : null,
      dueDate: data?.dueDate ? String(data?.dueDate) : null,
      consumption: data?.consumption ? String(data?.consumption) : '',
      consumptionDays: data?.consumptionDays
        ? String(data?.consumptionDays)
        : '',
      generalCost: data?.generalCost ? String(data?.generalCost) : '',
      goal: data?.goal ? String(data?.goal) : '',
      pathFile: data?.pathFile ? String(data?.pathFile) : null,
      totalPayment: data?.totalPayment ? String(data?.totalPayment) : '',
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('invoice-energy')
    onClose()
  }

  const mutationCreate = useMutation(
    (data: CreateInvoiceEnergyType) => {
      data.monthYear = dayjs(data.monthYear).format(
        'YYYY-MM-DD[T12:00:00-04:00]',
      )
      data.dueDate = dayjs(data.dueDate).format('YYYY-MM-DD')
      data.consumption = Number(data.consumption)
      data.consumptionDays = Number(data.consumptionDays)
      data.generalCost = priceConvertStringToNumber(data.generalCost)
      data.goal = priceConvertStringToNumber(data.goal)
      data.totalPayment = priceConvertStringToNumber(data.totalPayment)
      data.status = false
      return createInvoiceEnergy(data)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: CreateInvoiceEnergyType) => {
      update.monthYear = dayjs(update.monthYear).format(
        'YYYY-MM-DD[T12:00:00-04:00]',
      )
      update.dueDate = dayjs(update.dueDate).format('YYYY-MM-DD')
      update.consumption = Number(update.consumption)
      update.consumptionDays = Number(update.consumptionDays)
      update.generalCost = priceConvertStringToNumber(update.generalCost)
      update.goal = priceConvertStringToNumber(update.goal)
      update.totalPayment = priceConvertStringToNumber(update.totalPayment)
      update.status = update.status ?? true
      return updateInvoiceEnergy(Number(data?.id) ?? 0, update)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateInvoiceEnergyType> = (formData) => {
    const { pathFile } = formData
    if (pathFile) {
      const { name } = pathFile as File
      formData.pathFile = name
    }
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }
    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('invoice.energy.view')

    if (data?.id) return t('invoice.energy.edit')

    return t('invoice.energy.new')
  }

  return (
    <>
      <Modal title={getTitle()} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='monthYear'
              views={['year', 'month']}
              control={control}
              inputFormat='MMMM YYYY'
              disableMaskedInput
              label={t('input.monthAndYear') ?? ''}
              error={!!errors?.monthYear}
              errorMessage={errors?.monthYear?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='dueDate'
              control={control}
              inputFormat='DD/MM/YYYY'
              disableMaskedInput
              label={t('input.expiration') ?? ''}
              error={!!errors?.dueDate}
              errorMessage={errors?.dueDate?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='consumption'
              label={
                t('input.consumption', {
                  abbreviation: '(kW)',
                }) ?? ''
              }
              type='number'
              control={control}
              fullWidth
              error={!!errors?.consumption}
              errorMessage={errors?.consumption?.message}
              disabled={isView}
              InputProps={{
                inputComponent: MaskedInputNumber as any,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='consumptionDays'
              label={t('input.daysOfConsumption') ?? ''}
              type='number'
              control={control}
              fullWidth
              error={!!errors?.consumptionDays}
              errorMessage={errors?.consumptionDays?.message}
              disabled={isView}
              InputProps={{
                inputComponent: MaskedInputNumber as any,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='generalCost'
              label={
                t('input.generalCostOfEnergy', {
                  abbreviation: '(R$/kW)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.generalCost}
              errorMessage={errors?.generalCost?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='goal'
              label={
                t('input.goal', {
                  abbreviation: '(R$)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.goal}
              errorMessage={errors?.goal?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='totalPayment'
              label={
                t('input.invoice', {
                  abbreviation: '(R$)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.totalPayment}
              errorMessage={errors?.totalPayment?.message}
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputFile onChange={(file) => setValue('pathFile', file)} />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
