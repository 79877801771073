import { Player } from '@lottiefiles/react-lottie-player'
import { Box } from '@mui/material'

import jsonAnimate from '../../assets/icons/json/loading.json'

export function LoadingLottieFile() {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(255,255,255,0.25)',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 3,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 9999,
        blur: 1,
      }}
    >
      <Box>
        <Player
          autoplay
          loop
          src={jsonAnimate}
          style={{
            height: 200,
            width: 200,
          }}
        />
      </Box>
    </Box>
  )
}
