import './resetPassword.css'

export function ResetPasswordEmailPage() {
  return (
    <>
      <div className='container'>
        <div className='content'>
          <div className='logo'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='209.322'
              height='91.13'
              viewBox='0 0 209.322 91.13'
            >
              <g
                id='Grupo_5535'
                data-name='Grupo 5535'
                transform='translate(-552 -159.527)'
              >
                <g
                  id='Grupo_5071'
                  data-name='Grupo 5071'
                  transform='translate(609 219.732)'
                >
                  <path
                    id='Caminho_7396'
                    data-name='Caminho 7396'
                    d='M809.8,581.781a3.138,3.138,0,0,1-1.827-.532l-.117-1.163a3.039,3.039,0,0,0,1.861.681,1.267,1.267,0,0,0,1.345-1.412c0-2.26-3.057-3.257-3.057-6.082a2.076,2.076,0,0,1,2.227-2.227,2.767,2.767,0,0,1,1.5.4l-.216.947a2.236,2.236,0,0,0-1.213-.382,1.134,1.134,0,0,0-1.2,1.23c0,2.093,3.091,3.223,3.091,6.131A2.222,2.222,0,0,1,809.8,581.781Z'
                    transform='translate(-805.559 -570.597)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7397'
                    data-name='Caminho 7397'
                    d='M819.9,574.821a1.135,1.135,0,0,1-.681-.7c0-.216.482-.681.681-.681a1.085,1.085,0,0,1,.715.681A1.109,1.109,0,0,1,819.9,574.821Zm-.532,8.125v-7.527h1.08v7.527Z'
                    transform='translate(-811.405 -571.83)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7398'
                    data-name='Caminho 7398'
                    d='M826.167,585.038a2.486,2.486,0,0,1-1.4-.4l-.117-1.13a2.129,2.129,0,0,0,1.379.582.833.833,0,0,0,.914-.864c0-1.363-2.21-2.06-2.21-4.121a1.648,1.648,0,0,1,1.761-1.728,2.253,2.253,0,0,1,1.229.349l-.183.914a1.623,1.623,0,0,0-.963-.316.768.768,0,0,0-.8.831c0,1.363,2.244,2.094,2.244,4.071A1.707,1.707,0,0,1,826.167,585.038Z'
                    transform='translate(-814.203 -573.855)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7399'
                    data-name='Caminho 7399'
                    d='M836.711,576.367H835.1v4.868c0,.6.283.831.8.831a1.021,1.021,0,0,0,.515-.117l-.1.947a1.433,1.433,0,0,1-.632.116,1.509,1.509,0,0,1-1.645-1.612v-5.035h-.964v-.947h.964v-1.479l1.063-.5v1.977h1.811Z'
                    transform='translate(-818.535 -571.83)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7400'
                    data-name='Caminho 7400'
                    d='M842.244,581.164c0-2.393,1.1-3.855,2.493-3.855,1.445,0,2.193,1.329,2.193,2.842,0,.266-.017.548-.034.748l-.532.632h-3.041c.066,1.412.681,2.559,1.878,2.559a2.081,2.081,0,0,0,1.362-.482l-.117,1.047a2.215,2.215,0,0,1-1.4.382C843.324,585.036,842.244,583.49,842.244,581.164Zm1.113-.549h2.493a3.923,3.923,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C844.155,578.256,843.491,579,843.357,580.616Z'
                    transform='translate(-823.254 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7401'
                    data-name='Caminho 7401'
                    d='M860.858,584.936V579.3a.931.931,0,0,0-1.047-1.047.984.984,0,0,0-1.1,1.047v5.633h-1.064V579.3a.938.938,0,0,0-1.047-1.047.984.984,0,0,0-1.1,1.047v5.633h-1.08v-7.527h1.047v.764h.017a1.427,1.427,0,0,1,1.479-.864,1.525,1.525,0,0,1,1.529.947,1.711,1.711,0,0,1,1.645-.947,1.659,1.659,0,0,1,1.794,1.795v5.832Z'
                    transform='translate(-829.522 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7402'
                    data-name='Caminho 7402'
                    d='M872.394,582.776a2.172,2.172,0,0,1,1.911-2.41l1.047-.133v-.9a1.015,1.015,0,0,0-1.13-1.1,2.519,2.519,0,0,0-1.346.4l-.2-.88a3.07,3.07,0,0,1,1.678-.449,1.881,1.881,0,0,1,2.06,2.044v3.489a1.536,1.536,0,0,0,.7,1.379v.067l-.648.731a1.567,1.567,0,0,1-.8-.831,1.637,1.637,0,0,1-1.462.847C873.059,585.036,872.394,584.105,872.394,582.776Zm1.927,1.312c.548,0,1.03-.5,1.03-1.3v-1.612l-.814.083c-.665.083-1.1.582-1.1,1.529C873.441,583.64,873.823,584.089,874.321,584.089Z'
                    transform='translate(-838.766 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7403'
                    data-name='Caminho 7403'
                    d='M890.564,581.784v-10.6h1.13v10.6Z'
                    transform='translate(-848.114 -570.668)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7404'
                    data-name='Caminho 7404'
                    d='M899.635,584.936v-5.6a1.019,1.019,0,0,0-1.13-1.08,1.07,1.07,0,0,0-1.179,1.1v5.583h-1.08v-7.527h1.03v.8h.016a1.524,1.524,0,0,1,1.578-.9,1.7,1.7,0,0,1,1.828,1.811v5.816Z'
                    transform='translate(-851.037 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7405'
                    data-name='Caminho 7405'
                    d='M911.074,576.367h-1.612v4.868c0,.6.283.831.8.831a1.021,1.021,0,0,0,.515-.117l-.1.947a1.431,1.431,0,0,1-.631.116A1.509,1.509,0,0,1,908.4,581.4v-5.035h-.964v-.947h.964v-1.479l1.063-.5v1.977h1.811Z'
                    transform='translate(-856.794 -571.83)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7406'
                    data-name='Caminho 7406'
                    d='M916.606,581.164c0-2.393,1.1-3.855,2.493-3.855,1.446,0,2.193,1.329,2.193,2.842,0,.266-.016.548-.034.748l-.531.632h-3.041c.067,1.412.681,2.559,1.878,2.559a2.081,2.081,0,0,0,1.362-.482l-.116,1.047a2.217,2.217,0,0,1-1.4.382C917.686,585.036,916.606,583.49,916.606,581.164Zm1.113-.549h2.493a3.952,3.952,0,0,0,.016-.465c0-.931-.4-1.894-1.113-1.894C918.517,578.256,917.853,579,917.719,580.616Z'
                    transform='translate(-861.513 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7407'
                    data-name='Caminho 7407'
                    d='M930.422,581.305a1.553,1.553,0,0,1-1.5-1.728v-9.455h1.063v9.422a.758.758,0,0,0,.615.831Z'
                    transform='translate(-867.852 -570.122)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7408'
                    data-name='Caminho 7408'
                    d='M935.22,574.821a1.135,1.135,0,0,1-.681-.7c0-.216.482-.681.681-.681a1.084,1.084,0,0,1,.715.681A1.109,1.109,0,0,1,935.22,574.821Zm-.532,8.125v-7.527h1.08v7.527Z'
                    transform='translate(-870.739 -571.83)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7409'
                    data-name='Caminho 7409'
                    d='M942.136,588.109a2.286,2.286,0,0,1-2.327-2.459,2.059,2.059,0,0,1,1.146-2.11l-.615-.715a1.084,1.084,0,0,1,.731-1.047,2.225,2.225,0,0,1-.964-2.077c0-1.429.748-2.41,2.01-2.41a2.414,2.414,0,0,1,1.1.266,2.032,2.032,0,0,0,1.08-.283l.183.964a1.437,1.437,0,0,1-.7.183,2.313,2.313,0,0,1,.316,1.279,2.155,2.155,0,0,1-1.911,2.393c-.449.016-.7.216-.7.515a.521.521,0,0,0,.4.482l.964.316a2.219,2.219,0,0,1,1.545,2.343A2.188,2.188,0,0,1,942.136,588.109Zm.415-3.772-.8-.25c-.532.116-.931.665-.931,1.562a1.392,1.392,0,0,0,1.313,1.529,1.307,1.307,0,0,0,1.229-1.429A1.375,1.375,0,0,0,942.551,584.337Zm-.449-6.131c-.582,0-.98.6-.98,1.479,0,.9.4,1.5.98,1.5.548,0,.964-.6.964-1.5C943.066,578.8,942.667,578.206,942.1,578.206Z'
                    transform='translate(-873.451 -573.802)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7410'
                    data-name='Caminho 7410'
                    d='M951.239,581.164c0-2.393,1.1-3.855,2.493-3.855,1.446,0,2.194,1.329,2.194,2.842,0,.266-.017.548-.034.748l-.531.632h-3.041c.067,1.412.681,2.559,1.878,2.559a2.081,2.081,0,0,0,1.362-.482l-.117,1.047a2.215,2.215,0,0,1-1.4.382C952.319,585.036,951.239,583.49,951.239,581.164Zm1.113-.549h2.493a3.923,3.923,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C953.15,578.256,952.486,579,952.352,580.616Z'
                    transform='translate(-879.331 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7411'
                    data-name='Caminho 7411'
                    d='M966.812,584.936v-5.6a1.018,1.018,0,0,0-1.13-1.08,1.071,1.071,0,0,0-1.18,1.1v5.583h-1.08v-7.527h1.03v.8h.016a1.524,1.524,0,0,1,1.578-.9,1.7,1.7,0,0,1,1.828,1.811v5.816Z'
                    transform='translate(-885.6 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7412'
                    data-name='Caminho 7412'
                    d='M978.251,576.367h-1.612v4.868c0,.6.283.831.8.831a1.021,1.021,0,0,0,.515-.117l-.1.947a1.432,1.432,0,0,1-.632.116,1.509,1.509,0,0,1-1.645-1.612v-5.035h-.964v-.947h.964v-1.479l1.063-.5v1.977h1.811Z'
                    transform='translate(-891.357 -571.83)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7413'
                    data-name='Caminho 7413'
                    d='M983.783,581.164c0-2.393,1.1-3.855,2.493-3.855,1.446,0,2.193,1.329,2.193,2.842,0,.266-.017.548-.034.748l-.532.632h-3.041c.066,1.412.681,2.559,1.878,2.559a2.081,2.081,0,0,0,1.362-.482l-.117,1.047a2.216,2.216,0,0,1-1.4.382C984.863,585.036,983.783,583.49,983.783,581.164Zm1.113-.549h2.493a3.923,3.923,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C985.694,578.256,985.03,579,984.9,580.616Z'
                    transform='translate(-896.075 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7414'
                    data-name='Caminho 7414'
                    d='M1001.887,581.164c0-2.393,1.1-3.855,2.493-3.855,1.445,0,2.193,1.329,2.193,2.842,0,.266-.017.548-.033.748l-.532.632h-3.041c.066,1.412.682,2.559,1.878,2.559a2.08,2.08,0,0,0,1.362-.482l-.116,1.047a2.215,2.215,0,0,1-1.4.382C1002.967,585.036,1001.887,583.49,1001.887,581.164Zm1.113-.549h2.492a3.825,3.825,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C1003.8,578.256,1003.133,579,1003,580.616Z'
                    transform='translate(-905.39 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7415'
                    data-name='Caminho 7415'
                    d='M1020.742,581.784v-10.6h1.13v10.6Z'
                    transform='translate(-915.091 -570.668)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7416'
                    data-name='Caminho 7416'
                    d='M1029.812,584.936v-5.6a1.018,1.018,0,0,0-1.13-1.08,1.071,1.071,0,0,0-1.18,1.1v5.583h-1.08v-7.527h1.03v.8h.016a1.524,1.524,0,0,1,1.578-.9,1.7,1.7,0,0,1,1.828,1.811v5.816Z'
                    transform='translate(-918.013 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7417'
                    data-name='Caminho 7417'
                    d='M1041.251,576.367h-1.612v4.868c0,.6.283.831.8.831a1.021,1.021,0,0,0,.515-.117l-.1.947a1.432,1.432,0,0,1-.632.116,1.509,1.509,0,0,1-1.645-1.612v-5.035h-.964v-.947h.964v-1.479l1.063-.5v1.977h1.812Z'
                    transform='translate(-923.77 -571.83)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7418'
                    data-name='Caminho 7418'
                    d='M1046.784,581.164c0-2.393,1.1-3.855,2.493-3.855,1.446,0,2.193,1.329,2.193,2.842,0,.266-.017.548-.034.748l-.532.632h-3.041c.066,1.412.681,2.559,1.878,2.559a2.081,2.081,0,0,0,1.362-.482l-.117,1.047a2.216,2.216,0,0,1-1.4.382C1047.864,585.036,1046.784,583.49,1046.784,581.164Zm1.113-.549h2.493a3.938,3.938,0,0,0,.016-.465c0-.931-.4-1.894-1.113-1.894C1048.7,578.256,1048.031,579,1047.9,580.616Z'
                    transform='translate(-928.489 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7419'
                    data-name='Caminho 7419'
                    d='M1060.814,588.109a2.287,2.287,0,0,1-2.327-2.459,2.059,2.059,0,0,1,1.147-2.11l-.615-.715a1.084,1.084,0,0,1,.731-1.047,2.225,2.225,0,0,1-.964-2.077c0-1.429.748-2.41,2.01-2.41a2.416,2.416,0,0,1,1.1.266,2.034,2.034,0,0,0,1.08-.283l.182.964a1.436,1.436,0,0,1-.7.183,2.316,2.316,0,0,1,.316,1.279,2.155,2.155,0,0,1-1.911,2.393c-.449.016-.7.216-.7.515a.521.521,0,0,0,.4.482l.964.316a2.219,2.219,0,0,1,1.545,2.343A2.189,2.189,0,0,1,1060.814,588.109Zm.416-3.772-.8-.25c-.532.116-.931.665-.931,1.562a1.392,1.392,0,0,0,1.313,1.529,1.308,1.308,0,0,0,1.23-1.429A1.376,1.376,0,0,0,1061.229,584.337Zm-.449-6.131c-.582,0-.981.6-.981,1.479,0,.9.4,1.5.981,1.5.548,0,.963-.6.963-1.5C1061.744,578.8,1061.345,578.206,1060.781,578.206Z'
                    transform='translate(-934.51 -573.802)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7420'
                    data-name='Caminho 7420'
                    d='M1073.189,578.79a1.033,1.033,0,0,0-.432-.1c-.7,0-1.279.515-1.279,1.711v4.57h-1.08v-7.527h1.08v1.263h.016a1.514,1.514,0,0,1,1.5-1.329,1.542,1.542,0,0,1,.482.083Z'
                    transform='translate(-940.638 -573.855)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7421'
                    data-name='Caminho 7421'
                    d='M1077.652,582.776a2.172,2.172,0,0,1,1.911-2.41l1.047-.133v-.9a1.015,1.015,0,0,0-1.13-1.1,2.519,2.519,0,0,0-1.346.4l-.2-.88a3.07,3.07,0,0,1,1.678-.449,1.881,1.881,0,0,1,2.061,2.044v3.489a1.536,1.536,0,0,0,.7,1.379v.067l-.648.731a1.566,1.566,0,0,1-.8-.831,1.637,1.637,0,0,1-1.462.847C1078.317,585.036,1077.652,584.105,1077.652,582.776Zm1.928,1.312c.548,0,1.03-.5,1.03-1.3v-1.612l-.814.083c-.665.083-1.1.582-1.1,1.529C1078.7,583.64,1079.081,584.089,1079.58,584.089Z'
                    transform='translate(-944.371 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7422'
                    data-name='Caminho 7422'
                    d='M1092.628,581.239l-.016-.715h-.016a1.55,1.55,0,0,1-1.462.814c-1.479,0-2.393-1.578-2.393-3.888,0-2.327.914-3.839,2.426-3.839a1.65,1.65,0,0,1,1.379.648v-4.137h1.08v11.117Zm-.083-5.982a1.347,1.347,0,0,0-1.146-.715c-.9,0-1.579,1.213-1.579,2.958,0,1.695.715,2.908,1.6,2.908a1.392,1.392,0,0,0,1.13-.714Z'
                    transform='translate(-950.075 -570.122)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7423'
                    data-name='Caminho 7423'
                    d='M1104.063,585.036c-1.695,0-2.592-1.578-2.592-3.855,0-2.293.9-3.872,2.592-3.872s2.592,1.579,2.592,3.872C1106.655,583.457,1105.758,585.036,1104.063,585.036Zm0-6.78c-.881,0-1.512,1.2-1.512,2.925s.632,2.908,1.512,2.908,1.529-1.179,1.529-2.908S1104.944,578.256,1104.063,578.256Z'
                    transform='translate(-956.625 -573.82)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7424'
                    data-name='Caminho 7424'
                    d='M807.017,621.765,807,621.05h-.017a1.549,1.549,0,0,1-1.462.814c-1.479,0-2.393-1.578-2.393-3.888,0-2.327.914-3.839,2.426-3.839a1.649,1.649,0,0,1,1.379.648v-4.137h1.08v11.117Zm-.083-5.982a1.347,1.347,0,0,0-1.147-.714c-.9,0-1.578,1.213-1.578,2.958,0,1.695.714,2.908,1.6,2.908a1.392,1.392,0,0,0,1.13-.714Z'
                    transform='translate(-803.129 -590.972)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7425'
                    data-name='Caminho 7425'
                    d='M815.894,621.69c0-2.393,1.1-3.855,2.492-3.855,1.446,0,2.194,1.329,2.194,2.842,0,.266-.017.548-.033.748l-.532.632h-3.041c.066,1.412.681,2.559,1.878,2.559a2.082,2.082,0,0,0,1.362-.482l-.116,1.047a2.22,2.22,0,0,1-1.4.382C816.974,625.562,815.894,624.016,815.894,621.69Zm1.113-.549H819.5a3.937,3.937,0,0,0,.016-.465c0-.931-.4-1.894-1.113-1.894C817.8,618.782,817.14,619.53,817.007,621.142Z'
                    transform='translate(-809.697 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7426'
                    data-name='Caminho 7426'
                    d='M837.788,622.3c-2.244,0-3.689-2.11-3.689-5.417,0-3.34,1.5-5.417,3.639-5.417a3.449,3.449,0,0,1,2.06.615l-.2.964a2.9,2.9,0,0,0-1.712-.582c-1.545,0-2.658,1.628-2.658,4.437,0,2.626,1.1,4.42,2.559,4.42a2.039,2.039,0,0,0,1.229-.349v-3.356h-1.561v-.98h2.625v4.852A3.264,3.264,0,0,1,837.788,622.3Z'
                    transform='translate(-819.063 -591.395)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7427'
                    data-name='Caminho 7427'
                    d='M849.088,621.69c0-2.393,1.1-3.855,2.493-3.855,1.445,0,2.193,1.329,2.193,2.842,0,.266-.016.548-.033.748l-.532.632h-3.041c.066,1.412.681,2.559,1.878,2.559a2.079,2.079,0,0,0,1.362-.482l-.116,1.047a2.218,2.218,0,0,1-1.4.382C850.168,625.562,849.088,624.016,849.088,621.69Zm1.113-.549h2.493a3.923,3.923,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C851,618.782,850.334,619.53,850.2,621.142Z'
                    transform='translate(-826.775 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7428'
                    data-name='Caminho 7428'
                    d='M864.063,619.316a1.034,1.034,0,0,0-.432-.1c-.7,0-1.279.515-1.279,1.711v4.57h-1.08v-7.527h1.08v1.263h.017a1.514,1.514,0,0,1,1.5-1.329,1.541,1.541,0,0,1,.482.083Z'
                    transform='translate(-833.043 -594.706)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7429'
                    data-name='Caminho 7429'
                    d='M868.834,621.69c0-2.393,1.1-3.855,2.493-3.855,1.445,0,2.193,1.329,2.193,2.842,0,.266-.017.548-.033.748l-.532.632h-3.041c.066,1.412.681,2.559,1.878,2.559a2.079,2.079,0,0,0,1.362-.482l-.116,1.047a2.218,2.218,0,0,1-1.4.382C869.914,625.562,868.834,624.016,868.834,621.69Zm1.113-.549h2.492a3.908,3.908,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C870.745,618.782,870.08,619.53,869.947,621.142Z'
                    transform='translate(-836.934 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7430'
                    data-name='Caminho 7430'
                    d='M884.407,625.462v-5.6a1.018,1.018,0,0,0-1.13-1.08,1.071,1.071,0,0,0-1.18,1.1v5.583h-1.08v-7.527h1.03v.8h.016a1.524,1.524,0,0,1,1.578-.9,1.7,1.7,0,0,1,1.828,1.811v5.816Z'
                    transform='translate(-843.203 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7431'
                    data-name='Caminho 7431'
                    d='M896.694,619.217a1.79,1.79,0,0,0-1.113-.366c-.98,0-1.711,1.179-1.711,2.875,0,1.728.714,2.875,1.811,2.875a1.893,1.893,0,0,0,1.03-.283l-.1,1a2.338,2.338,0,0,1-1.113.249c-1.628,0-2.709-1.445-2.709-3.821,0-2.393,1.13-3.838,2.659-3.838a2.519,2.519,0,0,1,1.429.382Z'
                    transform='translate(-849.259 -594.706)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7432'
                    data-name='Caminho 7432'
                    d='M904.079,615.347a1.134,1.134,0,0,1-.682-.7c0-.216.482-.681.682-.681a1.085,1.085,0,0,1,.714.681A1.108,1.108,0,0,1,904.079,615.347Zm-.532,8.126v-7.527h1.08v7.527Z'
                    transform='translate(-854.717 -592.681)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7433'
                    data-name='Caminho 7433'
                    d='M908.667,623.3a2.171,2.171,0,0,1,1.911-2.409l1.047-.133v-.9a1.015,1.015,0,0,0-1.13-1.1,2.52,2.52,0,0,0-1.346.4l-.2-.881a3.074,3.074,0,0,1,1.678-.449,1.881,1.881,0,0,1,2.06,2.044v3.489a1.536,1.536,0,0,0,.7,1.379v.067l-.648.731a1.566,1.566,0,0,1-.8-.831,1.636,1.636,0,0,1-1.462.847C909.332,625.562,908.667,624.631,908.667,623.3Zm1.928,1.312c.548,0,1.03-.5,1.03-1.3v-1.612l-.814.083c-.665.083-1.1.582-1.1,1.529C909.714,624.166,910.1,624.615,910.595,624.615Z'
                    transform='translate(-857.428 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7434'
                    data-name='Caminho 7434'
                    d='M926.6,625.462v-5.633a.931.931,0,0,0-1.047-1.047.984.984,0,0,0-1.1,1.047v5.633H923.39v-5.633a.939.939,0,0,0-1.047-1.047.983.983,0,0,0-1.1,1.047v5.633h-1.08v-7.527h1.047v.764h.017a1.427,1.427,0,0,1,1.479-.864,1.525,1.525,0,0,1,1.529.947,1.711,1.711,0,0,1,1.645-.947,1.659,1.659,0,0,1,1.795,1.795v5.832Z'
                    transform='translate(-863.344 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7435'
                    data-name='Caminho 7435'
                    d='M938.234,621.69c0-2.393,1.1-3.855,2.493-3.855,1.446,0,2.193,1.329,2.193,2.842,0,.266-.016.548-.033.748l-.531.632h-3.041c.067,1.412.681,2.559,1.878,2.559a2.081,2.081,0,0,0,1.362-.482l-.116,1.047a2.22,2.22,0,0,1-1.4.382C939.314,625.562,938.234,624.016,938.234,621.69Zm1.113-.549h2.493a3.923,3.923,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C940.145,618.782,939.481,619.53,939.347,621.142Z'
                    transform='translate(-872.64 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7436'
                    data-name='Caminho 7436'
                    d='M953.807,625.462v-5.6a1.018,1.018,0,0,0-1.13-1.08,1.07,1.07,0,0,0-1.179,1.1v5.583h-1.08v-7.527h1.03v.8h.017a1.524,1.524,0,0,1,1.578-.9,1.7,1.7,0,0,1,1.828,1.811v5.816Z'
                    transform='translate(-878.909 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7437'
                    data-name='Caminho 7437'
                    d='M965.247,616.893h-1.612v4.868c0,.6.283.831.8.831a1.026,1.026,0,0,0,.515-.117l-.1.947a1.432,1.432,0,0,1-.632.116,1.509,1.509,0,0,1-1.645-1.611v-5.035h-.964v-.947h.964v-1.479l1.063-.5v1.977h1.811Z'
                    transform='translate(-884.666 -592.681)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7438'
                    data-name='Caminho 7438'
                    d='M973.336,625.562c-1.694,0-2.592-1.578-2.592-3.855,0-2.293.9-3.872,2.592-3.872s2.593,1.579,2.593,3.872C975.929,623.983,975.031,625.562,973.336,625.562Zm0-6.78c-.88,0-1.512,1.2-1.512,2.925s.632,2.908,1.512,2.908,1.529-1.18,1.529-2.908S974.217,618.782,973.336,618.782Z'
                    transform='translate(-889.367 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7439'
                    data-name='Caminho 7439'
                    d='M993.762,621.765l-.017-.715h-.017a1.548,1.548,0,0,1-1.462.814c-1.479,0-2.393-1.578-2.393-3.888,0-2.327.914-3.839,2.426-3.839a1.65,1.65,0,0,1,1.379.648v-4.137h1.08v11.117Zm-.083-5.982a1.347,1.347,0,0,0-1.146-.714c-.9,0-1.579,1.213-1.579,2.958,0,1.695.714,2.908,1.6,2.908a1.391,1.391,0,0,0,1.13-.714Z'
                    transform='translate(-899.209 -590.972)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7440'
                    data-name='Caminho 7440'
                    d='M1002.639,621.69c0-2.393,1.1-3.855,2.492-3.855,1.446,0,2.194,1.329,2.194,2.842,0,.266-.017.548-.033.748l-.532.632h-3.041c.066,1.412.682,2.559,1.878,2.559a2.08,2.08,0,0,0,1.362-.482l-.116,1.047a2.22,2.22,0,0,1-1.4.382C1003.719,625.562,1002.639,624.016,1002.639,621.69Zm1.113-.549h2.492a3.838,3.838,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C1004.55,618.782,1003.885,619.53,1003.752,621.142Z'
                    transform='translate(-905.776 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7441'
                    data-name='Caminho 7441'
                    d='M1024.2,622.443a2.532,2.532,0,0,1-2.775-2.609v-8.125h1.113v8.042a1.678,1.678,0,1,0,3.357,0v-8.042h1.1v8.125A2.538,2.538,0,0,1,1024.2,622.443Z'
                    transform='translate(-915.443 -591.518)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7442'
                    data-name='Caminho 7442'
                    d='M1038.822,616.893h-1.612v4.868c0,.6.282.831.8.831a1.028,1.028,0,0,0,.516-.117l-.1.947a1.431,1.431,0,0,1-.631.116,1.509,1.509,0,0,1-1.645-1.611v-5.035h-.964v-.947h.964v-1.479l1.063-.5v1.977h1.811Z'
                    transform='translate(-922.52 -592.681)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7443'
                    data-name='Caminho 7443'
                    d='M1045.379,615.347a1.133,1.133,0,0,1-.682-.7c0-.216.482-.681.682-.681a1.085,1.085,0,0,1,.714.681A1.108,1.108,0,0,1,1045.379,615.347Zm-.532,8.126v-7.527h1.08v7.527Z'
                    transform='translate(-927.415 -592.681)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7444'
                    data-name='Caminho 7444'
                    d='M1052.078,621.831a1.552,1.552,0,0,1-1.5-1.728v-9.455h1.063v9.422a.759.759,0,0,0,.615.831Z'
                    transform='translate(-930.444 -590.972)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7445'
                    data-name='Caminho 7445'
                    d='M1056.877,615.347a1.134,1.134,0,0,1-.682-.7c0-.216.482-.681.682-.681a1.085,1.085,0,0,1,.714.681A1.108,1.108,0,0,1,1056.877,615.347Zm-.532,8.126v-7.527h1.08v7.527Z'
                    transform='translate(-933.331 -592.681)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7446'
                    data-name='Caminho 7446'
                    d='M1065.422,621.765l-.017-.715h-.016a1.55,1.55,0,0,1-1.462.814c-1.479,0-2.393-1.578-2.393-3.888,0-2.327.914-3.839,2.426-3.839a1.649,1.649,0,0,1,1.379.648v-4.137h1.08v11.117Zm-.083-5.982a1.347,1.347,0,0,0-1.146-.714c-.9,0-1.579,1.213-1.579,2.958,0,1.695.714,2.908,1.6,2.908a1.391,1.391,0,0,0,1.13-.714Z'
                    transform='translate(-936.078 -590.972)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7447'
                    data-name='Caminho 7447'
                    d='M1074.2,623.3a2.171,2.171,0,0,1,1.911-2.409l1.047-.133v-.9a1.015,1.015,0,0,0-1.13-1.1,2.521,2.521,0,0,0-1.346.4l-.2-.881a3.076,3.076,0,0,1,1.678-.449,1.881,1.881,0,0,1,2.061,2.044v3.489a1.537,1.537,0,0,0,.7,1.379v.067l-.648.731a1.565,1.565,0,0,1-.8-.831,1.636,1.636,0,0,1-1.462.847C1074.861,625.562,1074.2,624.631,1074.2,623.3Zm1.928,1.312c.548,0,1.03-.5,1.03-1.3v-1.612l-.814.083c-.665.083-1.1.582-1.1,1.529C1075.243,624.166,1075.625,624.615,1076.124,624.615Z'
                    transform='translate(-942.593 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7448'
                    data-name='Caminho 7448'
                    d='M1089.171,621.765l-.016-.715h-.017a1.55,1.55,0,0,1-1.462.814c-1.479,0-2.393-1.578-2.393-3.888,0-2.327.914-3.839,2.426-3.839a1.649,1.649,0,0,1,1.379.648v-4.137h1.08v11.117Zm-.083-5.982a1.347,1.347,0,0,0-1.146-.714c-.9,0-1.579,1.213-1.579,2.958,0,1.695.714,2.908,1.6,2.908a1.391,1.391,0,0,0,1.13-.714Z'
                    transform='translate(-948.297 -590.972)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7449'
                    data-name='Caminho 7449'
                    d='M1098.048,621.69c0-2.393,1.1-3.855,2.493-3.855,1.445,0,2.193,1.329,2.193,2.842,0,.266-.017.548-.033.748l-.532.632h-3.041c.067,1.412.682,2.559,1.878,2.559a2.079,2.079,0,0,0,1.362-.482l-.116,1.047a2.219,2.219,0,0,1-1.4.382C1099.128,625.562,1098.048,624.016,1098.048,621.69Zm1.113-.549h2.493a3.908,3.908,0,0,0,.017-.465c0-.931-.4-1.894-1.113-1.894C1099.959,618.782,1099.294,619.53,1099.161,621.142Z'
                    transform='translate(-954.864 -594.67)'
                    fill='#141432'
                  />
                  <path
                    id='Caminho_7450'
                    data-name='Caminho 7450'
                    d='M1111.435,625.564a2.488,2.488,0,0,1-1.4-.4l-.116-1.13a2.132,2.132,0,0,0,1.379.582.833.833,0,0,0,.914-.864c0-1.363-2.209-2.061-2.209-4.121a1.648,1.648,0,0,1,1.761-1.728,2.257,2.257,0,0,1,1.229.349l-.183.914a1.62,1.62,0,0,0-.963-.316.768.768,0,0,0-.8.831c0,1.362,2.243,2.094,2.243,4.071A1.706,1.706,0,0,1,1111.435,625.564Z'
                    transform='translate(-960.974 -594.706)'
                    fill='#141432'
                  />
                </g>
                <g id='Logotipo' transform='translate(609 159.527)'>
                  <g id='Grupo_3203' data-name='Grupo 3203'>
                    <path
                      id='Caminho_7065'
                      data-name='Caminho 7065'
                      d='M1494.935,488.786a14.457,14.457,0,0,1-7.555-2.019l-.521-6.513a12.809,12.809,0,0,0,7.294,2.67c2.8,0,4.624-1.433,4.624-4.363,0-7.359-11.528-10.876-11.528-22.339,0-5.8,3.778-9.639,9.834-9.639a13.5,13.5,0,0,1,6.578,1.563l-1.173,5.536a9.557,9.557,0,0,0-4.754-1.368,3.559,3.559,0,0,0-3.908,3.777c0,6.969,11.658,11.072,11.658,22.469C1505.485,485.073,1501.578,488.786,1494.935,488.786Z'
                      transform='translate(-1486.859 -446.388)'
                      fill='#141432'
                    />
                    <path
                      id='Caminho_7066'
                      data-name='Caminho 7066'
                      d='M1538.869,488.8V447.253h6.708V488.8Z'
                      transform='translate(-1513.601 -446.732)'
                      fill='#141432'
                    />
                    <path
                      id='Caminho_7067'
                      data-name='Caminho 7067'
                      d='M1569.3,488.8V447.253h6.708V488.8Z'
                      transform='translate(-1529.246 -446.732)'
                      fill='#141432'
                    />
                    <path
                      id='Caminho_7068'
                      data-name='Caminho 7068'
                      d='M1612.691,488.709c-9.118,0-15.11-7.555-15.11-21.232s6.253-21.3,14.915-21.3a14.654,14.654,0,0,1,8.6,2.54l-1.042,5.666a11.42,11.42,0,0,0-6.578-2.28c-5.536,0-9.183,5.275-9.183,15.5,0,8.987,3.452,15.3,8.4,15.3a6.527,6.527,0,0,0,3.256-.716v-11.2h-4.82v-5.6h11.137v20.189C1620.05,487.6,1616.729,488.709,1612.691,488.709Z'
                      transform='translate(-1543.788 -446.181)'
                      fill='#141432'
                    />
                  </g>
                  <g
                    id='Grupo_3204'
                    data-name='Grupo 3204'
                    transform='translate(85.704 0.521)'
                  >
                    <path
                      id='Caminho_7069'
                      data-name='Caminho 7069'
                      d='M1675.64,489.391c-7.034,0-12.375-4.038-12.375-10.681V447.253h7.881v30.74a4.559,4.559,0,0,0,9.118,0v-30.74h7.75V478.71C1688.014,485.353,1682.608,489.391,1675.64,489.391Z'
                      transform='translate(-1663.265 -447.253)'
                      fill='#141432'
                    />
                    <path
                      id='Caminho_7070'
                      data-name='Caminho 7070'
                      d='M1740.622,453.961V488.8h-7.88V453.961h-7.815v-6.708h23.511v6.708Z'
                      transform='translate(-1694.969 -447.253)'
                      fill='#141432'
                    />
                    <path
                      id='Caminho_7071'
                      data-name='Caminho 7071'
                      d='M1783.908,488.8V447.253h7.88V488.8Z'
                      transform='translate(-1725.295 -447.253)'
                      fill='#141432'
                    />
                  </g>
                </g>
                <g id='Simbolo' transform='translate(552 159.768)'>
                  <path
                    id='Caminho_7127'
                    data-name='Caminho 7127'
                    d='M1367.446,462.925a3.92,3.92,0,0,1,0-5.537l10.93-10.93h-8.189l-6.835,6.835a9.717,9.717,0,0,0,0,13.727l6.893,6.892,8.214.025Z'
                    transform='translate(-1360.513 -446.458)'
                    fill='#0165b1'
                  />
                  <path
                    id='Caminho_7128'
                    data-name='Caminho 7128'
                    d='M1392.991,462.925a3.921,3.921,0,0,1,0-5.538l10.93-10.93h-8.189l-6.834,6.835a9.716,9.716,0,0,0,0,13.727l5.369,5.369a3.922,3.922,0,0,1,0,5.539l-10.9,10.947h8.146l6.853-6.854a9.716,9.716,0,0,0,0-13.727Z'
                    transform='translate(-1372.261 -446.458)'
                    fill='#0165b1'
                  />
                  <path
                    id='Caminho_7129'
                    data-name='Caminho 7129'
                    d='M1426.692,484.122l-6.893-6.893-8.214-.025,11.012,11.012a3.92,3.92,0,0,1,0,5.537l-10.93,10.93h8.189l6.835-6.835A9.719,9.719,0,0,0,1426.692,484.122Z'
                    transform='translate(-1386.772 -462.267)'
                    fill='#0165b1'
                  />
                </g>
              </g>
            </svg>
          </div>

          <p>Olá, Sandra</p>
          <p>Clique no botão para redefinir a sua senha.</p>

          <a href='#'>
            <button className='btn'>Redefinir</button>
          </a>

          <p>
            Caso não tenha sido você quem solicitou a redefinição de senha,
            informe ao administrador o ocorrido.
          </p>

          <small style={{ fontStyle: 'italic', display: 'block' }}>
            Caso o botão não esteja funcionando, cole o link
            www.siiguti.com.br/reset-password no seu navegador
          </small>
        </div>
      </div>
    </>
  )
}
