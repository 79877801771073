import { Stack, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  sx?: SxProps
}

export function MapTemperatureColors(props: Props) {
  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    setIsAnimated(true)
  }, [])

  return (
    <Stack
      direction='row'
      justifyContent='space-around'
      alignItems='center'
      sx={{
        position: 'absolute',
        bottom: 72,
        left: 16,
        width: isAnimated ? ['calc(100% - 32px)', '100%'] : 0,
        overflow: 'hidden',
        transition: 'width 0.3s ease-in-out',
        maxWidth: ['100%', 450],
        height: 44,
        borderWidth: 4,
        borderRadius: 2,
        borderColor: 'background.paper',
        borderStyle: 'solid',
        background:
          'linear-gradient(90deg, #df5751 0%, #f09e56 15%, #f9e47e 30%, #3cb470 45%, #275fbd 60%, #8a60d3 85%, #ce74c2 100%)',
        ...props.sx,
      }}
    >
      {isAnimated && (
        <>
          <h5>40ºC</h5>
          <h5>36ºC</h5>
          <h5>32ºC</h5>
          <h5>28ºC</h5>
          <h5>24ºC</h5>
          <h5>20ºC</h5>
          <h5>16ºC</h5>
        </>
      )}
    </Stack>
  )
}
