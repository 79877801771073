import { Box, SxProps, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  status?: boolean
  sx?: SxProps
}

export function WavePulse({ status, sx }: Props) {
  const { t } = useTranslation('common')
  return (
    <Box
      position={'absolute'}
      sx={{
        top: -4,
        right: -4,
        zIndex: 1,
        ...sx,
      }}
    >
      <Tooltip
        title={status ? undefined : t('sensorOffline')}
        placement='top'
        arrow
      >
        <Box
          className='pulse'
          sx={{
            width: 8,
            height: 8,
            background: (theme) =>
              status ? theme.palette.success.main : theme.palette.error.main,
            '&::after, &::before': {
              background: (theme) =>
                status ? 'transparent' : theme.palette.error.dark,
            },
            borderRadius: 2,
            zIndex: 1,
          }}
        />
      </Tooltip>
    </Box>
  )
}
