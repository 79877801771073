import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

import { ReactComponent as AlertIcon } from 'assets/company-icon/alert-quantity.svg'
import { ReactComponent as EnergyIcon } from 'assets/company-icon/eletric-quantity.svg'
import { ReactComponent as GasIcon } from 'assets/company-icon/gas-quantity.svg'
import { ReactComponent as HumidityIcon } from 'assets/company-icon/hum-quantity.svg'
import { ReactComponent as OpeningIcon } from 'assets/company-icon/opening-quantity.svg'
import { ReactComponent as TemperatureHumidityIcon } from 'assets/company-icon/temp-hum-quantity.svg'
import { ReactComponent as TemperatureIcon } from 'assets/company-icon/temp-quantity.svg'
import { ReactComponent as WaterIcon } from 'assets/company-icon/water-quantity.svg'

export const getDescriptionBySensorCompanyType = (type: SensorTypeEnum) => {
  const descriptions = {
    [SensorTypeEnum.ELECTRIC]: 'Energia',
    [SensorTypeEnum.TEMP]: 'Temperatura',
    [SensorTypeEnum.HUM]: 'Umidade',
    [SensorTypeEnum.WATER]: 'Água',
    [SensorTypeEnum.AIR]: 'Gás',
    [SensorTypeEnum.TEMP_HUM]: 'Temp e umid',
    [SensorTypeEnum.ALL]: 'Todos',
    [SensorTypeEnum.OPENING]: 'Abertura',
    [SensorTypeEnum.BTN_PANIC]: 'Alerta',
  }

  return descriptions[type]
}

export const getIconBySensorCompanyType = (type: SensorTypeEnum) => {
  const icons = {
    [SensorTypeEnum.ELECTRIC]: EnergyIcon,
    [SensorTypeEnum.ALL]: TemperatureIcon,
    [SensorTypeEnum.TEMP]: TemperatureIcon,
    [SensorTypeEnum.HUM]: HumidityIcon,
    [SensorTypeEnum.WATER]: WaterIcon,
    [SensorTypeEnum.AIR]: GasIcon,
    [SensorTypeEnum.TEMP_HUM]: TemperatureHumidityIcon,
    [SensorTypeEnum.BTN_PANIC]: AlertIcon,
    [SensorTypeEnum.OPENING]: OpeningIcon,
  }

  return icons[type]
}
