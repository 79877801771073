import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Stack, Typography } from '@mui/material'
import { AirConditioningActionEnum } from 'core/enums/AirConditioningActionEnum'
import { AirConditioningFanModeEnum } from 'core/enums/AirConditioningFanModeEnum'
import { forwardRef, useImperativeHandle } from 'react'

import { ReactComponent as IconVentilate } from 'assets/icons/air-conditioning/icon-ventilate.svg'
import { useAirConditioningControlPanel } from 'hooks/map/useAirConditioningControlPanel'

import { SpeedProgress } from '../SpeedProgress'
import {
  ControlContent,
  ControlWrapper,
  FanProgress,
  FanProgressItem,
} from './styles'

export type RefAirConditioningControlPanel = {
  getTemperature(): number
  setTemperature(value: number): void
}

type Props = {
  enabledActions?: AirConditioningActionEnum[]
  levelFanMode: number
  onIncreaseEvent?: (temperature: number) => void
  onDecreaseEvent?: (temperature: number) => void
  airConditioningFanMode: AirConditioningFanModeEnum
}

export const AirConditioningControlPanel = forwardRef<
  RefAirConditioningControlPanel,
  Props
>((props, ref) => {
  const { levelFanMode, enabledActions = [], airConditioningFanMode } = props
  const airConditioningIsOn = enabledActions.includes(
    AirConditioningActionEnum.ON,
  )
  const fanModeIsEnabled = enabledActions.includes(
    AirConditioningActionEnum.VENTILATE,
  )

  const {
    temperature,
    colorGradient,
    increaseIsValid,
    decreaseIsValid,
    increaseTemperature,
    decreaseTemperature,
    setTemperature,
  } = useAirConditioningControlPanel({
    airConditioningIsOn,
    onIncreaseEvent: props.onIncreaseEvent,
    onDecreaseEvent: props.onDecreaseEvent,
    increaseEnable: () => airConditioningIsOn && !fanModeIsEnabled,
    decreaseEnable: () => airConditioningIsOn && !fanModeIsEnabled,
  })

  useImperativeHandle(
    ref,
    () => ({
      getTemperature: () => temperature,
      setTemperature: (value) => setTemperature(value),
    }),
    [temperature],
  )

  const fanMode = (
    <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
      <Stack gap={1}>
        <SpeedProgress barNumber={levelFanMode} />
        <IconVentilate height={28} width={28} />
      </Stack>
      <FanProgress>
        <FanProgressItem />
        <FanProgressItem />
        <FanProgressItem />
      </FanProgress>
    </Stack>
  )

  const defaultMode = (
    <>
      {airConditioningIsOn && (
        <>
          {[
            AirConditioningFanModeEnum.LEVEL,
            AirConditioningFanModeEnum.COOL,
          ].includes(airConditioningFanMode) && (
            <SpeedProgress barNumber={levelFanMode} />
          )}

          {airConditioningFanMode === AirConditioningFanModeEnum.AUTO && (
            <Typography
              style={{
                opacity: airConditioningIsOn ? 1 : 0.35,
              }}
            >
              Auto
            </Typography>
          )}
        </>
      )}

      <Typography
        style={{
          opacity: airConditioningIsOn ? 1 : 0.35,
        }}
        fontSize={'2.375rem'}
        fontWeight={300}
      >
        {temperature}ºC
      </Typography>
    </>
  )

  const getStyleArrowByEnable = (enable: boolean) => ({
    opacity: enable ? 1 : 0.35,
    cursor: enable ? 'pointer' : 'initial',
    fontSize: 40,
  })

  return (
    <ControlWrapper
      colors={colorGradient}
      temperature={temperature}
      airConditioningIsOn={airConditioningIsOn}
    >
      <ControlContent>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <KeyboardArrowUpIcon
            onClick={increaseTemperature}
            sx={getStyleArrowByEnable(increaseIsValid())}
          />
          {fanModeIsEnabled ? fanMode : defaultMode}
          <KeyboardArrowDownIcon
            onClick={decreaseTemperature}
            sx={getStyleArrowByEnable(decreaseIsValid())}
          />
        </Stack>
      </ControlContent>
    </ControlWrapper>
  )
})
