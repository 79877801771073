import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Table, TableActionType } from 'components/Table'
import { TabPanel, TabPanelProps } from 'components/Tabs/TabPanel'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { quantityProducedMapper } from 'pages/setting/mappers/quantity-produced.mapper'
import { listQuantityProduced } from 'services/goalAndParameter/quantityProduced/listQuantityProduced'
import { QuantityProducedType } from 'types/goalAndParameter/quantity-produced'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { FormQuantityProducedModal } from '../FormQuantityProducedModal'

type DataType = QuantityProducedType

type Props = Omit<TabPanelProps, 'children'> & {
  search?: string
  openForm: boolean
  onCloseForm: () => void
}

export function QuantityProduced(props: Props) {
  const { search, openForm, onCloseForm, ...rest } = props
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openFormEdit, setOpenFormEdit] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<DataType | null>(null)

  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('utility')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<DataType>,
    Error
  >({
    queryKey: ['quantity-produced', page, search, orderBy],
    queryFn: () => listQuantityProduced({ page, search, columnSort: orderBy }),
  })

  const quantityProduced = quantityProducedMapper(data?.results ?? [])

  const handleCancel = () => {
    onCloseForm()
    setOpenFormView(false)
    setSelected(null)
    setOpenFormEdit(false)
  }

  const handleEdit = (item: DataType) => {
    setSelected(item)
    setOpenFormEdit(true)
  }

  const handleView = (item: DataType) => {
    setSelected(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: DataType) => {
    httpDelete(`/quantity-produced/${item.id}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'formattedYear',
      label: t('table.goalAndParameter.quantityProduced.year'),
    },
    {
      id: 'formattedMonth',
      label: t('table.goalAndParameter.quantityProduced.month'),
    },
    {
      id: 'quantityProduced',
      label: t('table.goalAndParameter.quantityProduced.quantityProduced'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
  ]

  const tableActions: TableActionType<DataType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_GOAL,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_GOAL,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_GOAL,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <TabPanel {...rest}>
      <Table<DataType>
        columns={columns}
        rows={quantityProduced}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView || openFormEdit) && (
        <FormQuantityProducedModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView || openFormEdit}
          onClose={handleCancel}
        />
      )}
    </TabPanel>
  )
}
