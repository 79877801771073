import { StatusOSType } from 'types/pre-os/pre-os'

import { IOrderService } from '../../core/interfaces/order-service/IOrderService'
import { httpClient } from '../api/httpClient'

export const updateOrderService = (id: number, update: IOrderService) => {
  const { file, idfile, ...rest } = update

  if (!file) {
    return httpClient.put(`/order-service/${id}`, rest)
  }

  const formData = new FormData()

  if (idfile) {
    formData.append('idfile', String(idfile || ''))
  }

  formData.append('file', file)
  formData.append('request', JSON.stringify(rest))
  return httpClient.put(`/order-service/update/${id}`, formData)
}

export const updateActionOSCalendar = (id: number, update: StatusOSType) => {
  return httpClient.put(`/order-service/${id}`, update)
}
