import { Stack, Tooltip } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'

import { getIconByEquipmentType } from 'helpers/equipment-type.helper'

type Props = {
  data: {
    label: string
    handleClick?: () => void
    type: EquipmentTypeEnum
  }
}

export const ArconditionedNode = ({ data }: Props) => {
  return (
    <Tooltip
      title='Ver informações e controle'
      arrow
      onClick={data.handleClick}
    >
      <Stack
        sx={{
          cursor: 'pointer',
          background: '#6d87f1',
          padding: '4px',
          borderRadius: '4px',
        }}
      >
        <img
          height={14}
          width={14}
          src={getIconByEquipmentType(data.type)}
          alt={data.label}
        />
      </Stack>
    </Tooltip>
  )
}
