import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Node } from 'reactflow'

import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { typeEquipmentOrArCon } from 'constants/maps'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { useEquipment } from 'pages/map/hook/useEquipment'
import { useMapStore } from 'store/map.store'
import { AddElementType } from 'types/area/create-linked-area'
import { EquipmentType } from 'types/equipment/equipment'

interface Props {
  data: any
  type: typeEquipmentOrArCon
  nodes?: Node<any, string | undefined>[]
  onClick?: (data: AddElementType) => void
  onClose?: () => void
  handleSave?: (nodes: any) => void
}

export const ListEquipment = ({
  data,
  type,
  onClick,
  nodes,
  handleSave,
  onClose,
}: Props) => {
  const {
    columns,
    order,
    setOrder,
    setOrderBy,
    orderBy,
    page,
    setPage,
    setSearch,
    error,
    isLoading,
    isFetching,
    data: dataEquipment,
    equipmentReplace,
  } = useEquipment({ isFeeder: false })
  const queryClient = useQueryClient()
  const { sweetAlert } = useSweetAlert()
  const { t } = useTranslation('common')
  const { closeModal } = useMapStore()

  useEffect(() => {
    queryClient.invalidateQueries('equipment-list')
  }, [type])

  const handleAddNode = (data: AddElementType) => {
    onClick?.(data)
    handleSave?.({ nodes })
    onClose?.()
    closeModal(true)
  }

  const handleClick = (row: any) => {
    sweetAlert({
      title: t('register').toString(),
      text: t('map.equipmentList.text').toString(),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('yes').toString(),
      cancelButtonText: t('no').toString(),
      onSuccess: () => {
        handleAddNode({
          type: 'equipment',
          equipment: dataEquipment?.items?.find(
            (item: any) => item.id === row.id,
          ),
        })
      },
    })
  }

  return (
    <>
      <HeaderAction onSearch={(search) => setSearch(search)} />

      <Table<EquipmentType>
        columns={columns}
        order={order}
        orderBy={orderBy}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        onOrderBy={(newOrderBy) => setOrderBy(newOrderBy)}
        onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
        pagination={{
          page,
          onChangePage: setPage,
          limit: dataEquipment?.limit,
          count: dataEquipment?.total,
        }}
      >
        {equipmentReplace && equipmentReplace.length ? (
          equipmentReplace?.map((row: any) => {
            return (
              <TableRow
                hover
                key={row.id}
                onClick={() => {
                  handleClick(row)
                }}
              >
                <TableCell sx={{ border: 'none' }}>{row.createdAt}</TableCell>
                <TableCell sx={{ border: 'none' }}>{row.file}</TableCell>
                <TableCell sx={{ border: 'none' }}>{row.equipment}</TableCell>
                <TableCell sx={{ border: 'none' }}>
                  {row.typeEquipment}
                </TableCell>
                <TableCell sx={{ border: 'none' }}>{row.code}</TableCell>
                <TableCell sx={{ border: 'none' }}>
                  {row.manufacturer}
                </TableCell>
                <TableCell sx={{ border: 'none' }}>{row.statusMui}</TableCell>
              </TableRow>
            )
          })
        ) : (
          <TableRow>
            <TableCell colSpan={7} sx={{ border: 'none' }}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: (theme) => theme.palette.text.disabled,
                }}
              >
                {'Sem dados cadastrados'}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </>
  )
}
