import { Grid } from '@mui/material'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/Input'
import { Loading } from 'components/Loading'
import { CreateControllerType } from 'types/equipment/create-equipment'

interface Props {
  control: Control<CreateControllerType>
  isView?: boolean
  errors: FieldErrors<CreateControllerType>
  setValue: UseFormSetValue<CreateControllerType>
  getValues: UseFormGetValues<CreateControllerType>
  watch: UseFormWatch<CreateControllerType>
  isLoading?: boolean
}

export function StepsEquipmentConfigurationInitial({
  control,
  isView,
  errors,
  setValue,
  getValues,
  watch,
  isLoading = false,
}: Props) {
  const { t } = useTranslation('common')
  return (
    <>
      {isLoading ? (
        <Loading mode={'circular'} />
      ) : (
        <>
          <Grid item xs={6}>
            <Input
              name='name'
              label={t('input.name')}
              control={control}
              fullWidth
              error={!!errors?.name}
              errorMessage={errors?.name?.message}
              isDisabled={isView}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name='code'
              label={t('input.code')}
              control={control}
              fullWidth
              error={!!errors?.code}
              errorMessage={errors?.code?.message}
              isDisabled={isView}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name='model'
              label={t('input.model') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.model}
              errorMessage={errors?.model?.message}
              isDisabled={isView}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name='brand'
              label={t('input.brand') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.brand}
              errorMessage={errors?.brand?.message}
              isDisabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name='topic'
              label={t('input.topic')}
              control={control}
              fullWidth
              error={!!errors?.topic}
              errorMessage={errors?.topic?.message}
              isDisabled={isView}
              required
            />
          </Grid>
        </>
      )}
    </>
  )
}
