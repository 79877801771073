import { CircularProgress, DialogActions } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Loading } from 'components/Loading'

type Props = {
  stepAction: boolean
  currentStep: number
  onCancel: () => void
  onBack: () => void
  onNext: () => void
  isView?: boolean
  titleActionsSuccess?: string
  titleActionsBack?: string
  titleActionsCancel?: string
  titleActionsNext?: string
  isLoading?: boolean
}

export function ActionsFormEquipment({
  stepAction,
  currentStep,
  onCancel,
  onBack,
  isView,
  titleActionsSuccess = 'save',
  titleActionsBack = 'back',
  titleActionsCancel = 'cancel',
  titleActionsNext = 'next',
  isLoading,
}: Props) {
  const { t } = useTranslation('common')
  return (
    <>
      <DialogActions
        sx={{
          '&>:not(:first-of-type)': { marginLeft: 2 },
          justifyContent: ['space-between', 'flex-end'],
        }}
      >
        <Button
          variant='outlined'
          type={'button'}
          onClick={currentStep === 0 ? onCancel : onBack}
        >
          {t(
            `button.title.${
              currentStep === 0 ? titleActionsCancel : titleActionsBack
            }`,
          )}
        </Button>

        {stepAction ? (
          <Button
            variant='contained'
            type={'submit'}
            disabled={isView && currentStep === 1}
          >
            {t(
              `button.title.${
                currentStep === 1 ? titleActionsSuccess : titleActionsNext
              }`,
            )}
          </Button>
        ) : isLoading ? (
          <Button variant='outlined' disabled={isView}>
            <CircularProgress size={24} />
          </Button>
        ) : (
          <Button variant='outlined' type={'submit'} disabled={isView}>
            {t(`button.title.${titleActionsSuccess}`)}
          </Button>
        )}
      </DialogActions>
    </>
  )
}
