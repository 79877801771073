/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { formatNumbersDashboard } from 'helpers/formatNumbersDashboard'
import { getConsumptionGraphicData } from 'pages/dashboard/mappers/graphic-energy-consumption-general.mapper'
import { dashboardGenerate } from 'services/invoice/energy/dashboardGenerate'

interface Props {
  isPreview?: boolean
}

export function useGeneralConsumptionChart(props: Props) {
  const { isPreview } = props
  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const { data, refetch } = useQuery({
    queryKey: ['dashboardGenerate', date],
    queryFn: () => dashboardGenerate(String(dayjs(date).format('YYYY'))),
  })

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const { consumption, goal, generalCost, totalPayment } =
    getConsumptionGraphicData(data?.results || [])

  const [period, setPeriod] = useState<PeriodType>('year')
  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 2,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const isKilowattHourAxis = context.dataset.yAxisID === 'y2'
            const key = isKilowattHourAxis ? 'prefix' : 'suffix'
            const { KILOWATT_HOUR, REAL } = DashboardTooltipSuffixEnum
            const value = isKilowattHourAxis ? REAL : KILOWATT_HOUR
            return formatNumbersDashboard({ context, [key]: value })
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return value + ' kWh'
          },
        },
      },
      y2: {
        position: 'right',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return 'R$ ' + value
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      labels: [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ],
      datasets: [
        {
          type: 'line' as const,
          label: t('chart.energy.goal'),
          data: goal,
          backgroundColor: 'transparent',
          borderColor: '#FFE375',
          yAxisID: 'y2',
        },
        {
          type: 'line' as const,
          label: t('chart.energy.overallCostOfEnergy', {
            abbreviation: '(R$/kWh)',
          }),
          data: generalCost,
          backgroundColor: 'transparent',
          borderColor: '#FF6F00',
          yAxisID: 'y2',
        },
        {
          type: 'bar' as const,
          label: t('chart.energy.consumption', {
            abbreviation: '(kWh)',
          }),
          data: consumption,
          borderRadius: 20,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.energy[0],
          ),
        },
        {
          type: 'bar' as const,
          label: t('chart.energy.invoice'),
          yAxisID: 'y2',
          data: totalPayment,
          borderRadius: 20,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.energy[1],
          ),
        },
      ],
    }

    setChartData(chartData)
  }, [i18n.language, date, data])

  return {
    isDesktop,
    date,
    options,
    setDate,
    chartData,
    chartRef,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
  }
}
