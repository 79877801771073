import { Stack, Typography, useTheme } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { WeekdayEnum } from 'core/enums/WeekdayEnum'
import moment from 'moment'
import { FC, useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { AirConditioningControl } from 'components/AirConditioningControl'
import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'
import { Loading } from 'components/Loading'
import { Modal } from 'components/Modal'
import { ModalProgrammingAirConditioning } from 'components/ModalProgrammingAirConditioning'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { getIconByEquipmentType } from 'helpers/equipment-type.helper'
import { AirConditioningSchedulingService } from 'services/air-conditioning-scheduling/airConditioningScheduling.service'
import { ModalType } from 'types/modal'
import { SubareaType } from 'types/subarea/subarea'

import { LabelSidebarMonitoring } from '../MonitoringSidebar/styles'
import { LabelNameSubarea, ModalContent, ModalWrapper } from '../styles'

type Props = ModalType & {
  selectedAirConditioning: SubareaType
}

export const ModalAirConditioningDetails: FC<Props> = ({
  open,
  onClose,
  selectedAirConditioning,
}) => {
  const [openImage, setOpenImage] = useState(false)
  const [showProgramming, setShowProgramming] = useState(false)

  const theme = useTheme()

  const imageUrl = `${BASE_URL_IMAGES}${selectedAirConditioning.equipment?.file?.name}`

  const { data: dataScheduling = [], isLoading: isLoadingScheduling } =
    useQuery({
      queryKey: [
        'air-conditioning-scheduling',
        selectedAirConditioning?.id?.value,
      ],
      queryFn: () =>
        AirConditioningSchedulingService.findByLinkedArea(
          selectedAirConditioning.id?.value,
        ),
      enabled: Boolean(selectedAirConditioning.id?.value),
    })

  const currentSchedule = useMemo(() => {
    const currentWeekday = new Date().getDay()
    const todaySchedule = dataScheduling.find(({ weekday }) => {
      const weekDaySearched =
        Object.entries(WeekdayEnum).at(currentWeekday) ?? []
      return weekday === weekDaySearched[1]
    })

    const timeCurrent = moment()
    return todaySchedule?.times.find((time) => {
      const startTime = moment(time.startTime, 'HH:mm')
      const endTime = moment(time.endTime, 'HH:mm')
      return timeCurrent.isBetween(startTime, endTime)
    })
  }, [dataScheduling])

  return (
    <div
      style={{
        position: open ? 'fixed' : 'initial',
        zIndex: 100,
        left: 0,
        height: '100%',
        width: '100%',
      }}
      onClick={onClose}
    >
      <ModalWrapper
        open={open}
        background={theme.palette.background.default}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Stack
          flexDirection={'row'}
          flexWrap={'wrap'}
          marginBottom={2}
          alignItems={'center'}
        >
          <Stack
            gap={'0.5rem'}
            flex={1}
            flexWrap={'wrap'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <LabelNameSubarea>
              {selectedAirConditioning.area?.name}
            </LabelNameSubarea>
            <LabelSidebarMonitoring>
              <img
                src={getIconByEquipmentType(EquipmentTypeEnum.AIR_CONDITIONING)}
                height={15}
                width={15}
              />
              <Typography fontSize={'10px'}>Ar condicionado</Typography>
            </LabelSidebarMonitoring>
          </Stack>
          <IconButton onClick={onClose} variant='contained' iconName='close' />
        </Stack>

        <ModalContent marginBottom='0.75rem'>
          <Typography
            fontSize={'24px'}
            fontWeight={600}
            marginBottom={'0.5rem'}
          >
            {selectedAirConditioning.equipment?.name}
          </Typography>
          <Stack
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            marginBottom={1}
          >
            <Typography fontSize={'12px'}>
              <strong>Modelo:</strong>{' '}
              {selectedAirConditioning.equipment?.model}
            </Typography>
            <Typography fontSize={'12px'}>
              <strong>Código:</strong> {selectedAirConditioning.equipment?.code}
            </Typography>
            <Typography fontSize={'12px'}>
              <strong>Fabricante:</strong>{' '}
              {selectedAirConditioning.equipment?.brand}
            </Typography>
          </Stack>
          {selectedAirConditioning?.equipment?.file && (
            <img
              src={imageUrl}
              height={140}
              width={322}
              style={{
                cursor: 'pointer',
                objectFit: 'cover',
                borderRadius: 4,
                objectPosition: 'top center',
              }}
              onClick={() => setOpenImage(true)}
              alt={selectedAirConditioning.equipment?.name}
            />
          )}
        </ModalContent>

        <ModalContent marginBottom='0.75rem'>
          <AirConditioningControl
            equipmentId={selectedAirConditioning.equipment?.id?.value}
          />
        </ModalContent>

        <ModalContent>
          <Typography marginBottom={2}>Programação atual</Typography>
          {isLoadingScheduling && <Loading />}
          {!isLoadingScheduling && (
            <>
              <Stack
                flexDirection={'row'}
                flexWrap={'wrap'}
                gap={1}
                marginBottom={2}
                alignItems={'center'}
              >
                <Input
                  label='Hora inicial'
                  value={(currentSchedule?.startTime ?? '')?.slice(0, 5)}
                  style={{ flex: 1 }}
                  name='start_time'
                />

                <Typography>até</Typography>
                <Input
                  label='Hora final'
                  value={(currentSchedule?.endTime ?? '')?.slice(0, 5)}
                  style={{ flex: 1 }}
                  name='end_time'
                />
              </Stack>
              <Input
                fullWidth
                label='Temperatura'
                name='temperature'
                value={currentSchedule?.temperature ?? ''}
                InputProps={{
                  endAdornment: 'ºC',
                }}
              />
            </>
          )}

          <Button
            variant='contained'
            sx={{
              display: 'flex',
              marginLeft: 'auto',
              marginTop: 2,
            }}
            onClick={() => setShowProgramming(true)}
          >
            Programar períodos
          </Button>
        </ModalContent>

        <Modal
          open={openImage}
          onClose={() => setOpenImage(false)}
          title={'Image_001.JPG'}
          maxWidth='md'
        >
          <img
            src={imageUrl}
            height={432}
            width={'100%'}
            style={{
              borderRadius: 4,
              objectFit: 'cover',
              objectPosition: 'top center',
            }}
            alt={selectedAirConditioning.equipment?.name}
          />
        </Modal>

        {selectedAirConditioning && (
          <ModalProgrammingAirConditioning
            open={showProgramming}
            onClose={() => setShowProgramming(false)}
            linkedAreaId={selectedAirConditioning.id?.value}
          />
        )}
      </ModalWrapper>
    </div>
  )
}
