/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { httpClient } from 'services/api/httpClient'
import { sensorService } from 'services/sensor.service'

function formatarTempoDecorrido(segundos: number): string {
  const dias = Math.floor(segundos / 86400)
  segundos -= dias * 86400

  const horas = Math.floor(segundos / 3600)
  segundos -= horas * 3600

  const minutos = Math.floor(segundos / 60)
  segundos -= minutos * 60

  const partes = []
  if (dias > 0) partes.push(`${Math.round(dias)}d`)
  if (horas > 0) partes.push(`${Math.round(horas)}h`)
  if (minutos > 0) partes.push(`${Math.round(minutos)} min`)
  if (segundos > 0) partes.push(`${Math.round(segundos)} s`)

  if (partes.length === 0) return '--'
  return partes.join(', ')
}

export function useSensorRealTimeChart() {
  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')

  const [generatingFile, setGeneratingFile] = useState(false)

  const [typeChart, setTypeChart] = useState<'quantity' | 'minute'>('quantity')

  const [selectedSensors, setSelectedSensors] = useState<any[]>([])

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'dashboard-sensor-real-time',
      startDate,
      selectedSensors,
      period,
    ],
    queryFn: async () => {
      if (!selectedSensors.length) {
        return Promise.resolve([])
      }
      const { data } = await httpClient.get('dashboard-sensor/opening', {
        params: {
          date: dayjs(date).format('YYYY-MM-DD'),
          sensorIds: selectedSensors.map((sensor) => sensor.value).join(','),
        },
      })

      return data ?? []
    },
    staleTime: 0,
    cacheTime: 0,
  })
  const filteredData = data?.reduce((result: any, item: any) => {
    const existingItem = result.find(
      (existing: any) => existing.sensor === item.sensor,
    )

    if (existingItem) {
      existingItem.amount += item.amount
      existingItem.timeseconds += item.timeseconds
    } else {
      result.push({ ...item })
    }

    return result
  }, [])

  const { data: sensors } = useQuery({
    queryKey: ['sensor', 'opening'],
    queryFn: async () => {
      return await sensorService.listAllOpeningType()
    },
    select: (data) => {
      return data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }))
    },
  })

  useEffect(() => {
    if (sensors?.length) {
      setSelectedSensors(
        sensors?.map((item) => ({
          label: item?.label,
          value: item?.value,
        })) ?? [
          {
            label: sensors[0]?.label,
            value: sensors[0]?.value,
          },
        ],
      )
    }
  }, [sensors])

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const totals =
    selectedSensors?.map((sensor) => {
      const findData =
        filteredData?.find(
          (item: any) => Number(item.sensor) === Number(sensor?.value),
        ) ?? {}

      return {
        label: sensor.label,
        ...findData,
      }
    }) ?? []

  return {
    chartData,
    setChartData,
    t,
    date,
    setDate,
    chartRef,
    isDesktop,
    period,
    setPeriod,
    isLoading,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
    typeChart,
    setTypeChart,
    sensors,
    selectedSensors,
    setSelectedSensors,
    totals,
    formatarTempoDecorrido,
  }
}
