import * as yup from 'yup'

export const schemaCreateUser = () =>
  yup.object({
    email: yup.string().email().max(50).required(),
    name: yup.string().max(50).required(),
    role: yup.string().max(50),
    rfid: yup.string().max(50),
    contact: yup.string().max(30),
  })
