import { httpClient } from 'services/api/httpClient'
import { InvoiceEnergyType } from 'types/invoice/invoice-energy'
import { PaginationParamType, PaginationType } from 'types/pagination'

export const listInvoiceEnergy = async ({
  page = 1,
  search = '',
  columnSort,
}: PaginationParamType): Promise<PaginationType<InvoiceEnergyType>> => {
  const response = await httpClient.get('/energy-invoice')
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  const results = items?.filter(
    (item: InvoiceEnergyType) =>
      String(item.consumption).toUpperCase().includes(search.toUpperCase()) ||
      String(item.consumptionDays)
        .toUpperCase()
        .includes(search.toUpperCase()) ||
      item.goal.toString().toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
    order: columnSort,
  }
}
