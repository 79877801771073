import { CARD_IMAGES } from 'constants/cardImages'
import { ModuleEnum } from 'constants/modules'

export interface MenuProps {
  name: string
  description: string
  route: string
  submenu?: MenuProps[]
  image?: string
  module?: string
  hiddenMenu?: boolean
  hiddenBreadcrumbs?: boolean
}

export const mainMenu: MenuProps[] = [
  {
    name: 'home',
    description: 'homeDescription',
    route: '/monitoring-companies',
    module: ModuleEnum.MONITORING_COMPANIES,
    image: CARD_IMAGES.HOME,
  },
  {
    name: 'dashboard',
    description: 'dashboardDescription',
    route: '/dashboard',
    module: ModuleEnum.DASH,
    image: CARD_IMAGES.DASHBOARD,
  },
  {
    name: 'notifications',
    description: 'notificationsDescription',
    route: '/notifications',
    hiddenMenu: true,
  },
  {
    name: 'map',
    description: 'mapDescription',
    route: '/map',
    module: ModuleEnum.CONF_MAP,
    image: CARD_IMAGES.MAPA,
    submenu: [
      {
        name: 'monitoring',
        description: 'monitoringDescription',
        route: '/map/monitoring-sensors',
        module: ModuleEnum.CONF_MAP,
        image: CARD_IMAGES.MONITORAMENTO_DE_SENSORES,
      },
      {
        name: 'mapSensors',
        description: 'mapSensorsDescription',
        route: '/map/mount-map',
        module: ModuleEnum.CONF_MAP,
        image: CARD_IMAGES.MONTAR_MAPA,
        submenu: [
          {
            name: 'mapUpload',
            description: 'mapUploadDescription',
            route: '/map/mount-map/upload',
          },
        ],
      },
      {
        name: 'shifts',
        description: 'shiftsDescription',
        route: '/map/shifts',
        module: ModuleEnum.CONF_MAP,
        image: CARD_IMAGES.TURNOS,
      },
      {
        name: 'measuringArea',
        description: 'measuringAreaDescription',
        route: '/map/measuring-areas',
        module: ModuleEnum.CONF_MAP,
        image: CARD_IMAGES.AREAS_DE_MEDICAO,
      },
    ],
  },
  {
    name: 'settings',
    description: 'settingsDescription',
    route: '/settings',
    module: ModuleEnum.CONF,
    image: CARD_IMAGES.CONFIGURACOES,
    submenu: [
      {
        name: 'registerSensors',
        description: 'registerSensorsDescription',
        route: '/settings/create-sensors',
        module: ModuleEnum.CONF_SENSOR,
        image: CARD_IMAGES.SENSORES,
        submenu: [
          {
            name: 'historic',
            description:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam',
            route: '/settings/create-sensors/historic',
            module: ModuleEnum.CONF_SENSOR,
          },
        ],
      },
      {
        name: 'registerEquipment',
        description: 'registerEquipmentDescription',
        route: '/settings/create-equipments',
        module: ModuleEnum.CONF_PROCEDURE,
        image: CARD_IMAGES.EQUIPAMENTOS,
      },
      {
        name: 'scalability',
        description: 'scalabilityDescription',
        route: '/settings/scalability',
        module: ModuleEnum.MAIN_CONFIG_SCALING,
        image: CARD_IMAGES.ESCALABILIDADE,
      },
      {
        name: 'notifications',
        description: 'notificationsDescription',
        route: '/settings/create-notification',
        module: ModuleEnum.CONF_NOTIFICATIONS,
        image: CARD_IMAGES.NOTIFICACOES,
      },
      {
        name: 'goalsAndParameters',
        description: 'goalsAndParametersDescription',
        route: '/settings/goals-and-parameters',
        module: ModuleEnum.CONF_GOAL,
        image: CARD_IMAGES.METAS_E_PARAMETROS,
      },
      {
        name: 'invoices',
        description: 'invoicesDescription',
        route: '/settings/invoices',
        module: ModuleEnum.CONF_INVOICE,
        image: CARD_IMAGES.FATURAS,
      },
    ],
  },
  {
    name: 'maintenance',
    description: 'maintenanceDescription',
    route: '/maintenance',
    module: ModuleEnum.MAIN,
    image: CARD_IMAGES.MANUTENCAO,
    submenu: [
      {
        name: 'preOs',
        description: 'preOsDescription',
        route: '/maintenance/pre-os',
        module: ModuleEnum.MAIN,
        image: CARD_IMAGES.PRE_OS,
      },
      {
        name: 'maintenanceSchedule',
        description: 'maintenanceScheduleDescription',
        route: '/maintenance/schedule',
        module: ModuleEnum.MAIN_CALENDAR,
        image: CARD_IMAGES.CALENDARIO_DE_MANUTENCAO,
        submenu: [
          {
            name: 'followOS',
            description: '',
            route: '/maintenance/schedule/details',
          },
        ],
      },
    ],
  },
  {
    name: 'accessControl',
    description: 'accessControlDescription',
    route: '/access-control',
    module: ModuleEnum.ACC,
    image: CARD_IMAGES.CONTROLE_DE_ACESSO,
    submenu: [
      {
        name: 'profiles',
        description: 'profilesDescription',
        route: '/access-control/profiles',
        module: ModuleEnum.ACC_PROFILE,
        image: CARD_IMAGES.PERFIS,
        submenu: [
          {
            name: 'profilesCreate',
            description: 'profilesCreateDescription',
            route: '/access-control/profiles/create',
            module: ModuleEnum.ACC_PROFILE,
          },
          {
            name: 'profilesEdit',
            description: 'profilesEditDescription',
            route: '/access-control/profiles/edit',
            module: ModuleEnum.ACC_PROFILE,
          },
          {
            name: 'profilesView',
            description: 'profilesViewDescription',
            route: '/access-control/profiles/view',
            module: ModuleEnum.ACC_PROFILE,
          },
        ],
      },
      {
        name: 'users',
        description: 'usersDescription',
        route: '/access-control/users',
        module: ModuleEnum.ACC_USER,
        image: CARD_IMAGES.USUARIOS,
      },
      {
        name: 'units',
        description: 'unitsDescription',
        route: '/access-control/units',
        module: ModuleEnum.ACC_UNIT,
        image: CARD_IMAGES.UNIT,
      },
    ],
  },
  {
    name: 'systemTutorial',
    description: 'systemTutorialDescription',
    route: '/system-tutorial',
    image: CARD_IMAGES.TUTORIAL_DO_SISTEMA,
    hiddenBreadcrumbs: true,
    submenu: [
      {
        name: 'systemTutorial',
        description: 'systemTutorialDescription',
        route: '/system-tutorial/search',
      },
    ],
  },
  {
    name: 'client',
    description: 'clientDescription',
    route: '/order-service',
    module: ModuleEnum.CLI,
    image: CARD_IMAGES.CLIENTE,
  },
  {
    name: 'btn',
    description: 'btnDescription',
    route: '/btn-panic-alert',
    module: ModuleEnum.BTN_ALERT_PANIC,
    image: CARD_IMAGES.ALERTA_BOTAO_PANICO,
    submenu: [
      {
        name: 'btnMonitoring',
        description: 'btnMonitoringDescription',
        route: '/btn-panic-alert/monitoring/area',
        module: ModuleEnum.BTN_MONITORING,
        image: CARD_IMAGES.MONITORAMENTO,
      },
      {
        name: 'btnMapPlanConfig',
        description: 'btnMapPlanConfigDescription',
        route: '/btn-panic-alert/btn-map-plan-config',
        module: ModuleEnum.BTN_CONF_MAP_PLAN,
        image: CARD_IMAGES.MAPA_PLANO_CONFIG,
      },
      {
        name: 'btnProcedure',
        description: 'btnProcedureDescription',
        route: '/btn-panic-alert/create-procedures',
        module: ModuleEnum.BTN_PROCEDURE,
        image: CARD_IMAGES.PROCEDIMENTO,
      },
      {
        name: 'btnReport',
        description: 'btnReportDescription',
        route: '/btn-panic-alert/report',
        module: ModuleEnum.BTN_REPORT,
        image: CARD_IMAGES.RELATORIO,
      },
    ],
  },
]
