import { usePermission } from 'hooks/usePermission'
import { useAuthStore } from 'store/auth.store'

type RestrictedProps = {
  children: JSX.Element
  module?: string
  permission?: string
  disabled?: boolean
}

export function Restricted({
  children,
  module,
  permission,
  disabled = false,
}: RestrictedProps): JSX.Element {
  const { user } = useAuthStore((state) => state)
  const { allowed } = usePermission(module, permission)

  if (!user && !disabled) {
    return <></>
  }

  if (!allowed && !disabled) {
    return <></>
  }

  return children
}
