/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { RowAction } from 'components/Table/RowAction'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { deleteMeasuringArea } from 'services/measuring-area/deleteMeasuringArea'
import { getMeasuringAreas } from 'services/measuring-area/getMeasuringAreas'
import { MeasuringAreaType } from 'types/measuring-area/measuringArea'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { FormMeasuringAreaModal } from './components/FormMeasuringAreaModal'

export function MeasuringAreasPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<MeasuringAreaType | null>(null)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState(true)
  const [orderBy, setOrderBy] = useState('name')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<MeasuringAreaType>,
    Error
  >(
    ['measuring-areas', page, search, orderBy],
    () => getMeasuringAreas({ page, search, columnSort: orderBy }),
    {
      refetchOnWindowFocus: false,
    },
  )

  const measuringAreas = data?.items ?? []

  const onClearSelected = () => setSelected(null)

  const handleOpenCreate = () => setOpenForm(true)

  const handleCancelForm = () => {
    setOpenForm(false)
    setOpenFormView(false)
    onClearSelected()
  }

  const handleEdit = (item: MeasuringAreaType) => {
    setSelected(item)
    handleOpenCreate()
  }

  const handleView = (item: MeasuringAreaType) => {
    setSelected(item)
    setOpenFormView(true)
    setOpenForm(true)
  }

  const handleDelete = async (item: MeasuringAreaType) => {
    try {
      await deleteMeasuringArea(Number(item.id?.value))
      toast.success(t('information.delete.success'))
      refetch()
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  const columns: ColumnType[] = [
    {
      id: 'sector',
      label: t('table.measuringAreas.areaName'),
      // label: t('table.measuringAreas.sector'),
    },

    {
      id: 'shiftName',
      label: t('table.measuringAreas.shiftName'),
    },

    // {
    //   id: 'areasName',
    //   label: t('table.measuringAreas.areasName'),
    // },

    // {
    //   id: 'muiStatus',
    //   label: t('table.measuringAreas.muiStatus'),
    // },
  ]

  return (
    <>
      <HeaderAction
        onCreate={handleOpenCreate}
        // onSearch={(search) => setSearch(search)}
      />

      <Table<MeasuringAreaType>
        columns={columns}
        order={order ? 'ASC' : 'DESC'}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrder((state) => (newOrderBy === orderBy ? !state : true))
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        hasActions
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.total,
          onChangePage: setPage,
        }}
      >
        {!!measuringAreas?.length &&
          measuringAreas?.map((row, rowIndex) => {
            return (
              <TableRow hover key={rowIndex}>
                <TableCell sx={{ border: 'none' }}>{row.name}</TableCell>
                <TableCell sx={{ border: 'none' }}>
                  {row?.shift?.name ?? '-'}
                </TableCell>
                {/* <TableCell sx={{ border: 'none' }}>
                  {row?.subAreas?.length
                    ? row.subAreas
                        .map((area) => area.name)
                        ?.toString()
                        ?.replaceAll(',', ', ')
                    : '-'}
                </TableCell> */}
                {/* <TableCell sx={{ border: 'none' }}>
                  <Toggle
                    name='toggle'
                    checked={row.active}
                    // onChange={() => handleUpdateStatus(row)}
                  />
                </TableCell> */}
                <TableCell align='right' sx={{ border: 'none' }}>
                  <RowAction.Root>
                    <RowAction.Button
                      icon='show'
                      onClick={() => handleView(row)}
                    />
                    <RowAction.Button
                      icon='edit'
                      onClick={() => handleEdit(row)}
                    />
                    <RowAction.Button
                      icon='delete'
                      onClick={() => {
                        sweetAlert({
                          onSuccess: () => handleDelete(row),
                        })
                      }}
                    />
                  </RowAction.Root>
                </TableCell>
              </TableRow>
            )
          })}
      </Table>

      {openForm && (
        <FormMeasuringAreaModal
          data={selected}
          isView={openFormView}
          open={openForm}
          onClose={handleCancelForm}
        />
      )}
    </>
  )
}
