import axios from 'axios'

const getStates = async () => {
  const response = await axios.get(
    'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome',
  )

  return {
    states: response.data,
  }
}

export const statesService = {
  getStates,
}
