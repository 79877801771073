import { Chip, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Photo } from 'components/Photo'
import { Sensor } from 'components/Sensor'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { SensorOption } from 'types/sensor/sensor'

export function equipmentMapper(equipment: any) {
  const { t } = useTranslation('common')

  return equipment.map((equipment: any) => ({
    ...equipment,
    createdAt: dayjs(new Date(equipment?.creation)).format('DD/MM/YYYY') ?? '',
    file: equipment?.file?.name ? (
      <Photo src={`${BASE_URL_IMAGES.concat(equipment?.file?.name)}`} />
    ) : (
      '-'
    ),
    equipment: equipment?.name ?? '',
    typeEquipment: t(`equipmentType.${equipment?.type}`) ?? '',
    code: equipment?.code ?? '',
    manufacturer: equipment?.brand ?? '',
    statusMui: (
      <>
        {equipment.sensors.map((item: any) => (
          <Stack
            key={item.id}
            direction='row'
            width={100}
            alignItems='center'
            sx={{ mb: equipment.sensors.length > 1 ? 0.5 : 0 }}
          >
            <Sensor
              icon={item.sensor.type as SensorOption}
              hiddenTitle={false}
              size='small'
              sx={{ marginRight: 1 }}
            />
            {item.sensor.status ? (
              <Chip
                label={t('on')}
                color='success'
                variant='outlined'
                size='small'
              />
            ) : (
              <Chip
                label={t('off')}
                color='error'
                variant='outlined'
                size='small'
              />
            )}
          </Stack>
        ))}
      </>
    ),
  }))
}
