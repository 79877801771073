import { FC } from 'react'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import { CreateParameterType } from 'types/goalAndParameter/create-parameter'

import { AirConditioningFirst } from './AirConditioningStepFirst'
import { AirConditioningLast } from './AirConditioningStepLast'

type Props = {
  control: Control<CreateParameterType>
  getValues: UseFormGetValues<CreateParameterType>
  setValue: UseFormSetValue<CreateParameterType>
  watch: UseFormWatch<CreateParameterType>
  errors: FieldErrors
  activeStep: number
  isView?: boolean
}

export const AirConditioningForm: FC<Props> = (props) => {
  const firstStep = props.activeStep === 0
  return firstStep ? (
    <AirConditioningFirst
      control={props.control}
      errors={props.errors}
      isView={props.isView ?? false}
      watch={props.watch}
    />
  ) : (
    <AirConditioningLast
      control={props.control}
      getValues={props.getValues}
      setValue={props.setValue}
      watch={props.watch}
      errors={props.errors}
      isView={props.isView ?? false}
    />
  )
}
