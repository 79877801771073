import { httpClient } from 'services/api/httpClient'
import { InvoiceWaterType } from 'types/invoice/invoice-water'
import { PaginationParamType, PaginationType } from 'types/pagination'

export const listInvoiceWater = async ({
  page = 1,
  search = '',
  columnSort,
}: PaginationParamType): Promise<PaginationType<InvoiceWaterType>> => {
  const response = await httpClient.get('/water-invoice')
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  const results = items?.filter(
    (item: InvoiceWaterType) =>
      String(item.consumption).toUpperCase().includes(search.toUpperCase()) ||
      String(item.consumptionDays)
        .toUpperCase()
        .includes(search.toUpperCase()) ||
      String(item.totalPayment).toUpperCase().includes(search.toUpperCase()) ||
      String(item.monthYear).toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
    order: columnSort,
  }
}
