import {
  Box,
  List,
  ListItem,
  Menu,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { NodeProps } from 'reactflow'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Restricted } from 'components/Restricted'
import { SensorIconButton } from 'components/Sensor/SensorIconButton'
import { SensorScheme } from 'components/Sensor/SensorScheme'
import { SensorSchemeImage } from 'components/Sensor/SensorScheme/SensorSchemeImage'
import { WavePulse } from 'components/WavePulse'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { colorBySensor } from 'helpers/colorBySensor'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { httpClient } from 'services/api/httpClient'
import { useHeatAndHumidityMapStore } from 'store/heatAndHumidityMap.store'

import HumidityBox from '../HumidityBox'
import ParameterAlert from '../ParameterAlert'
import TemperatureBox from '../TemperatureBox'
import { TypeElectricName } from '../TypeElectricName'
import { Handles } from './Handles'

export function SquareSensor(props: NodeProps) {
  const { t } = useTranslation('common')
  const { showHeatMap, showHumidityMap } = useHeatAndHumidityMapStore(
    (state) => state,
  )
  const theme = useTheme()
  const { sweetAlert } = useSweetAlert()
  const queryClient = useQueryClient()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const sensorData = props.data?.sensorMonitors || []

  const data = [
    SensorTypeEnum.AIR,
    SensorTypeEnum.ELECTRIC,
    SensorTypeEnum.TEMP,
    SensorTypeEnum.HUM,
    SensorTypeEnum.TEMP_HUM,
  ].includes(props.data?.sensor?.type?.toUpperCase())
    ? sensorData
    : sensorData?.[0]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    httpClient
      .delete(
        `/linked-area/${
          props.data?.data?.linkedAreaId ??
          props.data?.linkedAreaId ??
          props.data?.id
        }`,
      )
      .then(() => {
        toast.success(t('information.delete.success'))
        queryClient.invalidateQueries('equipment-list-btn')
        props.data?.onDelete(props.id)
      })
      .catch((error) => {
        getErrorMessage(error)
      })
  }

  return (
    <>
      <Box
        sx={{
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: props.selected
            ? theme.palette.primary.main
            : 'transparent',
          borderRadius: 1,
          position: 'relative',
        }}
      >
        {props.data?.sensor?.type?.toUpperCase() === SensorTypeEnum.TEMP_HUM ? (
          <>
            {data?.map((item: any, index: number) =>
              item?.valueType === SensorTypeEnum.TEMP
                ? showHeatMap && (
                    <TemperatureBox
                      key={index}
                      temperature={Number(Number(item?.value || 0).toFixed(0))}
                    />
                  )
                : showHumidityMap && (
                    <HumidityBox key={index} temperature={item?.value || 0} />
                  ),
            )}
          </>
        ) : (
          <>
            {showHeatMap &&
              props.data?.sensor?.type?.toUpperCase() === SensorTypeEnum.TEMP &&
              data?.map(
                (item: any, index: number) =>
                  item?.valueType === SensorTypeEnum.TEMP && (
                    <TemperatureBox
                      key={index}
                      temperature={item?.value || 0}
                    />
                  ),
              )}
            {showHumidityMap &&
              props.data?.sensor?.type?.toUpperCase() === SensorTypeEnum.HUM &&
              data?.map(
                (item: any, index: number) =>
                  item?.valueType === SensorTypeEnum.HUM && (
                    <HumidityBox key={index} temperature={item?.value} />
                  ),
              )}
          </>
        )}

        {props.data?.showStatus && props.data?.validShiftRangeTime && (
          <WavePulse status={props.data?.sensor?.status} />
        )}

        <Box
          sx={{
            position: 'relative',
            filter: props.data?.validShiftRangeTime
              ? undefined
              : 'grayscale(100%)', // verificar o turno, caso não tenha turno ativo, para a hora e dia atual, desabilitar o sensor
          }}
        >
          {/* Exibir alerta em caso do sensor fora do parametro */}
          {props.data?.sensor?.status && props.data?.validShiftRangeTime && (
            <ParameterAlert
              parameters={props.data?.parameters}
              sensor={props.data?.sensor}
              value={data?.value}
              temperature={
                props.data?.sensor?.type?.toUpperCase() ===
                  SensorTypeEnum.TEMP &&
                data?.map(
                  (item: any, index: number) =>
                    item?.valueType === SensorTypeEnum.HUM &&
                    (item?.value
                      ? Number(Number(item?.value || 0).toFixed(0))
                      : undefined),
                )
              }
              humidity={
                props.data?.sensor?.type?.toUpperCase() ===
                  SensorTypeEnum.HUM &&
                data?.map(
                  (item: any, index: number) =>
                    item?.valueType === SensorTypeEnum.HUM &&
                    (item?.value
                      ? Number(Number(item?.value || 0).toFixed(0))
                      : undefined),
                )
              }
            />
          )}
          <Stack spacing={1}>
            <SensorIconButton
              icon={props.data?.sensor?.type?.toUpperCase()}
              size='small'
              hiddenTitle
              onClick={handleClick}
            />
          </Stack>
        </Box>

        <Handles />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id={props.id}
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mb: 1.5,
            paddingX: [1, 2],
            '&.MuiPaper-root': {
              borderRadius: 1,
              outline: '2px solid',
              outlineColor: colorBySensor(
                props.data.sensor?.type as SensorTypeEnum,
              ),
              background: (theme) =>
                `${theme.palette.background.default} !important`,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={1}
          minWidth={180}
        >
          <Typography sx={{ fontSize: 16 }}>
            <b>{props.data?.sensor?.name}</b>
          </Typography>
          <Box
            sx={{
              height: 10,
              width: 10,
              borderRadius: 5,
              background: (theme) =>
                props.data?.sensor?.status
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          />
        </Stack>

        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={1}
          borderBottom={!props.data?.showDetails ? '1px solid #eee' : undefined}
        >
          <List>
            <ListItem sx={{ padding: '0 0 5px 0' }}>
              <Typography>
                {t('map.type')}: {t(`sensor.${props.data?.sensor?.type}`)}
              </Typography>
            </ListItem>
            <ListItem sx={{ padding: '0 0 5px 0' }}>
              <Typography>
                {t('map.code')}: {props.data?.sensor?.id?.value}
              </Typography>
            </ListItem>
            {/* <ListItem sx={{padding: '0 0 5px 0'}}>
              <Typography >{t('map.date')}: {dayjs(new Date(data?.[0].creation)).format('DD/MM/YYYY HH:mm:ss')}</Typography>
            </ListItem> */}
          </List>
        </Stack>
        {props.data?.sensor?.file && <SensorSchemeImage title='Ver foto' />}
        {props.data?.showDetails && (
          <Stack
            direction={'row'}
            spacing={1}
            paddingY={1}
            borderTop={'1px solid #eee'}
            borderBottom={
              props.data?.onCreateOS || props.data?.onDelete
                ? '1px solid #eee'
                : undefined
            }
            marginY={1}
            alignItems='center'
          >
            {/* Se o tipo for TEMP_HUM precisa mostrar dois sensores, um temp outro hum */}
            {props.data?.sensor?.type?.toUpperCase() ===
            SensorTypeEnum.TEMP_HUM ? (
              <Stack spacing={1}>
                {data?.map((item: any, index: number) =>
                  item?.valueType === SensorTypeEnum.TEMP ? (
                    <Stack direction={'row'} spacing={1} key={index}>
                      <SensorScheme
                        icon={props.data?.sensor?.type?.toUpperCase()}
                        popoverTitle={t('sensor.TEMP')}
                      />
                      <Typography>
                        {Number(item?.value || 0).toFixed(0)}ºC{' '}
                        {t('sensor.TEMP')}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction={'row'} spacing={1} key={index}>
                      <SensorScheme
                        icon={props.data?.sensor?.type?.toUpperCase()}
                        popoverTitle={t('sensor.HUM')}
                      />
                      <Typography>
                        {Number(item?.value || 0).toFixed(0)}% {t('sensor.HUM')}
                      </Typography>
                    </Stack>
                  ),
                )}
              </Stack>
            ) : props.data?.sensor?.type?.toUpperCase() ===
              SensorTypeEnum.TEMP ? (
              data?.map((item: any, index: number) => (
                <Stack direction={'row'} spacing={1} key={index}>
                  <SensorScheme
                    icon={props.data?.sensor?.type?.toUpperCase()}
                    popoverTitle={t('sensor.TEMP')}
                  />
                  <Typography>
                    {Number(item?.value || 0).toFixed(0)}ºC {t('sensor.TEMP')}
                  </Typography>
                </Stack>
              ))
            ) : (
              props.data?.sensor?.type?.toUpperCase() === SensorTypeEnum.HUM &&
              data?.map((item: any, index: number) => (
                <Stack direction={'row'} spacing={1} key={index}>
                  <SensorScheme
                    icon={props.data?.sensor?.type?.toUpperCase()}
                    popoverTitle={t('sensor.HUM')}
                  />
                  <Typography>
                    {Number(item?.value || 0).toFixed(0)}% {t('sensor.HUM')}
                  </Typography>
                </Stack>
              ))
            )}

            {props.data?.sensor?.type?.toUpperCase() ===
              SensorTypeEnum.WATER && (
              <Stack>
                <Typography>
                  Vazão:{' '}
                  {data?.value
                    ? data?.value?.toFixed(2)?.replace('.', ',')
                    : '--'}{' '}
                  l/s
                </Typography>
                <Typography>
                  Vazão média/dia:{' '}
                  {data?.averageValue
                    ? Number(data?.averageValue)
                        .toFixed(2)
                        ?.replace('.', ',')
                    : '--'}{' '}
                  l/s
                </Typography>
                <Typography>
                  Consumo/dia:{' '}
                  {data?.totalValue
                    ? Number(data?.totalValue)
                        .toFixed(2)
                        ?.replace('.', ',')
                    : '--'}{' '}
                  L ou{' '}
                  {data?.totalValue
                    ? (Number(data?.totalValue || 0) / 1000)
                        .toFixed(3)
                        .replace('.', ',')
                    : '--'}{' '}
                  m³
                </Typography>
              </Stack>
            )}

            {props.data?.sensor?.type?.toUpperCase() ===
              SensorTypeEnum.ELECTRIC && (
              <Stack>
                {data?.map((item: any, index: number) => (
                  <Typography key={index}>
                    <TypeElectricName type={item?.valueType} /> {item?.value}{' '}
                    {item?.unitMeasurement}
                  </Typography>
                ))}
              </Stack>
            )}

            {props.data?.sensor?.type?.toUpperCase() === SensorTypeEnum.AIR && (
              <Stack>
                {data?.map((item: any, index: number) => (
                  <Typography key={index}>
                    {item?.type} - {item?.value} {item?.unitMeasurement}
                  </Typography>
                ))}
              </Stack>
            )}
          </Stack>
        )}

        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignContent={'center'}
          spacing={1}
        >
          <Restricted
            module={ModuleEnum.MAIN_CALENDAR}
            permission={PermissionEnum.CREATE}
          >
            {props?.data?.onCreateOS && (
              <Button
                variant='text'
                size='small'
                color='inherit'
                sx={{
                  minWidth: 'auto',
                }}
                onClick={() => props?.data?.onCreateOS()}
              >
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t('map.createOs')}
                </Typography>
              </Button>
            )}
          </Restricted>

          {props.data?.onDelete && (
            <IconButton
              iconName='delete'
              size='small'
              variant='text'
              color='error'
              onClick={() => {
                handleClose()
                sweetAlert({
                  onSuccess: () => handleDelete(),
                })
              }}
            />
          )}
        </Stack>
      </Menu>
    </>
  )
}
