import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { SensorDataRecordService } from 'services/sensor-data-record/sensor-data-record.service'
import { SensorDataRecordInHourlyIntervals } from 'types/sensordata/sensor-data-hourly'

type Params = {
  sensorIds: string[]
}

export const useModalAreaControl = (params: Params) => {
  const [data, setData] = useState<SensorDataRecordInHourlyIntervals[]>([])

  const fetchSensorData = useCallback(() => {
    SensorDataRecordService.findDataInHourlyIntervals({
      measurement: '°C',
      sensorType: SensorTypeEnum.TEMP,
      day: new Date(),
      sensorIds: params.sensorIds,
    })
      .then((response) => {
        setData(response.data)
      })
      .catch(() => {
        toast.error('Erro ao listar informações de sensores')
      })
  }, [])

  useEffect(() => {
    fetchSensorData()
  }, [fetchSensorData])

  const averageSubareaTemperature = useMemo(() => {
    const sum = data.reduce((acumulator, row) => acumulator + row.value, 0)
    return sum > 0 ? sum / data.length : 0
  }, [data])

  return {
    data,
    averageSubareaTemperature,
  }
}
