import { Navigate, useLocation } from 'react-router-dom'

import { useAuthStore } from 'store/auth.store'

type ProtectedRouteProps = {
  layout: React.ComponentType
}

const ProtectedRoute = ({
  layout: Layout,
}: ProtectedRouteProps): React.ReactElement | null => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  return <Layout />
}

export default ProtectedRoute
