import { useState } from 'react'
import { useQuery } from 'react-query'

import { ordenateEquipment, typeEquipmentOrArCon } from 'constants/maps'
import { equipmentMapper } from 'pages/btnPanicAlert/mappers/equipmentBtn.mapper'
import { getAllEquipmentsByType } from 'services/equipments/getAllEquipments'
import { ColumnType } from 'types/table/column'

interface Props {
  typeEquipment?: string
  isFeeder?: boolean
}

const ITEM_PER_PAGE = 10

const groupEquipmentNoAirConditioningAndBtnPanic = `${ordenateEquipment.OPENING},${ordenateEquipment.WATER_TANK},${ordenateEquipment.COMPRESSOR_AIR},${ordenateEquipment.CONDENSER},${ordenateEquipment.DEHUMIDIFIER},${ordenateEquipment.DRYBOX},${ordenateEquipment.EXHAUST},${ordenateEquipment.GENERATOR},${ordenateEquipment.NOBREAK},${ordenateEquipment.OTHER},${ordenateEquipment.SPLITTER},${ordenateEquipment.FUEL_TANK},${ordenateEquipment.NITROGEN_PLANT}`

export const useEquipment = ({ typeEquipment, isFeeder }: Props) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')

  const { data, error, isLoading, isFetching, refetch } = useQuery<any, Error>({
    queryKey: ['equipment-list', page, search, orderBy, order, typeEquipment],
    queryFn: () =>
      getAllEquipmentsByType({
        types:
          (typeEquipment &&
            typeEquipment === typeEquipmentOrArCon.AIR_CONDITIONING) ||
          typeEquipment === typeEquipmentOrArCon.BTN_PANIC
            ? typeEquipment
            : groupEquipmentNoAirConditioningAndBtnPanic,
        feeder: isFeeder,
        columnSort: order,
        columnName: orderBy,
        page,
        numberPerPage: ITEM_PER_PAGE,
        search,
      }),
  })

  const equipmentReplace = equipmentMapper(data?.items ?? [])

  const columns: ColumnType[] = [
    {
      id: 'createdAt',
      label: 'Data de Criação',
    },
    {
      id: 'file',
      label: 'Imagem',
    },
    {
      id: 'equipment',
      label: 'Equipamento',
    },
    {
      id: 'typeEquipment',
      label: 'Tipo de Equipamento',
    },
    {
      id: 'code',
      label: 'Código',
    },
    {
      id: 'manufacturer',
      label: 'Fabricante',
    },
    {
      id: 'statusMui',
      label: 'Status do Sensor',
    },
  ]

  return {
    columns,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    search,
    setSearch,
    page,
    setPage,
    equipmentReplace,
    error,
    isLoading,
    isFetching,
    refetch,
    data,
  }
}
