import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'

export function httpDelete(
  router: string,
  callback?: () => void,
  callbackError?: () => void,
) {
  return httpClient
    .delete(router)
    .then(() => {
      callback?.()
    })
    .catch((error) => {
      getErrorMessage(error)
      callbackError?.()
    })
}
