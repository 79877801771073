export function DashboardTemperaturaAreaContent() {
  return (
    <>
      <p>
        Nesse gráfico é mostrada a temperatura e umidade conforme a seleção de
        um determinado setor ou área.
      </p>
      <p>Para selecionar a área ou setor, basta:</p>
      <p>
        <strong>Passo 1:</strong> Selecione o filtro de setores no filtro da
        direita{' '}
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        temperatura e umidade, caso esse gráfico não esteja disponibilizando o
        valor, será necessário realizar o cadastro dos sensores em Configurações{' '}
        {'->'} Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
      <p>
        Os dados relacionados a áreas devem ser cadastrados em{' '}
        <strong>Mapa {'->'} Áreas</strong>, selecione Mapa nesse tutorial para
        saber mais.
      </p>
    </>
  )
}
