import { Box, Menu, Stack, Typography, useTheme } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { FC, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { NodeProps } from 'reactflow'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Restricted } from 'components/Restricted'
import { SensorIconButton } from 'components/Sensor/SensorIconButton'
import { SensorSchemeImage } from 'components/Sensor/SensorScheme/SensorSchemeImage'
import { WavePulse } from 'components/WavePulse'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { getIconByEquipmentType } from 'helpers/equipment-type.helper'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { httpClient } from 'services/api/httpClient'
import { SensorType } from 'types/sensor/sensor'

import ParameterAlert from '../ParameterAlert'
import { Handles } from './Handles'
import { SensorData } from './SensorData'
import { EquipmentNodeItemWrapper } from './style'

export const SquareEquipment: FC<NodeProps> = (props) => {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const theme = useTheme()
  const QueryClient = useQueryClient()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isBtnPanic = props.data?.equipment.type === EquipmentTypeEnum.BTN_PANIC
  const icon = getIconByEquipmentType(
    EquipmentTypeEnum[
      props.data?.equipment.type as keyof typeof EquipmentTypeEnum
    ],
  )
  const open = Boolean(anchorEl)

  const { data } = useQuery({
    queryKey: ['sensor', props.data?.equipment?.sensors?.[0]?.id?.value],
    queryFn: () => {
      if (!props.data?.equipment?.sensors?.[0]?.id?.value)
        return Promise.resolve(undefined)

      if (
        props.data?.equipment?.sensors?.[0]?.type?.toUpperCase() !== 'WATER'
      ) {
        return httpClient.get<any[]>(
          `/sensor/datarecord/${props.data?.equipment?.sensors?.[0]?.id?.value}`,
        )
      }

      if (
        props.data?.equipment?.sensors?.[0]?.type?.toUpperCase() === 'WATER'
      ) {
        return httpClient.get<any[]>(
          `/sensor/datarecord/water/${props.data?.equipment?.sensors?.[0]?.id?.value}`,
        )
      }

      return Promise.resolve(undefined)
    },
    select: (data) => data?.data?.[0],
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handleClose()
    httpClient
      .delete(
        `/linked-area/${
          props.data?.data?.linkedAreaId ??
          props.data?.linkedAreaId ??
          props.data?.id.value ??
          props.data?.id
        }`,
      )
      .then(() => {
        toast.success(t('information.delete.success'))
        QueryClient.invalidateQueries('map-image')
        QueryClient.invalidateQueries('linked-areas')
        QueryClient.invalidateQueries('subareas')
        QueryClient.invalidateQueries('equipment-list-btn')
        QueryClient.invalidateQueries('equipment-list')
        props.data?.onDelete(props.id)
      })
      .catch((error) => {
        getErrorMessage(error)
      })
  }

  const isAirConditioning = props.data?.equipment?.type === 'AIR_CONDITIONING'

  return (
    <>
      <Box
        sx={{
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: props.selected
            ? theme.palette.primary.main
            : 'transparent',
          borderRadius: 1,
        }}
      >
        {props.data?.showStatus && props.data?.validShiftRangeTime && (
          <WavePulse
            status={props.data?.equipment?.sensors?.[0]?.sensor?.status}
          />
        )}
        {isBtnPanic ? (
          <EquipmentNodeItemWrapper>
            <SensorIconButton
              icon='BTN_PANIC'
              onClick={handleClick}
              size='small'
              sx={{
                width: '15px !important',
                height: '15px !important',
              }}
            />
          </EquipmentNodeItemWrapper>
        ) : (
          <EquipmentNodeItemWrapper
            bgColor={isAirConditioning ? '#6d87f1' : theme.palette.primary.main}
          >
            <img
              src={icon}
              alt=''
              height={15}
              width={15}
              onClick={handleClick}
            />
          </EquipmentNodeItemWrapper>
        )}

        <Box
          sx={{
            position: 'relative',
            filter: props.data?.validShiftRangeTime
              ? undefined
              : 'grayscale(100%)', // verificar o turno, caso não tenha turno ativo, para a hora e dia atual, desabilitar o sensor
          }}
        >
          {/* Exibir alerta em caso do sensor fora do parametro */}
          {props.data?.equipment?.sensors?.[0]?.sensor?.status &&
            props.data?.validShiftRangeTime && (
              <ParameterAlert
                parameters={props.data?.parameters}
                sensor={props.data?.equipment?.sensors?.[0]}
                value={data?.value}
                temperature={
                  props.data?.sensor?.type?.toUpperCase() ===
                    SensorTypeEnum.TEMP &&
                  data?.map(
                    (item: any, index: number) =>
                      item?.valueType === SensorTypeEnum.HUM &&
                      (item?.value
                        ? Number(Number(item?.value || 0).toFixed(0))
                        : undefined),
                  )
                }
                humidity={
                  props.data?.sensor?.type?.toUpperCase() ===
                    SensorTypeEnum.HUM &&
                  data?.map(
                    (item: any, index: number) =>
                      item?.valueType === SensorTypeEnum.HUM &&
                      (item?.value
                        ? Number(Number(item?.value || 0).toFixed(0))
                        : undefined),
                  )
                }
              />
            )}
        </Box>

        <Handles />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id={props.id}
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mb: 1.5,
            paddingX: [1, 2],
            '&.MuiPaper-root': {
              borderRadius: 1,
              outline: '2px solid',
              outlineColor: theme.palette.primary.main,
              background: (theme) =>
                `${theme.palette.background.default} !important`,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={1}
          minWidth={180}
          sx={{
            mb: 2,
          }}
        >
          <Typography sx={{ fontSize: 16 }}>
            {props.data?.equipment?.name}
          </Typography>

          <Box
            sx={{
              height: 10,
              width: 10,
              borderRadius: 5,
              background: (theme) =>
                props.data?.equipment?.status
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          />
        </Stack>

        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={1}
        >
          <Typography
            sx={{ color: (theme) => theme.palette.text.disabled, fontSize: 12 }}
          >
            Tipo: {t(`equipmentType.${props.data?.equipment?.type}`)}
          </Typography>
          <Typography
            sx={{ color: (theme) => theme.palette.text.disabled, fontSize: 12 }}
          >
            Cod.: {props.data?.equipment?.code}
          </Typography>
          <Typography
            sx={{ color: (theme) => theme.palette.text.disabled, fontSize: 12 }}
          >
            Fab.: {props.data?.equipment?.brand}
          </Typography>
        </Stack>

        <Stack
          direction={'column'}
          spacing={1}
          paddingY={1}
          borderBottom={
            props.data?.onCreateOS || props.data?.onDelete
              ? '1px solid #1D1E42'
              : undefined
          }
          marginY={1}
        >
          {props.data?.equipment?.file?.url && (
            <SensorSchemeImage
              src={props.data?.equipment?.file?.url}
              title='Ver foto'
            />
          )}
          {props.data?.equipment?.sensors?.map(
            (item: { sensor: SensorType }) => (
              <Stack
                key={item?.sensor?.id?.value}
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <SensorData
                  sensor={item?.sensor}
                  validShiftRangeTime={props.data?.validShiftRangeTime}
                />
              </Stack>
            ),
          )}
        </Stack>

        <Stack
          direction={'row'}
          justifyContent='flex-end'
          alignContent={'center'}
          spacing={1}
        >
          <Restricted
            module={ModuleEnum.MAIN_CALENDAR}
            permission={PermissionEnum.CREATE}
          >
            {props?.data?.onCreateOS && (
              <Button
                variant='text'
                size='small'
                color='inherit'
                sx={{
                  minWidth: 'auto',
                }}
                onClick={() => props?.data?.onCreateOS()}
              >
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t('map.createOs')}
                </Typography>
              </Button>
            )}
          </Restricted>

          {props.data?.onDelete && (
            <IconButton
              iconName='delete'
              size='small'
              variant='contained'
              onClick={() => {
                handleClose()
                sweetAlert({
                  onSuccess: () => {
                    handleDelete()
                  },
                })
              }}
              sx={{
                p: 2,
                background: (theme) => theme.palette.background.paper,
                '&:hover': {
                  background: (theme) => theme.palette.background.paper,
                },
              }}
            />
          )}
        </Stack>
      </Menu>
    </>
  )
}
