import { WeekdayEnum } from 'core/enums/WeekdayEnum'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { ProgrammingRef } from 'components/ModalProgrammingAirConditioning/FormProgramming'
import { useCheckboxSelection } from 'hooks/useCheckboxSelection'
import { AirConditioningSchedulingService } from 'services/air-conditioning-scheduling/airConditioningScheduling.service'
import { CreateAirConditioningSchedulingType } from 'types/air-conditioning-scheduling/create-air-conditioning-scheduling'
import {
  DayType,
  Params,
  RequestBodyType,
} from 'types/components/modal-programming-air-conditioning'

const standardInterval = {
  startTime: '00:00',
  endTime: '23:59',
}

export const useModalProgrammingAirConditioning = ({
  onClose,
  linkedAreaId,
}: Params) => {
  const formProgrammingRef = useRef<ProgrammingRef[]>([])

  const queryClient = useQueryClient()
  const { t } = useTranslation('common')
  const daysEnabled = useCheckboxSelection<WeekdayEnum>({
    initialValues: Object.values(WeekdayEnum) as WeekdayEnum[],
  })
  const selectedAccordions = useCheckboxSelection<WeekdayEnum>()
  const [submitIsLoading, setSubmitIsLoading] = useState(false)

  const days = useMemo<DayType[]>(
    () => [
      {
        legend: 'monday',
        value: WeekdayEnum.MONDAY,
      },
      {
        legend: 'tuesday',
        value: WeekdayEnum.TUESDAY,
      },
      {
        legend: 'wednesday',
        value: WeekdayEnum.WEDNESDAY,
      },
      {
        legend: 'thursday',
        value: WeekdayEnum.THURSDAY,
      },
      {
        legend: 'friday',
        value: WeekdayEnum.FRIDAY,
      },
      {
        legend: 'saturday',
        value: WeekdayEnum.SATURDAY,
      },
      {
        legend: 'sunday',
        value: WeekdayEnum.SUNDAY,
      },
    ],
    [],
  )

  const { data: dataScheduling = [], isLoading: isLoadingScheduling } =
    useQuery({
      queryKey: ['air-conditioning-scheduling', linkedAreaId],
      queryFn: () =>
        AirConditioningSchedulingService.findByLinkedArea(linkedAreaId),
      onSuccess(data) {
        daysEnabled.setValues(
          data.filter((row) => row.enable).map((row) => row.weekday),
        )
      },
    })

  const compareInterval = (interval: string, value: string) => {
    return interval.slice(0, 5) === value
  }

  const defaultValues = useMemo(() => {
    return dataScheduling.map(({ id, times, weekday }) => {
      const intervalOnlyContainsDefaultValues = times.every((time) => {
        return (
          compareInterval(time.startTime, standardInterval.startTime) &&
          compareInterval(time.endTime, standardInterval.endTime)
        )
      })

      const containsOnlyOneInterval = times.length < 2

      return {
        id: id.value,
        weekday,
        iSProgrammingAllHours:
          containsOnlyOneInterval && intervalOnlyContainsDefaultValues,
        interval: times.map((time) => ({
          id: time.id?.value,
          endTime: time.endTime,
          startTime: time.startTime,
          keepConnected: time.keepOn,
          temperature: time.temperature,
        })),
      }
    })
  }, [dataScheduling])

  const findDayByIndex = (index: number) => {
    return days.find((_, indexDay) => indexDay === index)
  }

  const findDataSchedulingByWeekday = (weekday: WeekdayEnum) => {
    return dataScheduling.find((row) => row.weekday === weekday)
  }

  const formatRequestInterval = (
    intervals: RequestBodyType['programmingIntervals'],
    iSProgrammingAllHours: boolean,
  ) => {
    return intervals.map(
      ({
        id,
        keepConnected = true,
        temperature,
        endTime = '',
        startTime = '',
      }) => ({
        ...(id && { id }),
        temperature,
        keepOn: keepConnected,
        startTime: iSProgrammingAllHours
          ? standardInterval.startTime
          : startTime,
        endTime: iSProgrammingAllHours ? standardInterval.endTime : endTime,
      }),
    )
  }

  const formatRequestData = (requestBody: RequestBodyType[]) => {
    return requestBody.map<CreateAirConditioningSchedulingType>((row) => ({
      id: row.id,
      times: formatRequestInterval(
        row.programmingIntervals,
        row.iSProgrammingAllHours,
      ),
      linkedArea: linkedAreaId,
      weekday: row.value,
      enable: daysEnabled.values.includes(row.value),
    }))
  }

  const handleSubmit = async (requestBody: RequestBodyType[]) => {
    try {
      setSubmitIsLoading(true)
      const data = formatRequestData(requestBody)
      for (const row of data) {
        const { id } = row
        delete row['id']

        await AirConditioningSchedulingService.updateOrCreate(row, id)
      }

      toast.success(t('savedInformation'))
      onClose()
      queryClient.invalidateQueries('air-conditioning-scheduling')
    } catch (e) {
      toast.error(t('information.save.error'))
    } finally {
      setSubmitIsLoading(false)
    }
  }

  const onSave = async () => {
    const requestBody: RequestBodyType[] = []
    const acordionsInvalid: WeekdayEnum[] = []

    const popularInvalidAccordionList = (index: number) => {
      const day = findDayByIndex(index)
      if (day && !selectedAccordions.checkValueIsActive(day?.value)) {
        acordionsInvalid.push(day.value)
      }
    }

    const populateRequestBody = (data: any, index: number) => {
      const day = findDayByIndex(index)

      if (day && daysEnabled.checkValueIsActive(day?.value)) {
        const dayRegistered = findDataSchedulingByWeekday(day?.value)

        requestBody.push({
          value: day.value,
          ...(dayRegistered && {
            id: dayRegistered.id.value,
          }),
          programmingIntervals: data.interval,
          iSProgrammingAllHours: data.iSProgrammingAllHours,
        })
      }
    }

    const formIsInValid = (index: number) => {
      return () => popularInvalidAccordionList(index)
    }

    const openAccordionsInvalid = () => {
      const { values, setValues } = selectedAccordions
      const invalidImportedAccordions = acordionsInvalid.filter(
        (value) => !values.includes(value),
      )
      setValues(values.concat(invalidImportedAccordions))
    }

    let index = 0
    for (const form of formProgrammingRef.current) {
      await form.onSubmit(
        (data) => populateRequestBody(data, index),
        formIsInValid(index),
      )
      index++
    }

    if (acordionsInvalid.length) {
      openAccordionsInvalid()
      return
    }

    const lengthDaysEnabled = daysEnabled.values.length
    const importedEveryDay = requestBody.length === lengthDaysEnabled

    if (importedEveryDay) {
      handleSubmit(requestBody)
    }
  }

  return {
    days,
    onSave,
    defaultValues,
    submitIsLoading,
    checkboxSelection: {
      daysEnabled,
      selectedAccordions,
    },
    formProgrammingRef,
    isLoadingScheduling,
  }
}
