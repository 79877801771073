import { Folder } from '@mui/icons-material'
import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import { filesize } from 'filesize'
import { useCallback, useState } from 'react'
import DropzoneComponent from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import MAX_SIZE from 'configs/maxFileUploadSize'
import { uuid } from 'helpers/uuid'

import { IconButton } from '../Button/IconButton'

interface Props {
  title?: string
  description?: string
  errorTitle?: string
  uploadingTitle?: string
  onChangeFile?: (file: File) => void
  onCancel?: () => void
  isError?: boolean
  isUploading?: boolean
  progress?: number
}

export function DropzoneFileUploading({
  title,
  description,
  errorTitle,
  uploadingTitle,
  onChangeFile,
  isError,
  isUploading,
  progress = 0,
  onCancel,
}: Props) {
  const { t } = useTranslation('common')
  const [file, setFile] = useState<File | undefined>({} as File)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles?.length) {
      toast.warn(t('alert.file.invalidFile'))
      return
    }

    if (acceptedFiles?.[0].size > MAX_SIZE) {
      toast.warn(t('alert.file.invalidSize'))
      return
    }

    const file = acceptedFiles[0]
    const fileExtension =
      file?.type?.replace('image/', '') ?? file?.name?.match(/\.([^.]+)$/)?.[1]
    const newName = `${uuid()}.${fileExtension}` // Gerar um novo nome usando UUID

    // Adicione o novo nome ao objeto File
    const renamedFile = new File([file], newName, { type: file?.type })

    onChangeFile?.(renamedFile)
    setFile(renamedFile)
  }, [])

  const handleCancel = () => {
    onCancel?.()
    setFile(undefined)
  }

  const size = filesize(file?.size ?? 0, { round: 2, standard: 'jedec' })

  return (
    <DropzoneComponent
      maxFiles={1}
      // maxSize={MAX_SIZE}
      accept={{ 'image/jpeg': [], 'image/png': [] }}
      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      disabled={isUploading || !!file?.size}
    >
      {({
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject,
      }) => (
        <Stack
          sx={{
            borderRadius: '6px',
            border: (theme) =>
              `2px dashed ${
                isDragAccept && isDragActive
                  ? theme.palette.success.main
                  : isDragReject
                  ? theme.palette.error.main
                  : theme.palette.getContrastText(
                      theme.palette.background.default,
                    )
              }`,
            borderWidth: !isUploading && file?.size ? 0 : 2,
            textAlign: isUploading ? 'left' : 'center',
            justifyContent: 'center',
            alignItems: isUploading ? 'flex-start' : 'center',
            minHeight: 142,
            '& > div': {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              padding: 3,
              borderRadius: '6px',
              background: (theme) =>
                !isUploading && file?.size
                  ? theme.palette.background.paper
                  : 'transparent',
            },
          }}
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isError && (
              <Typography variant='body1' color='error' fontWeight='bold'>
                {errorTitle ?? t('file.dropzone.error')}
              </Typography>
            )}
            {isUploading && (
              <Typography
                variant='body1'
                color={isError ? 'unset' : 'primary'}
                fontWeight='bold'
              >
                {uploadingTitle ?? t('file.dropzone.uploading')}
              </Typography>
            )}

            {!isUploading && !file?.size && (
              <Typography
                variant='body1'
                color={isError ? 'unset' : 'primary'}
                fontWeight='bold'
              >
                {title ?? t('file.dropzone.title')}
              </Typography>
            )}

            {!isUploading && !file?.size && (
              <Typography
                variant='body2'
                color={isDragReject ? 'error' : 'unset'}
              >
                {description ?? t('file.dropzone.description')}
              </Typography>
            )}

            {isUploading && (
              <Stack width='100%' spacing={1} paddingTop={1}>
                <Stack
                  direction='row'
                  spacing={2}
                  justifyContent='space-between'
                >
                  <Typography
                    variant='body2'
                    color={isDragReject ? 'error' : 'unset'}
                  >
                    {file?.name}
                  </Typography>

                  <Stack spacing={2} direction='row' alignItems='center'>
                    <Typography
                      variant='body2'
                      color={isDragReject ? 'error' : 'unset'}
                    >
                      {size?.toString()}
                    </Typography>

                    {onCancel && (
                      <IconButton
                        size='small'
                        color='inherit'
                        iconName='close'
                        onClick={handleCancel}
                      />
                    )}
                  </Stack>
                </Stack>

                <LinearProgress variant='determinate' value={progress} />
              </Stack>
            )}

            {!isUploading && file?.size && (
              <Stack
                spacing={2}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Stack spacing={2} direction='row'>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 64,
                      width: 64,
                      borderRadius: '6px',
                      padding: 2,
                      bgcolor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                  >
                    <Folder />
                  </Box>

                  <Stack alignItems='flex-start' justifyContent='center'>
                    <Typography
                      variant='body1'
                      color={isError ? 'unset' : 'primary'}
                      fontWeight='bold'
                    >
                      {file?.name}
                    </Typography>
                    <Typography
                      variant='body1'
                      color={isError ? 'unset' : 'primary'}
                      fontWeight='bold'
                    >
                      {size.toString()}
                    </Typography>
                  </Stack>
                </Stack>

                <IconButton
                  color='inherit'
                  iconName='close'
                  onClick={handleCancel}
                />
              </Stack>
            )}
          </div>
        </Stack>
      )}
    </DropzoneComponent>
  )
}
