import { PaginationParamType, PaginationType } from 'types/pagination'
import { CreateShiftType } from 'types/shift/create-shift'
import { ShiftType } from 'types/shift/shift'
import { UpdateShiftType } from 'types/shift/update-shift'

import { httpClient } from './api/httpClient'

const create = (data: CreateShiftType) => {
  return httpClient.post('/shift', data)
}

const deleteShift = (id: number) => {
  return httpClient.delete(`/shift/${id}`)
}

export const update = (id: number, shift: UpdateShiftType) => {
  return httpClient.put(`/shift/${id}`, shift)
}

export const updateStatus = (id: number) => {
  return httpClient.put(`/shift/status/${id}`)
}

type NewType = PaginationType<ShiftType>

export const findAll = async ({
  page,
  search = '',
  columnName,
  columnSort,
  numberPerPage,
}: PaginationParamType): Promise<NewType> => {
  if (!search.length) {
    const { data } = await httpClient.get('/shift', {
      params: {
        page: page - 1,
        name: search,
        columnSort,
        columnName,
        numberPerPage,
      },
    })

    return {
      results: data?.items ?? [],
      limit: data?.perPage,
      count: data?.total,

      items: data?.items ?? [],
      currentPage: page,
      perPage: data?.perPage,
      total: data?.total,
    }
  } else {
    const { data } = await httpClient.get('/shift/name', {
      params: {
        page: page - 1,
        name: search,
        columnSort: columnName,
        direction: columnSort,
        numberPerPage,
      },
    })

    return {
      results: data?.items ?? [],
      limit: data?.perPage,
      count: data?.total,

      items: data?.items ?? [],
      currentPage: page,
      perPage: data?.perPage,
      total: data?.total,
    }
  }
}

type ResponseType = ShiftType & {
  id: number
}
export const list = async (
  page = 1,
  search = '',
  orderBy?: string,
): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('/shift', {
    params: {
      page: page - 1,
      search,
      columnSort: orderBy,
      numberPerPage: 1000,
    },
  })

  return (
    data?.items?.map((item: ShiftType) => ({
      ...item,
      id: Number(item?.entityId?.value),
    })) ?? []
  )
}

export const shiftService = {
  create,
  delete: deleteShift,
  findAll,
  update,
  updateStatus,
  list,
}
