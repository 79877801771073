import { Grid, Stack, Typography } from '@mui/material'
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Button/IconButton'
import { CreateControllerType } from 'types/equipment/create-equipment'

import { ControllerNestedCommands } from './ControllerNestedCommands'

interface Props {
  control: Control<CreateControllerType>
  isView?: boolean
  errors: FieldErrors<CreateControllerType>
  setValue: UseFormSetValue<CreateControllerType>
  getValues: UseFormGetValues<CreateControllerType>
  watch: UseFormWatch<CreateControllerType>
  clearErrors: UseFormClearErrors<CreateControllerType>
}

export function StepsEquipmentConfigurationCommand({
  control,
  isView,
  errors,
  setValue,
  getValues,
  clearErrors,
  watch,
}: Props) {
  const { t } = useTranslation('common')
  const {
    fields: groupFields,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: 'groupController',
  })

  return (
    <>
      <Grid item container xs={12} height={'100%'}>
        <Grid item xs={11}>
          <Typography variant='caption' textAlign={'left'} fontSize={'11px'}>
            {t('equipment.control.description')}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Stack direction='column' alignItems='center' justifyContent='center'>
            <IconButton
              size={'small'}
              iconName='add'
              variant={'contained'}
              onClick={() =>
                appendGroup({
                  commands: [
                    {
                      mode: '',
                      fan: '',
                      temperature: 16,
                      command: '',
                      id: null,
                    },
                  ],
                  airConditioners: [''],
                })
              }
              disabled={isView}
            />
          </Stack>
        </Grid>
      </Grid>

      {/* Insert Commands and Air Conditioners */}
      <Grid
        item
        container
        spacing={2}
        flexWrap={'nowrap'}
        display={'flex'}
        xs={12}
        sx={{
          overflowX: 'auto',
        }}
      >
        {groupFields.map((group, index) => (
          <Grid
            item
            container
            xs={12}
            key={group.id}
            height={350}
            minWidth={600}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <ControllerNestedCommands
              nestIndex={index}
              isView={isView}
              control={control}
              clearErrors={clearErrors}
              errors={errors}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              removeGroup={removeGroup}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
