import { IconButton } from 'components/Button/IconButton'
import { Toggle } from 'components/Toggle'

export function EscalabilidadeContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros das escalabilidades do
        sistema, é por meio dela que serão cadastrados os níveis e a
        escalabilidade de notificações do sistema. Por exemplo, no ao cadastrar
        o nível 1 de notificações, será informado o tempo que este irá receber
        as notificações, nível dois e assim por diante. A escalabilidade, vai
        informar o usuário que ficará em cada nível notificação. Essa informação
        será importante apenas para notificar os usuários sobre vários tipos de
        chamadas. Ele é importante principalmente para Calendário de Manutenção
        (Manutenção {'->'} Calendário de Manutenção) e para Notificações
        (Configurações {'->'} Notificações), então sugerimos que antes de
        realizar o cadastro desses módulos, sempre verifique se os níveis já
        foram cadastrados. Caso esse submódulo não apareça no seu sistema
        atualmente, provavelmente o seu tipo de perfil não permite o acesso.
      </p>
      <p>
        Caso necessite o uso desse submódulo, solicite ao administrador do
        sistema.
      </p>
      <p>
        <strong>Cadastrar Nível</strong>
      </p>

      <p>Para realizar o cadastro de um novo nível, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Escalabilidade
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Cadastrar Níveis” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os níveis pré-definidos do sistema
        que são os níveis de 1 a 4, podendo cadastrar mais níveis, informar o
        tempo de espera de um nível para o outro ser acionado.
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar.
      </p>
      <p>
        <strong>Importante:</strong> Caso o nível não seja adicionado, poderá
        impossibilitar o cadastro de escalabilidade.
      </p>
      <p>
        <strong>Cadastrar Escalabilidade</strong>
      </p>

      <p>Para realizar o cadastro de uma nova escalabilidade, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Escalabilidade
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os campos que são: selecionar nível
        e usuários.
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar.
      </p>
      <p>
        <strong>Editar Escalabilidade</strong>
      </p>
      <p>Para realizar a edição de uma escalabilidade, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Escalabilidade
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a escalabilidade que vai editar
        dentro da listagem e selecionar o botão de ações
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='add' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos que aparecerão: selecionar
        nível e usuários.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar.
      </p>
      <p>
        <strong>Importante:</strong> Para realizar a edição dos níveis,
        selecione em Cadastrar Níveis , realize a edição e salve.
      </p>
      <p>
        <strong>Excluir Escalabilidade</strong>
      </p>
      <p>
        Não é possível excluir os níveis e escalabilidade, caso precise que seja
        excluído, desative o nível em <Toggle name='active' checked /> presente
        na listagem de escalabilidade.
      </p>
    </>
  )
}
