import { CreateEquipmentType } from 'types/equipment/create-equipment'

import { httpClient } from '../api/httpClient'

export const updateEquipment = (id: number, data: CreateEquipmentType) => {
  if (!data?.file) {
    return httpClient.put(`/equipment/${id}`, data)
  }

  const { file, idfile, ...rest } = data
  const formData = new FormData()

  if (idfile) {
    formData.append('idfile', String(idfile || ''))
  }

  formData.append('file', file)
  formData.append('request', JSON.stringify(rest))

  return httpClient.put(`/equipment/update/${id}`, formData)
}

export const updateStatusEquipment = async (id: string | number) => {
  return await httpClient.put(`/equipment/status/${id}`)
}
