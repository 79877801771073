import { IconButton } from 'components/Button/IconButton'

export function TutorialSistemaContent() {
  return (
    <>
      <p>
        No Módulo de Tutorial são informadas como funciona os módulos do
        sistema. Nesse tutorial atual, você poderá ter as informações iniciais
        do sistema como login, esqueceu a senha, primeiro acesso, minha conta,
        internacionalização, notificações, modo claro e escuro, e saída do
        sistema.
      </p>

      <h3>Login</h3>
      <p>
        Para realizar o Login, basta inserir o Login e a Senha que você
        cadastrou quando o e-mail de primeiro acesso foi recebido e cadastrado
        por você. Caso tenha esquecido, clique em “Esqueci Minha Senha”. Caso
        nunca tenha recebido um e-mail de primeiro acesso ou nunca realizou o
        pedido de cadastro, solicite pelo administrador do sistema.
      </p>
      <p>
        <strong>Primeiro Acesso</strong>
      </p>
      <p>
        Para realizar o login, é necessário que um administrador tenha realizado
        o seu cadastro no Módulo de Controle de Acesso {'->'} Usuários. Em
        seguida, uma mensagem será enviada para o e-mail cadastrado.
      </p>
      <p>
        <strong>Passo 1:</strong> Clique no link enviado pelo e-mail e copie o
        código contido no corpo do e-mail, ele direcionará para a definição do
        login de acesso ao sistema
      </p>
      <p>
        <strong>Passo 2:</strong> Cole o código copiado no corpo do texto do
        e-mail.
      </p>
      <p>
        <strong>Passo 3:</strong> Digite a senha e confirme
      </p>
      <p>
        <strong>Passo 4:</strong> Selecione Salvar{' '}
      </p>
      <p>
        O sistema informará que o cadastro foi realizado com sucesso. Volte ao
        Login e realize o seu acesso com o Login e a senha cadastrada.
      </p>
      <p>
        <strong>Esqueceu acesso</strong>
      </p>
      <p>Caso tenha esquecido a sua senha,</p>
      <p>
        <strong>Passo 1:</strong> Clique em “Esqueci a senha” no Login.
      </p>
      <p>
        <strong>Passo 2:</strong> Digite o seu e-mail. Um e-mail será enviado.
      </p>
      <p>
        <strong>Passo 3:</strong> Clique no link enviado pelo e-mail e copie o
        código contido no corpo do e-mail
      </p>
      <p>
        <strong>Passo 4:</strong> Cole o código copiado no corpo do texto do
        e-mail no campo disponibilizado. Uma nova tela surgirá, solicitando a
        nova senha.
      </p>
      <p>
        <strong>Passo 5:</strong> Digite a nova senha e confirme
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar{' '}
      </p>
      <p>
        O sistema informará que a troca foi realizada com sucesso. Volte ao
        Login e realize o seu acesso com o Login e a senha nova.
      </p>
      <h3>Minha Conta</h3>
      <p>Para visualizar ou editar os dados da sua conta, basta</p>
      <p>
        <strong>Passo 1:</strong> Realize o Login no sistema
      </p>
      <p>
        <strong>Passo 2:</strong> No menu superior direito, irá encontrar o
        botão de “Minha Conta”{' '}
        <IconButton iconName='personOutline' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione a opção Editar Conta{' '}
      </p>
      <p>
        <strong>Passo 4:</strong> Edite as informações: nome completo, contato,
        login e cargo. Os campos de e-mail e perfil não podem ser editados,
        podendo apenas modificar diretamente pelo administrador do sistema no
        Módulo de usuários
      </p>
      <p>
        <strong>Passo 5:</strong> Selecione Salvar{' '}
      </p>
      <p>Para Alterar a Senha, basta:</p>
      <p>
        <strong>Passo 1:</strong> Realize o Login no sistema
      </p>
      <p>
        <strong>Passo 2:</strong> No menu superior direito, irá encontrar o
        botão de “Minha Conta” .
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione a opção “Trocar minha senha”
      </p>
      <p>
        <strong>Passo 4:</strong> Digite a senha atual, a nova senha e a repita.
      </p>
      <p>
        <strong>Passo 5:</strong> Selecione Salvar{' '}
      </p>
      <p>
        Uma mensagem será enviada para o e-mail cadastrado para confirmar a
        troca de senha, só depois da confirmação que o sistema validará e
        realizará a mudança.
      </p>
      <h3>Modo Claro</h3>
      <p>
        O sistema tem duas formas de visualização geral dele, o Modo Dark, onde
        todas as telas são azul escuros. E o Modo Claro, onde as telas são de
        cor branca. Para mudar a modo do sistema, basta:
      </p>
      <p>
        <strong>Passo 1:</strong> Realize o Login no sistema
      </p>
      <p>
        <strong>Passo 2:</strong> Entre no módulo de Dashboard
      </p>
      <p>
        No menu superior direito, irá encontrar os botões de Claro e o Dark .
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione a opção que deseja.
      </p>
      <h3>Internacionalização</h3>
      <p>
        O sistema tem duas línguas, português e Inglês. Para mudar a língua,
        basta
      </p>
      <p>
        <strong>Passo 1:</strong> Realize o Login no sistema
      </p>
      <p>
        <strong>Passo 2:</strong> Entre no módulo de Dashboard
      </p>
      <p>
        No menu superior direito, irá encontrar o botão de linguagem, vindo de
        fábrica sempre com a língua português, aparecerá um ícone PT ,{' '}
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione a opção que deseja{' '}
      </p>
      <h3>Notificações</h3>
      <p>Para visualizar as notificações geradas pelo sistema, basta: </p>
      <p>
        <strong>Passo 1:</strong> Realize o Login no sistema
      </p>
      <p>
        <strong>Passo 2:</strong> No menu superior direito, irá encontrar o
        botão de notificação , selecione-o
      </p>
      <p>
        Um pequeno modal irá aparecer mostrando as notificações mais atuais,
        caso queira visualizar todas as notificações geradas pelo sistema,
        basta:
      </p>
      <p>
        <strong>Passo 1:</strong> Selecione o botão “Tudo” , “Não lidas” ou “Ver
        Tudo”{' '}
      </p>
      <p>
        Uma nova tela surgirá, onde você pode marcar as notificações que deseja
        colocar como lidas{' '}
        <IconButton iconName='check' variant='text' color='inherit' />,
        excluí-las{' '}
        <IconButton iconName='delete' variant='text' color='inherit' /> ou
        visualizar <IconButton iconName='show' variant='text' color='inherit' />{' '}
        a notificação indo até a tela que gerou a notificação. E pode escolher
        também uma notificação como favorita{' '}
        <IconButton iconName='star' variant='text' color='inherit' />.
      </p>
    </>
  )
}
