import { Box, Stack, Typography } from '@mui/material'
import {
  StatusOrderServiceEnum,
  findStatusOS,
} from 'core/enums/StatusOrderServiceEnum'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Icon } from 'components/Icon'
import { STATUS_SCHEDULE } from 'helpers/statusSchedule'
import { LogOSType } from 'types/pre-os/pre-os'

interface Props {
  reason: string
  data: LogOSType[] | null
}

export function VerticalTimeline({ data, reason }: Props) {
  const { t } = useTranslation('common')
  const getlogs = () => {
    return data?.map((item) => {
      let title = ''
      if (item.StatusLog == 'CREATE')
        title = t('oS.maintenance.createdBy').concat(' ').concat(item.user)
      else if (item.statusOS == StatusOrderServiceEnum.ACCEPTED)
        title = t('oS.maintenance.acceptedBy').concat(' ').concat(item.user)
      else if (item.statusOS == StatusOrderServiceEnum.FINISHED)
        title = t('oS.maintenance.completedBy').concat(' ').concat(item.user)
      else if (item.statusOS == StatusOrderServiceEnum.IN_PROGRESS)
        title = t('oS.maintenance.inProgressBy').concat(' ').concat(item.user)
      else if (item.statusOS == StatusOrderServiceEnum.NOT_ACCEPTED)
        title = t('oS.maintenance.notAcceptedBy').concat(' ').concat(item.user)
      else if (item.statusOS == StatusOrderServiceEnum.NOT_SOLVED)
        title = t('oS.maintenance.notResolvedBy').concat(' ').concat(item.user)
      else if (item.statusOS == StatusOrderServiceEnum.REFUSED)
        title = t('oS.maintenance.refusedBy').concat(' ').concat(item.user)
      return {
        id: item.id.value,
        title: title,
        description: moment(item.creation).format('DD/MM/YYYY HH:mm'),
        justification: reason,
        status: item.statusOS,
      }
    })
  }

  return (
    <>
      <Stack
        spacing={3}
        sx={{
          position: 'relative',
          '&::after': {
            display: 'block',
            position: 'absolute',
            content: '""',
            height: '100%',
            width: 3,
            bottom: 0,
            left: 18,
            background: (theme) =>
              theme.palette.getContrastText(theme.palette.background.default),
            zIndex: 1,
            opacity: 0.5,
          },
        }}
      >
        {getlogs()?.map((item) => (
          <Box
            key={item.id}
            display='flex'
            flexDirection='row'
            alignItems='center'
            width='100%'
          >
            <Box position={'relative'}>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: 22,
                  border: '3px solid transparent',
                  background: 'transparent',
                  zIndex: 2,
                  position: 'relative',
                  '&::before': {
                    position: 'absolute',
                    content: '""',
                    width: 40,
                    height: 40,
                    top: -3,
                    left: -3,
                    bottom: 0,
                    borderRadius: 22,
                    border: `3px solid ${
                      STATUS_SCHEDULE.COLORS[findStatusOS(item.status)]
                    }`,
                    background:
                      STATUS_SCHEDULE.COLORS[findStatusOS(item.status)],
                    zIndex: 6,
                  },
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: 40,
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 9,
                }}
              >
                <Icon name={STATUS_SCHEDULE.ICONS[findStatusOS(item.status)]} />
              </Box>
            </Box>
            <Box flex={1} display='flex' flexDirection='column' marginLeft={2}>
              <Typography variant='body2' fontSize='0.7rem'>
                {item?.description}
              </Typography>
              <Typography variant='body1' fontWeight={'bold'}>
                {item.title}
              </Typography>
              {item.justification &&
                item.status == StatusOrderServiceEnum.NOT_ACCEPTED && (
                  <Typography variant='body2' marginTop={2}>
                    {t('oS.maintenance.justification')}: {item.justification}
                  </Typography>
                )}
            </Box>
          </Box>
        ))}
      </Stack>
    </>
  )
}
