import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Table, TableActionType } from 'components/Table'
import { TabPanel, TabPanelProps } from 'components/Tabs/TabPanel'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { goalMapper } from 'pages/setting/mappers/goal.mapper'
import { listGoal } from 'services/goalAndParameter/goal/listGoal'
import { GoalType } from 'types/goalAndParameter/goal'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { FormGoalModal } from '../FormGoalModal'

type DataType = GoalType

type Props = Omit<TabPanelProps, 'children'> & {
  search?: string
  openForm: boolean
  onCloseForm: () => void
}

export function Goal(props: Props) {
  const { search, openForm, onCloseForm, ...rest } = props
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openFormEdit, setOpenFormEdit] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<DataType | null>(null)

  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('unitName')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('DESC')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<DataType>,
    Error
  >({
    queryKey: ['goal', page, search, orderBy, order],
    queryFn: () =>
      listGoal({ page, search, columnSort: order, columnName: orderBy }),
    cacheTime: 0,
    staleTime: 0,
  })
  const goal = goalMapper(data?.items ?? [])

  const handleCancel = () => {
    onCloseForm()
    setOpenFormView(false)
    setSelected(null)
    setOpenFormEdit(false)
  }

  const handleEdit = (item: DataType) => {
    setSelected(item)
    setOpenFormEdit(true)
  }

  const handleView = (item: DataType) => {
    setSelected(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: DataType) => {
    httpDelete(`/goal/${item.id}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'unitName',
      label: t('table.goalAndParameter.goal.utility'),
    },
    {
      id: 'formattedArea',
      label: t('table.goalAndParameter.goal.area'),
    },
    {
      id: 'consumption',
      label: t('table.goalAndParameter.goal.consumptionUntil'),
    },
    {
      id: 'period',
      label: t('table.goalAndParameter.goal.period'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
  ]

  const tableActions: TableActionType<DataType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_GOAL,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_GOAL,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_GOAL,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <TabPanel {...rest}>
      <Table<DataType>
        columns={columns}
        rows={goal}
        orderBy={orderBy}
        order={order}
        onOrderBy={(newOrderBy) => {
          if (newOrderBy === 'muiStatus') {
            setOrderBy('status')
            return
          }
          if (newOrderBy === 'formattedArea') {
            setOrderBy('area')
            return
          }
          setOrderBy(newOrderBy)
        }}
        onToggleOrder={() => {
          setOrder(order === 'ASC' ? 'DESC' : 'ASC')
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.perPage,
          count: data?.total,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView || openFormEdit) && (
        <FormGoalModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView || openFormEdit}
          onClose={handleCancel}
        />
      )}
    </TabPanel>
  )
}
