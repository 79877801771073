import { Stack, useTheme } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { SensorTypeEnum, getAllSensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { useEffect, useMemo, useRef, useState } from 'react'
import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionMode,
  Node,
  useNodesState,
} from 'reactflow'

import 'reactflow/dist/style.css'

import { BackgroundNodesMonitoring } from 'components/reactFlow/nodes/BackgroundNodesMonitoring'
import { SquareSensor } from 'components/reactFlow/nodes/SquareSensor'
import { ResizableNode } from 'components/reactFlow/ResizableNode'
import { addAlpha } from 'helpers/addAlpha'
import { useMonitoring } from 'hooks/map/useMonitoring'
import { useMonitoringSidebar } from 'hooks/map/useMonitoringSidebar'
import { MapTabNavigation } from 'pages/btnPanicAlert/components/MapTabNavigation'
import { useHeatAndHumidityMapStore } from 'store/heatAndHumidityMap.store'
import { useMonitoringStore } from 'store/monitoring/monitoring.store'
import { useSidebarMonitoringStore } from 'store/monitoring/sidebar-monitoring.store'
import { SubareaType } from 'types/subarea/subarea'

import { MapHumidityColors } from '../components/MapHumidityColors'
import { MapTemperatureColors } from '../components/MapTemperatureColors'
import { ModalAirConditioningDetails } from './ModalAirConditioningDetails'
import { ModalAreaControl } from './ModalAreaControl'
import { ModalEquipmentDetailing } from './ModalEquipmentDetailing'
import { MonitoringSidebar } from './MonitoringSidebar'
import { ArconditionedNode } from './Nodes/ArconditionedNode'
import { EquipmentNode } from './Nodes/EquipmentNode'
import { filter } from 'lodash'
import { FindSensor } from 'types/sensor-monitor/find-sensor-monitor'
import LoadingMinus from 'components/Loading/LoadingMinus'

const NODE_TYPES = {
  sensor: SquareSensor,
  group: ResizableNode,
  equipment: EquipmentNode,
  arconditioned: ArconditionedNode,
  background: BackgroundNodesMonitoring,
}

const INITIAL_NODES = [
  {
    id: 'id-background',
    type: 'background',
    position: {
      x: 0,
      y: 0,
    },
    data: {},
    draggable: false,
    selectable: false,
    style: {
      zIndex: '-1 !important',
    },
  },
] satisfies Node[]

export function MonitoringSensorPage() {
  const theme = useTheme()
  const {
    showHeatMap,
    showHumidityMap,
    updateShowHeatMap,
    updateShowHumidityMap,
  } = useHeatAndHumidityMapStore()

  const setLoading = (value: boolean) => loadingMinusRef?.current?.setShow(value)

  const loadingMinusRef = useRef<any>()
  const { maps } = useMonitoringStore()
  const [companyId, setCompanyId] = useState('')

  const { visibleSensorTypes, typesVisibleEquipment } =
    useSidebarMonitoringStore()
  const { company, subareas, sensorsAndEquipments, sensorsMonitorData } = useMonitoring({
    companyId,
    sensorTypes: getAllSensorTypeEnum({
      typesNotEnabled: [SensorTypeEnum.ALL, SensorTypeEnum.BTN_PANIC],
    }),
    typesEquipment: [
      EquipmentTypeEnum.WATER_TANK,
      EquipmentTypeEnum.COMPRESSOR_AIR,
      EquipmentTypeEnum.CONDENSER,
      EquipmentTypeEnum.DRYBOX,
      EquipmentTypeEnum.NOBREAK,
      EquipmentTypeEnum.SPLITTER,
      EquipmentTypeEnum.FUEL_TANK,
      EquipmentTypeEnum.NITROGEN_PLANT,
      EquipmentTypeEnum.OTHER,
      EquipmentTypeEnum.DEHUMIDIFIER,
      EquipmentTypeEnum.GENERATOR,
      EquipmentTypeEnum.EXHAUST,
      EquipmentTypeEnum.AIR_CONDITIONING,
    ],
    visibleSensorTypes,
    setLoading,
  })

  const { sidebar } = useMonitoringSidebar()

  const reactFlowWrapper = useRef<any>(null)
  const [nodes, setNodes] = useNodesState(INITIAL_NODES)
  const [selectedAirConditioning, setSelectedAirConditioning] =
    useState<SubareaType | null>(null)
  const [selectedEquipment, setSelectedEquipment] =
    useState<SubareaType | null>(null)
  const [selectedSubarea, setSelectedSubarea] = useState<null | SubareaType>(
    null,
  )

  const onSelectSubarea = (idMap: string) => {
    const subarea = subareas.find((subarea) => subarea.idMap === idMap)
    if (subarea) {
      setSelectedSubarea(subarea)
    }
  }

  const sensorsAndEquipmentOfSelectedSubarea = useMemo(() => {
    if (selectedSubarea) {
      return sensorsAndEquipments.filter(
        (row) => row.area?.id?.value === selectedSubarea.areaID?.value,
      )
    }

    return []
  }, [selectedSubarea, sensorsAndEquipments])

  useEffect(() => {
    return () => {
      updateShowHeatMap(false)
      updateShowHumidityMap(false)
    }
  }, [])

  useEffect(() => {
    const newNodes = subareas.map<Node>((subarea) => {
      return {
        id: subarea.idMap,
        type: subarea.type,
        data: {
          label: subarea.nomeSubArea,
          resizable: false,
        },
        position: {
          x: subarea.coordinateX,
          y: subarea.coordinateY,
        },
        style: {
          width: subarea.width ?? 20,
          height: subarea.height ?? 20,
          background: subarea.color
            ? addAlpha(subarea.color, 0.5)
            : '#F2578366',
          borderRadius: 5,
          fontSize: 14,
        },
      }
    })

    sensorsAndEquipments
      .filter(({ type, sensor, equipment }) => {
        const isEquipment = type === 'equipment'

        return isEquipment
          ? typesVisibleEquipment.includes(equipment.type)
          : visibleSensorTypes.includes(sensor.type)
      })
      .forEach((row) => {
        const subarea = subareas.find(
          (item) => item.areaID.value === row.area?.id.value,
        )
        const isArconditioned =
          EquipmentTypeEnum.AIR_CONDITIONING === row.equipment?.type
        const isEquipment = row.type === 'equipment'

        const sensorId = +row.sensor?.id?.value || 0
        const sensorMonitors: FindSensor[] = isEquipment ? [] : filter(sensorsMonitorData, { id: sensorId })

        newNodes.push({
          id: row.idMap,
          type: isArconditioned ? 'arconditioned' : row.type,
          position: {
            x: row.coordinateX,
            y: row.coordinateY,
          },
          ...(subarea?.idMap && {
            extent: 'parent',
            parentNode: subarea?.idMap,
          }),
          data: {
            label:
              (isEquipment ? row.equipment : row.sensor).name ??
              'não identificado',
            type: (isEquipment ? row.equipment : row.sensor).type,
            measurement: 22,
            code: row.equipment?.code,
            manufacturer: {
              name: row.equipment?.brand,
            },
            area: {
              name: row.area?.name,
            },
            showDetails: true,
            validShiftRangeTime: true,
            sensor: row.sensor,
            sensorMonitors,
            ...(isArconditioned && {
              handleClick: () => setSelectedAirConditioning(row),
            }),
            ...(isEquipment &&
              !isArconditioned && {
                handleClick: () => setSelectedEquipment(row),
              }),
          },
        })
      })

    setNodes([...INITIAL_NODES, ...newNodes])
  }, [
    visibleSensorTypes,
    subareas,
    typesVisibleEquipment,
    sensorsAndEquipments,
    sensorsMonitorData,
  ])

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <MonitoringSidebar
        {...sidebar}
        companies={company.items}
        companyId={companyId}
        changeCompanyId={(value) => setCompanyId(value)}
      />

      <MapTabNavigation
        parentElementIsOpen={sidebar.sidebarVisibility}
        parentElementSize={{
          large: sidebar.SIDEBAR_SIZE_LARGE,
          small: sidebar.SIDEBAR_SIZE_SMALL,
        }}
        hideActionNewArea
        maps={maps}
      />

      <Stack
        sx={{
          position: 'relative',
          height: 'calc(100vh - 104px)',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <ReactFlow
            nodeTypes={NODE_TYPES}
            nodes={nodes}
            connectionMode={ConnectionMode.Loose}
            defaultEdgeOptions={{
              type: 'default',
            }}
            onNodeClick={(_, node) => {
              if (
                node.type === 'group' &&
                typesVisibleEquipment.includes(
                  EquipmentTypeEnum.AIR_CONDITIONING,
                )
              ) {
                onSelectSubarea(node.id)
              }
            }}
            zoomOnDoubleClick={false}
            minZoom={0.2}
            fitView
            ref={reactFlowWrapper}
          >
            <Background
              gap={32}
              color={theme.palette.background.paper}
              variant={BackgroundVariant.Lines}
            />
          </ReactFlow>

          {showHeatMap && (
            <MapTemperatureColors
              sx={{
                left: 'initial',
                right: '1rem',
              }}
            />
          )}
          {showHumidityMap && (
            <MapHumidityColors
              sx={{
                bottom: showHumidityMap && showHeatMap ? 20 : 72,
                left: 'initial',
                right: '1rem',
              }}
            />
          )}
        </Stack>
      </Stack>

      {selectedSubarea && (
        <ModalAreaControl
          open={Boolean(selectedSubarea)}
          onClose={() => setSelectedSubarea(null)}
          sensorsAndEquipments={sensorsAndEquipmentOfSelectedSubarea}
        />
      )}

      {selectedAirConditioning && (
        <ModalAirConditioningDetails
          open={Boolean(selectedAirConditioning)}
          onClose={() => setSelectedAirConditioning(null)}
          selectedAirConditioning={selectedAirConditioning}
        />
      )}

      {selectedEquipment && (
        <ModalEquipmentDetailing
          open={Boolean(selectedEquipment)}
          selectedEquipment={selectedEquipment}
          onClose={() => setSelectedEquipment(null)}
        />
      )}

      <LoadingMinus ref={loadingMinusRef} />
    </Stack>
  )
}
