import { Link, LinkProps, Typography } from '@mui/material'
import { Link as RouteLink } from 'react-router-dom'

interface Props extends LinkProps {
  link: string
  children?: React.ReactNode
  disabled?: boolean
}

export function BreadcrumbLink({ link, children, disabled, ...rest }: Props) {
  return !disabled ? (
    <Link
      component={RouteLink}
      to={link}
      color='inherit'
      underline='hover'
      {...rest}
    >
      {children}
    </Link>
  ) : (
    <Typography component='div' color='text.primary'>
      {children}
    </Typography>
  )
}
