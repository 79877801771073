import { Stack, TableCell, TableRow } from '@mui/material'

import { HeaderAction } from 'components/HeaderAction'
import { Restricted } from 'components/Restricted'
import { Sensor } from 'components/Sensor'
import { Table } from 'components/Table'
import { RowAction } from 'components/Table/RowAction'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { DataBranchType } from 'types/branch/branch'
import { SensorOption } from 'types/sensor/sensor'

import { FormBranchModal } from './components/FormBranchModal'
import { useBranches } from './hooks/useBranches'

export const BranchesPage = () => {
  const {
    columns,
    openForm,
    openFormEdit,
    openFormView,
    orderBy,
    page,
    search,
    setSearch,
    selected,
    branches,
    data,
    error,
    isFetching,
    isLoading,
    tableActions,
    handleCreate,
    handleDelete,
    handleEdit,
    handleView,
    handleCancel,
    setOrderBy,
    setPage,
    sweetAlert,
  } = useBranches()

  return (
    <>
      <HeaderAction
        buttonTitle={'Nova Unidade'}
        onCreate={handleCreate}
        onSearch={(search) => setSearch(search)}
        module={ModuleEnum.ACC_UNIT}
        permission={PermissionEnum.CREATE}
      />

      <Table<DataBranchType>
        columns={columns}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      >
        {branches?.map((row) => (
          <TableRow hover key={row.id.value}>
            <TableCell
              sx={{
                border: 'none',
                width: 'calc(100% / 7)',
              }}
            >
              {row.name}
            </TableCell>
            <TableCell
              sx={{
                border: 'none',
                width: 'calc(100% / 7)',
              }}
            >
              {row.city}
            </TableCell>
            <TableCell
              sx={{
                border: 'none',
                width: 'calc(100% / 7)',
              }}
            >
              {row.state}
            </TableCell>
            <TableCell
              sx={{
                border: 'none',
                width: 'calc(100% / 7)',
              }}
            >
              {row.streetAddress}
            </TableCell>
            <TableCell
              sx={{
                border: 'none',
                width: 'calc(100% / 7)',
              }}
            >
              {row.zipCode}
            </TableCell>
            <TableCell sx={{ border: 'none', width: 'calc(100% /7)' }}>
              <Stack direction='row' width={100}>
                {row.utilities
                  .sort((a, b) => a.id - b.id)
                  .map((utility) => (
                    <Stack direction='row' key={utility.id}>
                      <Sensor
                        icon={utility.name as SensorOption}
                        hiddenTitle={false}
                        size='small'
                        sx={{ marginRight: 1 }}
                      />
                    </Stack>
                  ))}
              </Stack>
            </TableCell>
            <TableCell align='right' sx={{ border: 'none' }}>
              <RowAction.Root>
                <Restricted
                  module={ModuleEnum.CONF_SENSOR}
                  permission={PermissionEnum.VIEW}
                >
                  <RowAction.Button
                    icon='show'
                    onClick={() => handleView(row)}
                  />
                </Restricted>
                <Restricted
                  module={ModuleEnum.CONF_SENSOR}
                  permission={PermissionEnum.EDIT}
                >
                  <RowAction.Button
                    icon='edit'
                    onClick={() => handleEdit(row)}
                  />
                </Restricted>

                <Restricted
                  module={ModuleEnum.CONF_SENSOR}
                  permission={PermissionEnum.DELETE}
                >
                  <RowAction.Button
                    icon='delete'
                    onClick={() => {
                      sweetAlert({
                        onSuccess: () => handleDelete(row),
                      })
                    }}
                  />
                </Restricted>
              </RowAction.Root>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      {(openForm || openFormView || openFormEdit) && (
        <FormBranchModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView || openFormEdit}
          onClose={handleCancel}
        />
      )}
    </>
  )
}
