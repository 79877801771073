import { httpClient } from 'services/api/httpClient'
import { GraphicQuantityProducedType } from 'types/goalAndParameter/dashboard/graphic-quantity-procuced'
import { PaginationType } from 'types/pagination'

export const dashboardGenerateQuantityProduced = async (
  year: string,
): Promise<PaginationType<GraphicQuantityProducedType>> => {
  // if yer null or undefined, set year to current year
  if (!year) {
    const date = new Date()
    year = date.getFullYear().toString()
  }
  const response = await httpClient.get(
    `/quantity-produced/dashboard/consumption-general/by-year/${year}`,
  )
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  return {
    results: items,
    limit: perPage,
    count: total,
  }
}
