import { Menu, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Button/IconButton'
import { IconNamesProps } from 'components/Icon'

interface RowActionRootProps {
  icon?: IconNamesProps
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  size?: 'small' | 'medium' | 'large'
  children: React.ReactNode
}

export function RowActionRoot({
  icon = 'moreVert',
  color,
  size = 'small',
  children,
}: RowActionRootProps) {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const variantButton = isDesktop ? 'text' : 'outlined'

  return (
    <>
      <IconButton
        variant={open ? 'contained' : variantButton}
        color={color}
        size={size}
        iconName={icon}
        title={t('button.title.action') ?? ''}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          background: 'transparent',
        }}
      />

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: 8,
          },
        }}
        sx={{
          '& .MuiPaper-root': {
            background: (theme) =>
              theme.palette.mode === 'dark'
                ? '#141432 !important'
                : `${theme.palette.background.paper} !important`,
          },
          '& ul': {
            padding: 0,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack direction='row' spacing={1} onClick={handleClose}>
          {children}
        </Stack>
      </Menu>
    </>
  )
}
