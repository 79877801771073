import { Box, Grid, Stack, Typography } from '@mui/material'
import { LatLngExpression } from 'leaflet'
import { useRef, useState } from 'react'

import IconEquipmentImg from 'assets/images/map/icon-equipment.svg'
import IconPinImg from 'assets/images/map/icon-pin.svg'
import IconSensorImg from 'assets/images/map/icon-sensor.svg'
import { Map } from 'components/MapContainer'
import { useMonitoringSensorStore } from 'store/monitoring-sensor.store'
import { theme } from 'styles/themes/muiTheme'
import { MonitoringCompanies } from 'types/monitoring-companies'

interface Props {
  data: MonitoringCompanies[]
}
interface Location {
  latitude: number
  longitude: number
  zoom: number
}

export const MapMonitoring = ({ data }: Props) => {
  const { setShowDataCompanyDetail, setCurrentCompany } =
    useMonitoringSensorStore()

  const [position] = useState<LatLngExpression>([
    data?.[0]?.latitude ?? 0,
    data?.[0]?.longitude ?? 0,
  ])
  const [mapLocation, setMapLocation] = useState<Location | null>(null)
  const refMap = useRef(null)

  const handleCompany = (company: MonitoringCompanies) => {
    setShowDataCompanyDetail(false)
    setCurrentCompany(company)
    setMapLocation({
      latitude: company.latitude,
      longitude: company.longitude,
      zoom: 16,
    })
  }

  return (
    <Stack
      direction='column'
      flex={1}
      sx={{ width: '100%', height: 'calc(100vh - 104px)' }}
    >
      <Box>
        <Map.Root
          center={position}
          zoom={13}
          scrollWheelZoom={true}
          style={{ height: 'calc(100vh - 104px)' }}
          attributionControl={false}
          zoomControl={false}
          boxZoom={true}
          ref={refMap}
        >
          <Map.Layer />
          <Map.ZoomControl position='bottomright' />
          {data?.map((company, index) => {
            return (
              <>
                <Map.Pin
                  variant='jump-circular'
                  position={[company.latitude, company.longitude]}
                  isEditMode={false}
                  onClick={() => {
                    handleCompany(company)
                  }}
                  key={index}
                >
                  <Map.Tooltip direction='right' offset={[30, -10]} opacity={1}>
                    <Stack
                      direction='column'
                      sx={{
                        maxWidth: 400,
                        background: (theme) => theme.palette.background.paper,
                        borderRadius: 1,
                        opacity: 1,
                        m: -2,
                        p: 2,
                      }}
                      className='custom-tooltip'
                    >
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.text.primary,
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {company.name ?? 'Nome da empresa'}
                      </Typography>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='center'
                        sx={{ width: 300, height: theme.spacing(10) }}
                      >
                        <Grid item xs={1}>
                          <img src={IconPinImg} width={16} height={16} alt='' />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              fontSize: 14,
                            }}
                          >
                            {`Endereço: ${company?.streetAddress ?? ''} `}
                          </Typography>
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              fontSize: 14,
                            }}
                          >
                            {`${company?.district ?? ''} ${
                              company?.city ?? ''
                            } `}
                          </Typography>
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              fontSize: 14,
                            }}
                          >
                            {`${company?.state ?? ''}, CEP: ${
                              company?.zipCode ?? ''
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        sx={{ width: 300 }}
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item xs={1}>
                          <img
                            src={IconSensorImg}
                            width={16}
                            height={16}
                            alt=''
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              height: 25,
                              fontSize: 14,
                            }}
                          >
                            {`Qtd de sensores: ${company?.information?.totalSensor}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{ width: 300 }}
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid item xs={1}>
                          <img
                            src={IconEquipmentImg}
                            width={16}
                            height={16}
                            alt=''
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              height: 25,
                              fontSize: 14,
                            }}
                          >
                            {`Qtd de equipamentos: ${company?.information?.totalEquipment}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Map.Tooltip>
                </Map.Pin>
              </>
            )
          })}
          <Map.FlyToCoords
            coords={{
              latitude: mapLocation?.latitude ?? -23.5489,
              longitude: mapLocation?.longitude ?? -46.6388,
            }}
            zoom={mapLocation?.zoom}
          />
        </Map.Root>
      </Box>
    </Stack>
  )
}
