import { Popover, Stack, SxProps, Typography } from '@mui/material'
import React, { useState } from 'react'

import { Icon, IconNamesProps } from 'components/Icon'

interface Props {
  iconName?: IconNamesProps
  textPopover: string
  sx: SxProps
}

export const MouseOnPopover = ({
  iconName = undefined,
  textPopover,
  sx,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Stack
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Icon name={iconName ?? 'infoOutlined'} />
      </Stack>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
          ...sx,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{textPopover}</Typography>
      </Popover>
    </>
  )
}
