import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from 'components/Button'
import { Topic, TopicButtons } from 'components/Button/TopicButtons'
import { Logo } from 'components/Logo'

import { Content } from './styles'

interface Props {
  isDesktop: boolean
}

export function TutorialMenu({ isDesktop }: Props) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchValue = search?.replace('?q=', '')?.replace('?topicId=', '')

  const [showMobileTopics, setShowMobileTopics] = useState(false)
  const [topics] = useState<Topic[]>([
    {
      id: '706af01b-c30e-478f-86f2-d163c2c0d213',
      name: 'Dashboard',
      subtopics: [
        {
          id: '8d15f831-04a3-441c-80fe-aec4886f6685',
          name: 'Água',
        },
        {
          id: 'c79041d6-6315-4b4d-8de7-402636bca0fe',
          name: 'Energia',
        },
        {
          id: '300016c6-55f1-453d-83e8-330d55982550',
          name: 'Gás',
        },
        {
          id: '96f11856-a3f9-4205-8e7e-3dedb0da9e86',
          name: 'Temperatura e umidade',
        },
      ],
    },
    {
      id: '8aefcaef-5c42-4b5b-8ced-1b057d729eee',
      name: 'Mapa',
      subtopics: [
        {
          id: '070b0581-4ec1-44d4-82eb-a132b898cd70',
          name: 'Monitoramento de sensores',
        },
        {
          id: '3c850e82-2161-489c-8969-53a669bbec3d',
          name: 'Montar mapa',
        },
        {
          id: '4c2c80cb-be8f-4f08-8e58-5dae27c3099b',
          name: 'Turnos',
        },
        {
          id: '95742866-eb8c-4eee-8f35-2658078f9ea6',
          name: 'Áreas de medição',
        },
      ],
    },
    {
      id: '55f2d4ae-6f4d-4989-8e0d-3e8859b2531d',
      name: 'Configurações',
      subtopics: [
        {
          id: '7d72df70-5487-4a53-8e8b-915e8d95b4f0',
          name: 'Sensores',
        },
        {
          id: 'f4fe4076-10c2-4c7d-8b54-804500702606',
          name: 'Equipamentos',
        },
        {
          id: '0a90be70-ed58-412a-8be4-cd4b44c8788b',
          name: 'Escalabilidade',
        },
        {
          id: '2d199fdd-501a-4c0e-85aa-d8c8e020e930',
          name: 'Notificações',
        },
        {
          id: 'd2489944-f037-4b67-87cb-959749f91ea3',
          name: 'Metas e parâmetros',
        },
        {
          id: 'a2b4b28a-c1e0-45b8-8a04-6a7c50a46235',
          name: 'Faturas',
        },
      ],
    },
    {
      id: '691d0400-94ad-49bc-8aad-57b2fe8b4e56',
      name: 'Manutenção',
      subtopics: [
        {
          id: '1f4b33b5-7b27-4a8d-8987-0f8a5a282c79',
          name: 'Pré OS',
        },
        {
          id: '57937146-48aa-4554-8337-39e7c43437cc',
          name: 'Calendário de manutenção',
        },
      ],
    },
    {
      id: '851d439d-0d78-49a1-81b6-c6aaa4b97a82',
      name: 'Controle de acesso',
      subtopics: [
        {
          id: '851d439d-0d78-49a1-81b6-c6aaa4b97a821',
          name: 'Perfis',
        },
        {
          id: '851d439d-0d78-49a1-81b6-c6aaa4b97a822',
          name: 'Usuários',
        },
      ],
    },
    {
      id: '6adfb609-6370-4126-84f9-cd336e8fa756',
      name: 'Cliente',
    },
    {
      id: '6adfb609-6370-4126-84f9-cd336e8fa42s',
      name: 'Minhas Informações',
    },
    {
      id: '6adfb609-6370-4126-84f9-cd336e8fa42H',
      name: 'Tutorial do Sistema',
    },
  ])

  const handleSelectTopic = (id: string | number) => {
    return navigate(`/system-tutorial/search?topicId=${id}`)
  }

  const TopicsItems = () => (
    <Stack spacing={1}>
      {topics?.map((item) => (
        <TopicButtons
          key={item.id}
          topic={item}
          onClick={handleSelectTopic}
          activeTopicId={searchValue}
        />
      ))}
    </Stack>
  )

  return (
    <>
      {isDesktop ? (
        <Content spacing={3}>
          <Stack
            direction='row'
            justifyContent={'center'}
            marginBottom={2}
            onClick={() => navigate('/')}
            sx={{ cursor: 'pointer' }}
          >
            <Logo mode='LOGOTIPO' />
          </Stack>

          <Typography>
            {t('Encontre o que precisa navegando pelo menu lateral')}
          </Typography>

          <TopicsItems />
        </Content>
      ) : (
        <Stack padding={2}>
          <Button
            variant='text'
            onClick={() => setShowMobileTopics((state) => !state)}
            fullWidth
            color='inherit'
            endIcon={showMobileTopics ? <ExpandLess /> : <ExpandMore />}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            Tópicos
          </Button>

          <Collapse in={showMobileTopics}>
            <TopicsItems />
          </Collapse>
        </Stack>
      )}
    </>
  )
}
