/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextFieldProps } from '@mui/material'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

import { ValidationError } from 'types/ValidationError'

import { InputMui } from './InputMui'

type Props = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  showIcon?: boolean
  helpWithIcon?: string
  errorMessage?: any | ValidationError | string
}

export function InputIpAddress({ control, ...rest }: Props) {
  const [formattedValue, setFormattedValue] = useState('')

  return (
    <>
      {control ? (
        <Controller
          name={rest?.name}
          control={control}
          defaultValue=''
          render={({ field }) => (
            <InputMui
              {...rest}
              {...field}
              onChange={(event) => {
                const numericValue = event.target.value
                  .replace(/[^\d.]/g, '')
                  .slice(0, 15)

                field.onChange(numericValue)
              }}
            />
          )}
        />
      ) : (
        <InputMui
          {...rest}
          value={formattedValue}
          onChange={(event) => {
            const numericValue = event.target.value
              .replace(/[^\d.]/g, '')
              .slice(0, 15)

            setFormattedValue(numericValue)
          }}
        />
      )}
    </>
  )
}
