/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'

import { ShiftType } from 'types/shift/shift'
export function shiftMapper(shift: ShiftType[]) {
  const { t } = useTranslation('common')
  const days: any = {
    MONDAY: t('monday'),
    TUESDAY: t('tuesday'),
    WEDNESDAY: t('wednesday'),
    THURSDAY: t('thursday'),
    FRIDAY: t('friday'),
    SATURDAY: t('saturday'),
    SUNDAY: t('sunday'),
  }
  return shift.map((item) => ({
    ...item,
    days: item.days?.map((day) => {
      return {
        ...day,
        name: days[String(day.name)],
      }
    }),
  }))
}
