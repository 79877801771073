/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Stack, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Input } from 'components/Input'
import { MaskedInputNumber } from 'components/Input/mask/MaskedInputNumber'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { getAllAreas } from 'services/area/getAllAreas'
import { createGoal } from 'services/goalAndParameter/goal/createGoal'
import { updateGoal } from 'services/goalAndParameter/goal/updateGoal'
import { unitService } from 'services/unit.service'
import { AreaType } from 'types/area/area'
import { CreateGoalType } from 'types/goalAndParameter/create-goal'
import { GoalType } from 'types/goalAndParameter/goal'
import { ModalType } from 'types/modal'
import { UnitType } from 'types/unit/unit'

import { schemaCreateGoal } from '../validations/create-goal.validation'

interface Props extends ModalType {
  data: GoalType | null
  isView?: boolean
}

export function FormGoalModal({
  data,
  isView,
  onClose,
  open,
}: Readonly<Props>) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { data: units } = useQuery({
    queryKey: 'all-unit',
    queryFn: () => unitService.list(),
    select: (data: any[]) =>
      data?.flatMap((unit: UnitType) =>
        unit.name.includes('WATER') || unit.name.includes('ELECTRIC')
          ? {
              id: String(unit.id),
              name: t(unit.name),
            }
          : [],
      ),
    cacheTime: 0,
    staleTime: 0,
  })

  const { data: areas } = useQuery({
    queryKey: 'all-area',
    queryFn: () => getAllAreas(),
    cacheTime: 0,
    staleTime: 0,
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<CreateGoalType>({
    resolver: yupResolver(schemaCreateGoal),
    defaultValues: {
      unit: data?.unit.id?.value ? String(data?.unit.id?.value) : undefined,
      area: data?.area?.id.value ? String(data?.area?.id.value) : undefined,
      consumption: data?.consumption ?? '',
      period: data?.period ?? '',
      // status: data?.status ?? true,
    },
  })
  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('goal')
    queryClient.invalidateQueries('goal-water')
    onClose()
  }

  const mutationCreate = useMutation(
    (data: CreateGoalType) => {
      return createGoal(data)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: CreateGoalType) => {
      return updateGoal(Number(data?.id) ?? 0, update)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateGoalType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('goalAndParameter.goal.view')

    if (data?.id) return t('goalAndParameter.goal.edit')

    return t('goalAndParameter.goal.new')
  }

  return (
    <>
      <Modal title={getTitle()} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12} sm={12}>
            <Select
              name='unit'
              label={t('input.utility')}
              options={units ?? []}
              control={control}
              fullWidth
              error={!!errors?.unit}
              errorMessage={errors?.unit?.message}
              required
              disabled={isView}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Select
              name='area'
              label={t('input.area')}
              options={areas?.map((area: AreaType) => ({
                id: String(area.id),
                name: area.name,
              }))}
              control={control}
              fullWidth
              error={!!errors?.area}
              errorMessage={errors?.area?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction={'row'} alignItems='center' spacing={2}>
              <Typography whiteSpace='nowrap'>
                {t('input.consumptionUntil')}
              </Typography>
              <Input
                name='consumption'
                label={watch('unit') === '2' ? 'kW' : 'm³'}
                type='number'
                control={control}
                fullWidth
                error={!!errors?.consumption}
                errorMessage={errors?.consumption?.message}
                isDisabled={isView}
                InputProps={{
                  inputComponent: MaskedInputNumber as any,
                }}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='period'
              label={t('input.period')}
              control={control}
              fullWidth
              error={!!errors?.period}
              errorMessage={errors?.period?.message}
              isDisabled={isView}
              required
            />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
