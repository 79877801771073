import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnType } from 'types/table/column'

export function useAlertPanicScreen() {
  const { t } = useTranslation('common')
  const [openForm, setOpenForm] = useState(false)
  const [formView, setFormView] = useState(false)

  const handleOpen = () => setOpenForm(true)
  const handleClose = () => {
    setOpenForm(false)
    setFormView(false)
  }

  const handleView = () => {
    setFormView(true)
    setOpenForm(true)
  }

  const columns: ColumnType[] = [
    {
      id: 'triggerDate',
      label: t('panicMonitoring.list.date'),
    },
    {
      id: 'location',
      label: t('panicMonitoring.list.location'),
    },
    {
      id: 'name',
      label: t('panicMonitoring.list.buttonName'),
    },
    {
      id: 'type',
      label: t('panicMonitoring.list.type'),
    },
    {
      id: 'actions',
      label: t('panicMonitoring.list.action'),
    },
  ]

  return { columns, openForm, handleOpen, handleClose, handleView, formView }
}

export function formatDate(dateString: any, format: boolean) {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formattedDay = day < 10 ? `0${day}` : day
  const formattedMonth = month < 10 ? `0${month}` : month
  const formattedYear = year
  const formattedHours = hours < 10 ? `0${hours}` : hours
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  if (format) {
    return `${formattedDay}/${formattedMonth}/${formattedYear} - ${formattedHours}h:${formattedMinutes}`
  }

  return `${formattedDay}/${formattedMonth} - ${formattedHours}h:${formattedMinutes}`
}
