import { FormMyAccountType, MyAccountType } from 'types/my-account/myAccount'

import { httpClient } from './api/httpClient'

type ResponseType = MyAccountType & {
  id: number
}

export const get = async (id: number): Promise<ResponseType> => {
  const { data } = await httpClient.get(`my-account/${id}`)

  return data
}

export const update = ({
  id,
  data,
}: {
  id: number
  data: FormMyAccountType
}) => {
  return httpClient.put(`/my-account/${id}`, data)
}

export const myAccountService = {
  get,
  update,
}
