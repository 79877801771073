import { Divider, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Modal } from 'components/Modal'
import {
  SensorDataRecordService,
  ValueType,
} from 'services/sensor-data-record/sensor-data-record.service'
import { ModalType } from 'types/modal'

import { LinearHumidity } from '../graphic/LinearHumidity'

interface Props extends ModalType {
  data?: any
}
export function ModalSensorsHumidity({ data, onClose, open }: Props) {
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [orderBy, setOrderBy] = useState('sensorName')

  const { t } = useTranslation('common')

  const { data: sensors } = useQuery({
    queryKey: ['sensorsByHum', page, orderBy, order, data?.id],
    queryFn: () =>
      SensorDataRecordService.findAllDataSensorByAreaAndValueType({
        page,
        columnSort: order,
        columnName: orderBy,
        areaId: data?.id,
        valueType: ValueType.HUMIDITY,
      }),
    enabled: !!data?.id,
  })

  return (
    <Modal
      title={
        `${t('dashboard.humidity.titleSensor')}: ` +
        (data?.name ? ` - ${data?.name}` : '')
      }
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
    >
      <Stack spacing={2}>
        <LinearHumidity
          label={t('dashboard.humidity.overallAreaAverage')}
          value={data?.value ?? 0}
        />
        <Divider />
      </Stack>
      <Divider />
      <Stack mt={2} mb={2}>
        <Typography variant='subtitle1'>
          {`${t('dashboard.humidity.totalSensor')}: `}
          <strong>{sensors?.total || 0}</strong>
        </Typography>
      </Stack>
      <Stack spacing={1}>
        {sensors?.items?.map((item: any, index: number) => (
          <LinearHumidity
            key={index}
            label={item.sensorName}
            value={item.lastValue || 0}
          />
        ))}
      </Stack>
    </Modal>
  )
}
