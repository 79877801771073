import { IconNamesProps } from 'components/Icon'

const ICONS = {
  NEW: 'circle' as IconNamesProps,
  FINISHED: 'check' as IconNamesProps,
  IN_PROGRESS: 'timelapse' as IconNamesProps,
  REFUSED: 'warning' as IconNamesProps,
  NOT_SOLVED: 'close' as IconNamesProps,
  NOT_ACCEPTED: 'warning' as IconNamesProps,
  ACCEPTED: 'circle' as IconNamesProps,
}

const COLORS = {
  NEW: '#0165B1',
  FINISHED: '#3EB46D',
  IN_PROGRESS: '#F19A4C',
  REFUSED: '#EE5DAC',
  NOT_SOLVED: '#DE4D53',
  NOT_ACCEPTED: '#FF4D4D',
  ACCEPTED: '#21b0fe',
}

export const STATUS_SCHEDULE = {
  ICONS,
  COLORS,
}
