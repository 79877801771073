import { TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'

import { InputSearchMui } from './InputSearchMui'

type Props = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  hiddenIcon?: boolean
}

export function InputSearch({ control, ...rest }: Props) {
  return (
    <>
      {control ? (
        <Controller
          name={rest?.name}
          control={control}
          defaultValue=''
          render={({ field }) => <InputSearchMui {...rest} {...field} />}
        />
      ) : (
        <InputSearchMui {...rest} />
      )}
    </>
  )
}
