import { Grid, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { Node } from 'reactflow'

import { HeaderAction } from 'components/HeaderAction'
import { Loading } from 'components/Loading'
import { Modal } from 'components/Modal'
import { Table } from 'components/Table'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { useEquipmentBtnPanic } from 'pages/btnPanicAlert/hooks/useEquipmentBtnPanic'
import { useMapStore } from 'store/map.store'
import { AddElementType } from 'types/area/create-linked-area'
import { EquipmentType } from 'types/equipment/equipment'
import { ModalType } from 'types/modal'

type NodesType = { nodes: Node<any, string | undefined>[] }

interface Props extends ModalType {
  onClick: (data: AddElementType) => void
  nodes: Node<any, string | undefined>[]
  handleSave?: (nodes: NodesType) => void
  data?: any
}

export const ModalAddElement = ({
  open,
  onClose,
  onClick,
  handleSave,
  nodes,
  ...props
}: Props) => {
  const {
    columns,
    order,
    setOrder,
    setOrderBy,
    orderBy,
    page,
    sensorBtnPanic,
    setPage,
    setSearch,
    error,
    isLoading,
    isFetching,
    refetch,
    data,
  } = useEquipmentBtnPanic()
  const { currentMap } = useMapStore()
  const { sweetAlert } = useSweetAlert()
  const QueryClient = useQueryClient()

  const dataSubarea = props?.data.data

  const handleAddNode = (data: AddElementType) => {
    onClick(data)
    handleSave?.({ nodes })
    onClose()
  }

  const handleClick = (row: any) => {
    sweetAlert({
      title: 'Registrar',
      text: 'Você selecionou um equipamento para vincular à uma sub área escolhida no início do cadastro. Deseja salvar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      onSuccess: () => {
        handleAddNode({
          type: 'equipment',
          equipment: data?.items?.find((item: any) => item.id === row.id),
        })
      },
    })
  }
  useEffect(() => {
    QueryClient.invalidateQueries('equipment-list-btn')
  }, [open])

  if (isLoading || isFetching) {
    return <Loading />
  }

  return (
    <>
      <Modal
        title={'Pesquise e selecione um botão de pânico'}
        open={open}
        onClose={onClose}
        maxWidth='xl'
        sx={{
          zIndex: 4,
        }}
      >
        <Stack direction='row' justifyContent='space-between' mb={2}>
          <Grid
            sx={{
              background: (theme) => theme.palette.primary.dark,
              px: 1.5,
              borderRadius: 1,
              font: 'normal normal normal 12px/20px Noto Sans',
            }}
          >
            {'BOTÃO DE PÂNICO'}
          </Grid>
          <Grid
            sx={{
              background: (theme) => theme.palette.background.paper,
              px: 1.5,
              borderRadius: 1,
              font: 'normal normal normal 12px/20px Noto Sans',
            }}
          >
            {`${currentMap?.nameArea.toUpperCase()} - ${dataSubarea.nomeSubArea.toUpperCase()}`}
          </Grid>
        </Stack>
        <HeaderAction onSearch={(search) => setSearch(search)} sx={{ mb: 2 }} />

        <Table<EquipmentType>
          columns={columns}
          orderBy={orderBy}
          order={order}
          onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
          onOrderBy={(newOrderBy) => {
            if (newOrderBy !== 'moduleMapper' && newOrderBy !== 'usersMapper')
              setOrderBy(newOrderBy)
          }}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          pagination={{
            page,
            limit: data?.limit,
            count: data?.total,
            onChangePage: setPage,
          }}
        >
          {sensorBtnPanic && sensorBtnPanic.length ? (
            sensorBtnPanic?.map((row: any) => {
              return (
                <TableRow
                  hover
                  key={row.id.value}
                  onClick={() => {
                    handleClick(row)
                  }}
                >
                  <TableCell sx={{ border: 'none' }}>{row.createdAt}</TableCell>
                  <TableCell sx={{ border: 'none' }}>{row.file}</TableCell>
                  <TableCell sx={{ border: 'none' }}>{row.equipment}</TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    {row.typeEquipment}
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>{row.code}</TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    {row.manufacturer}
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>{row.statusMui}</TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} sx={{ border: 'none' }}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontStyle: 'italic',
                    color: (theme) => theme.palette.text.disabled,
                  }}
                >
                  {'Sem dados cadastrados'}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </Modal>
    </>
  )
}
