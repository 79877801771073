import { ZoomControl } from 'react-leaflet'

interface IMapZoomControlProps {
  position?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright'
}

export const MapZoomControl = ({
  position = 'topleft',
}: IMapZoomControlProps) => {
  return (
    <>
      <ZoomControl position={position} />
    </>
  )
}
