/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { Actions } from 'components/Actions'
import { HeaderAction } from 'components/HeaderAction'
import { Table, TableActionType } from 'components/Table'
import { Toggle } from 'components/Toggle'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { shiftMapper } from 'pages/map/mappers/shift.mapper'
import { shiftService } from 'services/shift.service'
import { PaginationType } from 'types/pagination'
import { ShiftType } from 'types/shift/shift'
import { ColumnType } from 'types/table/column'

import { FormShiftModal } from './components/FormShiftModal'

export function ShiftPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<ShiftType | null>(null)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [orderBy, setOrderBy] = useState('name')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<ShiftType>,
    Error
  >({
    queryKey: ['shifts', page, search, orderBy, order],
    queryFn: () =>
      shiftService.findAll({
        page,
        search,
        columnName: orderBy,
        columnSort: order,
        numberPerPage: 10,
      }),
  })

  const measuringAreas = shiftMapper(data?.results ?? [])

  const onClearSelected = () => setSelected(null)

  const handleOpenCreate = () => setOpenForm(true)

  const handleCancelForm = () => {
    setOpenForm(false)
    setOpenFormView(false)
    onClearSelected()
  }

  const handleEdit = (item: ShiftType) => {
    setSelected(item)
    handleOpenCreate()
  }

  const handleView = (item: ShiftType) => {
    setSelected(item)
    setOpenFormView(true)
    setOpenForm(true)
  }

  const callback = () => {
    toast.success(t('savedInformation'))
    refetch()
  }

  const mutationDelete = useMutation((id: number) =>
    httpDelete(`/shift/${id}`, callback),
  )

  const handleDelete = async (item: ShiftType) => {
    toast.promise(mutationDelete.mutateAsync(Number(item.entityId?.value)), {
      pending: t('deleting'),
    })
  }

  const handleUpdateStatus = async (item: ShiftType) => {
    try {
      await shiftService.updateStatus(Number(item.entityId?.value))
      refetch()
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  const columns: ColumnType[] = [
    {
      id: 'name',
      label: t('table.shift.name'),
    },
    {
      id: 'weekDays',
      label: t('table.shift.weekDays'),
    },
    {
      id: 'muiStatus',
      label: t('table.shift.status'),
    },
  ]

  const actions: TableActionType<ShiftType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
    },
    {
      type: 'delete',
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <>
      <HeaderAction
        onCreate={handleOpenCreate}
        onSearch={(search) => setSearch(search)}
      />

      <Table<ShiftType>
        columns={columns}
        order={order}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          if (newOrderBy === 'weekDays') {
            setOrderBy('name')
            return
          }
          if (newOrderBy === 'muiStatus') {
            setOrderBy('status')
            return
          }
          setOrderBy(newOrderBy)
        }}
        onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={actions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      >
        {!!measuringAreas?.length &&
          measuringAreas?.map((row, rowIndex) => {
            return (
              <TableRow hover key={rowIndex}>
                <TableCell sx={{ border: 'none' }}>{row.name}</TableCell>
                <TableCell sx={{ border: 'none' }}>
                  {row?.days?.length
                    ? row.days
                        .map((day) => t(String(day.name)))
                        ?.toString()
                        ?.replaceAll(',', ', ')
                    : '-'}
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                  <Toggle
                    name='toggle'
                    checked={row.status}
                    onChange={() => handleUpdateStatus(row)}
                  />
                </TableCell>
                {!!actions?.length && (
                  <TableCell align='right' sx={{ border: 'none' }}>
                    <Actions
                      options={actions?.map((item) => ({
                        type: item?.type ?? 'add',
                        disabled: item?.disabled,
                        router: item?.router,
                        title: item?.title,
                        action: item?.onClick
                          ? () => item.onClick?.(row)
                          : undefined,
                      }))}
                    />
                  </TableCell>
                )}
              </TableRow>
            )
          })}
      </Table>

      {openForm && (
        <FormShiftModal
          data={selected}
          isView={openFormView}
          open={openForm}
          onClose={handleCancelForm}
        />
      )}
    </>
  )
}
