import { Grid } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DropzoneFileUploading } from 'components/Dropzone/FileUploading'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { AreaService } from 'services/area/area.service'
import { branchService } from 'services/branch.service'
import { CreateMapAreaType } from 'types/map/create-map'
import { ModalType } from 'types/modal'

interface Props extends ModalType {
  data?: null | any
  onSuccess?: () => void
  companyId?: string
}

export function MapForm({ onClose, open, data, companyId }: Props) {
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const dataArea = data

  const { data: listBranches } = useQuery({
    queryKey: ['list-branches'],
    queryFn: () => branchService.findListBranchesSelect(),
  })

  const queryClient = useQueryClient()
  const { t } = useTranslation('common')
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateMapAreaType>({
    defaultValues: {
      areaName: data?.nameArea ?? '',
      file: null,
      companyId: companyId ? companyId : '',
    },
  })

  const title = 'Nova área'

  function onSetSuccessfully() {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('map-image-all')
    queryClient.invalidateQueries('company-map')
    onClose()
  }

  const mutationCreate = useMutation(
    (data: CreateMapAreaType) => {
      return AreaService.createAreaBtn(data)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const mutationUpdate = useMutation(
    (data: CreateMapAreaType) => {
      const dataUpdate = {
        ...data,
        mapImageId: dataArea?.mapImage?.entityId.value,
      }

      return AreaService.updateArea(dataArea?.entityId?.value, dataUpdate)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  function onSubmit(formData: CreateMapAreaType) {
    const sendData = !dataArea?.entityId?.value
      ? mutationCreate
      : mutationUpdate

    sendData.mutate(formData)
  }

  function handleUploadFile(file: File) {
    setValue('file', file)
  }

  return (
    <Modal title={title} open={open} onClose={onClose}>
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        {!companyId && (
          <Grid item xs={12}>
            <Select
              name='companyId'
              label='Unidade'
              placeholder='Escolha uma unidade'
              value={watch('companyId')}
              options={
                listBranches?.map((branch) => ({
                  id: branch.id.value,
                  name: branch.name,
                })) ?? []
              }
              control={control}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Input
            control={control}
            name='areaName'
            label='Nome da área'
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <DropzoneFileUploading
            progress={progress}
            isUploading={isUploading}
            onChangeFile={(file) => handleUploadFile(file)}
          />
        </Grid>

        {open && (
          <Grid item xs={12}>
            <ModalAction
              onCancel={onClose}
              isSubmit
              isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
