import { profilesMock } from 'mocks/profiles.mock'

import { LIMIT_PER_PAGE } from 'constants/pagination'
import { httpClient } from 'services/api/httpClient'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'
import { ProfileType } from 'types/profile/profile'

type NewType = PaginationType<ProfileType>

export const listProfiles = async ({
  page = 1,
  search = '',
  columnSort,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  const response = await httpClient.get('/profile-all')
  const { total } = response.data
  const results = response.data.items.filter((item: any) =>
    item.name.toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = LIMIT_PER_PAGE
  return {
    items: results.slice(offset * limit, offset * limit + limit),
    currentPage: page,
    perPage: 10,
    total: results.length,
  }
  const { data } = await httpClient.get<PaginationResponseType>(
    search?.length ? '/profiles/findcustom' : 'profile-all',
    {
      params: {
        page: page - 1,
        columnSort,
        numberPerPage,
        ...(search?.length ? { filter: search } : {}),
      },
    },
  )

  return {
    items: data?.items ?? [],
    currentPage: data?.currentPage,
    perPage: data?.perPage,
    total: total,
  }
}
