import { Map } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { LegacyRef, ReactNode } from 'react'
import { MapContainer, MapContainerProps } from 'react-leaflet'

interface Props extends MapContainerProps {
  children: ReactNode
  ref?: LegacyRef<Map> | undefined
}

export const MapRoot = (props: Props) => {
  return <MapContainer {...props} />
}
