import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Table, TableActionType } from 'components/Table'
import { TabPanel, TabPanelProps } from 'components/Tabs/TabPanel'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { invoiceContractMapper } from 'pages/setting/mappers/invoice-contract.mapper'
import { listInvoiceContract } from 'services/invoice/contract/listInvoiceContract'
import { InvoiceContractType } from 'types/invoice/invoice-contract'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { FormInvoiceContractModal } from '../FormInvoiceContractModal'

type DataType = InvoiceContractType

type Props = Omit<TabPanelProps, 'children'> & {
  search?: string
  openForm: boolean
  onCloseForm: () => void
}

export function InvoiceContract(props: Props) {
  const { search, openForm, onCloseForm, ...rest } = props
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openFormEdit, setOpenFormEdit] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<DataType | null>(null)

  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('referent')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<DataType>,
    Error
  >({
    queryKey: ['invoice-contract', page, search, orderBy],
    queryFn: () => listInvoiceContract({ page, search, columnSort: orderBy }),
    refetchOnWindowFocus: false,
  })

  const invoiceContracts = invoiceContractMapper(data?.items ?? [])

  const handleCancel = () => {
    onCloseForm()
    setOpenFormView(false)
    setSelected(null)
    setOpenFormEdit(false)
  }

  const handleEdit = (item: DataType) => {
    setSelected(item)
    setOpenFormEdit(true)
  }

  const handleView = (item: DataType) => {
    setSelected(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: DataType) => {
    httpDelete(`/contract/${item.id}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'regarding',
      label: t('table.invoice.contract.referent'),
    },
    {
      id: 'formattedTotalPayable',
      label: t('table.invoice.contract.totalPayable'),
    },
    {
      id: 'formattedMonth',
      label: t('table.invoice.contract.monthAndYear'),
    },
    {
      id: 'formattedExpiration',
      label: t('table.invoice.contract.expiration'),
    },
    {
      id: 'company',
      label: t('table.invoice.contract.company'),
    },
    {
      id: 'responsible',
      label: t('table.invoice.contract.responsible'),
    },
    {
      id: 'contact',
      label: t('table.invoice.contract.contact'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
  ]

  const tableActions: TableActionType<DataType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_INVOICE,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_INVOICE,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_INVOICE,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <TabPanel {...rest}>
      <Table<DataType>
        columns={columns}
        rows={invoiceContracts}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView || openFormEdit) && (
        <FormInvoiceContractModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView || openFormEdit}
          onClose={handleCancel}
        />
      )}
    </TabPanel>
  )
}
