export function DashboardTemperaturaInternoExternoContent() {
  return (
    <>
      <p>
        Nesse gráfico é mostrada a temperatura interna da fábrica em comparação
        com a temperatura externa
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        temperatura, caso esse gráfico não esteja disponibilizando o valor, será
        necessário realizar o cadastro dos sensores em Configurações {'->'}{' '}
        Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
