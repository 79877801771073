import { ActiveVariableEnum } from 'core/enums/ActiveVariableEnum'
import { useState } from 'react'

export const useMonitoringSidebar = () => {
  const SIDEBAR_SIZE_SMALL = 40
  const SIDEBAR_SIZE_LARGE = 280

  const [sidebarVisibility, setSidebarVisibility] = useState(true)
  const [activeVariable, setActiveVariable] = useState<ActiveVariableEnum>(
    ActiveVariableEnum.EQUIPMENT,
  )

  const checkActiveVariable = (value: ActiveVariableEnum) => {
    return value === activeVariable
  }

  const toggleActiveVariable = () => {
    setActiveVariable((value) => {
      const { EQUIPMENT, AIR_CONDITIONING } = ActiveVariableEnum
      return value === EQUIPMENT ? AIR_CONDITIONING : EQUIPMENT
    })
  }

  const toggleSidebarVisibility = () => {
    setSidebarVisibility(!sidebarVisibility)
  }

  return {
    sidebar: {
      SIDEBAR_SIZE_SMALL,
      SIDEBAR_SIZE_LARGE,
      sidebarVisibility,
      toggleSidebarVisibility,
    },
    variable: {
      activeVariable,
      checkActiveVariable,
      toggleActiveVariable,
    },
  }
}
