import { LeafletMouseEvent } from 'leaflet'
import { TileLayer, TileLayerProps } from 'react-leaflet'

interface MapLayerProps extends Omit<TileLayerProps, 'url'> {
  url?: string
  onClickMap?: (event: LeafletMouseEvent) => void
}

export const MapLayer = (props: MapLayerProps) => {
  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        {...props}
      />
    </>
  )
}
