export function DashboardGasQualidadeArContent() {
  return (
    <>
      <p>
        Nesse gráfico são informados os tipos de gazes que estão sendo liberados
        no setor ou área que foi selecionada. Nesse gráfico pode ser filtrado
        por semana, mês e ano selecionando o período.
      </p>
      <p>Para selecionar a área ou setor, basta:</p>
      <p>
        <strong>Passo 1:</strong> Selecione o filtro de setores no filtro da
        direita
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        gás, caso esse gráfico não esteja disponibilizando o valor, será
        necessário realizar o cadastro dos sensores em Configurações {'->'}{' '}
        Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
      <p>
        Os dados relacionados a setores devem ser cadastrados em Mapa {'->'}{' '}
        Áreas, selecione Mapa nesse tutorial para saber mais.
      </p>
    </>
  )
}
