import { IconButton } from 'components/Button/IconButton'

export function AreaMedicaoContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros dos setores e áreas de
        medição da empresa. Essa informação será importante para identificar
        onde ficarão cada sensor dentro de fábrica, facilitando em caso de
        manutenção ou identificação de locais com dados investigativos. Ele será
        utilizado no Montar Mapa (Módulo de Mapas {'->'} Montar Mapa) e
        Monitoramento de sensores (Módulo de Mapa {'->'} Monitoramento de
        Sensores), então sugerimos que antes de realizar o Montar Mapa, sempre
        verifique se as áreas de medição já estejam cadastradas. Caso esse
        submódulo não apareça no seu sistema atualmente, provavelmente o seu
        tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>Cadastrar Área</p>

      <p>Para realizar o cadastro de uma nova área, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Mapa
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Áreas de Medição
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os campos que são: setor, turno e
        escrever a área e selecionar{' '}
        <IconButton iconName='check' variant='text' color='inherit' />, podendo
        colocar várias áreas em um setor.{' '}
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        Importante: Essa informação é importante para identificar o local em que
        os sensores e equipamentos estão dentro de fábrica, também para a
        Manutenção.{' '}
      </p>
      <p>
        Para excluir uma área, no momento do cadastro de uma, sem ainda apertar
        em salvar, aparece uma lixeira{' '}
        <IconButton iconName='delete' variant='text' color='inherit' /> onde
        pode excluir antes de salvar o cadastro inteiro.
      </p>
      <p>
        Para realizar o cadastro de uma área, é importante já ter realizado o
        cadastro de turnos
      </p>
      <p>Editar Área</p>

      <p>Para realizar a edição de uma área, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Mapa
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Áreas de Medição
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a área que vai editar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos que aparecerão: setor, turno e
        escrever a área e selecionar{' '}
        <IconButton iconName='check' variant='text' color='inherit' />, podendo
        colocar várias áreas em um setor.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar.{' '}
      </p>
      <p>Excluir Área</p>

      <p>Para realizar a exclusão de uma área, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Mapa
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Área de Medição
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a área que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a área será permanentemente excluído do
        sistema.
      </p>
      <p>Visualizar Área</p>

      <p>Para realizar visualizar os dados de uma área, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Área de Medição
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a área que vai visualizar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro. Esses dados serão mostrados sem poder realizar edição
        neles.
      </p>
    </>
  )
}
