import * as yup from 'yup'
import { ModeOption } from 'types/sensor/sensor'

// Extend the StringSchema interface to include the ipv4 method
declare module 'yup' {
  interface StringSchema {
    ipv4(message?: string): StringSchema
    port(message?: string): StringSchema
  }
}

export const schemaCreateSensor = () => {
  function ipv4(this: yup.StringSchema, message = 'Invalid IP address') {
    return this.matches(/^(\d{1,3}\.){3}(\d{1,3})$/, {
      message,
      excludeEmptyString: true,
    }).test('ip', message, (value) => {
      return value === undefined || value === null || value.trim() === ''
        ? true
        : value.split('.').every((i) => parseInt(i, 10) <= 255)
    })
  }
  function port(this: yup.StringSchema, message = 'Invalid port') {
    return this.when('$portNotNull', {
      is: true,
      then: this.matches(/^[0-9]+$/, message)
        .max(5)
        .required(),
      otherwise: this.nullable(true),
    })
  }

  yup.addMethod(yup.string, 'ipv4', ipv4)
  yup.addMethod(yup.string, 'port', port)

  return yup.object({
    name: yup.string().max(50).required(),
    port: yup.string().when('mode', {
      is: ModeOption.DATALOG,
      then: yup.string().port().required(),
      otherwise: yup.string().nullable(true).default(''),
    }),
    dataUnifier: yup.string().required(),
    ip: yup.string().when('mode', {
      is: ModeOption.DATALOG,
      then: yup.string().ipv4().required(),
      otherwise: yup.string().ipv4().nullable(true).default(''),
    }),
    unit: yup.string().when('mode', {
      is: ModeOption.CONCENTRATOR,
      then: yup.string().required(),
    }),
    address: yup.string().max(50).required(),
    type: yup.string().required(),
    time: yup.string().required().max(999999).min(0).matches(/^[0-9]+$/, 'Time cannot be a negative number'),
  })
}
