export function DashboardWaterPressureContent() {
  return (
    <>
      <p>
        Nesse gráfico é possível verificar a vasão do uso de água por setores em
        tempo real, conforme a utilização de água do setor, a cor do local fica
        maior no gráfico. Esse gráfico por ser em tempo real, não te a opção de
        verificação por data
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        vazão, caso esse gráfico não esteja disponibilizando o valor, será
        necessário realizar o cadastro dos sensores em Configurações {'->'}{' '}
        Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
