import { Box, FormControlLabel } from '@mui/material'
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'
import { Controller } from 'react-hook-form'

type Props = CheckboxProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name?: string
  label?: any
}

export function Checkbox({ control, name, label, ...rest }: Props) {
  return (
    <Box>
      {control && name ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <MuiCheckbox checked={field.value} {...rest} {...field} />
              }
              label={label}
            />
          )}
        />
      ) : (
        <MuiCheckbox {...rest} />
      )}
    </Box>
  )
}
