import { styled } from '@mui/material/styles'
import Tab, { TabTypeMap } from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

export const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #0C0C2580',
  '& .MuiTabs-indicator': {
    backgroundColor: '#66009D',
  },
  '&.MuiTabs-root, & .MuiButtonBase-root': {
    minHeight: 0,
    height: 36,
  },
})

export const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'uppercase',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(2),
  borderRadius: '4px',
  color: 'inherit',
  paddingTop: '0px',
  paddingBottom: '0px',
  fontSize: '0.75em',
  borderRight: '1px solid #27272780',
  '&:hover': {
    opacity: 1,
    background: '#1D1E42',
    color: '#F4F5F7',
  },
  '&.Mui-selected': {
    color: '#F4F5F7',
    fontWeight: theme.typography.fontWeightMedium,
    background: '#1D1E42',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#1D1E42',
  },
  '&:disabled': {
    background: '#666666',
    color: '#A6ABB2',
  },
}))

type StyledTabProps = TabTypeMap['props'] & {
  label: string
  disabled?: boolean
}

export const buttonColors = ['#455DDC', '#F9D47F', '#43D1A7']
