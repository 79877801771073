import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useCustomTheme } from '../../hooks/useCustomTheme'
import { IconButton } from '../Button/IconButton'

export function ToggleTheme() {
  const { t } = useTranslation('common')
  const { onThemeDark, onThemeLight, mode } = useCustomTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 1,
        borderRadius: '6px',
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <IconButton
        title={t('theme.dark') ?? ''}
        iconName='darkMode'
        variant={mode === 'dark' ? 'contained' : 'text'}
        onClick={onThemeDark}
      />
      <IconButton
        title={t('theme.light') ?? ''}
        iconName='brightness7'
        onClick={onThemeLight}
        variant={mode === 'light' ? 'contained' : 'text'}
      />
    </Box>
  )
}
