import styled from '@emotion/styled'

export const ActionItemAirConditioning = styled.div<{
  disabled?: boolean
}>(
  {
    width: 94,
    height: 52,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#141432',
  },
  ({ disabled }) => ({
    ...(disabled && {
      pointerEvents: 'none',
    }),
  }),
)
