/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { DatePicker } from 'components/DatePicker'
import { Input } from 'components/Input'
import { InputFile } from 'components/Input/InputFile'
import { InputPrice } from 'components/Input/InputPrice'
import { MaskedInputPhoneNumber } from 'components/Input/mask/MaskedInputPhoneNumber'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { priceConvertStringToNumber } from 'helpers/priceConvertStringToNumber'
import { createInvoiceContract } from 'services/invoice/contract/createInvoiceContract'
import { updateInvoiceContract } from 'services/invoice/contract/updateInvoiceContract'
import { CreateInvoiceContractType } from 'types/invoice/create-invoice-contract'
import { InvoiceContractType } from 'types/invoice/invoice-contract'
import { ModalType } from 'types/modal'

interface Props extends ModalType {
  data: InvoiceContractType | null
  isView?: boolean
}

export function FormInvoiceContractModal({
  data,
  isView,
  onClose,
  open,
}: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateInvoiceContractType>({
    // resolver: yupResolver(schemaCreateInvoiceMercadoLivre),
    defaultValues: {
      date: data?.date ?? null,
      expiration: data?.expiration ?? null,
      company: data?.company ?? '',
      contact: data?.contact ?? '',
      regarding: data?.regarding ?? '',
      responsible: data?.responsible ?? '',
      serviceTime: data?.serviceTime ?? '',
      totalPayable: data?.totalPayable ?? '',
      pathImage: data?.pathImage ?? null,
      status: data?.status ?? true,
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('invoice-contract')
    onClose()
  }

  const mutationCreate = useMutation(
    (data: CreateInvoiceContractType) => {
      data.totalPayable = priceConvertStringToNumber(data.totalPayable)
      return createInvoiceContract(data)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: CreateInvoiceContractType) => {
      update.totalPayable = priceConvertStringToNumber(update.totalPayable)
      return updateInvoiceContract(Number(data?.id) ?? 0, update)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateInvoiceContractType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('invoice.contract.view')

    if (data?.id) return t('invoice.contract.edit')

    return t('invoice.contract.new')
  }

  return (
    <>
      <Modal title={getTitle()} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12} sm={6}>
            <Input
              name='regarding'
              label={t('input.referent') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.regarding}
              errorMessage={errors?.regarding?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='totalPayable'
              label={t('input.totalPayable') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.totalPayable}
              errorMessage={errors?.totalPayable?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='date'
              control={control}
              views={['year', 'month']}
              inputFormat='MMMM YYYY'
              disableMaskedInput
              label={t('input.dateAndYear') ?? ''}
              error={!!errors?.date}
              errorMessage={errors?.date?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='expiration'
              control={control}
              inputFormat='DD/MM/YYYY'
              disableMaskedInput
              label={t('input.expiration') ?? ''}
              error={!!errors?.expiration}
              errorMessage={errors?.expiration?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name='company'
              label={t('input.company') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.company}
              errorMessage={errors?.company?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='responsible'
              label={t('input.responsible') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.responsible}
              errorMessage={errors?.responsible?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='contact'
              label={t('input.contact') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.contact}
              errorMessage={errors?.contact?.message}
              disabled={isView}
              InputProps={{
                inputComponent: MaskedInputPhoneNumber as any,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='serviceTime'
              label={
                t('input.serviceTime', {
                  abbreviation: '(SLA)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.serviceTime}
              errorMessage={errors?.serviceTime?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputFile onChange={(file) => setValue('pathImage', file)} />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
