import { httpClient } from 'services/api/httpClient'
import { AirConditioningSchedulingType } from 'types/air-conditioning-scheduling/air-conditioning-scheduling'
import { CreateAirConditioningSchedulingType } from 'types/air-conditioning-scheduling/create-air-conditioning-scheduling'

const baseUrl = 'air-conditioning-scheduling'

const updateOrCreate = (data: CreateAirConditioningSchedulingType, id = '') => {
  return httpClient({
    data,
    method: id ? 'PUT' : 'POST',
    url: baseUrl.concat(`${id ? `/${id}` : ''}`),
  })
}

const findByLinkedArea = (linkedAreaId: string) => {
  return httpClient<AirConditioningSchedulingType[]>({
    url: `${baseUrl}/linked-area`,
    method: 'GET',
    params: {
      linkedAreaId,
    },
  }).then((response) => response.data)
}

export const AirConditioningSchedulingService = {
  updateOrCreate,
  findByLinkedArea,
}
