import { styled, Typography, TypographyProps } from '@mui/material'

interface TextProps {
  size?: number
}

export const Text = styled(Typography)<TextProps & TypographyProps>(
  ({ size, theme }) => ({
    fontSize: size ?? 'inherit',
    letterSpacing: '0.42px',
    lineHeight: '2.25',

    '& .MuiSvgIcon-root': {
      fontSize: 18,
      verticalAlign: 'middle',
      marginRight: 2,
      color: theme.palette.primary.light,
    },
  }),
)
