import { httpClient } from 'services/api/httpClient'
import { EquipmentType } from 'types/equipment/equipment'
import { PaginationParamType } from 'types/pagination'

type ResponseType = EquipmentType & {
  id: number
}

type TypeEquipment = {
  types?: string
  feeder?: boolean
}

export const getAllEquipments = async (): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('equipment', {
    params: {
      page: 0,
      numberPerPage: 1000,
    },
  })

  return (
    data?.items?.map((item: EquipmentType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}

export const getAllEquipmentsByType = async ({
  types,
  feeder,
  page,
  numberPerPage,
  columnName,
  columnSort,
  search,
}: TypeEquipment & PaginationParamType): Promise<ResponseType[]> => {
  const newParams: {
    page: number
    name?: string
    types?: string
    columnSort?: string
    numberPerPage?: number
    columnName?: string
    isAvailable?: boolean
  } & TypeEquipment = {
    page: page - 1,
    numberPerPage: numberPerPage ?? 10,
    columnName,
    columnSort,
    name: search,
    isAvailable: true,
  }

  if (types) newParams.types = types

  if (feeder !== undefined) newParams.feeder = feeder

  const { data } = await httpClient.get('/equipment/findcustom', {
    params: newParams,
  })

  return data
}
