import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Node } from 'reactflow'

import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { subareaType } from 'constants/subarea'
import { shiftService } from 'services/shift.service'
import { subAreaService } from 'services/subarea/subArea.service'
import { useMapSubarea } from 'store/map-subarea.store'
import { ModalType } from 'types/modal'
import { CreateSubarea } from 'types/subarea/subarea'

interface Props extends ModalType {
  data?: null | any
  onSuccess?: () => void
  cancelNewSubarea?: () => void
  nodes?: Node[]
}

type IForm = {
  subArea: string
  shift: string
  color: string
}

const colors = [
  { id: 1, color: '#F25783' },
  { i: 2, color: '#FF8DBB' },
  { id: 3, color: '#9A47F6' },
  { id: 4, color: '#5B20CE' },
  { id: 5, color: '#455DDC' },
  { id: 6, color: '#456799' },
  { id: 7, color: '#99D98C' },
  { id: 8, color: '#43D1A7' },
  { id: 9, color: '#F9D47F' },
  { id: 10, color: '#FFB469' },
]

export function FormSubAreaModal({
  onClose,
  cancelNewSubarea,
  open,
  nodes = [],
  ...props
}: Props) {
  const queryClient = useQueryClient()
  const { currentSubarea } = useMapSubarea()
  const { t } = useTranslation('common')
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = React.useState(false)

  const mapId = searchParams.get('mapId')

  const { control, handleSubmit, reset, setValue, getValues, watch } =
    useForm<IForm>({
      defaultValues: {
        subArea: props.data?.nomeSubArea ?? '',
        shift: props.data?.shiftID.value ?? '',
        color: props.data?.color ?? '',
      },
    })

  const { data: shifts } = useQuery({
    queryKey: ['shifts-list'],
    queryFn: () => shiftService.list(),
    select: (data) =>
      data?.map((item) => ({
        id: Number(item.entityId?.value),
        name: item.name,
      })) ?? [],
    staleTime: 1 * (60 * 1000), // 1 min
    cacheTime: 1 * (60 * 1000), // 1 min
  })

  const isEditing = Boolean(props.data?.areaID.value)

  const title = isEditing ? t('map.subArea.edit') : t('map.subArea.new')

  const updateOrCreate = () => {
    const values = getValues()
    const currentNode = nodes.find((node) => node.id === currentSubarea?.id)

    const dataSubArea: CreateSubarea = {
      idMapImageCompany: Number(mapId),
      subArea: {
        name: values.subArea,
        idShift: Number(values.shift),
        color: values.color,
        width: Number(currentNode?.style?.width) ?? 50,
        height: Number(currentNode?.style?.height) ?? 35,
        idMap: currentNode?.id ?? '',
        coordinateX: currentNode?.position.x ?? 0,
        coordinateY: currentNode?.position.y ?? 0,
        mapType: subareaType.SENSOR_PLANT,
      },
    }

    if (isEditing) {
      return subAreaService.updateSubarea(props.data?.areaID?.value, {
        name: values.subArea,
        idShift: Number(values.shift),
        color: values.color,
        width: props.data.width ?? Number(currentNode?.style?.width) ?? 50,
        height: props.data.height ?? Number(currentNode?.style?.height) ?? 30,
        idMap: props.data.idMap ?? currentNode?.id ?? '',
        coordinateX: props.data.coordinateX ?? currentNode?.position.x ?? 0,
        coordinateY: props.data.coordinateY ?? currentNode?.position.y ?? 0,
      })
    }

    return subAreaService.create(dataSubArea)
  }

  function onSubmit() {
    setLoading(true)
    updateOrCreate()
      .then(() => {
        onClose()
        reset()
        queryClient.invalidateQueries('subareas')
        queryClient.invalidateQueries('list-branches')
        queryClient.invalidateQueries('company-map')
        toast.success(t('map.subArea.message.success'))
        if (cancelNewSubarea) cancelNewSubarea()
      })
      .catch(() => {
        toast.error(t('map.subArea.message.error'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (shifts?.length === 0) toast.info(t('shift.message.info'))

  return (
    <Modal title={title} open={open} onClose={onClose}>
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12}>
          <Input
            name='subArea'
            label={t('map.subArea.label')}
            control={control}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            name='shift'
            label={t('shift.label')}
            placeholder={t('shift.label').toString()}
            options={shifts ?? []}
            control={control}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Defina uma cor</Typography>
          <Typography sx={{ mb: 2 }}>
            Selecione uma das cores abaixo para ajudar na organização visual de
            suas sub áreas no mapa principal.
          </Typography>

          <Stack direction='row'>
            {colors.map((cor, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    bgcolor: cor.color,
                    width: 24,
                    height: 24,
                    mr: 2,
                    borderRadius: 2,
                    cursor: 'pointer',
                    outline: '2px solid',
                    outlineColor:
                      cor.color === watch('color')
                        ? (theme) => theme.palette.text.primary
                        : 'transparent',
                  }}
                  onClick={() => {
                    setValue('color', cor.color)
                  }}
                />
              </React.Fragment>
            ))}
          </Stack>
        </Grid>

        {open && (
          <Grid item xs={12}>
            <ModalAction onCancel={onClose} isSubmit isLoading={loading} />
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
