import { Stack, Typography } from '@mui/material'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'

type Props = {
  label: string
  value: number
}
export function LinearHumidity({ label, value }: Props) {
  return (
    <Stack width='100%' spacing={1} flex={1}>
      <Stack direction={'row'} spacing={1}>
        <Typography variant='subtitle1' component='div' color='text.secondary'>
          {label}
        </Typography>

        <Typography variant='subtitle1' component='div' color='text.secondary'>
          {value}%
        </Typography>
      </Stack>

      <Stack direction='column'>
        <LinearProgress
          variant='determinate'
          value={value}
          color='inherit'
          sx={{
            color: '#4A4B67',
            left: 0,
            borderRadius: 8,
            height: 16,
            '& .MuiLinearProgress-bar1Determinate': {
              backgroundColor: value > 50 ? '#DE4D53' : '#81FFB5',
              borderRadius: 8,
            },
          }}
        />
      </Stack>
    </Stack>
  )
}
