import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Button/IconButton'
import { IconNamesProps } from 'components/Icon'
import { SensorIconType } from 'components/Sensor'
import { SensorIconButton } from 'components/Sensor/SensorIconButton'

interface Props {
  icon: SensorIconType
  active?: string
  onClick?: (type: SensorIconType) => void
}

export function DashboardButton(props: Props) {
  const { active, icon, onClick } = props
  const { t } = useTranslation('common')
  const sensorNames = {
    TEMPERATURE_HUMIDITY: t('sensor.temperatureAndHumidity'),
    TEMPERATURE: t('sensor.temperature'),
    HUMIDITY: t('sensor.humidity'),
    GAS: t('sensor.gas'),
    ENERGY: t('sensor.energy'),
    TEMP_HUM: t('sensor.temperatureAndHumidity'),
    TEMP: t('sensor.temperature'),
    HUM: t('sensor.humidity'),
    AIR: t('sensor.gas'),
    ELECTRIC: t('sensor.energy'),
    ELETRIC: t('sensor.energy'),
    WATER: t('sensor.water'),
    SENSOR: 'Sensor',
    OPENING: t('sensor.opening'),
    BTN_PANIC: t('sensor.alert'),
  }

  return (
    <>
      {active?.toUpperCase() === icon ? (
        <SensorIconButton icon={icon} onClick={() => onClick?.(icon)} />
      ) : (
        <IconButton
          iconName={icon?.toLowerCase() as IconNamesProps}
          variant='outlined'
          color='secondary'
          title={sensorNames[icon]}
          onClick={() => onClick?.(icon)}
        />
      )}
    </>
  )
}
