import { httpClient } from 'services/api/httpClient'
import { QuantityProducedType } from 'types/goalAndParameter/quantity-produced'
import { PaginationParamType, PaginationType } from 'types/pagination'

export const listQuantityProduced = async ({
  page = 1,
  search = '',
  columnSort,
}: PaginationParamType): Promise<PaginationType<QuantityProducedType>> => {
  const response = await httpClient.get('/quantity-produced')
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  const results = items.filter((item: QuantityProducedType) =>
    String(item.quantityProduced).toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
    order: columnSort,
  }
}
