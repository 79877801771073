import axios from 'axios'
import { ViaCepType } from 'types/viacep/viaCepType';
import {viaCepApi } from 'services/api/viaCepApi'

const findCEP = async (cep: string) => {
  const response = await viaCepApi.get(
    `/${cep}/json`,
  )

  return response.data as ViaCepType ?? {}
}

export const viaCEPService = {
    findCEP,
}
