import styled from '@emotion/styled'

export const ConsumptionRangeWrapper = styled.div({
  display: 'flex',
  marginBottom: '0.75rem',
  '& > div:first-of-type': {
    borderRadius: '6px 0 0 6px',
  },
  '& > div:last-child': {
    borderRadius: '0 6px 6px 0',
  },
})

export const ConsumptionRange = styled.div<{
  bgColor: string
  indicator?: boolean
  indicatorPercentage?: number
}>(
  {
    flex: 1,
    padding: 5,
    position: 'relative',
  },
  ({ bgColor, indicatorPercentage = 0, indicator }) => ({
    background: bgColor,
    ...(indicator && {
      '&:before': {
        content: '""',
        display: 'flex',
        height: '1rem',
        width: 2,
        top: '-3px',
        left: `${indicatorPercentage}%`,
        position: 'absolute',
        background: '#fff',
      },
    }),
  }),
)

export const CircleRange = styled.div<{
  borderColor: string
}>(
  {
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  ({ borderColor }) => ({
    border: `2px solid ${borderColor}`,
  }),
)
