import { FlyToLocation } from './FlyToLocation'
import { MapLayer } from './Layer'
import { MapPin } from './Pin'
import { MapPopup } from './Popup'
import { MapRoot } from './Root'
import { MapTooltip } from './Tooltip'
import { MapZoomControl } from './ZoomControl'

export const Map = {
  Root: MapRoot,
  Layer: MapLayer,
  Pin: MapPin,
  FlyToCoords: FlyToLocation,
  ZoomControl: MapZoomControl,
  Popup: MapPopup,
  Tooltip: MapTooltip,
}
