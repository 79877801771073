import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Autocomplete } from 'components/Select/Autocomplete'
import { useWaterTankLevelRealTimeChart } from 'pages/dashboard/hooks/water/useWaterTankLevelRealTimeChart'

interface Props {
  isPreview?: boolean
}

export function WaterTankLevelRealTimeChart(props: Props) {
  const { isPreview } = props
  const { t } = useTranslation('common')

  const { tankLevel, equipments, setSelectEquipment, equipment } =
    useWaterTankLevelRealTimeChart({ isPreview })

  return (
    <Box
      sx={{
        gridArea: 'bottom-left',
        borderRadius: 1,
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        minHeight: 200,
        padding: 2,
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <Stack
        direction={['column', 'row']}
        justifyContent={['space-between']}
        alignItems={'flex-start'}
        marginBottom={3}
      >
        <Typography variant='h2' fontSize='1.125rem' fontWeight='bold'>
          {t('chart.water.waterTankLevel')}
        </Typography>

        {!isPreview && (
          <Stack
            direction={['column', 'row']}
            justifyContent={'flex-end'}
            alignItems={['flex-end', 'center']}
            spacing={2}
            width={['100%', 'auto']}
            marginLeft={'auto'}
          >
            <Stack
              direction={['column', 'row']}
              justifyContent={'flex-end'}
              alignItems={['flex-end', 'center']}
              spacing={2}
              width={['100%', 'auto']}
              flexWrap='wrap'
              gap={2}
            >
              <Box minWidth={200} width={['100%', 'auto']}>
                {!!equipments?.find(
                  (item: any) => item.value === equipment?.value,
                ) && (
                  <Autocomplete
                    name='equipments'
                    placeholder={t('input.selectEquipment')}
                    value={equipments?.find(
                      (item: any) => item.value === equipment?.value,
                    )}
                    options={equipments ?? []}
                    onChange={(value: any) => {
                      setSelectEquipment(value)
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Stack
        flex={1}
        alignItems={'center'}
        justifyContent={'center'}
        paddingTop={isPreview ? 1 : 3}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            width: isPreview ? 160 : 300,
            height: isPreview ? 160 : 300,
          }}
        >
          <CircularProgress
            variant='determinate'
            sx={{
              position: 'absolute',
              left: 0,
              color: '#4a4b68',
            }}
            thickness={4}
            value={100}
            size={isPreview ? 160 : 300}
          />
          <CircularProgress
            variant='determinate'
            value={tankLevel ? 100 : 0}
            size={isPreview ? 160 : 300}
            thickness={4}
            sx={{
              color: '#90daf8',
              position: 'absolute',
              left: 0,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant={isPreview ? 'h1' : 'h2'}
              component='div'
              color='text.primary'
              fontWeight={'bold'}
              fontSize={'1.55rem'}
            >
              {tankLevel ? `${tankLevel?.toFixed(1)} m³` : '--'}
            </Typography>
            <Typography
              variant='caption'
              component='div'
              color='text.secondary'
            >
              {t('realTime')}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}
