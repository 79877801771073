/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import dayjs from 'dayjs'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { getMonthDays } from 'helpers/getMonthDays'
import { getTimesOfDay } from 'helpers/getTimesOfDay'
import {
  TempAndHumDailyTotals,
  TempAndHumDataItem,
  TempAndHumHourlyTotals,
  TempAndHumMonthlyTotals,
  TempAndHumResponseType,
} from 'pages/dashboard/hooks/temperature/@types/temperatureAndHumidity.types'
import { httpClient } from 'services/api/httpClient'
import { getAllAreasBySensorTempHum } from 'services/area/getAllAreas'

interface Props {
  isPreview?: boolean
}

export function useIndoorVSOutdoorAmbientTemperatureChart(props: Props) {
  const { isPreview } = props
  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')
  const [generatingFile, setGeneratingFile] = useState(false)

  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DDT00:00:00') // Obter o primeiro dia
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DDT23:59:59') // Obter o último dia

  const year = dayjs(date).get('year')
  const month = dayjs(date).get('month') + 1

  const [areaId1, setSelectArea1] = useState<string | null>(null)
  const [areaId2, setSelectArea2] = useState<string | null>(null)
  const [areaName1, setAreaName1] = useState<string | null>(null)
  const [areaName2, setAreaName2] = useState<string | null>('--')

  const { data: areas, refetch: refetchArea } = useQuery({
    queryKey: 'all-areas-temperature-humidity',
    queryFn: async () => {
      /* return await getAllAreasBySensorType(SensorTypeEnum.TEMP_HUM) */
      return await getAllAreasBySensorTempHum()
    },
    select: (data) => {
      return data?.map((item) => ({
        label: item.name,
        value: String(item.id.value),
      }))
    },
  })
  const { data: dataIndoor, refetch: refetchIndoor } = useQuery({
    queryKey: [
      'temperature-humidity-indoor-area1',
      startDate,
      endDate,
      areaId1,
    ],
    queryFn: async () => {
      if (!areaId1) return []

      const response = await httpClient.get(
        '/dashboard-temperature-and-humidity/temperature-and-humidity-by-area',
        {
          params: {
            startDate,
            endDate,
            areaId: areaId1 ?? undefined,
          },
        },
      )

      return response.data?.map(
        (item: TempAndHumResponseType & { json: string }) => ({
          ...item,
          json: JSON.parse(item.json),
        }),
      ) as TempAndHumResponseType[]
    },
    enabled: !!areaId1,
  })

  useEffect(() => {
    if (areas?.length) {
      setSelectArea1(areas[0].value)
      setAreaName1(areas[0].label)
    }
  }, [areas])

  const { data: dataOutdoor, refetch: refetchOutdoor } = useQuery({
    queryKey: [
      'temperature-humidity-indoor-area2',
      startDate,
      endDate,
      areaId2,
    ],
    queryFn: async () => {
      if (!areaId2) return []

      const response = await httpClient.get(
        '/dashboard-temperature-and-humidity/temperature-and-humidity-by-area',
        {
          params: {
            startDate,
            endDate,
            areaId: areaId2 ?? undefined,
          },
        },
      )

      return response.data?.map(
        (item: TempAndHumResponseType & { json: string }) => ({
          ...item,
          json: JSON.parse(item.json),
        }),
      ) as TempAndHumResponseType[]
    },
    enabled: !!areaId2,
  })

  useEffect(() => {
    const timeInMinutes = 5 * 60000
    const intervalId = setInterval(() => {
      refetchOutdoor()
      refetchIndoor()
      refetchArea()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  function calculateDailyTotals(
    data: TempAndHumDataItem[],
  ): TempAndHumDailyTotals[] {
    const dailyTotals: Array<
      TempAndHumDailyTotals & {
        countTemperature: number
        countHumidity: number
      }
    > = []
    const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD') // Obtém o primeiro dia do período
    const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD') // Obtém o último dia do período

    // Preenche o array dailyTotals com todos os dias do período
    for (
      let date = moment(startDate).clone();
      date.isSameOrBefore(endDate);
      date.add(1, 'day')
    ) {
      const dateString = date.format('YYYY-MM-DD')
      dailyTotals.push({
        date: dateString,
        totalTemperature: 0,
        countTemperature: 0,
        totalHumidity: 0,
        countHumidity: 0,
      })
    }

    for (const item of data) {
      const date = moment(item.creation).format('YYYY-MM-DD')

      const existingItem = dailyTotals.find((x) => x.date === date)

      if (existingItem && item.valuetype === 'TEMP') {
        existingItem.totalTemperature += !isNaN(Number(item.value))
          ? Number(item.value)
          : 0
        existingItem.countTemperature++
      } else if (existingItem && item.valuetype === 'HUM') {
        existingItem.totalHumidity += !isNaN(Number(item.value))
          ? Number(item.value)
          : 0
        existingItem.countHumidity++
      }
    }

    return dailyTotals.map((item) => ({
      ...item,
      totalTemperature: Math.round(
        item.countTemperature
          ? item.totalTemperature / item.countTemperature
          : item.totalTemperature,
      ),
      totalHumidity: Math.round(
        item.countHumidity
          ? item.totalHumidity / item.countHumidity
          : item.totalHumidity,
      ),
    }))
  }

  function calculateMonthlyTotals(
    data: TempAndHumDataItem[] = [],
  ): TempAndHumMonthlyTotals[] {
    if (!data.length) {
      return []
    }

    const monthlyTotals: Array<
      TempAndHumMonthlyTotals & {
        countTemperature: number
        countHumidity: number
      }
    > = []

    const year = moment(date).year() // Obtém o ano atual

    // Inicializa o array monthlyTotals com os meses do ano
    for (let month = 1; month <= 12; month++) {
      const monthString = moment()
        .month(month - 1)
        .year(year)
        .format('YYYY-MM')
      monthlyTotals.push({
        month: monthString,
        totalTemperature: 0,
        totalHumidity: 0,
        countTemperature: 0,
        countHumidity: 0,
      })
    }

    for (const item of data) {
      const month = moment(item.creation).format('YYYY-MM')

      const existingItem = monthlyTotals.find((i) => i.month === month)
      if (existingItem && item.valuetype === 'TEMP') {
        existingItem.totalTemperature += !isNaN(Number(item.value))
          ? Number(item.value)
          : 0
        existingItem.countTemperature++
      } else if (existingItem && item.valuetype === 'HUM') {
        existingItem.totalHumidity += !isNaN(Number(item.value))
          ? Number(item.value)
          : 0
        existingItem.countHumidity++
      }
    }

    return monthlyTotals.map((item) => ({
      ...item,
      totalTemperature: Math.round(
        item.totalTemperature / item.countTemperature,
      ),
      totalHumidity: Math.round(item.totalHumidity / item.countHumidity),
    }))
  }

  function calculateHourlyTotals(data: TempAndHumDataItem[]) {
    const hourlyTotals: Array<
      TempAndHumHourlyTotals & {
        countTemperature: number
        countHumidity: number
      }
    > = []

    // inicializa o array hourlyTotals com as horas do dia
    for (let hour = 0; hour < 24; hour++) {
      hourlyTotals.push({
        hour: hour.toString(),
        totalTemperature: 0,
        totalHumidity: 0,
        countTemperature: 0,
        countHumidity: 0,
      })
    }

    // Preenche o array hourlyTotals com os valores de temperatura e umidade
    for (const item of data) {
      const hour = moment(item.creation).hour()

      const existingItem = hourlyTotals.find((i) => i.hour === hour.toString())
      if (existingItem && item.valuetype === 'TEMP') {
        existingItem.totalTemperature += !isNaN(Number(item.value))
          ? Number(item.value)
          : 0
        existingItem.countTemperature++
      } else if (existingItem && item.valuetype === 'HUM') {
        existingItem.totalHumidity += !isNaN(Number(item.value))
          ? Number(item.value)
          : 0
        existingItem.countHumidity++
      }
    }

    return hourlyTotals
  }

  const getData = (
    type: keyof Omit<TempAndHumMonthlyTotals, 'month'>,
    temperatureData: TempAndHumResponseType[],
  ) => {
    if (period === 'year') {
      return calculateMonthlyTotals(temperatureData ?? [])?.map(
        (item) => item[type],
      )
    }

    if (period === 'day') {
      return calculateHourlyTotals(temperatureData ?? [])?.map(
        (item) => item[type],
      )
    }

    return calculateDailyTotals(temperatureData ?? [])?.map(
      (item) => item[type],
    )
  }

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // let label = context.dataset.label || ''

            return `${context.parsed.y} ºC`
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return value + 'ºC'
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  const getLabels = useCallback(() => {
    if (period === 'year') {
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    return getTimesOfDay(date, 'HH:mm')
  }, [period, date])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()

    const chartData = {
      labels,
      datasets: [
        {
          fill: true,
          tension: 0.4,
          type: 'line' as const,
          label: t('chart.temperature.areaName', { name: areaName1 }),
          borderRadius: 20,
          data: getData('totalTemperature', dataIndoor ?? []),
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[3],
            0.6,
          ),
          borderColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[3],
          ),
        },
        {
          fill: true,
          tension: 0.4,
          type: 'line' as const,
          label: t('chart.temperature.areaName', { name: areaName2 }),
          borderRadius: 20,
          data: getData('totalTemperature', dataOutdoor ?? []),
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[2],
            0.6,
          ),
          borderColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.temperature[2],
          ),
        },
      ],
    }

    setChartData(chartData)
  }, [
    i18n.language,
    date,
    getLabels,
    dataIndoor,
    dataOutdoor,
    areaId1,
    areaId2,
  ])

  return {
    isDesktop,
    date,
    options,
    setDate,
    chartData,
    chartRef,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    areas,
    setSelectArea1,
    setSelectArea2,
    setAreaName1,
    setAreaName2,
    areaId1,
    areaId2,
    year,
    month,
  }
}
