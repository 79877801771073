export enum ModuleEnum {
  DASH_WATER = 'DASH_WATER',
  DASH_ENERGY = 'DASH_ENERGY',
  CAIXA_DAGUA = 'Caixa Dágua',
  DASH_GAS = 'DASH_GAS',
  DASH_TEMPERATURE = 'DASH_TEMPERATURE',
  DASH_TEMPERATURE_HUMIDITY = 'DASH_TEMPERATURE_HUMIDITY',
  DASH_HUMIDITY = 'DASH_HUMIDITY',
  DASH_ADVANCED = 'DASH_ADVANCED',
  DASH = 'DASH',
  CONF = 'CONF',
  CONF_SENSOR = 'CONF_SENSOR',
  CONF_PROCEDURE = 'CONF_EQUIPMENT',
  CONF_CONTROL_BRANCH_OFFICE = 'CONF_CONTROL_BRANCH_OFFICE',
  CONF_ALERTS = 'CONF_ALERTS',
  CONF_NOTIFICATIONS = 'CONF_NOTIFICATIONS',
  CONF_GOAL = 'CONF_GOAL',
  CONF_PARAMETER = 'CONF_PARAMETER',
  CONF_INVOICE = 'CONF_INVOICE',
  CONF_MAP = 'CONF_MAP',
  CONF_MAP_WATER = 'CONF_MAP_WATER',
  CONF_MAP_ENERGY = 'CONF_MAP_ENERGY',
  CONF_MAP_GAS = 'CONF_MAP_GAS',
  CONF_MAP_TEMPERATURE = 'CONF_MAP_TEMPERATURE',
  CONF_MAP_TEMPERATURE_HUMIDITY = 'CONF_MAP_TEMPERATURE_HUMIDITY',
  CONF_MAP_HUMIDITY = 'CONF_MAP_HUMIDITY',
  MON = 'MON',
  MON_WATER = 'MON_WATER',
  MON_ENERGY = 'MON_ENERGY',
  MON_GAS = 'MON_GAS',
  MON_TEMPERATURE = 'MON_TEMPERATURE',
  MON_TEMPERATURE_HUMIDITY = 'MON_TEMPERATURE_HUMIDITY',
  MON_HUMIDITY = 'MON_HUMIDITY',
  MAIN = 'MAIN',
  MAIN_CALENDAR = 'MAIN_CALENDAR',
  MAIN_CONFIG_SCALING = 'MAIN_CONFIG_SCALING',
  MONITORING_COMPANIES = 'MONITORING_COMPANIES',
  ACC = 'ACC',
  ACC_USER = 'ACC_USER',
  ACC_UNIT = 'ACC_UNIT',
  ACC_PROFILE = 'ACC_PROFILE',
  CLI = 'CLI',
  CLI_CLIENT = 'CLI_CLIENT',
  BTN = 'BTN',
  BTN_ALERT_PANIC = 'BTN_ALERT_PANIC',
  BTN_MONIT = 'BTN_MONIT',
  BTN_CONF_MAP_PLAN = 'BTN_CONF_MAP_PLAN',
  BTN_PROCEDURE = 'BTN_PROCEDURE',
  BTN_MONITORING = 'BTN_MONITORING',
  BTN_REPORT = 'BTN_REPORT',
}

export enum PermissionEnum {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}
