import { icon } from 'leaflet'

import PinPurpleGif from 'assets/images/map/pim.gif'
import PinJumpCircularSVG from 'assets/images/map/pin-jump-circular.svg'
import PinPrimarySvg from 'assets/images/map/pin-primary.svg'

export enum PinVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  JUMP_CIRCULAR = 'jump-circular',
}

export const getIconByVariant = (variant: PinVariant) => {
  if (variant === PinVariant.DEFAULT) {
    return icon({
      iconUrl: PinPrimarySvg,
      iconSize: [38, 38],
      iconAnchor: [16, 32],
      className: 'custom-icon',
    })
  }
  if (variant === PinVariant.PRIMARY) {
    return icon({
      iconUrl: PinPurpleGif,
      iconSize: [38, 38],
      iconAnchor: [16, 32],
      className: 'custom-icon',
    })
  }
  if (variant === PinVariant.SECONDARY) {
    return icon({
      iconUrl: PinPrimarySvg,
      iconSize: [38, 38],
      iconAnchor: [16, 32],
      className: 'custom-icon',
    })
  }
  if (variant === PinVariant.JUMP_CIRCULAR) {
    return icon({
      iconUrl: PinJumpCircularSVG,
      iconSize: [38, 38],
      iconAnchor: [16, 32],
      className: 'custom-icon',
    })
  }
}
