import { Divider, Stack } from '@mui/material'
import { useState } from 'react'

import { IconButton } from 'components/Button/IconButton'
import { useMonitoringSensorStore } from 'store/monitoring-sensor.store'

import { CardAlertSensor } from './components/CardAlertSensor'
import { DataFactory } from './components/DataFactory'
import { NumberSensorsCompany } from './components/NumberSensorsCompany'

interface Props {
  data: any
}

export const UnitSidebar = ({ data }: Props) => {
  const [alerts] = useState([])

  const { showDataCompanyDetail, setShowDataCompanyDetail, currentCompany } =
    useMonitoringSensorStore()

  return (
    <Stack
      direction='column'
      flex={1}
      sx={{
        width: !showDataCompanyDetail ? '100%' : 0,
        maxHeight: 'calc(100vh - 104px)',
        maxWidth: !showDataCompanyDetail ? 418 : 0,
        background: (theme) => theme.palette.background.default,
        borderRight: !showDataCompanyDetail ? '2px solid' : '0 solid',
        borderColor: (theme) => theme.palette.secondary.light,
        p: !showDataCompanyDetail ? 2 : 0,
        transition: 'all 0.3s ease',
        position: 'relative',
      }}
    >
      <IconButton
        size='small'
        variant='contained'
        iconName='chevronLeft'
        sx={{
          width: 30,
          height: 30,
          position: 'absolute',
          left: -30,
          top: 60,
          zIndex: 1200,
          transform: 'translateX(50%)',
        }}
        onClick={() => setShowDataCompanyDetail(true)}
      />

      <DataFactory data={currentCompany} />

      <NumberSensorsCompany />

      <Divider
        sx={{ color: (theme) => theme.palette.primary.main, mt: 1, mb: 0.5 }}
      />

      <Stack
        direction='column'
        sx={{
          overflowY: 'scroll',
          height: 'calc(100vh - 400px)',
        }}
      >
        {alerts?.map((alert: any) => (
          <CardAlertSensor typeAlert='unit' data={data} key={alert?.id} />
        ))}
      </Stack>
    </Stack>
  )
}
