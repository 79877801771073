import { httpClient } from 'services/api/httpClient'
import { CompanyType } from 'types/company/company'
import { PaginationType } from 'types/pagination'

type NewType = PaginationType<CompanyType>

export const getCompany = async (
  page = 1,
  search = '',
  orderBy?: string,
): Promise<NewType> => {
  const response = await httpClient.get('/company')
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  const results = items?.filter((item: CompanyType) =>
    item.name.toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
    order: orderBy,
  }
}
