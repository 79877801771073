import { PaginationType } from 'types/pagination'
import { OSType } from 'types/pre-os/pre-os'

import { IOrderService } from '../../core/interfaces/order-service/IOrderService'
import { httpClient } from '../api/httpClient'

type NewType = PaginationType<IOrderService>

export const getOrderServices = async (
  page = 1,
  search = '',
  orderBy?: string,
): Promise<NewType> => {
  const response = await httpClient.get('/order-service', {
    params: {
      page: page - 1,
      search,
    },
  })

  const { total } = response.data
  const { perPage } = response.data
  return {
    results: response?.data?.items ?? [],
    limit: perPage,
    count: total,
    order: orderBy,
  }
}

/**
 * Pesquisa 1(uma) ordem de serviço
 * @param id Identificador unico da ordem
 * @returns OSType
 */
export const getOrderService = async (id: number): Promise<OSType> => {
  const { data } = await httpClient.get(`/order-service/${id}`)
  return data
}
