/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { colors } from 'helpers/colors'
import { getAllAreasBySensorType } from 'services/area/getAllAreas'
import { getAllByRealTime } from 'services/dashboard/gasDashboard.service'
import { SensorTypeEnum } from '../../../../core/enums/SensorTypeEnum'

export function useGroupAirQualityChart() {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [areaId, setSelectArea] = useState<string | undefined>(undefined)
  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs().startOf('year').format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs().endOf('year').format('YYYY-MM-DD')

  const { data, refetch } = useQuery({
    queryKey: ['realTime', areaId],
    queryFn: async () => {
      if (!areaId) return []

      return await getAllByRealTime(areaId)
    },
  })

  const { data: areas, refetch: refetchAreas } = useQuery({
    queryKey: 'all-areas-air-quality-group',
    queryFn: async () => {
      return await getAllAreasBySensorType(SensorTypeEnum.AIR)
    },
    select: (data) => {
      return data?.map((item) => ({
        label: item.name,
        value: String(item.id.value),
      }))
    },
  })

  useEffect(() => {
    if (areas?.length) {
      setSelectArea(areas[0].value)
    }
  }, [areas])

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
      refetchAreas()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const airs = [
    {
      label: t('chart.gas.carbonMonoxide', { abbreviation: '(CO)' }),
    },
    {
      label: t('chart.gas.nitrogenDioxide', { abbreviation: '(NO2)' }),
    },
    {
      label: t('chart.gas.ammonia', { abbreviation: '(NH3)' }),
    },
    {
      label: t('chart.gas.methane', { abbreviation: '(CH4)' }),
    },
    {
      label: t('chart.gas.propane', { abbreviation: '(C3H8)' }),
    },
    {
      label: t('chart.gas.ethanol', { abbreviation: '(C2H5OH)' }),
    },
    {
      label: t('chart.gas.hydrogen', { abbreviation: '(H2)' }),
    },
    {
      label: t('chart.gas.butane', { abbreviation: '(C4H10)' }),
    },
  ]

  const items =
    data?.map((item: any, index: number) => {
      return {
        name:
          airs.find((air) => air.label.includes(item.valueType))?.label ??
          item.valueType,
        value: item.value,
        color: colors.gas[index > colors.gas.length ? 0 : index].start,
      }
    }) ?? []

  return {
    isDesktop,
    items,
    t,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
    areas,
    setSelectArea,
    areaId,
  }
}
