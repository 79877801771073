import * as yup from 'yup'

export const schemaClientSensor = () =>
  yup.object({
    nameEquipment: yup.string().min(2).max(35).required(),
    description: yup.string().min(2).max(245).required(),
    dateOccurrence: yup.string().required(),
    type: yup.string().required(),
    location: yup.string().min(2).max(50).required(),
  })
