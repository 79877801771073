import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { sensorService } from 'services/sensor.service'
import { PaginationType } from 'types/pagination'
import { SensorTypeMap } from 'types/sensor/sensor'
import { ColumnType } from 'types/table/column'

import { sensorMapper } from '../components/mappers/sensor.mapper'

interface Props {
  typeSensor?: string
}

enum TypeSensor {
  SENSOR = 'SENSOR',
  AIR_CONDITIONING_SENSOR = 'AIR_CONDITIONING_SENSOR',
}

export const useSensor = ({ typeSensor }: Props) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')

  const getTypesSensors = () => {
    if (typeSensor) {
      if (TypeSensor.SENSOR === typeSensor) {
        return `${SensorTypeEnum.AIR},${SensorTypeEnum.ELECTRIC},${SensorTypeEnum.OPENING},${SensorTypeEnum.WATER},${SensorTypeEnum.TEMP},${SensorTypeEnum.HUM},${SensorTypeEnum.TEMP_HUM}`
      }
      if (TypeSensor.AIR_CONDITIONING_SENSOR === typeSensor) {
        return `${SensorTypeEnum.TEMP},${SensorTypeEnum.HUM},${SensorTypeEnum.TEMP_HUM}`
      }
    }
  }

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<SensorTypeMap>,
    Error
  >({
    queryKey: ['list-sensor-map', page, search, orderBy, order, typeSensor],
    queryFn: () =>
      sensorService.listMapSensor({
        page,
        columnName: orderBy,
        columnSort: order,
        type: getTypesSensors(),
        search,
      }),
  })

  const sensorReplace = sensorMapper(data?.items ?? [])

  const columns: ColumnType[] = [
    {
      id: 'createdAt',
      label: 'Data de Criação',
    },
    {
      id: 'address',
      label: 'Sinal',
    },
    {
      id: 'name',
      label: 'Sensor',
    },
    {
      id: 'type',
      label: 'Tipo de Sensor',
    },
    {
      id: 'equipment',
      label: 'Equipamento associado',
    },
    {
      id: 'ip',
      label: 'Endereo (mac/ip)',
    },
  ]

  return {
    columns,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    search,
    setSearch,
    page,
    setPage,
    sensorReplace,
    error,
    isLoading,
    isFetching,
    refetch,
    data,
  }
}
