import { IOrderService } from '../../core/interfaces/order-service/IOrderService'
import { httpClient } from '../api/httpClient'

export const createOrderService = (data: IOrderService) => {
  const { file, idfile, ...rest } = data

  if (!file) {
    return httpClient.post('/order-service', rest)
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('request', JSON.stringify(rest))

  return httpClient.post('/order-service/create', formData)
}
