import { PanicButtonTypeEnum } from 'core/enums/PanicButtonTypeEnum'
import { create } from 'zustand'

type State = {
  companyId?: string
  setCompanyId(value: string): void
  panicButtonTypes: PanicButtonTypeEnum[]
  setPanicButtonTypes: (values: PanicButtonTypeEnum[]) => void
  totalEquipment?: number
  setTotalEquipment(value: number): void
  totalTriggered?: number
  setTotalTriggered(value: number): void
}

export const panicMonitoringAreaSidebarStore = create<State>((set) => ({
  companyId: '',
  setCompanyId(value) {
    set({
      companyId: value,
    })
  },
  panicButtonTypes: [],
  setPanicButtonTypes(values) {
    set({
      panicButtonTypes: values,
    })
  },
  setTotalEquipment(value) {
    set({
      totalEquipment: value,
    })
  },
  setTotalTriggered(value) {
    set({
      totalTriggered: value,
    })
  },
}))
