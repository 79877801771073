import { Sensors } from '@mui/icons-material'
import { Box, BoxProps, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { sensorColors } from 'configs/sensorColors'
import { SensorOption } from 'types/sensor/sensor'

import { ReactComponent as AguaSvg } from '../../assets/icons/agua.svg'
import { ReactComponent as AlertSvg } from '../../assets/icons/alert.svg'
import { ReactComponent as EnergiaSvg } from '../../assets/icons/energia.svg'
import { ReactComponent as GasSvg } from '../../assets/icons/gas.svg'
import { ReactComponent as TemperaturaSvg } from '../../assets/icons/temperatura.svg'
import { ReactComponent as TemperaturaUmidadeSvg } from '../../assets/icons/temperaturaUmidade.svg'
import { ReactComponent as UmidadeSvg } from '../../assets/icons/umidade.svg'
import { IconSvg } from '../SvgIcon'

const icons = {
  TEMPERATURE_HUMIDITY: <IconSvg component={TemperaturaUmidadeSvg} />,
  TEMP_HUM: <IconSvg component={TemperaturaUmidadeSvg} />,
  TEMPERATURE: <IconSvg component={TemperaturaSvg} />,
  TEMP: <IconSvg component={TemperaturaSvg} />,
  HUMIDITY: <IconSvg component={UmidadeSvg} />,
  HUM: <IconSvg component={UmidadeSvg} />,
  GAS: <IconSvg component={GasSvg} />,
  AIR: <IconSvg component={GasSvg} />,
  ENERGY: <IconSvg component={EnergiaSvg} />,
  ELECTRIC: <IconSvg component={EnergiaSvg} />,
  ELETRIC: <IconSvg component={EnergiaSvg} />,
  WATER: <IconSvg component={AguaSvg} />,
  SENSOR: <Sensors fontSize='medium' />,
  OPENING: <Sensors fontSize='medium' />,
  BTN_PANIC: <IconSvg component={AlertSvg} />,
}

export type SensorIconType = SensorOption

interface Props extends BoxProps {
  icon: SensorIconType
  size?: 'small' | 'medium'
  error?: boolean
  hiddenTitle?: boolean
}

export function Sensor({
  icon,
  size = 'medium',
  hiddenTitle,
  error,
  sx,
  ...rest
}: Props) {
  const { t } = useTranslation('common')
  return (
    <>
      {hiddenTitle && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '6px',
            backgroundImage: error
              ? sensorColors.GRADIENT['ERROR']
              : sensorColors.GRADIENT[icon],
            minWidth: size === 'small' ? 28 : 44,
            minHeight: size === 'small' ? 28 : 44,
            color: '#FFF',
            padding: '2px',
            ...sx,
          }}
          {...rest}
        >
          {icons[icon]}
        </Box>
      )}
      {!hiddenTitle && (
        <Tooltip
          title={t(`sensor.${icon?.toLowerCase()}`)}
          placement='top'
          arrow
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '6px',
              backgroundImage: error
                ? sensorColors.GRADIENT['ERROR']
                : sensorColors.GRADIENT[icon],
              minWidth: size === 'small' ? 28 : 44,
              minHeight: size === 'small' ? 28 : 44,
              color: '#FFF',
              padding: '2px',
              ...sx,
            }}
            {...rest}
          >
            {icons[icon]}
          </Box>
        </Tooltip>
      )}
    </>
  )
}
