import { Avatar, Popover, Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Icon } from 'components/Icon'

interface Props {
  title?: string
  src?: string
}

export function Photo({ src, title }: Props) {
  const { t } = useTranslation('common')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      {title ? (
        <Button
          variant='contained'
          color='secondary'
          startIcon={<Icon name='photo' />}
          sx={{ paddingX: 2 }}
          onClick={handleClick}
        >
          {title}
        </Button>
      ) : (
        <IconButton
          iconName='photo'
          size='small'
          variant='contained'
          title={t('clickToView') ?? ''}
          onClick={handleClick}
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& > .MuiPaper-root': {
            borderRadius: '6px',
            border: (theme) =>
              theme.palette.mode === 'dark'
                ? '1px solid #5858B2'
                : '1px solid #ccc',
            background: (theme) => theme.palette.secondary.light,
            padding: 1,
          },
        }}
      >
        <Stack
          direction='row'
          spacing={1}
          minWidth={180}
          bgcolor='#FFF'
          borderRadius={1}
        >
          <Avatar
            src={src}
            title='photo'
            sx={{
              height: 180,
              width: 180,
              borderRadius: 1,
            }}
          />
        </Stack>
      </Popover>
    </>
  )
}
