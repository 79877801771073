function interpolateColor(
  color1: string,
  color2: string,
  factor: number,
): string {
  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return { r, g, b }
  }

  const rgbToHex = (rgb: { r: number; g: number; b: number }): string => {
    return `#${((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b)
      .toString(16)
      .slice(1)}`
  }

  const color1Rgb = hexToRgb(color1)
  const color2Rgb = hexToRgb(color2)

  const interpolatedRgb = {
    r: Math.round(color1Rgb.r + (color2Rgb.r - color1Rgb.r) * factor),
    g: Math.round(color1Rgb.g + (color2Rgb.g - color1Rgb.g) * factor),
    b: Math.round(color1Rgb.b + (color2Rgb.b - color1Rgb.b) * factor),
  }

  return rgbToHex(interpolatedRgb)
}

export function getColorAtIndex(colors: ColorPair[], index: number): ColorPair {
  if (index >= 0 && index < colors.length) {
    return colors[index]
  } else {
    const randomIndex = Math.floor(Math.random() * colors.length)
    return colors[randomIndex]
  }
}

interface ColorPair {
  start: string
  end: string
}

export function generateIntermediateColors(
  colors: ColorPair[],
  index?: number,
): ColorPair {
  const colorPair = getColorAtIndex(colors, index ?? 0)
  const factor = 0.5

  if (index && index <= colors.length - 1) {
    return colorPair
  }

  const intermediateColor1 = interpolateColor(
    colorPair.start,
    colorPair.end,
    factor,
  )
  const intermediateColor2 = interpolateColor(
    colorPair.start,
    colorPair.end,
    factor * 0.7,
  )

  return {
    start: intermediateColor1,
    end: intermediateColor2,
  }
}
