import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useRouteError } from 'react-router-dom'

import notFoundImg from 'assets/images/not-found.svg'
import { IconButton } from 'components/Button/IconButton'
import { Container } from 'components/Container'

export default function ErrorPage() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const error = useRouteError() as { statusText: string; message: string }

  return (
    <Stack
      id='error-page'
      sx={{
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Stack
        component='header'
        direction='row'
        alignItems='center'
        sx={{
          width: '100%',
          minHeight: 104,
          borderBottom: (theme) => `2px solid ${theme.palette.secondary.light}`,
          marginBottom: 3,
          paddingLeft: 0,
        }}
      >
        <Container>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Stack direction='row' alignItems='center' spacing={2}>
              <IconButton
                iconName='goBack'
                variant='contained'
                color='primary'
                onClick={() => navigate(-1)}
                title={t('button.title.goBack') ?? ''}
              />
              <IconButton
                iconName='refresh'
                variant='contained'
                color='primary'
                onClick={() => document.location.reload()}
              />
              <Typography
                variant='h1'
                sx={{ fontSize: ['1.125rem', '1.5rem'] }}
              >
                404 Error
              </Typography>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Stack
        flex={1}
        spacing={6}
        sx={{
          width: '100%',
          alignItems: 'center',
          paddingTop: 6,
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            minHeight: [350, 500],
            minWidth: ['100%', 450],
            background: `url(${notFoundImg})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />

        <Box textAlign='center' fontStyle='italic'>
          <Typography variant='body2'>{t('error.404')}</Typography>
          <span>{error.statusText || error.message}</span>
        </Box>
      </Stack>
    </Stack>
  )
}
