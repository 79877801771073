export enum SensorTypeEnum {
  ALL = 'ALL',
  TEMP = 'TEMP',
  HUM = 'HUM',
  TEMP_HUM = 'TEMP_HUM',
  ELECTRIC = 'ELECTRIC',
  WATER = 'WATER',
  AIR = 'AIR',
  OPENING = 'OPENING',
  BTN_PANIC = 'BTN_PANIC',
}

type ParamsGetAllSensor = {
  typesNotEnabled?: SensorTypeEnum[]
}

export const getAllSensorTypeEnum = (params: ParamsGetAllSensor = {}) => {
  const { typesNotEnabled } = params

  return Object.values(SensorTypeEnum).filter((item) => {
    return typeof item === 'string' && typesNotEnabled
      ? !typesNotEnabled.includes(item)
      : true
  }) as SensorTypeEnum[]
}
