import { Collapse, Stack } from '@mui/material'
import { useState } from 'react'

import { Button } from '..'

import { IconButton } from '../IconButton'

export type Topic = {
  id: string | number
  name: string
  subtopics?: Topic[]
}

interface Props {
  topic: Topic
  onClick?: (buttonId: string | number) => void
  activeTopicId?: string
}

export function TopicButtons({ topic, onClick, activeTopicId }: Props) {
  const [show, setShow] = useState(() => {
    if (topic.id === activeTopicId) return true

    return !!topic?.subtopics?.find((item) => item.id === activeTopicId)
  })

  return (
    <>
      {!topic?.subtopics?.length ? (
        <Button
          variant={activeTopicId === topic.id ? 'contained' : 'outlined'}
          fullWidth
          onClick={() => onClick?.(topic.id)}
          sx={{
            textAlign: 'left',
            justifyContent: 'flex-start',
          }}
        >
          {topic.name}
        </Button>
      ) : (
        <>
          <Stack width='100%'>
            <Stack direction='row' spacing={1} width='100%'>
              <IconButton
                iconName={show ? 'expandLess' : 'expandMore'}
                variant='outlined'
                onClick={() => setShow((state) => !state)}
              />
              <Button
                variant='outlined'
                fullWidth
                onClick={() => onClick?.(topic.id)}
                sx={{
                  textAlign: 'left',
                  justifyContent: 'flex-start',
                }}
              >
                {topic.name}
              </Button>
            </Stack>
            <Collapse in={show}>
              <Stack spacing={1} marginTop={1}>
                {topic.subtopics.map((item) => (
                  <Button
                    key={`subtopic-${item.id}`}
                    variant={
                      activeTopicId === item.id ? 'contained' : 'outlined'
                    }
                    fullWidth
                    onClick={() => onClick?.(item.id)}
                    sx={{
                      textAlign: 'left',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Stack>
            </Collapse>
          </Stack>
        </>
      )}
    </>
  )
}
