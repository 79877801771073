import { create } from 'zustand'

import { MapItemType } from 'types/map/map'

type HiddenFeederType = {
  id: string
  hidden: boolean
}

type MapStore = {
  currentCompany: null | string
  setCurrentCompany: (value: string) => void
  sidebarVisibility: boolean
  toggleSidebarVisibility(): void
  currentMap: null | MapItemType
  setCurrentMap(value?: MapItemType): void
  maps: null | MapItemType[]
  setMaps: (value: MapItemType[]) => void
  loadingMap: boolean
  setLoadingMap: (loadingMap: boolean) => void
  hiddenSchema?: {
    id: string
    hidden: boolean
  }
  setHiddenSchema: (hiddenSchema: { id: string; hidden: boolean }) => void
  hiddenFeeders?: HiddenFeederType[]
  setHiddenFeeders: (hiddenFeeders: { id: string; hidden: boolean }) => void
  hiddenAreas: string[]
  setHiddenAreas: (hiddenAreas: string[]) => void
  closeModal: (value: boolean) => void
  isCloseModal: boolean
}

export const useMapStore = create<MapStore>((set) => ({
  currentCompany: null,
  setCurrentCompany: (currentCompany) => {
    set({ currentCompany })
  },
  sidebarVisibility: true,
  toggleSidebarVisibility: () => {
    set((state) => ({ sidebarVisibility: !state.sidebarVisibility }))
  },
  currentMap: null,
  setCurrentMap: (currentMap) => {
    set({ currentMap })
  },
  loadingMap: false,
  setLoadingMap: (loadingMap: boolean) => {
    set({ loadingMap })
  },
  hiddenSchema: undefined,
  setHiddenSchema: (hiddenSchema: { id: string; hidden: boolean }) => {
    set({ hiddenSchema })
  },
  hiddenFeeders: [],
  setHiddenFeeders: (hiddenFeeder: { id: string; hidden: boolean }) => {
    const existingHiddenFeeder = useMapStore
      .getState()
      .hiddenFeeders?.find((hf) => hf.id === hiddenFeeder.id)

    let hiddenFeeders = useMapStore.getState().hiddenFeeders

    if (existingHiddenFeeder) {
      hiddenFeeders = hiddenFeeders?.map((hf) =>
        hf.id === hiddenFeeder.id ? hiddenFeeder : hf,
      )
    } else {
      hiddenFeeders = [...(hiddenFeeders || []), hiddenFeeder]
    }
    set({ hiddenFeeders })
  },
  hiddenAreas: [],
  setHiddenAreas: (hiddenAreas: string[]) => {
    set({ hiddenAreas })
  },
  isCloseModal: false,
  closeModal: (value) => {
    set({ isCloseModal: value })
  },
  maps: null,
  setMaps: (maps) => {
    set({ maps })
  },
}))
