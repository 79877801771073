import { Stack } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { Header } from './components/Header'

export const PanicLayout = () => {
  return (
    <>
      <Header marginBottom={0} />

      <Stack flex={1} component='main' spacing={3}>
        <Outlet />
      </Stack>
    </>
  )
}
