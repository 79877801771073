import { Stack } from '@mui/material'
import { Outlet } from 'react-router-dom'

import bgImg from 'assets/images/background.png'

import { DevelopedBy } from './components/DevelopedBy'

export function PublicLayout() {
  return (
    <Stack
      sx={{
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        paddingY: 6,
        background: `url(${bgImg})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Outlet />

      <DevelopedBy />
    </Stack>
  )
}
