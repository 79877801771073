import * as yup from 'yup'

export const schemaShift = () => {
  return yup.object({
    name: yup.string().required(),
    weekDays: yup.array().of(
      yup.object({
        hourInitial: yup.string().required(),
        hourFinal: yup.string().required(),
      }),
    ),
  })
}
