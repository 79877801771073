import { httpClient } from 'services/api/httpClient'
import { ParameterType } from 'types/goalAndParameter/parameter'
import { PaginationParamType, PaginationType } from 'types/pagination'

type NewType = PaginationType<ParameterType>

export const listParameter = async ({
  page,
  search = '',
  columnSort,
  columnName,
  numberPerPage,
}: PaginationParamType): Promise<NewType> => {
  const { data } = await httpClient.get('/parameter', {
    params: {
      page: page - 1,
      numberPerPage,
      columnSort,
      columnName,
      search,
    },
  })

  return data
}

export const findAllParameters = async () => {
  const response = await httpClient.get('/parameter', {
    params: {
      page: 0,
      numberPerPage: 1000,
    },
  })

  return response.data?.items ?? []
}
