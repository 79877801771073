/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import { AxiosProgressEvent } from 'axios'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { MaskedInputPhoneNumber } from 'components/Input/mask/MaskedInputPhoneNumber'
import { Loading } from 'components/Loading'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'
import { authService } from 'services/auth.service'
import { myAccountService } from 'services/my-account.service'
import { useAuthStore } from 'store/auth.store'
import { ModalType } from 'types/modal'
import { FormMyAccountType, MyAccountType } from 'types/my-account/myAccount'

import { schemaMyAccount } from './my-account.validation'

type Props = ModalType

export function FormMyAccount({ onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { user, setUser } = useAuthStore((state) => state)
  const id = user?.id ?? 0
  const [isChangePassword, setChangePassword] = useState(false)

  const [isUploading, setUploading] = useState(false)
  const [progressUpload, setProgressUpload] = useState(0)
  const [fileName, setFileName] = useState(user?.fileName ?? '')

  const { data, isLoading } = useQuery<MyAccountType, Error>({
    queryKey: ['my-account'],
    queryFn: () => myAccountService.get(user?.id ?? 0),
  })

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<FormMyAccountType>({
    resolver: yupResolver(schemaMyAccount),
    defaultValues: {
      name: user?.name ?? '',
      email: user?.email ?? '',
      contact: user?.contact ?? '',
      login: user?.login ?? '',
      profile: user?.profile?.name ?? 'Não informado',
      role: user?.role ?? 'Não informado',
      rfid: user?.rfid ?? 'Não informado',
      password: '',
      passwordConfirmation: '',
      oldPassword: '',
    },
  })

  useEffect(() => {
    if (data?.email) {
      setValue('email', data?.email)
      setValue('name', data?.name)
      setValue('contact', data?.contact)
      setValue('login', data?.login)
      setValue('profile', data?.profile?.name ?? 'Não informado')
      setValue('role', data?.role ?? 'Não informado')
      setValue('rfid', data?.rfid ?? 'Não informado')
      setFileName((state) => data?.fileName ?? state)
    }
  }, [data])

  const onSetSuccessfully = async () => {
    toast.success(t('savedInformation'))
    queryClient.invalidateQueries('my-account')
    const { data: updateUser } = await authService.getUser(id) // carregar dados do usuario autenticado
    setUser({
      ...user,
      ...updateUser,
    })
    reset()
    onClose()
  }

  const mutationUpdate = useMutation(
    (formData: FormMyAccountType) => {
      return myAccountService.update({
        id: user?.id ?? 0,
        data: isChangePassword
          ? {
              contact: formData.contact,
              email: formData.email,
              login: formData.login,
              name: formData.name,
              role: formData.role,
              // profile: formData.profile,
              rfid: formData.rfid,
              fileName: fileName,
              password: formData.password,
              oldPassword: formData.oldPassword,
            }
          : {
              contact: formData.contact,
              email: formData.email,
              login: formData.login,
              name: formData.name,
              role: formData.role,
              // profile: formData.profile,
              rfid: formData.rfid,
              fileName: fileName,
            },
      })
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const onSubmit: SubmitHandler<FormMyAccountType> = (formData) => {
    if (formData?.password && formData?.password?.length < 6) {
      toast.error(t('passwordValidationMinLength'))
      setFocus('password')
      return
    }
    mutationUpdate.mutate(formData)
  }

  const handleUploadImage = (file?: File | null) => {
    if (!file || !user) return

    setUploading(true)

    const formData = new FormData()
    formData.append('file', file)

    httpClient
      .post('/upload', formData, {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent?.total || 1),
          )

          setProgressUpload(progress)
        },
      })
      .then((response) => {
        setUploading(false)
        // Upload concluído com sucesso
        const image = response.data?.replace(
          'Uploaded the file successfully: ',
          '',
        )
        setFileName(image)
        myAccountService
          .update({
            id: user?.id ?? 0,
            data: {
              contact: user.contact ?? '0000000000',
              email: user.email,
              login: user.login ?? user.name,
              name: user.name,
              role: user.role ?? 'Não informado',
              // profile: user.profile,
              rfid: user.rfid ?? 'Não informado',
              fileName: image,
            },
          })
          .then(async () => {
            toast.success(t('savedInformation'))
            queryClient.invalidateQueries('my-account')
            const { data: updateUser } = await authService.getUser(id) // carregar dados do usuario autenticado
            setUser({
              ...user,
              ...updateUser,
            })
          })
          .catch((error) => {
            // Ocorreu um erro durante o upload
            getErrorMessage(error)
          })
          .finally(() => {
            setUploading(false)
          })
      })
      .catch((error) => {
        // Ocorreu um erro durante o upload
        getErrorMessage(error)
        setUploading(false)
      })
  }

  return (
    <>
      <Modal title={t('myAccount')} open={open} onClose={onClose}>
        {isLoading && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Loading mode='circular' />
            </Grid>
          </Grid>
        )}
        {!isLoading && (
          <Grid
            container
            spacing={3}
            component='form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <>
              <Grid item xs={3}>
                <Box
                  component={'label'}
                  htmlFor='upload'
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                >
                  <input
                    id='upload'
                    type='file'
                    accept='image/*'
                    hidden
                    onChange={(event) =>
                      handleUploadImage(event?.target?.files?.[0])
                    }
                    disabled={isUploading}
                    multiple={false}
                  />
                  <Avatar
                    src={`${BASE_URL_IMAGES}${fileName}`}
                    sx={{
                      maxHeight: 120,
                      height: '100%',
                      maxWidth: 120,
                      width: '100%',
                      borderRadius: 1,
                    }}
                  />

                  {isUploading && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        borderRadius: 1,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      {progressUpload}%
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Input
                      name='name'
                      label={t('input.fullName') ?? ''}
                      control={control}
                      fullWidth
                      error={!!errors?.name}
                      errorMessage={errors?.name?.message}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name='email'
                      type='email'
                      label={t('input.email') ?? ''}
                      control={control}
                      fullWidth
                      error={!!errors?.email}
                      errorMessage={errors?.email?.message}
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Input
                  name='contact'
                  label={t('input.contact') ?? ''}
                  control={control}
                  fullWidth
                  error={!!errors?.contact}
                  errorMessage={errors?.contact?.message}
                  InputProps={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    inputComponent: MaskedInputPhoneNumber as any,
                  }}
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <Input
                  name='login'
                  label={t('input.login') ?? ''}
                  control={control}
                  fullWidth
                  error={!!errors?.login}
                  errorMessage={errors?.login?.message}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name='profile'
                  label={t('input.profile') ?? ''}
                  control={control}
                  fullWidth
                  error={!!errors?.profile}
                  errorMessage={errors?.profile?.message}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name='role'
                  label={t('input.role') ?? ''}
                  control={control}
                  fullWidth
                  error={!!errors?.role}
                  errorMessage={errors?.role?.message}
                  disabled
                />
              </Grid>

              {isChangePassword && (
                <>
                  <Grid item xs={12}>
                    <Typography fontWeight={'bold'}>
                      {t('important')}
                    </Typography>
                    <Typography variant='body2'>
                      {t('passwordValidation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name='oldPassword'
                      type='password'
                      label={t('input.currentPassword') ?? ''}
                      control={control}
                      fullWidth
                      error={!!errors?.oldPassword}
                      errorMessage={errors?.oldPassword?.message}
                      required={isChangePassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name='password'
                      type='password'
                      label={t('input.password') ?? ''}
                      control={control}
                      fullWidth
                      error={!!errors?.password}
                      errorMessage={errors?.password?.message}
                      required={isChangePassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name='passwordConfirmation'
                      type='password'
                      label={t('input.repeatANewPassword') ?? ''}
                      control={control}
                      fullWidth
                      error={!!errors?.passwordConfirmation}
                      errorMessage={errors?.passwordConfirmation?.message}
                      required={isChangePassword}
                    />
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <Button
                  variant='text'
                  color='inherit'
                  onClick={() => setChangePassword((state) => !state)}
                  sx={{
                    textTransform: 'inherit',
                  }}
                >
                  {isChangePassword
                    ? t('keepCurrentPassword')
                    : t('changeMyPassword')}
                </Button>
              </Grid>
              <Grid item xs={12} md={8}>
                <ModalAction
                  onCancel={onClose}
                  isSubmit
                  isLoading={mutationUpdate.isLoading}
                />
              </Grid>
            </>
          </Grid>
        )}
      </Modal>
    </>
  )
}
