/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react'
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import { CreateParameterType } from 'types/goalAndParameter/create-parameter'

import { SensorSelectType } from '../FormParameterModal'
import { FormFirstStepOpeningDoor } from './components/FormFirstStepOpeningDoor'
import { FormSecondStepOpeningDoor } from './components/FormSecondStepOpeningDoor'

interface Props {
  isView?: boolean
  getValues: UseFormGetValues<CreateParameterType>
  setValue: UseFormSetValue<CreateParameterType>
  control: Control<CreateParameterType, any>
  register: UseFormRegister<CreateParameterType>
  errors: FieldErrors<CreateParameterType>
  activeStep: number
  steps?: string[]
  sensorSelected: SensorSelectType[]
  setSensorsSelected: Dispatch<SetStateAction<SensorSelectType[]>>
  clearErrors: UseFormClearErrors<CreateParameterType>
  sensors?: any[]
  watch: UseFormWatch<CreateParameterType>
}

export const FormOpening = ({
  isView,
  control,
  errors,
  getValues,
  register,
  setValue,
  activeStep,
  steps,
  sensors,
  sensorSelected,
  setSensorsSelected,
  clearErrors,
  watch,
}: Props) => {
  const formSensor = [
    {
      id: 0,
      component: (
        <FormFirstStepOpeningDoor
          control={control}
          errors={errors}
          getValues={getValues}
          register={register}
          setValue={setValue}
          isView={isView}
          sensors={sensors}
          sensorSelected={sensorSelected}
          setSensorsSelected={setSensorsSelected}
          watch={watch}
          clearErrors={clearErrors}
        />
      ),
    },
    {
      id: 1,
      component: (
        <FormSecondStepOpeningDoor
          control={control}
          errors={errors}
          getValues={getValues}
          register={register}
          setValue={setValue}
          isView={isView}
          watch={watch}
        />
      ),
    },
  ]

  return (
    <>
      {activeStep === steps?.length ? null : (
        <>{formSensor[activeStep].component}</>
      )}
    </>
  )
}
