import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Icon } from 'components/Icon'
import { DataCompanyType } from 'types/branch/branch'

import { MapForm } from '../MapForm'
import { AntTab, AntTabs } from './Components/TabMap'

type Props = {
  maps?: DataCompanyType['areas']
  companyId?: string
  hideActionNewArea?: boolean
  parentElementSize: {
    small: number
    large: number
  }
  parentElementIsOpen: boolean
}

const INDEX_NOT_FOUND = -1

export const MapTabNavigation = ({
  maps = [],
  companyId,
  parentElementSize,
  parentElementIsOpen,
  hideActionNewArea = false,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState(0)
  const [openForm, setOpenForm] = useState(false)

  const setCurrentIndex = () => {
    const mapId = searchParams.get('mapId')
    const index = maps.findIndex((map) => map.entityId.value === mapId)
    setValue((oldValue) => (index !== INDEX_NOT_FOUND ? index : 0))
  }

  const updateSelectedMap = (currentIndex: number) => {
    const map = maps.at(currentIndex)
    if (map) {
      setSearchParams({
        mapId: map.entityId.value,
      })
    }
  }

  const changeTabs = (currentIndex: number) => {
    const isIndexToAddMap = currentIndex === maps.length
    if (isIndexToAddMap) {
      setOpenForm(isIndexToAddMap)
    } else {
      updateSelectedMap(currentIndex)
    }
  }

  useEffect(() => {
    setCurrentIndex()
  }, [maps])

  return (
    <>
      <AntTabs
        value={value}
        onChange={(_, newValue) => {
          changeTabs(newValue)
        }}
        aria-label='buttons-types-charts'
        sx={{
          position: 'absolute',
          left: parentElementIsOpen
            ? parentElementSize.large
            : parentElementSize.small,
          zIndex: 4,
          background: (theme) => theme.palette.background.default,
          width: parentElementIsOpen
            ? `calc(100% - ${parentElementSize.large})`
            : `calc(100% - ${parentElementSize.small})`,
          '& .MuiTabs-indicator': {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
          overflowX: 'hidden',
        }}
      >
        {maps?.map((map) => (
          <AntTab
            key={map.entityId.value}
            sx={{ marginRight: 1 }}
            label={map.nameArea}
          />
        ))}
        {!hideActionNewArea && (
          <AntTab
            sx={{ marginRight: 1, display: 'flex', gap: '0.5rem' }}
            icon={<Icon name='add' />}
            iconPosition='start'
            label={'New area'}
          />
        )}
      </AntTabs>

      {openForm && (
        <MapForm
          open={openForm}
          onClose={() => setOpenForm(false)}
          companyId={companyId}
        />
      )}
    </>
  )
}
