import { Stack, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  sx?: SxProps
}

export function MapHumidityColors(props: Props) {
  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    setIsAnimated(true)
  }, [])

  return (
    <Stack
      direction='row'
      justifyContent='space-around'
      alignItems='center'
      sx={{
        position: 'absolute',
        bottom: 72,
        left: 16,
        width: isAnimated ? ['calc(100% - 32px)', '100%'] : 0,
        overflow: 'hidden',
        transition: 'width 0.3s ease-in-out',
        maxWidth: ['100%', 450],
        height: 44,
        borderWidth: 4,
        borderRadius: 2,
        borderColor: 'background.paper',
        borderStyle: 'solid',
        background:
          'linear-gradient(90deg, #d37d33 0%, #de9d63 15%, #e7b88d 30%, #efd0b3 35%, #f9efe5 40%, #e2f7e4 60%, #c4e6c6 70%, #98d59d 80%, #69c170 85%, #44b24f 100%)',
        '& h5': {
          fontSize: '0.65rem',
        },
        ...props.sx,
      }}
    >
      {isAnimated && (
        <>
          <h5>0%</h5>
          <h5>5%</h5>
          <h5>10%</h5>
          <h5>20%</h5>
          <h5 style={{ color: 'black' }}>25%</h5>
          <h5 style={{ color: 'black' }}>30%</h5>
          <h5 style={{ color: 'black' }}>35%</h5>
          <h5>40%</h5>
          <h5>45%</h5>
          <h5>50%</h5>
        </>
      )}
    </Stack>
  )
}
