/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { Actions } from 'components/Actions'
import { DatePeriod } from 'components/DatePeriod'
import { Autocomplete } from 'components/Select/Autocomplete'
import { httpClientDownloadExcel } from 'helpers/httpClientDownloadExcel'
import { useConsumptionChart } from 'pages/dashboard/hooks/energy/useConsumptionChart'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
)

interface Props {
  isPreview?: boolean
}

export function ConsumptionChart(props: Props) {
  const { isPreview } = props

  const {
    options,
    date,
    setDate,
    chartRef,
    chartData,
    selectedAreas,
    t,
    setSelectedAreas,
    areas,
    period,
    setPeriod,
    setGeneratingFile,
    generatingFile,
    areaId,
  } = useConsumptionChart({ isPreview })

  return (
    <Box
      sx={{
        borderRadius: 1,
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        maxWidth: '100vw',
        minHeight: 200,
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        position: 'relative',
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>
      <Stack
        direction={['column', 'row']}
        justifyContent={['space-between']}
        alignItems={'flex-start'}
        marginBottom={3}
        flexWrap='wrap'
      >
        <Typography
          variant='h2'
          fontSize='1.125rem'
          fontWeight='bold'
          whiteSpace='nowrap'
          marginBottom={1}
        >
          {t('chart.energy.consumption', {
            abbreviation: '',
          })}
        </Typography>

        {!isPreview && (
          <Stack
            direction={['column', 'row']}
            justifyContent={'flex-end'}
            alignItems={['flex-end', 'center']}
            gap={2}
            width={['100%', 'auto']}
            marginLeft={'auto'}
          >
            <Stack
              direction={['column', 'row']}
              justifyContent={'flex-end'}
              alignItems={['flex-end', 'center']}
              spacing={2}
              width={['100%', 'auto']}
              flexWrap='wrap'
              gap={2}
            >
              <Box minWidth={200} width={['100%', 'auto']}>
                <DatePeriod
                  date={date}
                  defaultPeriod={period}
                  periods={['month', 'year']}
                  onChangeDate={setDate}
                  onChangePeriod={(value) => setPeriod(value)}
                />
              </Box>

              <Box minWidth={200} width={['100%', 'auto']}>
                <Autocomplete
                  name='area'
                  placeholder={t('input.selectArea')}
                  isMulti
                  value={selectedAreas}
                  onChange={(values: any) => setSelectedAreas(values)}
                  options={areas ?? []}
                />
              </Box>
            </Stack>

            <Stack paddingTop={[2, 0]}>
              <Actions
                color='inherit'
                title='button.title.export'
                options={[
                  {
                    type: 'excel',
                    action: () =>
                      httpClientDownloadExcel({
                        url:
                          '/dashboard-energy/consumption/export-excel/' +
                          areaId,
                        fileName: 'consumo',
                        year: date?.getFullYear() ?? new Date().getFullYear(),
                        setGeneratingFile,
                      }),
                  },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </Stack>

      <Chart
        type='bar'
        ref={chartRef}
        options={options}
        data={chartData}
        height={120}
      />
    </Box>
  )
}
