import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Icon } from 'components/Icon'
import { STATUS_SCHEDULE } from 'helpers/statusSchedule'
import { StatusScheduleType } from 'types/status-schedule'

interface Props {
  values: StatusScheduleType[]
}

export function Status({ values }: Props) {
  const { t } = useTranslation('common')

  return (
    <Stack
      direction={['row', 'row']}
      justifyContent={['space-between', 'flex-start']}
      alignItems={['flex-start', 'center']}
      width={'100%'}
      sx={{
        gap: 2,
        flexWrap: 'wrap',
      }}
    >
      {values.map((item) => (
        <Stack key={item} direction={'row'} alignItems={'center'} spacing={1}>
          <Box
            sx={{
              borderRadius: '50%',
              height: 24,
              width: 24,
              background: STATUS_SCHEDULE.COLORS[item],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon name={STATUS_SCHEDULE.ICONS[item]} />
          </Box>
          <Typography variant='body2'>
            {t(`scheduleStatus.${item?.toLowerCase()}`)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}
