import styled from '@emotion/styled'

export const ModalWrapper = styled.dialog<{ background?: string }>(
  {
    '&[open]': {
      position: 'fixed',
      top: 104,
      right: 0,
      bottom: 0,
      height: '100%',
      padding: '1.25rem',
      width: 420,
      zIndex: 5,
      overflow: 'auto',
      margin: 'initial',
      marginLeft: 'auto',
      border: 0,
      maxHeight: 'calc(100% - 104px)',
      color: '#fff',
    },
  },
  ({ background }) => ({
    background: background || '#11132d',
  }),
)

export const LabelNameSubarea = styled.div({
  display: 'flex',
  fontSize: 10,
  borderRadius: 6,
  border: '1px solid #0096AA',
  background: '#08283e',
  padding: '6px 1rem',
})

export const ModalContent = styled.div<{
  marginBottom?: string
  padding?: string
  background?: string
}>(
  {
    background: '#0C0C25',
    borderRadius: 6,
  },
  ({ marginBottom, padding = '1.25rem 1rem', background }) => ({
    padding,
    marginBottom,
    background: background || '#0C0C25',
  }),
)
