import styled from '@emotion/styled'

export const SidebarMonitoringWrapper = styled.div<{
  borderColor: string
  isVisible: boolean
  bgColor?: string
}>(
  {
    position: 'absolute',
    left: 0,
    width: '100%',
    bottom: 0,
    padding: '1.25rem',
    top: '104px',
    zIndex: 5,
    transition: '0.3s',
  },
  ({ borderColor, bgColor, isVisible }) => ({
    background: bgColor,
    maxWidth: isVisible ? 280 : 0,
    boxShadow: `2px 0 ${borderColor}`,
    '& div': {
      transition: '0.3s',
      opacity: isVisible ? 1 : 0,
    },
  }),
)

export const LabelSidebarMonitoringWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
})

export const LabelSidebarMonitoring = styled.div<{
  isActive?: boolean
  bgColor?: string
}>(
  {
    padding: '5px 6px',
    display: 'flex',
    cursor: 'pointer',
    gap: '0.5rem',
    borderRadius: '6px',
    width: 'max-content',
    alignItems: 'center',
    transition: '0.3s',
    justifyContent: 'flex-start',
  },
  ({ isActive, bgColor }) => ({
    ...(!isActive && {
      boxShadow: '0 0 0 1px #fff',
    }),
    background: isActive ? bgColor : 'transparent',
  }),
)
