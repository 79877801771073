/* eslint-disable @typescript-eslint/no-explicit-any */

import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { branchService } from 'services/branch.service'
import { useMapSubarea } from 'store/map-subarea.store'
import { useMapStore } from 'store/map.store'
import { MapItemType } from 'types/map/map'

import { BranchSidebar } from './components/BranchSidebar'
import { MapTabNavigation } from './components/MapTabNavigation'
import { MountMap } from './components/MountMap'
import { SubareaSidebar } from './components/SubareaSidebar'
import { UploadMap } from './components/UploadMap'

export function MountMapPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  const { sidebarVisibility, setCurrentMap, currentCompany, setMaps } =
    useMapStore()
  const { showActionsNewSubarea, resetCurrentSubarea } = useMapSubarea()

  const { data: companies } = useQuery({
    queryKey: ['list-branches'],
    queryFn: () => branchService.findListBranchesSelect(),
  })

  const { data: dataCompany } = useQuery({
    queryKey: ['company-map', currentCompany, searchParams.get('mapId')],
    queryFn: () => {
      if (currentCompany) return branchService.findCompanyById(currentCompany)

      return Promise.resolve(null)
    },
  })

  const image = dataCompany?.areas?.find(
    (area) => area.mapImage?.active === true,
  )

  const mapId = dataCompany?.areas.length ? searchParams.get('mapId') : null
  const mapIsEmpty = !dataCompany?.areas?.length
  const enableSubareaSidebar = !showActionsNewSubarea()

  useEffect(() => {
    const firstMap = dataCompany?.areas?.at(0)
    setMaps(dataCompany?.areas as MapItemType[])

    if (mapId) {
      const mapSearched =
        dataCompany &&
        dataCompany?.areas.find((area) => area.entityId.value === mapId)

      setCurrentMap(mapSearched ?? firstMap)
    } else {
      setCurrentMap(firstMap)
      setSearchParams({
        mapId: firstMap?.entityId.value ?? '',
      })
    }
  }, [dataCompany?.areas, mapId])

  useEffect(() => {
    const errorHandler = (event: any) => {
      const messages = [
        'ResizeObserver loop completed with undelivered notifications',
        'ResizeObserver loop limit exceeded',
      ]

      if (messages.some((message) => event.message?.includes?.(message))) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
      }
    }

    window.addEventListener('error', errorHandler)

    return () => {
      window.removeEventListener('error', errorHandler)
      resetCurrentSubarea()
    }
  }, [])

  if (!mapId && mapIsEmpty) {
    return (
      <Stack
        direction='row'
        flex={1}
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <BranchSidebar data={companies} />
        <UploadMap />
      </Stack>
    )
  }

  return (
    <Stack
      direction='row'
      flex={1}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {enableSubareaSidebar && <SubareaSidebar data={companies} />}

      <MapTabNavigation
        parentElementIsOpen={sidebarVisibility && !showActionsNewSubarea()}
        parentElementSize={{
          small: 40,
          large: 280,
        }}
        maps={dataCompany?.areas}
        companyId={dataCompany?.id.value}
      />

      {image ? <MountMap /> : <UploadMap />}
    </Stack>
  )
}
