import { Navigate } from 'react-router-dom'

import { useAuthStore } from 'store/auth.store'

type PublicRouteProps = {
  layout: React.ComponentType
}

const PublicRoute = ({
  layout: Layout,
}: PublicRouteProps): React.ReactElement | null => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

  if (isAuthenticated) {
    return <Navigate to='/' />
  }

  return <Layout />
}

export default PublicRoute
