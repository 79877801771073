import { ID } from 'types/id'

export type SensorType = {
  id?: ID
  name: string
  type: string
  address: string
  status: boolean
  dataUnifier: boolean
  port?: string
  unit?: string
  ip?: string
  keyUnique?: string
  mode: ModeOption
  time: number
}

export type DataRecordSensorType = {
  sensorId: number
  sensorName: string
  maxValue: number
  minValue: number
  lastValue: string
}

export type SensorOption =
  | 'WATER'
  | 'ENERGY'
  | 'GAS'
  | 'TEMPERATURE'
  | 'TEMPERATURE_HUMIDITY'
  | 'HUMIDITY'
  | 'ELECTRIC'
  | 'ELETRIC'
  | 'AIR'
  | 'TEMP'
  | 'TEMP_HUM'
  | 'HUM'
  | 'SENSOR'
  | 'OPENING'
  | 'BTN_PANIC'

export enum ModeOption {
  SENSOR = 'SENSOR',
  DATALOG = 'DATALOG',
  CONCENTRATOR = 'CONCENTRATOR',
}

export interface SensorTypeMap extends SensorType {
  creation: Date
}
