/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextFieldProps } from '@mui/material'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

import { ValidationError } from 'types/ValidationError'

import { InputMui } from './InputMui'

type Props = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  showIcon?: boolean
  helpWithIcon?: string
  errorMessage?: any | ValidationError | string
}

export function InputMacAddress({ control, ...rest }: Props) {
  const [formattedValue, setFormattedValue] = useState('')

  return (
    <>
      {control ? (
        <Controller
          name={rest?.name}
          control={control}
          defaultValue=''
          render={({ field }) => (
            <InputMui
              {...rest}
              {...field}
              onChange={(event) =>
                field.onChange(
                  event.target.value
                    ?.replace(/[^a-zA-Z0-9:]/g, '')
                    .toUpperCase(),
                )
              }
            />
          )}
        />
      ) : (
        <InputMui
          {...rest}
          value={formattedValue}
          onChange={(event) =>
            setFormattedValue(
              event.target.value?.replace(/[^a-zA-Z0-9:]/g, '').toUpperCase(),
            )
          }
        />
      )}
    </>
  )
}
