import { Grid } from '@mui/material'
import { Control, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/Input'
import { Toggle } from 'components/Toggle'
import { NotificationSettingFormType } from 'types/notification-setting'

interface IFormStepNotificationProps {
  isView?: boolean
  errors?: FieldErrors<NotificationSettingFormType>
  control: Control<NotificationSettingFormType, any>
}

export const FormStepNotification = ({
  isView,
  errors,
  control,
}: IFormStepNotificationProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={12}>
        <Input
          name='name'
          placeholder={t('input.name').toString()}
          label={t('input.name')}
          control={control}
          error={!!errors?.name}
          errorMessage={errors?.name?.message}
          fullWidth
          InputProps={{
            disabled: isView,
          }}
          required
        />
      </Grid>
    </>
  )
}
