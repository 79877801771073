import { httpClient } from 'services/api/httpClient'
import { MapDetailsType } from 'types/map/map'
import { CreateSubarea, SubareaTypeCreate } from 'types/subarea/subarea'

type TypeSubArea = Pick<SubareaTypeCreate, 'mapType'>

type ParamsSubareaById = {
  id: string
  type: TypeSubArea
}

const create = async (data: CreateSubarea) => {
  const response = await httpClient.post('/area', data)
  return response.data
}

const listSubareaById = async ({ id, type }: ParamsSubareaById) => {
  const { data } = await httpClient.get<MapDetailsType>(
    `/map-image-company/area/${id}`,
    {
      params: {
        type: type.mapType,
      },
    },
  )
  return data
}

const updateSubarea = async (id: string, data: SubareaTypeCreate) => {
  const response = await httpClient.put(`/area/${id}`, data)
  return response.data
}

const remove = async (id: string) => {
  const response = await httpClient.delete(`/area/${id}`)
  return response.data
}

export const subAreaService = {
  create,
  listSubareaById,
  updateSubarea,
  remove,
}
