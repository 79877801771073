/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@mui/material'
import { LatLngExpression } from 'leaflet'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { CheckboxGroup } from 'components/Checkbox/CheckboxGroup'
import { Input } from 'components/Input'
import { MaskedInputCep } from 'components/Input/mask/MaskedInputCep'
import { MaskedInputNumber } from 'components/Input/mask/MaskedInputNumber'
import { Map } from 'components/MapContainer'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { branchService } from 'services/branch.service'
import { statesService } from 'services/states.service'
import { viaCEPService } from 'services/viacep.service'
import { DataBranchType, ICreateBranch, IUtility } from 'types/branch/branch'
import { ModalType } from 'types/modal'
import  {useDebounced} from 'hooks/useDebounced'

import { schemaBranch } from '../validations/create-branch.validation'


interface Props extends ModalType {
  data?: DataBranchType | null
  isView?: boolean
}

export function FormBranchModal({ data, isView, onClose, open }: Props) {
  const [position, setPosition] = useState<LatLngExpression>([
    -23.5489, -46.6388,
  ])
  const [loadCep, setLoadCep] = useState(false);
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const { data: listStates } = useQuery({
    queryKey: 'list-uf',
    queryFn: () => statesService.getStates(),
    select: (data) =>
      data.states.map((state: any) => ({ id: state.sigla, name: state.sigla })) ??
      [],
  })

  const { data: listUtilities } = useQuery({
    queryKey: 'list-utilities',
    queryFn: () => branchService.listUtilities(),
    select: (data) =>
    data.items.map((utility: IUtility) => ({ id: utility.entityId.value, name: t(utility.name) })) ??
      [],
  })

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICreateBranch>({
    resolver: yupResolver(schemaBranch()),
    defaultValues: {
      name: data?.name ?? '',
      zipCode: data?.zipCode ?? '',
      streetAddress: data?.streetAddress ?? '',
      number: data?.number ?? '',
      complement: data?.additionalInfo ?? '',
      city: data?.city ?? '',
      district: data?.district ?? '',
      state: data?.state ?? '',
      latitude: data?.latitude ?? '',
      longitude: data?.longitude ?? '',
      plusCode: data?.plusCode ?? '',
      utilities: data?.utilities?.map((utility) => utility?.id) ?? [],
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('list-branches')
    onClose()
  }

  const mutationCreate = useMutation(
    (formData: ICreateBranch) => {
      formData.latitude = typeof formData.latitude  === 'string' ? Number(formData.latitude) : formData.latitude
      formData.longitude = typeof formData.longitude  === 'string' ? Number(formData.longitude) : formData.longitude
      formData.number = formData.number?.length ? formData.number : null
      formData.complement = formData.complement?.length ? formData.complement : null
      formData.plusCode = formData.plusCode?.length ? formData.plusCode : null
      formData.utilities = formData.utilities?.map((utility) => (
        {entityId: utility}
      ))

      return branchService.create(formData)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (formData: ICreateBranch) => {
      formData.latitude = typeof formData.latitude  === 'string' ? Number(formData.latitude) : formData.latitude
      formData.longitude = typeof formData.longitude  === 'string' ? Number(formData.longitude) : formData.longitude
      formData.number = formData.number?.length ? formData.number : null
      formData.complement = formData.complement?.length ? formData.complement : null
      formData.plusCode = formData.plusCode?.length ? formData.plusCode : null
      formData.utilities = formData.utilities?.map((utility) => (
        {entityId: utility}
      ))
      return branchService.update(Number(data?.id.value) ?? 0, formData)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<ICreateBranch> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }
    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('branch.view')

    if (data?.id) return t('branch.edit')

    return t('branch.new')
  }

  const title = getTitle()

  useEffect(() => {

    setValue('latitude', position.toString().split(',')[0])
    setValue('longitude', position.toString().split(',')[1])
  }, [position])

  const lat = watch('latitude')
  const lng = watch('longitude')
  const zipCode = watch('zipCode')
  const debounced = useDebounced(zipCode, 800)

  useEffect(() => {
    if (lat && lng) {
      setPosition([Number(lat), Number(lng)])
    }
  }, [data])

  const fillWithCEP = useCallback(async () => {
    try {
      const cep: string = zipCode.replace('-', '')
      if(cep.length >= 8) {
        const response = await viaCEPService.findCEP(cep)
        setValue('complement', response.complemento)
        setValue('district', response.bairro)
        setValue('city', response.localidade)
        setValue('state', response.uf)
        setValue('streetAddress', response.logradouro)
      }
    } catch (error) {
      toast.info('informações do CEP não encontradas.')
    } finally{
      setLoadCep(false)
    }
  }, [debounced])

  useEffect(() => {
    setLoadCep(true)
    fillWithCEP()
  }, [debounced, fillWithCEP])

  return (
    <>
      <Modal title={title} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12}>
            <Input
              name='name'
              label='Nome da Unidade'
              control={control}
              fullWidth
              error={!!errors?.name}
              errorMessage={errors?.name?.message}
              required
              InputProps={{
                disabled: isView
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Endereço</Typography>
          </Grid>
          <Grid item xs={4}>
            <Input
              name='zipCode'
              label='cep'
              control={control}
              fullWidth
              error={!!errors?.zipCode}
              errorMessage={errors?.zipCode?.message}
              required
              InputProps={{
                inputComponent: MaskedInputCep as any,
                disabled: isView
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name='streetAddress'
              label='logradouro'
              control={control}
              fullWidth
              error={!!errors?.streetAddress}
              errorMessage={errors?.streetAddress?.message}
              required
              InputProps={{
                disabled: isView || loadCep
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              name='number'
              label='N°'
              control={control}
              fullWidth
              error={!!errors?.number}
              errorMessage={errors?.number?.message}
              InputProps={{
                inputComponent: MaskedInputNumber as any,
                disabled: isView || loadCep
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name='complement'
              label='Complemento'
              control={control}
              fullWidth
              error={!!errors?.complement}
              errorMessage={errors?.complement?.message}
              InputProps={{
                disabled: isView || loadCep
              }}
            />
          </Grid>

          <Grid item xs={5}>
            <Input
              name='district'
              label='Bairro'
              control={control}
              fullWidth
              error={!!errors?.district}
              errorMessage={errors?.district?.message}
              required
              InputProps={{
                disabled: isView || loadCep
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              name='city'
              label='Cidade'
              control={control}
              fullWidth
              error={!!errors?.city}
              errorMessage={errors?.city?.message}
              required
              InputProps={{
                disabled: isView || loadCep
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              name='state'
              label='Estado'
              control={control}
              fullWidth
              error={!!errors?.state}
              errorMessage={errors?.state?.message}
              required
              disabled={isView || loadCep}
              options={listStates}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>Coordenadas</Typography>
          </Grid>
          <Grid item container xs={8} columnSpacing={3}>
            <Grid item xs={6}>
              <Input
                name='latitude'
                label='Latitude'
                control={control}
                fullWidth
                error={!!errors?.latitude}
                errorMessage={errors?.latitude?.message}
                required
                InputProps={{
                  disabled: isView
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name='longitude'
                label='Longitude'
                control={control}
                fullWidth
                error={!!errors?.longitude}
                errorMessage={errors?.longitude?.message}
                required
                InputProps={{
                  disabled: isView
                }}

              />
            </Grid>
            <Grid item xs={12}>
            <Input
              name='plusCode'
              label='Plus Code'
              control={control}
              fullWidth
              error={!!errors?.plusCode}
              errorMessage={errors?.plusCode?.message}
              InputProps={{
                disabled: isView
              }}
            />
          </Grid>
          </Grid>
          <Grid item xs={4}>
          <div>
              <Map.Root
                center={position}
                zoom={6}
                scrollWheelZoom={true}
                style={{ height: '150px' }}
                attributionControl={false}
                zoomControl={false}
              >
                <Map.Layer />
                <Map.Pin
                  position={position}
                  isEditMode={isView}
                  onChangeLocation={event => {
                  setPosition([event.lat, event.lng])
                }} />
                <Map.FlyToCoords
                  coords={{
                    latitude: Number(position.toString().split(',')[0]),
                    longitude: Number(position.toString().split(',')[1]),
                  }}
                  zoom={13}
                />
              </Map.Root>
            </div>
          </Grid>


          <Grid item xs={12}>
            <Typography>Utilidades</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                '& .MuiFormControl-root': {
                  width: '100% !important',

                  '& .MuiFormGroup-root': {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',

                    '& .MuiFormControlLabel-root': {
                      flex: '0 0 30%',
                      '& .MuiTypography-root': {
                        fontSize: 12,
                      },
                    },
                  },
                },
              }}
            >
              <CheckboxGroup
                name='utilities'
                control={control}
                options={listUtilities ?? []}
                error={!!errors?.utilities}
                helperText={errors?.utilities?.message}
                isDisabled={isView}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>

          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
