import { yupResolver } from '@hookform/resolvers/yup'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers'
import { StatusOrderServiceEnum } from 'core/enums/StatusOrderServiceEnum'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ActionMeta, OnChangeValue } from 'react-select'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import InputBase from 'components/Input/InputBase'
import { Loading } from 'components/Loading'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { PhotoExpand } from 'components/Photo/Expand'
import { Select } from 'components/Select'
import { Autocomplete } from 'components/Select/Autocomplete'
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter'
import { countCaracteres } from 'helpers/text.helper'
import { httpClient } from 'services/api/httpClient'
import { accept, refuse } from 'services/pre-os/updatePreOS'
import { ModalType } from 'types/modal'
import { FormPreOSType } from 'types/pre-os/form-pre-os'
import { OSType, PreOSType } from 'types/pre-os/pre-os'
import { UserSelectType, UsersType } from 'types/user/user'

import { IUpdateOrderService } from '../../../core/interfaces/order-service/IUpdateOrderService'
import { schemaClientSensor } from '../validations/create-client.validation'

interface Props extends ModalType {
  data: PreOSType | null
  isView?: boolean
}

export function FormPreOSModal({ data, isView: view, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const isView = view || data?.status?.includes('Editado')
  dayjs.extend(utc)

  const [expanded, setExpanded] = useState<string | false>('')
  const [status, setStatus] = useState<string | null>(null)
  const [selectedUsers, setSelectedUsers] = useState<UserSelectType[]>([])

  const [historic, setHistoric] = useState<string[]>([])

  const { isLoading } = useQuery(
    ['order_service_log', data?.entityId?.value],
    () => {
      return httpClient.get<OSType>(`/order-service/${data?.entityId?.value}`)
    },
    {
      onSuccess(data) {
        setHistoric(
          data?.data?.logs?.map((item) => {
            const date = dayjs(item?.creation).format('DD/MM/YYYY')
            const hour = dayjs(item?.creation).format('HH:mm')

            return `${capitalizeFirstLetter(
              t('statusLog.' + item.StatusLog.toLowerCase()),
            )} ${t('in')} ${date} ${t('at')} ${hour} ${t(
              'by',
            )} ${capitalizeFirstLetter(item?.user)}`
          }) ?? [],
        )
      },
      cacheTime: 5 * 1000,
      staleTime: 5 * 1000,
    },
  )

  const { isLoading: isLoadingUsers, data: dataUser } = useQuery(
    ['all-users'],
    () => {
      return httpClient.get<UsersType[]>(
        '/user-account/find-all-except-clients',
      )
    },
  )

  const users =
    dataUser?.data?.map((item) => ({
      value: item?.entityId?.value?.toString() ?? '',
      label: item?.name ?? '',
    })) ?? []

  const statusOS = data?.status ?? 'NEW'

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormPreOSType>({
    resolver: yupResolver(schemaClientSensor(statusOS)),
    defaultValues: {
      client: data?.user ?? '',
      nameEquipment: data?.nameEquipment ?? '',
      description: data?.description ?? '',
      serialNumber: data?.serialNumber ?? '',
      type: data?.type ?? 'CORRECTIVE',
      model: data?.model ?? 'Não definido',
      location: data?.location ?? '',
      reasonRejection: '',
      comment: data?.comment ?? '',
      priority: data?.priority ?? 'LOW',
      date: null,
      hour: null,
    },
  })

  const mutationUpdateApproved = useMutation(
    (payload: IUpdateOrderService) => {
      return accept(Number(data?.entityId?.value), payload)
    },
    {
      onSuccess: () => {
        toast.success(t('savedInformation'))
        reset()
        onClose()
        queryClient.invalidateQueries('pre-os')
      },
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )
  const mutationUpdateRecuse = useMutation(
    (payload: IUpdateOrderService) => {
      return refuse(Number(data?.entityId?.value), payload)
    },
    {
      onSuccess: () => {
        toast.success(t('savedInformation'))
        reset()
        onClose()
        queryClient.invalidateQueries('pre-os')
      },
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const orderOptions = (values: readonly UserSelectType[]) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed))
  }

  const onChange = (
    newValue: OnChangeValue<UserSelectType, true>,
    actionMeta: ActionMeta<UserSelectType>,
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        newValue = []
        break
    }

    setSelectedUsers(orderOptions(newValue))
  }
  const arrangeValues = (value: FormPreOSType) => {
    let date = ''
    if (status === 'ACCEPTED') {
      value.reasonRejection = ''
      const dateFormatted = dayjs(value.date).format('YYYY-MM-DD')
      const hourFormatted = dayjs(value.hour).format('HH:mm')
      date = dayjs(`${dateFormatted} ${hourFormatted}`).utc().format()
    }
    const orderService: IUpdateOrderService = {
      name: value.name,
      nameEquipment: value.nameEquipment,
      serialNumber: value.serialNumber,
      type: String(value.type),
      description: value.description ?? '',
      location: value.location,
      comment: value.reasonRejection,
      model: value.model,
      priority: String(value.priority),
      dateOccurrence: date,
      attendants: selectedUsers.map((item) => item.value),
    }

    return orderService
  }
  const onSubmit: SubmitHandler<FormPreOSType> = (formData) => {
    if (status === 'ACCEPTED') {
      mutationUpdateApproved.mutate(arrangeValues(formData))
    } else if (status === 'REFUSED') {
      mutationUpdateRecuse.mutate(arrangeValues(formData))
    }
  }

  const title = data?.entityId?.value ? t('preOS.edit') : t('preOS.new')
  return (
    <Modal
      title={isView ? String(t('preOS.view')) : title}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid item xs={12}>
          <Input
            name='client'
            label={t('input.requester') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.client}
            errorMessage={errors?.client?.message}
            required
            isDisabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='nameEquipment'
            label={t('input.equipment') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.nameEquipment}
            errorMessage={errors?.nameEquipment?.message}
            required
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='model'
            label={t('input.model') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.model}
            errorMessage={errors?.model?.message}
            required
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='serialNumber'
            label={t('input.serialNumber') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.serialNumber}
            errorMessage={errors?.serialNumber?.message}
            required
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name='type'
            label={t('input.type')}
            options={[
              {
                id: 'PREVENTIVE',
                name: 'Preventiva',
              },
              {
                id: 'CORRECTIVE',
                name: 'Corretiva',
              },
            ]}
            control={control}
            fullWidth
            error={!!errors?.type}
            errorMessage={errors?.type?.message}
            required
            disabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='location'
            label={t('input.localization') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.location}
            errorMessage={errors?.location?.message}
            required
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhotoExpand url='' name='Foto.png' />
        </Grid>
        <Grid item xs={12}>
          <Input
            name='description'
            label={t('input.description') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.description}
            errorMessage={errors?.description?.message}
            required
            multiline
            minRows={3}
            maxRows={6}
            isDisabled={isView}
            helperText={
              <Box
                component={'span'}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>Maximo de caracteres: 250</span>
                <span>
                  {countCaracteres(String(watch('description')), 250) ?? 0}
                  /250
                </span>
              </Box>
            }
          />
        </Grid>
        {isView && data?.status === StatusOrderServiceEnum.NOT_ACCEPTED && (
          <Grid item xs={12}>
            <Input
              name='comment'
              label={t('input.reasonRejection') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.comment}
              errorMessage={errors?.comment?.message}
              required
              multiline
              minRows={3}
              maxRows={6}
              isDisabled={isView}
              // helperText={
              //   <Box
              //     component={'span'}
              //     sx={{ display: 'flex', justifyContent: 'space-between' }}
              //   >
              //     <span>Maximo de caracteres: 250</span>
              //     <span>
              //       {countCaracteres(String(watch('comment')), 250) ?? 0}
              //       /250
              //     </span>
              //   </Box>
              // }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack
            spacing={1}
            sx={{
              width: '100%',
              background: (theme) => theme.palette.secondary.main,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              paddingX: 1,
              '& .MuiPaper-root': {
                background: (theme) =>
                  `${theme.palette.secondary.main} !important`,
              },
            }}
          >
            {isLoading && <Loading />}
            {!isLoading && (
              <Accordion
                expanded={expanded === 'historic'}
                sx={{
                  minHeight: 18,
                  background: (theme) =>
                    `${theme.palette.secondary.main} !important`,
                  boxShadow: 'none',
                  marginBottom: '0px !important',
                  padding: 0,
                  border: 'none !important',
                  '&::before': {
                    display: 'none',
                  },
                  width: '100%',
                }}
              >
                <AccordionSummary
                  aria-controls={'1-content'}
                  id={'1-header'}
                  onClick={() => setExpanded(expanded ? false : 'historic')}
                  sx={{
                    padding: 0,
                    minHeight: '18px !important',
                    background: (theme) => theme.palette.secondary.main,
                    width: '100%',
                    '& > div': {
                      marginBottom: '0px !important',
                      marginTop: '0px !important',
                    },
                    '&.Mui-expanded': {
                      minHeight: '18px !important',
                      marginBottom: '0px !important',
                      '& > div': {
                        marginBottom: 1,
                      },
                    },
                  }}
                >
                  <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{
                      width: '100%',
                      background: (theme) => theme.palette.secondary.main,
                      borderRadius: 1,
                      display: 'flex',
                      alignItems: 'center',
                      paddingX: 1,
                      minHeight: 44,
                    }}
                  >
                    <Typography
                      sx={{
                        width: '100%',
                      }}
                    >
                      {t('historic')}
                    </Typography>
                    <Icon
                      name={
                        expanded === 'historic' ? 'expandLess' : 'expandMore'
                      }
                    />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingX: 1,
                    background: 'transparent !important',
                    borderTop: (theme) =>
                      `1px solid ${theme.palette.secondary.light}`,
                  }}
                >
                  <Stack
                    spacing={1}
                    maxHeight={100}
                    sx={{
                      marginTop: 1,
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: 6,
                        height: 6,
                        background: 'transparent',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: (theme) => theme.palette.secondary.light,
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: (theme) => theme.palette.background.default,
                      },
                    }}
                  >
                    <Grid container spacing={1}>
                      {historic?.length <= 0 && (
                        <Grid item xs={12}>
                          <Typography variant='body2'>
                            {t('noHistoricFound')}
                          </Typography>
                        </Grid>
                      )}

                      {historic?.map((item, index) => (
                        <Grid item xs={12} key={index}>
                          <Typography variant='body2'>{item}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )}
          </Stack>
        </Grid>

        {!isView && data?.status === StatusOrderServiceEnum.NEW && (
          <Grid item xs={12}>
            <Stack
              direction={'row'}
              justifyContent={['space-between', 'flex-start']}
              spacing={2}
            >
              <Button
                startIcon={
                  <Box color='success.main'>
                    <Icon name='check' />
                  </Box>
                }
                variant='outlined'
                color='success'
                size='medium'
                onClick={() => setStatus('ACCEPTED')}
              >
                {t('button.title.approve')}
              </Button>
              <Button
                startIcon={
                  <Box color='error.main'>
                    <Icon name='close' />
                  </Box>
                }
                variant='outlined'
                color='error'
                size='medium'
                onClick={() => setStatus('REFUSED')}
              >
                {t('button.title.recuse')}
              </Button>
            </Stack>
          </Grid>
        )}

        {!isView && status === 'REFUSED' && (
          <Grid item xs={12}>
            <Input
              name='reasonRejection'
              label={t('input.reasonRejection') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.reasonRejection}
              errorMessage={errors?.reasonRejection?.message}
              multiline
              minRows={3}
              maxRows={6}
              required
              helperText={
                <Box
                  component={'span'}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>Maximo de caracteres: 650</span>
                  <span>
                    {countCaracteres(String(watch('reasonRejection')), 650) ??
                      0}
                    /650
                  </span>
                </Box>
              }
            />
          </Grid>
        )}
        {!isView && status === 'ACCEPTED' && (
          <>
            <Grid item xs={12} sm={6}>
              <Input
                name='name'
                label={t('input.nameOS')}
                control={control}
                fullWidth
                error={!!errors?.name}
                errorMessage={errors?.name?.message}
                required
                disabled={isView}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                name='priority'
                label={t('input.priority')}
                options={[
                  {
                    id: 'LOW',
                    name: 'Baixa prioridade',
                  },
                  {
                    id: 'HIGH',
                    name: 'Alta prioridade',
                  },
                ]}
                control={control}
                fullWidth
                error={!!errors?.priority}
                errorMessage={errors?.priority?.message}
                required
                disabled={isView}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                name='attendants'
                placeholder={t('input.select') ?? ''}
                label={t('input.associate') ?? ''}
                closeMenuOnSelect={false}
                isLoading={isLoadingUsers}
                options={users}
                isMulti
                value={selectedUsers}
                isClearable
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(newValue: any, actionMeta: any) =>
                  onChange(newValue, actionMeta)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DesktopDatePicker
                label={t('input.initiationDate')}
                views={['day']}
                value={watch('date')}
                onChange={(date) => setValue('date', date ?? '')}
                disabled={isView}
                renderInput={(params) => (
                  <InputBase fullWidth {...params} required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DesktopTimePicker
                label={t('input.hour')}
                views={['hours', 'minutes']}
                value={watch('hour')}
                onChange={(hour) => setValue('hour', hour ?? '')}
                disabled={isView}
                renderInput={(params) => (
                  <InputBase fullWidth {...params} required />
                )}
              />
            </Grid>
          </>
        )}

        {!isView && (
          <Grid item xs={12}>
            <ModalAction
              onCancel={onClose}
              isSubmit
              isLoading={
                mutationUpdateApproved.isLoading ||
                mutationUpdateRecuse.isLoading
              }
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
