import { GraphicQuantityProducedType } from 'types/goalAndParameter/dashboard/graphic-quantity-procuced'
import { GraphicConsumptionGeneralType } from 'types/invoice/dashboard/energy/graphic-consumption-general'
const getConsumedEnergyFromProduced = (
  energy: GraphicConsumptionGeneralType[],
  produced: GraphicQuantityProducedType[],
) => {
  // gera pelo calulo de energia consumida / quantidade produzida = custo da energia
  const energyCost = new Array(12).fill(0)
  // consumo de energia
  const consumed = new Array(12).fill(0)
  // quantidade produzida
  const quantityProduced = new Array(12).fill(0)
  energy.map((item) => {
    consumed[Number(item.month) - 1] = Number(item.consumption)
  })
  produced.map((item) => {
    quantityProduced[Number(item.month) - 1] = Number(item.produced)
  })
  consumed.map((item, index) => {
    energyCost[index] =
      quantityProduced[index] > 0 ? item / quantityProduced[index] : 0
  })
  return {
    energyCost,
    quantityProduced,
  }
}

export { getConsumedEnergyFromProduced }
