import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { ActiveVariableEnum } from 'core/enums/ActiveVariableEnum'
import { FC, useCallback } from 'react'

import EquipmentIcon from 'assets/icons/equipment.svg'
import SingleIcon from 'assets/icons/icon-single-registration.svg'
import EquipmentLightIcon from 'assets/icons/light/equipment-light.svg'
import SingleLightIcon from 'assets/icons/light/icon-single-registration-light.svg'
import { IOSSwitch } from 'components/Toggle'

import { MonitoringSidebarCard } from '../MonitoringSidebarCard'

type Props = {
  activeVariable: ActiveVariableEnum
  toggleActiveVariable(): void
  checkActiveVariable(value: ActiveVariableEnum): boolean
}

export const VariableCard: FC<Props> = ({
  activeVariable,
  checkActiveVariable,
  toggleActiveVariable,
}) => {
  const theme = useTheme()
  const isDark = theme.palette.mode === 'dark'

  const generateStyle = useCallback(
    (value: ActiveVariableEnum) => {
      const isActive = checkActiveVariable(value)
      return {
        sx: {
          wordBreak: 'break-all',
          ...(isActive && {
            textDecoration: 'underline',
          }),
        },
        fontSize: '10px',
        fontWeight: isActive ? 700 : 500,
      }
    },
    [activeVariable],
  )

  const stylesEquipment = generateStyle(ActiveVariableEnum.EQUIPMENT)
  const stylesAirConditioning = generateStyle(
    ActiveVariableEnum.AIR_CONDITIONING,
  )

  return (
    <MonitoringSidebarCard
      title='Variável'
      innerApendRight={
        <Tooltip
          title={
            'Alterne entre Equipamentos/Sensores e Ar condicionado/sensores para melhor posicionar esses equipamentos e organiza-los em tela para futuro monitoramento'
          }
          arrow
          placement='bottom'
        >
          <InfoOutlinedIcon />
        </Tooltip>
      }
    >
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        gap={'0.5rem'}
        justifyContent={'space-between'}
      >
        <Typography {...stylesEquipment}>Equipamentos e sensores</Typography>
        <IOSSwitch
          value={'equipment'}
          checked={activeVariable === ActiveVariableEnum.AIR_CONDITIONING}
          onClick={toggleActiveVariable}
          inputProps={{ 'aria-label': 'Equipment' }}
        />
        <Typography {...stylesAirConditioning}>
          Ar condicionado e sensores
        </Typography>
      </Stack>
      <img
        src={
          checkActiveVariable(ActiveVariableEnum.EQUIPMENT)
            ? isDark
              ? EquipmentIcon
              : EquipmentLightIcon
            : isDark
            ? SingleIcon
            : SingleLightIcon
        }
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '10px',
          display: 'flex',
        }}
        height={115}
        width={154}
      />
    </MonitoringSidebarCard>
  )
}
