import { ButtonProps } from '@mui/material'

import { Button } from 'components/Button'

interface MyAccountActionProps extends Omit<ButtonProps, 'title'> {
  title: string | null
  // icon: React.ElementType
  icon?: React.ReactNode
}

export function MyAccountAction({
  title,
  icon,
  // icon: Icon,
  ...rest
}: MyAccountActionProps) {
  return (
    <Button
      variant='outlined'
      startIcon={icon}
      // startIcon={<Icon name={iconName} />
      {...rest}
    >
      {title}
    </Button>
  )
}
