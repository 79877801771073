import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Photo } from 'components/Photo'
import { Status } from 'components/Status'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'

import { IOrderService } from '../../../core/interfaces/order-service/IOrderService'

type Type = 'corrective' | 'preventive'

export function clientsMapper(clients: IOrderService[]) {
  const { t } = useTranslation('common')
  return clients.map((item) => ({
    ...item,
    photo: item?.file?.name ? (
      <Photo src={`${BASE_URL_IMAGES.concat(item?.file?.name)}`} />
    ) : null,
    nameEquipment: item.nameEquipment.toUpperCase(),
    date: moment(item.dateOccurrence).format('DD/MM/YYYY HH:mm'),
    shortDescription:
      item.description?.length > 60
        ? `${item.description.slice(0, 60)}...`
        : item.description,
    status: <Status type={item.status} />,
    typeFormatted: t(item.type?.toLowerCase() as Type),
  }))
}
