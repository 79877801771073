/* eslint-disable quotes */
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import { ArcElement, Legend, RadialLinearScale, Tooltip } from 'chart.js'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { Actions } from 'components/Actions'
import { httpClientDownloadExcel } from 'helpers/httpClientDownloadExcel'
import { useWaterVaseChart } from 'pages/dashboard/hooks/water/useWaterVaseChart'

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

interface Props {
  isPreview?: boolean
}

export function WaterVaseChart(props: Props) {
  const { isPreview } = props

  const {
    chartData,
    chartRef,
    options,
    t,
    setGeneratingFile,
    generatingFile,
    data,
    bgColors,
  } = useWaterVaseChart()

  return (
    <Box
      sx={{
        gridArea: 'top-right',
        borderRadius: 1,
        width: '100%',
        height: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: isPreview ? 'auto' : 200,
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        position: 'relative',
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>

      <Stack
        direction={['row']}
        justifyContent={['space-between']}
        alignItems={'flex-start'}
        marginBottom={3}
      >
        <Typography variant='h2' fontSize='1.125rem' fontWeight='bold'>
          {t('chart.water.waterPressure')} ({t('lastHour')})
        </Typography>

        {!isPreview && (
          <Actions
            color='inherit'
            title='button.title.export'
            options={[
              {
                type: 'excel',
                action: () =>
                  httpClientDownloadExcel({
                    url: '/dashboard-water/water-vase/export-excel',
                    fileName: 'agua-vazo-da-agua',
                    setGeneratingFile,
                  }),
              },
            ]}
          />
        )}
      </Stack>

      <Box
        sx={{
          marginX: 'auto',
          maxHeight: isPreview ? 200 : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ maxHeight: isPreview ? 200 : 'auto' }}>
          <Chart
            type='polarArea'
            ref={chartRef}
            options={options}
            data={chartData}
            height={120}
          />
        </Stack>
        {!!data?.length && !isPreview && (
          <Stack marginLeft={2} spacing={2} textAlign={'left'}>
            <Typography variant='h2' fontSize='1.125rem' fontWeight='bold'>
              {t('areaOfMeasurement')}
            </Typography>

            <Stack spacing={1}>
              {data?.map((item, index) => (
                <Stack
                  key={index}
                  spacing={1}
                  direction={'row'}
                  alignItems={'center'}
                >
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: 1,
                      background: `linear-gradient(90deg, ${bgColors[index].start} 0%, ${bgColors[index].end} 100%)`,
                    }}
                  />
                  <Typography variant='body2'>{item.area}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  )
}
