import { IconButton } from 'components/Button/IconButton'

export function ClienteContent() {
  return (
    <>
      <p>
        Nesse módulo, são realizados os cadastros das OS realizados pelos
        clientes de fábrica. Vale ressaltar que o termo “cliente” é utilizado
        aqui como funcionários da Foxconn que a equipe de facilites atendem. Os
        dados desse submódulo serão enviados para Pré OS (Manutenção {'->'} Pré
        OS) e, caso de aceite, será enviado para o calendário de manutenção
        (Manutenção {'->'} Calendário de Manutenção).
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>

      <p>
        <strong>Cadastrar Clientes</strong>
      </p>

      <p>Para realizar o cadastro de uma nova OS feita pelo cliente, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Clientes
      </p>
      <p>
        <strong>Passo 3:</strong> Clicar no botão “+ Nova OS” .
      </p>
      <p>
        <strong>Passo 4:</strong> Preencher os campos que são: solicitante,
        equipamento, modelo, número de série, tipo de OS, localização do
        ocorrido, upload de foto e a descrição do problema.
      </p>
      <p>
        <strong>Passo 5:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        Importante: Após o envio da solicitação de OS, esta será enviada para os
        funcionários de facilites, onde vão aprovar ou rejeitar o pedido pelo
        módulo de Manutenção, em Pré OS. Depois da resposta, o status na
        listagem de solicitações informará com as escritas “aprovado”,
        “rejeitado”, “novo” ou “em andamento”.
      </p>
      <p>
        <strong>Editar Clientes</strong>
      </p>
      <p>Para realizar a edição um chamado de OS pelo cliente, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Cliente
      </p>
      <p>
        <strong>Passo 3:</strong> Selecionar a OS que vai editar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 5:</strong> Edite os campos que aparecerão: solicitante,
        equipamento, modelo, número de série, tipo de OS, localização do
        ocorrido, upload de foto e a descrição do problema.
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        <strong>Excluir Clientes</strong>
      </p>

      <p>Para realizar a exclusão de um chamado de OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Clientes
      </p>
      <p>
        <strong>Passo 3:</strong> Selecionar a OS que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a OS será permanentemente excluído do
        sistema.
      </p>
      <p>
        <strong>Visualizar Clientes</strong>
      </p>
      <p>Para realizar visualizar os dados da OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Clientes
      </p>
      <p>
        <strong>Passo 3:</strong> Selecionar a OS que vai visualizar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, sendo eles: solicitante, equipamento, modelo, número de
        série, tipo de OS, localização do ocorrido, upload de foto e a descrição
        do problema.
      </p>
    </>
  )
}
