import { useEffect } from 'react'
import { useMap } from 'react-leaflet'

interface MapFlyToCoordsProps {
  coords: {
    latitude: number
    longitude: number
  }
  zoom?: number
}

export function FlyToLocation({ coords, zoom }: MapFlyToCoordsProps) {
  const map = useMap()

  useEffect(() => {
    if (coords) {
      map.flyTo([coords.latitude, coords.longitude], zoom)
    }
  }, [coords, zoom, map])

  return null
}
