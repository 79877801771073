import {
  Stack,
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Avatar,
  Typography,
} from '@mui/material'
import MuiTableCell from '@mui/material/TableCell'
import { Fragment } from 'react'
import { useQuery } from 'react-query'

import { Actions } from 'components/Actions'
import { HeaderAction } from 'components/HeaderAction'
import { Toggle } from 'components/Toggle'
import { addAlpha } from 'helpers/addAlpha'
import { levelMapper } from 'pages/setting/mappers/level.mapper'
import { getLevel } from 'services/level/getLevel'
import { ScalabilityType } from 'types/scalability/scalability'

import { FormLevelModal } from './components/FormLevelModal'
import { FormScalabilityModal } from './components/FormScalabilityModal'
import { useScalability } from './hooks/useScalability'
import { TableCell, TableContainer } from './styles/table.styles'

export function ScalabilityPage() {
  const {
    t,
    setSearch,
    scalability,
    handleCreate,
    handleCreateLevel,
    setOpenFormScalability,
    openFormScalability,
    openFormLevel,
    setOpenFormLevel,
    handleToggleStatus,
    selectScalability,
    handleEdit,
  } = useScalability()
  const { data } = useQuery({
    queryKey: 'all-level',
    queryFn: () => getLevel(),
  })
  const levels = levelMapper(data?.results ?? [])
  return (
    <>
      <HeaderAction
        onSearch={(search) => setSearch(search)}
        buttons={[
          {
            title: t('button.title.new'),
            iconName: 'add',
            onClick: handleCreate,
          },
          {
            title: t('button.title.newLevels'),
            iconName: 'add',
            onClick: handleCreateLevel,
          },
        ]}
      />

      <TableContainer>
        <MuiTable stickyHeader aria-label='sticky table'>
          <TableBody>
            {scalability?.results?.map((item: ScalabilityType, index) => (
              <Fragment key={index}>
                <TableRow>
                  <TableCell
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {item?.level?.name}
                  </TableCell>
                  <TableCell align='right'>
                    <Actions
                      color='inherit'
                      options={[
                        {
                          type: 'edit',
                          action: () => handleEdit(item),
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <MuiTableCell
                    sx={{
                      padding: 0,
                      paddingTop: '0px',
                      background: 'transparent',
                    }}
                    colSpan={2}
                  >
                    {!!item?.scalabilities?.length && (
                      <MuiTable>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: (theme) =>
                                  `1px solid ${addAlpha(
                                    theme.palette.secondary.light,
                                    0.6,
                                  )}`,
                                verticalAlign: 'top',
                              }}
                            >
                              {t('table.scalability.user')}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: (theme) =>
                                  `1px solid ${addAlpha(
                                    theme.palette.secondary.light,
                                    0.6,
                                  )}`,
                                verticalAlign: 'top',
                              }}
                            >
                              {t('table.scalability.profile')}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: (theme) =>
                                  `1px solid ${addAlpha(
                                    theme.palette.secondary.light,
                                    0.6,
                                  )}`,
                                verticalAlign: 'top',
                              }}
                            >
                              {t('table.scalability.email')}
                            </TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item.scalabilities.map((scalability, index) => (
                            <TableRow
                              hover
                              key={`${index}-${scalability.id.value}`}
                            >
                              <MuiTableCell>
                                <Stack
                                  alignItems={'center'}
                                  direction={'row'}
                                  spacing={2}
                                >
                                  <Avatar />
                                  <Typography>
                                    {scalability.user.name}
                                  </Typography>
                                </Stack>
                              </MuiTableCell>
                              <MuiTableCell>{'Admninistrador'}</MuiTableCell>
                              <MuiTableCell>
                                {scalability.user.email}
                              </MuiTableCell>
                              <MuiTableCell>
                                <Toggle
                                  name='status'
                                  checked={scalability.active}
                                  onChange={() =>
                                    handleToggleStatus(scalability)
                                  }
                                />
                              </MuiTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </MuiTable>
                    )}
                  </MuiTableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {openFormScalability && (
        <FormScalabilityModal
          data={selectScalability}
          open={openFormScalability}
          onClose={() => {
            setOpenFormScalability(false)
          }}
        />
      )}

      {openFormLevel && (
        <FormLevelModal
          data={levels}
          open={openFormLevel}
          onClose={() => setOpenFormLevel(false)}
        />
      )}
    </>
  )
}
