import {
  FormControl,
  FormHelperText,
  Theme,
  Typography,
  alpha,
} from '@mui/material'
import { useMemo } from 'react'
import Select, { StylesConfig } from 'react-select'
import makeAnimated from 'react-select/animated'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'

import { ErrorMessage } from 'components/Input/ErrorMessage'
import { ValidationError } from 'types/ValidationError'

import { useCustomTheme } from '../../../hooks/useCustomTheme'

const animatedComponents = makeAnimated()

interface Options {
  value: string | number
  label: string
  [x: string]: string | number | object
}

interface Props extends StateManagerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  label?: string
  helperText?: string
  error?: boolean
  options: Options[]
  errorMessage?: ValidationError | string
}

const getColorStyles = ({
  theme,
  error,
}: {
  theme: Theme
  error?: boolean
}): StylesConfig => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderWidth: 2,
    minHeight: 44,
    borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    ':hover': {
      backgroundColor: theme.palette.secondary.light,
      borderColor: error
        ? theme.palette.error.main
        : theme.palette.primary.main,
    },
  }),
  menu: (styles) => ({
    ...styles,
    background: theme.palette.background.paper,
    zIndex: 2,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? 'transparent'
        : isSelected
        ? theme.palette.background.default
        : isFocused
        ? theme.palette.secondary.light
        : 'transparent',
      color: isDisabled
        ? '#555567'
        : isSelected
        ? theme.palette.mode === 'dark'
          ? '#FFF'
          : '#000'
        : theme.palette.mode === 'dark'
        ? '#FFF'
        : '#000',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? theme.palette.background.default
            : 'transparent'
          : undefined,
        color: isDisabled
          ? undefined
          : isSelected
          ? theme.palette.mode === 'dark'
            ? '#FFF'
            : '#000'
          : theme.palette.mode === 'dark'
          ? '#FFF'
          : '#000',
      },
    }
  },
  input: (styles) => ({
    ...styles,
    color: theme.palette.secondary.contrastText,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: alpha(theme.palette.secondary.contrastText, 0.8),
  }),
  singleValue: (styles) => ({
    ...styles,
    color: theme.palette.secondary.contrastText,
  }),
  multiValue: (styles) => ({
    ...styles,
    background: theme.palette.background.paper,
    '> div': {
      color: theme.palette.secondary.contrastText,
    },
  }),
})

export function AutocompleteLabel({
  options,
  helperText,
  errorMessage,
  label,
  error,
  ...rest
}: Readonly<Props>) {
  const { theme } = useCustomTheme()

  const colorStyles = useMemo(
    () => getColorStyles({ theme, error }),
    [theme, error],
  )

  return (
    <FormControl
      sx={{
        minWidth: '100%',
        position: 'relative',

        '& .MuiTypography-root': {
          position: 'absolute',
          top: '-8px',
          left: '16px',
          zIndex: 1,
          fontSize: '10px',
          backgroundColor: theme.palette.background.default,
          padding: '0 4px',
          display: 'none',
        },

        '&:not(:empty) .MuiTypography-root': {
          display: 'block',
          backgroundColor: theme.palette.background.default,
          padding: '0 4px',
          color: 'white',
          transition: 'all 0.2s ease', // Adiciona uma transição suave
        },
      }}
      error={error}
    >
      {label && <Typography>{label}</Typography>}
      <Select
        closeMenuOnSelect
        components={animatedComponents}
        // defaultValue={[]}
        isMulti={false}
        options={options}
        styles={colorStyles}
        {...rest}
      />
      {(error || helperText) && (
        <FormHelperText>
          {errorMessage ? <ErrorMessage error={errorMessage} /> : helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
