export function priceConvertStringToNumber(
  value?: string | number,
): number | string {
  if (!value) return ''

  const v = String(value)
  // Remove o ponto de milhar (caso exista) e substitui a vírgula por um ponto decimal
  const numberFormat = v.replace(/\./g, '').replace(',', '.')

  // Converte a string formatada para um número
  const number = parseFloat(numberFormat)

  return number
}
