import * as yup from 'yup'

import { EquipmentTypeEnum } from '../../../core/enums/EquipmentTypeEnum'

const NOTALLOWEDCOMMANDSPACEORDOT = 'validations.notAllowedCommaSpaceOrDot'
const INVALIDIP = 'validations.invalidIP'
const INVALIDMAC = 'validations.invalidMAC'
export const schemaCreateEquipment = () =>
  yup.object({
    name: yup
      .string()
      .trim()
      .max(50)
      .matches(/^[^.,]+$/, NOTALLOWEDCOMMANDSPACEORDOT)
      .required(),
    code: yup
      .string()
      .trim()
      .max(50)
      .matches(/^[^.,]+$/, NOTALLOWEDCOMMANDSPACEORDOT)
      .required(),
    model: yup
      .string()
      .trim()
      .max(50)
      .matches(/^[^.,]+$/, NOTALLOWEDCOMMANDSPACEORDOT)
      .required(),
    brand: yup
      .string()
      .trim()
      .max(50)
      .matches(/^[^.,]+$/, NOTALLOWEDCOMMANDSPACEORDOT)
      .required(),
    feeder: yup.boolean().required(),
    gps: yup.boolean().required(),
    mobile: yup.boolean().required(),
    type: yup.string().required(),
    specifications: yup.array().when('type', {
      is: (type: string) => {
        return type !== EquipmentTypeEnum.BTN_PANIC
      },
      then: yup.array().of(
        yup.object().shape({
          key: yup
            .string()
            .trim()
            .max(30)
            .matches(/^[^.,]+$/, NOTALLOWEDCOMMANDSPACEORDOT)
            .required(),
          value: yup
            .string()
            .trim()
            .max(50)
            .matches(/^[^.,]+$/, NOTALLOWEDCOMMANDSPACEORDOT)
            .required(),
        }),
      ),
    }),
    procedures: yup.string().when('type', {
      is: (type: string) => {
        return type === EquipmentTypeEnum.BTN_PANIC
      },
      then: yup.string().required(),
    }),
    alertType: yup.string().when('type', {
      is: (type: string) => {
        return type === EquipmentTypeEnum.BTN_PANIC
      },
      then: yup.string().required(),
    }),
    cameras: yup.array().of(
      yup.object().shape({
        ip: yup
          .string()
          .matches(
            /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            INVALIDIP,
          )
          .nullable(),
        mac: yup
          .string()
          .matches(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, INVALIDMAC)
          .nullable(),
      }),
    ),
    sensors: yup.array().when('type', {
      is: (type: string) => {
        return type === EquipmentTypeEnum.BTN_PANIC
      },
      then: yup
        .array()
        .of(
          yup.object().shape({
            sensor: yup.string().trim().required(),
          }),
        )
        .test('sensors', 'validations.required', (value) => {
          if (!value) return false
          return value.every((item) => item.sensor && item.sensor !== '')
        }),
    }),
  })

export const schemaCreateEquipmentConfigurationPartial = () => {
  return yup.object({
    name: yup.string().required(),
    code: yup.string().required(),
    model: yup.string().required(),
    brand: yup.string().required(),
    topic: yup.string().required(),
  })
}

export const schemaCreateEquipmentCommands = () => {
  return yup.object().shape({
    groupController: yup.array().of(
      yup.object().shape({
        commands: yup.array().of(
          yup.object().shape({
            mode: yup.string().required(),
            fan: yup.string().required(),
            temperature: yup.number().required(),
            command: yup.string().required(),
          }),
        ),
      }),
    ),
  })
}
