import {
  AccessTime,
  Add,
  ArrowBack,
  Brightness7,
  Cached,
  CameraAltOutlined,
  Check,
  ChevronLeft,
  ChevronRight,
  Close,
  DarkMode,
  ErrorOutline,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  GridView,
  Help,
  HourglassEmpty,
  LockOpenOutlined,
  LockOutlined,
  Logout,
  Menu,
  MoreHoriz,
  MoreVert,
  Notifications,
  NotificationsNone,
  PersonOutline,
  PhotoOutlined,
  PictureAsPdf,
  RadioButtonUnchecked,
  Remove,
  RemoveOutlined,
  Sensors,
  SettingsOutlined,
  Star,
  StarOutline,
  Timelapse,
  TuneOutlined,
  WarningAmber,
  PlaceOutlined,
  QueryBuilderOutlined,
  LocationOn,
  Map,
  GridViewOutlined,
  Visibility,
  VisibilityOff,
  InfoOutlined,
} from '@mui/icons-material'

import { ReactComponent as OpeningIcon } from 'assets/icons/equipment-icons/abertura.svg'
import { ReactComponent as AirConditioningIcon } from 'assets/icons/equipment-icons/air-conditioning.svg'
import { ReactComponent as BtnPanicIcon } from 'assets/icons/equipment-icons/botao-de-panico.svg'
import { ReactComponent as CompressorIcon } from 'assets/icons/equipment-icons/compressor.svg'
import { ReactComponent as CondenserIcon } from 'assets/icons/equipment-icons/condenser.svg'
import { ReactComponent as DehumidifierIcon } from 'assets/icons/equipment-icons/desumidificador.svg'
import { ReactComponent as DryboxIcon } from 'assets/icons/equipment-icons/drybox.svg'
import { ReactComponent as ExhaustIcon } from 'assets/icons/equipment-icons/exaustor.svg'
import { ReactComponent as FuelTankIcon } from 'assets/icons/equipment-icons/fuel-tank.svg'
import { ReactComponent as GeneratorIcon } from 'assets/icons/equipment-icons/gerador.svg'
import { ReactComponent as NitrogenPlantIcon } from 'assets/icons/equipment-icons/nitrogen-plant.svg'
import { ReactComponent as NobreakIcon } from 'assets/icons/equipment-icons/nobreak.svg'
import { ReactComponent as OtherIcon } from 'assets/icons/equipment-icons/other.svg'
import { ReactComponent as SplitIcon } from 'assets/icons/equipment-icons/split.svg'
import { ReactComponent as WaterTankIcon } from 'assets/icons/equipment-icons/water-tank.svg'

import { ReactComponent as WaterSvg } from '../../assets/icons/agua.svg'
import { ReactComponent as timeOpeningDoorSvg } from '../../assets/icons/bx_door-open.svg'
import { ReactComponent as ClosedDoor } from '../../assets/icons/closed-door.svg'
import { ReactComponent as DeleteSvg } from '../../assets/icons/delete.svg'
import { ReactComponent as DownloadSvg } from '../../assets/icons/download.svg'
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg'
import { ReactComponent as EnergySvg } from '../../assets/icons/energia.svg'
import { ReactComponent as ExcelSvg } from '../../assets/icons/excel.svg'
import { ReactComponent as GasSvg } from '../../assets/icons/gas.svg'
import { ReactComponent as HiddenSvg } from '../../assets/icons/hidden.svg'
import { ReactComponent as AreaIcon } from '../../assets/icons/listsensors.svg'
import { ReactComponent as TimeLineSvg } from '../../assets/icons/maps/icons-modal/timeline.svg'
import { ReactComponent as OpenDoorSvg } from '../../assets/icons/open-door.svg'
import { ReactComponent as PresentationSvg } from '../../assets/icons/presentation.svg'
import { ReactComponent as TemperatureSvg } from '../../assets/icons/temperaturaIcon.svg'
import { ReactComponent as TemperatureHumiditySvg } from '../../assets/icons/temperaturaUmidade.svg'
import { ReactComponent as HumiditySvg } from '../../assets/icons/umidade.svg'
import { ReactComponent as VisibilitySvg } from '../../assets/icons/visibility.svg'
import { IconSvg } from '../SvgIcon'

const icons = {
  timeOpeningDoor: <IconSvg component={timeOpeningDoorSvg} fontSize='small' />,
  timeLineSvg: (
    <IconSvg
      component={TimeLineSvg}
      sx={{ width: '50px', display: 'flex', alignItems: 'center' }}
    />
  ),
  openingIconSVG: <IconSvg component={OpeningIcon} fontSize='small' />,
  airConditioningSVG: (
    <IconSvg component={AirConditioningIcon} fontSize='small' />
  ),
  btnPanicSVG: <IconSvg component={BtnPanicIcon} fontSize='small' />,
  compressorSVG: <IconSvg component={CompressorIcon} fontSize='small' />,
  condenserSVG: <IconSvg component={CondenserIcon} fontSize='small' />,
  dehumidifierSVG: <IconSvg component={DehumidifierIcon} fontSize='small' />,
  dryBoxSVG: <IconSvg component={DryboxIcon} fontSize='small' />,
  exhaustSVG: <IconSvg component={ExhaustIcon} fontSize='small' />,
  fuelTankSVG: <IconSvg component={FuelTankIcon} fontSize='small' />,
  generatorSVG: <IconSvg component={GeneratorIcon} fontSize='small' />,
  nitrogenPlantSVG: <IconSvg component={NitrogenPlantIcon} fontSize='small' />,
  noBreakSVG: <IconSvg component={NobreakIcon} fontSize='small' />,
  otherSVG: <IconSvg component={OtherIcon} fontSize='small' />,
  splitSVG: <IconSvg component={SplitIcon} fontSize='small' />,
  waterTankSVG: <IconSvg component={WaterTankIcon} fontSize='small' />,

  openDoor: <IconSvg component={OpenDoorSvg} />,
  closedDoor: <IconSvg component={ClosedDoor} />,
  infoOutlined: <InfoOutlined fontSize='small' />,
  check: <Check fontSize='small' />,
  chevronLeft: <ChevronLeft fontSize='small' />,
  chevronRight: <ChevronRight fontSize='small' />,
  expandLess: <ExpandLessOutlined fontSize='small' />,
  expandMore: <ExpandMoreOutlined fontSize='small' />,
  water: <IconSvg component={WaterSvg} fontSize='small' />,
  gas: <IconSvg component={GasSvg} fontSize='small' />,
  energy: <IconSvg component={EnergySvg} fontSize='small' />,
  // eslint-disable-next-line camelcase
  temperature_humidity: (
    <IconSvg component={TemperatureHumiditySvg} fontSize='small' />
  ),
  humidity: <IconSvg component={HumiditySvg} fontSize='small' />,
  presentation: <IconSvg component={PresentationSvg} fontSize='small' />,
  hidden: <IconSvg component={HiddenSvg} fontSize='small' />,
  show: <IconSvg component={VisibilitySvg} fontSize='small' />,
  add: <Add fontSize='small' />,
  duplicate: <Add fontSize='small' />,
  edit: <IconSvg component={EditSvg} fontSize='small' />,
  delete: <IconSvg component={DeleteSvg} fontSize='small' />,
  pdf: <PictureAsPdf fontSize='small' />,
  excel: <IconSvg component={ExcelSvg} fontSize='small' />,
  download: <IconSvg component={DownloadSvg} fontSize='small' />,
  temperature: <IconSvg component={TemperatureSvg} fontSize='small' />,
  darkMode: <DarkMode fontSize='small' />,
  brightness7: <Brightness7 fontSize='small' />,
  moreVert: <MoreVert fontSize='small' />,
  moreHoriz: <MoreHoriz fontSize='small' />,
  notificationsNone: <NotificationsNone fontSize='small' />,
  notifications: <Notifications fontSize='small' />,
  close: <Close fontSize='small' />,
  personOutline: <PersonOutline fontSize='small' />,
  menu: <Menu fontSize='small' />,
  help: <Help fontSize='small' />,
  remove: <Remove fontSize='small' />,
  photo: <PhotoOutlined fontSize='small' />,
  settings: <SettingsOutlined fontSize='small' />,
  tune: <TuneOutlined fontSize='small' />,
  time: <AccessTime fontSize='small' />,
  historic: <AccessTime fontSize='small' />,
  zoomOut: <RemoveOutlined fontSize='small' />,
  zoomIn: <Add fontSize='small' />,
  lock: <LockOutlined fontSize='small' />,
  unlock: <LockOpenOutlined fontSize='small' />,
  goBack: <ArrowBack fontSize='small' />,
  error: <ErrorOutline fontSize='small' />,
  refresh: <Cached fontSize='small' />,
  grid: <GridView fontSize='small' />,
  circle: <RadioButtonUnchecked fontSize='small' />,
  timelapse: <Timelapse fontSize='small' />,
  warning: <WarningAmber fontSize='small' />,
  followOS: <HourglassEmpty fontSize='small' />,
  logout: <Logout fontSize='small' />,
  star: <Star fontSize='small' />,
  starOutline: <StarOutline fontSize='small' />,
  camera: <CameraAltOutlined fontSize='small' />,
  sensor: <Sensors fontSize='medium' />,
  placeOutLine: <PlaceOutlined fontSize='small' />,
  timeOutLine: <QueryBuilderOutlined fontSize='small' />,
  pin: <LocationOn fontSize='small' />,
  map: <Map fontSize='small' />,
  squares: <GridViewOutlined fontSize='small' />,
  visibility: <Visibility fontSize='small' />,
  visibilityOff: <VisibilityOff fontSize='small' />,
  area: <IconSvg component={AreaIcon} />,
}

export type IconNamesProps =
  | 'check'
  | 'chevronLeft'
  | 'show'
  | 'hidden'
  | 'add'
  | 'duplicate'
  | 'chevronRight'
  | 'edit'
  | 'delete'
  | 'pdf'
  | 'excel'
  | 'download'
  | 'brightness7'
  | 'darkMode'
  | 'moreVert'
  | 'moreHoriz'
  | 'notificationsNone'
  | 'notifications'
  | 'close'
  | 'personOutline'
  | 'menu'
  | 'help'
  | 'remove'
  | 'photo'
  | 'settings'
  | 'tune'
  | 'temperature'
  | 'time'
  | 'historic'
  | 'expandLess'
  | 'expandMore'
  | 'zoomOut'
  | 'zoomIn'
  | 'lock'
  | 'unlock'
  | 'goBack'
  | 'error'
  | 'refresh'
  | 'grid'
  | 'presentation'
  | 'temperature_humidity'
  | 'water'
  | 'gas'
  | 'energy'
  | 'humidity'
  | 'circle'
  | 'timelapse'
  | 'warning'
  | 'followOS'
  | 'logout'
  | 'star'
  | 'starOutline'
  | 'camera'
  | 'sensor'
  | 'placeOutLine'
  | 'timeOutLine'
  | 'closedDoor'
  | 'openDoor'
  | 'timeOpeningDoor'
  | 'pin'
  | 'map'
  | 'squares'
  | 'visibility'
  | 'visibilityOff'
  | 'area'
  | 'infoOutlined'
  | 'timeLineSvg'
  | 'openingIconSVG'
  | 'airConditioningSVG'
  | 'btnPanicSVG'
  | 'compressorSVG'
  | 'condenserSVG'
  | 'dehumidifierSVG'
  | 'dryBoxSVG'
  | 'exhaustSVG'
  | 'fuelTankSVG'
  | 'generatorSVG'
  | 'nitrogenPlantSVG'
  | 'noBreakSVG'
  | 'otherSVG'
  | 'splitSVG'
  | 'waterTankSVG'

type Props = {
  name: IconNamesProps
}

export function Icon({ name }: Props) {
  return icons[name]
}
