import { IconButton } from 'components/Button/IconButton'

export function SensoresContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros dos sensores que foram
        instalados na fábrica para obtenção de dados sobre temperatura, umidade,
        energia, gás e água. Esses dados serão usados para no Módulo Dashboard,
        Módulo Configurações {'->'} Equipamentos, Módulo Mapa {'->'} Montar Mapa
        e Monitoramento de Sensores., sendo ele o módulo chave para o
        funcionamento do sistema. Caso esse submódulo não apareça no seu sistema
        atualmente, provavelmente o seu tipo de perfil não permite o acesso.
        Caso necessite o uso desse submódulo, solicite ao administrador do
        sistema.
      </p>

      <p>
        <strong>Cadastrar Sensores</strong>
      </p>

      <p>Para realizar o cadastro de um novo sensor, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Sensores
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os campos que são: nome do sensor,
        status de ativo ou inativo, tipo de sensor onde você deverá selecionar a
        utilidade do sensor (água, energia, gás, temperatura, umidade ou
        temperatura e umidade), se o sensor é um datalog, caso sim deva informar
        o endereço IP dele, caso não, informar o endereço MAC.
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        Importante: Caso o endereço IP ou MAC não ser digitado de forma correta
        ao do sensor, esse não conseguirá ter acesso ao sistema. Então é
        importante ter cuidado ao preencher esse campo.
      </p>

      <p>
        <strong>Editar Sensores</strong>
      </p>

      <p>Para realizar a edição de um sensor, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Sensor
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o sensor que vai editar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos que aparecerão: nome do
        sensor, status de ativo ou inativo, tipo de sensor onde você deverá
        selecionar a utilidade do sensor (água, energia, gás, temperatura,
        umidade ou temperatura e umidade), se o sensor é um datalog, caso sim
        deva informar o endereço IP dele, caso não, informar o endereço MAC.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar.{' '}
      </p>
      <i>
        <strong>Importante:</strong> Caso o endereço IP ou MAC não ser digitado
        de forma correta ao do sensor, esse não conseguirá ter acesso ao
        sistema. Então é importante ter cuidado ao preencher esse campo.
      </i>

      <p>
        <strong>Excluir Sensores</strong>
      </p>

      <p>Para realizar a exclusão de um sensor, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Sensores
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o sensor que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-lo, selecione sim e o sensor será permanentemente excluído do
        sistema.
      </p>

      <p>
        <strong>Visualizar Sensores</strong>
      </p>

      <p>Para realizar visualizar os dados de um sensor, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Sensores
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o sensor que vai visualizar dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro. Esses dados serão mostrados sem poder realizar edição
        neles.
      </p>

      <p>
        <strong>Duplicar Sensor</strong>
      </p>

      <p>
        Caso precise ou queira agilizar o cadastro de vários sensores, basta:
      </p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Sensores
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o sensor que vai duplicar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='add' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Ao clicar nessa opção, aparecerá uma tela
        mostrando os campos preenchidos e informando que é uma cópia no campo
        nome, edite essa informação e informe o endereço MAC ou IP, pois esses
        dados não vêm preenchidos.
      </p>

      <p>
        <strong>Histórico de Sensor</strong>
      </p>

      <p>Para visualizar o histórico dos sensores, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Sensores
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o sensor que vai duplicar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Histórico”{' '}
        <IconButton iconName='historic' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Ao clicar nessa opção, aparecerá uma tela
        mostrando o ID do sensor, a data que esse sensor enviou dados, a hora, a
        ocorrência, o equipamento que esse sensor está atrelado, o status se o
        sensor está ligado ou desligado e o valor padrão enviado pelo sensor.
      </p>
    </>
  )
}
