import { Stack } from '@mui/material'

interface MyAccountActionsProps {
  children: React.ReactNode
}

export function MyAccountActions({ children }: MyAccountActionsProps) {
  return (
    <Stack direction={'row'} spacing={2}>
      {children}
    </Stack>
  )
}
