import { CircularProgress, DialogActions } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'

type Props = {
  stepAction: boolean
  currentStep: number
  onCancel: () => void
  onBack: () => void
  isView?: boolean
  isLoading?: boolean
}

export const ActionFormParameter: FC<Props> = ({
  onCancel,
  isView,
  stepAction,
  currentStep,
  onBack,
  isLoading,
}) => {
  const { t } = useTranslation('common')

  return (
    <div>
      <DialogActions
        sx={{
          '&>:not(:first-of-type)': { marginLeft: 2 },
          justifyContent: ['space-between', 'flex-end'],
        }}
      >
        <Button
          variant='outlined'
          type={'button'}
          onClick={onCancel}
          disabled={isView}
          style={{
            display:
              stepAction && currentStep === 1 ? 'none' : 'block',
          }}
        >
          {t('button.title.cancel')}
        </Button>

        { currentStep === 0 ?
        <Button
          variant='outlined'
          type={'submit'}
          style={{ display: stepAction ? 'block' : 'none' }}
        >
          {t('button.title.next')}
        </Button>
        :
        <Button
          variant='outlined'
          type={'button'}
          onClick={e => {
            e.preventDefault()
            onBack()
          }}
          style={{ display: stepAction ? 'block' : 'none' }}
        >
          {t('button.title.back')}
        </Button>
        }

        {isLoading ? (
          <Button
            variant='outlined'
            disabled={isView}
            style={{
              display:
                stepAction && currentStep === 0 ? 'none' : 'block',
            }}
          >
            <CircularProgress size={24} />
          </Button>
        ) : (
          <Button
            variant='outlined'
            type={'submit'}
            disabled={isView}
            style={{
              display:
                stepAction && currentStep === 0 ? 'none' : 'block',
            }}
          >
            {t('button.title.save')}
          </Button>
        )}
      </DialogActions>
    </div>
  )
}
