import { Box, Grid, Stack, Typography } from '@mui/material'

import { typeEquipmentOrArCon } from 'constants/maps'
import { useMapSubarea } from 'store/map-subarea.store'

interface Props {
  img: string
  title: string
  description: string
  type: typeEquipmentOrArCon
  handleOpenModal: (type: typeEquipmentOrArCon) => void
}

export const CardOption = ({
  img,
  title,
  description,
  type,
  handleOpenModal,
}: Props) => {
  const { isAirConditioning } = useMapSubarea()

  return (
    <Grid item xs={isAirConditioning ? 6 : 4}>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: 2,
          border: '1px solid',
          borderColor: (theme) => theme.palette.background.paper,
          p: 2,

          '&:hover': {
            background: (theme) => theme.palette.background.default,
            cursor: 'pointer',
            border: '1px solid',
            borderColor: (theme) => theme.palette.primary.main,
            transition: 'easy all 0.5s',
          },
        }}
        onClick={() => {
          handleOpenModal(type)
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ height: 150 }}
        >
          <Box component='img' src={img} width={150} />
        </Stack>
        <Typography
          sx={{
            font: 'normal normal bold 14px/20px Noto Sans',
            textAlign: 'center',
            my: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            font: 'normal normal normal 13px/20px Noto Sans',
            textAlign: 'center',
            mb: 1,
          }}
        >
          {description}
        </Typography>
      </Stack>
    </Grid>
  )
}
