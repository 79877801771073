import { PreOSType } from 'types/pre-os/pre-os'

export const preOSMock: PreOSType[] = []

export const sensorDoorMock = [
  {
    id: 1,
    local: {
      rua: '00001',
      bairro: '00001',
      cep: '00001',
    },
    openingTime: 3,
    qtyTimesOpenings: {
      qty: 2,
      type: 'min',
    },
    typeDoorRule: 'open',
  },
  {
    id: 2,
    local: {
      rua: '00002',
      bairro: '00002',
      cep: '00002',
    },
    openingTime: 3,
    qtyTimesOpenings: {
      qty: 1,
      type: 'hora(s)',
    },
    typeDoorRule: 'close',
  },
  {
    id: 3,
    local: {
      rua: '00003',
      bairro: '00003',
      cep: '00003',
    },
    openingTime: 5,
    qtyTimesOpenings: {
      qty: 40,
      type: 'seg',
    },
    typeDoorRule: 'open',
  },
  {
    id: 4,
    local: {
      rua: '00004',
      bairro: '00004',
      cep: '00004',
    },
    openingTime: 5,
    qtyTimesOpenings: {
      qty: 40,
      type: 'seg',
    },
    typeDoorRule: 'close',
  },
  {
    id: 5,
    local: {
      rua: '00005',
      bairro: '00005',
      cep: '00005',
    },
    openingTime: 2,
    qtyTimesOpenings: {
      qty: 12,
      type: 'min',
    },
    typeDoorRule: 'close',
  },
  {
    id: 6,
    local: {
      rua: '00006',
      bairro: '00006',
      cep: '00006',
    },
    openingTime: 1,
    qtyTimesOpenings: {
      qty: 1,
      type: 'min',
    },
    typeDoorRule: 'close',
  },
]
