import { CreateMeasuringAreaType } from 'types/measuring-area/create-measuringArea'

import { httpClient } from '../api/httpClient'

export const updateMeasuringArea = (
  id: number | string,
  data: CreateMeasuringAreaType,
) => {
  return httpClient.put(`/measuring-area/${id}`, data)
}
