import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IconButton } from 'components/Button/IconButton'
import { IconNamesProps } from 'components/Icon'

interface RowActionButtonProps {
  icon: IconNamesProps
  title?: string
  disabled?: boolean
  action?: () => void
  onClick?: () => void
  size?: 'small' | 'medium' | 'large'
  buttonType?: 'link' | 'button'
  route?: string
}

export function RowActionButton({
  icon,
  title,
  action,
  route,
  buttonType = 'button',
  disabled,
  onClick,
  size = 'medium',
}: RowActionButtonProps) {
  const theme = useTheme()
  const { t } = useTranslation('common')

  const button = (
    <IconButton
      title={title ?? t(`button.title.${icon}`) ?? ''}
      variant='contained'
      iconName={icon}
      color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
      onClick={action ?? onClick}
      size={size}
      disabled={disabled}
    />
  )

  return (
    <>
      {buttonType === 'link' && route ? (
        <Link to={route}>{button}</Link>
      ) : (
        button
      )}
    </>
  )
}
