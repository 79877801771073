import { useTheme } from '@mui/material'
import { PriorityEnum } from 'core/enums/PriorityEnum'
import { StatusOrderServiceEnum } from 'core/enums/StatusOrderServiceEnum'
import Scheduler, { Resource } from 'devextreme-react/scheduler'
import { CellClickEvent, ViewType } from 'devextreme/ui/scheduler'
import { useTranslation } from 'react-i18next'

import { STATUS_SCHEDULE } from 'helpers/statusSchedule'
import { OSType } from 'types/pre-os/pre-os'

import { Appointment } from './Appointment'
// import { getTimeZones } from 'devextreme/time_zone_utils'
// const timeZones = getTimeZones(new Date(2020, 6, 21))

const groups = ['priority']
const timelines = {
  day: 'timelineDay',
  week: 'timelineWeek',
  month: 'timelineMonth',
  year: 'timelineMonth',
}

const resourcesData = [
  {
    id: StatusOrderServiceEnum.NEW,
    text: 1,
    color: STATUS_SCHEDULE.COLORS.NEW,
  },
  {
    id: StatusOrderServiceEnum.FINISHED,
    text: 2,
    color: STATUS_SCHEDULE.COLORS.FINISHED,
  },
  {
    id: StatusOrderServiceEnum.IN_PROGRESS,
    text: 3,
    color: STATUS_SCHEDULE.COLORS.IN_PROGRESS,
  },
  {
    id: StatusOrderServiceEnum.REFUSED,
    text: 4,
    color: STATUS_SCHEDULE.COLORS.REFUSED,
  },
  {
    id: StatusOrderServiceEnum.NOT_SOLVED,
    text: 5,
    color: STATUS_SCHEDULE.COLORS.NOT_SOLVED,
  },
  {
    id: StatusOrderServiceEnum.NOT_ACCEPTED,
    text: 6,
    color: STATUS_SCHEDULE.COLORS.NOT_ACCEPTED,
  },
]

interface Props {
  timeline: 'day' | 'week' | 'month' | 'year'
  date?: Date
  onChangeDate?: (date: Date) => void
  status: string[]
  onCellClick?: string | ((e: CellClickEvent) => void)
  data: OSType[]
  refetch: () => void
}

export function Timelines({
  timeline,
  date,
  onChangeDate,
  onCellClick,
  data: originalData,
  refetch,
}: Props) {
  const { t } = useTranslation('common')
  const theme = useTheme()

  const data = originalData?.map((item) => {
    const dtEnd =
      item.status !== StatusOrderServiceEnum.FINISHED
        ? new Date()
        : new Date(item.dateOccurrence)
    return {
      text: item.name,
      statusId: item.status,
      startDate: new Date(item.dateOccurrence),
      endDate: dtEnd,
      priority: item.priority == PriorityEnum.HIGH ? 2 : 1,
      localization: item.location,
      progress: 'Fechado Nível 2',
      data: item,
      refetch,
    }
  })
  const priorityData = [
    {
      text: t('highPriority'),
      id: 2,
      color: '#DE4D53',
    },
    {
      text: t('lowPriority'),
      id: 1,
      color: '#4891FF',
    },
  ]

  return (
    <Scheduler
      className={`siiguti_timeline_${theme.palette.mode}`}
      timeZone='America/Manaus'
      dataSource={data}
      // views={['timelineDay', 'timelineWeek', 'timelineMonth']}
      views={[]}
      defaultCurrentView={timelines[timeline] as ViewType}
      currentView={timelines[timeline] as ViewType}
      defaultCurrentDate={date}
      currentDate={date}
      onCurrentDateChange={onChangeDate}
      height={580}
      groups={groups}
      cellDuration={60}
      firstDayOfWeek={0}
      startDayHour={0}
      endDayHour={24}
      appointmentComponent={Appointment}
      editing={false}
      onCellClick={onCellClick}
      showAllDayPanel={false}
      onAppointmentClick={(e) => {
        e.cancel = true
      }}
      onAppointmentDblClick={(e) => {
        e.cancel = true
      }}
    >
      <Resource
        fieldExpr='statusId'
        allowMultiple={true}
        dataSource={resourcesData}
        label='Status'
        useColorAsDefault={true}
      />
      <Resource
        fieldExpr='priority'
        allowMultiple={false}
        dataSource={priorityData}
        label='Priority'
      />
    </Scheduler>
  )
}
