import { httpClient } from 'services/api/httpClient'
import { GoalType } from 'types/goalAndParameter/goal'
import { PaginationParamType, PaginationType } from 'types/pagination'

type NewType = PaginationType<GoalType>
export const listGoal = async ({
  page,
  search = '',
  columnSort,
  columnName,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  const { data } = await httpClient.get('/goal', {
    params: {
      page: page - 1,
      search,
      columnSort,
      columnName,
      numberPerPage,
    },
  })

  return data
}
