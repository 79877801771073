export function DashboardWaterGeneralConsumptionTargetContent() {
  return (
    <>
      <p>
        Nesse gráfico é possível verificar o comparativo de consumo de água por
        setores, sendo esses setores como refeitório, banheiros, bebedouros, e
        etc.
      </p>
      <p>
        São mostradas as metas para cada setor, se o consumo passou da meta ou
        se ainda não alcançou. Nesse gráfico é possível filtrar o consumo por
        meio dos filtros de semana, mês e ano com seleção de datas. Conforme o
        cadastro de setores medidos, são disponibilizadas cores diferenciadas.
      </p>
      <p>
        Os dados relacionados a setores devem ser cadastrados em Mapa {'->'}{' '}
        Áreas, selecione Mapa nesse tutorial para saber mais.
      </p>
      <p>
        Os dados disponibilizados de consumo de água são cadastrados em faturas
        de água, o cadastro deve ser realizado em Configurações {'->'} Faturas{' '}
        {'->'} Água, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
