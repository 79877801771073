/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { HeaderAction } from 'components/HeaderAction'
import { Table, TableActionType } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { listProcedures } from 'services/procedures/listProcedures'
import { PaginationType } from 'types/pagination'
import { ProceduresType } from 'types/procedures/procedures'
import { ColumnType } from 'types/table/column'

import { FormProcedureModal } from './components/FormProcedureModal'
import { proceduresMapper } from './mappers/procedures.mapper'

export function ProcedurePage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const queryClient = useQueryClient()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<
    (Omit<ProceduresType, 'id'> & { id?: number | any }) | null
  >(null)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<ProceduresType>,
    Error
  >({
    queryKey: ['procedures', page, search, orderBy, order],
    queryFn: () => listProcedures({ page, search, columnSort: order, columnName: orderBy }),
  })

  const procedures = proceduresMapper(data?.items ?? [])

  const handleOpen = () => setOpenForm(true)

  const handleCancel = () => {
    setOpenForm(false)
    setOpenFormView(false)
    setSelected(null)
  }

  const handleEdit = (item: ProceduresType) => {
    setSelected(item)
    handleOpen()
  }

  const handleView = (item: ProceduresType) => {
    setSelected(item)
    setOpenFormView(true)
  }

  const handleDelete = (item: ProceduresType) => {
    httpDelete(`/procedures/${item.id}`, () => {
      refetch()
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'procedures' ||
          query.queryKey[0] === 'all-sensors-procedures' ||
          query.queryKey[0] === 'all-procedures',
      })
      toast.success(t('savedInformation'))
    })
  }

  const columns: ColumnType[] = [
    {
      id: 'id',
      label: t('table.procedure.title'),
    },
    {
      id: 'name',
      label: t('table.procedure.name'),
    },
    {
      id: 'description',
      label: t('table.procedure.description'),
    },
    {
      id: 'status',
      label: t('table.procedure.status'),
    },
  ]

  const tableActions: TableActionType<ProceduresType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_PROCEDURE,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_PROCEDURE,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_PROCEDURE,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <>
      <HeaderAction
        onCreate={handleOpen}
        onSearch={(search) => setSearch(search)}
      />

      <Table<ProceduresType>
        columns={columns}
        rows={procedures}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrderBy(newOrderBy)
        }}
        onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
        order={order}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.total,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView) && (
        <FormProcedureModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView}
          onClose={handleCancel}
        />
      )}
    </>
  )
}
