import styled from '@emotion/styled'

export const EquipmentNodeItemWrapper = styled.div<{
  bgColor?: string
}>(
  {
    height: '22px',
    width: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    '&:hover ~ * ': {
      display: 'block',
    },
  },
  ({ bgColor }) => ({
    background: bgColor,
  }),
)
