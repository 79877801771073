export function DashboardEnergyCustoEnergiaQuantidadeProduzidaContent() {
  return (
    <>
      <p>
        Nesse gráfico é realizada a comparação do custo de energia com a
        quantidade de produtos produzidos da fábrica. Nesse gráfico pode ser
        filtrado por semana, mês e ano selecionando o período.
      </p>
      <p>
        Os dados disponibilizados nesse gráfico sobre total produzido são
        cadastrados em Configurações {'->'} Metas e Parâmetros {'->'} Quantidade
        Produzida, selecione Configurações nesse tutorial para saber mais.
      </p>
      <p>
        Os dados relacionados sobre custo de energia são cadastrados em
        Configurações {'->'} Faturas {'->'} Energia, selecione Configurações
        nesse tutorial para saber mais.
      </p>
    </>
  )
}
