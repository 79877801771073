import { Typography, useTheme } from '@mui/material'
import { SensorTypeEnum, getAllSensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { FC, useEffect } from 'react'

import { getIconBySensorType } from 'helpers/sensor-type.helper'
import { useCheckboxSelection } from 'hooks/useCheckboxSelection'
import { useSidebarMonitoringStore } from 'store/monitoring/sidebar-monitoring.store'

import { MonitoringSidebarCard } from '../MonitoringSidebarCard'
import {
  LabelSidebarMonitoring,
  LabelSidebarMonitoringWrapper,
} from '../styles'

export const SensorCard: FC = () => {
  const theme = useTheme()
  const { setVisibleSensorTypes } = useSidebarMonitoringStore()

  const sensorSelect = useCheckboxSelection<SensorTypeEnum>()
  const { values, setValues, checkValueIsActive, changeValue } = sensorSelect

  const sensors = [
    {
      title: 'Energia',
      type: SensorTypeEnum.ELECTRIC,
    },
    {
      title: 'Temperatura',
      type: SensorTypeEnum.TEMP,
    },
    {
      title: 'Umidade',
      type: SensorTypeEnum.HUM,
    },
    {
      title: 'Água',
      type: SensorTypeEnum.WATER,
    },
    {
      title: 'Gás',
      type: SensorTypeEnum.AIR,
    },
    {
      title: 'Temp e umid',
      type: SensorTypeEnum.TEMP_HUM,
    },
    {
      title: 'Abertura',
      type: SensorTypeEnum.OPENING,
    },
  ]

  const showAll = () => {
    if (checkValueIsActive(SensorTypeEnum.ALL)) {
      setValues([])
    } else {
      setValues(getAllSensorTypeEnum())
    }
  }

  useEffect(() => {
    setVisibleSensorTypes(values)
  }, [values])

  return (
    <MonitoringSidebarCard title='Sensores' isAccordion>
      <LabelSidebarMonitoring
        bgColor={theme.palette.primary.main}
        style={{ marginBottom: '10px' }}
        isActive={checkValueIsActive(SensorTypeEnum.ALL)}
        onClick={showAll}
      >
        <Typography
          fontSize={'12px'}
          color={
            theme.palette.mode === 'dark'
              ? 'text.primary'
              : checkValueIsActive(SensorTypeEnum.ALL)
              ? '#FFF'
              : 'text.primary'
          }
        >
          Ver tudo
        </Typography>
      </LabelSidebarMonitoring>
      <LabelSidebarMonitoringWrapper>
        {sensors.map((sensor) => {
          const isActive = checkValueIsActive(sensor.type)
          const IconComponent = getIconBySensorType(sensor.type)

          const iconStyle = {
            ...(isActive && {
              fill: 'none',
              style: {
                borderRadius: '2px',
                border: '1px solid #fff',
              },
            }),
          }

          return (
            <LabelSidebarMonitoring
              key={sensor.title}
              isActive={isActive}
              bgColor={theme.palette.primary.main}
              onClick={() => changeValue(sensor.type)}
            >
              <IconComponent height={14} width={14} {...iconStyle} />
              <Typography
                fontSize={'12px'}
                color={
                  theme.palette.mode === 'dark'
                    ? 'text.primary'
                    : checkValueIsActive(SensorTypeEnum.ALL) || isActive
                    ? '#FFF'
                    : 'text.primary'
                }
              >
                {sensor.title}
              </Typography>
            </LabelSidebarMonitoring>
          )
        })}
      </LabelSidebarMonitoringWrapper>
    </MonitoringSidebarCard>
  )
}
