import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Box, Stack } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 0,
  padding: 0,
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export type TutorialType = {
  id: string | number
  name: string
  image?: string | null
  content?: string | null | ReactNode
  topics?: TutorialType[]
}

interface Props {
  data: TutorialType
}

export function Tutorial({ data }: Props) {
  const { name, content, image, topics } = data
  return (
    <Stack spacing={2}>
      <Typography fontWeight={'bold'} variant='body1'>
        {name}
      </Typography>
      {image ? (
        <Box
          component='img'
          src={image}
          sx={{
            borderRadius: 1,
            width: '100%',
          }}
        />
      ) : (
        <Box
          sx={{
            borderRadius: 1,
            background: (theme) => theme.palette.background.paper,
            width: '100%',
          }}
        />
      )}

      {!!content && (
        <Typography
          component='div'
          style={{
            lineHeight: '1.5',
            fontSize: '14px',
            opacity: 0.9,
          }}
        >
          {content}
        </Typography>
      )}

      {!!topics?.length && (
        <Stack spacing={2} width={'100%'} maxWidth={['100%', 940]}>
          {topics.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography fontWeight={'bold'} variant='body1'>
                  {item.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Stack spacing={2}>
                  {item?.image ? (
                    <Box
                      component='img'
                      src={item?.image}
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        borderRadius: 1,
                        background: (theme) => theme.palette.background.paper,
                        width: '100%',
                      }}
                    />
                  )}

                  {!!item?.content && (
                    <Typography
                      component='div'
                      style={{
                        lineHeight: '1.5',
                        fontSize: '14px',
                        opacity: 0.9,
                      }}
                    >
                      {item?.content}
                    </Typography>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
