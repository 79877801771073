import { httpClient } from 'services/api/httpClient'
import {
  CreateNotificationSettingType,
  NotificationSettingType,
} from 'types/notification-setting'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'

const create = (data: CreateNotificationSettingType) => {
  return httpClient.post('/notification-setting', data)
}

const update = (id: number, data: CreateNotificationSettingType) => {
  return httpClient.put(`/notification-setting/${id}`, data)
}

const changeStatus = (id: number | string) => {
  return httpClient.put(`/notification-setting/status/${id}`)
}

type NewType = PaginationType<NotificationSettingType>

export const findAll = async ({
  page = 1,
  search = '',
  columnSort,
  columnName,
}: PaginationParamType): Promise<NewType> => {
  const response = await httpClient.get('/notification-setting', {
    params: {
      page: page - 1,
      search,
      columnSort,
      columnName,
    },
  })
  const { total } = response.data
  const { perPage } = response.data
  const { items } = response.data

  return {
    items: items,
    currentPage: page,
    perPage: perPage,
    total: total,
  } as PaginationResponseType
}

export const notificationSettingService = {
  create,
  update,
  findAll,
  changeStatus,
}
