import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { ReactElement } from 'react-imask/dist/mixin'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const MaskedInputMAC = forwardRef<ReactElement, CustomProps>(
  function MaskedInputNumber(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask='**:**:**:**:**:**'
        inputRef={ref}
        onAccept={(value: unknown) => {
          return onChange({
            target: { name: props.name, value: String(value) },
          })
        }}
        overwrite
      />
    )
  },
)
