import { LinearProgress } from '@mui/material'

interface PasswordStrengthBarProps {
  value: number
}

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({ value }) => {
  const getColor = (value: number) => {
    if (value < 10) return 'secondary'
    if (value < 40) return 'error'
    if (value < 60) return 'primary'
    return 'success'
  }

  return (
    <LinearProgress
      variant='determinate'
      value={value}
      color={getColor(value)}
    />
  )
}

export default PasswordStrengthBar
