/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Toggle } from 'components/Toggle'
import { updateGoalStatus } from 'services/goalAndParameter/goal/updateGoalStatus'
import { GoalType } from 'types/goalAndParameter/goal'

export function goalMapper(data: GoalType[]) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const handleToggleStatus = async (id: string) => {
    try {
      await updateGoalStatus(id)
      queryClient.invalidateQueries('goal')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  return data.map((item) => ({
    ...item,
    id: typeof item?.id !== 'number' ? item?.id?.value : item?.id,
    formattedArea: item?.area?.name ?? '-',
    consumption:
      item.unit.name === 'WATER'
        ? item.consumption + ' M³'
        : item.consumption + ' kWh',
    unitName: t(item.unit.name),
    sectorName: item.sector ? item.sector : '-',
    muiStatus: (
      <Toggle
        name='toggle'
        checked={item.status}
        onChange={() =>
          handleToggleStatus(
            typeof item?.id !== 'number' ? item?.id?.value : item?.id,
          )
        }
      />
    ),
  }))
}
