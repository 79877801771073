import { PermissionType } from '../types/permission.type'
import { toggleChildrenCheckboxes } from './toggleChildrenCheckboxes'

// Toggle checkbox and children checkboxes
export const toggleCheckbox = (
  checkboxes: PermissionType[],
  checkboxId: string,
  isChecked: boolean,
): PermissionType[] => {
  return checkboxes.map((checkbox) => {
    if (checkbox.id === checkboxId) {
      checkbox.checked = isChecked
      if (checkbox.children) {
        checkbox.children = toggleChildrenCheckboxes(
          checkbox.children,
          isChecked,
        )
      }
    } else if (checkbox.children) {
      const updatedChildren = toggleCheckbox(
        checkbox.children,
        checkboxId,
        isChecked,
      )
      if (updatedChildren.some((child) => child.checked)) {
        checkbox.checked = true
      } else {
        checkbox.checked = false
      }
      checkbox.children = updatedChildren
    }
    return checkbox
  })
}
