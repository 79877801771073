import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import img from 'assets/icons/listsensors.svg'

type Props = {
  isPreview?: boolean
  label: string
  value: number | string
  color: string
  onClick?: () => void
  isHoverDisabled?: boolean
  fontSizeLabel?: number | string
  fontSizeValue?: number | string
  size?: number
  type?: 'temperature' | 'percentage'
}

export function CircularGraphic({
  isPreview,
  label,
  value,
  color,
  onClick,
  isHoverDisabled,
  fontSizeLabel,
  fontSizeValue,
  size,
  type = 'temperature',
}: Props) {
  const [isHover, setIsHover] = useState(false)

  const handleToggleHover = (value: boolean) => {
    setIsHover(value)
  }

  return (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          width: size ? size : isPreview ? 160 : 180,
          height: size ? size : isPreview ? 160 : 180,
          cursor: 'pointer',
        }}
      >
        <CircularProgress
          variant='determinate'
          sx={{
            position: 'absolute',
            left: 0,
            color: '#4a4b68',
            '&:after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '50%',
              border: '2px dashed #4a4b68',
            },
          }}
          thickness={4}
          value={100}
          size={size ? size : isPreview ? 160 : 180}
        />
        <CircularProgress
          variant='determinate'
          value={Number(value.toString().replace(',', '.'))}
          size={size ? size : isPreview ? 160 : 180}
          thickness={4}
          sx={{
            color: color,
            position: 'absolute',
            left: 0,
          }}
        />
        <Box
          sx={{
            top: 24,
            left: 24,
            bottom: 24,
            right: 24,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            borderWidth: 3,
            borderColor: '#4a4b68',
            borderStyle: 'solid',
            '&:hover': {
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
              backgroundColor: 'rgba(48,48,96,0.6)',
              borderColor: (theme) => theme.palette.primary.main,
            },
          }}
          onMouseEnter={() => {
            if (isHoverDisabled) return
            handleToggleHover(true)
          }}
          onMouseLeave={() => {
            if (isHoverDisabled) return
            handleToggleHover(false)
          }}
          onClick={onClick}
        >
          {isHover ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box>
                <img src={img} />
              </Box>
              <Box p={1} textAlign={'center'}>
                <Typography
                  variant='body1'
                  component='div'
                  color='text.primary'
                  fontSize={'0.8rem'}
                >
                  Ver dados dos sensores da area
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Typography
                variant={isPreview ? 'h1' : 'h2'}
                component='div'
                color='text.primary'
                fontWeight={'bold'}
                fontSize={fontSizeValue ? fontSizeValue : '1.5rem'}
              >
                {value || '--'}
                <small>{type === 'percentage' ? '%' : 'ºC'}</small>
              </Typography>
              <Typography
                variant='body1'
                component='div'
                color='text.secondary'
                whiteSpace={'pre-wrap'}
                textAlign={'center'}
                maxWidth={size ? size : isPreview ? 160 : 180}
                fontSize={fontSizeLabel ? fontSizeLabel : '1rem'}
              >
                {label}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Stack>
  )
}
