import { Stack, SxProps, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

type Props = {
  title: string
  description?: string
  children: ReactNode
  style?: SxProps
}

export const AirConditionerCard: FC<Props> = (props) => {
  return (
    <Stack
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        borderRadius: '6px',
        padding: '10px',
        ...props.style,
      }}
    >
      <Typography marginBottom={2} fontWeight={700}>
        {props.title}
      </Typography>
      <Typography marginBottom={2} fontWeight={400}>
        {props.description}
      </Typography>
      {props.children}
    </Stack>
  )
}
