import { subareaType } from 'constants/subarea'
import { httpClient } from 'services/api/httpClient'
import { AreaType } from 'types/area/area'
import { PaginationType } from 'types/pagination'

type NewType = PaginationType<AreaType>

export const getArea = async (
  page = 1,
  search = '',
  orderBy?: string,
): Promise<NewType> => {
  const response = await httpClient.get('/area')
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  const results = items?.filter((item: AreaType) =>
    item.name.toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
    order: orderBy,
  }
}

export const getAreaByCompany = async (
  companyId: string,
): Promise<AreaType[]> => {
  const response = await httpClient.get(`/area/get-by-company/${companyId}`)
  return response.data
}

export const findSubareaByAreaId = async ({
  mapImageCompanyId,
  type,
}: {
  mapImageCompanyId: string
  type: subareaType
}) => {
  const { data } = await httpClient.get('/area/subarea/map', {
    params: {
      type,
      mapImageCompanyId,
    },
  })
  return data
}
