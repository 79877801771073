import { Divider, Stack } from '@mui/material'
import { useState } from 'react'

import { useMonitoringSensorStore } from 'store/monitoring-sensor.store'

import { CardAlertSensor } from './components/CardAlertSensor'
import { NumberOfSensor } from './components/NumberOfSensor'

export const SensorSidebar = () => {
  const [alerts] = useState([])

  const { showDataCompanyDetail } = useMonitoringSensorStore()

  return (
    <Stack
      direction='column'
      flex={1}
      sx={{
        width: '100%',
        maxHeight: 'calc(100% - 104px)',
        maxWidth: showDataCompanyDetail ? 418 : 0,
        background: (theme) => theme.palette.background.default,
        borderRight: showDataCompanyDetail ? '2px solid' : '0 solid',
        borderColor: (theme) => theme.palette.secondary.light,
        p: showDataCompanyDetail ? 2 : 0,
        transition: 'all 0.3s ease',
      }}
    >
      <NumberOfSensor />

      <Divider
        sx={{ color: (theme) => theme.palette.primary.main, mt: 1, mb: 0.5 }}
      />

      <Stack
        direction='column'
        sx={{
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 465px)',
        }}
      >
        {/* Aqui serão inseridos os dados de alerta dos sensores quando a API estiver sido feita */}
        {alerts?.map((alert: any) => (
          <CardAlertSensor icon='warning' typeAlert='sensor' key={alert?.id} />
        ))}
      </Stack>
    </Stack>
  )
}
