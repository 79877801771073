/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import dayjs from 'dayjs'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { getMonthDays } from 'helpers/getMonthDays'
import { getWeekDays } from 'helpers/getWeekDays'
import { httpClient } from 'services/api/httpClient'
import { sensorDoorService } from 'services/dashboard/sensordoorDashboard.service'

interface DataItem {
  sensor: number
  amount: number
  timeseconds: number
  totalInRuleOpenings: number
  totalNotInRuleOpenings: number
  date: Date
}

interface APIDataItem {
  sensor: number
  totalOpenings: number
  totalTimeSeconds: number
  totalInRuleOpenings: number
  totalNotInRuleOpenings: number
  date: string
}

function formatInMinutes(milliseconds: number) {
  const minutes = (milliseconds % (60 * 60)) / 60
  return minutes.toFixed(1)
}
function formatTimeMinutesAndSeconds(minutes: number) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = Math.floor(minutes % 60)
  const seconds = Math.floor((minutes * 60) % 60)

  const formattedTime = `${String(hours).padStart(2, '0')}h ${String(
    remainingMinutes,
  ).padStart(2, '0')}min ${String(seconds).padStart(2, '0')}s`
  return formattedTime
}

interface Props {
  isPreview?: boolean
}

export function useSensorChart(props: Props) {
  const { isPreview } = props

  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')

  const [generatingFile, setGeneratingFile] = useState(false)

  const [typeChart, setTypeChart] = useState<'quantity' | 'minute'>('quantity')

  const [selectedSensors, setSelectedSensors] = useState<any[]>([])

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['dashboard-sensor', startDate, selectedSensors, period],
    queryFn: async () => {
      const { data } = await httpClient.get(
        'dashboard-sensor/opening/monitoring-real',
        {
          params: {
            weekParam:
              period === 'week'
                ? `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(startDate)
                  .add(6, 'day')
                  .format('DD/MM/YYYY')}`
                : undefined,
            monthParam:
              period === 'month' ? dayjs(date).format('MM') : undefined,
            yearParam: dayjs(date).format('YYYY'),
            sensorIds:
              selectedSensors.length > 0
                ? selectedSensors
                  .map((sensor: { value: any }) => sensor.value)
                  .join(',')
                : undefined,
          },
        },
      )

      // Mapear os dados para o formato desejado
      const mappedData: DataItem[] = data.map((item: APIDataItem) => {
        const [ano, mes, dia] = item.date.split('-').map(Number)
        const date = new Date(ano, mes - 1, dia)

        return {
          sensor: item.sensor,
          amount: item.totalOpenings,
          timeseconds: item.totalTimeSeconds,
          date: date,
          totalInRuleOpenings: item.totalInRuleOpenings,
          totalNotInRuleOpenings: item.totalNotInRuleOpenings,
        }
      })

      return mappedData ?? []
    },
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
  })

  const { data: sensors } = useQuery({
    queryKey: ['sensor', 'opening-type'],
    queryFn: async () => {
      return await sensorDoorService.listSensorOpeningDoor()
    },
    select: (data) => {
      const newData = data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }))

      return newData ?? []
    },
  })

  useEffect(() => {
    if (data && !data.length) {
      toast.info(t('dashboard.sensor.message.noDataDisplay'))
    }
  }, [data, isLoading])

  useEffect(() => {
    const timeInMinutes = 5 * (1000 * 60) // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.yAxisID === 'y2') {
              return `${context.parsed.y
                  ? formatTimeMinutesAndSeconds(context.parsed.y)
                  : ''
                }`
            }

            return `${context.parsed.y} `
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        stacked: true,
        ticks: {
          display: isPreview ? false : true,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value
          },
        },
      },
      y2: {
        position: 'right',
        ticks: {
          display: isPreview ? false : true,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value + ' min'
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
        grid: {
          tickWidth: 10,
        },
        stacked: true,
      },
    },
  }

  const getLabels = useCallback(() => {
    if (period === 'year') {
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    return getWeekDays(date, 'DD/MMM')
  }, [period, date])

  function calculateDailyTotals(
    data: DataItem[] | undefined,
    sensorId: string,
    type: 'quantity' | 'time',
    typeCase?: 'inRule' | 'inNotRule',
  ): any[] {
    const dailyTotals: any[] = []
    const startDate = moment(date).startOf('month') // Obtém o primeiro dia do mês atual
    const endDate = moment(date).endOf('month') // Obtém o último dia do mês atual

    // Preenche o array dailyTotals com todos os dias do mês
    for (
      let date = startDate.clone();
      date.isSameOrBefore(endDate);
      date.add(1, 'day')
    ) {
      const dateString = date.format('YYYY-MM-DD')

      const findItem = data?.find(
        (item) =>
          moment(item?.date).format('YYYY-MM-DD') === dateString &&
          String(item?.sensor) === sensorId,
      )

      if (findItem) {
        dailyTotals.push({
          month: dateString,
          total:
            typeCase === 'inRule'
              ? findItem.totalInRuleOpenings
              : findItem.totalNotInRuleOpenings,
          time: findItem?.timeseconds
            ? formatInMinutes(Number(findItem?.timeseconds))
            : 0,
        })
      } else {
        dailyTotals.push({
          month: dateString,
          total: 0,
          time: 0,
        })
      }
    }

    if (type === 'time') {
      return dailyTotals.map((item) => item.time)
    }

    return dailyTotals.map((item) => item.total)
  }

  function calculateWeekTotals(
    data: DataItem[] | undefined,
    sensorId: string,
    type: 'quantity' | 'time',
    typeCase?: 'inRule' | 'inNotRule',
  ): any[] {
    const dailyTotals: any[] = []
    const startDate = moment(date).startOf('week')
    const endDate = moment(date).endOf('week')

    // Preenche o array dailyTotals com todos os dias do mês
    for (
      let dateStartWeek = startDate.clone();
      dateStartWeek.isSameOrBefore(endDate);
      dateStartWeek.add(1, 'day')
    ) {
      const dateString = dateStartWeek.format('YYYY-MM-DD')

      const findItem = data?.find(
        (item) =>
          moment(item?.date).format('YYYY-MM-DD') === dateString &&
          String(item?.sensor) === sensorId,
      )

      if (findItem) {
        dailyTotals.push({
          month: dateString,
          total:
            typeCase === 'inRule'
              ? findItem.totalInRuleOpenings
              : findItem.totalNotInRuleOpenings,
          time: findItem?.timeseconds
            ? formatInMinutes(Number(findItem?.timeseconds))
            : 0,
          inRole: findItem?.totalInRuleOpenings,
          inNotRule: findItem?.totalNotInRuleOpenings,
        })
      } else {
        dailyTotals.push({
          month: dateString,
          total: 0,
          time: 0,
        })
      }
    }

    if (type === 'time') {
      return dailyTotals.map((item) => item.time)
    }

    return dailyTotals.map((item) => item.total)
  }

  function calculateMonthlyTotals(
    data: DataItem[] | undefined,
    sensorId: string,
    type: 'quantity' | 'time',
    typeCase?: 'inRule' | 'inNotRule',
  ): any[] {
    const monthlyTotals: any[] = []
    const year = moment(date).year() // Obtém o ano da data selecionada

    // Inicializa o array monthlyTotals com os meses do ano
    for (let month = 1; month <= 12; month++) {
      const monthString = moment()
        .month(month - 1)
        .year(year)
        .format('MM')

      const filteredItems = data?.filter(
        (item) =>
          moment(item?.date).format('MM') === monthString &&
          String(item?.sensor) === sensorId &&
          item?.date.getFullYear() === year,
      ) // Inicializa as variáveis para armazenar a soma dos valores
      let totalAmount = 0
      let totalTimeSeconds = 0

      // Calcula a soma dos valores
      filteredItems?.forEach((item) => {
        totalAmount =
          typeCase === 'inRule'
            ? totalAmount + item?.totalInRuleOpenings
            : totalAmount + item?.totalNotInRuleOpenings
        totalTimeSeconds += item?.timeseconds ?? 0
      })

      // Converte o tempo total de segundos para minutos e arredonda para uma casa decimal
      const totalTimeMinutes = formatInMinutes(totalTimeSeconds)

      // Adiciona os totais calculados ao array monthlyTotals
      monthlyTotals.push({
        month: monthString,
        total: totalAmount,
        time: totalTimeMinutes,
      })
    }

    if (type === 'time') {
      return monthlyTotals.map((item) => item.time)
    }

    return monthlyTotals.map((item) => item.total)
  }

  const datasets = useCallback(() => {
    const quantities =
      selectedSensors.map((sensor) => {
        const formattedData =
          period === 'month'
            ? calculateDailyTotals(data, sensor?.value, 'quantity', 'inRule')
            : period === 'week'
              ? calculateWeekTotals(data, sensor?.value, 'quantity', 'inRule')
              : calculateMonthlyTotals(data, sensor?.value, 'quantity', 'inRule')

        return {
          data: formattedData ?? [],
        }
      }) ?? []

    const quantitiesDois =
      selectedSensors.map((sensor) => {
        const formattedData =
          period === 'month'
            ? calculateDailyTotals(data, sensor?.value, 'quantity', 'inNotRule')
            : period === 'week'
              ? calculateWeekTotals(data, sensor?.value, 'quantity', 'inNotRule')
              : calculateMonthlyTotals(
                data,
                sensor?.value,
                'quantity',
                'inNotRule',
              )

        return {
          data: formattedData ?? [],
        }
      }) ?? []

    const times =
      selectedSensors.map((sensor) => {
        const formattedData =
          period === 'month'
            ? calculateDailyTotals(data, sensor?.value, 'time')
            : period === 'week'
              ? calculateWeekTotals(data, sensor?.value, 'time')
              : calculateMonthlyTotals(data, sensor?.value, 'time')

        return formattedData
      }) ?? []

    const timeSomaData = times.reduce((acc, item) => {
      item.map((itemDois, index) => {
        if (!acc[index]) acc[index] = 0
        acc[index] += Number(itemDois)
      })

      return acc
    }, [])

    const timeAcc = {
      type: 'line' as const,
      yAxisID: 'y2',
      label: t('dashboard.sensor.message.doorOpeningTime'),
      data: timeSomaData ?? [],
      borderRadius: 20,
      backgroundColor: 'transparent',
      borderColor: createGradient(
        chartRef.current.ctx,
        chartRef.current.chartArea,
        colors?.sensor[2],
      ),
    }

    // retornos para teste
    const quantitiesSumInRole = quantities.reduce((acc: any, item: any) => {
      item.data.map((itemDois: any, index: any) => {
        if (!acc[index]) acc[index] = 0
        acc[index] += Number(itemDois)
      })

      return acc
    }, [])
    const quantitiesDoisSomeInNotRole = quantitiesDois.reduce(
      (acc: any, item: any) => {
        item.data.map((itemDois: any, index: any) => {
          if (!acc[index]) acc[index] = 0
          acc[index] += Number(itemDois)
        })

        return acc
      },
      [],
    )
    const quantitiesDoisResult = {
      type: 'bar' as const,
      label: t('dashboard.sensor.message.surplusDoorOpening'),
      data: quantitiesDoisSomeInNotRole ?? [],
      borderRadius: {
        topLeft: 999,
        topRight: 999,
      },
      barPercentage: period === 'week' ? 0.3 : period === 'year' ? 0.3 : 1,
      borderSkipped: 'middle' as const,
      backgroundColor: createGradient(
        chartRef.current.ctx,
        chartRef.current.chartArea,
        colors?.sensor[1],
      ),
    }

    const quantitiesResult = {
      type: 'bar' as const,
      label: t('dashboard.sensor.message.openingDoor'),
      data: quantitiesSumInRole ?? [],
      borderRadius: {
        bottomLeft: 999,
        bottomRight: 999,
      },
      barPercentage: period === 'week' ? 0.3 : period === 'year' ? 0.3 : 1,
      borderSkipped: 'top' as const,
      backgroundColor: createGradient(
        chartRef.current.ctx,
        chartRef.current.chartArea,
        colors?.sensor[0],
      ),
    }

    const result = [timeAcc, quantitiesResult, quantitiesDoisResult]

    return result
  }, [selectedSensors, getLabels, data, typeChart])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()

    const chartData = {
      labels,
      datasets: datasets(),
    }

    setChartData(chartData)
  }, [i18n.language, date, getLabels, typeChart, datasets])

  return {
    chartData,
    setChartData,
    options,
    t,
    date,
    setDate,
    chartRef,
    isDesktop,
    period,
    setPeriod,
    isLoading,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
    typeChart,
    setTypeChart,
    sensors,
    selectedSensors,
    setSelectedSensors,
  }
}
