import axios from 'axios'
import { toast } from 'react-toastify'

import { ENVIRONMENT } from 'constants/environment'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { signOut } from 'store/auth.store'

const endpoint = ENVIRONMENT.API_URL

export function configApi() {
  const getToken = localStorage.getItem(
    `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
  )
  const getLang = localStorage.getItem(
    `@${ENVIRONMENT.APP_NAME}:application.language`,
  )

  const api = axios.create({
    baseURL: endpoint,
  })

  api.defaults.headers.common['Authorization'] = getToken
    ? `Bearer ${getToken}`
    : ''

  api.defaults.headers.common['Accept-Language'] = getLang

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(
        `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
      )
      const language = localStorage.getItem(
        `@${ENVIRONMENT.APP_NAME}:application.language`,
      )

      if (token && config?.headers?.Authorization) {
        config.headers.Authorization = `Bearer ${token}`
      }
      if (language && config?.headers?.['Accept-Language']) {
        config.headers['Accept-Language'] = language
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  api.interceptors.response.use(
    (response) => {
      return response
    },
    (error: any) => {
      if (
        error.response?.status === 401 ||
        error.response?.statusText === 'Unauthorized' ||
        error?.message === 'Request failed with status code 401'
      ) {
        if (error?.response?.data?.message === 'The request is unauthorized') {
          if (
            !localStorage.getItem(
              `@${ENVIRONMENT.APP_NAME}:application.showMessageSessionExpired`,
            )
          ) {
            toast.warn(
              getLang === 'en'
                ? 'Your session has expired. sign in again!'
                : 'Sua sessão expirou. faça login novamente!',
            )
            localStorage.setItem(
              `@${ENVIRONMENT.APP_NAME}:application.showMessageSessionExpired`,
              'true',
            )
          }

          signOut()
        } else {
          getErrorMessage(error)
          signOut()
        }
      }

      if (error.response?.status === 500) {
        toast.error('Server Error')
        throw new Error('Server Error')
      }

      // getErrorMessage(error)

      return Promise.reject(error)
    },
  )

  return api
}
