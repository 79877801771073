import { Grid, Stack, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/Input'
import { CreateParameterType } from 'types/goalAndParameter/create-parameter'

import { AirConditionerCard } from '../../AirConditionerCard'
import { ActionNotification } from './ActionNotification'
import './style.css'

type Props = {
  control: Control<CreateParameterType>
  getValues: UseFormGetValues<CreateParameterType>
  setValue: UseFormSetValue<CreateParameterType>
  watch: UseFormWatch<CreateParameterType>
  errors: FieldErrors
  isView?: boolean
}

enum NotificationActions {
  ALARM = 'ALARM',
  TEMPERATURE = 'TEMPERATURE',
}

export const AirConditioningLast: FC<Props> = (props) => {
  const { t } = useTranslation('common')
  const [notifications, setNotifications] = useState<NotificationActions[]>([])

  const checkNotificationActive = (value: NotificationActions) => {
    return notifications.includes(value)
  }

  const changeNotification = (value: NotificationActions) => {
    if (checkNotificationActive(value)) {
      setNotifications(notifications.filter((item) => item !== value))
    } else {
      setNotifications(notifications.concat(value))
    }
  }

  useEffect(() => {
    if (!props.watch('temperatureOnlyMax')) {
      props.setValue('isMinAndMax', true)
    }
  }, [])

  return (
    <Grid item xs={12}>
      <AirConditionerCard
        title={t('modal.titleAirStepLastTemp')}
        description={t('modal.descriptionAirStepLastTemp') as string}
        style={{
          marginBottom: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Stack direction={'row'} alignItems='center' spacing={2}>
              <Typography whiteSpace='nowrap'>{t('input.minimum')} </Typography>
              <Input
                name='temperatureMin'
                label={'°C'}
                type='number'
                control={props.control}
                fullWidth
                error={!!props.errors?.temperatureMin}
                errorMessage={props.errors?.temperatureMin?.message}
                InputProps={{
                  disabled: props.isView,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Stack direction={'row'} alignItems='center' spacing={2}>
              <Typography whiteSpace='nowrap'>{t('input.maximum')}</Typography>
              <Input
                name='temperatureMax'
                label={'°C'}
                type='number'
                control={props.control}
                fullWidth
                error={!!props.errors?.temperatureMax}
                errorMessage={props.errors?.temperatureMax?.message}
                required
                InputProps={{
                  disabled: props.isView,
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </AirConditionerCard>
      <AirConditionerCard
        title={t('modal.titleAirStepLastAction')}
        description={t('modal.descriptionAirStepLastAction') as string}
        style={props.isView ? { opacity: 0.5, pointerEvents: 'none' } : {}}
      >
        <ActionNotification
          title={t('modal.titleAlarm')}
          description={t('modal.descriptionAlarm') as string}
          checked={checkNotificationActive(NotificationActions.ALARM)}
          onChangeCheckBox={() => changeNotification(NotificationActions.ALARM)}
          isView={props.isView}
          disabled={props.isView}
        />
        <ActionNotification
          title={t('modal.titleTemperature')}
          description={t('modal.descriptionTemperature') as string}
          checked={checkNotificationActive(NotificationActions.TEMPERATURE)}
          onChangeCheckBox={() =>
            changeNotification(NotificationActions.TEMPERATURE)
          }
          isView={props.isView}
          disabled={true}
        />
      </AirConditionerCard>
    </Grid>
  )
}
