import dayjs from 'dayjs'
import moment from 'moment'

function formatTime(value: number, type: 'hours' | 'minutes'): string {
  if (type === 'minutes' && value >= 60) {
    const hours = Math.floor(value / 60)
    const minutes = value % 60
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0',
    )}:00`
  } else if (type === 'minutes' && value < 60) {
    return `00:${String(value).padStart(2, '0')}:00`
  } else if (type === 'hours') {
    const hours = Math.min(value, 23) // Limita o valor máximo a 23 horas
    return `${String(hours).padStart(2, '0')}:00:00`
  } else {
    return '00:00:00' // Tratamento para outros tipos inválidos
  }
}

function convertToValidTime(value: number, type: 'hours' | 'minutes'): string {
  if (type === 'minutes') {
    // Limita o valor máximo a 59 minutos
    return String(Math.min(value, 59))
  } else if (type === 'hours') {
    // Limita o valor máximo a 23 horas
    return String(Math.min(value, 23))
  } else {
    return '' // Tratamento para outros tipos inválidos
  }
}

const getYearFromDate = (date: string) => {
  return dayjs(date).format('YYYY')
}

const getMonthFromDate = (date: string) => {
  return dayjs(date).format('MM')
}

const getMonthFromNumber = (month: number) => {
  return moment()
    .month(month - 1)
    .format('MMMM')
}

type timeType = 'hours' | 'minutes'
const createTime = (value: number, type: timeType): string => {
  const time = moment.duration(value, type)
  const formattedTime = moment.utc(time.asMilliseconds()).format('HH:mm:ss')
  return String(formattedTime)
}
// returm if hour or minute
const convertTimeType = (value: string): timeType => {
  const time = moment.duration(value)
  const hours = time.hours()
  const minutes = time.minutes()
  if (hours > 0) {
    return 'hours'
  }
  if (minutes > 0) {
    return 'minutes'
  }
  return 'hours'
}

// get time em number
const getTime = (value: string): number => {
  const time = moment.duration(value)
  const hours = time.hours()
  const minutes = time.minutes()
  if (hours > 0) {
    return hours
  }
  if (minutes > 0) {
    return minutes
  }
  return 0
}

export {
  convertTimeType,
  convertToValidTime,
  createTime,
  formatTime,
  getMonthFromDate,
  getMonthFromNumber,
  getTime,
  getYearFromDate
}

