import { Stack, StackProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Content = styled((props: StackProps) => <Stack {...props} />)(
  ({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: 240,
    height: '100%',
    minHeight: '100vh',
    background:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.main
        : theme.palette.background.default,
    padding: 16,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.background.default,
    },
  }),
)
