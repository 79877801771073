import AddBoxIcon from '@mui/icons-material/AddBox'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Button/IconButton'
import { Checkbox } from 'components/Checkbox'
import { DatePicker } from 'components/DatePicker'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { abbreviationWeekDays } from 'constants/weekDays'
import { CreateParameterType } from 'types/goalAndParameter/create-parameter'

interface Props {
  isView?: boolean
  getValues: UseFormGetValues<CreateParameterType>
  setValue: UseFormSetValue<CreateParameterType>
  control: Control<CreateParameterType, any>
  register: UseFormRegister<CreateParameterType>
  errors: FieldErrors<CreateParameterType>
  watch: UseFormWatch<CreateParameterType>
}

export const FormSecondStepOpeningDoor = ({
  control,
  errors,
  isView,
  watch,
}: Props) => {

  const { t } = useTranslation('common')

  const {
    fields: fieldsTimes,
    append: appendTimes,
    remove: removeTimes,
  } = useFieldArray({
    control,
    name: 'hours',
  })

  const {
    fields: fieldsDates,
    append: appendDates,
    remove: removeDates,
  } = useFieldArray({
    control,
    name: 'dates',
  })
  const { fields: fieldDays, update: updateDays } = useFieldArray({
    control,
    name: 'days',
  })

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography sx={{ fontWeight: 'bold', mb: 1.5 }}>
          {t('noOpeningRules')}
        </Typography>
        <Typography sx={{ fontWeight: 400, mb: 0.5 }}>
          {t('totalDoorInactivity')}
        </Typography>
        <Typography
          sx={{
            color: grey[500],
            fontWeight: 300,
            wordWrap: 'break-word',
          }}
        >
          {t('selectDaysDoorCannotOpened')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          {fieldDays?.map((field, index) => (
            <Grid key={index}>
              <Checkbox
                control={control}
                name={`days.${index}.entityId`}
                label={t(abbreviationWeekDays[index])}
                onChange={(e) => {
                  updateDays(index, {
                    ...field,
                    entityId: e.target.checked,
                  })
                }}
                disabled={isView}
              />
            </Grid>
          ))}
        </Stack>
        <Divider variant='fullWidth' sx={{ height: '2px' }} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography sx={{ fontWeight: 400, mb: 0.5 }}>
          {t('periodOfInactivity')}
        </Typography>
        <Typography
          sx={{
            color: grey[500],
            fontWeight: 300,
            wordWrap: 'break-word',
          }}
        >
          {t('setPeriodTimeDoorCannotBeOpened')}
        </Typography>
      </Grid>

      {fieldsDates?.map((field, index) => (
        <Grid item container xs={12} sm={12} key={field?.id}>
          <Grid item xs={6} sm={6}>
            <Stack direction='row' alignItems='center'>
              <Checkbox
                control={control}
                name={`dates.${index}.status`}
                label={t('from')}
                disabled={isView}
              />
              <DatePicker
                control={control}
                name={`dates.${index}.startDate`}
                value={`dates.${index}.startDate`}
                views={['year', 'month', 'day']}
                inputFormat='DD/MM/YYYY'
                disableMaskedInput
                disabled={!watch(`dates.${index}.status`) || isView}
                sx={{
                  width: '150px',
                  '& fieldset': {
                    borderColor: errors?.dates?.[index]?.startDate
                      ? (theme) => theme.palette.error.main
                      : (theme) => theme.palette.primary.main,
                  },
                  '& .MuiFormHelperText-root': {
                    color: errors?.dates?.[index]?.startDate
                      ? (theme) => theme.palette.error.main
                      : (theme) => theme.palette.primary.main,
                  },
                }}
                error={!!errors?.dates?.[index]?.startDate}
                errorMessage={errors?.dates?.[index]?.startDate?.message}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
            >
              <Typography sx={{ mr: 2 }}>{t('to')}</Typography>
              <DatePicker
                control={control}
                name={`dates.${index}.endDate`}
                value={`dates.${index}.endDate`}
                views={['year', 'month', 'day']}
                inputFormat='DD/MM/YYYY'
                disableMaskedInput
                disabled={!watch(`dates.${index}.status`) || isView}
                sx={{
                  width: '150px',
                  '& fieldset': {
                    borderColor: errors?.dates?.[index]?.endDate
                      ? (theme) => theme.palette.error.main
                      : (theme) => theme.palette.primary.main,
                  },
                  '& .MuiFormHelperText-root': {
                    color: errors?.dates?.[index]?.endDate
                      ? (theme) => theme.palette.error.main
                      : (theme) => theme.palette.primary.main,
                  },
                }}
                error={!!errors?.dates?.[index]?.endDate}
                errorMessage={errors?.dates?.[index]?.endDate?.message}
              />
              <Box>
                {index === 0 && !isView ? (
                  <IconButton
                    icon={<AddBoxIcon />}
                    onClick={() =>
                      appendDates({
                        startDate: null,
                        endDate: null,
                        status: false,
                      })
                    }
                  />
                ) : (
                  <IconButton
                    icon={<Icon name='delete' />}
                    sx={{
                      '& svg': {
                        width: '15px',
                        color: '#FFFFFF',
                        backgroundColor: (theme) => theme.palette.error.main,
                        borderRadius: 0.5,
                      },
                    }}
                    disabled={isView}
                    onClick={() => removeDates(index)}
                  />
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      ))}

      {fieldsTimes?.map((field, index) => (
        <Grid item container xs={12} sm={12} key={field?.id}>
          <Grid item xs={6} sm={6}>
            <Stack direction='row' alignItems='center'>
              <Checkbox
                control={control}
                name={`hours.${index}.status`}
                label={t('from')}
                disabled={isView}
              />
              <Input
                name={`hours.${index}.hourInitial`}
                type='time'
                control={control}
                sx={{ width: '150px' }}
                error={!!errors?.hours?.[index]?.hourInitial}
                errorMessage={errors?.hours?.[index]?.hourInitial?.message}
                InputProps={{
                  disabled: !watch(`hours.${index}.status`) || isView,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
              sx={{ mb: 2 }}
            >
              <Typography sx={{ mr: 2 }}>{t('to')}</Typography>
              <Input
                name={`hours.${index}.hourFinal`}
                type='time'
                control={control}
                sx={{ width: '150px' }}
                error={!!errors?.hours?.[index]?.hourFinal}
                errorMessage={errors?.hours?.[index]?.hourFinal?.message}
                InputProps={{
                  disabled: !watch(`hours.${index}.status`) || isView,
                }}
              />
              <Box>
                {index === 0 && !isView ? (
                  <>
                    <IconButton
                      icon={<AddBoxIcon />}
                      onClick={() =>
                        appendTimes({
                          hourInitial: '',
                          hourFinal: '',
                          status: false,
                        })
                      }
                    />
                  </>
                ) : (
                  <>
                    <IconButton
                      icon={<Icon name='delete' />}
                      sx={{
                        '& svg': {
                          width: '15px',
                          color: '#FFFFFF',
                          backgroundColor: (theme) => theme.palette.error.main,
                          borderRadius: 0.5,
                        },
                      }}
                      disabled={isView}
                      onClick={() => removeTimes(index)}
                    />
                  </>
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      ))}

    </>
  )
}
