import { IconButton } from 'components/Button/IconButton'

export function ProfileContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros dos perfis de acesso ao
        sistema. Será por meio dele que será possível definir o tipo de acesso
        para cada tipo de usuário, o cadastro desse submódulo é importante para
        o cadastro de usuários (Controle de acesso {'->'} usuários), então
        sugerimos que seja inicialmente realizado o cadastro de perfil antes de
        usuários.
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>
        <strong>Cadastrar Perfil</strong>
      </p>

      <p>
        O módulo de Perfil é uma funcionalidade de criação de um nome para um
        grupo de usuários, onde cada grupo pode receber alertas, ações e
        configurações diferentes dependendo de cada perfil que é selecionado e
        dos módulos que são selecionados para o acesso do usuário. Apenas os
        administradores do sistema ou usuários autorizados poderão acessar esse
        módulo.
      </p>
      <p>Para realizar o cadastro de um novo perfil, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Perfil
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher o campo de nome de perfil
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione os módulos e ações que o perfil
        poderá usar dentro do sistema.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        Importante: O e-mail e contato são campos onde é necessário um cuidado
        dobrado ao serem preenchidos, pois é por meio deles que os alertas,
        notificações e validações do sistema são realizados.{' '}
      </p>
      <p>
        Ao salvar o novo usuário, este receberá um e-mail e um código de acesso,
        que é uma senha temporária. Em seguida, o usuário deverá acessar ao
        sistema conforme explicado no item 1.1 desse manual.
      </p>
      <p>
        <strong>Editar Perfil</strong>
      </p>

      <p>Para realizar a edição de um perfil, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Perfil
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o perfil que vai editar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite o nome e os acessos que o perfil terá no
        sistema.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        Importante: O e-mail e contato são campos onde é necessário um cuidado
        dobrado ao serem editado, pois é por meio deles que os alertas,
        notificações e validações do sistema são realizados.{' '}
      </p>
      <p>
        <strong>Excluir Perfil</strong>
      </p>

      <p>Para realizar a exclusão de um perfil, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Perfil
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o perfil que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-lo, selecione sim e o perfil será permanentemente excluído do
        sistema.
      </p>
      <p>
        <strong>Visualizar Perfil</strong>
      </p>
      <p>Para realizar visualizar os dados de um perfil, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Perfil
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o perfil que vai visualizar dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, sendo eles: nome do perfil e módulos de acesso.
      </p>
    </>
  )
}
