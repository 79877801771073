import { ProfilePermissionType } from 'types/profile/create-profile'

import { PermissionType } from '../types/permission.type'

// Extract create permissions
export const extractCreatePermissions = (
  checkboxes: PermissionType[],
): ProfilePermissionType[] => {
  const createPermissions: ProfilePermissionType[] = []

  const extractFromCheckbox = (checkbox: PermissionType) => {
    if (checkbox.checked && checkbox.module && checkbox.type) {
      createPermissions.push({ module: checkbox.module, type: checkbox.type })
    }
    if (checkbox.children) {
      checkbox.children.forEach((child) => extractFromCheckbox(child))
    }
  }

  checkboxes.forEach((checkbox) => extractFromCheckbox(checkbox))

  return createPermissions
}
