import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Photo } from 'components/Photo'
import { Status } from 'components/Status'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { PreOSType } from 'types/pre-os/pre-os'

type Type = 'corrective' | 'preventive'

export function preOSMapper(data: PreOSType[]) {
  const { t } = useTranslation('common')
  return data.map((item) => ({
    ...item,
    photo: item?.file?.name ? (
      <Photo src={`${BASE_URL_IMAGES.concat(item?.file?.name)}`} />
    ) : (
      '-'
    ),
    formattedOccurrenceDate: moment(item.dateOccurrence).format(
      'DD/MM/YYYY [às] HH:mm',
    ),
    client: item?.user,
    shortDescription:
      item.description?.length > 60
        ? `${item.description.slice(0, 60)}...`
        : item.description,
    muiStatus: <Status type={item.status} />,
    typeFormatted: t(item.type?.toLowerCase() as Type),
  }))
}
