import { Stack, Typography, useTheme } from '@mui/material'
import { FC, useState } from 'react'

import { IconButton } from 'components/Button/IconButton'
import { Modal } from 'components/Modal'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import {
  getDescriptionByEquipmentType,
  getIconByEquipmentType,
} from 'helpers/equipment-type.helper'
import { ModalType } from 'types/modal'
import { SubareaType } from 'types/subarea/subarea'

import { LabelSidebarMonitoring } from '../MonitoringSidebar/styles'
import { LabelNameSubarea, ModalContent, ModalWrapper } from '../styles'
import { EquipmentSensorShowcase } from './EquipmentSensorShowcase'

type Props = ModalType & {
  selectedEquipment: SubareaType
}

export const ModalEquipmentDetailing: FC<Props> = ({
  open,
  onClose,
  selectedEquipment,
}) => {
  const [openImage, setOpenImage] = useState(false)
  const theme = useTheme()

  const imageUrl = `${BASE_URL_IMAGES}${selectedEquipment?.equipment?.file?.name}`

  return (
    <div
      style={{
        position: open ? 'fixed' : 'initial',
        zIndex: 100,
        left: 0,
        height: '100%',
        width: '100%',
      }}
      onClick={onClose}
    >
      <ModalWrapper
        open={open}
        background={theme.palette.background.default}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Stack
          flexDirection={'row'}
          flexWrap={'wrap'}
          marginBottom={2}
          alignItems={'center'}
        >
          <Stack
            gap={'0.5rem'}
            flex={1}
            flexWrap={'wrap'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <LabelNameSubarea>{selectedEquipment.area?.name}</LabelNameSubarea>
            <LabelSidebarMonitoring
              bgColor={
                theme.palette.mode === 'light'
                  ? theme.palette.background.paper
                  : 'transparent'
              }
            >
              <img
                src={getIconByEquipmentType(selectedEquipment.equipment.type)}
                height={15}
                width={15}
              />
              <Typography
                fontSize={'10px'}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                {getDescriptionByEquipmentType(
                  selectedEquipment.equipment.type,
                )}
              </Typography>
            </LabelSidebarMonitoring>
          </Stack>
          <IconButton onClick={onClose} variant='contained' iconName='close' />
        </Stack>

        <ModalContent
          marginBottom='0.75rem'
          background={
            theme.palette.mode === 'light'
              ? theme.palette.background.paper
              : '#0C0C25'
          }
        >
          <Typography
            fontSize={'24px'}
            fontWeight={600}
            marginBottom={'0.5rem'}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {selectedEquipment.equipment?.name}
          </Typography>
          <Stack
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={1}
            marginBottom={1}
          >
            <Typography
              fontSize={'12px'}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              <strong>Modelo:</strong> {selectedEquipment.equipment?.model}
            </Typography>
            <Typography
              fontSize={'12px'}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              <strong>Código:</strong> {selectedEquipment.equipment?.code}
            </Typography>
            <Typography
              fontSize={'12px'}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              <strong>Fabricante:</strong> {selectedEquipment.equipment?.brand}
            </Typography>
          </Stack>
          {selectedEquipment?.equipment?.file && (
            <img
              src={imageUrl}
              height={140}
              width={322}
              style={{
                cursor: 'pointer',
                objectFit: 'cover',
                borderRadius: 4,
                objectPosition: 'top center',
              }}
              onClick={() => setOpenImage(true)}
              alt={selectedEquipment.equipment?.name}
            />
          )}
        </ModalContent>

        {(selectedEquipment.equipment?.sensors ?? []).map(({ sensor }: any) => (
          <EquipmentSensorShowcase
            key={sensor.id?.value}
            sensorType={sensor?.type}
            sensorId={sensor.id?.value}
          />
        ))}

        <Modal
          open={openImage}
          onClose={() => setOpenImage(false)}
          title={'Image_001.JPG'}
          maxWidth='md'
        >
          <img
            src={imageUrl}
            height={432}
            width={'100%'}
            style={{
              borderRadius: 4,
              objectFit: 'cover',
              objectPosition: 'top center',
            }}
            alt={selectedEquipment.equipment?.name}
          />
        </Modal>
      </ModalWrapper>
    </div>
  )
}
