import { PermissionType } from '../types/permission.type'

// Toggle all children checkboxes
export const toggleChildrenCheckboxes = (
  checkboxes: PermissionType[],
  isChecked: boolean,
): PermissionType[] => {
  return checkboxes.map((checkbox) => {
    checkbox.checked = isChecked
    if (checkbox.children) {
      checkbox.children = toggleChildrenCheckboxes(checkbox.children, isChecked)
    }
    return checkbox
  })
}
