import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'

import { DatePickerWeek } from 'components/DatePicker/Week/DatePickerWeek'
import InputBase from 'components/Input/InputBase'
import { RadioGroup } from 'components/Radio/RadioGroup'

interface Props {
  date: Date | null
  periodType: string
  onChangeDate: (date: Date | null) => void
  onChangePeriodType: (type: 'day' | 'week' | 'month' | 'year') => void
}

export function SelectDate({
  date,
  periodType,
  onChangeDate,
  onChangePeriodType,
}: Props) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation('common')

  const handleChange = (newValue: Date | null) => {
    onChangeDate(newValue)
  }

  return (
    <Stack
      direction={['column', 'row']}
      justifyContent={['flex-start', 'flex-end']}
      alignItems={'center'}
      width={'100%'}
    >
      <Stack direction={'row'} spacing={2} alignItems='center'>
        <RadioGroup
          row
          name='radioGroup'
          value={periodType}
          onChange={(event) =>
            onChangePeriodType(
              event.target.value as 'day' | 'week' | 'month' | 'year',
            )
          }
          options={[
            { id: 'day', name: t('input.day') ?? '' },
            { id: 'week', name: t('input.week') ?? '' },
            { id: 'month', name: t('input.month') ?? '' },
          ]}
        />
      </Stack>
      <Box marginTop={[2, 0]} marginLeft={[0, 2]} width={['100%', 'auto']}>
        {periodType?.includes('month') ||
        periodType.includes('year') ||
        periodType.includes('day') ? (
          isDesktop ? (
            <DesktopDatePicker
              label={t('input.period')}
              views={periodType === 'month' ? ['month', 'year'] : ['day']}
              value={date}
              openTo={periodType === 'month' ? 'month' : 'day'}
              onChange={handleChange}
              renderInput={(params) => <InputBase fullWidth {...params} />}
            />
          ) : (
            <MobileDatePicker
              label={t('input.period')}
              views={periodType === 'month' ? ['month', 'year'] : ['day']}
              value={date}
              openTo={periodType === 'month' ? 'month' : 'day'}
              onChange={handleChange}
              renderInput={(params) => <InputBase fullWidth {...params} />}
            />
          )
        ) : (
          <DatePickerWeek
            defaultDate={date ?? undefined}
            onChange={(value) => handleChange(value ? new Date(value) : null)}
          />
        )}
      </Box>
    </Stack>
  )
}
