/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'

import { ValidationError } from 'types/ValidationError'

import { InputMuiPrice } from './InputMuiPrice'

type Props = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  showIcon?: boolean
  helpWithIcon?: string
  errorMessage?: any | ValidationError | string
}

export function InputPrice({ control, ...rest }: Props) {
  return (
    <>
      {control ? (
        <Controller
          name={rest?.name}
          control={control}
          defaultValue=''
          render={({ field }) => <InputMuiPrice {...rest} {...field} />}
        />
      ) : (
        <InputMuiPrice {...rest} />
      )}
    </>
  )
}
