import { Stack, Typography } from '@mui/material'

interface MyAccountItemContentProps {
  name?: string
  email?: string
}

export function MyAccountItemContent({
  name,
  email,
}: MyAccountItemContentProps) {
  return (
    <Stack>
      <Typography>{name ?? '-'}</Typography>
      <Typography
        variant='body2'
        component={'small'}
        sx={{ opacity: 0.7 }}
        fontWeight={300}
      >
        {email ?? '-'}
      </Typography>
    </Stack>
  )
}
