import { Stack, Typography } from '@mui/material'

export function DevelopedBy() {
  return (
    <Stack
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingBottom: 3,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        textTransform={'uppercase'}
        variant='body2'
        fontSize={'0.625rem'}
      >
        DESENVOLVIDO PELO INSTITUTO CREATHUS © 2023
      </Typography>
    </Stack>
  )
}
