import AreasDeMedicaoImg from 'assets/images/cards/Areas_de_medicao.svg'
import alertaBtnPanicoImg from 'assets/images/cards/BtnPanico.svg'
import CalendarioDeManutencaoImg from 'assets/images/cards/Calendario_de_manutencao.svg'
import clientesImg from 'assets/images/cards/Clientes.svg'
import configuracoesImg from 'assets/images/cards/Configuracoes.svg'
import controleDeAcessoImg from 'assets/images/cards/Controle_de_acesso.svg'
import dashboardImg from 'assets/images/cards/Dashboards.svg'
import equipamentosImg from 'assets/images/cards/Equipamentos.svg'
import escalabilidadeImg from 'assets/images/cards/Escalabilidade.svg'
import faturasImg from 'assets/images/cards/Faturas.svg'
import homeImg from 'assets/images/cards/home-img.svg'
import manutencaoImg from 'assets/images/cards/Manutencao.svg'
import mapaImg from 'assets/images/cards/Mapa.svg'
import metasEParametrosImg from 'assets/images/cards/Metas_e_parametros.svg'
import monitoramentoDeSensoresImg from 'assets/images/cards/Monitoramento_de_sensores.svg'
import monitoramentoImg from 'assets/images/cards/monitoramento-panico-jump.svg'
import montarMapaImg from 'assets/images/cards/Montar_mapa.svg'
import mapaPlanoConfigImg from 'assets/images/cards/montar-mapa-panico-jump.svg'
import notificacoesImg from 'assets/images/cards/Notificacoes.svg'
import perfisImg from 'assets/images/cards/perfil-Jump.svg'
import preOsImg from 'assets/images/cards/PreOs.svg'
import procedimentoImg from 'assets/images/cards/procedimentos-panico-jump.svg'
import relatorioImg from 'assets/images/cards/Relatorios.svg'
import sensoresImg from 'assets/images/cards/Sensores.svg'
import turnosImg from 'assets/images/cards/Turnos.svg'
import tutorialDoSistemaImg from 'assets/images/cards/Tutorial_do_sistema.svg'
import unitImage from 'assets/images/cards/unidades-jump.svg'
import usuariosImg from 'assets/images/cards/user-jump.svg'

export const CARD_IMAGES = {
  DASHBOARD: dashboardImg,
  AREAS_DE_MEDICAO: AreasDeMedicaoImg,
  CALENDARIO_DE_MANUTENCAO: CalendarioDeManutencaoImg,
  CLIENTE: clientesImg,
  CONFIGURACOES: configuracoesImg,
  CONTROLE_DE_ACESSO: controleDeAcessoImg,
  EQUIPAMENTOS: equipamentosImg,
  ESCALABILIDADE: escalabilidadeImg,
  FATURAS: faturasImg,
  MANUTENCAO: manutencaoImg,
  MAPA: mapaImg,
  METAS_E_PARAMETROS: metasEParametrosImg,
  MONITORAMENTO_DE_SENSORES: monitoramentoDeSensoresImg,
  MONTAR_MAPA: montarMapaImg,
  NOTIFICACOES: notificacoesImg,
  PERFIS: perfisImg,
  PRE_OS: preOsImg,
  SENSORES: sensoresImg,
  TURNOS: turnosImg,
  TUTORIAL_DO_SISTEMA: tutorialDoSistemaImg,
  USUARIOS: usuariosImg,
  UNIT: unitImage,
  ALERTA_BOTAO_PANICO: alertaBtnPanicoImg,
  MONITORAMENTO: monitoramentoImg,
  MAPA_PLANO_CONFIG: mapaPlanoConfigImg,
  PROCEDIMENTO: procedimentoImg,
  RELATORIO: relatorioImg,
  HOME: homeImg,
}
