/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Stack, Typography } from '@mui/material'
import { StatusOrderServiceEnum } from 'core/enums/StatusOrderServiceEnum'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Actions } from 'components/Actions'
import { IconButton } from 'components/Button/IconButton'
import { Restricted } from 'components/Restricted'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { STATUS_SCHEDULE } from 'helpers/statusSchedule'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { FormOSModal } from 'pages/maintenance/components/FormOSModal'
import { StatusScheduleType } from 'types/status-schedule'

export function Appointment(model: any) {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const navigate = useNavigate()
  const { targetedAppointmentData } = model.data

  const [openForm, setOpenForm] = useState(false)

  const startTime = moment(targetedAppointmentData.displayStartDate).format(
    'HH:mm',
  )
  const endTime = moment(targetedAppointmentData.displayEndDate).format('HH:mm')

  /* Executa a acao de deletar a ordem de servico */
  const handleDelete = (item: any) => {
    httpDelete(`/order-service/${item?.entityId?.value}`, () => {
      targetedAppointmentData.refetch()
      toast.success(t('alert.delete'))
    })
  }

  const handleCancelForm = () => {
    setOpenForm(false)
  }

  const disabledDelete = () => {
    if (targetedAppointmentData?.data?.status !== StatusOrderServiceEnum.NEW)
      return true

    return false
  }

  return (
    <>
      <Box className='showtime-preview'>
        <Stack
          direction={'row'}
          spacing={1}
          width='100%'
          color='secondary.contrastText'
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          overflow='hidden'
          position={'relative'}
        >
          <Stack>
            <Typography
              component={'span'}
              variant='body2'
              color='secondary.contrastText'
              sx={{ fontSize: 10 }}
            >
              {startTime}
              {' - '}
              {endTime}
            </Typography>
            <Typography
              component='strong'
              fontWeight={'bold'}
              color='secondary.contrastText'
              whiteSpace={'nowrap'}
              sx={{
                fontSize: 12,
              }}
            >
              {targetedAppointmentData.text?.length > 22
                ? `${targetedAppointmentData.text.slice(0, 19)}...`
                : targetedAppointmentData.text}
            </Typography>

            <Typography
              component={'span'}
              variant='body2'
              color='secondary.contrastText'
              sx={{ fontSize: 10 }}
            >
              <strong>Localização: </strong>
              {targetedAppointmentData?.localization}
            </Typography>
            <Typography
              component={'span'}
              variant='body2'
              color='secondary.contrastText'
              sx={{ fontSize: 10 }}
            >
              {targetedAppointmentData?.progress}
            </Typography>

            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
              }}
            >
              <Restricted
                module={ModuleEnum.MAIN_CALENDAR}
                permission={PermissionEnum.VIEW}
              >
                <IconButton
                  variant='text'
                  color='inherit'
                  size='small'
                  iconName={
                    STATUS_SCHEDULE.ICONS[
                      targetedAppointmentData?.statusId as StatusScheduleType
                    ]
                  }
                  onClick={() =>
                    navigate(
                      `/maintenance/schedule/details?id=${targetedAppointmentData?.data?.entityId?.value}`,
                    )
                  }
                />
              </Restricted>
            </Box>
          </Stack>

          <Actions
            color='inherit'
            size='medium'
            options={[
              {
                type: 'followOS',
                router: `/maintenance/schedule/details?id=${targetedAppointmentData?.data?.entityId?.value}`,
                module: ModuleEnum.MAIN_CALENDAR,
                permission: PermissionEnum.VIEW,
              },
              {
                type: 'edit',
                action: () => setOpenForm(true),
                module: ModuleEnum.MAIN_CALENDAR,
                permission: PermissionEnum.EDIT,
              },
              {
                disabled: disabledDelete(),
                type: 'delete',
                module: ModuleEnum.MAIN_CALENDAR,
                permission: PermissionEnum.DELETE,
                action: () => {
                  sweetAlert({
                    onSuccess: () =>
                      handleDelete(targetedAppointmentData?.data),
                  })
                },
              },
            ]}
          />
        </Stack>
      </Box>

      {openForm && (
        <FormOSModal
          data={{
            id: targetedAppointmentData?.data?.entityId?.value,
            name: targetedAppointmentData?.data?.name,
            nameEquipment: targetedAppointmentData?.data?.nameEquipment,
            model: targetedAppointmentData?.data?.model,
            serialNumber: targetedAppointmentData?.data?.serialNumber,
            type: targetedAppointmentData?.data?.type,
            location: targetedAppointmentData?.data?.location,
            priority: targetedAppointmentData?.data?.priority,
            dateOccurrence: targetedAppointmentData?.data?.dateOccurrence,
            hour: moment(targetedAppointmentData?.data?.dateOccurrence).format(
              'HH:mm',
            ),
            description: targetedAppointmentData?.data?.description,
            requester: targetedAppointmentData?.data?.user,
            attendants: targetedAppointmentData?.data?.attendants,
          }}
          open={openForm}
          onClose={handleCancelForm}
          onSuccess={() => {
            targetedAppointmentData.refetch()
            handleCancelForm()
          }}
        />
      )}
    </>
  )
}
