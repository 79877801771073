import {
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMoreOutlined,
} from '@mui/icons-material'
import {
  Box,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Error } from 'components/Error'
import { addAlpha } from 'helpers/addAlpha'
import { uuid } from 'helpers/uuid'

import { ColumnType } from '../../types/table/column'
import { Actions, ActionType } from '../Actions'
import { Loading } from '../Loading'
import { TablePagination } from './TablePagination'

export interface TableActionType<T> extends Omit<ActionType, 'action'> {
  onClick?: (row: T) => void
}

interface Props<T> extends TableProps {
  columns: ColumnType[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows?: any[]
  actions?: TableActionType<T>[]
  hasActions?: boolean
  orderBy?: string
  onOrderBy?: (value: string) => void
  order?: 'DESC' | 'ASC'
  isLoading?: boolean
  isFetching?: boolean
  error?: Error | null
  onToggleOrder?: () => void
  pagination?: {
    page: number
    limit?: number
    count?: number
    onChangePage: (newPage: number) => void
  }
}

export function Table<T>({
  columns = [],
  rows = [],
  actions = [],
  hasActions,
  orderBy,
  onOrderBy,
  order,
  onToggleOrder,
  isLoading,
  isFetching,
  error,
  pagination,
  children,
  ...rest
}: Props<T>) {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <TableContainer
      sx={{
        maxHeight: 'auto',
        '&::-webkit-scrollbar': {
          width: 6,
          height: 6,
          background: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: (theme) => theme.palette.background.default,
        },
      }}
    >
      <Box height={5} width='100%'>
        {isFetching && (
          <Loading
            mode='linear'
            sx={{ padding: 0, alignItems: 'flex-start' }}
          />
        )}
      </Box>
      {isDesktop ? (
        <MuiTable stickyHeader aria-label='sticky table' {...rest}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column?.align}
                  sx={{
                    minWidth: column?.minWidth,
                    textTransform: 'uppercase',
                    background: (theme) =>
                      addAlpha(theme.palette.secondary.light, 0.6),
                    color: (theme) => theme.palette.secondary.contrastText,
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.secondary.light}`,
                    borderRight: (theme) =>
                      `1px solid ${theme.palette.secondary.light}`,
                    cursor: onOrderBy ? 'pointer' : 'default',
                    verticalAlign: 'top',
                    fontSize: 12,
                    '&:nth-last-child(2)': {
                      borderRight: 'none',
                    },
                  }}
                  onClick={() => {
                    onOrderBy?.(column.id)
                    onToggleOrder?.()
                  }}
                >
                  {onOrderBy ? (
                    <Stack
                      flexDirection='row'
                      justifyContent={
                        column?.align === 'right' ? 'flex-end' : 'flex-start'
                      }
                      alignItems='center'
                    >
                      {column.label}
                      {orderBy && (
                        <>
                          {column.id !== 'actions' && (
                            <>
                              {orderBy !== column.id ? (
                                <UnfoldMoreOutlined
                                  color='disabled'
                                  fontSize='small'
                                />
                              ) : (
                                <>
                                  {order === 'DESC' && (
                                    <ArrowDropUp color={'inherit'} />
                                  )}
                                  {order === 'ASC' && (
                                    <ArrowDropDown color={'inherit'} />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Stack>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}

              {(!!actions?.length || hasActions) && (
                <TableCell
                  sx={{
                    textTransform: 'uppercase',
                    background: (theme) =>
                      addAlpha(theme.palette.secondary.light, 0.6),
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.secondary.light}`,
                    cursor: onOrderBy ? 'pointer' : 'default',
                    verticalAlign: 'top',
                  }}
                >
                  {' '}
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {!!rows?.length &&
              rows?.map((row, rowIndex) => {
                return (
                  <TableRow hover key={rowIndex}>
                    {columns.map((column, columnIndex) => {
                      const value = row[column.id]
                      return (
                        <TableCell
                          key={columnIndex}
                          align={column?.align}
                          sx={{ border: 'none' }}
                        >
                          {column?.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}

                    {!!actions?.length && (
                      <TableCell align='right' sx={{ border: 'none' }}>
                        <Actions
                          color='inherit'
                          options={actions?.map((item) => ({
                            type: item?.type ?? 'add',
                            disabled: item?.disabled,
                            router: item?.router,
                            title: item?.title,
                            action: item?.onClick
                              ? () => item.onClick?.(row)
                              : undefined,
                            module: item?.module,
                            permission: item?.permission,
                          }))}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}

            {children}
          </TableBody>
        </MuiTable>
      ) : (
        <MuiTable stickyHeader aria-label='sticky table' {...rest}>
          {!!rows?.length &&
            rows?.map((row) => (
              <TableBody key={uuid()} sx={{}}>
                {columns.map((column) => {
                  const value = row[column.id]

                  return (
                    <TableRow key={uuid()} hover>
                      <TableCell
                        component='th'
                        align={column?.align}
                        sx={{
                          border: 'none',
                          minWidth: column?.minWidth,
                          textTransform: 'uppercase',
                          background: (theme) =>
                            addAlpha(theme.palette.secondary.light, 0.6),
                          borderRight: (theme) =>
                            `1px solid ${theme.palette.secondary.light}`,
                          cursor: onOrderBy ? 'pointer' : 'default',
                          verticalAlign: 'top',
                        }}
                      >
                        {column?.label}
                      </TableCell>
                      <TableCell align={'right'} sx={{ border: 'none' }}>
                        {column?.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {!!actions?.length && (
                  <TableRow hover>
                    <TableCell
                      align='left'
                      sx={{
                        border: 'none',
                        textTransform: 'uppercase',
                        background: (theme) =>
                          addAlpha(theme.palette.secondary.light, 0.6),
                        borderRight: (theme) =>
                          `1px solid ${theme.palette.secondary.light}`,
                        cursor: onOrderBy ? 'pointer' : 'default',
                        verticalAlign: 'top',
                      }}
                    >
                      {t('table.action')}
                    </TableCell>
                    <TableCell align='right' sx={{ border: 'none' }}>
                      <Actions
                        options={actions?.map((item) => ({
                          type: item?.type ?? 'add',
                          disabled: item?.disabled,
                          router: item?.router,
                          title: item?.title,
                          action: item?.onClick
                            ? () => item.onClick?.(row)
                            : undefined,
                        }))}
                      />
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            ))}
          {children && <TableBody>{children}</TableBody>}
        </MuiTable>
      )}

      {!rows.length && isLoading && <Loading mode='circular' />}

      {!rows.length && <Error error={error} />}

      {pagination &&
        !!pagination?.count &&
        pagination.count >= (pagination.limit ?? 10) && (
          <TablePagination
            page={pagination.page}
            limit={pagination.limit}
            count={pagination.count}
            onChangePage={pagination.onChangePage}
          />
        )}
    </TableContainer>
  )
}
