import { Card, Grid, Stack, useTheme, CardContent } from '@mui/material'
import { FC } from 'react'

import { Icon } from 'components/Icon'
import { SensorDoor } from 'services/dashboard/sensordoorDashboard.service'

import { Text } from './style'

interface SensorCardDetailsProps {
  data: SensorDoor
  width?: string | number
}

export const SensorCardDetails: FC<SensorCardDetailsProps> = ({
  width,
  data,
}) => {
  const theme = useTheme()

  const COLOR = {
    open: theme.palette.success.main,
    close: '#FB262F',
  }

  function formatarTempo(segundos: number) {
    const dias = Math.floor(segundos / (60 * 60 * 24))
    const horas = Math.floor((segundos % (60 * 60 * 24)) / (60 * 60))
    const minutos = Math.floor((segundos % (60 * 60)) / 60)
    const segundosRestantes = segundos % 60

    let tempoFormatado = ''

    if (dias > 0) {
      tempoFormatado += dias + 'd '
    }

    if (horas > 0) {
      tempoFormatado += horas + 'h '
    }

    if (minutos > 0) {
      tempoFormatado += minutos + 'min '
    }

    if (segundosRestantes > 0 && dias === 0 && horas === 0 && minutos === 0) {
      tempoFormatado += segundosRestantes + 's '
    }

    return tempoFormatado.trim() + ' aberta'
  }

  return (
    <>
      <Card
        elevation={2}
        sx={{
          maxWidth: width ? width : '100%',
          background:
            COLOR[
              data?.isopening != true ? 'open' : ('close' as keyof typeof COLOR)
            ],
          px: 1,
        }}
      >
        <CardContent
          sx={{
            p: 2,
            background: (theme) => theme.palette.background.default,
            '&:hover': {
              background: (theme) => theme.palette.background.paper,
              transition: 'all 0.2s ease',
            },
          }}
        >
          <Stack direction='column'>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Text variant='subtitle2' size={16} fontWeight={600}>
                  {data?.nameSensor}
                </Text>

                <Stack
                  direction='column'
                  alignItems='flex-start'
                  justifyContent='center'
                >
                  <Text variant='body2' size={10}>
                    <Icon name='placeOutLine' />
                    Local: {data?.local}
                  </Text>
                  <Text
                    variant='body2'
                    size={10}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Icon name='timeOpeningDoor' />
                    {data?.amount} aberturas
                  </Text>
                  <Text variant='body2' size={10}>
                    <Icon name='timeOutLine' />
                    {`${formatarTempo(data.timeseconds)}`}
                  </Text>
                </Stack>
              </Grid>
              <Grid item>
                <Stack
                  direction='column'
                  alignItems='center'
                  sx={{
                    '& > svg': {
                      width: 70,
                      height: 70,
                      viewBox: '0 0 70 70 !important',
                      fontSizeAdjust: '0.58',
                      '& > path': {
                        fill: COLOR[
                          data?.isopening != true
                            ? 'open'
                            : ('close' as keyof typeof COLOR)
                        ],
                        scale: '0.4',
                      },
                    },
                  }}
                >
                  <Text
                    size={16}
                    fontWeight={600}
                    sx={{
                      color:
                        COLOR[
                          data?.isopening != true
                            ? 'open'
                            : ('close' as keyof typeof COLOR)
                        ],
                    }}
                  >
                    {(data?.isopening == true ? 'open' : 'close') === 'open'
                      ? 'Aberta'
                      : 'Fechada'}
                  </Text>
                  {(data?.isopening == true ? 'open' : 'close') === 'open' ? (
                    <Icon name='openDoor' />
                  ) : (
                    <Icon name='closedDoor' />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}
