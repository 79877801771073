import { httpClient } from 'services/api/httpClient'
import { PaginationType } from 'types/pagination'
import { PreOSType } from 'types/pre-os/pre-os'

type NewType = PaginationType<PreOSType>

export const getPreOS = async (
  page = 1,
  search = '',
  orderBy?: string,
): Promise<NewType> => {
  const response = await httpClient.get('/order-service', {
    params: {
      page: page - 1,
      search,
    },
  })
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data

  return {
    results: items,
    limit: perPage,
    count: total,
    order: orderBy,
  }
}
