import { IUpdateOrderService } from '../../core/interfaces/order-service/IUpdateOrderService'
import { httpClient } from '../api/httpClient'

export const updatePreOS = (id: number, payload: IUpdateOrderService) => {
  return httpClient.put(`/order-service/${id}`, payload)
}

const refuse = (id: number, payload: IUpdateOrderService) => {
  return httpClient.put(`/order-service/refuse/${id}`, payload)
}

const accept = (id: number, payload: IUpdateOrderService) => {
  return httpClient.put(`/order-service/accept/${id}`, payload)
}

export { refuse, accept }
