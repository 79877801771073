import { CssBaseline } from '@mui/material'
import { LocalizationProvider, ptBR } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import 'moment/locale/pt'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RouterProvider } from 'react-router-dom'
import * as yup from 'yup'

import { AuthProvider } from 'contexts/AuthProvider'
import { yupLocale } from 'helpers/yupLocale'

import { CustomThemeProvider } from './contexts/CustomThemeProvider'
import { SensorsMenuProvider } from './contexts/SensorsMenuProvider'
import { SweetAlertProvider } from './contexts/SweetAlertProvider'
import { routers } from './routers/index.router'

import 'devextreme/dist/css/dx.dark.css'
import 'react-toastify/dist/ReactToastify.css'
import 'reactflow/dist/style.css'
import './styles/global.css'
import { LoadingProvider } from 'contexts/LoadingProvider'

yup.setLocale(yupLocale)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 9 * (60 * 1000), // 9 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
      refetchOnWindowFocus: true,
      retry: 1,
    },
  },
})

function App() {
  const { i18n } = useTranslation('common')
  moment.locale(i18n.language)
  moment.updateLocale(i18n.language, {
    week: {
      dow: 0, // 0 é domingo, 1 é segunda-feira, e assim por diante
    },
  })

  return (
    <Suspense fallback='loading'>
      <AuthProvider>
        <CustomThemeProvider>
          <CssBaseline />
          <LocalizationProvider
            dateAdapter={AdapterMoment as any}
            adapterLocale={i18n.language}
            localeText={
              ptBR.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <SweetAlertProvider>
              <LoadingProvider>
                <QueryClientProvider client={queryClient}>
                  <SensorsMenuProvider>
                    <RouterProvider router={routers} />
                  </SensorsMenuProvider>

                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </LoadingProvider>
            </SweetAlertProvider>
          </LocalizationProvider>
        </CustomThemeProvider>
      </AuthProvider>
    </Suspense>
  )
}

export { App }
