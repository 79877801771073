import { isEmpty } from 'lodash'
import { httpClient } from 'services/api/httpClient'
import { FindSensor, SensorAndValueType } from 'types/sensor-monitor/find-sensor-monitor'

export const findBySensorsAndValueTypes = (sensorsAndValueTypes: SensorAndValueType[]): Promise<FindSensor[]> => {
  if(isEmpty(sensorsAndValueTypes)) return Promise.resolve([])

  return httpClient.post<FindSensor[]>(
    '/sensor-monitor/last-data',
    sensorsAndValueTypes,
  )
  .then(d => d.data)
  .catch(() => [])
}
