import { Search } from '@mui/icons-material'
import { InputAdornment, TextFieldProps } from '@mui/material'
import { ForwardedRef, forwardRef } from 'react'

import InputBase from '../InputBase'

type Props = TextFieldProps & {
  name?: string
  hiddenIcon?: boolean
}

export const InputSearchMui = forwardRef(
  ({ hiddenIcon, ...rest }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <InputBase
        fullWidth
        {...rest}
        type='search'
        color={rest?.error ? 'error' : rest?.color}
        InputProps={
          !hiddenIcon
            ? {
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search fontSize='small' />
                  </InputAdornment>
                ),
              }
            : undefined
        }
        ref={ref}
      />
    )
  },
)

InputSearchMui.displayName = 'InputSearchMui'
