import { Box } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { useMapStore } from 'store/map.store'

export function BackgroundNodes() {
  const [searchParams] = useSearchParams()
  const { maps } = useMapStore()

  const mapId = searchParams.get('mapId')
  const image = maps?.find((image) => image.entityId.value === mapId)

  return (
    <Box
      component='img'
      src={image ? `${BASE_URL_IMAGES}${image.mapImage?.fileName}` : undefined}
      sx={{
        height: 'auto',
        width: 'auto',
        opacity: 0.6,
      }}
    />
  )
}
