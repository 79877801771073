/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { formatNumbersDashboard } from 'helpers/formatNumbersDashboard'
import {
  getConsumedEnergy,
  getContractedEnergy,
} from 'pages/dashboard/mappers/graphic-energy-contracted-vs-consumed.mapper'
import { dashboardGenerate as energyDashboard } from 'services/invoice/energy/dashboardGenerate'
import { dashboardGenerate } from 'services/invoice/mercadoLivre/dashboardGenerate'

interface Props {
  isPreview?: boolean
}

export function useContractedVSConsumedChart(props: Props) {
  const { isPreview } = props

  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())

  const [period, setPeriod] = useState<PeriodType>('year')
  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data: dataContracted, refetch: refetchContracted } = useQuery({
    queryKey: ['dashboardGenerate', date],
    queryFn: () => dashboardGenerate(String(dayjs(date).format('YYYY'))),
    select: (data) => {
      return getContractedEnergy(data?.results || [])
    },
  })

  const { data: dataConsumption, refetch: refetchConsumption } = useQuery({
    queryKey: ['energyDashboardGenerate', date],
    queryFn: () => energyDashboard(String(dayjs(date).format('YYYY'))),
    select: (data) => {
      return getConsumedEnergy(data?.results || [])
    },
  })

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetchContracted()
      refetchConsumption()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const options: ChartOptions = {
    responsive: true,
    interaction: {
      intersect: false,
      axis: 'x',
    },
    plugins: {
      legend: {
        display: isDesktop && !isPreview,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return formatNumbersDashboard({
              context,
              suffix: DashboardTooltipSuffixEnum.MEGAWATT,
            })
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          display: !isPreview,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value + ' MW'
          },
        },
      },
      x: {
        ticks: {
          display: !isPreview,
        },
      },
    },
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      labels: [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ],
      datasets: [
        {
          type: 'line' as const,
          label: t('chart.energy.contractedDemand', {
            abbreviation: '(MW)',
          }),
          data: dataContracted?.contracted ?? [],
          backgroundColor: 'transparent',
          borderColor: '#FF6F00',
          fill: false,
          stepped: true,
        },
        {
          type: 'bar' as const,
          label: t('chart.energy.consumed', {
            abbreviation: '(MW)',
          }),
          data: dataConsumption?.consumption ?? [],
          borderRadius: 20,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.energy[0],
          ),
        },
      ],
    }

    setChartData(chartData)
  }, [
    i18n.language,
    date,
    dataContracted?.contracted,
    dataConsumption?.consumption,
  ])

  return {
    isDesktop,
    date,
    options,
    setDate,
    chartData,
    chartRef,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
  }
}
