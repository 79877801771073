import { Icon, LatLngLiteral, LeafletMouseEvent } from 'leaflet'
import { Marker, MarkerProps, useMapEvent } from 'react-leaflet'

import { PinVariant, getIconByVariant } from 'helpers/get-pin-variant'

interface MapPinProps extends MarkerProps {
  variant?: 'default' | 'primary' | 'secondary' | 'jump-circular'
  isEditMode?: boolean
  onChangeLocation?: (location: LatLngLiteral) => void
  iconPin?: Icon
  onClick?: () => void
}

export const MapPin = ({
  children,
  position,
  onChangeLocation,
  isEditMode = false,
  variant = 'default',
  iconPin,
  onClick,
  ...rest
}: MapPinProps) => {
  function handleClickInMap(event: LeafletMouseEvent) {
    if (!isEditMode && onChangeLocation) onChangeLocation(event.latlng)
  }

  if (onChangeLocation) {
    useMapEvent('click', handleClickInMap)
  }

  const customIcon = getIconByVariant(variant as PinVariant) ?? iconPin

  return (
    <Marker
      icon={customIcon}
      position={position}
      {...rest}
      clickable={true}
      eventHandlers={{ click: onClick }}
    >
      {children}
    </Marker>
  )
}
