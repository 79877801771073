import jwtDecode from 'jwt-decode'

import { ENVIRONMENT } from 'constants/environment'
import { myAccountService } from 'services/my-account.service'
import { setUser } from 'store/auth.store'
import { UserType } from 'types/user/userType'

export async function userLoader() {
  const token = localStorage.getItem(
    `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
  )

  if (!token) return false

  const user = jwtDecode<UserType>(token)

  if (!user) return false

  if (user?.profile) return true

  try {
    const getUser = await myAccountService.get(user?.id ?? 0)

    setUser({
      ...user,
      ...getUser,
    })
  } catch (error) {
    return false
  }

  return true
}
