import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderAction } from 'components/HeaderAction'
import { Tabs } from 'components/Tabs'
import { ModuleEnum, PermissionEnum } from 'constants/modules'

import { Goal } from './components/goalAndParameter/Goal'
import { Parameter } from './components/goalAndParameter/Parameter'
import { QuantityProduced } from './components/goalAndParameter/QuantityProduced'

export function GoalAndParameterPage() {
  const { t } = useTranslation('common')
  const [search, setSearch] = useState('')
  const [tab, setTab] = useState(1)
  const [openForm, setOpenForm] = useState(false)

  const tabOptions = [
    {
      label: t('goals'),
      value: 1,
    },
    {
      label: t('parameters'),
      value: 2,
    },
    {
      label: t('quantityProduced'),
      value: 3,
    },
  ]

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const onButtonTitle = (tab: number) => {
    if (tab === 1) return t('goalAndParameter.goal.new')

    if (tab === 2) return t('goalAndParameter.parameter.new')

    return t('button.title.new')
  }

  return (
    <>
      <HeaderAction
        onCreate={() => setOpenForm(true)}
        buttonTitle={onButtonTitle(tab)}
        module={ModuleEnum.CONF_GOAL}
        permission={PermissionEnum.CREATE}
      />

      <Tabs options={tabOptions} value={tab} onChange={handleChangeTab}>
        <Goal
          currentTab={tab}
          index={1}
          search={search}
          openForm={openForm}
          onCloseForm={() => setOpenForm(false)}
        />
        <Parameter
          currentTab={tab}
          index={2}
          search={search}
          openForm={openForm}
          onCloseForm={() => setOpenForm(false)}
        />
        <QuantityProduced
          currentTab={tab}
          index={3}
          search={search}
          openForm={openForm}
          onCloseForm={() => setOpenForm(false)}
        />
      </Tabs>
    </>
  )
}
