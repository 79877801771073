import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Input } from 'components/Input'
import { MaskedInputPhoneNumber } from 'components/Input/mask/MaskedInputPhoneNumber'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { Toggle } from 'components/Toggle'
import { getAllProfiles } from 'services/profiles/getAllProfiles'
import { userService } from 'services/user.service'
import { ModalType } from 'types/modal'
import { CreateUserType, UsersType } from 'types/user/user'

import { schemaCreateUser } from '../validations/create-user.validation'

interface Props extends ModalType {
  data: UsersType | null
  isView?: boolean
}

export function FormUserModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const { data: profilesData } = useQuery(
    'list-profiles',
    () => getAllProfiles(),
    {
      refetchOnWindowFocus: false,
    },
  )

  const profiles =
    profilesData?.map((item) => ({ id: item.id, name: item.name })) ?? []

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateUserType>({
    resolver: yupResolver(schemaCreateUser()),
    defaultValues: {
      name: data?.name ?? '',
      email: data?.email ?? '',
      contact: data?.contact ?? '',
      profile: data?.profile?.id?.value ?? '',
      role: data?.role ?? '',
      rfid: data?.rfid ?? '',
      active: data?.active ?? true,
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('users')
    queryClient.invalidateQueries('all-users')
    onClose()
  }

  const mutationCreate = useMutation(
    (formData: CreateUserType) => {
      return userService.create(formData)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (formData: CreateUserType) => {
      return userService.update(data?.id ?? 0, formData)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateUserType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('user.view')

    if (data?.id) return t('user.edit')

    return t('user.new')
  }

  const title = getTitle()

  return (
    <>
      <Modal title={title} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          {data?.createdAt && (
            <Grid item xs={12}>
              <Typography>
                {t('createdIn')}{' '}
                {moment(data.createdAt).format('DD/MM/YYYY [às] HH:mm')}
              </Typography>
            </Grid>
          )}

          <Grid item xs={8} sm={9}>
            <Select
              name='profile'
              label={t('input.selectProfile') ?? ''}
              options={profiles}
              control={control}
              fullWidth
              error={!!errors?.profile}
              errorMessage={errors?.profile?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Toggle name='active' disabled={isView} control={control} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='name'
              label={t('input.fullName') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.name}
              errorMessage={errors?.name?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='role'
              label={t('input.role') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.role}
              errorMessage={errors?.role?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name='email'
              type='email'
              label={t('input.email') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.email}
              errorMessage={errors?.email?.message}
              required
              disabled={isView}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              name='contact'
              label={t('input.contact') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.contact}
              errorMessage={errors?.contact?.message}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: MaskedInputPhoneNumber as any,
              }}
              required
              disabled={isView}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              name='rfid'
              label={'RFID'}
              control={control}
              fullWidth
              error={!!errors?.rfid}
              errorMessage={errors?.rfid?.message}
              required
              disabled={isView}
            />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
