import { GraphicConsumptionGeneralType } from 'types/invoice/dashboard/energy/graphic-consumption-general'
const getConsumptionGraphicData = (data: GraphicConsumptionGeneralType[]) => {
  const month = new Array(12).fill(0)
  const consumption = new Array(12).fill(0)
  const goal = new Array(12).fill(0)
  const generalCost = new Array(12).fill(0)
  const totalPayment = new Array(12).fill(0)
  data.map((item) => {
    month[Number(item.month) - 1] = item.month
    consumption[Number(item.month) - 1] = item.consumption
    goal[Number(item.month) - 1] = item.goal
    generalCost[Number(item.month) - 1] = item.generalCost
    totalPayment[Number(item.month) - 1] = item.totalPayment
  })

  return {
    month,
    consumption,
    goal,
    generalCost,
    totalPayment,
  }
}

export { getConsumptionGraphicData }
