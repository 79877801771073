import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'

import { httpClient } from 'services/api/httpClient'
import { EquipmentType } from 'types/equipment/equipment'

export const getAllEquipmentByType = async (type: EquipmentTypeEnum) => {
  const { data } = await httpClient.get<EquipmentType[]>(
    `equipment/type/${type}`,
  )

  return data
}
