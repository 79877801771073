import { LIMIT_PER_PAGE } from 'constants/pagination'
import { PaginationType } from 'types/pagination'
import { SensorHistoricType } from 'types/sensor/sensor-historic'

const findAll = async (
  sensorId?: number | string,
  page = 1,
  search = '',
  orderBy?: string,
): Promise<PaginationType<SensorHistoricType>> => {
  if (!sensorId)
    return {
      results: [],
      limit: 0,
      count: 0,
    }

  const offset = page - 1
  const limit = LIMIT_PER_PAGE
  return {
    results: [],
    limit,
    count: 0,
    order: orderBy,
  }
}

export const sensorHistoricService = {
  findAll,
}
