/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material'
import { StatusOrderServiceEnum } from 'core/enums/StatusOrderServiceEnum'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { HeaderAction } from 'components/HeaderAction'
import { Loading } from 'components/Loading'
import { Restricted } from 'components/Restricted'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { listOrderServicePeriod } from 'services/order-service/listOrderServicePeriod'
import { OSType } from 'types/pre-os/pre-os'

import { FormOSModal } from './components/FormOSModal'
import { SelectDate } from './components/SelectDate'
import { Status } from './components/Status'
import { Timelines } from './components/Timelines'

type CellType = {
  cellData: {
    endDate: Date
    startDate: Date
    groups: {
      priority: number
    }
  }
}

export function SchedulePage() {
  const { t } = useTranslation('common')

  const [status, setStatus] = useState(['opened', 'closed', 'unresolved'])
  const [date, setDate] = useState<Date | null>(new Date())
  const [periodType, setPeriodType] = useState<
    'day' | 'week' | 'month' | 'year'
  >('month')
  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<any | null>(null)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    OSType[],
    Error
  >({
    queryKey: ['order-service-period', date, periodType],
    queryFn: () => listOrderServicePeriod(date ?? new Date(), periodType),
  })

  const handleCreate = ({ cellData }: CellType) => {
    setOpenForm(true)
  }

  const onClearSelected = () => setSelected(null)

  const handleCancelForm = () => {
    setOpenForm(false)
    setOpenFormView(false)
    onClearSelected()
  }

  return (
    <>
      <HeaderAction onSearch={(value) => setSearch(value)} />

      <Box
        display='flex'
        flexDirection={['column', 'row']}
        justifyContent={['flex-start', 'space-between']}
        alignItems={['flex-start', 'center']}
      >
        <Status
          values={[
            StatusOrderServiceEnum.NEW,
            StatusOrderServiceEnum.FINISHED,
            StatusOrderServiceEnum.IN_PROGRESS,
            StatusOrderServiceEnum.REFUSED,
            StatusOrderServiceEnum.NOT_SOLVED,
            StatusOrderServiceEnum.ACCEPTED,
            StatusOrderServiceEnum.NOT_ACCEPTED,
          ]}
        />

        <SelectDate
          date={date}
          periodType={periodType}
          onChangeDate={setDate}
          onChangePeriodType={setPeriodType}
        />
      </Box>

      {isLoading && <Loading />}

      {!isLoading && (
        <Timelines
          timeline={periodType ?? 'month'}
          date={date ?? undefined}
          onChangeDate={setDate}
          status={status}
          onCellClick={(event) => handleCreate(event)}
          data={data ?? []}
          refetch={refetch}
        />
      )}

      <Restricted
        module={ModuleEnum.MAIN_CALENDAR}
        permission={PermissionEnum.CREATE}
      >
        <>
          {openForm && (
            <FormOSModal
              data={selected}
              isView={openFormView}
              open={openForm}
              onClose={handleCancelForm}
              onSuccess={() => {
                refetch()
                handleCancelForm()
              }}
            />
          )}
        </>
      </Restricted>
    </>
  )
}
