/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'

import { ValidationError } from 'types/ValidationError'

import { InputMui } from './InputMui'

type Props = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  showIcon?: boolean
  helpWithIcon?: string
  errorMessage?: any | ValidationError | string
  isDisabled?: boolean
}

const styleTime = {
  '&:hover': {
    '.MuiInputLabel-root[data-shrink="false"]': {
      background: '#26264f',
    },
  },
  '.MuiInputLabel-root[data-shrink="false"]': {
    left: '-2px',
    background: '#141432',
  },
}

export function Input({ control, ...rest }: Props) {
  const inputIsTime = rest.type === 'time'
  if (inputIsTime) {
    rest.sx = { ...styleTime, ...rest.sx }
  }

  return (
    <>
      {control ? (
        <Controller
          name={rest?.name}
          control={control}
          defaultValue=''
          render={({ field }) => (
            <InputMui {...rest} {...field} disabled={rest?.['isDisabled']} />
          )}
        />
      ) : (
        <InputMui {...rest} />
      )}
    </>
  )
}
