/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { InputAdornment, TextFieldProps, Tooltip } from '@mui/material'
import { ForwardedRef, forwardRef } from 'react'

import { ValidationError } from 'types/ValidationError'

import { HelpIcon } from '../Icon/Help'
import { ErrorMessage } from './ErrorMessage'
import InputBase from './InputBase'

type Props = TextFieldProps & {
  name?: string
  showIcon?: boolean
  helpWithIcon?: string
  errorMessage?: any | ValidationError | string
  InputProps?: any
}

export const InputMuiPrice = forwardRef(
  (
    { showIcon, helpWithIcon, errorMessage, InputProps, ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    function formatInputMuiPrice(value: any) {
      if (!value) return value

      let v = value.replace(/\D/g, '')
      v = (v / 100).toFixed(2) + ''
      v = v.replace('.', ',')
      v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

      return v
    }

    return (
      <InputBase
        autoComplete='off'
        {...rest}
        type='text'
        helperText={
          errorMessage ? (
            <ErrorMessage error={errorMessage} />
          ) : (
            rest?.helperText
          )
        }
        color={rest?.error ? 'error' : rest?.color}
        onChange={(event) => {
          const value = formatInputMuiPrice(event.target.value)
          rest?.onChange?.(value)
        }}
        InputProps={
          showIcon || helpWithIcon?.length
            ? {
                endAdornment: (
                  <InputAdornment position='end'>
                    {!helpWithIcon?.length && rest?.color === 'success' && (
                      <CheckCircleOutline fontSize='small' color='success' />
                    )}
                    {!helpWithIcon?.length && rest?.error && (
                      <CancelOutlined fontSize='small' color='error' />
                    )}
                    {!!helpWithIcon?.length && (
                      <Tooltip title={helpWithIcon} arrow placement='top'>
                        <HelpIcon />
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment
                    position='start'
                    sx={{ background: 'transparent', marginRight: 0 }}
                  >
                    R$
                  </InputAdornment>
                ),
                inputComponent: InputProps?.inputComponent,
              }
            : InputProps
            ? InputProps
            : {
                startAdornment: (
                  <InputAdornment
                    position='start'
                    sx={{ background: 'transparent', marginRight: 0 }}
                  >
                    R$
                  </InputAdornment>
                ),
              }
        }
        ref={ref}
      />
    )
  },
)

InputMuiPrice.displayName = 'InputMuiPrice'
