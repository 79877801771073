import { IconButton } from 'components/Button/IconButton'

export function MontarMapaContent() {
  return (
    <>
      <p>
        Nesse submódulo é realizada a montagem do mapa da fábrica. Para realizar
        a montagem, é necessário que o cadastro de sensores (Configurações{' '}
        {'->'} Sensores), equipamentos (Configurações {'->'} Equipamentos),
        parâmetros (Configurações {'->'} Metas e Parâmetros) e áreas (Mapa{' '}
        {'->'} Áreas de Medição).
      </p>
      <p>
        Para montar o mapa da empresa, é necessário ter uma imagem nos formatos
        jpg, png, img com o tamanho de até 25 MB. Entrando no submódulo de
        montar mapa, o sistema informará esses dados, você deverá segurar e
        soltar a imagem dentro do espaço disponibilizado. O sistema,
        normalmente, já vem por padrão com uma imagem disponibilizada no início
        do seu desenvolvimento. Caso necessite trocar a imagem, clique no botão{' '}
        <IconButton iconName='camera' variant='text' color='inherit' />
      </p>
      <p>
        Para poder visualizar mais de perto o mapa, use o Scroll do mouse ou
        clique no botão{' '}
        <IconButton iconName='zoomOut' variant='text' color='inherit' />
        <IconButton iconName='zoomIn' variant='text' color='inherit' />
      </p>
      <p>
        Para remover a visualização do mapa e visualizar apenas os sensores,
        clique em visualizar{' '}
        <IconButton iconName='hidden' variant='text' color='inherit' />
      </p>
      <p>
        Para cadastrar um sensor, equipamento ou alimentador* no mapa, basta:
      </p>
      <p>
        <strong>Passo 1:</strong> Clicar com o botão esquerdo em qualquer parte
        do mapa
      </p>
      <p>
        <strong>Passo 2:</strong> Uma tela irá surgir para que você selecione o
        equipamento, sensor ou alimentador
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione a opção desejada.
      </p>
      <p>
        <strong>Passo 4:</strong> Serão mostrados os alimentadores cadastrados
        com os sensores vinculados a ele, selecione um
      </p>
      <p>
        <strong>Passo 5:</strong> Ao selecionar, informe o setor a que esse
        alimentador pertence
      </p>
      <p>
        <strong>Passo 6:</strong> Em seguida, informe a área a que esse
        alimentador pertence, se ele pertencer a um setor.
      </p>
      <p>
        O ícone do tipo de sensor, que o alimentador está vinculado, surgirá no
        mapa. Para realizar o cadastro do esquema* de um alimentador com um
        equipamento ou sensor, basta:
      </p>
      <p>
        <strong>Passo 1:</strong> Um botão de mais{' '}
        <IconButton iconName='add' variant='text' color='inherit' /> aparecerá
        ao lado do alimentador ao passar por cima dele com o mouse, clique nele
      </p>
      <p>
        <strong>Passo 2:</strong> Uma linha começará a surgir da seta do seu
        mouse, leve ela até um ponto e clique, puxe e clique, até o esquema ser
        formado na tela. A linha só irá parar de aparecer se você conectar um
        equipamento a outro.
      </p>
      <p>
        *1: Alimentadores são equipamentos que disponibilizam material para
        outros equipamentos funcionarem ou auxiliar tarefas. Exemplo: Splintão,
        UPS, geradores, Máquina de Nitrogênio e Compressor de Ar.
      </p>
      <p>
        *2: Esquemas são linhas que conectam um lugar a outro. Por exemplo:
        tubulações, encanamentos etc.
      </p>
      <p>
        Os sensores podem ser observados individualmente ou todos juntos por
        meio do menu lateral esquerdo do mapa, clicando no botão de visualizar{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
    </>
  )
}
