import { httpClient } from 'services/api/httpClient'
import { LevelType } from 'types/level/level'

export const getLevel = async (page = 1, search = '', orderBy?: string) => {
  const response = await httpClient.get('/level')
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  const results = items?.filter(
    (item: LevelType) =>
      item.name.toUpperCase().includes(search.toUpperCase()) ||
      item.time.toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
    order: orderBy,
  }
}
