import { Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Button'
import { MonitoringCompanies } from 'types/monitoring-companies'

interface Props {
  data: MonitoringCompanies | null
}

export const DataFactory = ({ data }: Props) => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('/dashboard', { state: { companyId: data?.id } })
  }

  return (
    <Stack direction='column'>
      <Stack
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: 1,
          p: 2,
          mb: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 'bold',
            mb: 1,
          }}
        >
          {data?.name}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Endereço: {data?.streetAddress}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          CEP: {data?.zipCode}
        </Typography>

        <Button variant='contained' sx={{ mt: 2 }} onClick={handleNavigate}>
          Ver o detalhes da unidade
        </Button>
      </Stack>
    </Stack>
  )
}
