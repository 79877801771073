/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { DatePicker } from 'components/DatePicker'
import { Input } from 'components/Input'
import { InputFile } from 'components/Input/InputFile'
import { InputPrice } from 'components/Input/InputPrice'
import { MaskedInputNumber } from 'components/Input/mask/MaskedInputNumber'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { priceConvertStringToNumber } from 'helpers/priceConvertStringToNumber'
import { createInvoiceWater } from 'services/invoice/water/createInvoiceWater'
import { updateInvoiceWater } from 'services/invoice/water/updateInvoiceWater'
import { CreateInvoiceWaterType } from 'types/invoice/create-invoice-water'
import { InvoiceWaterType } from 'types/invoice/invoice-water'
import { ModalType } from 'types/modal'

import { schemaCreateInvoiceWater } from '../validations/create-invoice-water.validation'

interface Props extends ModalType {
  data: InvoiceWaterType | null
  isView?: boolean
}

export function FormInvoiceWaterModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateInvoiceWaterType>({
    resolver: yupResolver(schemaCreateInvoiceWater),
    defaultValues: {
      monthYear: data?.monthYear ? String(data?.monthYear) : null,
      dueDate: data?.dueDate ? String(data?.dueDate) : null,
      consumption: String(data?.consumption ?? ''),
      totalPayment: String(data?.totalPayment ?? ''),
      consumptionDays: String(data?.consumptionDays ?? ''),
      generalCost: String(data?.generalCost ?? ''),
      generalCostSewerage: String(data?.generalCostSewerage ?? ''),
      goal: String(data?.goal ?? ''),
      pathFile: String(data?.pathFile) ?? null,
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('invoice-water')
    queryClient.invalidateQueries('dashboard-water-general-consumption')
    onClose()
  }

  const mutationCreate = useMutation(
    ({ pathFile, ...data }: CreateInvoiceWaterType) => {
      if (pathFile) {
        data.file = pathFile
      }
      data.monthYear = dayjs(data.monthYear).format(
        'YYYY-MM-DD[T12:00:00-04:00]',
      )
      data.dueDate = dayjs(data.dueDate).format('YYYY-MM-DD[T12:00:00-04:00]')
      data.consumptionDays = Number(data.consumptionDays)
      data.consumption = priceConvertStringToNumber(data.consumption)
      data.totalPayment = priceConvertStringToNumber(data.totalPayment)
      data.generalCost = priceConvertStringToNumber(data.generalCost)
      data.generalCostSewerage = priceConvertStringToNumber(
        data.generalCostSewerage,
      )
      data.goal = priceConvertStringToNumber(data.goal)
      data.status = false
      return createInvoiceWater(data)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: CreateInvoiceWaterType) => {
      update.monthYear = dayjs(update.monthYear).format(
        'YYYY-MM-DD[T12:00:00-04:00]',
      )
      update.dueDate = dayjs(update.dueDate).format(
        'YYYY-MM-DD[T12:00:00-04:00]',
      )
      update.consumptionDays = Number(update.consumptionDays)
      update.consumption = priceConvertStringToNumber(update.consumption)
      update.totalPayment = priceConvertStringToNumber(update.totalPayment)
      update.generalCost = priceConvertStringToNumber(update.generalCost)
      update.generalCostSewerage = priceConvertStringToNumber(
        update.generalCostSewerage,
      )
      update.goal = priceConvertStringToNumber(update.goal)
      update.status = update.status ?? true
      return updateInvoiceWater(Number(data?.id) ?? 0, update)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateInvoiceWaterType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('invoice.water.view')

    if (data?.id) return t('invoice.water.edit')

    return t('invoice.water.new')
  }

  return (
    <>
      <Modal title={getTitle()} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='monthYear'
              views={['year', 'month']}
              control={control}
              inputFormat='MMMM YYYY'
              disableMaskedInput
              label={t('input.monthAndYear') ?? ''}
              error={!!errors?.monthYear}
              errorMessage={errors?.monthYear?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='dueDate'
              control={control}
              inputFormat='DD/MM/YYYY'
              disableMaskedInput
              label={t('input.expiration') ?? ''}
              error={!!errors?.dueDate}
              errorMessage={errors?.dueDate?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='consumption'
              label={
                t('input.consumption', {
                  abbreviation: '(m³)',
                }) ?? ''
              }
              type='number'
              control={control}
              fullWidth
              error={!!errors?.consumption}
              errorMessage={errors?.consumption?.message}
              disabled={isView}
              InputProps={{
                inputComponent: MaskedInputNumber as any,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name='consumptionDays'
              label={t('input.daysOfConsumption') ?? ''}
              type='number'
              control={control}
              fullWidth
              error={!!errors?.consumptionDays}
              errorMessage={errors?.consumptionDays?.message}
              disabled={isView}
              InputProps={{
                inputComponent: MaskedInputNumber as any,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='generalCost'
              label={
                t('input.costGeneralOfWater', {
                  abbreviation: '(R$/m³)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.generalCost}
              errorMessage={errors?.generalCost?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='generalCostSewerage'
              label={
                t('input.costGeneralOfSewer', {
                  abbreviation: '(R$/m³)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.generalCostSewerage}
              errorMessage={errors?.generalCostSewerage?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='goal'
              label={
                t('input.goal', {
                  abbreviation: '(R$)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.goal}
              errorMessage={errors?.goal?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPrice
              name='totalPayment'
              label={
                t('input.invoice', {
                  abbreviation: '(R$)',
                }) ?? ''
              }
              control={control}
              fullWidth
              error={!!errors?.totalPayment}
              errorMessage={errors?.totalPayment?.message}
              disabled={isView}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputFile onChange={(file) => setValue('pathFile', file)} />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
