import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { httpDelete } from 'helpers/httpDelete'
import { createArea } from 'services/area/createArea'
import { getAllAreas } from 'services/area/getAllAreas'
import { updateArea } from 'services/area/updateArea'
import { CreateAreaType } from 'types/area/create-area'
import { ModalType } from 'types/modal'

import { schemaArea } from '../validations/area.validation'

type Props = ModalType

export function FormAreaModal({ onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const [selectArea, setSelectArea] = useState<CreateAreaType | null>(null)

  const { data: areas, refetch } = useQuery('areas', () => getAllAreas(), {
    refetchOnWindowFocus: false,
  })

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateAreaType>({
    resolver: yupResolver(schemaArea),
    defaultValues: {
      name: '',
    },
  })

  const mutationCreate = useMutation(
    (create: CreateAreaType) => {
      return createArea(create)
    },
    {
      onSuccess: () => {
        toast.success(t('savedInformation'))

        queryClient.invalidateQueries('all-areas') // invalidate all areas map
        queryClient.invalidateQueries('linkedAreas') // invalidate linked areas map
        queryClient.invalidateQueries('water-vase') // invalidate water vase chart
        queryClient.invalidateQueries('all-areas')
        queryClient.invalidateQueries('temperature-find-all-last-hour')
        queryClient.invalidateQueries('humidity-find-all-last-hour')
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'areas',
        })
        reset()
        refetch()
      },
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: CreateAreaType) => {
      return updateArea(selectArea?.id ?? 0, update)
    },
    {
      onSuccess: () => {
        toast.success(t('savedInformation'))
        reset()
        refetch()
      },
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateAreaType> = (formData) => {
    if (!selectArea?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const handleDelete = (id: number) => {
    httpDelete(`/area/${id}`, refetch)
  }

  return (
    <Modal
      maxWidth='xs'
      title={t('area.title') ?? ''}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12}>
          <Typography>{t('area.description')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack direction='row' alignItems='flex-start' spacing={3}>
            <Input
              name='name'
              label={t('area.inputName')}
              control={control}
              fullWidth
              error={!!errors?.name}
              errorMessage={errors?.name?.message}
              required
            />

            <IconButton
              variant='outlined'
              iconName='check'
              title={t('button.title.send') ?? ''}
              type='submit'
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {areas?.map((item) => (
            <Stack
              key={item.id}
              direction='row'
              alignItems='center'
              spacing={2}
              marginBottom={2}
            >
              <Typography>{item.name}</Typography>

              <Stack
                flex={1}
                justifyContent='flex-end'
                direction='row'
                alignItems='center'
                spacing={2}
              >
                <IconButton
                  variant='outlined'
                  iconName='edit'
                  title={t('button.title.edit') ?? ''}
                  onClick={() => {
                    setSelectArea(item)
                    setValue('name', item.name)
                  }}
                />
                <IconButton
                  variant='outlined'
                  iconName='delete'
                  title={t('button.title.delete') ?? ''}
                  onClick={() => handleDelete(item.id)}
                />
              </Stack>
            </Stack>
          ))}
        </Grid>

        <Grid item xs={12}>
          <ModalAction
            onCancel={onClose}
            isSubmit={false}
            onSave={onClose}
            isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}
