import { Box, Stack, SxProps } from '@mui/material'
import debounce from 'lodash.debounce'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Restricted } from 'components/Restricted'

import { Button } from '../Button'
import { Icon, IconNamesProps } from '../Icon'
import { InputSearch } from '../Input/InputSearch'

type ButtonType = {
  title?: string | null
  iconName: IconNamesProps
  onClick?: () => void
}

interface Props {
  onCreate?: () => void
  onSearch?: (search: string) => void
  children?: ReactNode
  buttonTitle?: string | null
  sx?: SxProps
  buttons?: ButtonType[]
  module?: string
  permission?: string
}

export function HeaderAction({
  onCreate,
  onSearch,
  buttonTitle,
  children,
  sx,
  buttons,
  module,
  permission,
}: Props) {
  const { t } = useTranslation('common')

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onSearch?.(e?.target?.value)
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  return (
    <Stack
      width='100%'
      direction={['column-reverse', 'row']}
      alignItems={['flex-start', 'center']}
      sx={sx}
    >
      {onCreate && (
        <Restricted
          module={module}
          permission={permission}
          disabled={!module || !permission}
        >
          <Button
            variant='contained'
            startIcon={<Icon name='add' />}
            onClick={onCreate}
            sx={{
              textTransform: 'initial',
              marginTop: [2, 0],
              marginRight: [0, 2],
            }}
          >
            {buttonTitle ?? t('button.title.new')}
          </Button>
        </Restricted>
      )}

      {!!buttons?.length && (
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          width={['100%', 'auto']}
          sx={{
            marginRight: [0, 2],
            '& button + button': {
              marginLeft: 2,
            },
          }}
        >
          {buttons.map((item, index) => (
            <Button
              key={index}
              variant='contained'
              startIcon={<Icon name={item.iconName} />}
              onClick={item?.onClick}
              sx={{
                textTransform: 'initial',
                marginTop: [2, 0],
              }}
            >
              {item?.title ?? t('button.title.new')}
            </Button>
          ))}
        </Stack>
      )}

      {children}

      {onSearch && (
        <Box width='100%' maxWidth={400}>
          <InputSearch
            name='search'
            type='search'
            placeholder={t('input.search') ?? ''}
            sx={{ '& fieldset': { borderColor: 'transparent !important' } }}
            onChange={debouncedResults}
          />
        </Box>
      )}
    </Stack>
  )
}
