import { Navigate, RouteProps } from 'react-router-dom'

import { GardPage } from 'pages/guard/GuardPage'
import { useAuthStore } from 'store/auth.store'

type RouterGuardProps = RouteProps & {
  module: string
  component: React.ComponentType
}

const RouterGuard = ({
  module,
  component: Component,
}: RouterGuardProps): React.ReactElement | null => {
  const { user } = useAuthStore()

  if (!user) return <GardPage />

  if (!user?.profile) return <GardPage />

  if (user?.profile?.name?.toLowerCase()?.includes('admin')) {
    return <Component />
  }

  const hasPermission = user?.profile?.permissions?.find(
    (item: { feature: { name: string } }) =>
      item?.feature?.name?.includes(module),
  )

  if (!hasPermission && module.includes('DASH')) return <Navigate to='/home' />

  return hasPermission ? <Component /> : <GardPage />
}

export default RouterGuard
