import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import AddAreaLightImg from 'assets/icons/light/novo-mapa-light.svg'
import AddAreaImg from 'assets/images/icon_map.svg'
import { TEXT_TITLE_14, TEXT_SUBTEXT } from 'constants/textTypes'

import { MapForm } from './MapForm'

export function UploadMap() {
  const [openAreaModal, setOpenAreaModal] = useState(false)
  const theme = useTheme()
  const isDark = theme.palette.mode === 'dark'

  function handleClose() {
    setOpenAreaModal(false)
  }

  return (
    <>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='center'
        sx={{ height: 'calc(100vh - 104px)' }}
      >
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
          onClick={() => setOpenAreaModal(true)}
          sx={{ cursor: 'pointer', width: 'auto' }}
        >
          <Grid item>
            <Box
              component='img'
              src={isDark ? AddAreaImg : AddAreaLightImg}
              alt='adicionar imagem'
              sx={{ width: 147, height: 115 }}
            />
          </Grid>
          <Grid item container sx={{ maxWidth: '295px' }}>
            <Grid item sx={{ ml: 2 }}>
              <Typography
                sx={{
                  ...TEXT_TITLE_14,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                Adicionar área
              </Typography>
              <Typography
                sx={{
                  ...TEXT_SUBTEXT,
                  color: (theme) => theme.palette.text.disabled,
                  textDecoration: 'underline',
                }}
              >
                Clique aqui para adicionar as informações de um novo mapa
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {openAreaModal ? (
        <MapForm open={openAreaModal} onClose={handleClose} />
      ) : null}
    </>
  )
}
