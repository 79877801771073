/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Modal } from 'components/Modal'
import { Select } from 'components/Select'
import { weekdayId, weekDaysUpperCase } from 'constants/weekDays'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { getArea } from 'services/area/getArea'
import { createParameter } from 'services/goalAndParameter/parameter/createParameter'
import { updateParameter } from 'services/goalAndParameter/parameter/updateParameter'
import { sensorService } from 'services/sensor.service'
import { unitService } from 'services/unit.service'
import { CreateParameterType } from 'types/goalAndParameter/create-parameter'
import { ParameterType } from 'types/goalAndParameter/parameter'
import { ModalType } from 'types/modal'
import { UnitType } from 'types/unit/unit'

import { schemaCreateParameter } from '../validations/create-parameter.validation'
import { ActionFormParameter } from './ActionFormParameter'
import { AirConditioningForm } from './AirConditioningForm'
import { FormOpening } from './FormOpening'
import { StepperSensor } from './FormOpening/components/Stepper'
import { HumidityForm } from './HumidityForm'
import { TemperatureForm } from './TemperatureForm'
import { TemperatureHumidityForm } from './TemperatureHumidityForm'

import { find, isEmpty } from 'lodash'
import Alert from '@mui/material/Alert'

interface Props extends ModalType {
  data: ParameterType | null
  isView?: boolean
}

export interface SensorSelectType {
  value: string | number
  label: string
  isFixed: boolean
  waterInlet?: boolean
  waterOutlet?: boolean
  type?: string
  floor?: string
}

enum UnitTypes {
  TEMPERATURE = 'TEMP',
  HUMIDITY = 'HUM',
  OPENING = 'OPENING',
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  TEMPERATURE_HUMIDITY = 'TEMP_HUM',
}

const steps = ['open', 'noOpen']
const stepsAir = ['locale', 'temperatureAndActions']

export function FormParameterModal({ data, isView, onClose, open }: Props) {
  const [activeStep, setActiveStep] = useState(0)
  const [sensorsSelected, setSensorsSelected] = useState<SensorSelectType[]>(
    data?.sensors?.map((sensor) => ({
      label: sensor.sensor.name,
      value: sensor.sensor.id.value,
      type: sensor.sensor.type,
      sensor: sensor.sensor?.id.value,
      waterInlet: sensor.sensor?.waterInlet,
      waterOutlet: sensor.sensor?.waterOutlet,
      floor: sensor.sensor?.floor,
      isFixed: false, // Add the missing isFixed property
    })) || [],
  )
  const [isLoading, setIsLoading] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState('')

  useEffect(() => {
    setValue('sensors', sensorsSelected);
  }, [sensorsSelected])

  const handleCancel = () => {
    if (activeStep === 0) {
      onClose()
    }
  }

  const handleNext = () => setActiveStep(current => current + 1)

  const handleBack = () => setActiveStep(current => current - 1)

  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const { data: units } = useQuery({
    queryKey: ['all-unit'],
    queryFn: () => unitService.list(),
    select: (data: any[]) => {
      const allTypes = Object.values(UnitTypes).map(String)
      return data?.flatMap((unit: UnitType) =>
        allTypes.includes(unit.name)
          ? {
              id: String(unit.id),
              name: unit.name,
            }
          : [],
      )
    },
  })

  const { data: areas } = useQuery({
    queryKey: 'all-area',
    queryFn: () => getArea(),
  })

  const { data: sensors } = useQuery({
    queryKey: ['sensors', sensorsSelected],
    queryFn: () => sensorService.listAllOpeningType(),
  })

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<CreateParameterType>({
    resolver: yupResolver(schemaCreateParameter),
    defaultValues: {
      unit: data?.unit.name || undefined,
      company: data?.area?.mapImageCompany?.company?.id.value ?? '',
      mapImage: data?.area?.mapImageCompany?.id.value ?? '',
      area: data?.area?.id.value ? String(data?.area?.id.value) : undefined,
      temperatureMax: data?.temperatureMax ? String(data?.temperatureMax) : '',
      temperatureMin: data?.temperatureMin ? String(data?.temperatureMin) : '',
      percentageMax: String(data?.percentageMax),
      percentageMin: String(data?.percentageMin),
      status: data?.status ?? true,
      isMinAndMax: !!(data?.temperatureMax && data?.temperatureMin),
      isOnlyMax: !!(data?.temperatureMax && !data?.temperatureMin),
      temperatureOnlyMax: data?.temperatureMin ? '' : data?.temperatureMax,
      sensors:
        data?.sensors?.map((sensor) => ({
          label: sensor.sensor.name,
          value: sensor.sensor.id.value,
          type: sensor.sensor.type,
          sensor: sensor.sensor?.id.value,
          waterInlet: sensor.sensor?.waterInlet,
          waterOutlet: sensor.sensor?.waterOutlet,
          floor: sensor.sensor?.floor,
        })) || [],
      qtdTimeChecked: data?.qtdTimeChecked || false,
      numberOfTimes: data?.numberOfTimes ? String(data.numberOfTimes) : '',
      periodNUmber: data?.periodNUmber ? String(data.periodNUmber) : '',
      periodType: data?.qtdTimeChecked ? data?.periodType ?? '' : '',

      openingTimeChecked: data?.openingTimeChecked || false,
      openingTimeNumber: data?.openingTimeNumber
        ? String(data.openingTimeNumber)
        : '',
      openingTimeType: data?.openingTimeChecked ? data?.openingTimeType ?? '' : '',

      days: data?.days?.length
        ? weekDaysUpperCase.map((day) => {
            const matchingDay = data?.days?.find(
              (d) => d.day.id.value === day.id.toString(),
            )
            return { entityId: matchingDay ? true : false }
          })
        : weekDaysUpperCase.map((_) => ({ entityId: false })),
      hours: data?.hours?.length
        ? data?.hours?.map((hour) => {
            let hourInitial: string | string[] =
              hour?.hours?.hourInitial.split(':')
            let hourFinal: string | string[] = hour?.hours?.hourFinal.split(':')

            hourInitial = `${hourInitial[0]}:${hourInitial[1]}`
            hourFinal = `${hourFinal[0]}:${hourFinal[1]}`

            return {
              ...(hour?.hours?.id?.value ? { hour: hour.hours.id.value } : {}),
              hourInitial: hourInitial,
              hourFinal: hourFinal,
              status: hour?.hours.status,
            }
          })
        : [
            {
              hourInitial: '',
              hourFinal: '',
              status: false,
            },
          ],
      dates: data?.dates?.length
        ? data.dates.map((date) => {
            return {
              ...(date?.dates?.id?.value ? { date: date.dates.id.value } : {}),
              startDate: date?.dates.startDate
                ? moment(date.dates.startDate).format('YYYY-MM-DD')
                : null,
              endDate: date?.dates.endDate
                ? moment(date.dates.endDate).format('YYYY-MM-DD')
                : null,
              status: date?.dates.status,
            }
          })
        : [
            {
              startDate: null,
              endDate: null,
              status: false,
            },
          ],
    },
  })

  const steppeAction = ['OPENING', 'AIR_CONDITIONING'].includes(
    watch('unit') as string,
  )

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'parameter' ||
        query.queryKey[0] === 'all-parameter',
    })
    setIsLoading(false)
    onClose()
  }

  const mutationCreate = useMutation(
    (data: CreateParameterType) => {
      const unitIds = {
        TEMP: '',
        HUM: '',
        OPENING: '',
        TEMP_HUM: '',
      }
      units?.map(
        (unit: any) =>
          (unitIds[unit.name as keyof typeof unitIds] = unit.id.toString()),
      )

      if (data.unit !== 'TEMP_HUM') {
        data.percentageMax = null
        data.percentageMin = null
      }
      if (data.unit === 'OPENING') {
        data.temperatureMax = null
        data.temperatureMin = null
        data.area = null
      }
      if (data.unit !== 'OPENING') {
        data.numberOfTimes = null
        data.periodNUmber = null
        data.periodType = null
        data.openingTimeNumber = null
        data.openingTimeType = null
        data.hours = []
        data.days = []
        data.dates = []
      }
      if (data.unit === 'AIR_CONDITIONING') {
        data.isOnlyMax = true
      }
      if (sensorsSelected.length) {
        data.sensors = sensorsSelected
      }
      data.unit = unitIds[data.unit as keyof typeof unitIds]

      let days: { entityId: string | number | boolean | null }[] | null = null

      if (data?.days?.length) {
        days = data.days
          .map(
            (
              day,
              index,
            ): { entityId: string | number | boolean | null } | null => {
              if (day?.entityId) {
                return { entityId: weekdayId[index] }
              }

              return null
            },
          )
          .filter((day) => day !== null) as {
          entityId: string | number | boolean | null
        }[]
      }

      let dates = null
      if (data?.dates?.length) {
        dates = data.dates.map((date) => {
          return {
            startDate: date?.startDate
              ? moment(date.startDate).format('YYYY-MM-DD')
              : null,
            endDate: date?.endDate
              ? moment(date.endDate).format('YYYY-MM-DD')
              : null,
            status: date?.status,
          }
        })
      }

      return createParameter({
        unit: data?.unit,
        area: data?.area,
        temperatureMax: data?.isOnlyMax
          ? data?.temperatureOnlyMax
          : data?.temperatureMax,
        temperatureMin: data?.isMinAndMax ? null : data.temperatureMin,
        percentageMax: data?.percentageMax,
        percentageMin: data?.percentageMin,
        status: data?.status ?? false,

        sensors: data?.sensors?.map((item) => ({
          sensor: item?.sensor ?? item,
          waterInlet: item?.waterInlet || false,
          waterOutlet: item?.waterOutlet || false,
          floor: item?.floor ?? '',
        })),
        qtdTimeChecked: data?.qtdTimeChecked,
        numberOfTimes: data?.numberOfTimes || null,
        periodNUmber: data?.periodNUmber || null,
        periodType: data?.qtdTimeChecked ? data?.periodType || null : null,
        openingTimeChecked: data?.openingTimeChecked,
        openingTimeNumber: data?.openingTimeNumber || null,
        openingTimeType: data?.openingTimeChecked ? data?.openingTimeType || null : null,
        hours:
          data?.hours &&
          data?.hours.every(
            (hour) =>
              hour.hourInitial === '' &&
              hour.hourFinal === '' &&
              hour.status === false,
          )
            ? []
            : data?.hours,
        days: days || [],
        dates:
          dates &&
          dates.every(
            (date) =>
              (date.startDate === null || date.startDate === '') &&
              (date.endDate === null || date.endDate === '') &&
              date.status === false,
          )
            ? []
            : dates,
      })
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
        setIsLoading(false)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: CreateParameterType) => {
      const unitIds = {
        TEMP: '',
        HUM: '',
        OPENING: '',
        TEMP_HUM: '',
      }
      units?.map(
        (unit: any) =>
          (unitIds[unit.name as keyof typeof unitIds] = unit.id.toString()),
      )
      if (update.unit !== 'OPENING') {
        update.numberOfTimes = null
        update.periodNUmber = null
        update.periodType = null
        update.openingTimeNumber = null
        update.openingTimeType = null
        update.hours = []
        update.days = []
        update.dates = []
      }
      if (update.unit === 'AIR_CONDITIONING') {
        update.isOnlyMax = true
      }
      update.unit = unitIds[update.unit as keyof typeof unitIds]

      update.sensors = sensorsSelected

      let days: { entityId: string | number | boolean | null }[] | null = null

      if (update?.days?.length) {
        days = update?.days
          .map(
            (
              day,
              index,
            ): { entityId: string | number | boolean | null } | null => {
              if (day?.entityId) {
                return { entityId: weekdayId[index] }
              }

              return null
            },
          )
          .filter((day) => day !== null) as {
          entityId: string | number | boolean | null
        }[]
      }

      let dates = null
      if (update?.dates?.length) {
        dates = update.dates.map((item) => {
          const newItem: any = {
            startDate: item?.startDate
              ? moment(item?.startDate).format('YYYY-MM-DD')
              : null,
            endDate: item?.endDate
              ? moment(item?.endDate).format('YYYY-MM-DD')
              : null,
            status: item?.status,
          }
          if ('date' in item && item.date) {
            newItem.date = item.date
          }
          return newItem
        })
      }

      let hours = null
      if (update?.hours?.length) {
        hours = update.hours.map((item) => {
          const newItem: any = {
            hourInitial: item?.hourInitial ? item?.hourInitial : '',
            hourFinal: item?.hourFinal ? item?.hourFinal : '',
            status: item?.status,
          }
          if ('hour' in item && item.hour) {
            newItem.hour = item.hour
          }
          return newItem
        })
      }

      return updateParameter(Number(data?.id) ?? 0, {
        unit: update?.unit,
        area: update?.area || null,
        temperatureMax: update?.temperatureMax ?? null,
        temperatureMin: update?.temperatureMin ?? null,
        percentageMax: update?.percentageMax || null,
        percentageMin: update?.percentageMin || null,
        status: update?.status ?? false,

        sensors: update?.sensors?.map((item) => ({
          sensor: item?.sensor ?? item,
          waterInlet: item?.waterInlet || false,
          waterOutlet: item?.waterOutlet || false,
          floor: item?.floor ?? '',
        })),

        qtdTimeChecked: update?.qtdTimeChecked || false,
        numberOfTimes: update?.numberOfTimes || null,
        periodNUmber: update?.periodNUmber || null,
        periodType: update?.periodType || null,

        openingTimeChecked: update?.openingTimeChecked || false,
        openingTimeNumber: update?.openingTimeNumber || null,
        openingTimeType: update?.openingTimeType || null,

        hours:
          hours &&
          hours.every(
            (hour) =>
              hour.hourInitial === '' &&
              hour.hourFinal === '' &&
              hour.status === false,
          )
            ? []
            : hours,
        days: days || [],
        dates:
          dates &&
          dates.every(
            (date) =>
              (date.startDate === null || date.startDate === '') &&
              (date.endDate === null || date.endDate === '') &&
              date.status === false,
          )
            ? []
            : dates,
      })
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
        setIsLoading(false)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateParameterType> = (formData) => {
    setIsLoading(true)

    if (checkSelectedUtility(UnitTypes.OPENING)) {
      const hasDates = !isEmpty(find(formData.dates, { status: true }))
      const hasDays = !isEmpty(find(formData.days, { entityId: true }))
      const hasHours = !isEmpty(find(formData.hours, { status: true }))
      const hasOpeningTime = formData.openingTimeChecked
      const hasQtdTime = formData.qtdTimeChecked

      if (!hasDates && !hasDays && !hasHours && !hasOpeningTime && !hasQtdTime) {
          setErrorSubmit('Deve conter ao menos 1 regra!')
          setIsLoading(false)
          return
      } else {
        setErrorSubmit('')
      }
    }

    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('goalAndParameter.parameter.view')

    if (data?.id) return t('goalAndParameter.parameter.edit')

    return t('goalAndParameter.parameter.new')
  }

  const checkSelectedUtility = (value: UnitTypes) => watch('unit') === value

  return (
    <Modal title={getTitle()} open={open} onClose={onClose}>
      {checkSelectedUtility(UnitTypes.OPENING) && (
        <StepperSensor activeStep={activeStep} steps={steps} />
      )}
      {checkSelectedUtility(UnitTypes.AIR_CONDITIONING) && (
        <StepperSensor activeStep={activeStep} steps={stepsAir} />
      )}
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={activeStep === 0
          ? handleSubmit(handleNext)
          : handleSubmit(onSubmit)
        }
        noValidate
      >
        <Grid item xs={8} sm={12}>
          <Select
            name='unit'
            label={t('input.utility')}
            options={units?.map((unit) => ({
              id: unit.name,
              name: t(unit.name).toString(),
            }))}
            onChange={(e) => setValue('unit', e.target.value as string)}
            control={control}
            fullWidth
            error={!!errors?.unit}
            errorMessage={errors?.unit?.message}
            required
            disabled={isView}
          />
        </Grid>

        {checkSelectedUtility(UnitTypes.AIR_CONDITIONING) && (
          <AirConditioningForm
            control={control}
            errors={errors}
            activeStep={activeStep}
            isView={isView}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        )}
        {checkSelectedUtility(UnitTypes.OPENING) && (
          <FormOpening
            isView={isView}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            register={register}
            activeStep={activeStep}
            steps={steps}
            sensorSelected={sensorsSelected}
            setSensorsSelected={setSensorsSelected}
            sensors={sensors}
            watch={watch}
            clearErrors={clearErrors}
          />
        )}
        {checkSelectedUtility(UnitTypes.HUMIDITY) && (
          <HumidityForm
            control={control}
            errors={errors}
            watch={watch}
            areas={areas?.results ?? []}
            isView={isView}
          />
        )}
        {checkSelectedUtility(UnitTypes.TEMPERATURE_HUMIDITY) && (
          <TemperatureHumidityForm
            control={control}
            errors={errors}
            watch={watch}
            areas={areas?.results ?? []}
            isView={isView}
          />
        )}
        {checkSelectedUtility(UnitTypes.TEMPERATURE) && (
          <TemperatureForm
            control={control}
            errors={errors}
            watch={watch}
            areas={areas?.results ?? []}
            isView={isView}
          />
        )}
        <Grid item xs={12}>
          {errorSubmit &&
          <Alert severity="error">
            {errorSubmit}
          </Alert>
          }
          <ActionFormParameter
            onCancel={handleCancel}
            onBack={handleBack}
            stepAction={steppeAction}
            currentStep={activeStep}
            isLoading={isLoading}
            isView={isView}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}
