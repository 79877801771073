import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Tooltip, Typography } from '@mui/material'
import { WeekdayEnum } from 'core/enums/WeekdayEnum'
import {
  useMemo,
  Fragment,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ReactComponent as HrProgramIcon } from 'assets/icons/24hr_program.svg'
import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'
import { IOSSwitch } from 'components/Toggle'

import { HourButton } from '../styles'
import { getValidationSchema } from './validation'

export type ProgrammingRef = {
  onSubmit: (
    onValid: (data: any) => void,
    onInvalid: () => void,
  ) => Promise<void>
}

type Props = {
  validationEnabled?: boolean
  defaultValues: null | {
    id?: string
    weekday: WeekdayEnum
    iSProgrammingAllHours: boolean
    interval: Array<{
      id?: string
      endTime: string
      startTime: string
      temperature: string
      keepConnected: boolean
    }>
  }
}

const defaultValues = {
  iSProgrammingAllHours: true,
  interval: [
    {
      endTime: '',
      startTime: '',
      temperature: '',
      keepConnected: false,
    },
  ],
}

export const FormProgramming = forwardRef<unknown, Props>((props, ref) => {
  const { t } = useTranslation('common')

  const {
    watch,
    control,
    clearErrors,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: props.validationEnabled
      ? yupResolver(getValidationSchema())
      : undefined,
    mode: 'all',
    defaultValues: props.defaultValues ?? defaultValues,
  })

  useEffect(() => {
    clearErrors()
  }, [props.validationEnabled])

  const iSProgrammingAllHours = watch('iSProgrammingAllHours')

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: 'interval',
  })

  const containOnlyOneField = fields.length <= 1

  const gridTemplateColumns = useMemo(() => {
    if (iSProgrammingAllHours) {
      return 'auto 1fr 1.5fr'
    }

    return containOnlyOneField
      ? 'auto 1fr auto 1fr 1.5fr'
      : '1fr auto 1fr 1.5fr auto'
  }, [containOnlyOneField, iSProgrammingAllHours])

  const onSubmit: ProgrammingRef['onSubmit'] = (onValid, onInvalid) => {
    const eventSubmit = handleSubmit(onValid, onInvalid)
    return eventSubmit()
  }

  useImperativeHandle<unknown, ProgrammingRef>(
    ref,
    () => ({
      onSubmit,
    }),
    [],
  )

  return (
    <>
      <Stack
        display={'grid'}
        gridTemplateColumns={gridTemplateColumns}
        alignItems={'flex-start'}
        gap={1.5}
        rowGap={2}
        sx={{
          marginBottom: '0.75rem',
        }}
      >
        {containOnlyOneField && (
          <Tooltip
            title={`${iSProgrammingAllHours ? 'Desativar' : 'Ativar'} 24h`}
            placement='top'
          >
            <HourButton
              iSProgrammingAllHours={iSProgrammingAllHours}
              onClick={() =>
                setValue('iSProgrammingAllHours', !iSProgrammingAllHours)
              }
            >
              <HrProgramIcon height={27} width={27} />
            </HourButton>
          </Tooltip>
        )}

        {fields.map((field, index) => (
          <Fragment key={field.id}>
            {iSProgrammingAllHours ? (
              <Typography fontSize={11}>
                O modo 24h determina o funcionamento em tempo integral e
                contínuo desse equipamento
              </Typography>
            ) : (
              <>
                <Input
                  error={Boolean(errors.interval?.[index]?.startTime)}
                  errorMessage={errors.interval?.[index]?.startTime?.message}
                  control={control}
                  type='time'
                  label='Hora inicial'
                  name={`interval.${index}.startTime`}
                />
                <Typography>até</Typography>
                <Input
                  error={Boolean(errors.interval?.[index]?.endTime)}
                  errorMessage={errors.interval?.[index]?.endTime?.message}
                  control={control}
                  type='time'
                  label='Hora final'
                  name={`interval.${index}.endTime`}
                />
              </>
            )}
            <Input
              fullWidth
              error={Boolean(errors.interval?.[index]?.temperature)}
              errorMessage={errors.interval?.[index]?.temperature?.message}
              control={control}
              label='Temperatura'
              name={`interval.${index}.temperature`}
              inputProps={{
                maxLength: 2,
              }}
              InputProps={{
                endAdornment: 'ºC',
              }}
            />

            {!containOnlyOneField && (
              <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                <Tooltip title='Manter ligado'>
                  <div>
                    <IOSSwitch
                      value={'active'}
                      checked={watch(`interval.${index}.keepConnected`)}
                      inputProps={{ 'aria-label': 'Manter ligado' }}
                      onClick={() =>
                        update(index, {
                          ...watch(`interval.${index}`),
                          keepConnected: !field.keepConnected,
                        })
                      }
                    />
                  </div>
                </Tooltip>

                <IconButton
                  color='secondary'
                  variant='contained'
                  iconName='delete'
                  onClick={() => remove(index)}
                  title={t('button.title.delete') as string}
                />
              </Stack>
            )}
          </Fragment>
        ))}
      </Stack>

      {!iSProgrammingAllHours && (
        <Stack flexDirection={'row'} gap={1.5} alignItems={'center'}>
          <IconButton
            size='small'
            variant='contained'
            color='secondary'
            iconName='add'
            onClick={() => {
              append({
                endTime: '',
                startTime: '',
                temperature: '',
                keepConnected: false,
              })
            }}
          />
          <Typography fontSize={12}>
            Para segurança e manutenção do equipamento, o desligamento do ar
            condicionado é automático após o último período cadastrado
          </Typography>
        </Stack>
      )}
    </>
  )
})
