export function MetasContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros das metas das utilidades de
        fábrica: água, energia e gás. Para realizar o cadastro de metas, é
        necessário cadastrar inicialmente as áreas. Os dados desse submódulo
        serão utilizados no Dashboard, então sugerimos que sempre verifique se
        as metas mensais já foram cadastradas.
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>
        <strong>o Cadastrar de Metas</strong>
      </p>

      <p>Para realizar o cadastro de uma nova meta, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Metas”
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione o tipo a utilidade entre água,
        energia e gás.
      </p>
      <p>
        Ao selecionar a utilidade de água, o campo de consumo libera o campo
        litros e período, e de energia o campo de consumo libera o campo kw e
        período{' '}
      </p>
      <p>
        <strong>Passo 7:</strong> Preencha os campos: setor, área, consumo até e
        período.
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>Importante: </strong> Esses dados afetam nos valores do
        dashboard de água. Para realizar o cadastro de metas, é importante já
        ter uma área cadastrada (Mapa, submódulo de áreas)
      </p>
      <p>
        <strong>o Editar Meta</strong>
      </p>
      <p>Para realizar a edição de uma meta, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Metas”
      </p>
      <p>
        <strong>Passo 5:</strong> Selecionar a meta que vai editar dentro da
        listagem e selecionar o botão de ações{' '}
      </p>
      <p>
        <strong>Passo 6:</strong> Clicar no botão “Editar” .
      </p>
      <p>
        <strong>Passo 7:</strong> Edite os campos: utilidades, status de ativo
        ou inativo, setor, área, consumo e período.{' '}
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>o Excluir Meta</strong>
      </p>

      <p>Para realizar a exclusão de uma meta, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a meta que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir” .
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a meta será permanentemente excluída do
        sistema.
      </p>
      <p>
        <strong>o Visualizar Meta</strong>
      </p>
      <p>Para realizar visualizar os dados de uma meta, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a meta que vai visualizar dentro da
        listagem e selecionar o botão de ações{' '}
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar” .
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, sendo eles: utilidades, status de ativo ou inativo, setor,
        área, consumo e período.
      </p>
    </>
  )
}
