import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Stack,
  Switch,
  SwitchProps,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { SyntheticEvent } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { HelpIcon } from '../Icon/Help'

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#141432',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  name: string
  legend?: string
  label?: string | null
  error?: boolean
  disabled?: boolean
  helperText?: string
  helpWithIcon?: string
  checked?: boolean
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void
}

export function Toggle({
  control,
  name,
  legend,
  label,
  error,
  helperText,
  helpWithIcon,
  disabled,
  ...rest
}: Props) {
  const { t } = useTranslation('common')
  return (
    <FormControl component='fieldset' error={error} disabled={disabled}>
      {legend && <FormLabel component='legend'>{legend}</FormLabel>}
      {control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Stack direction='row' width='100%' alignItems='center'>
              <FormControlLabel
                {...field}
                checked={field.value}
                control={<IOSSwitch sx={{ m: 1 }} />}
                label={
                  label ??
                  (field.value === true
                    ? t('toggle.active')
                    : t('toggle.inactive'))
                }
              // name={name}
              // onChange={(event)}
              // inputRef={field.ref}
              />
              {!!helpWithIcon?.length && (
                <Tooltip title={helpWithIcon} arrow placement='top'>
                  <HelpIcon />
                </Tooltip>
              )}
            </Stack>
          )}
        />
      ) : (
        <Stack direction='row' width='100%' alignItems='center'>
          <FormControlLabel
            {...rest}
            control={<IOSSwitch sx={{ m: 1 }} />}
            label={
              label ??
              (rest.checked === true
                ? t('toggle.active')
                : t('toggle.inactive'))
            }
          // name={name}
          // onChange={(event)}
          // inputRef={field.ref}
          />
          {!!helpWithIcon?.length && (
            <Tooltip title={helpWithIcon} arrow placement='top'>
              <HelpIcon />
            </Tooltip>
          )}
        </Stack>
      )}
      {(error || helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
