import { styled } from '@mui/system'
import React, { useEffect, useRef } from 'react'

import { addAlpha } from 'helpers/addAlpha'

interface StyledHumidityBoxProps {
  temperature: number
}

const StyledHumidityBox = styled('div')<StyledHumidityBoxProps>(({ theme }) => {
  return {
    position: 'absolute',
    top: -130,
    left: -130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    zIndex: 1,
    opacity: 0.8,
    filter: 'brightness(150%)',
  }
})

interface HumidityBoxProps {
  temperature: number
}

const HumidityBox: React.FC<HumidityBoxProps> = ({ temperature }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')

      if (context) {
        const gradient = context.createRadialGradient(
          130,
          130,
          0,
          150,
          150,
          100,
        )

        const colors = [
          { threshold: 0, color: '#d37d33' },
          { threshold: 5, color: '#de9d63' },
          { threshold: 10, color: '#e7b88d' },
          { threshold: 20, color: '#efd0b3' },
          { threshold: 25, color: '#f7e9db' },
          { threshold: 30, color: '#e2f7e4' },
          { threshold: 35, color: '#c4e6c6' },
          { threshold: 40, color: '#98d59d' },
          { threshold: 45, color: '#69c170' },
          { threshold: 50, color: '#44b24f' },
        ]

        let selectedColor = colors[0].color
        for (const { threshold, color } of colors) {
          if (temperature >= threshold) {
            selectedColor = color
          }
        }

        gradient.addColorStop(0, addAlpha(selectedColor, 0.7))
        gradient.addColorStop(0.3, addAlpha(selectedColor, 0.5))
        gradient.addColorStop(0.5, addAlpha(selectedColor, 0.2))
        gradient.addColorStop(1, 'rgba(255,255,255,0)')

        context.fillStyle = gradient
        context.fillRect(0, 0, canvas.width, canvas.height)
      }
    }
  }, [temperature])

  return (
    <StyledHumidityBox temperature={temperature}>
      <canvas ref={canvasRef} width={300} height={300} />
    </StyledHumidityBox>
  )
}

export default HumidityBox
