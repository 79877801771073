import { httpClient } from 'services/api/httpClient'
import { CreateInvoiceWaterType } from 'types/invoice/create-invoice-water'

export const createInvoiceWater = (data: CreateInvoiceWaterType) => {
  if (!data?.file) {
    return httpClient.post('/water-invoice', data)
  }

  const { file, ...rest } = data
  const formData = new FormData()

  if (file) {
    formData.append('file', file)
  }

  formData.append('request', JSON.stringify(rest))

  return httpClient.post('/water-invoice/create', formData)
}
