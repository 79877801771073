import { PhotoOutlined } from '@mui/icons-material'
import { Backdrop, Box, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Container } from 'components/Container'

interface Props {
  url?: string
  name?: string
}

export function PhotoExpand({ url, name }: Props) {
  const { t } = useTranslation('common')

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <Button
        variant='text'
        color='inherit'
        sx={{ textAlign: 'left', padding: 0 }}
        onClick={handleToggle}
      >
        <Stack direction={'row'} spacing={3} paddingRight={1}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              bgcolor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              height: 44,
              width: 44,
            }}
          >
            <PhotoOutlined fontSize='medium' />
          </Box>
          <Stack>
            <Typography color={'primary'} textTransform='initial'>
              {name}
            </Typography>
            <Typography>{t('clickToExpand')}</Typography>
          </Stack>
        </Stack>
      </Button>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Stack
          alignItems={'cneter'}
          sx={{
            height: '100%',
            minHeight: '100vh',
            width: '100%',
            padding: [2, 3],
          }}
        >
          <Container>
            <IconButton
              color='secondary'
              iconName='close'
              variant='contained'
              onClick={handleClose}
            />

            <Typography variant='h1' marginTop={[3, 6]}>
              {t('viewImage')}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
                background: (theme) =>
                  `url(${url}) ${theme.palette.primary.main}`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                color: (theme) => theme.palette.primary.contrastText,
                maxWidth: 1084,
                minHeight: [300, 708],
                marginX: 'auto',
                marginTop: 6,
              }}
            >
              {!url && <PhotoOutlined sx={{ height: 200, width: 200 }} />}
            </Box>
          </Container>
        </Stack>
      </Backdrop>
    </>
  )
}
