import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { UsersType } from 'types/user/user'

import { FormUserModal } from './components/FormUserModal'
import { useUsers } from './hooks/useUsers'

export function UsersPage() {
  const {
    setSearch,
    columns,
    page,
    setPage,
    orderBy,
    setOrderBy,
    data,
    users,
    error,
    isLoading,
    isFetching,
    handleCreate,
    tableActions,
    selectedUser,
    openForm,
    openFormView,
    handleCancel,
  } = useUsers()

  return (
    <>
      <HeaderAction
        onCreate={handleCreate}
        onSearch={(search) => setSearch(search)}
        module={ModuleEnum.ACC_USER}
        permission={PermissionEnum.CREATE}
      />

      <Table<UsersType>
        columns={columns}
        rows={users ?? []}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.perPage,
          count: data?.total,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView) && (
        <FormUserModal
          data={selectedUser}
          isView={openFormView}
          open={openForm || openFormView}
          onClose={handleCancel}
        />
      )}
    </>
  )
}
