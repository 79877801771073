import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Accordion } from 'components/Accordion'
import { Checkbox } from 'components/Checkbox'
import { Loading } from 'components/Loading'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { useModalProgrammingAirConditioning } from 'hooks/monitoring/useModalProgrammingAirConditioning'
import { ModalType } from 'types/modal'

import { FormProgramming, ProgrammingRef } from './FormProgramming'
import { accordionStyle } from './styles'

type Props = ModalType & {
  linkedAreaId: string
}

export const ModalProgrammingAirConditioning: FC<Props> = ({
  open,
  onClose,
  linkedAreaId,
}) => {
  const { t } = useTranslation('common')
  const {
    days,
    onSave,
    defaultValues,
    formProgrammingRef,
    submitIsLoading,
    isLoadingScheduling,
    checkboxSelection,
  } = useModalProgrammingAirConditioning({
    linkedAreaId,
    onClose,
  })

  const { daysEnabled, selectedAccordions } = checkboxSelection

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!submitIsLoading) onClose()
      }}
      title={'Programação ar condicionado'}
      sx={{
        '& div.MuiPaper-root': {
          maxWidth: '620px !important',
        },
      }}
    >
      {isLoadingScheduling && (
        <Loading
          mode='circular'
          sx={{ padding: 0, alignItems: 'flex-start', margin: 'auto' }}
        />
      )}

      {!isLoadingScheduling && (
        <Stack gap={1}>
          {days.map((day, index) => {
            const defaultValue = defaultValues.find(
              (row) => row.weekday === day.value,
            )
            const dayEnable = daysEnabled.checkValueIsActive(day.value)

            return (
              <Accordion
                key={day.legend}
                sx={accordionStyle}
                title={
                  <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                    <Checkbox
                      sx={{
                        padding: 0,
                      }}
                      onClick={(event) => {
                        event.stopPropagation()
                        daysEnabled.changeValue(day.value)
                      }}
                      checked={dayEnable}
                    />
                    <Typography fontWeight={600}>{t(day.legend)}</Typography>
                  </Stack>
                }
                expanded={selectedAccordions.checkValueIsActive(day.value)}
                onChange={() => selectedAccordions.changeValue(day.value)}
              >
                <FormProgramming
                  validationEnabled={dayEnable}
                  defaultValues={defaultValue ?? null}
                  ref={(element: ProgrammingRef) =>
                    (formProgrammingRef.current[index] = element)
                  }
                />
              </Accordion>
            )
          })}
        </Stack>
      )}

      <ModalAction
        disabledSubmit={!daysEnabled.values.length}
        isLoading={submitIsLoading || isLoadingScheduling}
        onSave={onSave}
        onCancel={onClose}
        sx={{
          marginTop: 2,
        }}
      />
    </Modal>
  )
}
