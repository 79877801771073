import { toast } from 'react-toastify'

import { httpClient } from 'services/api/httpClient'

import { getErrorMessage } from './getErrorMessage'

interface Props {
  url: string
  year?: string | number
  startDate?: string
  endDate?: string
  fileName?: string
  areaId?: string | number
  setGeneratingFile?: (loading: boolean) => void
  params?: any
}

export const httpClientDownloadExcel = async ({
  url,
  year,
  startDate,
  endDate,
  areaId,
  fileName,
  setGeneratingFile,
  params,
}: Props) => {
  setGeneratingFile?.(true)
  try {
    const response = await httpClient.get(url, {
      params: params || {
        startDate,
        endDate,
        areaId,
        year
      },
      responseType: 'blob', // Indica que a resposta é um arquivo binário
    })

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Cria um objeto URL temporário para o Blob
    const downloadUrl = URL.createObjectURL(blob)

    // Cria um link âncora invisível para iniciar o download
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = `${fileName ?? 'arquivo'}.xlsx`
    document.body.appendChild(link)
    link.click()

    // Remove o link âncora após o download
    document.body.removeChild(link)
  } catch (error) {
    getErrorMessage(error)
  } finally {
    setGeneratingFile?.(false)
  }
}
