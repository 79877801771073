import styled from '@emotion/styled'
export const AlertPanicCarouselWrapper = styled.div({
  width: '100%',
  height: '230px',
  padding: '10px',
  display: 'flex',
  position: 'relative',
  overflow: 'auto',
})

export const AlertPanicCarouselContent = styled.div({
  position: 'absolute',
  display: 'flex',
  gap: '10px',
})
