/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import moment from 'moment'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { DatePicker } from 'components/DatePicker'
import { Input } from 'components/Input'
import { MaskedInputNumber } from 'components/Input/mask/MaskedInputNumber'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Toggle } from 'components/Toggle'
import { getMonthFromDate, getYearFromDate } from 'helpers/date.helper'
import { createQuantityProduced } from 'services/goalAndParameter/quantityProduced/createQuantityProduced'
import { updateQuantityProduced } from 'services/goalAndParameter/quantityProduced/updateQuantityProduced'
import { CreateQuantityProducedType } from 'types/goalAndParameter/create-quantity-produced'
import { ModalType } from 'types/modal'

import { schemaCreateQuantityProduced } from '../validations/create-quantity-produced.validation'

interface Props extends ModalType {
  data: any | null
  isView?: boolean
}

export function FormQuantityProducedModal({
  data,
  isView,
  onClose,
  open,
}: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateQuantityProducedType>({
    resolver: yupResolver(schemaCreateQuantityProduced),
    defaultValues: {
      quantityProduced: data?.quantityProduced
        ? String(data?.quantityProduced)
        : '',
      year: data?.year
        ? moment().set('year', Number(data.year)).format('YYYY-MM-DD')
        : null,
      month: data?.month
        ? moment().set('month', Number(data.month)).format('YYYY-MM-DD')
        : null,
      status: data?.status ?? false,
    },
  })
  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('quantity-produced')
    onClose()
  }

  const mutationCreate = useMutation(
    (data: CreateQuantityProducedType) => {
      data.month = getMonthFromDate(String(data.month))
      data.year = getYearFromDate(String(data.year))
      return createQuantityProduced(data)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: CreateQuantityProducedType) => {
      update.month = getMonthFromDate(String(update.month))
      update.year = getYearFromDate(String(update.year))
      return updateQuantityProduced(Number(data?.id) ?? 0, update)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateQuantityProducedType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('goalAndParameter.quantityProduced.view')

    if (data?.id) return t('goalAndParameter.quantityProduced.edit')

    return t('goalAndParameter.quantityProduced.new')
  }

  return (
    <>
      <Modal title={getTitle()} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={8} sm={9}>
            <Input
              name='quantityProduced'
              label={t('input.quantityProduced')}
              type='number'
              control={control}
              fullWidth
              error={!!errors?.quantityProduced}
              errorMessage={errors?.quantityProduced?.message}
              disabled={isView}
              InputProps={{
                inputComponent: MaskedInputNumber as any,
              }}
              required
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Toggle name='status' control={control} disabled={isView} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='year'
              control={control}
              views={['year']}
              inputFormat='YYYY'
              disableMaskedInput
              label={t('input.year') ?? ''}
              error={!!errors?.year}
              errorMessage={errors?.year?.message}
              required
              disabled={isView}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name='month'
              control={control}
              views={['month']}
              inputFormat='MMMM'
              disableMaskedInput
              label={t('input.month') ?? ''}
              error={!!errors?.month}
              errorMessage={errors?.month?.message}
              required
              disabled={isView}
            />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
