import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Button/IconButton'
import { LIMIT_PER_PAGE } from 'constants/pagination'

interface Props {
  page: number
  limit?: number
  count: number
  onChangePage: (newPage: number) => void
}

export function TablePagination({
  page,
  limit = LIMIT_PER_PAGE,
  count,
  onChangePage,
}: Props) {
  const { t } = useTranslation('common')
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onChangePage(newPage)
  }

  return (
    <>
      <Stack
        direction='row'
        justifyContent={['space-between', 'flex-end']}
        alignItems='center'
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.secondary.light}`,
          paddingY: 1,
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          paddingX={2}
          order={[1, 0]}
        >
          <Typography variant='body2'>
            {page * limit < count ? page * limit : count}
          </Typography>
          <Typography variant='body2'>{t('table.of')}</Typography>
          <Typography variant='body2'>{count}</Typography>
        </Stack>

        <IconButton
          iconName='chevronLeft'
          size='small'
          color='inherit'
          disabled={page <= 1}
          sx={{ order: [0, 1] }}
          onClick={(event) => handleChangePage(event, page > 1 ? page - 1 : 1)}
        />

        <IconButton
          iconName='chevronRight'
          size='small'
          color='inherit'
          sx={{ order: 2 }}
          disabled={page * limit >= count}
          onClick={(event) =>
            handleChangePage(event, page * limit < count ? page + 1 : page)
          }
        />
      </Stack>
    </>
  )
}
