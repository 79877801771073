import { Stack, useTheme } from '@mui/material'
import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  ConnectionMode,
  Controls,
  Edge,
  Node,
  updateEdge,
  useEdgesState,
  useNodesState,
  XYPosition,
} from 'reactflow'
import 'reactflow/dist/style.css'

import { IconButton } from 'components/Button/IconButton'
import { Loading } from 'components/Loading'
import CustomConnection from 'components/reactFlow/edges/CustomConnection'
import { BackgroundNodes } from 'components/reactFlow/nodes/BackgroundNodes'
import { SquareEquipment } from 'components/reactFlow/nodes/SquareEquipment'
import { SquareFeeder } from 'components/reactFlow/nodes/SquareFeeder'
import { SquareSensor } from 'components/reactFlow/nodes/SquareSensor'
import { ResizableNode } from 'components/reactFlow/ResizableNode'
import { TypeLinkedArea } from 'constants/subarea'
import { useLoading } from 'contexts/LoadingProvider'
import { hexToRgba } from 'helpers/hexToRgba'
import { uuid } from 'helpers/uuid'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { AreaService } from 'services/area/area.service'
import { linkedAreaService } from 'services/linkedArea/linked-area.service'
import { useMapSubarea } from 'store/map-subarea.store'
import { useMapStore } from 'store/map.store'
import { AreaType } from 'types/area/area'
import { AddElementType } from 'types/area/create-linked-area'
import { EquipmentType } from 'types/equipment/equipment'
import { SensorType } from 'types/sensor/sensor'

import { ActionsSubarea } from '../ActionsSubarea'
import { FormSubAreaModal } from '../FormSubAreaModal'
import { MapForm } from '../MapForm'
import { SearchModalElement } from '../ModalAddElement/SearchModalElement'
import { SaveMapChanges } from '../SaveMapChanges'

import { useTranslation } from 'react-i18next'

const NODE_TYPES = {
  feeder: SquareFeeder,
  sensor: SquareSensor,
  group: ResizableNode,
  equipment: SquareEquipment,
  background: BackgroundNodes,
}

const EDGE_TYPES = {
  default: CustomConnection,
}

const INITIAL_NODES = [
  {
    id: 'id-background',
    type: 'background',
    position: {
      x: 0,
      y: 0,
    },
    data: {},
    draggable: false,
    selectable: false,
    style: {
      zIndex: '-2 !important',
    },
  },
] satisfies Node[]

export const MountMap = () => {
  const reactFlowWrapper = useRef<any>(null)
  const edgeUpdateSuccessful = useRef(true)
  const { setLoading } = useLoading()

  const {
    subareas,
    currentSubarea,
    resetCurrentSubarea,
    showActionsNewSubarea,
    hiddenSubareasId,
    isAirConditioning,
  } = useMapSubarea()
  const { currentMap, currentCompany } = useMapStore()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const { sweetAlert } = useSweetAlert()
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()

  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null)
  const [currentNode, setCurrentNode] = useState<null | Node>(null)
  const [openModalAdd, setOpenModalAdd] = useState(false)
  const [openModalAddElement, setOpenModalElement] = useState(false)
  const [openEditArea, setOpenEditArea] = useState(false)
  const [hasModification, setHasModification] = useState(false)
  const [currentPosition, setCurrentPosition] = useState({
    x: 0,
    y: 0,
  })
  const [nodes, setNodes, onNodesChange] = useNodesState(INITIAL_NODES)
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const mapId = searchParams.get('mapId')

  // sensores e equipamentos vinculados
  const { data: linkedAreas, isLoading } = useQuery({
    queryKey: [
      'linked-areas',
      mapId,
      currentMap?.entityId.value,
      isAirConditioning,
    ],
    queryFn: () => {
      if (mapId) {
        return !isAirConditioning
          ? linkedAreaService.allLinkedArea({
              idMapImageCompany: mapId ?? '',
            })
          : linkedAreaService.allLinkedArea({
              idMapImageCompany: mapId ?? '',
              type: TypeLinkedArea.AIR_CONDITIONING,
            })
      }

      return Promise.resolve(null)
    },
  })

  useEffect(() => {
    if (currentSubarea) {
      setNodes((oldValues) => oldValues.concat(currentSubarea))
    }
  }, [currentSubarea])

  useEffect(() => {
    isLoading ? setLoading(true) : setLoading(false)
  }, [isLoading])

  useEffect(() => {
    const newLinkedAreas =
      linkedAreas
        ?.filter((linked: any) => linked.type !== 'default')
        ?.map((linkedArea: any) => {
          return {
            id: linkedArea.idMap,
            type: linkedArea.type,
            position: {
              x: linkedArea.coordinateX,
              y: linkedArea.coordinateY,
            },
            style: {
              width: 26,
              height: 26,
            },
            parent: subareas?.find((item) => {
              if (item.areaID?.value === linkedArea.area.id.value)
                return item.idMap
            }),
            data: {
              hideData: true,
              validShiftRangeTime: true,
              type: linkedArea.type,
              sensor: linkedArea.sensor,
              equipment: linkedArea.equipment,
              linkedAreaId: linkedArea?.id?.value,
              idMap: linkedArea.idMap,
              onDelete: onDeleteNode,
            },
          }
        }) ?? []

    const newNodes2 = [...subareas, ...newLinkedAreas]

    const newNodes = newNodes2?.map<Node>((item) => {
      const isGroup = item.type === 'group'

      const result = {
        id: item.idMap ?? item.id,
        type: item.type,
        position: {
          x: item.coordinateX ?? item.position.x,
          y: item.coordinateY ?? item.position.y,
        },
        hidden: hiddenSubareasId.includes(item.areaID?.value ?? ''),
        ...(item.parent && {
          parentNode: item.parent.idMap,
          extent: 'parent',
        }),
        style: {
          width: isGroup ? item.width ?? 128 : 26,
          height: isGroup ? item.height ?? 40 : 26,
          ...(isGroup && {
            background: hexToRgba(item.color ?? '#5B20CE', 0.6),
          }),
        },
        data: {
          ...item,
          hideData: true,
          ...(item.nomeSubArea && {
            label: item.nomeSubArea,
          }),
          ...(item.parent && {
            linkedAreaId: item.data?.linkedAreaId,
            areaId: item.areaID?.value,
          }),
          validShiftRangeTime: true,
          type: item.type,
          sensor: item?.sensor ?? item.data?.sensor,
          equipment: item?.equipment ?? item.data?.equipment,
          onDelete: () => onDeleteNode(item.idMap ?? item.id),
        },
      }

      return result
    })

    setNodes([...INITIAL_NODES, ...newNodes])

    const newEdges = linkedAreas
      ?.filter((item: any) => item.type.includes('default'))
      ?.map((item: any) => {
        return {
          id: item.idMap,
          type: item.type,
          source: item.source?.idMap,
          target: item.target?.idMap,
          sourceHandle: item.sourceHandle,
          targetHandle: item.targetHandle,
          data: {
            id: item?.id,
          },
        } as Edge
      })

    setEdges(newEdges)

    queryClient.invalidateQueries('linked-areas')
  }, [subareas, hiddenSubareasId, linkedAreas])

  const onDeleteNode = (id: string) => {
    setNodes((nodesState) => nodesState.filter((node) => node.id !== id))
  }

  const handleAddClickModal = ({ type, sensor, equipment }: AddElementType) => {
    setHasModification(true)
    setOpenModalAdd(false)

    addSquareNode({
      type,
      parentNode: currentNode?.id,
      position: currentPosition,
      sensor,
      area: currentNode?.data,
      equipment,
    })
  }

  const handleDoubleClick: MouseEventHandler = (event) => {
    const target = event.target as HTMLDivElement
    const id = target.dataset['id'] ?? ''

    const node = nodes.find((node) => node.id === id && node.type === 'group')

    const addEnabledElement = node && !showActionsNewSubarea()

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - 24,
      y: event.clientY - reactFlowBounds.top - 24,
    })

    if (addEnabledElement) {
      setOpenModalElement(true)
      setCurrentNode(node)
      setCurrentPosition({
        x: position.x - node.position.x,
        y: position.y - node.position.y,
      })
    }
  }

  const checkOverlap = (elemento1: Node, elemento2: Node) => {
    const position1 = elemento1.position
    const { height = 26, width = 26 } = elemento1.style ?? {}

    const position2 = elemento2.position
    const { height: height2 = 0, width: width2 = 0 } = elemento2.style ?? {}

    // Verificar se o elemento 1 está à esquerda do elemento 2
    const left = position1.x < position2.x + (width2 as number)
    // Verificar se o elemento 1 está à direita do elemento 2
    const right = position1.x + (width as number) > position2.x
    // Verificar se o elemento 1 está acima do elemento 2
    const top = position1.y < position2.y + (height2 as number)
    // Verificar se o elemento 1 está abaixo do elemento 2
    const bottom = position1.y + (height as number) > position2.y

    // Verificar se qualquer parte do elemento 1 está sobreposta com o elemento 2
    return left && right && top && bottom
  }

  const cancelNewSubarea = () => {
    setNodes((nodes) => nodes.filter(({ id }) => id !== currentSubarea?.id))
    resetCurrentSubarea()
  }

  const handleDeleteArea = async () => {
    sweetAlert({
      title: t('map.mountMap.titleDeleteArea').toString(),
      text: t('map.mountMap.textDeleteArea').toString(),
      confirmButtonText: t('yes').toString(),
      cancelButtonText: t('no').toString(),
      onSuccess: async () => {
        setIsLoadingDelete(true)
        await AreaService.deleteArea(currentMap?.entityId.value ?? '')
          .then(() => {
            queryClient.invalidateQueries('company-map')
            queryClient.invalidateQueries('map-image-all')
            queryClient.invalidateQueries('subareas')
            queryClient.invalidateQueries('linked-areas')
            setSearchParams({
              mapId: '',
            })

            toast.success(t('map.mountMap.message.success'))
          })
          .catch(() => {
            toast.error(t('map.mountMap.message.error'))
          })
          .finally(() => {
            setIsLoadingDelete(false)
          })
      },
    })
  }

  const onConnect = (connection: Connection) => {
    setHasModification(true)
    return setEdges((edgesState) => addEdge(connection, edgesState))
  }

  const onEdgeUpdateStart = () => {
    edgeUpdateSuccessful.current = false
    setHasModification(true)
  }

  const onEdgeUpdate = (oldEdge: any, newConnection: any) => {
    edgeUpdateSuccessful.current = true
    setEdges((els) => updateEdge(oldEdge, newConnection, els))
    setHasModification(true)
  }

  const onEdgeUpdateEnd = (_: any, edge: any) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id))
    }

    edgeUpdateSuccessful.current = true
    setHasModification(true)
  }

  const onNodeDrag = (node: Node) => {
    const hasOverlap = nodes
      .filter(
        (item) =>
          item.type !== 'background' && item.parentNode === node.parentNode,
      )
      .some(
        (nodeItem) => nodeItem.id !== node.id && checkOverlap(node, nodeItem),
      )

    if (hasOverlap) {
      setNodes(nodes)
    }
  }

  /**
   * * Adicionar nó no mapa
   */
  const addSquareNode = ({
    type,
    position,
    parentNode,
    sensor,
    area,
    equipment,
  }: {
    type: 'sensor' | 'equipment' | 'feeder' | 'scheme'
    position: XYPosition
    parentNode?: string
    sensor?: SensorType
    area?: AreaType
    equipment?: EquipmentType
  }) => {
    setNodes(
      nodes.concat({
        id: uuid(),
        type,
        position,
        ...(parentNode && {
          parentNode,
          extent: 'parent',
        }),
        style: {
          width: 26,
          height: 26,
        },
        data: {
          hideData: true,
          validShiftRangeTime: true,
          type,
          sensor,
          area,
          equipment,
          onDelete: onDeleteNode,
        },
      }),
    )
  }

  return (
    <>
      <Stack
        flex={1}
        position='relative'
        sx={{ width: '100%', height: 'calc(100vh - 104px)' }}
      >
        <ActionsSubarea
          cancel={cancelNewSubarea}
          save={() => setOpenModalAdd(true)}
        />
        {isLoadingDelete ? (
          <Loading />
        ) : (
          <ReactFlow
            ref={reactFlowWrapper}
            onInit={setReactFlowInstance}
            nodeTypes={NODE_TYPES}
            edgeTypes={EDGE_TYPES}
            nodes={nodes}
            edges={edges}
            onNodeDrag={(_, node) => {
              onNodeDrag(node)
              setHasModification(true)
            }}
            onNodesChange={onNodesChange}
            onEdgesChange={(state) => {
              onEdgesChange(state)
              setHasModification(true)
            }}
            connectionMode={ConnectionMode.Loose}
            onConnect={onConnect}
            onDoubleClick={handleDoubleClick}
            zoomOnDoubleClick={false}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            defaultEdgeOptions={{
              type: 'default',
            }}
            minZoom={0.2}
            fitViewOptions={{
              padding: 0,
            }}
            fitView
            selectNodesOnDrag={false}
          >
            <Background
              gap={32}
              color={theme.palette.background.paper}
              variant={BackgroundVariant.Lines}
            />
            <Stack
              position={'absolute'}
              bottom={10}
              right={33}
              flexDirection={'row'}
              alignItems={'center'}
              gap={1}
              zIndex={10}
              sx={{
                '& .react-flow__controls-button': {
                  '&:hover': {
                    background: 'none',
                  },
                },
              }}
            >
              <Controls
                showInteractive={false}
                style={{
                  position: 'initial',
                  display: 'flex',
                  zIndex: 10,
                  borderRadius: 4,
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.background.paper
                      : theme.palette.primary.main,
                  color: '#FFFFFF',
                  fill: '#FFFFFF',
                  minHeight: 44,
                  alignItems: 'center',
                }}
              />

              <IconButton
                variant='contained'
                iconName='edit'
                sx={{
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.background.paper
                      : theme.palette.primary.main,
                }}
                onClick={() => setOpenEditArea(true)}
              />
              <IconButton
                variant='contained'
                iconName='delete'
                sx={{
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.background.paper
                      : theme.palette.primary.main,
                }}
                onClick={handleDeleteArea}
              />
            </Stack>
          </ReactFlow>
        )}

        {hasModification && !showActionsNewSubarea() && (
          <SaveMapChanges
            nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onSuccess={() => setHasModification(false)}
          />
        )}
      </Stack>

      {openModalAdd && (
        <FormSubAreaModal
          open={openModalAdd}
          cancelNewSubarea={cancelNewSubarea}
          onClose={() => setOpenModalAdd(false)}
          nodes={nodes}
        />
      )}
      {openModalAddElement && (
        <SearchModalElement
          open={openModalAddElement}
          onClose={() => setOpenModalElement(false)}
          nodes={nodes}
          edges={edges}
          data={currentNode}
          onClick={handleAddClickModal}
        />
      )}

      {openEditArea && (
        <MapForm
          open={openEditArea}
          data={currentMap}
          onClose={() => setOpenEditArea(false)}
          companyId={String(currentCompany)}
        />
      )}
    </>
  )
}
