import { convertTimeType, getTime } from 'helpers/date.helper'
import { uuid } from 'helpers/uuid'
import { LevelType } from 'types/level/level'

export function levelMapper(data: LevelType[]) {
  return data.map((item: LevelType) => ({
    id: item.entityId?.value ?? 0,
    name: item.name,
    value: String(getTime(item.time)),
    type: convertTimeType(item.time),
    _id: uuid(),
  }))
}
