import {
  DataBranchType,
  DataCompanyType,
  ICreateBranch,
  IUtilities,
} from 'types/branch/branch'
import { ID } from 'types/id'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'

import { httpClient } from './api/httpClient'

export type IBranchList = {
  id: ID
  name: string
}[]

const create = async (data: ICreateBranch) => {
  return await httpClient.post('/company', {
    name: data.name,
    streetAddress: data.streetAddress,
    number: data.number,
    additionalInfo: data.complement,
    district: data.district,
    city: data.city,
    state: data.state,
    zipCode: data.zipCode,
    plusCode: data.plusCode,
    latitude: data.latitude,
    longitude: data.longitude,
    utilities: data.utilities,
  })
}

const update = async (id: number, data: ICreateBranch) => {
  await httpClient.put(`/company/${id}`, {
    name: data.name,
    streetAddress: data.streetAddress,
    number: data.number,
    additionalInfo: data.complement,
    district: data.district,
    city: data.city,
    state: data.state,
    zipCode: data.zipCode,
    plusCode: data.plusCode,
    latitude: Number(data.latitude),
    longitude: Number(data.longitude),
    utilities: data.utilities,
  })
}

type NewType = PaginationType<DataBranchType>
const listBranches = async ({
  page = 1,
}: PaginationParamType): Promise<NewType> => {
  const { data } = await httpClient.get<PaginationResponseType>('/company')

  return {
    items: data?.items ?? [],
    currentPage: page,
    perPage: data?.perPage,
    total: data?.total,
  }
}

const listUtilities = async (): Promise<IUtilities> => {
  const response = await httpClient.get('/utility')

  return response.data
}

const deleteBranch = async (id: number) => {
  await httpClient.delete(`/company/${id}`)
}

const findListBranchesSelect = async (): Promise<IBranchList> => {
  const response = await httpClient.get('/company/list-select')
  // TODO: Remover map e tratar somente onde for chamado
  return response.data.map((branch: any) => ({
    id: branch.id,
    name: branch.name,
  }))
}

// TODO: padronizar o nome para company onde for branch
const findCompanyById = async (id: string): Promise<DataCompanyType> => {
  const response = await httpClient.get(`/company/${id}`)

  return response.data
}

export const branchService = {
  create,
  update,
  deleteBranch,
  listBranches,
  listUtilities,
  findListBranchesSelect,
  findCompanyById,
}
