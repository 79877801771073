import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'

interface ParameterAlertProps {
  parameters: any[]
  sensor: any
  temperature?: number
  humidity?: number
  value?: number
}

const ParameterAlert: React.FC<ParameterAlertProps> = ({
  parameters,
  sensor,
  temperature,
  humidity,
  value,
}) => {
  const sensorType = sensor?.type?.toUpperCase()
  const parameter = parameters?.find((parameter: any) => {
    if (
      sensorType === 'TEMP_HUM' &&
      parameter.unit?.name?.toUpperCase() === sensorType
    ) {
      return (
        parameter.temperatureMin > Number(temperature || 0) ||
        parameter.temperatureMax < Number(temperature || 0) ||
        parameter.percentageMin > Number(humidity || 0) ||
        parameter.percentageMax < Number(humidity || 0)
      )
    }

    if (
      sensorType === 'TEMP' &&
      parameter.unit?.name?.toUpperCase() === sensorType
    ) {
      return (
        parameter.temperatureMin > Number(value || 0) ||
        parameter.temperatureMax < Number(value || 0)
      )
    }

    if (
      sensorType === 'HUM' &&
      parameter.unit?.name?.toUpperCase() === sensorType
    ) {
      return (
        parameter.percentageMin > Number(value || 0) ||
        parameter.percentageMax < Number(value || 0)
      )
    }

    return false
  })

  return (
    <>
      {parameter &&
        (sensor?.type?.toUpperCase() === 'TEMP' ||
          sensor?.type?.toUpperCase() === 'HUM' ||
          sensor?.type?.toUpperCase() === 'TEMP_HUM') && (
          <>
            {sensor?.type?.toUpperCase()?.includes('TEMP_HUM') && (
              <>
                {(parameter?.temperatureMin > Number(temperature || 0) ||
                  parameter?.temperatureMax < Number(temperature || 0)) && (
                  <Tooltip
                    title={`Temperatura fora do parâmetro (${parameter?.temperatureMin} - ${parameter?.temperatureMax}ºC)`}
                    placement='top'
                    arrow
                  >
                    <Typography
                      component='div'
                      sx={{
                        position: 'absolute',
                        top: -32,
                        whiteSpace: 'nowrap',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {parameter?.temperatureMin} - {parameter?.temperatureMax}
                      ºC
                    </Typography>
                  </Tooltip>
                )}
                {(parameter?.percentageMin > Number(humidity || 0) ||
                  parameter?.percentageMax < Number(humidity || 0)) && (
                  <Tooltip
                    title={`Umidade fora do parâmetro (${parameter?.percentageMin} - ${parameter?.percentageMax}ºC)`}
                    placement='top'
                    arrow
                  >
                    <Typography
                      component='div'
                      sx={{
                        position: 'absolute',
                        top: -16,
                        whiteSpace: 'nowrap',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {parameter?.percentageMin} - {parameter?.percentageMax}%
                    </Typography>
                  </Tooltip>
                )}
              </>
            )}
            {sensor?.type?.toUpperCase() === 'TEMP' &&
              (parameter?.temperatureMin > Number(value || 0) ||
                parameter?.temperatureMax < Number(value || 0)) && (
                <Tooltip
                  title={`Temperatura fora do parâmetro (${parameter?.temperatureMin} - ${parameter?.temperatureMax}ºC)`}
                  placement='top'
                  arrow
                >
                  <Typography
                    component='div'
                    sx={{
                      position: 'absolute',
                      top: -16,
                      whiteSpace: 'nowrap',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {parameter?.temperatureMin} - {parameter?.temperatureMax}
                    ºC
                  </Typography>
                </Tooltip>
              )}

            {sensor?.type?.toUpperCase() === 'HUM' &&
              (parameter?.percentageMin > Number(value || 0) ||
                parameter?.percentageMax < Number(value || 0)) && (
                <Tooltip
                  title={`Umidade fora do parâmetro (${parameter?.percentageMin} - ${parameter?.percentageMax}ºC)`}
                  placement='top'
                  arrow
                >
                  <Typography
                    component='div'
                    sx={{
                      position: 'absolute',
                      top: -16,
                      whiteSpace: 'nowrap',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {parameter?.percentageMin} - {parameter?.percentageMax}%
                  </Typography>
                </Tooltip>
              )}

            <Box
              className='pulse'
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 28,
                height: 28,
                background: (theme) => theme.palette.warning.main,
                '&::after, &::before': {
                  background: (theme) => theme.palette.warning.main,
                  opacity: 0.8,
                },
                borderRadius: 2,
                zIndex: -1,
              }}
            />
          </>
        )}
    </>
  )
}

export default ParameterAlert
