import { create } from 'zustand'

type MapStore = {
  showHeatMap: boolean
  showHumidityMap: boolean
  setShowHeatMap: (showHeatMap: boolean) => void
  setShowHumidityMap: (showHumidityMap: boolean) => void
  updateShowHeatMap: (showHeatMap: boolean) => void
  updateShowHumidityMap: (showHumidityMap: boolean) => void
}

export const useHeatAndHumidityMapStore = create<MapStore>((set) => ({
  showHeatMap: false,
  showHumidityMap: false,
  setShowHeatMap: (showHeatMap: boolean) => {
    set({ showHeatMap, showHumidityMap: false })
  },
  setShowHumidityMap: (showHumidityMap: boolean) => {
    set({ showHumidityMap, showHeatMap: false })
  },
  updateShowHeatMap: (showHeatMap: boolean) => {
    set({ showHeatMap })
  },
  updateShowHumidityMap: (showHumidityMap: boolean) => {
    set({ showHumidityMap })
  },
}))
