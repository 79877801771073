import {
  Breadcrumbs,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { IconButton } from 'components/Button/IconButton'
import { Logo } from 'components/Logo'
import { MenuProps } from 'configs/menuConfig'

import { BreadcrumbLink } from '../BreadcrumbLink'

type BreadcrumbType = {
  route: string
  name: string
}

interface Props {
  menu?: MenuProps
}

export function Navbar({ menu }: Props) {
  const { t } = useTranslation('common')
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const isDark = theme.palette.mode === 'dark'

  const handleGoBack = (breadcrumbs?: BreadcrumbType[]) => {
    if (breadcrumbs) {
      return navigate(breadcrumbs[breadcrumbs?.length - 2]?.route)
    }

    return navigate(-1)
  }

  const getTitle = (
    menus: MenuProps[],
    parentMenu: MenuProps,
  ): Partial<MenuProps> => {
    if (menu?.route === pathname) return menu

    const findMenus = menus.filter((item) => pathname.includes(item.route))

    let subMenu = {}

    findMenus.forEach((item) => {
      if (item.route === pathname) {
        subMenu = item
      }

      if (item.route !== pathname && item?.submenu) {
        subMenu = getTitle(item.submenu, item)
      }
    })

    if (!subMenu) {
      return parentMenu
    }

    return subMenu
  }

  const getBreadcrumbs = (menus: MenuProps): BreadcrumbType[] => {
    const breadcrumbs: BreadcrumbType[] = []

    breadcrumbs.push({
      route: menus.route,
      name: menus.name,
    })

    const findMenus =
      menus?.submenu?.filter((item) => pathname.includes(item.route)) ?? []

    findMenus.forEach((item) => {
      if (item.route === pathname) {
        breadcrumbs.push({
          route: item.route,
          name: item.name,
        })
      }

      if (item.route !== pathname && item?.submenu) {
        const test = getBreadcrumbs(item)
        breadcrumbs.push(...test)
      }
    })

    return breadcrumbs
  }

  const submenu = menu?.submenu ? getTitle(menu.submenu, menu) : undefined
  const breadcrumbs: BreadcrumbType[] = menu ? getBreadcrumbs(menu) : []

  const onToggleTitle = (title: string) => {
    if (!title) {
      document.title = ''
    } else {
      document.title = `${title} - Jump`
    }
  }

  useEffect(() => {
    let title = ''

    if (!menu?.submenu) {
      title = t(
        menu?.name ? `menu.${menu.name}` : pathname === '/' ? 'Dashboards' : '',
      )
    }

    if (menu?.submenu) {
      title = t(submenu?.name ? `menu.${submenu?.name}` : '')
    }

    onToggleTitle(title)
  }, [submenu, menu, onToggleTitle])

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <Logo
        mode='VERTICAL'
        sx={{
          height: (theme) => theme.spacing(4),
          width: 'auto',
        }}
      />

      {!menu?.submenu && (
        <Typography variant='h1' sx={{ fontSize: ['1.125rem', '1.5rem'] }}>
          {t(
            menu?.name
              ? `menu.${menu.name}`
              : pathname === '/'
              ? 'Dashboards'
              : '',
          )}
        </Typography>
      )}
      {menu?.submenu && (
        <Stack spacing={2} direction='row' alignItems='center'>
          {breadcrumbs.length > 1 && (
            <IconButton
              variant='contained'
              iconName='chevronLeft'
              onClick={() => handleGoBack(breadcrumbs)}
            />
          )}

          <Stack
            sx={{
              '& > nav ol li a': {
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
                borderRadius: 1,
                font: 'normal normal 500 10px/20px Noto Sans',
                p: 1,
              },
              '& > nav ol li div': {
                backgroundColor: (theme) =>
                  `${theme.palette.primary.main} !important`,
                borderRadius: 1,
                font: 'normal normal 500 10px/20px Noto Sans',
                p: 0.5,
                color: isDark ? theme.palette.primary.contrastText : 'white',
              },
            }}
          >
            {isDesktop &&
              breadcrumbs.length > 1 &&
              !menu?.hiddenBreadcrumbs && (
                <Breadcrumbs separator='›' aria-label='breadcrumb'>
                  {breadcrumbs.map((item, index) => (
                    <BreadcrumbLink
                      key={index}
                      link={item.route}
                      disabled={index >= breadcrumbs.length - 1}
                    >
                      {t(`menu.${item.name}`).toUpperCase()}
                    </BreadcrumbLink>
                  ))}
                </Breadcrumbs>
              )}

            <Typography
              variant='h1'
              sx={{
                fontSize: ['1.125rem', '1.2rem'],
                mt: 0.5,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {t(submenu?.name ? `menu.${submenu.name}` : '')}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
