import * as yup from 'yup'

export const schemaBranch = () =>
  yup.object({
    name: yup.string().required(),
    zipCode: yup.string().min(8).required(),
    streetAddress: yup.string().required(),
    city: yup.string().required(),
    district: yup.string().required(),
    state: yup.string().required(),
    utilities: yup.array().required(),
  })
