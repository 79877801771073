import { Stack, Typography, useTheme } from '@mui/material'
import { PanicButtonTypeEnum } from 'core/enums/PanicButtonTypeEnum'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Accordion } from 'components/Accordion'
import { IconButton } from 'components/Button/IconButton'
import { accordionStyle } from 'components/ModalProgrammingAirConditioning/styles'
import { Select } from 'components/Select'
import { addAlpha } from 'helpers/addAlpha'
import { useCheckboxSelection } from 'hooks/useCheckboxSelection'
import { branchService } from 'services/branch.service'
import { panicMonitoringAreaSidebarStore } from 'store/panic-monitoring/panicMonitoringAreaSidebar.store'
import { useSearchParams } from 'react-router-dom'

export const PanicMonitoringAreaSidebar = () => {
  const { t } = useTranslation('common')
  const [expandedFilter, setExpandedFilter] = useState(true)
  const {
    companyId,
    setCompanyId,
    setPanicButtonTypes,
    totalEquipment,
    totalTriggered,
  } = panicMonitoringAreaSidebarStore()
  const searchParamsRes = useSearchParams()
  const setSearchParams = searchParamsRes[1]

  const panicButtonSelection = useCheckboxSelection<PanicButtonTypeEnum>({
    initialValues: [
      PanicButtonTypeEnum.Activated,
      PanicButtonTypeEnum.Operated,
    ],
  })
  // theme
  const theme = useTheme()

  const isDark = theme.palette.mode === 'dark'

  const filters = [
    {
      title: 'panicMonitoring.activated',
      value: PanicButtonTypeEnum.Activated,
    },
    {
      title: 'panicMonitoring.operated',
      value: PanicButtonTypeEnum.Operated,
    },
  ]

  const { data: companies } = useQuery({
    queryKey: 'list-companies',
    queryFn: branchService.findListBranchesSelect,
    select: (data) => {
      return data.map((item) => ({
        id: item.id.value,
        name: item.name,
      }))
    },
  })

  useEffect(() => {
    if (companies?.length) setCompanyId(companies[0]?.id)
  }, [companies])

  useEffect(() => {
    setPanicButtonTypes(panicButtonSelection.values)
  }, [panicButtonSelection.values])

  return (
    <>
      <Select
        name='unity'
        value={companyId}
        onChange={({ target }) => {
          setSearchParams({ mapId: '' })
          setCompanyId(target.value as string)
        }}
        label={t('units.unit')}
        options={companies}
      />

      <Accordion
        title={
          <div>
            <Typography
              marginBottom={1}
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {t('filter')}
            </Typography>
            <Typography>
              {t('buttons')}: {totalTriggered}
            </Typography>
            <Typography>
              {t('buttons')} {t('panicMonitoring.activated')}: {totalEquipment}
            </Typography>
          </div>
        }
        sx={{
          ...accordionStyle,
          '& > div': {
            ...accordionStyle['& > div'],
            background: `${theme.palette.background.paper} !important`,
          },
        }}
        expanded={expandedFilter}
        onChange={() => setExpandedFilter(!expandedFilter)}
      >
        {filters.map((filter) => {
          const isActive = panicButtonSelection.checkValueIsActive(filter.value)
          const border = `1px solid ${isActive ? '#66009D' : 'transparent'}`
          return (
            <Stack
              key={filter.title}
              marginTop={2}
              bgcolor={isActive ? addAlpha('#66009D', 0.1) : '#0C0C25'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={2}
              border={border}
              sx={{
                cursor: 'pointer',
              }}
              borderRadius={1}
              onClick={() => panicButtonSelection.changeValue(filter.value)}
            >
              <IconButton
                sx={{
                  border,
                  borderRadius: 1,
                }}
                title={t(filter.title) as string}
                color='secondary'
                variant='contained'
                iconName={isActive ? 'visibility' : 'visibilityOff'}
              />
              <Typography fontWeight={600}>{t(filter.title)}</Typography>
            </Stack>
          )
        })}
      </Accordion>
    </>
  )
}
