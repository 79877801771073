/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { createContext, useCallback } from 'react'

import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'
import { useAuthStore } from 'store/auth.store'
import { UserType } from 'types/user/userType'

type SignInType = {
  login: string
  password: string
}

interface AuthContextType {
  user: UserType | null
  signIn: (
    user: SignInType,
    callback: VoidFunction,
    callbackError?: VoidFunction,
  ) => void
  signOut: (callback?: VoidFunction) => void
  isAuthenticated: boolean
  loading: boolean
  isPermission: (roles: string[]) => boolean
}
const AuthContext = createContext<AuthContextType>(null!)

function AuthProvider({ children }: { children: React.ReactNode }) {
  const {
    setToken,
    setProfile,
    user,
    signOut,
    isAuthenticated,
    setLoading,
    loading,
  } = useAuthStore((state) => state)

  const isPermission = useCallback(
    (roles: string[]) => {
      try {
        const roleUser = user?.roles ?? []
        let isPermission = false
        if (roleUser) {
          roleUser.map((role: string) => {
            if (roles.includes(role)) {
              isPermission = true
            }
          })
        }
        return isPermission
      } catch (error) {
        return false
      }
    },
    [user],
  )

  const signIn = async (
    { login, password }: SignInType,
    callback: VoidFunction,
    callbackError?: VoidFunction,
  ) => {
    setLoading(true)
    try {
      const response = await httpClient.post('auth/login-user', {
        login,
        password,
      })

      const responseProfile = await httpClient.get(
        'user-account/find-profile',
        {
          headers: {
            Authorization: `Bearer ${response.data.jwtToken}`,
          },
        },
      )

      setToken(response.data.jwtToken)
      setProfile(responseProfile.data)

      callback()
    } catch (error: any) {
      getErrorMessage(error)

      signOut()

      callbackError && callbackError()
    } finally {
      setLoading(false)
    }
  }

  const value = {
    user,
    signIn,
    signOut,
    isAuthenticated,
    loading,
    isPermission,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
