/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { colors } from 'helpers/colors'
import { httpClient } from 'services/api/httpClient'
import { ID } from 'types/id'

type TempAndHumiType = {
  id?: ID
  area: string
  value: number
}

export function useRealTimeHumidityChart() {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const [openModal, setOpenModal] = useState(false)

  const [generatingFile, setGeneratingFile] = useState(false)
  const [selectedArea, setSelectedArea] = useState<any | null>()

  const {
    data: dataHumidity,
    refetch: refetchHumidity,
    isLoading: isLoadingHumidity,
  } = useQuery({
    queryKey: 'humidity-find-all-last-hour',
    queryFn: async () => {
      const response = await httpClient.get(
        '/dashboard-temperature-and-humidity/humidity/find-all-last-hour',
      )

      return (response.data || [])
        .map((item: TempAndHumiType | null) => {
          if (item && typeof item.value === 'number') {
            return {
              ...item,
              value: Math.round(item.value),
            }
          }
          return null
        })
        .filter(
          (item: any): item is TempAndHumiType => item !== null,
        ) as TempAndHumiType[]
    },
  })

  const onOpenModal = (item: any) => {
    setSelectedArea(item)
    setOpenModal(true)
  }

  const onCloseModal = () => {
    setSelectedArea(null)
    setOpenModal(false)
  }

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetchHumidity()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const items = dataHumidity?.map((item) => {
    return {
      id: item?.id?.value,
      name: item.area,
      color: colors.temperature[1]?.start,
      value: item.value,
    }
  })

  return {
    isDesktop,
    items,
    t,
    generatingFile,
    setGeneratingFile,
    openModal,
    onOpenModal,
    onCloseModal,
    selectedArea,
    isLoadingHumidity,
  }
}
