import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface StepperSensorProps {
  activeStep: number
  steps?: string[],
  isView?: boolean
}

export const StepperSensor = ({ activeStep, steps, isView }: StepperSensorProps) => {
  const [skipped] = useState(new Set<number>())

  const { t } = useTranslation('common')

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  return (
    <Box sx={{ width: '100%', mb: 4 }}>
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ width: 300, fontSize: 12 }}
        >
          {steps?.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{t(label)}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Stack>
    </Box>
  )
}
