import { Box, IconButton, IconButtonProps } from '@mui/material'

import { Sensor, SensorIconType } from '..'

interface Props extends IconButtonProps {
  icon?: SensorIconType
  size?: 'small' | 'medium'
  error?: boolean
  hiddenTitle?: boolean
}

export function SensorIconButton({
  icon,
  size,
  error,
  sx,
  hiddenTitle,
  ...rest
}: Props) {
  const component = (
    <IconButton
      size={size}
      aria-label={icon || rest?.['aria-label']}
      sx={{
        minWidth: size === 'small' ? 28 : 44,
        minHeight: size === 'small' ? 28 : 44,
        padding: 0,
        background: 'transparent',
        ...sx,
      }}
      {...rest}
    >
      {icon && (
        <Sensor
          icon={icon}
          size={size}
          error={error}
          hiddenTitle={hiddenTitle}
        />
      )}
      {!icon && (
        <Box
          sx={{
            borderRadius: 1,
            height: 20,
            width: 20,
            bgcolor: (theme) => theme.palette.secondary.main,
          }}
        />
      )}
    </IconButton>
  )

  return component
}
