/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { httpClient } from 'services/api/httpClient'
import { getAllEquipmentTypeWaterTank } from 'services/equipments/getAllEquipmentTypeWaterTank'

interface Props {
  isPreview?: boolean
}

export function useWaterTankLevelRealTimeChart(props: Props) {
  const { t, i18n } = useTranslation('common')
  const [generatingFile, setGeneratingFile] = useState(false)

  const [equipment, setSelectEquipment] = useState<any | null>(null)

  const { data, refetch } = useQuery({
    queryKey: ['water-tank-level-real-time', equipment?.value],
    queryFn: async () => {
      if (!equipment) return []

      const response = await httpClient.get<any[]>(
        '/dashboard-water/water-tank-level-real-time',
        {
          params: {
            equipmentId: equipment?.value,
          },
        },
      )

      return response.data?.reduce((acc, item) => {
        const { device, value, waterinlet, wateroutlet } = item

        const existingEntry = acc.find((entry: any) => entry.device === device)

        if (existingEntry) {
          if (waterinlet) {
            existingEntry.valueWaterinlet += value
          }
          if (wateroutlet) {
            existingEntry.valueWateroutlet += value
          }
        } else {
          const newEntry = {
            device,
            valueWaterinlet: waterinlet ? value : 0,
            valueWateroutlet: wateroutlet ? value : 0,
          }
          acc.push(newEntry)
        }

        return acc
      }, [])
    },
  })

  const tankLevel = data?.[0]
    ? data?.[0]?.valueWaterinlet - data?.[0]?.valueWateroutlet
    : 0

  const { data: equipments, refetch: refetchEquipments } = useQuery({
    queryKey: 'all-equipments',
    queryFn: async () => {
      return await getAllEquipmentTypeWaterTank()
    },
    select: (data) => {
      return data?.map((item) => ({
        label: item.name,
        value: String(item?.id),
        type: item.type,
      }))
    },
  })

  useEffect(() => {
    if (equipments?.length) {
      const findWaterTank = equipments?.find(
        (item: any) => item.type === 'CAIXA_DAGUA',
      )
      setSelectEquipment(findWaterTank ?? equipments[0] ?? null)
    }
  }, [equipments])

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
      refetchEquipments()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  return {
    t,
    generatingFile,
    setGeneratingFile,
    data,
    tankLevel,
    equipments,
    equipment,
    setSelectEquipment,
  }
}
