import { TextFieldProps, useMediaQuery, useTheme } from '@mui/material'
import {
  CalendarPickerView,
  DesktopDatePicker,
  MobileDatePicker,
} from '@mui/x-date-pickers'
import { Controller } from 'react-hook-form'

import { ErrorMessage } from 'components/Input/ErrorMessage'
import InputBase from 'components/Input/InputBase'
import { ValidationError } from 'types/ValidationError'

type Props = TextFieldProps & {
  name: string
  label?: string | null
  views?: CalendarPickerView[]
  inputFormat: string
  disableMaskedInput?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessage?: any | ValidationError | string
  value?: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void
}

export function DatePicker(props: Props) {
  const {
    label,
    views,
    inputFormat,
    disableMaskedInput,
    value,
    onChange,
    control,
    errorMessage,
    ...rest
  } = props
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      {control ? (
        <Controller
          name={rest?.name}
          control={control}
          defaultValue=''
          render={({ field }) => (
            <>
              {isDesktop ? (
                <DesktopDatePicker
                  label={label}
                  views={views}
                  inputFormat={inputFormat}
                  value={field?.value}
                  disableMaskedInput={disableMaskedInput}
                  disabled={rest?.disabled}
                  onChange={(value) => field?.onChange(value)}
                  renderInput={(params) => (
                    <InputBase
                      fullWidth
                      {...rest}
                      {...params}
                      helperText={
                        errorMessage ? (
                          <ErrorMessage error={errorMessage} />
                        ) : (
                          rest?.helperText
                        )
                      }
                    />
                  )}
                />
              ) : (
                <MobileDatePicker
                  label={label}
                  views={views}
                  inputFormat={inputFormat}
                  value={field?.value}
                  disableMaskedInput={disableMaskedInput}
                  disabled={rest?.disabled}
                  onChange={(value) => field?.onChange(value)}
                  renderInput={(params) => (
                    <InputBase
                      fullWidth
                      {...rest}
                      {...params}
                      helperText={
                        errorMessage ? (
                          <ErrorMessage error={errorMessage} />
                        ) : (
                          rest?.helperText
                        )
                      }
                    />
                  )}
                />
              )}
            </>
          )}
        />
      ) : (
        <>
          {isDesktop ? (
            <DesktopDatePicker
              label={label}
              views={views}
              inputFormat={inputFormat}
              value={value}
              disableMaskedInput={disableMaskedInput}
              disabled={rest?.disabled}
              onChange={(value) => onChange?.(String(value ?? ''))}
              renderInput={(params) => (
                <InputBase fullWidth {...rest} {...params} />
              )}
            />
          ) : (
            <MobileDatePicker
              label={label}
              views={views}
              inputFormat={inputFormat}
              value={value}
              disableMaskedInput={disableMaskedInput}
              disabled={rest?.disabled}
              onChange={(value) => onChange?.(String(value ?? ''))}
              renderInput={(params) => (
                <InputBase fullWidth {...rest} {...params} />
              )}
            />
          )}
        </>
      )}
    </>
  )
}
