import { useState } from 'react'

type Props<V> = {
  initialValues?: V[]
  filteToRemoveValue?: (value: V, valueComparison: V) => boolean
}

export const useCheckboxSelection = <V = string>(props?: Props<V>) => {
  const [values, setValues] = useState<V[]>(() => props?.initialValues ?? [])

  const filteToRemoveDefaultValue = (value: V, valueComparison: V) => {
    return value !== valueComparison
  }

  const checkValueIsActive = (value: V) => values.includes(value)

  const changeValue = (value: V) => {
    if (checkValueIsActive(value)) {
      const method = props?.filteToRemoveValue ?? filteToRemoveDefaultValue
      setValues(values.filter((item) => method(item, value)))
    } else {
      setValues(values.concat(value))
    }
  }

  return {
    values,
    setValues,
    changeValue,
    checkValueIsActive,
  }
}
