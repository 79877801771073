export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const weekDaysUpperCase = [
  {
    id: 1,
    name: 'MONDAY',
    translate: 'weekDays.monday',
  },
  {
    id: 2,
    name: 'TUESDAY',
    translate: 'weekDays.tuesday',
  },
  {
    id: 3,
    name: 'WEDNESDAY',
    translate: 'weekDays.wednesday',
  },
  {
    id: 4,
    name: 'THURSDAY',
    translate: 'weekDays.thursday',
  },
  {
    id: 5,
    name: 'FRIDAY',
    translate: 'weekDays.friday',
  },
  {
    id: 6,
    name: 'SATURDAY',
    translate: 'weekDays.saturday',
  },
  {
    id: 7,
    name: 'SUNDAY',
    translate: 'weekDays.sunday',
  },
]

export const abbreviationWeekDays = [
  'abbreviatedWeek.mon',
  'abbreviatedWeek.tue',
  'abbreviatedWeek.wed',
  'abbreviatedWeek.thu',
  'abbreviatedWeek.fri',
  'abbreviatedWeek.sat',
  'abbreviatedWeek.sun',
]

export const weekDaysAbbreviation = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
]

export const weekdayId = [1, 2, 3, 4, 5, 6, 7]
