import { LinearProgress, Stack, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'

interface TimeProgressProps {
  timeInMinutes: number
  showProgress?: boolean
}

export function DashboardProgressTimeRefresh({
  timeInMinutes,
  showProgress = false,
}: TimeProgressProps) {
  const [minutes, setMinutes] = useState(timeInMinutes)
  const [seconds, setSeconds] = useState(0)
  const [currentSecond, setCurrentSecond] = useState(0)

  const totalTimeInSeconds = timeInMinutes * 60

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        setMinutes(timeInMinutes)
        setCurrentSecond(0)
      } else {
        if (seconds === 0) {
          setMinutes(minutes - 1)
          setSeconds(59)
          setCurrentSecond((state) => state + 1)
        } else {
          setSeconds(seconds - 1)
          setCurrentSecond((state) => state + 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [minutes, seconds])

  return (
    <Tooltip title='Tempo para atualizar a página'>
      <Stack mb={2}>
        {showProgress && (
          <p>{Math.floor((currentSecond / totalTimeInSeconds) * 100)}%</p>
        )}
        <p>
          Atualização em{' '}
          {`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
            2,
            '0',
          )}`}
        </p>
        <LinearProgress
          variant='determinate'
          value={(currentSecond / totalTimeInSeconds) * 100}
        />
      </Stack>
    </Tooltip>
  )
}
