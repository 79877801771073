enum TypeEnum {
  PREVENTIVE = 'PREVENTIVE',
  CORRECTIVE = 'CORRECTIVE',
}

const findType = (type: string) => {
  return TypeEnum[type as keyof typeof TypeEnum]
}

export { TypeEnum, findType }
