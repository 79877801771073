import { FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'

interface CheckboxItem {
  id: string
  label: string
  checked: boolean
  parentId?: string
  children?: CheckboxItem[]
}

interface CheckboxProps {
  item: CheckboxItem
  selectedCheckboxes?: string[]
  onCheckboxChange: (checkboxId: string, isChecked: boolean) => void
  disabled?: boolean
}

export const CustomCheckbox: React.FC<CheckboxProps> = ({
  item,
  onCheckboxChange,
  disabled,
}) => {
  const { t } = useTranslation('common')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(item.id, event.target.checked)
  }
  return (
    <FormControlLabel
      label={t(`profile.${item.label}`)}
      control={
        <Checkbox
          checked={item.checked}
          onChange={handleChange}
          disabled={disabled}
        />
      }
    />
  )
}
