/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Sensor } from 'components/Sensor'
import { Toggle } from 'components/Toggle'
import { changeStatusProcedure } from 'services/procedures/updateStatusProcedure'
import { ProceduresType } from 'types/procedures/procedures'

export function proceduresMapper(procedures: ProceduresType[]) {
  const { t } = useTranslation('common')
  const QueryClient = useQueryClient()

  const handleToggleStatus = async (id: string) => {
    try {
      await changeStatusProcedure(id)
      toast.success('Status atualizado com sucesso!')
      QueryClient.invalidateQueries('procedures')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  return procedures.map((item) => ({
    ...item,
    id: item?.id?.value ?? item?.id,
    name: item?.name ?? '',
    description: item?.description ?? '',
    linkedDevices: item.sensors ? (
      <Stack spacing={1}>
        {item.sensors.map((sensor) => (
          <Stack direction={'row'} spacing={1} key={sensor.id?.value}>
            <Sensor
              icon={sensor?.sensor?.type?.toUpperCase() as any}
              size='small'
            />
            {sensor?.sensor?.status ? (
              <Chip
                label={t('on')}
                color='success'
                variant='outlined'
                size='small'
              />
            ) : (
              <Chip
                label={t('off')}
                color='error'
                variant='outlined'
                size='small'
              />
            )}
          </Stack>
        ))}
      </Stack>
    ) : (
      <></>
    ),
    status: (
      <Toggle
        name='toggle'
        checked={item.status}
        onChange={() => {
          handleToggleStatus(item?.id?.value ?? item?.id)
        }}
      />
    ),
  }))
}
