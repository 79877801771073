import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

export function colorBySensor(sensorType: SensorTypeEnum) {
  switch (sensorType) {
    case SensorTypeEnum.TEMP:
      return '#517CD6'
    case SensorTypeEnum.HUM:
      return '#81FFB5'
    case SensorTypeEnum.TEMP_HUM:
      return '#E386D6'
    case SensorTypeEnum.ELECTRIC:
      return '#FFBA5E'
    case SensorTypeEnum.WATER:
      return '#90CFFC'
    case SensorTypeEnum.AIR:
      return '#6A5EF5'
    case SensorTypeEnum.OPENING:
      return '#FFFF00'
    case SensorTypeEnum.BTN_PANIC:
      return '#F24A4A'
    default:
      return '#FFFFFF'
  }
}
