import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import dayjs from 'dayjs'

import { httpClient } from 'services/api/httpClient'
import {
  PaginationType,
  PaginationParamType,
  PaginationResponseType,
} from 'types/pagination'
import { DataRecordSensorType } from 'types/sensor/sensor'
import { SensorDataRecordType } from 'types/sensor/sensor-data-record'
import { SensorDataRecordInHourlyIntervals } from 'types/sensordata/sensor-data-hourly'

type NewType = PaginationType<DataRecordSensorType>

export enum ValueType {
  HUMIDITY = 'HUM',
  TEMPERATURE = 'TEMP',
}

type ParamsType = PaginationParamType & {
  areaId: string
  valueType: ValueType
}

type HourlyIntervalsParams = {
  day: Date
  sensorIds: string[]
  measurement: string
  sensorType: SensorTypeEnum
}

const findDataInHourlyIntervals = (params: HourlyIntervalsParams) => {
  return httpClient.get<SensorDataRecordInHourlyIntervals[]>(
    'sensor-data-record/hourly-intervals',
    {
      params: {
        ...params,
        sensorIds: params.sensorIds.join(','),
        day: dayjs(params.day).format('YYYY-MM-DD'),
      },
    },
  )
}

const findAllBySensorId = (sensorId: string) => {
  return httpClient.get<SensorDataRecordType[]>(`sensor/datarecord/${sensorId}`)
}

const findAllDataSensorByAreaAndValueType = async ({
  page = 1,
  columnSort,
  columnName,
  numberPerPage = 10,
  areaId,
  valueType,
}: ParamsType): Promise<NewType> => {
  const { data } = await httpClient.get<PaginationResponseType>(
    `/dashboard-temperature-and-humidity/sensor-record/by-area/${areaId}/value-type/${valueType}`,
    {
      params: {
        page: page - 1,
        columnSort,
        columnName,
        numberPerPage,
      },
    },
  )
  return {
    items: data?.items,
    currentPage: page,
    perPage: data?.perPage,
    total: data?.total,
  }
}

export const SensorDataRecordService = {
  findAllBySensorId,
  findDataInHourlyIntervals,
  findAllDataSensorByAreaAndValueType,
}
