import jwtDecode from 'jwt-decode'
import { create } from 'zustand'

import { ENVIRONMENT } from 'constants/environment'
import { getExpTokenDateHour } from 'helpers/getExpTokenDateHour'
import { httpClient } from 'services/api/httpClient'
import { UserProfileType, UserType } from 'types/user/userType'

type AuthState = {
  loading: boolean
  user: UserType | null
  isAuthenticated: boolean
  token: string | null
  expToken: string | null
  setToken: (token: string) => void
  signOut: () => void
  setUser: (user: UserType) => void
  setLoading: (loading: boolean) => void
  setProfile: (profile: UserProfileType) => void
}

export const useAuthStore = create<AuthState>((set) => ({
  loading: false,
  user: localStorage.getItem(`@${ENVIRONMENT.APP_NAME}:application.auth.token`)
    ? jwtDecode(
        localStorage.getItem(
          `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
        ) ?? '',
      )
    : null,
  isAuthenticated: localStorage.getItem(
    `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
  )
    ? true
    : false,
  token: localStorage.getItem(
    `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
  ),
  expToken: localStorage.getItem(
    `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
  )
    ? getExpTokenDateHour(
        String(
          localStorage.getItem(
            `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
          ),
        ),
      )
    : null,
  setToken: (token: string) => {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

    localStorage.setItem(
      `@${ENVIRONMENT.APP_NAME}:application.auth.token`,
      token,
    )
    localStorage.removeItem(
      `@${ENVIRONMENT.APP_NAME}:application.showMessageSessionExpired`,
    )

    set({
      token,
      isAuthenticated: true,
      user: jwtDecode(token),
      expToken: getExpTokenDateHour(token),
    })
  },
  signOut: () => {
    localStorage.removeItem(`@${ENVIRONMENT.APP_NAME}:application.auth.token`)

    httpClient.defaults.headers.common['Authorization'] = ''

    set({ user: null, token: null, isAuthenticated: false })
  },
  setUser: (user: UserType) => {
    set({ user })
  },
  setLoading: (loading: boolean) => {
    set({ loading })
  },
  setProfile: (profile: UserProfileType) => {
    set((state) => ({
      user: state?.user
        ? {
            ...state.user,
            profile,
          }
        : state.user,
    }))
  },
}))

export const signOut = useAuthStore.getState().signOut
export const setUser = useAuthStore.getState().setUser
export const expToken = useAuthStore.getState().expToken
