export const colorsDashboard = [
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
  {
    start: '#0693FF',
    end: '#3B62FF',
  },
  {
    start: '#4a4af9',
    end: '#7575f5',
  },
  {
    start: '#b58afa',
    end: '#a819d4',
  },
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
]
