/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ActionMeta, OnChangeValue } from 'react-select'
import { toast } from 'react-toastify'

import { Modal } from 'components/Modal'
import { Select } from 'components/Select'
import {
  findNameTypeEquipment,
  typeEquipmentOptions,
} from 'constants/typeEquipmentOptions'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { ActionsFormEquipment } from 'pages/setting/components/ActionsFormEquipment'
import { FormControllerScreen } from 'pages/setting/components/Screen/FormControllerScreen'
import { FormDryboxScreen } from 'pages/setting/components/Screen/FormDryboxScreen'
import { FormGenericScreen } from 'pages/setting/components/Screen/FormGenericScreen'
import { FormPanicButtonScreen } from 'pages/setting/components/Screen/FormPanicButtonScreen'
import { FormWaterTankScreen } from 'pages/setting/components/Screen/FormWaterTankScreen'
import { StepperController } from 'pages/setting/components/StepperController'
import { controlService } from 'services/control.service'
import {
  createEquipment,
  createEquipmentController,
  updateEquipmentController,
} from 'services/equipments/createEquipment'
import { updateEquipment } from 'services/equipments/updateEquipment'
import { sensorService } from 'services/sensor.service'
import {
  CreateControllerType,
  CreateEquipmentType,
} from 'types/equipment/create-equipment'
import { ModalType } from 'types/modal'

import { findNameAlertTypeEquipment } from '../../../core/enums/AlertTypeEquipment'
import { EquipmentTypeEnum } from '../../../core/enums/EquipmentTypeEnum'
import {
  schemaCreateEquipment,
  schemaCreateEquipmentCommands,
  schemaCreateEquipmentConfigurationPartial,
} from '../validations/create-equipment.validation'

interface SensorSelectType {
  value: string
  label: string
  isFixed?: boolean
  waterInlet?: boolean
  waterOutlet?: boolean
  type?: string
  floor?: string
}

interface Props extends ModalType {
  data: any | null
  isView?: boolean
}

const COMMAND = [
  {
    mode: '',
    fan: '',
    temperature: 16,
    command: '',
  },
]

export function FormEquipmentModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const [activeStep, setActiveStep] = useState(0)
  const id = data?.id

  const [valueSensorSelected, setValueSensorSelected] = useState<
    SensorSelectType[]
  >(
    data?.sensorData?.map((item: any) => ({
      label: item?.sensor?.name,
      value: String(item?.sensor?.id?.value ?? item?.sensor?.id) ?? '',
      type: item?.sensor?.type,
      waterInlet: item?.waterInlet || false,
      waterOutlet: item?.waterOutlet || false,
      floor: item?.floor ?? '',
    })) ?? [],
  )

  const { data: sensors } = useQuery(
    ['all-sensors-equipments'],
    () => sensorService.listAllIsNotLiked(),
    {
      refetchOnWindowFocus: true,
    },
  )

  const { data: controller, isLoading } = useQuery(
    ['ge-control-by-id', data?.id?.value ?? data?.id],
    () => controlService.findById(data?.id?.value ?? data?.id),
    {
      refetchOnWindowFocus: true,
      enabled: id !== null && data?.type === EquipmentTypeEnum.CONTROLLER,
    },
  )

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CreateEquipmentType>({
    resolver: yupResolver(schemaCreateEquipment()),
    defaultValues: {
      name: data?.name ?? '',
      code: data?.code ?? '',
      model: data?.model ?? '',
      brand: data?.brand ?? '',
      status: data?.statusData ?? false,
      feeder: data?.feederData ?? false,
      mobile: data?.mobile ?? false,
      gps: data?.gps ?? false,
      type:
        (findNameTypeEquipment(data?.type)
          ?.id as unknown as EquipmentTypeEnum) ?? undefined,
      alertType: findNameAlertTypeEquipment(data?.alertType),
      procedures: data?.procedureID,
      specifications: data?.specifications?.length
        ? data?.specifications.map((item: any) => ({
            id: item?.specification?.id?.value ?? item?.specification?.id,
            key: item?.specification?.key,
            value: item?.specification?.value,
          }))
        : [
            {
              key: '',
              value: '',
            },
          ],
      sensors: data?.sensorData?.map((item: any) => ({
        sensor: item?.sensor?.id?.value ?? item?.sensor?.id,
        waterInlet: item?.waterInlet || false,
        waterOutlet: item?.waterOutlet || false,
        floor: item?.floor ?? '',
      })) ?? [
        {
          sensor: '',
          name: '',
          waterInlet: false,
          waterOutlet: false,
          floor: '',
        },
      ],
      file: null,
      idfile: data?.idFile ?? null,
      cameras: data?.cameras?.length
        ? data?.cameras.map((item: any) => ({
            id: item?.camera?.id?.value ?? item?.camera?.id,
            ip: item?.camera?.ip,
            mac: item?.camera?.mac,
          }))
        : [
            {
              ip: null,
              mac: null,
            },
          ],
    },
  })

  const checkType = (type: EquipmentTypeEnum) => watch('type') === type

  const useFormConfigurationInitial = useForm<CreateControllerType>({
    resolver: yupResolver(schemaCreateEquipmentConfigurationPartial()),
  })

  const useFormConfigurationCommand = useForm<CreateControllerType>({
    resolver: yupResolver(schemaCreateEquipmentCommands()),
  })

  const useFieldSpecification = useFieldArray({
    control,
    name: 'specifications',
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    useFormConfigurationInitial.reset()
    useFormConfigurationCommand.reset()
    queryClient.invalidateQueries('equipments')
    queryClient.invalidateQueries('all-equipments')
    queryClient.invalidateQueries('ge-control-by-id')
    onClose()
  }

  const mutationCreateController = useMutation(
    (newEquipment: CreateControllerType) => {
      return createEquipmentController(newEquipment)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const mutationUpdateController = useMutation(
    (payload: CreateControllerType) => {
      return updateEquipmentController(data?.id?.value ?? data?.id, payload)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const getPayload = (formData: CreateEquipmentType) => {
    if (!checkType(EquipmentTypeEnum.BTN_PANIC)) {
      const { cameras, procedures, ...rest } = formData
      return rest
    }

    return formData
  }

  const mutationCreate = useMutation(
    (newEquipment: CreateEquipmentType) => {
      newEquipment = getPayload(newEquipment) as CreateEquipmentType
      return createEquipment({
        brand: newEquipment.brand,
        code: newEquipment.code,
        model: newEquipment.model,
        status: newEquipment.status,
        name: newEquipment.name,
        feeder: newEquipment.feeder,
        mobile: newEquipment.mobile,
        gps: newEquipment.gps,
        type: newEquipment.type,
        alertType: newEquipment?.alertType,
        procedures: newEquipment?.procedures
          ? [String(newEquipment.procedures)]
          : [],
        specifications:
          newEquipment.specifications?.map((item) => ({
            key: item?.key,
            value: item?.value,
          })) ?? [],
        sensors:
          newEquipment?.sensors?.map((item) => ({
            sensor: item?.sensor ?? item,
            waterInlet: item?.waterInlet || false,
            waterOutlet: item?.waterOutlet || false,
            floor: item?.floor ?? '',
          })) ?? [],
        file: newEquipment.file,
        cameras:
          newEquipment.cameras?.map((item) => ({
            id: item?.id,
            ip: item?.ip,
            mac: item?.mac,
          })) ?? [],
      })
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const mutationUpdate = useMutation(
    (newEquipment: CreateEquipmentType) => {
      newEquipment = getPayload(newEquipment) as CreateEquipmentType
      return updateEquipment(
        data?.id?.value ?? data?.id,
        newEquipment?.file
          ? {
              brand: newEquipment.brand,
              code: newEquipment.code,
              model: newEquipment.model,
              name: newEquipment.name,
              status: newEquipment.status,
              feeder: newEquipment.feeder,
              mobile: newEquipment.mobile,
              gps: newEquipment.gps,
              type: newEquipment.type,
              triggered: false,
              alertType: newEquipment?.alertType,
              procedures: newEquipment?.procedures
                ? [String(newEquipment.procedures)]
                : [],
              specifications:
                newEquipment?.specifications.map((item) => ({
                  id: item?.id,
                  key: item?.key ?? '',
                  value: item?.value ?? 0,
                })) ?? [],
              sensors:
                newEquipment?.sensors?.map((item) => ({
                  sensor: item?.sensor ?? item,
                  waterInlet: item?.waterInlet || false,
                  waterOutlet: item?.waterOutlet || false,
                  floor: item?.floor ?? '',
                })) ?? [],
              file: newEquipment.file,
              idfile: newEquipment.idfile,
              cameras:
                newEquipment?.cameras?.map((item) => ({
                  id: item?.id,
                  ip: item?.ip ?? '',
                  mac: item?.mac ?? '',
                })) ?? [],
            }
          : {
              brand: newEquipment.brand,
              code: newEquipment.code,
              model: newEquipment.model,
              name: newEquipment.name,
              status: newEquipment.status,
              feeder: newEquipment.feeder,
              mobile: newEquipment.mobile,
              gps: newEquipment.gps,
              type: newEquipment.type,
              alertType: newEquipment?.alertType,
              triggered: false,
              procedures: newEquipment?.procedures
                ? [String(newEquipment.procedures)]
                : [],
              specifications: newEquipment?.specifications,
              sensors:
                newEquipment?.sensors?.map((item) => ({
                  sensor: item?.sensor ?? item,
                  waterInlet: item?.waterInlet || false,
                  waterOutlet: item?.waterOutlet || false,
                  floor: item?.floor ?? '',
                })) ?? [],
              cameras:
                newEquipment?.cameras?.map((item) => ({
                  id: item?.id,
                  ip: item?.ip ?? '',
                  mac: item?.mac ?? '',
                })) ?? [],
            },
      )
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const orderOptions = (values: readonly SensorSelectType[]) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed))
  }

  const onChange = (
    newValue: OnChangeValue<SensorSelectType, true>,
    actionMeta: ActionMeta<SensorSelectType>,
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        newValue = valueSensorSelected.filter((v) => v.isFixed)
        break
    }

    setValueSensorSelected(orderOptions(newValue))
    setValue(
      'sensors',
      newValue.map((item) => {
        return {
          name: item.label,
          sensor: item.value,
          waterInlet: false,
          waterOutlet: false,
          floor: '',
        }
      }),
    )
  }

  const onSubmit: SubmitHandler<CreateEquipmentType | CreateControllerType> = (
    formData,
  ) => {
    if (checkType(EquipmentTypeEnum.CONTROLLER)) {
      formData = {
        ...useFormConfigurationInitial.getValues(),
        ...useFormConfigurationCommand.getValues(),
      } as CreateControllerType

      formData = {
        ...formData,
        groupController: [
          ...formData.groupController.map((item) => ({
            ...item,
            airConditioners: item.airConditioners?.map(
              (item: any) => item.value,
            ),
          })),
        ],
      }

      if (data?.id?.value || data?.id) {
        mutationUpdateController.mutate(formData)
        return
      }

      mutationCreateController.mutate(formData)
      return
    }

    if (!data?.id?.value || !data?.id) {
      mutationCreate.mutate(formData as CreateEquipmentType)
      return
    }

    mutationUpdate.mutate(formData as CreateEquipmentType)
  }

  useEffect(() => {
    if (!isLoading) {
      useFormConfigurationInitial.reset({
        name: controller?.name ?? '',
        code: controller?.code ?? '',
        model: controller?.model ?? '',
        brand: controller?.brand ?? '',
        topic: controller?.topic ?? '',
      })

      useFormConfigurationCommand.reset({
        groupController: controller?.groupController
          ? controller?.groupController.map((item) => {
              return {
                commands: item.commands
                  ? item.commands.map((item) => ({
                      id: item?.id?.value,
                      mode: item?.mode,
                      fan: item?.fan,
                      temperature: item?.temperature,
                      command: item?.command,
                    }))
                  : COMMAND,
                airConditioners: item.airConditioners
                  ? item.airConditioners.map((item: any) => ({
                      label: item?.name,
                      value: item.id?.value,
                    }))
                  : [''],
              }
            })
          : [
              {
                commands: COMMAND,
                airConditioners: [],
              },
            ],
      })
    }
  }, [isLoading, data])
  const getTitle = () => {
    if (isView) return t('equipment.view')

    if (data?.id) return t('equipment.edit')

    return t('equipment.new')
  }

  const title = getTitle()

  const handleCancel = () => {
    if (activeStep === 0) onClose()
  }

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const steps = [
    t('equipment.steps.configurationsInitial'),
    t('equipment.steps.configurationsContact'),
  ]

  const steppeAction = [EquipmentTypeEnum.CONTROLLER].includes(watch('type'))

  const getContent = [
    EquipmentTypeEnum.CONTROLLER,
    EquipmentTypeEnum.BTN_PANIC,
    EquipmentTypeEnum.DRYBOX,
    EquipmentTypeEnum.WATER_TANK,
  ].includes(watch('type'))

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      maxWidth={
        checkType(EquipmentTypeEnum.CONTROLLER) && activeStep === 1
          ? 'lg'
          : 'md'
      }
    >
      {checkType(EquipmentTypeEnum.CONTROLLER) && (
        <StepperController activeStep={activeStep} steps={steps} />
      )}
      <Grid
        container
        spacing={2}
        component='form'
        onSubmit={
          !steppeAction
            ? handleSubmit(onSubmit)
            : activeStep === 0
            ? useFormConfigurationInitial.handleSubmit(handleNext)
            : useFormConfigurationCommand.handleSubmit(onSubmit)
        }
        noValidate
      >
        {activeStep === 0 && (
          <Grid item xs={12}>
            <Select
              name='type'
              label={t('input.type') ?? ''}
              options={typeEquipmentOptions}
              control={control}
              onChange={(event: any) => {
                setValue('type', event.target.value)
              }}
              fullWidth
              error={!!errors?.type}
              errorMessage={errors?.type?.message}
              disabled={isView}
              required
            />
          </Grid>
        )}

        {checkType(EquipmentTypeEnum.CONTROLLER) && (
          <FormControllerScreen
            activeStep={activeStep}
            formConfiguration={useFormConfigurationInitial}
            formCommand={useFormConfigurationCommand}
            isView={isView}
            isLoading={isLoading}
          />
        )}

        {checkType(EquipmentTypeEnum.BTN_PANIC) && (
          <FormPanicButtonScreen
            control={control}
            isView={isView}
            errors={errors}
            setValue={setValue}
            useFieldSpecification={useFieldSpecification}
            sensors={sensors}
            selectedSensors={valueSensorSelected}
            onChange={onChange}
            file={
              data?.file && {
                name: data?.file?.name,
                url: data?.file?.url,
              }
            }
          />
        )}

        {checkType(EquipmentTypeEnum.DRYBOX) && (
          <FormDryboxScreen
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            useFieldSpecification={useFieldSpecification}
            sensors={sensors}
            isView={isView}
            file={
              data?.file && {
                name: data?.file?.name,
                url: data?.file?.url,
              }
            }
          />
        )}

        {checkType(EquipmentTypeEnum.WATER_TANK) && (
          <FormWaterTankScreen
            control={control}
            errors={errors}
            setValue={setValue}
            useFieldSpecification={useFieldSpecification}
            getValues={getValues}
            watch={watch}
            isView={isView}
            sensors={sensors}
            file={
              data?.file && {
                name: data?.file?.name,
                url: data?.file?.url,
              }
            }
          />
        )}

        {!getContent && (
          <FormGenericScreen
            control={control}
            errors={errors}
            setValue={setValue}
            useFieldSpecification={useFieldSpecification}
            onChange={onChange}
            selectedSensors={valueSensorSelected}
            sensors={sensors}
            isView={isView}
            file={
              data?.file && {
                name: data?.file?.name,
                url: data?.file?.url,
              }
            }
          />
        )}

        {!isView && !steppeAction ? (
          <Grid item xs={12}>
            <ActionsFormEquipment
              stepAction={steppeAction}
              currentStep={activeStep}
              onCancel={handleCancel}
              onBack={handleBack}
              onNext={handleNext}
              isView={isView}
              isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
            />
          </Grid>
        ) : isView && steppeAction && activeStep === 0 ? (
          <Grid item xs={12}>
            <ActionsFormEquipment
              stepAction={steppeAction}
              currentStep={activeStep}
              onCancel={handleCancel}
              onBack={handleBack}
              onNext={handleNext}
              isView={isView}
              isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ActionsFormEquipment
              stepAction={steppeAction}
              currentStep={activeStep}
              onCancel={handleCancel}
              onBack={handleBack}
              onNext={handleNext}
              isView={isView}
              isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
