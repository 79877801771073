import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { DesktopDateTimePicker, MobileDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Input } from 'components/Input'
import InputBase from 'components/Input/InputBase'
import { InputFile } from 'components/Input/InputFile'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { PhotoExpand } from 'components/Photo/Expand'
import { Select } from 'components/Select'
import { createOrderService } from 'services/order-service/createOrderService'
import { updateOrderService } from 'services/order-service/updateOrderService'
import { ModalType } from 'types/modal'

import { findType, TypeEnum } from '../../../core/enums/TypeEnum'
import { IOrderService } from '../../../core/interfaces/order-service/IOrderService'
import { schemaClientSensor } from '../validations/create-client.validation'

interface Props extends ModalType {
  data: IOrderService | null
  isView?: boolean
}

export function FormClientModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IOrderService>({
    resolver: yupResolver(schemaClientSensor()),
    defaultValues: {
      client: data?.user ?? '',
      nameEquipment: data?.nameEquipment ?? '',
      serialNumber: data?.serialNumber ?? '',
      model: data?.model ?? '',
      type: findType(String(data?.type)) ?? TypeEnum.CORRECTIVE,
      description: data?.description ?? '',
      location: data?.location ?? '',
      pathImage: data?.pathImage ?? '',
      dateOccurrence: data?.dateOccurrence ?? moment().format(),
      file: null,
      idfile: data?.file?.id?.value ?? null,
    },
  })

  const onFinished = () => {
    toast.success(t('savedInformation'))
    queryClient.invalidateQueries('clients')
    queryClient.invalidateQueries('pre-os')
    reset()
    onClose()
  }

  const mutationCreate = useMutation(
    (data: IOrderService) => {
      data.dateOccurrence = new Date(data.dateOccurrence).toISOString()
      // data.client = String(1) // TODO: pegar o Identify do cliente logado
      return createOrderService(data)
    },
    {
      onSuccess: onFinished,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (payload: IOrderService) => {
      payload.dateOccurrence = new Date(payload.dateOccurrence).toISOString()
      // payload.client = String(1) // TODO: pegar o Identify do cliente logado
      return updateOrderService(Number(data?.entityId?.value), payload)
    },
    {
      onSuccess: onFinished,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<IOrderService> = (formData) => {
    if (!data?.entityId?.value) {
      mutationCreate.mutate(formData)
      return
    }
    mutationUpdate.mutate(formData)
  }

  const title = data?.entityId?.value ? t('client.edit') : t('client.new')

  return (
    <Modal
      title={isView ? String(t('client.view')) : title}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid item xs={12}>
          <Input
            name='client'
            label={t('input.requester') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.client}
            errorMessage={errors?.client?.message}
            required
            isDisabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='nameEquipment'
            label={t('input.equipment') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.nameEquipment}
            errorMessage={errors?.nameEquipment?.message}
            required
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='model'
            label={t('input.model').toString()}
            control={control}
            fullWidth
            error={!!errors?.model}
            errorMessage={errors?.model?.message}
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='serialNumber'
            label={t('input.serialNumber') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.serialNumber}
            errorMessage={errors?.serialNumber?.message}
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='location'
            label={t('input.location') ?? ''}
            control={control}
            fullWidth
            required
            error={!!errors?.location}
            errorMessage={errors?.location?.message}
            isDisabled={isView}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {isDesktop ? (
            <DesktopDateTimePicker
              label={t('input.period')}
              views={['day']}
              value={watch('dateOccurrence')}
              onChange={(value) => setValue('dateOccurrence', value ?? '')}
              disabled={isView}
              renderInput={(params) => (
                <InputBase fullWidth {...params} required />
              )}
            />
          ) : (
            <MobileDatePicker
              label={t('input.period')}
              views={['day']}
              value={watch('dateOccurrence') ?? null}
              onChange={(value) => setValue('dateOccurrence', value ?? '')}
              disabled={isView}
              renderInput={(params) => (
                <InputBase fullWidth {...params} required />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name='type'
            label={t('input.type')}
            options={[
              {
                id: 'PREVENTIVE',
                name: t('orderService.type.PREVENTIVE').toString(),
              },
              {
                id: 'CORRECTIVE',
                name: t('orderService.type.CORRECTIVE').toString(),
              },
            ]}
            control={control}
            fullWidth
            error={!!errors?.type}
            errorMessage={errors?.type?.message}
            required
            disabled={isView}
          />
        </Grid>
        {!isView && (
          <Grid item xs={12} sm={6}>
            <InputFile
              defaultValue={data?.file?.name}
              onChange={(file) => {
                setValue('file', file)
              }}
              onDelete={() => {
                setValue('file', null)
              }}
            />
          </Grid>
        )}
        {isView && !!data?.file?.url?.length && (
          <Grid item xs={12} sm={6}>
            <PhotoExpand url={data?.file.url} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Input
            name='description'
            label={t('input.description') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.description}
            errorMessage={errors?.description?.message}
            required
            multiline
            minRows={3}
            isDisabled={isView}
          />
        </Grid>

        {!isView && (
          <Grid item xs={12}>
            <ModalAction
              onCancel={onClose}
              isSubmit
              isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
