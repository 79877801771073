import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { create } from 'zustand'

type State = {
  visibleSensorTypes: SensorTypeEnum[]
  typesVisibleEquipment: EquipmentTypeEnum[]
  setVisibleSensorTypes(values: SensorTypeEnum[]): void
  setTypesVisibleEquipment(values: EquipmentTypeEnum[]): void
}

export const useSidebarMonitoringStore = create<State>((set) => ({
  typesVisibleEquipment: [],
  visibleSensorTypes: [],
  setVisibleSensorTypes: (values) => {
    set({
      visibleSensorTypes: values,
    })
  },
  setTypesVisibleEquipment: (values) => {
    set({
      typesVisibleEquipment: values,
    })
  },
}))
