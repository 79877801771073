import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Toggle } from 'components/Toggle'
import { createProfile } from 'services/profiles/createProfiles'
import { updateProfile } from 'services/profiles/updateProfile'
import { FormProfileType } from 'types/profile/create-profile'

import { CustomCheckbox } from './components/CustomCheckbox'
import { PermissionGroup } from './components/PermissionGroup'
import { defaultPermissions } from './config/defaultPermissions'
import { PermissionType } from './types/permission.type'
import { extractCreatePermissions } from './utils/extractCreatePermissions'
import { toggleCheckbox } from './utils/toggleCheckbox'
import { schemaProfile } from './validations/create-profile.validation'

type StateType = {
  isView?: boolean
  profile: {
    name: string
    active: boolean
    linkedUsers?: any[]
    creation: string
    id: { value: string }
    permissions: Array<{
      permissionType: string
      id: { value: string }
      feature: {
        id: { value: string }
        name: string
      }
    }>
  }
}

export function ProfileFormPage() {
  const location = useLocation()
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { profile, isView } = (location?.state as StateType) ?? {}

  const [checkboxData, setCheckboxData] =
    useState<PermissionType[]>(defaultPermissions)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (profile) {
      setValue('name', profile.name)
      setValue('active', profile.active)

      const updatedCheckboxData = updateCheckboxes(
        defaultPermissions,
        profile.permissions,
      )

      setCheckboxData(updatedCheckboxData)
      setLoading(false)
    } else {
      const updatedCheckboxData = onChangeCheckboxes(defaultPermissions)

      setCheckboxData(updatedCheckboxData)
      setLoading(false)
    }

    return () => {
      setCheckboxData([])
    }
  }, [profile, profile?.permissions?.length])

  const updateCheckboxes = (
    checkboxes: PermissionType[],
    permissions: Array<{
      permissionType: string
      feature: {
        name: string
      }
    }>,
    parentCheckbox?: PermissionType,
  ): PermissionType[] => {
    return checkboxes.map((checkbox) => {
      checkbox.checked = false

      const matchingPermission = permissions.find(
        (permission) =>
          permission.feature.name === checkbox.module &&
          permission.permissionType === checkbox.type,
      )

      if (matchingPermission) {
        checkbox.checked = true
        checkParentCheckboxes(checkbox, checkboxData, parentCheckbox)
      }

      if (checkbox.children) {
        const parentCheckbox = checkbox
        checkbox.children = updateCheckboxes(
          checkbox.children,
          permissions,
          parentCheckbox,
        )
      }

      return checkbox
    })
  }

  const onChangeCheckboxes = (
    checkboxes: PermissionType[],
  ): PermissionType[] => {
    return checkboxes.map((checkbox) => {
      checkbox.checked = false

      if (checkbox.children) {
        checkbox.children = onChangeCheckboxes(checkbox.children)
      }

      return checkbox
    })
  }

  const checkParentCheckboxes = (
    checkbox: PermissionType,
    allCheckboxes: PermissionType[],
    currentParentCheckbox?: PermissionType,
  ) => {
    if (currentParentCheckbox?.parentId) {
      const parentCheckbox = allCheckboxes.find(
        (cb) => cb.id === currentParentCheckbox?.parentId,
      )
      if (parentCheckbox) {
        parentCheckbox.checked = true
        checkParentCheckboxes(checkbox, parentCheckbox.children ?? [])
      }
    } else {
      const parentCheckbox = allCheckboxes.find(
        (cb) => cb.id === checkbox.parentId,
      )

      if (parentCheckbox) {
        parentCheckbox.checked = true
        checkParentCheckboxes(parentCheckbox, allCheckboxes)
      }
    }
  }

  // const isView = false

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormProfileType>({
    resolver: yupResolver(schemaProfile()),
    defaultValues: {
      name: '',
      active: true,
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('profiles')
    queryClient.invalidateQueries('list-profiles')
    navigate('/access-control/profiles', {
      replace: true,
    })
  }

  const mutationCreate = useMutation(
    (newProfile: FormProfileType) => {
      return createProfile(newProfile)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: FormProfileType) => {
      return updateProfile(Number(profile?.id?.value), update)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const arrangeValuesCreate = (
    formData: FormProfileType,
    checkboxPermissionData: PermissionType[],
  ) => {
    const createPermission = extractCreatePermissions(checkboxPermissionData)

    return {
      name: formData.name,
      active: formData.active,
      permissions: createPermission,
    }
  }

  const onSubmit: SubmitHandler<FormProfileType> = (formData) => {
    if (!profile?.id?.value) {
      mutationCreate.mutate(arrangeValuesCreate(formData, checkboxData))
      return
    }

    mutationUpdate.mutate(arrangeValuesCreate(formData, checkboxData))
  }

  const handleCancel = () =>
    navigate('/access-control/profiles', {
      replace: true,
    })

  // Handle checkbox change
  const handleCheckboxChange = (checkboxId: string, isChecked: boolean) => {
    const updatedCheckboxes = toggleCheckbox(
      checkboxData,
      checkboxId,
      isChecked,
    )
    setCheckboxData(updatedCheckboxes)
  }

  // Render checkboxes
  const renderCheckboxes = (
    checkboxes: PermissionType[],
    onCheckboxChange: (checkboxId: string, isChecked: boolean) => void,
  ) => {
    return checkboxes.map((checkbox) => (
      <div key={checkbox.id}>
        {checkbox.children ? (
          <PermissionGroup
            permission={checkbox}
            onCheckboxChange={onCheckboxChange}
            disabled={isView}
          >
            {renderCheckboxes(checkbox.children, onCheckboxChange)}
          </PermissionGroup>
        ) : (
          <CustomCheckbox
            item={checkbox}
            onCheckboxChange={onCheckboxChange}
            disabled={isView}
          />
        )}
      </div>
    ))
  }

  return (
    <>
      <Box
        flex={1}
        display={'grid'}
        gridTemplateColumns={['1fr', '1fr', '300px 1fr']}
        gap={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>{t('nameTheProfile')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                name='name'
                label={t('input.name') ?? ''}
                control={control}
                fullWidth
                error={!!errors?.name}
                errorMessage={errors?.name?.message}
                disabled={isView}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Toggle name='active' disabled={isView} control={control} />
            </Grid>

            {!isView && (
              <Grid item xs={12}>
                <Stack
                  spacing={2}
                  direction={'row'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  <Button variant='outlined' onClick={handleCancel}>
                    {t('button.title.cancel')}
                  </Button>
                  <Button type='submit' variant='contained'>
                    {t('button.title.save')}
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            borderLeft: ['none', 'none', '1px solid #26264F'],
            paddingLeft: [0, 0, 3],
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: [
                '1fr',
                '1fr 1fr',
                '1fr 1fr',
                'repeat(3, minmax(0, 1fr))',
              ],
              gap: 3,
            }}
          >
            {!loading && renderCheckboxes(checkboxData, handleCheckboxChange)}
          </Box>
        </Stack>
      </Box>
    </>
  )
}
