import { IconButton } from 'components/Button/IconButton'

export function ParametrosContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros dos parâmetros das
        utilidades de fábrica ar-condicionado, temperatura e umidade. Para
        realizar o cadastro de parâmetros, é necessário cadastrar inicialmente
        as áreas. Os dados desse submódulo serão utilizados no Dashboard e para
        o controle remoto dos ar-condicionados, então sugerimos que sempre
        verifique se os cadastros dos parâmetros já foram realizados.
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>
        <strong>o Cadastrar de Parâmetro</strong>
      </p>

      <p>Para realizar o cadastro de um novo parâmetro, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Parâmetro”
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione o tipo a utilidade entre
        ar-condicionado, temperatura e umidade.
      </p>
      <p>
        Ao selecionar a utilidade os campos mínimo e máximo terão uma
        visualização de informações diferentes.
      </p>
      <p>
        <strong>Passo 7:</strong> Preencha os campos: utilidades, status de
        ativo ou inativo, setor, área, valor mínimo e máximo.
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>Importante: </strong> Esses dados afetam nos valores do
        dashboard e monitoramento de sensores. Para realizar o cadastro de
        parâmetros, é importante já ter uma área cadastrada (Mapa, submódulo de
        áreas)
      </p>
      <p>
        <strong>o Editar Parâmetro</strong>
      </p>
      <p>Para realizar a edição de um parâmetro, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Parâmetro”
      </p>
      <p>
        <strong>Passo 5:</strong> Selecionar o parâmetro que vai editar dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 6:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 7:</strong> Edite os campos: utilidades, status de ativo
        ou inativo, setor, área, valor mínimo e máximo.
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>o Excluir Parâmetro</strong>
      </p>

      <p>Para realizar a exclusão de um parâmetro, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o parâmetro que vai excluir dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-lo, selecione sim e o parâmetro será permanentemente excluído do
        sistema.
      </p>
      <p>
        <strong>o Visualizar Parâmetro</strong>
      </p>
      <p>Para realizar visualizar os dados de um parâmetro, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o parâmetro que vai visualizar
        dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, sendo eles: utilidades, status de ativo ou inativo, setor,
        área, valor mínimo e máximo.
      </p>
    </>
  )
}
