import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Toggle } from 'components/Toggle'
import { notificationSettingService } from 'services/notification/notification-setting.service'
import { NotificationSettingType } from 'types/notification-setting'

import { findModule } from '../../../core/enums/Module'

export function notificationsMapper(notifications: NotificationSettingType[]) {
  const queryClient = useQueryClient()

  const handleToggleStatus = async (id: string) => {
    try {
      await notificationSettingService.changeStatus(id)
      toast.success('Status atualizado com sucesso!')
      queryClient.invalidateQueries('notifications')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }
  return notifications.map((item) => ({
    ...item,
    typeNotificationID:
      item?.typesNotification?.map((type) => type?.value).join(', ') ?? '-',
    notificationActionID:
      item?.notificationAction?.map((type) => type?.value).join(', ') ?? '-',
    name: item?.name ?? '',
    typeNameMapper: item?.type,
    moduleMapper:
      item?.modules?.map((module) => findModule(module))?.join(', ') ?? '-',
    usersMapper: item?.users?.map((user) => user.name)?.join(', ') ?? '-',
    status: (
      <Toggle
        name='toggle'
        checked={item.status}
        onChange={() => {
          handleToggleStatus(item?.id?.value ?? item?.id)
        }}
      />
    ),
  }))
}
