import 'dayjs/locale/pt-br'
import { useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { DesktopDatePicker, MobileDatePicker, ptBR } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import dayjs, { Dayjs } from 'dayjs'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

dayjs.extend(isBetweenPlugin)

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>

interface Props {
  defaultDate?: Date | string
  onChange?: (date?: string) => void
  fullWidth?: boolean
}

export function DatePickerWeek({
  defaultDate,
  onChange,
  fullWidth = true,
}: Props) {
  const { i18n, t } = useTranslation('common')
  dayjs.locale(i18n.language === 'pt' ? 'pt-br' : i18n.language)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [value, setValue] = useState<Dayjs | null>(dayjs(defaultDate))

  useEffect(() => {
    setValue(dayjs(defaultDate))
  }, [defaultDate])

  const renderWeekPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>,
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />
    }

    const start = value.startOf('week')
    const end = value.endOf('week')

    const dayIsBetween = date.isBetween(start, end, null, '[]')
    const isFirstDay = date.isSame(start, 'day')
    const isLastDay = date.isSame(end, 'day')

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
      localeText={
        ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      {isDesktop ? (
        <DesktopDatePicker
          label={t('input.period')}
          value={value}
          onChange={(newValue) => {
            setValue(newValue)
            onChange?.(newValue?.format())
          }}
          renderDay={renderWeekPickerDay}
          renderInput={(params) => (
            <TextField fullWidth={fullWidth} {...params} />
          )}
          inputFormat={`[${value
            ?.startOf('week')
            ?.format('DD/MM/YYYY')} - ${value
            ?.endOf('week')
            ?.format('DD/MM/YYYY')}]`}
          disableMaskedInput
        />
      ) : (
        <MobileDatePicker
          label={t('input.period')}
          value={value}
          onChange={(newValue) => {
            setValue(newValue)
            onChange?.(newValue?.format())
          }}
          renderDay={renderWeekPickerDay}
          renderInput={(params) => (
            <TextField fullWidth={fullWidth} {...params} />
          )}
          inputFormat={`[${value
            ?.startOf('week')
            ?.format('DD/MM/YYYY')} - ${value
            ?.endOf('week')
            ?.format('DD/MM/YYYY')}]`}
          disableMaskedInput
        />
      )}
    </LocalizationProvider>
  )
}
