import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { Table, TableActionType } from 'components/Table'
import { TabPanel, TabPanelProps } from 'components/Tabs/TabPanel'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { parameterMapper } from 'pages/setting/mappers/parameter.mapper'
import { listParameter } from 'services/goalAndParameter/parameter/listParameter'
import { ParameterType } from 'types/goalAndParameter/parameter'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { FormParameterModal } from '../FormParameterModal'

type DataType = ParameterType

type Props = Omit<TabPanelProps, 'children'> & {
  search?: string
  openForm: boolean
  onCloseForm: () => void
}

const PER_PAGE = 10

export function Parameter(props: Props) {
  const { search, openForm, onCloseForm, ...rest } = props
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openFormEdit, setOpenFormEdit] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<DataType | null>(null)

  const [page, setPage] = useState(1)

  const [columnSort, setColumnSort] = useState('unit')
  const [columnDirection, setColumnDirection] = useState<'ASC' | 'DESC'>('ASC')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<DataType>,
    Error
  >({
    queryKey: ['parameter', page, search, columnSort, columnDirection],
    queryFn: () =>
      listParameter({
        page,
        search,
        numberPerPage: PER_PAGE,
        columnSort: columnDirection,
        columnName: columnSort,
      }),
    cacheTime: 0,
    staleTime: 0,
  })

  const parameter = parameterMapper(data?.items ?? [])

  const handleCancel = () => {
    onCloseForm()
    setOpenFormView(false)
    setSelected(null)
    setOpenFormEdit(false)
  }

  const handleEdit = (item: DataType) => {
    setSelected(item)
    setOpenFormEdit(true)
  }

  const handleView = (item: DataType) => {
    setSelected(item)
    setOpenFormView(true)
  }

  const callback = () => {
    toast.success(t('savedInformation'))
    refetch()
  }

  const mutationDelete = useMutation((id: number) =>
    httpDelete(`/parameter/${id}`, callback),
  )

  const handleDelete = (item: DataType) => {
    toast.promise(mutationDelete.mutateAsync(Number(item.id)), {
      pending: t('deleting'),
    })
  }

  const columns: ColumnType[] = [
    {
      id: 'unitName',
      label: t('table.goalAndParameter.parameter.utility'),
      sort: 'unit',
    },
    {
      id: 'company',
      label: t('table.goalAndParameter.parameter.company'),
      sort: 'area',
    },
    {
      id: 'formattedArea',
      label: t('table.goalAndParameter.parameter.area'),
      sort: 'area',
    },
    {
      id: 'subarea',
      label: t('table.goalAndParameter.parameter.subarea'),
      sort: 'area',
    },
    {
      id: 'sensor',
      label: t('table.goalAndParameter.parameter.sensor'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
      sort: 'status',
    },
  ]

  const tableActions: TableActionType<DataType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_PARAMETER,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_PARAMETER,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_PARAMETER,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          title: t('titleErrorBase').toString(),
          text: t('textErrorBase').toString(),
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <TabPanel {...rest}>
      <Table<DataType>
        columns={columns}
        rows={parameter}
        orderBy={columnSort}
        onOrderBy={(newOrderBy) => {
          if (newOrderBy === 'unitName') {
            newOrderBy = 'unit'
            setColumnSort(newOrderBy)
            return
          }
          if (newOrderBy === 'company') {
            newOrderBy = 'area'
            setColumnSort(newOrderBy)
            return
          }
          if (newOrderBy === 'formattedArea') {
            newOrderBy = 'area'
            setColumnSort(newOrderBy)
            return
          }
          if (newOrderBy === 'subarea') {
            newOrderBy = 'area'
            setColumnSort(newOrderBy)
            return
          }
          if (newOrderBy === 'muiStatus') {
            newOrderBy = 'status'
            setColumnSort(newOrderBy)
            return
          }
          setColumnSort(newOrderBy)
        }}
        onToggleOrder={() =>
          setColumnDirection(columnDirection === 'ASC' ? 'DESC' : 'ASC')
        }
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page: page,
          limit: data?.perPage,
          count: data?.total,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView || openFormEdit) && (
        <FormParameterModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView || openFormEdit}
          onClose={handleCancel}
        />
      )}
    </TabPanel>
  )
}
