/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { DesktopDateTimePicker } from '@mui/x-date-pickers'
import { PriorityEnum } from 'core/enums/PriorityEnum'
import { TypeEnum } from 'core/enums/TypeEnum'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ActionMeta, OnChangeValue } from 'react-select'
import { toast } from 'react-toastify'

import { Input } from 'components/Input'
import InputBase from 'components/Input/InputBase'
import { InputFile } from 'components/Input/InputFile'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { Autocomplete } from 'components/Select/Autocomplete'
import { httpClient } from 'services/api/httpClient'
import { createOrderService } from 'services/order-service/createOrderService'
import { updateOrderService } from 'services/order-service/updateOrderService'
import { ModalType } from 'types/modal'
import { UserSelectType, UsersType } from 'types/user/user'

import { schemaClientSensor } from '../validations/create-client.validation'

interface Props extends ModalType {
  data: any | null
  isView?: boolean
  onSuccess: () => void
}

export function FormOSModal({
  data,
  isView: view,
  onClose,
  open,
  onSuccess,
}: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const isView = view || data?.status?.includes('Editado')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const isEdit = data?.id ? true : false

  const [selectedUsers, setSelectedUsers] = useState<UserSelectType[]>([])

  const { isLoading: isLoadingUsers, data: dataUser } = useQuery(
    ['all-users'],
    () => {
      return httpClient.get<UsersType[]>(
        '/user-account/find-all-except-clients',
      )
    },
  )

  const users =
    dataUser?.data?.map((item) => ({
      value: item?.entityId?.value?.toString() ?? '',
      label: item?.name ?? '',
    })) ?? []

  const statusOS = data.status ?? 'NEW'

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schemaClientSensor(statusOS)),
    defaultValues: {
      name: data?.name ?? '',
      nameEquipment: data?.nameEquipment ?? '',
      description: data?.description ?? '',
      serialNumber: data?.serialNumber ?? '',
      type: data?.type ?? 'CORRECTIVE',
      model: data?.model ?? '',
      priority: data?.priority ?? 'LOW',
      location: data?.location ?? '',
      dateOccurrence: data?.dateOccurrence ?? null,
      file: null,
      idfile: data?.file?.id?.value ?? null,
      attendants:
        data?.attendants?.length > 0
          ? data?.attendants?.map((item: any) => ({
              value: item?.id?.value?.toString() ?? '',
              label: item?.name ?? '',
            }))
          : [],
    },
  })

  const mutationUpdate = useMutation(
    (dataOS: any) => {
      if (data?.id)
        return updateOrderService(data?.id ?? 0, {
          ...dataOS,
          dateOccurrence: new Date(dataOS?.dateOccurrence).toISOString(),
          attendants: dataOS?.attendants?.map((item: any) => item.value),
        })
      else
        return createOrderService({
          ...dataOS,
          attendants: dataOS?.attendants?.map((item: any) => item.value),
        })
    },
    {
      onSuccess: () => {
        toast.success(t('alert.success'))
        onSuccess()
      },
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<any> = (formData) => {
    mutationUpdate.mutate(formData)
  }

  const orderOptions = (values: readonly UserSelectType[]) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed))
  }

  const onChange = (
    newValue: OnChangeValue<UserSelectType, true>,
    actionMeta: ActionMeta<UserSelectType>,
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        newValue = []
        break
    }

    setSelectedUsers(orderOptions(newValue))
  }

  const title = data?.id ? t('oS.edit') : t('oS.new')
  return (
    <Modal
      title={isView ? String(t('oS.view')) : title}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid item xs={12}>
          <Input
            name='requester'
            label={t('input.requester') ?? ''}
            value={data?.requester}
            fullWidth
            disabled
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name='name'
            label={t('input.nameOS') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.name}
            errorMessage={errors?.name?.message}
            required
            disabled={data?.id}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='nameEquipment'
            label={t('input.equipment') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.nameEquipment}
            errorMessage={errors?.nameEquipment?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='model'
            label={t('input.model') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.model}
            errorMessage={errors?.model?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='serialNumber'
            label={t('input.serialNumber') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.serialNumber}
            errorMessage={errors?.serialNumber?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name='type'
            label={t('input.type')}
            options={[
              {
                id: TypeEnum.PREVENTIVE,
                name: 'Preventiva',
              },
              {
                id: TypeEnum.CORRECTIVE,
                name: 'Corretiva',
              },
            ]}
            control={control}
            fullWidth
            error={!!errors?.type}
            errorMessage={errors?.type?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name='location'
            label={t('input.location') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.location}
            errorMessage={errors?.location?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name='priority'
            label={t('input.priority')}
            options={[
              {
                id: PriorityEnum.LOW,
                name: 'Baixa prioridade',
              },
              {
                id: PriorityEnum.HIGH,
                name: 'Alta prioridade',
              },
            ]}
            control={control}
            fullWidth
            error={!!errors?.type}
            errorMessage={errors?.type?.message}
            required
          />
        </Grid>
        {isEdit && (
          <Grid item xs={12} sm={6}>
            <DesktopDateTimePicker
              label={t('input.dateOccurrence')}
              views={['day']}
              value={data?.dateOccurrence}
              onChange={() => {
                //
              }}
              renderInput={(params) => (
                <InputBase fullWidth {...params} required disabled={data?.id} />
              )}
            />
          </Grid>
        )}
        {!isEdit && (
          <Grid item xs={12} sm={6}>
            <DesktopDateTimePicker
              label={t('input.dateOccurrence')}
              views={['day']}
              value={watch('dateOccurrence')}
              onChange={(value) => setValue('dateOccurrence', value)}
              renderInput={(params) => (
                <InputBase fullWidth {...params} required />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <InputFile
            onChange={(file) => {
              setValue('file', file)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            name='attendants'
            placeholder={t('input.select') ?? ''}
            label={t('input.associate') ?? ''}
            closeMenuOnSelect={false}
            isLoading={isLoadingUsers}
            options={users}
            isMulti
            isDisabled={isView}
            value={watch('attendants')}
            isClearable
            onChange={(newValue) => {
              clearErrors('attendants')
              setValue(
                'attendants',
                (newValue as Array<{ value: string }>) ?? [],
              )
            }}
            error={!!errors?.attendants}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name='description'
            label={t('input.description') ?? ''}
            control={control}
            fullWidth
            error={!!errors?.description}
            errorMessage={errors?.description?.message}
            required
            multiline
            minRows={3}
            maxRows={6}
            disabled={data?.id}
          />
        </Grid>
        {!isView && (
          <Grid item xs={12}>
            <ModalAction onCancel={onClose} isSubmit />
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}
