import { Stack, useTheme } from '@mui/material'
import { ActiveVariableEnum } from 'core/enums/ActiveVariableEnum'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { FC, useEffect } from 'react'

import { IconButton } from 'components/Button/IconButton'
import { Select } from 'components/Select'
import { useMonitoringSidebar } from 'hooks/map/useMonitoringSidebar'
import { useSidebarMonitoringStore } from 'store/monitoring/sidebar-monitoring.store'
import { SelectOptionsType } from 'types/components/select-options'

import { EquipmentCard } from './EquipmentCard'
import { SensorCard } from './SensorCard'
import { SidebarMonitoringWrapper } from './styles'
import { TemperatureHumidityCard } from './TemperatureHumidityCard'
import { VariableCard } from './VariableCard'
import { useSearchParams } from 'react-router-dom'

type Props = {
  companies: SelectOptionsType[]
  companyId: string
  changeCompanyId: (value: string) => void
  sidebarVisibility: boolean
  toggleSidebarVisibility(): void
}

export const MonitoringSidebar: FC<Props> = ({
  companies,
  companyId,
  changeCompanyId,
  sidebarVisibility,
  toggleSidebarVisibility,
}) => {
  const theme = useTheme()
  const { variable } = useMonitoringSidebar()
  const { setTypesVisibleEquipment } = useSidebarMonitoringStore()
  const searchParamsRes = useSearchParams()
  const setSearchParams = searchParamsRes[1]

  const IconSidebarButton = sidebarVisibility ? 'chevronLeft' : 'chevronRight'

  useEffect(() => {
    const variableIsEquipment =
      variable.activeVariable === ActiveVariableEnum.EQUIPMENT
    setTypesVisibleEquipment(
      variableIsEquipment ? [] : [EquipmentTypeEnum.AIR_CONDITIONING],
    )
  }, [variable.activeVariable])

  return (
    <SidebarMonitoringWrapper
      isVisible={sidebarVisibility}
      bgColor={theme.palette.background.default}
      borderColor={theme.palette.secondary.light}
    >
      <Stack overflow={'auto'} maxHeight={'100%'} paddingTop={'6px'}>
        <Select
          sx={{ marginBottom: '10px' }}
          name='unity'
          value={companyId}
          onChange={({ target }) => {
            setSearchParams({ mapId: '' })
            changeCompanyId(target.value as string)
          }}
          label='Unidade'
          options={companies}
        />

        <Stack gap={'10px'}>
          <VariableCard {...variable} />
          {variable.checkActiveVariable(ActiveVariableEnum.EQUIPMENT) && (
            <EquipmentCard />
          )}
          <SensorCard />
          <TemperatureHumidityCard />
        </Stack>
      </Stack>

      <IconButton
        variant={'contained'}
        size='small'
        iconName={IconSidebarButton}
        aria-haspopup='true'
        onClick={toggleSidebarVisibility}
        sx={{
          position: 'absolute',
          right: '-1rem',
          bottom: '2.5rem',
        }}
      />
    </SidebarMonitoringWrapper>
  )
}
