import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

export const dataSensor = [
  {
    title: 'Total de sensores',
    value: 300,
  },
  {
    title: 'Sensores ligados',
    value: 245,
  },
  {
    title: 'Sensores desligados',
    value: 55,
  },
  {
    title: 'Sensores alarmados',
    value: 55,
  },
]
export const dataSensorCompany = [
  {
    title: 'Total de sensores',
    value: 300,
  },
  {
    title: 'Sensores ligados',
    value: 245,
  },
  {
    title: 'Sensores desligados',
    value: 55,
  },
]

export const dataFactory = {
  title: 'Fábrica de Maceió',
  address: 'Avenida Jhonatan, Nº 123, Bairro da inovação Manaus, AM',
  zipCode: '69345-678',
}

export const dataSensorUnit = [
  {
    type: SensorTypeEnum.WATER,
    value: 15,
  },
  {
    type: SensorTypeEnum.TEMP,
    value: 15,
  },
  {
    type: SensorTypeEnum.ELECTRIC,
    value: 25,
  },
  {
    type: SensorTypeEnum.TEMP_HUM,
    value: 15,
  },
  {
    type: SensorTypeEnum.AIR,
    value: 15,
  },
  {
    type: SensorTypeEnum.HUM,
    value: 15,
  },
  {
    type: SensorTypeEnum.BTN_PANIC,
    value: 8,
  },
  {
    type: SensorTypeEnum.OPENING,
    value: 22,
  },
]
