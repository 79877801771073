import styled from '@emotion/styled'

export const accordionStyle = {
  '& div.MuiAccordionSummary-content': {
    order: 1,
    margin: 0,
    paddingLeft: '0 !important',
  },
  '& > div:first-of-type': {
    padding: '1rem',
  },
  '& > div': {
    margin: 0,
    background: '#141432 !important',
    '&.Mui-expanded': {
      paddingBottom: 0,
      '& ~ div': {
        padding: '1rem',
        paddingTop: '2px',
      },
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
    '&.Mui-expanded': {
      transform: 'rotate(-90deg) !important',
    },
  },
}

export const HourButton = styled.div<{
  iSProgrammingAllHours?: boolean
}>(
  {
    padding: 9,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'max-content',
    transition: '0.4s',
    borderRadius: 6,
    background: '#26264f',
    '&:hover': {
      boxShadow: '0 0 0 2px #66009d',
    },
  },
  ({ iSProgrammingAllHours }) => ({
    ...(iSProgrammingAllHours && {
      background: '#66009d',
      boxShadow: '0 0 0 2px #66009d',
      '&:hover': {
        background: '#220943',
      },
    }),
  }),
)
