import { Grid, Stack, Typography } from '@mui/material'
import {
  Control,
  FieldErrors,
  UseFieldArrayReturn,
  UseFormSetValue,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActionMeta, OnChangeValue } from 'react-select'

import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'
import { InputFile } from 'components/Input/InputFile'
import { PhotoExpand } from 'components/Photo/Expand'
import { Autocomplete } from 'components/Select/Autocomplete'
import { Toggle } from 'components/Toggle'
import { CreateEquipmentType } from 'types/equipment/create-equipment'
import { ID } from 'types/id'

interface SensorSelectType {
  value: string
  label: string
  isFixed?: boolean
  waterInlet?: boolean
  waterOutlet?: boolean
  type?: string
  floor?: string
}

interface Props {
  control: Control<CreateEquipmentType>
  isView?: boolean
  errors: FieldErrors<CreateEquipmentType>
  setValue: UseFormSetValue<CreateEquipmentType>
  useFieldSpecification: UseFieldArrayReturn<
    CreateEquipmentType,
    'specifications'
  >
  sensors?: { id: ID; name: string; type: string }[]
  onChange: (
    newValue: OnChangeValue<SensorSelectType, true>,
    actionMeta: ActionMeta<SensorSelectType>,
  ) => void
  selectedSensors: SensorSelectType[]
  file?: {
    name: string
    url: string
  }
}

export function FormGenericScreen({
  control,
  isView,
  errors,
  setValue,
  useFieldSpecification,
  sensors,
  onChange,
  selectedSensors,
  file,
}: Props) {
  const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='flex-start'
          spacing={2}
          justifyContent='left'
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper + '99',
            padding: '8px',
            borderRadius: '6px',
          }}
        >
          <Stack>
            <Toggle
              name='feeder'
              control={control}
              label={t('input.feeder')}
              disabled={isView}
            />
          </Stack>
          <Stack>
            <Typography
              variant='body2'
              color='textSecondary'
              sx={{ alignSelf: 'center' }}
            >
              {t('feederHelp')}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Input
          name='name'
          label={t('input.name')}
          control={control}
          fullWidth
          error={!!errors?.name}
          errorMessage={errors?.name?.message}
          isDisabled={isView}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='code'
          label={t('input.code')}
          control={control}
          fullWidth
          error={!!errors?.code}
          errorMessage={errors?.code?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='model'
          label={t('input.model') ?? ''}
          control={control}
          fullWidth
          error={!!errors?.model}
          errorMessage={errors?.model?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='brand'
          label={t('input.brand') ?? ''}
          control={control}
          fullWidth
          error={!!errors?.brand}
          errorMessage={errors?.brand?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      {!isView && (
        <Grid item xs={12} sm={6}>
          <InputFile
            defaultValue={file?.name}
            onChange={(file) => {
              setValue('file', file)
            }}
            onDelete={() => {
              setValue('file', null)
            }}
          />
        </Grid>
      )}
      {isView && !!file?.url && (
        <Grid item xs={12} sm={6}>
          <PhotoExpand url={file?.url} />
        </Grid>
      )}
      <Stack
        spacing={2}
        sx={{
          marginLeft: '16px',
          marginTop: '16px',
          width: '100%',
          padding: '8px',
          backgroundColor: (theme) => theme.palette.background.paper + '99',
        }}
      >
        <Grid item xs={12}>
          <Typography variant='caption'>
            {t('equipment.specification')}
          </Typography>
        </Grid>
        {useFieldSpecification.fields?.map((field, index) => (
          <Grid item xs={12} key={index}>
            <Stack direction='row' alignItems='flex-start' spacing={3}>
              <Input
                name={`specifications.[${index}].key`}
                label={t('input.name') ?? ''}
                control={control}
                fullWidth
                isDisabled={isView}
                error={!!errors?.specifications?.[index]?.key}
                errorMessage={errors?.specifications?.[index]?.key?.message}
                required
              />
              <Input
                name={`specifications.[${index}].value`}
                label={t('input.value') ?? ''}
                control={control}
                fullWidth
                isDisabled={isView}
                error={!!errors?.specifications?.[index]?.value}
                errorMessage={errors?.specifications?.[index]?.value?.message}
                required
              />

              {index === 0 && !isView ? (
                <IconButton
                  variant='contained'
                  color={'secondary'}
                  iconName='add'
                  title={t('button.title.add') ?? ''}
                  onClick={() =>
                    useFieldSpecification.append({
                      key: '',
                      value: undefined,
                    })
                  }
                />
              ) : (
                <IconButton
                  variant='contained'
                  iconName='delete'
                  color={'error'}
                  title={t('button.title.delete') ?? ''}
                  disabled={isView}
                  onClick={() => useFieldSpecification.remove(index)}
                />
              )}
            </Stack>
          </Grid>
        ))}
      </Stack>
      <Grid item xs={12}>
        <Typography variant='body2'>{t('equipment.linkSensor')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          name='sensors'
          placeholder={t('input.selectSensors')}
          options={
            sensors?.map((sensor) => ({
              label: sensor.name,
              value: String(sensor.id),
              type: sensor.type,
            })) ?? []
          }
          isMulti
          value={selectedSensors}
          isClearable={selectedSensors.some((v) => !v.isFixed)}
          isDisabled={isView}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(newValue: any, actionMeta: any) => {
            onChange(newValue, actionMeta)
          }}
        />
      </Grid>
    </>
  )
}
