/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Toggle } from 'components/Toggle'
import { updateInvoiceMercadoLivreStatus } from 'services/invoice/mercadoLivre/updateInvoiceMercadoLivreStatus'
import { InvoiceMercadoLivreType } from 'types/invoice/invoice-mercado-livre'

export function invoiceMercadoLivreMapper(data: InvoiceMercadoLivreType[]) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const handleToggleStatus = async (id: string | undefined | number) => {
    try {
      await updateInvoiceMercadoLivreStatus(id)
      queryClient.invalidateQueries('invoice-mercado-livre')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }
  return data.map((item) => ({
    ...item,
    id: typeof item?.id !== 'number' ? item?.id?.value : item?.id,
    formattedMonth: moment(item.monthYear).format('MMM/YYYY'),
    formattedExpiration: moment(item.dueDate).format('DD/MM/YYYY'),
    formattedTotalValue: `R$ ${Number(item.totalPayment).toLocaleString(
      'pt-BR',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    )}`,
    muiStatus: (
      <Toggle
        name='toggle'
        label={item.status ? t('paid') : t('notPaid')}
        checked={item.status}
        onChange={() =>
          handleToggleStatus(
            typeof item?.id !== 'number' ? item?.id?.value : item?.id,
          )
        }
      />
    ),
  }))
}
