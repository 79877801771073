import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { sensorDoorMock } from 'mocks/pre-os.mock'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { useSensorRealTimeChart } from 'pages/dashboard/hooks/sensor/useSensorRealTimeChart'
import { sensorDoorService } from 'services/dashboard/sensordoorDashboard.service'
import {
  SensorDoor,
  getSensorDoorList,
} from 'services/sensor-door/list-sensor.service'

import { SensorCardDetails } from './SensorCardDetails'
interface Props {
  isPreview?: boolean
  timeInMinutes: number
}

export function RealTimeSensorChart(props: Props) {
  const { isPreview, timeInMinutes } = props
  const { data: sensorDoors, isLoading } = useQuery({
    queryKey: 'sensor-door-list',
    queryFn: () => getSensorDoorList(),
  })

  const { data: sensorsList, refetch } = useQuery({
    queryKey: ['sensor', 'opening'],
    queryFn: async () => {
      return await sensorDoorService.listOpeningAllToday()
    },
  })

  useEffect(() => {
    const interval = setInterval(
      () => {
        refetch()
      },
      timeInMinutes * 60 * 1000,
    )

    return () => clearInterval(interval)
  }, [refetch])

  useEffect(() => {
    if (sensorsList && sensorsList.length === 0) {
      toast.info(t('dashboard.sensor.message.infoNoData'))
    }
  }, [sensorsList, refetch])

  const {
    t,
    totals,
    selectedSensors,
    setSelectedSensors,
    sensors,
    formatarTempoDecorrido,
  } = useSensorRealTimeChart()

  if (isLoading) return <CircularProgress />

  return (
    <Box
      sx={{
        gridArea: 'bottom-left',
        borderRadius: 1,
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        overFlowY: 'hidden',
        minHeight: 180,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        position: 'relative',
      }}
    >
      <Stack
        direction={['row']}
        justifyContent={['space-between']}
        alignItems={['center']}
        marginBottom={3}
      >
        <Typography
          variant='h2'
          fontSize='1.125rem'
          fontWeight='bold'
          whiteSpace={['normal', isPreview ? 'normal' : 'nowrap']}
        >
          {t('chart.sensor.sensorRealTime')}
        </Typography>
      </Stack>

      <Grid container spacing={2}>
        {sensorsList?.map((door, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <SensorCardDetails data={door} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
