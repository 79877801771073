import { Stack, Typography } from '@mui/material'

export function TutorialPage() {
  return (
    <Stack
      spacing={3}
      justifyContent={'center'}
      alignItems={'center'}
      textAlign={'center'}
      paddingY={[6, 8]}
    >
      <Typography variant='h1' fontWeight='bold'>
        Como podemos te ajudar?
      </Typography>
    </Stack>
  )
}
