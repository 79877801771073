import { IconButton } from 'components/Button/IconButton'

export function CalendarioManutencaoContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros e acompanhamento das
        atividades das manutenções de fábrica. Duas rotas podem preencher este
        Módulo, sendo a primeira pela Pré OS, onde são aprovadas as OS antes de
        irem para o calendário. E a segunda rota é por cadastro direto dentro do
        calendário.
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>
        <strong>Cadastrar OS de Calendário de Manutenção</strong>
      </p>

      <p>Para realizar o cadastro de uma nova OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Manutenção
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Calendário de
        Manutenção
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar em uma área da data ou na data que
        deseja cadastrar a OS.
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os campos: solicitante (o sistema
        pode identificar quem é a pessoa que está realizando a OS), nome da OS,
        equipamento, modelo, número de série, tipo de OS, localização do
        equipamento, prioridade, data, hora de atendimento, upload de imagem da
        ocorrência, e descrição da OS.
      </p>
      <p>
        No momento em que a OS for criada, irá aparecer um cartão na data
        informada com a cor azul, informando que ela é nova.{' '}
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar .
      </p>

      <p>
        <strong>Editar OS de Calendário de Manutenção </strong>
      </p>
      <p>Para realizar a edição de uma OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Manutenção
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Calendário de
        Manutenção
      </p>
      <p>
        <strong>Passo 4:</strong> Buscar pela data da OS
      </p>
      <p>
        <strong>Passo 5:</strong> Selecionar a OS que vai editar e selecionar o
        botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 6:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos: solicitante (o sistema pode
        identificar quem é a pessoa que está realizando a OS), nome da OS,
        equipamento, modelo, número de série, tipo de OS, localização do
        equipamento, prioridade, data, hora de atendimento, upload de imagem da
        ocorrência, e descrição da OS.
      </p>
      <p>
        No momento em que a OS for criada, irá aparecer um cartão na data
        informada com a cor azul, informando que ela é nova.{' '}
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar ..
      </p>
      <p>
        <strong>Excluir OS de Calendário de Manutenção</strong>
      </p>

      <p>Para realizar a exclusão de uma OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Manutenção
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Calendário de
        Manutenção
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a data e a OS que vai excluir
        dentro do calendário e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a fatura de contrato será permanentemente
        excluída do sistema.
      </p>
      <p>
        <strong>Excluir OS de Calendário de Manutenção</strong>
      </p>
      <p>
        O acompanhamento da OS é para verificar como esta o desenvolvimento da
        OS, tendo dentro dessa ação verificar os dados da OS que foram
        registradas no cadastro dela, comentário de usuários que estão
        responsáveis pela OS, e progresso da OS.{' '}
      </p>
      <p>
        Os usuários podem aceitar ou recusar a execução da OS. Ao aceitá-la, o
        usuário pode dar como resolvido ou não resolvida. Ao recusá-las, o
        usuário deverá informar o motivo da recusa, que será registrada na OS na
        parte de Progresso da OS.
      </p>
      <p>
        O sistema disponibiliza o progresso da OS, onde tem os status de Ordem
        de Serviço Criada{' '}
        <IconButton iconName='circle' variant='text' color='inherit' />,
        Recusada pelo usuário x , em andamento pelo usuário x{' '}
        <IconButton iconName='timelapse' variant='text' color='inherit' /> e não
        resolvido pelo usuário x{' '}
        <IconButton iconName='close' variant='text' color='inherit' />.
      </p>
      <p>
        O calendário pode ser visualizado por semana, mês e ano, podendo
        selecionar os mesmo para visualização . Também pode realizar pesquisas
        por palavras chaves , facilitando encontrar a OS em que procura quando
        não sabe a data programada.{' '}
      </p>
      <p>Para Aceitar uma OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Manutenção
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Calendário de
        Manutenção
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a data e a OS que vai excluir
        dentro do calendário e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Acompanhar OS”{' '}
        <IconButton iconName='historic' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Leia atentamente sobre o que se trata a OS, se
        estiver de acordo com os seus serviços de manutenção e data disponível,
        selecione “Aceitar” .
      </p>
      <p>Quando a OS for resolvida, repita o passo 1 a 5 e selecione .</p>
      <p>
        Quando a OS não for resolvida, repita o passo de 1 a 5 e selecione .
        Após a recusa, informe o motivo e salve.
      </p>
      <p>
        Em caso de realização do item 6, porém não está de acordo com os seus
        serviços ou data indisponível, selecione “Recusar” , o sistema
        solicitará que informe o motivo da recusa.
      </p>
      <p>
        Para registrar um comentário, antes, durante ou depois do atendimento da
        OS, basta seguir os passos de 1 a 5 e nos campos registrados da OS,
        encontrar a opção ‘Comentar’ e enviar o comentário.
      </p>
    </>
  )
}
