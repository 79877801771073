export function formatRelativeTime(dateTime: Date | string): string {
  const rtf = new Intl.RelativeTimeFormat('pt-BR', { numeric: 'auto' })

  // Obter a data atual
  const now = new Date()

  // Converter a entrada para um objeto Date, se for uma string
  const inputDate = typeof dateTime === 'string' ? new Date(dateTime) : dateTime

  if (isNaN(inputDate.getTime())) {
    return ''
  }

  // Calcular a diferença de tempo em milissegundos
  const timeDiff = now.getTime() - inputDate.getTime()

  // Calcular a diferença em termos de segundos, minutos, horas e dias
  const seconds = Math.floor(timeDiff / 1000)
  const minutes = Math.floor(timeDiff / (1000 * 60))
  const hours = Math.floor(timeDiff / (1000 * 60 * 60))
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30))
  const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30 * 12))

  if (seconds < 60) {
    return rtf.format(-seconds, 'second')
  } else if (minutes < 60) {
    return rtf.format(-minutes, 'minute')
  } else if (hours < 24) {
    return rtf.format(-hours, 'hour')
  } else if (days < 365) {
    return rtf.format(-days, 'day')
  } else if (months < 12) {
    return rtf.format(-months, 'month')
  } else {
    return rtf.format(-years, 'year')
  }
}
