import { getErrorMessage } from 'helpers/getErrorMessage'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'
import { CreateSensorDto } from 'types/sensor/create-sensor.dto'
import { SensorType, SensorTypeMap } from 'types/sensor/sensor'
import { UpdateSensorDto } from 'types/sensor/update-sensor.dto'

import { httpClient } from './api/httpClient'

type NewType = PaginationType<SensorType>

export type ResponseType = SensorType & {
  id: number
}

export interface SensorTypeTemp {
  id: string
  name: string
  type: string
  address: string
  dataUnifier: boolean
  ip?: any
  port?: any
  unit: string
  status: boolean
}

const update = ({
  id,
  data,
}: {
  id: number | string
  data: UpdateSensorDto
}) => {
  return httpClient.put(`/sensor/${id}`, data)
}

const create = ({ data }: { data: CreateSensorDto }) => {
  return httpClient.post('/sensor', data)
}

export const findAll = async ({
  page = 1,
  search = '',
  type,
  columnSort,
  columnName,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  const newParams: {
    page: number
    name?: string
    types?: string
    columnSort?: string
    numberPerPage?: number
    columnName?: string
  } = {
    page: page - 1,
    columnName,
    columnSort,
    numberPerPage,
  }

  if (type) newParams.types = type
  if (search) newParams.name = search

  const { data } =
    search?.length || type?.length
      ? await httpClient.get<PaginationResponseType>('/sensor/findcustom', {
          params: newParams,
        })
      : await httpClient.get<PaginationResponseType>('/sensor', {
          params: newParams,
        })

  return {
    items: data?.items ?? [],
    currentPage: page,
    perPage: data?.perPage,
    total: data?.total,
  }
}

const listMapSensor = async ({
  page,
  columnName,
  columnSort,
  numberPerPage = 10,
  type,
  search,
}: PaginationParamType): Promise<PaginationType<SensorTypeMap>> => {
  const newParams: {
    page: number
    name?: string
    types?: string
    columnSort?: string
    numberPerPage?: number
    columnName?: string
    isAvailable?: boolean
  } = {
    page: page - 1,
    columnName,
    columnSort,
    numberPerPage,
    name: search,
    isAvailable: true,
  }
  if (type) newParams.types = type
  const { data } = await httpClient.get('/sensor/findcustom', {
    params: newParams,
  })

  return data
}

const list = async (): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('/sensor/findcustom', {
    params: {
      page: 0,
      numberPerPage: 1000,
    },
  })

  return (
    data?.items?.map((item: SensorType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}

const listAllIsNotLiked = async (): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('/sensor/available')

  return data?.map((item: SensorType) => ({
    ...item,
    id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
  }))
}

const listAllElectricType = async (): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('/sensor/find-all-electric-type')

  return (
    data?.map((item: SensorType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}

const listAllOpeningType = async (): Promise<SensorTypeTemp[]> => {
  const { data } = await httpClient.get('/sensor/find-all-opening-type')

  return (
    data?.map((item: SensorType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}

const deleteSensor = ({
  id,
  callback,
  callbackError,
}: {
  id: number | string
  callback?: () => void
  callbackError?: () => void
}) => {
  httpClient
    .delete(`/sensor/${id}`)
    .then(() => {
      if (callback) callback()
    })
    .catch((error) => {
      getErrorMessage(error)
      if (callbackError) callbackError()
    })
}

export const sensorService = {
  update,
  create,
  findAll,
  list,
  delete: deleteSensor,
  listAllElectricType,
  listAllOpeningType,
  listMapSensor,
  listAllIsNotLiked,
}
