import { MyAccountAction } from './MyAccountAction'
import { MyAccountActions } from './MyAccountActions'
import { MyAccountContent } from './MyAccountContent'
import { MyAccountItem } from './MyAccountItem'
import { MyAccountModal } from './MyAccountModal'
import { MyAccountRoot } from './MyAccountRoot'

export const MyAccount = {
  Root: MyAccountRoot,
  Actions: MyAccountActions,
  Action: MyAccountAction,
  Content: MyAccountContent,
  Modal: MyAccountModal,
  Item: MyAccountItem,
}
