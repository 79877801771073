import dayjs from 'dayjs'

import { PeriodType } from 'components/DatePeriod'
import { httpClient } from 'services/api/httpClient'
import { EnergyConsumptionType } from 'types/dashboard/energy/energy-consumption'
import { EnergyElectricalQuantitiesType } from 'types/dashboard/energy/energy-electrical-quantities'

const getAllEnergyConsumption = async (
  startDate?: Date | string,
  endDate?: Date | string,
  filter?: PeriodType,
  areaId?: string | Array<string>,
): Promise<EnergyConsumptionType[]> => {
  // if yer null or undefined, set year to current year
  if (areaId === undefined) {
    return []
  }
  if (filter === 'year') {
    const year = startDate ? dayjs(startDate).year() : ''
    const response = await httpClient.get(
      `/dashboard-energy/consumption/year/${year}/area/${
        areaId as Array<string>
      }`,
    )
    return response.data
  }
  if (filter === 'month') {
    const date = endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
    const response = await httpClient.get(
      `/dashboard-energy/consumption/month/${date}/area/${
        areaId as Array<string>
      }`,
    )
    return response.data
  }
  if (filter === 'week') {
    const response = await httpClient.get(
      `/dashboard-energy/consumption/week/${startDate}/${endDate}/area/${
        areaId as Array<string>
      }`,
    )
    return response.data
  }
  return []
}

const getElectricalQuantities = async (
  startDate?: Date | string,
  endDate?: Date | string,
  filter?: PeriodType,
  sensorIds?: Array<string>,
  valueType?: string,
): Promise<EnergyElectricalQuantitiesType[]> => {
  // if yer null or undefined, set year to current year
  if (sensorIds === undefined) {
    return []
  }
  if (filter === 'month') {
    const date = endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
    const response = await httpClient.get(
      `/dashboard-energy/electrical-quantities/month/${date}/value-type/${valueType}/sensors/${
        sensorIds as Array<string>
      }`,
    )
    return response.data
  }
  if (filter === 'week') {
    const response = await httpClient.get(
      `/dashboard-energy/electrical-quantities/week/${startDate}/${endDate}/value-type/${valueType}/sensors/${
        sensorIds as Array<string>
      }`,
    )
    return response.data
  }
  return []
}

export { getAllEnergyConsumption, getElectricalQuantities }
