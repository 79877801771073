/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartOptions } from 'chart.js'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { createGradient } from 'helpers/createGradient'
import { getMonthDays } from 'helpers/getMonthDays'
import { getWeekDays } from 'helpers/getWeekDays'
import { httpClient } from 'services/api/httpClient'
import { getAllEquipmentTypeWaterTank } from 'services/equipments/getAllEquipmentTypeWaterTank'

const colors = [
  {
    start: '#87CDFC',
    end: '#A2F5EF',
  },
]

interface Props {
  isPreview?: boolean
}

export function useWaterTankLevelChart(props: Props) {
  const { isPreview } = props
  const { t, i18n } = useTranslation('common')
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')

  const [equipment, setSelectEquipment] = useState<any | null>(null)

  const [generatingFile, setGeneratingFile] = useState(false)

  const { data, refetch } = useQuery({
    queryKey: ['water-tank-level', date, period, equipment?.value],
    queryFn: async () => {
      if (!equipment) return []

      const response = await httpClient.get<any[]>(
        '/dashboard-water/water-tank-level',
        {
          params: {
            year: dayjs(date).format('YYYY'),
            month: period === 'month' ? dayjs(date).format('MM') : undefined,
            equipmentId: equipment?.value,
            equipmentType: equipment?.type,
          },
        },
      )

      if (period === 'month') {
        return (
          response.data?.reduce((acc, item) => {
            const { year, month, day, value, waterinlet, wateroutlet } = item

            const existingEntry = acc.find(
              (entry: any) =>
                entry.year === year &&
                entry.month === month &&
                entry.day === day,
            )

            if (existingEntry) {
              if (waterinlet) {
                existingEntry.valueWaterinlet += value
              }
              if (wateroutlet) {
                existingEntry.valueWateroutlet += value
              }
            } else {
              const newEntry = {
                year,
                month,
                day,
                valueWaterinlet: waterinlet ? value : 0,
                valueWateroutlet: wateroutlet ? value : 0,
              }
              acc.push(newEntry)
            }

            return acc
          }, []) ?? []
        )
      }

      return (
        response.data?.reduce((acc, item) => {
          const { year, month, value, waterinlet, wateroutlet } = item

          const existingEntry = acc.find(
            (entry: any) => entry.year === year && entry.month === month,
          )

          if (existingEntry) {
            if (waterinlet) {
              existingEntry.valueWaterinlet += value
            }
            if (wateroutlet) {
              existingEntry.valueWateroutlet += value
            }
          } else {
            const newEntry = {
              year,
              month,
              valueWaterinlet: waterinlet ? value : 0,
              valueWateroutlet: wateroutlet ? value : 0,
            }
            acc.push(newEntry)
          }

          return acc
        }, []) ?? []
      )
    },
  })

  const { data: equipments, refetch: refetchEquipments } = useQuery({
    queryKey: 'all-equipments',
    queryFn: async () => {
      return await getAllEquipmentTypeWaterTank()
    },
    select: (data: any) => {
      return data?.map((item: any) => ({
        label: item.name,
        value: String(item?.id),
        type: item.type,
      }))
    },
  })

  useEffect(() => {
    if (equipments?.length) {
      const findWaterTank = equipments?.find(
        (item: any) => item.type === 'CAIXA_DAGUA',
      )
      setSelectEquipment(findWaterTank ?? equipments[0] ?? null)
    }
  }, [equipments])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
      },
      title: {
        display: false,
        text: 'Chart.js',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.y} m³`
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          display: isPreview ? false : true,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value + ' m³'
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
      refetchEquipments()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const getLabels = useCallback(() => {
    if (period === 'year') {
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    return getWeekDays(date, 'DD/MMM')
  }, [period, date])

  const getDatasets = useCallback(
    (labels: string[] | dayjs.Dayjs[]) => {
      let datasets: any[] = []

      if (period === 'year') {
        datasets = [
          {
            fill: true,
            type: 'line' as const,
            // data: Array(12)
            //   .fill(null)
            //   .map(() => Math.floor(Math.random() * 2000)),
            data: labels.map((_, indexMonth) => {
              const dataItem = data?.find(
                (item: any) => item.month === (indexMonth + 1).toString(),
              )

              const value =
                dataItem?.valueWaterinlet > dataItem?.valueWateroutlet
                  ? dataItem?.valueWaterinlet - dataItem?.valueWateroutlet
                  : 0

              if (!value) return 0

              return Number(value).toFixed(3)
            }),
            backgroundColor: createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              colors[0],
            ),
            borderColor: createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              colors[0],
            ),
            tension: 0.5,
          },
          ...datasets,
        ] // gera o gráfico por ano
      } else if (period === 'month') {
        datasets = [
          {
            fill: true,
            type: 'line' as const,
            // data: Array(12)
            //   .fill(null)
            //   .map(() => Math.floor(Math.random() * 2000)),
            data: labels.map((day) => {
              const dataItem = data?.find(
                (item: any) => item.day === day.toString(),
              )

              const value =
                dataItem?.valueWaterinlet > dataItem?.valueWateroutlet
                  ? dataItem?.valueWaterinlet - dataItem?.valueWateroutlet
                  : 0

              if (!value) return 0

              return Number(value).toFixed(3)
            }),
            backgroundColor: createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              colors[0],
            ),
            borderColor: createGradient(
              chartRef.current.ctx,
              chartRef.current.chartArea,
              colors[0],
            ),
            tension: 0.5,
          },
          ...datasets,
        ] // gera o gráfico por mês
      }

      return datasets
    },
    [period, date, data],
  )

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()
    const datasets = getDatasets(labels)

    const chartData = {
      labels,
      datasets,
    }

    setChartData(chartData)
  }, [i18n.language, date, data])

  return {
    chartData,
    chartRef,
    date,
    setDate,
    options,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    equipments,
    equipment,
    setSelectEquipment,
  }
}
