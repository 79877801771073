import { useMemo, useState } from 'react'

type Props = {
  decreaseEnable?: () => boolean
  increaseEnable?: () => boolean
  maximumTemperature?: number
  minimumTemperature?: number
  airConditioningIsOn?: boolean
  onIncreaseEvent?: (temperature: number) => void
  onDecreaseEvent?: (temperature: number) => void
}

export const useAirConditioningControlPanel = ({
  onDecreaseEvent,
  onIncreaseEvent,
  maximumTemperature = 27,
  minimumTemperature = 16,
  increaseEnable = () => true,
  decreaseEnable = () => true,
  airConditioningIsOn = true,
}: Props = {}) => {
  const [temperature, setTemperature] = useState(16)

  const colorGradient = useMemo(() => {
    const colors = [
      {
        colors: ['#1B187A', '#2C124E'],
        range: {
          min: 16,
          max: 18,
        },
      },
      {
        colors: ['#1E19DC', '#1B187A'],
        range: {
          min: 19,
          max: 21,
        },
      },
      {
        colors: ['#3BD0FF', '#1E19DC'],
        range: {
          min: 22,
          max: 24,
        },
      },
      {
        colors: ['#19DCAD', '#3BD0FF'],
        range: {
          min: 25,
          max: 26,
        },
      },
      {
        colors: ['#19DC46', '#19DCAD'],
        range: {
          min: 27,
        },
      },
    ]

    const color = colors.find(({ range }) => {
      const minValid = temperature >= range.min
      const maxValid = range.max ? temperature <= range.max : true
      return minValid && maxValid
    })

    if (airConditioningIsOn && color) {
      return color.colors
    }

    return ['#C3C3C3', '#545454']
  }, [temperature, airConditioningIsOn])

  const increaseIsValid = () => {
    return temperature < maximumTemperature && increaseEnable()
  }

  const decreaseIsValid = () => {
    return temperature > minimumTemperature && decreaseEnable()
  }

  const increaseTemperature = () => {
    if (increaseIsValid()) {
      const newValue = temperature + 1
      onIncreaseEvent?.(newValue)
      setTemperature(newValue)
    }
  }

  const decreaseTemperature = () => {
    if (decreaseIsValid()) {
      const newValue = temperature - 1
      onDecreaseEvent?.(newValue)
      setTemperature(newValue)
    }
  }

  return {
    setTemperature,
    increaseIsValid,
    decreaseIsValid,
    temperature,
    colorGradient,
    decreaseTemperature,
    increaseTemperature,
  }
}
