import { converterKwhToMwh } from 'helpers/converter.helper'
import { GraphicConsumptionGeneralType } from 'types/invoice/dashboard/energy/graphic-consumption-general'
import { GraphicConsumptionGeneralType as FreeMarketType } from 'types/invoice/dashboard/freeMarket/graphic-consumption-general'
const getConsumedEnergy = (data: GraphicConsumptionGeneralType[]) => {
  const month = new Array(12).fill(0)
  const consumption = new Array(12).fill(0)
  data.map((item) => {
    month[Number(item.month) - 1] = Number(item.month)
    consumption[Number(item.month) - 1] = converterKwhToMwh(
      Number(item.consumption),
    )
  })
  return {
    month,
    consumption,
  }
}

const getContractedEnergy = (data: FreeMarketType[]) => {
  const month = new Array(12).fill(0)
  const contracted = new Array(12).fill(0)
  data.map((item) => {
    month[Number(item.month) - 1] = Number(item.month)
    contracted[Number(item.month) - 1] = Number(item.contracted)
  })
  return {
    month,
    contracted,
  }
}

export { getConsumedEnergy, getContractedEnergy }
