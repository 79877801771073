import { Stack, Typography, useTheme } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { FC, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { getIconByEquipmentType } from 'helpers/equipment-type.helper'
import { uuid } from 'helpers/uuid'
import { useModalAreaControl } from 'pages/map/hook/useModalAreaControl'
import { ModalType } from 'types/modal'
import { SubareaType } from 'types/subarea/subarea'

import { LabelSidebarMonitoring } from '../MonitoringSidebar/styles'
import { LabelNameSubarea, ModalContent, ModalWrapper } from '../styles'
import { CompactAirConditioningControl } from './CompactAirConditioningControl'
import { ConsumptionGraph } from './ConsumptionGraph'
import { ModalSetTemperature } from './ModalSetTemperature'

type Props = ModalType & {
  sensorsAndEquipments?: SubareaType[]
}

export const ModalAreaControl: FC<Props> = ({
  open,
  onClose,
  sensorsAndEquipments = [],
}) => {
  const sensorIds = useMemo(() => {
    return sensorsAndEquipments
      .filter((row) =>
        [SensorTypeEnum.TEMP, SensorTypeEnum.TEMP_HUM].includes(
          row.sensor?.type,
        ),
      )
      .map((row) => row.sensor?.id?.value)
  }, [sensorsAndEquipments])

  const theme = useTheme()

  const numberOfAirConditioners = useMemo(() => {
    return sensorsAndEquipments.filter(
      (row) => row.equipment?.type === EquipmentTypeEnum.AIR_CONDITIONING,
    ).length
  }, [sensorsAndEquipments])

  const { data, averageSubareaTemperature } = useModalAreaControl({
    sensorIds,
  })

  const [showSetTemperature, setShowSetTemperature] = useState(false)
  const airConditioners = useMemo(
    () =>
      Array(4)
        .fill(null)
        .map((_, index) => ({
          id: uuid(),
          title: `Ar condicionado 000${index + 1}`,
        })),
    [],
  )

  const updateTemperatureAllAirConditioners = () => {
    setShowSetTemperature(false)
    toast.success('Temperatura ajustada com sucesso')
    onClose()
  }

  return (
    <div
      style={{
        position: open ? 'fixed' : 'initial',
        zIndex: 100,
        left: 0,
        height: '100%',
        width: '100%',
      }}
      onClick={onClose}
    >
      <ModalWrapper
        open={open}
        onClick={(event) => event.stopPropagation()}
        background={theme.palette.background.default}
      >
        <Stack
          flexDirection={'row'}
          flexWrap={'wrap'}
          marginBottom={1.5}
          alignItems={'center'}
        >
          <Stack
            gap={'0.5rem'}
            flex={1}
            flexWrap={'wrap'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <LabelNameSubarea>Nome sub area</LabelNameSubarea>
            <LabelSidebarMonitoring
              bgColor={
                theme.palette.mode === 'light'
                  ? theme.palette.background.paper
                  : 'transparent'
              }
            >
              <img
                src={getIconByEquipmentType(EquipmentTypeEnum.AIR_CONDITIONING)}
                height={15}
                width={15}
              />
              <Typography
                fontSize={'10px'}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                Ar condicionado
              </Typography>
            </LabelSidebarMonitoring>
          </Stack>
          <IconButton onClick={onClose} variant='contained' iconName='close' />
        </Stack>
        <Typography
          fontSize={'24px'}
          fontWeight={600}
          marginBottom={1.5}
          color={'text.primary'}
        >
          Laboratório
        </Typography>
        <Stack flexDirection={'row'} gap={2} marginBottom={1.5}>
          <ModalContent
            background={
              theme.palette.mode === 'light'
                ? theme.palette.background.paper
                : '#0C0C25'
            }
          >
            <Typography
              marginBottom={1}
              fontWeight={600}
              color={'text.primary'}
            >
              {averageSubareaTemperature.toFixed(2)}ºC
            </Typography>
            <Typography color={'text.primary'}>
              Temperatura média da sub área
            </Typography>
          </ModalContent>
          <ModalContent
            style={{ marginTop: 'initial' }}
            background={
              theme.palette.mode === 'light'
                ? theme.palette.background.paper
                : '#0C0C25'
            }
          >
            <Typography
              marginBottom={1}
              fontWeight={600}
              color={'text.primary'}
            >
              {numberOfAirConditioners} ar condicionado(s)
            </Typography>
            <Typography color={'text.primary'}>
              Cadastrados nessa sub área
            </Typography>
          </ModalContent>
        </Stack>
        <ModalContent
          marginBottom='0.75rem'
          background={
            theme.palette.mode === 'light'
              ? theme.palette.background.paper
              : '#0C0C25'
          }
        >
          {open && (
            <ConsumptionGraph
              data={data}
              averageTemperature={averageSubareaTemperature}
            />
          )}
        </ModalContent>

        {/* TODO: retornar com esta parte após aprovação da Análise de sistema */}
        {/*
        <ModalContent marginBottom='0.75rem'>
          <Typography fontSize={'14px'} fontWeight={'600'} marginBottom={1.5}>
            Controles dos ar condicionados do ambiente
          </Typography>

          <Stack
            flexDirection={'row'}
            gap={1}
            flexWrap={'wrap'}
            marginBottom={1.5}
          >
            {airConditioners.map((airConditioning) => (
              <CompactAirConditioningControl
                key={airConditioning.id}
                title={airConditioning.title}
              />
            ))}
          </Stack>

          <Button
            fullWidth
            variant='contained'
            onClick={() => setShowSetTemperature(true)}
          >
            Definir temperatura para todos
          </Button>
        </ModalContent>
        <ModalSetTemperature
          open={showSetTemperature}
          onSuccess={updateTemperatureAllAirConditioners}
          onClose={() => setShowSetTemperature(false)}
        /> */}
      </ModalWrapper>
    </div>
  )
}
