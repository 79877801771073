import { IconButton } from 'components/Button/IconButton'

export function PreOSContent() {
  return (
    <>
      <p>
        <strong>Auditar Pré OS</strong>
      </p>

      <p>Para auditar uma Pré OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Manutenção
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Pré OS
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a pré OS que vai auditar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Selecione auditar{' '}
        <IconButton iconName='check' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 6:</strong> Verifique os dados informados, e aceite ou
        recuse a OS.
      </p>
      <p>Em caso de Aceitar:</p>
      <p>
        <strong>Passo 7:</strong> Informe o nome da OS, prioridade, usuário que
        atenderá a OS, data de início da OS e horário.
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar .
      </p>
      <p>
        No momento que é salva a OS aceita, ela é enviada para o Calendário de
        Manutenção conforme a data que foi informada.
      </p>
      <p>Em caso de Recusar:</p>
      <p>
        <strong>Passo 7:</strong> Informe o motivo da recusa da OS
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar .
      </p>
      <p>
        Dependendo da resposta sobre a OS, para Cliente irá mostrar os status de
        “Novo” (quando acabou de criar a OS), “Aceito” (quando a OS foi aceita,
        mas ainda não iniciada), “Recusada” com possibilidade de visualizar o
        motivo da recusa em Visualizar, “Em andamento” (quando a OS já iniciou o
        processo de resolver a OS) e “Finalizada” (quando a OS já foi realizada
        e finalizada)
      </p>
      <p>
        <strong>Excluir Pré OS</strong>
      </p>

      <p>Para realizar a exclusão de uma pré OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Manutenção
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Pré OS
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a Pré OS que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a fatura de contrato será permanentemente
        excluída do sistema.
      </p>
      <p>
        Caso essa Pré OS tenha sido cadastrada no módulo de Cliente, esta também
        será excluída para eles.
      </p>
      <p>
        <strong>Visualizar Pré OS</strong>
      </p>
      <p>Para realizar visualizar os dados de uma Pré OS, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Manutenção
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Pré OS
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a pré OS que vai visualizar dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, não podendo editá-las ou auditá-las.
      </p>
    </>
  )
}
