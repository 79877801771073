import { PermissionType } from '../types/permission.type'

export const defaultPermissions: PermissionType[] = [
  {
    id: 'DASH',
    label: 'dashboards',
    checked: false,
    children: [
      {
        id: 'DASH_WATER',
        module: 'DASH_WATER',
        type: 'VIEW',
        label: 'water',
        checked: false,
        parentId: 'DASH',
      },
      {
        id: 'DASH_ENERGY',
        module: 'DASH_ENERGY',
        type: 'VIEW',
        label: 'energy',
        checked: false,
        parentId: 'DASH',
      },
      {
        id: 'DASH_GAS',
        module: 'DASH_GAS',
        type: 'VIEW',
        label: 'gas',
        checked: false,
        parentId: 'DASH',
      },
      {
        id: 'DASH_TEMPERATURE',
        module: 'DASH_TEMPERATURE',
        type: 'VIEW',
        label: 'temperature',
        checked: false,
        parentId: 'DASH',
      },
      {
        id: 'DASH_TEMPERATURE_HUMIDITY',
        module: 'DASH_TEMPERATURE_HUMIDITY',
        type: 'VIEW',
        label: 'temperature and humidity',
        checked: false,
        parentId: 'DASH',
      },
      {
        id: 'DASH_HUMIDITY',
        module: 'DASH_HUMIDITY',
        type: 'VIEW',
        label: 'humidity',
        checked: false,
        parentId: 'DASH',
      },
      {
        id: 'DASH_ADVANCED',
        module: 'DASH_ADVANCED',
        type: 'VIEW',
        label: 'advanced',
        checked: false,
        parentId: 'DASH',
      },
    ],
  },
  {
    id: 'CONF',
    label: 'configurations',
    checked: false,
    children: [
      {
        id: 'CONF_SENSOR',
        module: 'CONF_SENSOR',
        label: 'sensors',
        checked: false,
        parentId: 'CONF',
        children: [
          {
            id: 'CONF_SENSOR_VIEW',
            module: 'CONF_SENSOR',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'CONF_SENSOR',
          },
          {
            id: 'CONF_SENSOR_CREATE',
            module: 'CONF_SENSOR',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'CONF_SENSOR',
          },
          {
            id: 'CONF_SENSOR_EDIT',
            module: 'CONF_SENSOR',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'CONF_SENSOR',
          },
          {
            id: 'CONF_SENSOR_DELETE',
            module: 'CONF_SENSOR',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'CONF_SENSOR',
          },
        ],
      },
      {
        id: 'CONF_EQUIPMENT',
        module: 'CONF_EQUIPMENT',
        label: 'equipments',
        checked: false,
        parentId: 'CONF',
        children: [
          {
            id: 'CONF_EQUIPMENT_VIEW',
            module: 'CONF_EQUIPMENT',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'CONF_EQUIPMENT',
          },
          {
            id: 'CONF_EQUIPMENT_CREATE',
            module: 'CONF_EQUIPMENT',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'CONF_EQUIPMENT',
          },
          {
            id: 'CONF_EQUIPMENT_EDIT',
            module: 'CONF_EQUIPMENT',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'CONF_EQUIPMENT',
          },
          {
            id: 'CONF_EQUIPMENT_DELETE',
            module: 'CONF_EQUIPMENT',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'CONF_EQUIPMENT',
          },
        ],
      },
      {
        id: 'CONF_NOTIFICATIONS',
        module: 'CONF_NOTIFICATIONS',
        label: 'notifications',
        checked: false,
        parentId: 'CONF',
        children: [
          {
            id: 'CONF_NOTIFICATIONS_VIEW',
            module: 'CONF_NOTIFICATIONS',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'CONF_NOTIFICATIONS',
          },
          {
            id: 'CONF_NOTIFICATIONS_CREATE',
            module: 'CONF_NOTIFICATIONS',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'CONF_NOTIFICATIONS',
          },
          {
            id: 'CONF_NOTIFICATIONS_EDIT',
            module: 'CONF_NOTIFICATIONS',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'CONF_NOTIFICATIONS',
          },
          {
            id: 'CONF_NOTIFICATIONS_DELETE',
            module: 'CONF_NOTIFICATIONS',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'CONF_NOTIFICATIONS',
          },
        ],
      },
      {
        id: 'CONF_GOAL',
        module: 'CONF_GOAL',
        label: 'goals and parameters',
        checked: false,
        parentId: 'CONF',
        children: [
          {
            id: 'CONF_GOAL_VIEW',
            module: 'CONF_GOAL',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'CONF_GOAL',
          },
          {
            id: 'CONF_GOAL_CREATE',
            module: 'CONF_GOAL',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'CONF_GOAL',
          },
          {
            id: 'CONF_GOAL_EDIT',
            module: 'CONF_GOAL',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'CONF_GOAL',
          },
          {
            id: 'CONF_GOAL_DELETE',
            module: 'CONF_GOAL',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'CONF_GOAL',
          },
        ],
      },
      {
        id: 'CONF_INVOICE',
        module: 'CONF_INVOICE',
        label: 'invoices',
        checked: false,
        parentId: 'CONF',
        children: [
          {
            id: 'CONF_INVOICE_VIEW',
            module: 'CONF_INVOICE',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'CONF_INVOICE',
          },
          {
            id: 'CONF_INVOICE_CREATE',
            module: 'CONF_INVOICE',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'CONF_INVOICE',
          },
          {
            id: 'CONF_INVOICE_EDIT',
            module: 'CONF_INVOICE',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'CONF_INVOICE',
          },
          {
            id: 'CONF_INVOICE_DELETE',
            module: 'CONF_INVOICE',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'CONF_INVOICE',
          },
        ],
      },
    ],
  },
  {
    id: 'MAP',
    label: 'map',
    checked: false,
    children: [
      {
        id: 'MAP_MONITORING',
        module: 'MAP_MONITORING',
        type: 'VIEW',
        label: 'monitoring of sensors',
        checked: false,
        parentId: 'MAP',
      },
      {
        id: 'MAP_MOUNT_MAP',
        module: 'MAP_MOUNT_MAP',
        label: 'mount map',
        checked: false,
        parentId: 'MAP',
        children: [
          {
            id: 'MAP_MOUNT_MAP_VIEW',
            module: 'MAP_MOUNT_MAP',
            label: 'view',
            type: 'VIEW',
            checked: false,
            parentId: 'MAP_MOUNT_MAP',
          },
          {
            id: 'MAP_MOUNT_MAP_CREATE',
            module: 'MAP_MOUNT_MAP',
            label: 'create',
            type: 'CREATE',
            checked: false,
            parentId: 'MAP_MOUNT_MAP',
          },
          {
            id: 'MAP_MOUNT_MAP_EDIT',
            module: 'MAP_MOUNT_MAP',
            label: 'edit',
            type: 'EDIT',
            checked: false,
            parentId: 'MAP_MOUNT_MAP',
          },
          {
            id: 'MAP_MOUNT_MAP_DELETE',
            module: 'MAP_MOUNT_MAP',
            label: 'delete',
            type: 'DELETE',
            checked: false,
            parentId: 'MAP_MOUNT_MAP',
          },
        ],
      },
      {
        id: 'MAP_SHIFTS',
        module: 'MAP_SHIFTS',
        label: 'shifts',
        checked: false,
        parentId: 'MAP',
        children: [
          {
            id: 'MAP_SHIFTS_VIEW',
            module: 'MAP_SHIFTS',
            label: 'view',
            type: 'VIEW',
            checked: false,
            parentId: 'MAP_SHIFTS',
          },
          {
            id: 'MAP_SHIFTS_CREATE',
            module: 'MAP_SHIFTS',
            label: 'create',
            type: 'CREATE',
            checked: false,
            parentId: 'MAP_SHIFTS',
          },
          {
            id: 'MAP_SHIFTS_EDIT',
            module: 'MAP_SHIFTS',
            label: 'edit',
            type: 'EDIT',
            checked: false,
            parentId: 'MAP_SHIFTS',
          },
          {
            id: 'MAP_SHIFTS_DELETE',
            module: 'MAP_SHIFTS',
            label: 'delete',
            type: 'DELETE',
            checked: false,
            parentId: 'MAP_SHIFTS',
          },
        ],
      },
      {
        id: 'MAP_MEASUREMENT_AREA',
        module: 'MAP_MEASUREMENT_AREA',
        label: 'measurement areas',
        checked: false,
        parentId: 'MAP',
        children: [
          {
            id: 'MAP_MEASUREMENT_AREA_VIEW',
            module: 'MAP_MEASUREMENT_AREA',
            label: 'view',
            type: 'VIEW',
            checked: false,
            parentId: 'MAP_MEASUREMENT_AREA',
          },
          {
            id: 'MAP_MEASUREMENT_AREA_CREATE',
            module: 'MAP_MEASUREMENT_AREA',
            label: 'create',
            type: 'CREATE',
            checked: false,
            parentId: 'MAP_MEASUREMENT_AREA',
          },
          {
            id: 'MAP_MEASUREMENT_AREA_EDIT',
            module: 'MAP_MEASUREMENT_AREA',
            label: 'edit',
            type: 'EDIT',
            checked: false,
            parentId: 'MAP_MEASUREMENT_AREA',
          },
          {
            id: 'MAP_MEASUREMENT_AREA_DELETE',
            module: 'MAP_MEASUREMENT_AREA',
            label: 'delete',
            type: 'DELETE',
            checked: false,
            parentId: 'MAP_MEASUREMENT_AREA',
          },
        ],
      },
    ],
  },
  {
    id: 'MAINTENANCE',
    label: 'maintenance',
    checked: false,
    children: [
      {
        id: 'MAIN_CALENDAR',
        label: 'maintenance schedule',
        checked: false,
        parentId: 'MAINTENANCE',
        children: [
          {
            id: 'MAIN_CALENDAR_VIEW',
            module: 'MAIN_CALENDAR',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'MAIN_CALENDAR',
          },
          {
            id: 'MAIN_CALENDAR_CREATE',
            module: 'MAIN_CALENDAR',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'MAIN_CALENDAR',
          },
        ],
      },
      {
        id: 'MAIN_PRE_OS',
        label: 'pre os',
        checked: false,
        parentId: 'MAINTENANCE',
        children: [
          {
            id: 'MAIN_PRE_OS_CREATE',
            module: 'MAIN_PRE_OS',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'MAIN_PRE_OS',
          },
          {
            id: 'MAIN_PRE_OS_EDIT',
            module: 'MAIN_PRE_OS',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'MAIN_PRE_OS',
          },
          {
            id: 'MAIN_PRE_OS_DELETE',
            module: 'MAIN_PRE_OS',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'MAIN_PRE_OS',
          },
        ],
      },
    ],
  },
  {
    id: 'ACC',
    label: 'access control',
    checked: false,
    children: [
      {
        id: 'ACC_PROFILE',
        module: 'ACC_PROFILE',
        label: 'profiles',
        checked: false,
        parentId: 'ACC',
        children: [
          {
            id: 'ACC_PROFILE_VIEW',
            module: 'ACC_PROFILE',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'ACC_PROFILE',
          },
          {
            id: 'ACC_PROFILE_CREATE',
            module: 'ACC_PROFILE',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'ACC_PROFILE',
          },
          {
            id: 'ACC_PROFILE_EDIT',
            module: 'ACC_PROFILE',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'ACC_PROFILE',
          },
          {
            id: 'ACC_PROFILE_DELETE',
            module: 'ACC_PROFILE',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'ACC_PROFILE',
          },
        ],
      },
      {
        id: 'ACC_USER',
        module: 'ACC_USER',
        label: 'users',
        checked: false,
        parentId: 'ACC',
        children: [
          {
            id: 'ACC_USER_VIEW',
            module: 'ACC_USER',
            type: 'VIEW',
            label: 'view',
            checked: false,
            parentId: 'ACC_USER',
          },
          {
            id: 'ACC_USER_CREATE',
            type: 'CREATE',
            label: 'create',
            checked: false,
            parentId: 'ACC_USER',
          },
          {
            id: 'ACC_USER_EDIT',
            module: 'ACC_USER',
            type: 'EDIT',
            label: 'edit',
            checked: false,
            parentId: 'ACC_USER',
          },
          {
            id: 'ACC_USER_DELETE',
            module: 'ACC_USER',
            type: 'DELETE',
            label: 'delete',
            checked: false,
            parentId: 'ACC_USER',
          },
        ],
      },
    ],
  },
  {
    id: 'CLI_CLIENT',
    module: 'CLI_CLIENT',
    label: 'client area',
    checked: false,
    children: [
      {
        id: 'CLI_CLIENT_VIEW',
        module: 'CLI_CLIENT',
        type: 'VIEW',
        label: 'view',
        checked: false,
        parentId: 'CLI_CLIENT',
      },
      {
        id: 'CLI_CLIENT_CREATE',
        module: 'CLI_CLIENT',
        type: 'CREATE',
        label: 'create',
        checked: false,
        parentId: 'CLI_CLIENT',
      },
      {
        id: 'CLI_CLIENT_EDIT',
        module: 'CLI_CLIENT',
        type: 'EDIT',
        label: 'edit',
        checked: false,
        parentId: 'CLI_CLIENT',
      },
      {
        id: 'CLI_CLIENT_DELETE',
        module: 'CLI_CLIENT',
        type: 'DELETE',
        label: 'delete',
        checked: false,
        parentId: 'CLI_CLIENT',
      },
    ],
  },
]
