import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import { DefaultLayout } from 'layouts/DefaultLayout'
import { PanicLayout } from 'layouts/PanicLayout'
import { PublicLayout } from 'layouts/PublicLayout'
import { TutorialLayout } from 'layouts/TutorialLayout'
import { AccessControlPage } from 'pages/accessControl/AccessControlPage'
import { BranchesPage } from 'pages/accessControl/BranchesPage'
import { ProfileFormPage } from 'pages/accessControl/ProfileFormPage'
import { ProfilesPage } from 'pages/accessControl/ProfilesPage'
import { UsersPage } from 'pages/accessControl/UsersPage'
import { CreatePasswordPage } from 'pages/auth/CreatePasswordPage'
import { ForgotPasswordPage } from 'pages/auth/ForgotPasswordPage'
import { FirstAccessEmailPage } from 'pages/auth/mail/FirstAccessEmailPage'
import { ResetPasswordEmailPage } from 'pages/auth/mail/ResetPasswordEmailPage'
import { ResetPasswordPage } from 'pages/auth/ResetPasswordPage'
import { SignInPage } from 'pages/auth/SignInPage'
import { BtnPanicAlertPage } from 'pages/btnPanicAlert/BtnPanicAlertPage'
import { AlertPanicScreen } from 'pages/btnPanicAlert/components/screen/AlertPanicScreen'
import { MapScreen } from 'pages/btnPanicAlert/components/screen/MapScreen'
import { UnitAreaScreen } from 'pages/btnPanicAlert/components/screen/UnitAreaScreen'
import { MapPlanConfigPage } from 'pages/btnPanicAlert/MapPlanConfigPage'
import { MonitoringPage } from 'pages/btnPanicAlert/MonitoringPage'
import { ProcedurePage } from 'pages/btnPanicAlert/ProcedurePage'
import { ReportPage } from 'pages/btnPanicAlert/ReportPage'
import { ClientPage } from 'pages/client/ClientPage'
import { DashboardPage } from 'pages/dashboard/DashboardPage'
import ErrorPage from 'pages/error/ErrorPage'
import { HomePage } from 'pages/home'
import { MaintenancePage } from 'pages/maintenance/MaintenancePage'
import { PreOSPage } from 'pages/maintenance/PreOSPage'
import { ScheduleDetailsPage } from 'pages/maintenance/ScheduleDetailsPage'
import { SchedulePage } from 'pages/maintenance/SchedulePage'
import { MapPage } from 'pages/map/MapPage'
import { MapUploadPage } from 'pages/map/MapUploadPage'
import { MeasuringAreasPage } from 'pages/map/MeasuringAreasPage'
import { MonitoringSensorPage } from 'pages/map/MonitoringSensorPage'
import { MountMapPage } from 'pages/map/MountMapPage'
import { ShiftPage } from 'pages/map/ShiftPage'
import { MonitoringCompanies } from 'pages/monitoringCompanies'
import { GeneralNotificationPage } from 'pages/notification/GeneralNotificationPage'
import { EquipmentPage } from 'pages/setting/EquipmentPage'
import { GoalAndParameterPage } from 'pages/setting/GoalAndParameterPage'
import { InvoicePage } from 'pages/setting/InvoicePage'
import { NotificationPage } from 'pages/setting/NotificationPage'
import { ScalabilityPage } from 'pages/setting/ScalabilityPage'
import { SensorHistoryPage } from 'pages/setting/SensorHistoryPage'
import { SensorPage } from 'pages/setting/SensorPage'
import { SettingPage } from 'pages/setting/SettingPage'
import { TutorialPage } from 'pages/tutorial/TutorialPage'
import { TutorialSearchPage } from 'pages/tutorial/TutorialSearchPage'

import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import RouterGuard from './RouterGuard'
import { userLoader } from './user.loader'

export const routers = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* ------------------ PRIVATE PAGES ------------------ */}

      <Route
        path='/'
        element={<ProtectedRoute layout={DefaultLayout} />}
        loader={userLoader}
        id='root'
        errorElement={<ErrorPage />}
      >
        <Route>
          <Route
            index
            element={<RouterGuard module='DASH' component={DashboardPage} />}
          />
          <Route
            path='dashboard'
            element={<RouterGuard module='DASH' component={DashboardPage} />}
          />

          <Route path='home' element={<HomePage />} />

          <Route path='notifications' element={<GeneralNotificationPage />} />

          <Route
            path='order-service'
            element={<RouterGuard module='CLI_CLIENT' component={ClientPage} />}
          />

          {/* Maintenance pages */}
          <Route path='maintenance'>
            <Route
              index
              element={
                <RouterGuard module='MAIN' component={MaintenancePage} />
              }
            />
            <Route
              path='schedule'
              element={
                <RouterGuard module='MAIN_CALENDAR' component={SchedulePage} />
              }
            />
            <Route
              path='schedule/details'
              element={
                <RouterGuard
                  module='MAIN_CALENDAR'
                  component={ScheduleDetailsPage}
                />
              }
            />
            <Route
              path='pre-os'
              element={
                <RouterGuard
                  module='MAIN_CONFIG_SCALING'
                  component={PreOSPage}
                />
              }
            />
          </Route>

          {/* Access control pages */}
          <Route path='access-control'>
            <Route
              index
              element={
                <RouterGuard module='ACC' component={AccessControlPage} />
              }
            />
            <Route
              path='users'
              element={<RouterGuard module='ACC_USER' component={UsersPage} />}
            />
            <Route
              path='profiles'
              element={
                <RouterGuard module='ACC_PROFILE' component={ProfilesPage} />
              }
            />
            <Route
              path='units'
              element={
                <RouterGuard module='ACC_UNIT' component={BranchesPage} />
              }
            />
            {/* <Route
              path='profiles/create'
              element={
                <RouterGuard
                  module='ACC_PROFILE'
                  component={OldFormProfilePage}
                />
              }
            />
            <Route
              path='profiles/edit'
              element={
                <RouterGuard
                  module='ACC_PROFILE'
                  component={OldFormProfilePage}
                />
              }
            /> */}
            <Route
              path='profiles/create'
              element={
                <RouterGuard module='ACC_PROFILE' component={ProfileFormPage} />
              }
            />
            <Route
              path='profiles/edit'
              element={
                <RouterGuard module='ACC_PROFILE' component={ProfileFormPage} />
              }
            />
            <Route
              path='profiles/view'
              element={
                <RouterGuard module='ACC_PROFILE' component={ProfileFormPage} />
              }
            />
          </Route>

          {/* Settings pages */}
          <Route path='settings'>
            <Route
              index
              element={<RouterGuard module='CONF' component={SettingPage} />}
            />
            <Route
              path='scalability'
              element={
                <RouterGuard module='CONF' component={ScalabilityPage} />
              }
            />
            <Route
              path='create-sensors'
              element={
                <RouterGuard module='CONF_SENSOR' component={SensorPage} />
              }
            />
            <Route
              path='create-sensors/historic'
              element={
                <RouterGuard
                  module='CONF_SENSOR'
                  component={SensorHistoryPage}
                />
              }
            />
            <Route
              path='create-equipments'
              element={
                <RouterGuard
                  module='CONF_EQUIPMENT'
                  component={EquipmentPage}
                />
              }
            />
            <Route
              path='create-notification'
              element={
                <RouterGuard
                  module='CONF_NOTIFICATIONS'
                  component={NotificationPage}
                />
              }
            />
            <Route
              path='invoices'
              element={
                <RouterGuard module='CONF_INVOICE' component={InvoicePage} />
              }
            />
            <Route
              path='goals-and-parameters'
              element={
                <RouterGuard
                  module='CONF_GOAL'
                  component={GoalAndParameterPage}
                />
              }
            />
          </Route>

          {/* Map pages */}
          <Route path='map'>
            <Route
              index
              element={<RouterGuard module='CONF_MAP' component={MapPage} />}
            />
            <Route
              path='measuring-areas'
              element={
                <RouterGuard module='CONF_MAP' component={MeasuringAreasPage} />
              }
            />
            <Route
              path='shifts'
              element={<RouterGuard module='CONF_MAP' component={ShiftPage} />}
            />
          </Route>

          {/* Alert panic btn pages */}
          <Route path='btn-panic-alert'>
            <Route
              index
              element={
                <RouterGuard module='BTN' component={BtnPanicAlertPage} />
              }
            />
            <Route
              path='create-procedures'
              element={
                <RouterGuard module='BTN_PROCEDURE' component={ProcedurePage} />
              }
            />
            <Route
              path='report'
              element={
                <RouterGuard module='BTN_REPORT' component={ReportPage} />
              }
            />
          </Route>
        </Route>
      </Route>

      <Route
        path='/'
        element={<ProtectedRoute layout={PanicLayout} />}
        loader={userLoader}
        errorElement={<ErrorPage />}
      >
        <Route
          index
          element={
            <RouterGuard
              module='MONITORING_COMPANIES'
              component={MonitoringCompanies}
            />
          }
        />
        <Route
          path='monitoring-companies'
          element={
            <RouterGuard
              module='MONITORING_COMPANIES'
              component={MonitoringCompanies}
            />
          }
        />

        <Route path='btn-panic-alert'>
          <Route
            path='btn-map-plan-config'
            element={
              <RouterGuard
                module='BTN_MONITORING'
                component={MapPlanConfigPage}
              />
            }
          />
          <Route
            path='monitoring'
            element={
              <RouterGuard module='BTN_MONIT' component={MonitoringPage} />
            }
          >
            <Route
              index
              path='map'
              element={<RouterGuard module='BTN_MONIT' component={MapScreen} />}
            />
            <Route
              path='area'
              element={
                <RouterGuard
                  module='BTN_MONITORING'
                  component={UnitAreaScreen}
                />
              }
            />
            <Route
              path='alert-panic'
              element={
                <RouterGuard module='BTN_MONIT' component={AlertPanicScreen} />
              }
            />
          </Route>
        </Route>
        <Route
          path='map/monitoring-sensors'
          element={
            <RouterGuard module='CONF_MAP' component={MonitoringSensorPage} />
          }
        />
        <Route path='map/mount-map'>
          <Route
            index
            element={<RouterGuard module='CONF_MAP' component={MountMapPage} />}
          />
          <Route
            path='upload'
            element={
              <RouterGuard module='CONF_MAP' component={MapUploadPage} />
            }
          />
        </Route>
      </Route>

      <Route
        path='/system-tutorial'
        element={<ProtectedRoute layout={TutorialLayout} />}
        // loader={rootLoader}
        // action={rootAction}
        errorElement={<ErrorPage />}
      >
        <Route index element={<TutorialPage />} />
        <Route path='search' element={<TutorialSearchPage />} />
      </Route>

      {/* ------------------ PUBLIC PAGES ------------------ */}
      <Route
        path='/'
        element={<PublicRoute layout={PublicLayout} />}
        // loader={rootLoader}
        // action={rootAction}
        errorElement={<ErrorPage />}
      >
        <Route>
          <Route path='login' element={<SignInPage />} />
          <Route path='forgot-password' element={<ForgotPasswordPage />} />
          <Route path='reset-password/:code' element={<ResetPasswordPage />} />
          <Route path='create-password' element={<CreatePasswordPage />} />
          <Route path='first-access/:code' element={<CreatePasswordPage />} />
          {/* <Route path='first-access/:code' element={<FirstAccessPage />} /> */}
          <Route path='email-first-access' element={<FirstAccessEmailPage />} />
          <Route
            path='email-reset-password'
            element={<ResetPasswordEmailPage />}
          />
        </Route>
      </Route>
    </>,
  ),
)
