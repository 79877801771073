import dayjs from 'dayjs'

import { httpClient } from 'services/api/httpClient'
import { SensorType } from 'types/sensor/sensor'

export interface SensorDoor {
  date: string
  sensor: number
  amount: number
  timeseconds: number
  isopening: boolean
  nameSensor: string
  local: string
}

const listOpeningAllToday = async (): Promise<SensorDoor[]> => {
  const dataAtual = new Date()
  const year = dataAtual.getFullYear()
  const getMonth = dataAtual.getMonth() + 1
  const getDay = dataAtual.getDate()

  const month = getMonth < 10 ? '0' + getMonth : getMonth
  const day = getDay < 10 ? '0' + getDay : getDay

  const dataFormatada = `${year}-${month}-${day}`

  const { data } = await httpClient.get('/dashboard-sensor/opening/all-today', {
    params: {
      today: dataFormatada,
    },
  })

  return (
    data?.map((item: SensorDoor) => ({
      date: item?.date,
      sensor: item?.sensor,
      amount: item?.amount,
      timeseconds: item?.timeseconds,
      isopening: item?.isopening,
      nameSensor: item?.nameSensor,
      local: item?.local ?? 'SEM VÍNCULO COM ÁREA',
    })) ?? []
  )
}

export interface SensorTypeTemp {
  id: string
  name: string
  type: string
  address: string
  dataUnifier: boolean
  ip?: any
  port?: any
  unit: string
  status: boolean
}
const listSensorOpeningDoor = async (): Promise<SensorTypeTemp[]> => {
  const { data } = await httpClient.get('/sensor/find-all-sensor-opening-door')

  return (
    data?.map((item: SensorType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}

export const sensorDoorService = {
  listOpeningAllToday,
  listSensorOpeningDoor,
}
