/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'
import { scalabilityService } from 'services/scalability.service'
import { PaginationType } from 'types/pagination'
import { ScalabilityType } from 'types/scalability/scalability'

export function useScalability() {
  const { t } = useTranslation('common')
  const [openFormScalability, setOpenFormScalability] = useState(false)
  const [openFormLevel, setOpenFormLevel] = useState(false)
  const [selectScalability, setSelectScalability] =
    useState<ScalabilityType | null>(null)
  const [page] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy] = useState('name')

  const {
    data: scalability,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<PaginationType<ScalabilityType>, Error>({
    queryKey: ['scalability', page, search, orderBy],
    queryFn: () => scalabilityService.findAll(page, search, orderBy),
  })

  const handleCreate = () => {
    setSelectScalability(null)
    setOpenFormScalability(true)
  }

  const handleCreateLevel = () => {
    setOpenFormLevel(true)
  }

  const handleEdit = (item: ScalabilityType) => {
    setSelectScalability(item)
    setOpenFormScalability(true)
  }

  const handleToggleStatus = async (data: any) => {
    httpClient
      .put(`/scalability/status/${data?.id.value}`)
      .then(() => {
        refetch()
      })
      .catch((error) => getErrorMessage(error))
  }

  return {
    t,
    scalability,
    setSearch,
    handleCreate,
    handleCreateLevel,
    setOpenFormScalability,
    openFormScalability,
    setOpenFormLevel,
    openFormLevel,
    handleToggleStatus,
    handleEdit,
    selectScalability,
    error,
    isLoading,
    isFetching,
  }
}
