import { Box, Stack, Tab } from '@mui/material'
import MuiTabs from '@mui/material/Tabs'
import { ReactNode, useId } from 'react'

import { a11yProps } from 'helpers/a11yProps'

interface Props {
  options: Array<{
    label: string
    value?: number
  }>
  value: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  children?: ReactNode
}

export function Tabs(props: Props) {
  const { options, value, onChange, children } = props
  const id = useId()

  return (
    <Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs value={value} onChange={onChange} aria-label={id}>
          {options.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab?.value ?? index}
              {...a11yProps(tab?.value ?? index)}
            />
          ))}
        </MuiTabs>
      </Box>

      {children}
    </Stack>
  )
}
