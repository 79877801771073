import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useAuthStore } from 'store/auth.store'
import { SignInType } from 'types/auth/signin'

export function useSignIn() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { signIn } = useAuth()
  const isSubmitting = useAuthStore((state) => state.loading)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInType>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit: SubmitHandler<SignInType> = (formData) => {
    signIn(
      {
        login: formData.email,
        password: formData.password,
      },
      () => {
        navigate('/')
      },
      () => {
        navigate('/login')
      },
    )
  }

  return { control, handleSubmit, onSubmit, errors, t, isSubmitting }
}
