import { Box, Typography, useTheme } from '@mui/material'
import {
  EquipmentTypeEnum,
  getAllEquipmentTypeEnum,
} from 'core/enums/EquipmentTypeEnum'
import { useEffect } from 'react'

import { getIconByEquipmentType } from 'helpers/equipment-type.helper'
import { useCheckboxSelection } from 'hooks/useCheckboxSelection'
import { useSidebarMonitoringStore } from 'store/monitoring/sidebar-monitoring.store'

import { MonitoringSidebarCard } from '../MonitoringSidebarCard'
import {
  LabelSidebarMonitoring,
  LabelSidebarMonitoringWrapper,
} from '../styles'

export const EquipmentCard = () => {
  const theme = useTheme()
  const { setTypesVisibleEquipment } = useSidebarMonitoringStore()

  const equipmentSelect = useCheckboxSelection<EquipmentTypeEnum>()
  const { values, setValues, changeValue, checkValueIsActive } = equipmentSelect

  const equipments = [
    {
      type: EquipmentTypeEnum.WATER_TANK,
      title: 'Caixa d’água',
    },
    {
      type: EquipmentTypeEnum.COMPRESSOR_AIR,
      title: 'Compressor',
    },
    {
      type: EquipmentTypeEnum.CONDENSER,
      title: 'Condensador',
    },
    {
      type: EquipmentTypeEnum.DRYBOX,
      title: 'Drybox',
    },
    {
      type: EquipmentTypeEnum.NOBREAK,
      title: 'Nobreak',
    },
    {
      type: EquipmentTypeEnum.SPLITTER,
      title: 'Splitão',
    },
    {
      type: EquipmentTypeEnum.GENERATOR,
      title: 'Gerador',
    },
    {
      type: EquipmentTypeEnum.DEHUMIDIFIER,
      title: 'Desumidificador',
    },
    {
      type: EquipmentTypeEnum.EXHAUST,
      title: 'Exaustor',
    },
    {
      type: EquipmentTypeEnum.FUEL_TANK,
      title: 'Tanque de combustível',
    },
    {
      type: EquipmentTypeEnum.NITROGEN_PLANT,
      title: 'Usina nitrogênio',
    },
    {
      type: EquipmentTypeEnum.OTHER,
      title: 'Outro',
    },
  ]

  const showAll = () => {
    if (checkValueIsActive(EquipmentTypeEnum.ALL)) {
      setValues([])
    } else {
      const equipmentsType = getAllEquipmentTypeEnum()
      setValues(
        equipmentsType.filter(
          (item) => item !== EquipmentTypeEnum.AIR_CONDITIONING,
        ),
      )
    }
  }

  useEffect(() => {
    setTypesVisibleEquipment(values)
  }, [values])

  return (
    <MonitoringSidebarCard title='Equipamentos' isAccordion>
      <LabelSidebarMonitoring
        onClick={showAll}
        isActive={checkValueIsActive(EquipmentTypeEnum.ALL)}
        bgColor={theme.palette.primary.main}
        style={{ marginBottom: '10px' }}
      >
        <Typography
          fontSize={'12px'}
          color={
            theme.palette.mode === 'dark'
              ? 'text.primary'
              : checkValueIsActive(EquipmentTypeEnum.ALL)
              ? '#FFF'
              : 'text.primary'
          }
        >
          Ver tudo
        </Typography>
      </LabelSidebarMonitoring>
      <LabelSidebarMonitoringWrapper>
        {equipments.map((equipment) => (
          <LabelSidebarMonitoring
            key={equipment.title}
            onClick={() => changeValue(equipment.type)}
            isActive={checkValueIsActive(equipment.type)}
            bgColor={theme.palette.primary.main}
          >
            <Box
              component='img'
              src={getIconByEquipmentType(equipment.type)}
              height={15}
              width={15}
              sx={{
                background:
                  theme.palette.mode === 'light'
                    ? (theme) => theme.palette.primary.main
                    : 'transparent',
                p: 0.25,
                borderRadius: 0.5,
              }}
            />
            <Typography
              fontSize={'12px'}
              color={
                theme.palette.mode === 'dark'
                  ? 'text.primary'
                  : checkValueIsActive(EquipmentTypeEnum.ALL) ||
                    checkValueIsActive(equipment.type)
                  ? '#FFF'
                  : 'text.primary'
              }
            >
              {equipment.title}
            </Typography>
          </LabelSidebarMonitoring>
        ))}
      </LabelSidebarMonitoringWrapper>
    </MonitoringSidebarCard>
  )
}
