import { Warning } from '@mui/icons-material'
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'

import { Actions } from 'components/Actions'
import { Loading } from 'components/Loading'
import { httpClientDownloadExcel } from 'helpers/httpClientDownloadExcel'
import { CircularGraphic } from 'pages/dashboard/components/graphic/Circular'
import { ModalSensorsTemperature } from 'pages/dashboard/components/list/ModalSensorsTemperature'
import { useRealTimeTemperatureChart } from 'pages/dashboard/hooks/temperature/useRealTimeTemperatureChart'

interface Props {
  isPreview?: boolean
}

export function RealTimeTemperatureChart(props: Props) {
  const { isPreview } = props

  const {
    t,
    items,
    setGeneratingFile,
    generatingFile,
    onOpenModal,
    openModal,
    onCloseModal,
    selectedArea,
    isLoadingTemperature,
  } = useRealTimeTemperatureChart()

  return (
    <Box
      sx={{
        gridArea: 'bottom-left',
        borderRadius: 1,
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'auto',
        overFlowY: 'hidden',
        minHeight: 180,
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        position: 'relative',
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>

      <Stack
        direction={['row']}
        justifyContent={['space-between']}
        alignItems={['center']}
        marginBottom={3}
      >
        <Typography
          variant='h2'
          fontSize='1.125rem'
          fontWeight='bold'
          whiteSpace={['normal', isPreview ? 'normal' : 'nowrap']}
        >
          {t('chart.temperature.realTimeTemperature')}
        </Typography>

        {!isPreview && (
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            spacing={2}
          >
            <Actions
              color='inherit'
              title='button.title.export'
              options={[
                {
                  type: 'excel',
                  action: () =>
                    httpClientDownloadExcel({
                      url: '/dashboard-temperature-and-humidity/temperature/export-excel',
                      fileName: 'temperatura',
                      setGeneratingFile,
                    }),
                },
              ]}
            />
          </Stack>
        )}
      </Stack>

      <Box
        display='flex'
        flexWrap='wrap'
        paddingTop={isPreview ? 1 : 3}
        gap={2}
        sx={{
          justifyContent: 'space-between',
          maxHeight: isPreview ? 180 : 900,
          overflowX: 'auto',
          overFlowY: 'hidden',
          maxWidth: ['100vw', 'calc(100vw - 150px)'],
          '&::-webkit-scrollbar': {
            width: 6,
            height: 6,
            background: 'transparent',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => theme.palette.background.default,
          },
        }}
      >
        {isLoadingTemperature ? (
          <Loading />
        ) : (
          <>
            {!items?.length && (
              <>
                <Stack
                  width={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    mb={3}
                  >
                    <Warning color='disabled' fontSize='large' />
                    <Typography variant='subtitle1'>{t('noData')}</Typography>
                  </Box>
                </Stack>
              </>
            )}
            {items?.map((item, index) => (
              <CircularGraphic
                label={item.name}
                value={item.value}
                color={item.color}
                key={index}
                isPreview={isPreview}
                onClick={() => onOpenModal(item)}
              />
            ))}
          </>
        )}
      </Box>

      <ModalSensorsTemperature
        open={openModal}
        onClose={onCloseModal}
        data={selectedArea}
      />
    </Box>
  )
}
