export function DashboardTemperaturaTempoRealContent() {
  return (
    <>
      <p>
        Nesse gráfico é mostrada a temperatura e umidade em tempo real por áreas
        e setores.
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        temperatura e umidade, caso esse gráfico não esteja disponibilizando o
        valor, será necessário realizar o cadastro dos sensores em Configurações{' '}
        {'->'} Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        temperatura e umidade, caso esse gráfico não esteja disponibilizando o
        valor, será necessário realizar o cadastro dos sensores em Configurações{' '}
        {'->'} Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
