import { array, boolean, object, string } from 'yup'

const validateTime = (value: string, context: any) => {
  const totalMinutes = value
    .padStart(5, '0')
    .split(/\D/g)
    .map(Number)
    .reduce((acumulator, item) => acumulator + item, 0)

  const { iSProgrammingAllHours = true } = context.from?.[1]?.value ?? {}

  return iSProgrammingAllHours ? true : totalMinutes > 0
}

const getValueParsed = (value: string) => {
  return value.split(':').map(Number)
}

const sumHourAndMinute = (value: string) => {
  const [hours = 0, minutes = 0] = getValueParsed(value)
  const minutesInHours = minutes / 60

  return hours + minutesInHours
}

const validateValidTimeInterval = (value: string, { from, options }: any) => {
  const { interval = [] } = from?.[1]?.value ?? {}
  const index = options?.index ?? 0
  const comparisonRange = interval[index - 1]

  if (comparisonRange) {
    const endTime = sumHourAndMinute(comparisonRange.endTime)
    const startTime = sumHourAndMinute(value)

    return startTime > endTime
  }

  return true
}

export const getValidationSchema = () =>
  object({
    iSProgrammingAllHours: boolean(),
    interval: array().of(
      object({
        startTime: string()
          .test({
            name: 'date-valid',
            message: 'validations.required',
            test: (value = '', context) => validateTime(value, context),
          })
          .test({
            name: 'do not allow duplicate time slots',
            message: 'validations.timeRangeNotPermitted',
            test: (value = '', context) => {
              return validateValidTimeInterval(value, context)
            },
          }),
        endTime: string()
          .test({
            name: 'date-valid',
            message: 'validations.required',
            test: (value = '', context) => validateTime(value, context),
          })
          .test({
            name: 'end time is greater than start time',
            message: 'validations.endTimeInvalid',
            test(value = '', context) {
              const endTime = sumHourAndMinute(value)
              const startTime = sumHourAndMinute(context.parent?.startTime)
              const { iSProgrammingAllHours = true } = (context as any).from?.[1]?.value ?? {}

              return iSProgrammingAllHours ? true : endTime > startTime
            },
          }),
        temperature: string()
          .required('validations.required')
          .length(2, () => ({
            key: 'validations.stringLength',
            values: { length: 2 },
          }))
          .test({
            name: 'min-value',
            message: () => ({
              key: 'validations.numberMin',
              values: { min: 1 },
            }),
            test(value = '') {
              const valueParsed = Number(value.replace(/\D/g, ''))
              return valueParsed > 0
            },
          })
          .test({
            name: 'is-number',
            message: 'Valor inválido',
            test(value = '') {
              return value.replace(/\d/gi, '').trim().length === 0
            },
          }),
      }),
    ),
  })
