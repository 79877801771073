import { Box } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { useMonitoringStore } from 'store/monitoring/monitoring.store'

export const BackgroundNodesMonitoring = () => {
  const { maps } = useMonitoringStore()
  const [searchParams] = useSearchParams()

  const mapId = searchParams.get('mapId')
  const currentMap = maps.find((map) => map.entityId.value === mapId)

  return (
    <Box
      component='img'
      src={
        currentMap
          ? `${BASE_URL_IMAGES}${currentMap?.mapImage?.fileName}`
          : undefined
      }
      sx={{
        height: 'auto',
        width: 'auto',
        opacity: 0.6,
      }}
    />
  )
}
