import { Chip, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { Sensor } from 'components/Sensor'
import { SensorOption, SensorTypeMap } from 'types/sensor/sensor'

export function sensorMapper(sensores: SensorTypeMap[]) {
  const { t } = useTranslation('common')

  return sensores.map((sensor: any) => ({
    ...sensor,
    createdAt: dayjs(new Date(sensor?.creation)).format('DD/MM/YYYY') ?? '',
    sensor: sensor?.name ?? '',
    equipment: sensor?.equipment?.name ?? '-',
    type: (
      <>
        <Stack direction='row' width={100} alignItems='center'>
          <Sensor
            icon={sensor.type as SensorOption}
            hiddenTitle={false}
            size='small'
            sx={{ marginRight: 1 }}
          />
          {t(`sensor.${sensor.type}`)}
        </Stack>
      </>
    ),
  }))
}
