/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { formatNumbersDashboard } from 'helpers/formatNumbersDashboard'
import { getMonthDays } from 'helpers/getMonthDays'
import { getWeekDays } from 'helpers/getWeekDays'
import { getAllAreasBySensorType } from 'services/area/getAllAreas'
import { getAllGasAirQuality } from 'services/dashboard/gasDashboard.service'

import { SensorTypeEnum } from '../../../../core/enums/SensorTypeEnum'

interface Props {
  isPreview?: boolean
}

export function useAirQualityChart(props: Props) {
  const { isPreview } = props
  const { t, i18n } = useTranslation('common')
  const queryClient = useQueryClient()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [areaId, setSelectArea] = useState<string | undefined>(undefined)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [airs] = useState<any[]>([
    {
      label: t('chart.gas.carbonMonoxide', { abbreviation: '(CO)' }),
      value: [],
    },
    {
      label: t('chart.gas.nitrogenDioxide', { abbreviation: '(NO2)' }),
      value: [],
    },
    {
      label: t('chart.gas.ammonia', { abbreviation: '(NH3)' }),
      value: [],
    },
    {
      label: t('chart.gas.methane', { abbreviation: '(CH4)' }),
      value: [],
    },
    {
      label: t('chart.gas.propane', { abbreviation: '(C3H8)' }),
      value: [],
    },
    {
      label: t('chart.gas.ethanol', { abbreviation: '(C2H5OH)' }),
      value: [],
    },
    {
      label: t('chart.gas.hydrogen', { abbreviation: '(H2)' }),
      value: [],
    },
    {
      label: t('chart.gas.butane', { abbreviation: '(C4H10)' }),
      value: [],
    },
  ] as any)
  const [date, setDate] = useState<Date | null>(new Date())
  const [period, setPeriod] = useState<PeriodType>('year')

  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data, refetch } = useQuery({
    queryKey: ['dashboardGenerate', endDate, startDate, period, areaId],
    queryFn: () =>
      getAllGasAirQuality(startDate, endDate, period, areaId ?? undefined),
  })

  const { data: areas, refetch: refetchAreas } = useQuery({
    queryKey: 'all-areas-air-quality',
    queryFn: async () => {
      return await getAllAreasBySensorType(SensorTypeEnum.AIR)
    },
    select: (data) => {
      return data?.map((item) => ({
        label: item.name,
        value: String(item.id.value),
      }))
    },
  })

  useEffect(() => {
    if (areas?.length) {
      setSelectArea(areas[0].value)
    }
  }, [areas])

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetch()
      refetchAreas()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return formatNumbersDashboard({
              context,
              suffix: DashboardTooltipSuffixEnum.PARTS_PER_MILLION,
            })
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          display: !isPreview,
          callback: function (value) {
            return value + ' ppm'
          },
        },
      },
      x: {
        ticks: {
          display: !isPreview,
        },
      },
    },
  }

  const getLabels = useCallback(() => {
    queryClient.invalidateQueries('dashboardGenerate')
    airs.forEach((item) => {
      item.value = []
    })
    if (period === 'year') {
      data?.forEach((item: any) => {
        const monthValue = new Array(12).fill(0)
        if (item.valueType === 'CO') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[0].value = monthValue
        }
        if (item.valueType === 'NO2') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[1].value = monthValue
        }
        if (item.valueType === 'NH3') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[2].value = monthValue
        }
        if (item.valueType === 'CH4') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[3].value = monthValue
        }
        if (item.valueType === 'C3H8') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[4].value = monthValue
        }
        if (item.valueType === 'C2H5OH') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[5].value = monthValue
        }
        if (item.valueType === 'H2') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[6].value = monthValue
        }
        if (item.valueType === 'C4H10') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.month) - 1] = gas.value
          })
          airs[7].value = monthValue
        }
      })
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      const monthDays = getMonthDays(date, 'DD')
      data?.forEach((item: any) => {
        const monthValue = new Array(monthDays.length + 1).fill(0)
        if (item.valueType === 'CO') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[0].value = monthValue
        }
        if (item.valueType === 'NO2') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[1].value = monthValue
        }
        if (item.valueType === 'NH3') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[2].value = monthValue
        }
        if (item.valueType === 'CH4') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[3].value = monthValue
        }
        if (item.valueType === 'C3H8') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[4].value = monthValue
        }
        if (item.valueType === 'C2H5OH') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[5].value = monthValue
        }
        if (item.valueType === 'H2') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[6].value = monthValue
        }
        if (item.valueType === 'C4H10') {
          item.gasAirQuality.forEach((gas: any) => {
            monthValue[Number(gas.day) - 1] = gas.value
          })
          airs[7].value = monthValue
        }
      })
      return getMonthDays(date, 'DD')
    }

    return getWeekDays(date, 'DD/MMM')
  }, [period, date, data])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()

    const chartData = {
      labels,
      datasets: airs.map((item, index) => {
        return {
          type: 'line' as const,
          label: item.label,
          data: item.value,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.gas[index > colors.gas.length ? 0 : index],
          ),
          borderColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.gas[index > colors.gas.length ? 0 : index],
          ),
        }
      }),
    }

    setChartData(chartData)
  }, [i18n.language, date, getLabels, airs, areaId])

  return {
    isDesktop,
    date,
    options,
    setDate,
    chartData,
    chartRef,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
    areas,
    setSelectArea,
    areaId,
  }
}
