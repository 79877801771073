import { Tooltip } from 'react-leaflet'

interface IMapTooltipProps {
  children: React.ReactNode
  direction?: 'top' | 'right' | 'bottom' | 'left'
  offset?: [number, number]
  opacity?: number
}

export const MapTooltip = ({
  children,
  direction,
  offset,
  opacity,
}: IMapTooltipProps) => {
  return (
    <>
      <Tooltip
        direction={direction ?? 'bottom'}
        offset={offset ?? [0, 10]}
        opacity={opacity ?? 1}
        className='custom-tooltip'
      >
        {children}
      </Tooltip>
    </>
  )
}
