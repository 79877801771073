/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { STATUS_SCHEDULE } from 'helpers/statusSchedule'
import { ModalType } from 'types/modal'

interface Props extends ModalType {
  id?: string | null
}

export function FormRecuseOSModal({ id, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      justification: '',
    },
  })

  const onSubmit: SubmitHandler<any> = (formData) => {
    //
  }

  return (
    <Modal hiddenTitle open={open} onClose={onClose}>
      <Grid
        container
        spacing={3}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          padding={3}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
          textAlign={'center'}
        >
          <Box
            sx={{
              borderRadius: '50%',
              height: 28,
              width: 28,
              background: (theme) => theme.palette.info.main,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 1,
            }}
          >
            <Icon name={STATUS_SCHEDULE.ICONS.REFUSED} />
          </Box>
          <Typography variant='body1' fontWeight={'bold'}>
            {t('wantDeclineOS')}
          </Typography>
          <Typography variant='body2'>
            {t('wantDeclineOSDescription')}
          </Typography>
        </Stack>
        <Grid item xs={12}>
          <Input
            name='justification'
            label={t('input.addJustification') ?? ''}
            control={control}
            fullWidth
            multiline
            minRows={3}
            error={!!errors?.justification}
            errorMessage={errors?.justification?.message}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <ModalAction onCancel={onClose} isSubmit />
        </Grid>
      </Grid>
    </Modal>
  )
}
