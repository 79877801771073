import { SensorTypeEnum } from '../core/enums/SensorTypeEnum'

export const typesOfSensorsOptions: {
  id: SensorTypeEnum
  name: string
  value: SensorTypeEnum
}[] = [
  { id: SensorTypeEnum.WATER, name: 'Água', value: SensorTypeEnum.WATER },
  {
    id: SensorTypeEnum.ELECTRIC,
    name: 'Energia',
    value: SensorTypeEnum.ELECTRIC,
  },
  { id: SensorTypeEnum.AIR, name: 'Gás', value: SensorTypeEnum.AIR },
  { id: SensorTypeEnum.TEMP, name: 'Temperatura', value: SensorTypeEnum.TEMP },
  {
    id: SensorTypeEnum.TEMP_HUM,
    name: 'Temperatura e umidade',
    value: SensorTypeEnum.TEMP_HUM,
  },
  { id: SensorTypeEnum.HUM, name: 'Umidade', value: SensorTypeEnum.HUM },
  {
    id: SensorTypeEnum.BTN_PANIC,
    name: 'Alerta e Botão de Pânico',
    value: SensorTypeEnum.BTN_PANIC,
  },
  {
    id: SensorTypeEnum.OPENING,
    name: 'Abertura',
    value: SensorTypeEnum.OPENING,
  },
]
