export function DashboardEnergyGrandezaContent() {
  return (
    <>
      <p>
        Nesse gráfico são disponibilizadas as grandezas que estão sendo
        monitoradas por meio dos sensores de energia. Para que seja possível
        realizar o filtro de grandeza:
      </p>
      <p>
        <strong>Passo 1:</strong> Selecione o filtro de setores no filtro da
        direita
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione a Grandeza no filtro do meio
      </p>
      <p>
        Os dados disponibilizados de meta são cadastrados em Configurações{' '}
        {'->'} Metas e Parâmetros {'->'} Metas, selecione Configurações nesse
        tutorial para saber mais.
      </p>
      <p>Selecione a Grandeza no filtro do meio</p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        energia, caso esse gráfico não esteja disponibilizando o valor, será
        necessário realizar o cadastro dos sensores em Configurações {'->'}{' '}
        Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
