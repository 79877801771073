import {
  LocationOn,
  QueryBuilder,
  ListAlt,
  VideocamOutlined,
} from '@mui/icons-material'
import { Dialog, Grid, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { formatDate } from 'pages/btnPanicAlert/hooks/useAlertPanicScreen'
import { linkedAreaService } from 'services/linkedArea/linked-area.service'
import { monitoringOcorrenciaAlertService } from 'services/monitoring-ocorrencia-alert.service'
import { ModalType } from 'types/modal'
import {
  CreateMonitoringOcorrenciaAlertDto,
  HistoryMonitoringOcorrenciaAlertType,
} from 'types/monitoring-ocorrencia-alert'
import moment from 'moment'

type Props = ModalType & {
  data: any | null
  isView?: boolean
}
interface AlertData {
  id: number
  creation: string
  sensor: string
}

type HistoryMonitoringOcorrenciaAlertTypeForm =
  HistoryMonitoringOcorrenciaAlertType & {
    creation?: string
  }

export function FormAlertPanicModal({
  data,
  isView,
  onClose,
  open,
  onSuccess,
}: Props) {
  const { t } = useTranslation('common')
  const [openCam, setOpenCam] = useState(false)
  const [selectedCameraIndex, setSelectedCameraIndex] = useState(null)
  const [description, setDescription] = useState('')
  const [actionTaken, setActionTaken] = useState('')
  const [triggerDate, setTriggerDate] = useState('')


  const fetchDataForSensor = (sensorId: any): AlertData | undefined => {
    const response = useQuery({
      queryKey: ['last-sensors-alert', sensorId],
      refetchOnReconnect: true,
      queryFn: () => {
        if (sensorId) {
          linkedAreaService.getSensorAlertDate(sensorId)
        }
        return undefined
      },
      cacheTime: Infinity,
    })

    return response.data as AlertData | undefined
  }

  const alertData: AlertData | undefined = fetchDataForSensor(
    Number(data?.equipment?.sensors?.[0]?.sensor?.id?.value),
  )

  const { control } = useForm<HistoryMonitoringOcorrenciaAlertTypeForm>({
    defaultValues: {
      description: data?.entityId ? data.description : undefined,
      actionTaken: data?.entityId ? data.actionTaken : undefined,
      linkedAreaID: data?.entityId ? data.findLinkedAreaDTO?.id?.value : '',
      proceduresID: data?.entityId ? data.findProceduresDTO?.id?.value : '',
      name: data?.entityId
        ? data.findLinkedAreaDTO?.equipment?.name
        : data?.equipment?.name
        ? data?.equipment?.name
        : '-',
      nameDescription: data?.entityId
        ? data.findLinkedAreaDTO?.equipment?.model
        : data?.equipment?.model
        ? data?.equipment?.model
        : '-',
      nameArea: data?.entityId
        ? data.findLinkedAreaDTO?.area?.name
        : data?.area?.name
        ? data?.area?.name
        : '-',
      type: data?.entityId ? data.findLinkedAreaDTO?.equipment?.mobile : '',
      triggerDate: data?.equipment?.triggerDate
        ? formatDate(data?.equipment?.triggerDate, false)
        : data?.date
        ? formatDate(data?.date, true)
        : alertData
        ? formatDate(alertData.creation, false)
        : '-',
      creation: data?.creation ? formatDate(data?.creation, true) : undefined,
      procedureDescription: data?.entityId
        ? data.findProceduresDTO?.description
        : data?.equipment?.procedures?.[0]?.procedures?.description
        ? data?.equipment?.procedures?.[0]?.procedures?.description
        : '-',
      cameras: data?.entityId
        ? data.findLinkedAreaDTO?.equipment?.cameras?.map((cam: any) => ({
            ip: cam?.camera?.ip ?? '-',
            mac: cam?.camera?.mac ?? '-',
          })) ?? []
        : data?.equipment?.cameras?.map((cam: any) => ({
            ip: cam?.camera?.ip ?? '-',
            mac: cam?.camera?.mac ?? '-',
          })) ?? [],
    },
  })

  const mutationCreate = useMutation(
    (formData: CreateMonitoringOcorrenciaAlertDto) => {
      formData.description = description
      formData.actionTaken = actionTaken
      formData.triggerDate = data?.equipment?.triggerDate
      ? moment(data?.equipment?.triggerDate).utc(false).format()
      : data?.date
      ? moment(data?.date).utc(false).format()
      : alertData
      ? moment(alertData.creation).utc(false).format()
      : ''

      formData.linkedAreaID = data?.id?.value
      formData.proceduresID =
        data?.equipment?.procedures?.[0]?.procedures?.id?.value

      return monitoringOcorrenciaAlertService.create(formData)
    },
    {
      onError: (error: Error) => {
        toast.error(error?.message)
      },
      onSuccess: () => {
        toast.success('Alerta salvo com sucesso!')
        if (onSuccess) {
          onSuccess()
        }
        onClose()
      },
    },
  )
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value)
  }
  const handleActionTakenChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setActionTaken(event.target.value)
  }
  const handleOpenCam = (index: any) => {
    setSelectedCameraIndex(index)
    setOpenCam(true)
  }
  const handleCloseCam = () => {
    setSelectedCameraIndex(null)
    setOpenCam(false)
  }

  const AlertCam = () => {
    if (selectedCameraIndex !== null && control._defaultValues.cameras) {
      const camera = control._defaultValues.cameras[selectedCameraIndex]
      return (
        <Dialog open={openCam} onClose={handleCloseCam} fullWidth maxWidth='xs'>
          <Stack
            direction='column'
            component='div'
            sx={{ m: 0, p: 3, width: '100%' }}
            spacing={1}
            mb={3}
          >
            <Typography variant='h1' sx={{ fontSize: ['1.125rem', '1.5rem'] }}>
              {`Câmera ${selectedCameraIndex + 1}`}
            </Typography>
            <Typography variant='body1'>
              <strong>IP:</strong> {camera.ip}
            </Typography>
            <Typography variant='body1'>
              <strong>MAC:</strong> {camera.mac}
            </Typography>
          </Stack>
        </Dialog>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Modal
        title={control._defaultValues.name}
        description={control._defaultValues.nameDescription}
        onClose={onClose}
        open={open}
        maxWidth={'md'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack spacing={2}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <LocationOn color={'primary'} />
                <Typography variant={'caption'}>
                  <strong>{t('panicMonitoring.modal.location')}:</strong>{' '}
                  {control._defaultValues.nameArea}
                </Typography>
              </Stack>

              <Stack direction={'row'} mt={2} alignItems={'center'} spacing={1}>
                <QueryBuilder color={'primary'} />
                <Typography variant={'caption'}>
                  <strong>{t('panicMonitoring.modal.activationDate')}:</strong>{' '}
                  {control._defaultValues.triggerDate}
                </Typography>
              </Stack>
              {control._defaultValues.creation && (
                <Stack
                  direction={'row'}
                  mt={2}
                  alignItems={'center'}
                  spacing={1}
                >
                  <QueryBuilder color={'primary'} />
                  <Typography variant={'caption'}>
                    <strong>
                      {t('panicMonitoring.modal.occurrenceDate')}:
                    </strong>{' '}
                    {control._defaultValues.creation}
                  </Typography>
                </Stack>
              )}
              <Stack direction={'row'} mt={2} alignItems={'center'} spacing={1}>
                <ListAlt color={'primary'} />
                <Typography variant={'caption'}>
                  <strong>{t('panicMonitoring.modal.procedure')}:</strong>{' '}
                  {control._defaultValues.procedureDescription}
                </Typography>
              </Stack>
              <Stack direction={'row'} mt={2} alignItems={'center'} spacing={1}>
                {control._defaultValues.cameras &&
                  control._defaultValues.cameras?.map(
                    (cam: any, index: number) => (
                      <Button
                        startIcon={<VideocamOutlined />}
                        variant={'outlined'}
                        color={'primary'}
                        sx={{
                          background: 'transparent',
                          borderColor: (theme) => theme.palette.primary.main,
                          '&:hover': {
                            background: (theme) =>
                              theme.palette.primary.main + '33',
                          },
                        }}
                        onClick={() => handleOpenCam(index)}
                      >
                        {t('panicMonitoring.modal.camera')} {index + 1}
                      </Button>
                    ),
                  )}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Input
              name={'description'}
              label={t('panicMonitoring.modal.ocorrenciaDescription')}
              multiline
              rows={4}
              value={control._defaultValues.description}
              onChange={handleDescriptionChange}
              disabled={control._defaultValues.description !== '' && isView}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name={'name'}
              label={t('panicMonitoring.modal.actionTaken')}
              multiline
              rows={4}
              value={control._defaultValues.actionTaken}
              onChange={handleActionTakenChange}
              disabled={control._defaultValues.actionTaken !== '' && isView}
              fullWidth
            />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ModalAction
                  onCancel={onClose}
                  onSaveMutation={() => {
                    const formData = {
                      description: '',
                      actionTaken: '',
                      triggerDate: '',
                      linkedAreaID: '',
                      proceduresID: '',
                    }
                    mutationCreate.mutate(formData)
                  }}
                  isSubmit={!isView}
                  isLoading={mutationCreate.isLoading}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Modal>
      <AlertCam />
    </>
  )
}
