import { IconButton } from 'components/Button/IconButton'

export function UsersContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros dos usuários de acesso ao
        sistema. Será por meio dele que será possível acessar ao sistema. Caso
        esse submódulo não apareça no seu sistema atualmente, provavelmente o
        seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>

      <p>
        <strong>Cadastrar Usuário</strong>
      </p>

      <p>
        O módulo de Usuário pode ser acessado apenas pelos administradores do
        sistema ou pessoas autorizadas. Nesse módulo são criados os usuários que
        podem ter acesso ao sistema.,
      </p>
      <p>Para realizar o cadastro de um novo usuário, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Usuário
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 5:</strong> Preencher os campos que são: seleção de
        perfil, status do usuário, nome, cargo, e-mail, contato e o código RFID
        caso este tenha acesso a alguns locais da empresa.{' '}
      </p>
      <p>
        <strong>Passo 6:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        Importante: O e-mail e contato são campos onde é necessário um cuidado
        dobrado ao serem preenchidos, pois é por meio deles que os alertas,
        notificações e validações do sistema são realizados.{' '}
      </p>
      <p>
        Ao salvar o novo usuário, este receberá um e-mail e um código de acesso,
        que é uma senha temporária. Em seguida, o usuário deverá acessar ao
        sistema.
      </p>
      <p>
        <strong>Editar Usuário</strong>
      </p>

      <p>Para realizar a edição de um usuário, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Usuário
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o usuário que vai editar dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 6:</strong> Edite os campos que aparecerão: seleção de
        perfil, status do usuário, nome, cargo, e-mail, contato e o código RFID
        caso este tenha acesso a alguns locais da empresa.{' '}
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar .{' '}
      </p>
      <p>
        Importante: O e-mail e contato são campos onde é necessário um cuidado
        dobrado ao serem editado, pois é por meio deles que os alertas,
        notificações e validações do sistema são realizados.{' '}
      </p>
      <p>
        <strong>Excluir Usuário</strong>
      </p>

      <p>Para realizar a exclusão de um usuário, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Usuário
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o usuário que vai excluir dentro da
        listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-lo, selecione sim e o usuário não poderá ter maios acesso ao
        sistema, e será permanentemente excluído do sistema.
      </p>
      <p>
        <strong>Visualizar usuário</strong>
      </p>

      <p>Para realizar visualizar os dados de um usuário, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Controle de Acesso
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Usuário
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar o usuário que vai visualizar dentro
        da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, sendo eles: seleção de perfil, status do usuário, nome,
        cargo, e-mail, contato e o código RFID. Esses dados serão mostrados sem
        poder realizar edição neles.
      </p>
    </>
  )
}
