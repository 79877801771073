/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { forgotPassword } from 'services/authentication/forgotPassword'
import { toast } from 'react-toastify'

export function useForgotPassword() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [emailSent, setEmailSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  })
  const mutation = useMutation(
    (payload: any) => {
      return forgotPassword(payload)
    },
    {
      onSuccess: () => {
        toast.success('Email enviado com sucesso!')
        setEmailSent(true)
      },
      onError: () => {
        toast.error('Erro ao enviar email!')
      },
    },
  )

  const onSubmit: SubmitHandler<{ email: string }> = (formData) => {
    setIsSubmitting(true)
    mutation.mutate(formData)

    setIsSubmitting(false)
  }

  return {
    control,
    handleSubmit,
    onSubmit,
    errors,
    t,
    navigate,
    emailSent,
    isSubmitting,
  }
}
