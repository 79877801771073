enum ElectricalQuantitiesEnum {
  A_L1 = 'Corrente l1',
  A_L2 = 'Corrente l2',
  A_L3 = 'Corrente l3',
  A_SYS = 'Corrente total',
  AN = 'Corrente de neutro',
  V_L1_L2 = 'Tensao l1 e l2',
  V_L1_N = 'Tensao l1',
  V_L2_L3 = 'Tensao l2 l3',
  V_L2_N = 'Tensao l2',
  V_L3_L1 = 'Tensao l3 l1',
  V_L3_N = 'Tensao l3',
  V_L_L_SYS = 'Tensao média linha',
  V_L_N_SYS = 'Tensao média fase',
  KW_L1 = 'Potência Ativa l1',
  KW_L2 = 'Potência Ativa l2',
  KW_L3 = 'Potência Ativa l3',
  KW_SYS = 'Potência Ativa total',
  KW_DMD = 'Potência Ativa dmd',
  KVAR_L1 = 'Potência Reativa l1',
  KVAR_L2 = 'Potência Reativa l2',
  KVAR_L3 = 'Potência Reativa l3',
  KVAR_SYS = 'Potência Reativa total',
  KVA_L1 = 'Potência aparente l1',
  KVA_L2 = 'Potência aparente l2',
  KVA_L3 = 'Potência aparente l3',
  KVA_SYS = 'Potência aparente total',
  KVAH = 'Energia aparente',
  KWH_L1 = 'Energia ativa l1',
  KWH_L2 = 'Energia ativa l2',
  KWH_L3 = 'Energia ativa l3',
  KWH = 'Energia ativa',
  KWH_ = 'Energia ativa gerada',
  KVARH = 'Energia reativa',
  KVARH_ = 'Energia reativa gerada',
  HZ = 'Frequencia',
  PS = 'Sequencia fase',
  PHASE_SEQUENCE = 'Sequencia de fase',
  PF_L1 = 'Fator de Potência l1',
  PF_L2 = 'Fator de Potência l2',
  PF_L3 = 'Fator de Potência l3',
  PF_SYS = 'Fator de Potência Média',

  THD_A_L1 = 'Distorcao harmonica de corrente l1',
  THD_A_L2 = 'Distorcao harmonica de corrente l2',
  THD_A_L3 = 'Distorcao harmonica de corrente l3',

  THD_V_L1_N = 'Distorcao harmonica de tensao l1',
  THD_V_L2_N = 'Distorcao harmonica de tensao l2',
  THD_V_L3_N = 'Distorcao harmonica de tensao l3',
}

const findQuantity = (value: string) => {
  return ElectricalQuantitiesEnum[
    value as keyof typeof ElectricalQuantitiesEnum
  ]
}
const getKeyByIndex = (index: number) => {
  return Object.keys(ElectricalQuantitiesEnum)[index]
}

const getValueByIndex = (index: number) => {
  return Object.values(ElectricalQuantitiesEnum)[index]
}

function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

const length = Object.keys(ElectricalQuantitiesEnum).length

export {
  ElectricalQuantitiesEnum,
  findQuantity,
  length,
  getValueByIndex,
  getKeyByIndex,
  getKeyByValue
}
