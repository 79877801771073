import { httpClient } from 'services/api/httpClient'
import { AreaType } from 'types/area/area'

import { SensorTypeEnum } from '../../core/enums/SensorTypeEnum'

type ResponseType = AreaType & {
  id: number
}

export const getAllAreas = async (): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('area', {
    params: {
      page: 0,
      numberPerPage: 1000,
    },
  })

  return (
    data?.items?.map((item: AreaType) => ({
      ...item,
      id: typeof item?.id === 'object' ? item?.id?.value : item?.id,
    })) ?? []
  )
}

export const getAllAreasBySensorType = async (
  type: SensorTypeEnum,
): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('area/sensor', {
    params: {
      type,
    },
  })

  return data
}

export const getAllAreasBySensorTempHum = async (): Promise<ResponseType[]> => {
  const { data } = await httpClient.get('area/sensor-temp-hum')

  return data
}
