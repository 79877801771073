import { Grid, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Edge, Node } from 'reactflow'

import { Modal } from 'components/Modal'
import { typeEquipmentOrArCon, typeEquipmentOrArConLabel } from 'constants/maps'
import { useMapStore } from 'store/map.store'
import { AddElementType } from 'types/area/create-linked-area'
import { ModalType } from 'types/modal'

import {
  ListAirConditioning,
  ListEquipment,
  ListFeeder,
  ListSensor,
} from './components'

type NodesType = { nodes: Node<any, string | undefined>[] }
interface Props extends ModalType {
  data: {
    dataSubarea: any
    type: typeEquipmentOrArCon
  }
  onClick: (data: AddElementType) => void
  nodes: Node<any, string | undefined>[]
  edges: Edge<any>[] | undefined
  handleSave?: (nodes: NodesType) => void
}

export const ListModal = ({ open, onClose, data, nodes, onClick }: Props) => {
  const { currentMap, isCloseModal } = useMapStore()

  const componentList = {
    [typeEquipmentOrArCon.EQUIPMENT]: {
      component: ListEquipment,
    },
    [typeEquipmentOrArCon.FEEDER]: {
      component: ListFeeder,
    },
    [typeEquipmentOrArCon.AIR_CONDITIONING]: {
      component: ListAirConditioning,
    },
    [typeEquipmentOrArCon.SENSOR]: {
      component: ListSensor,
    },
    [typeEquipmentOrArCon.AIR_CONDITIONING_SENSOR]: {
      component: ListSensor,
    },
    [typeEquipmentOrArCon.BTN_PANIC]: {
      component: ListSensor,
    },
  }

  const Component = data.type && componentList[data.type].component

  useEffect(() => {
    if (isCloseModal) {
      onClose()
    }
  }, [isCloseModal])

  return (
    <>
      <Modal
        title={`Pesquise e selecione um ${
          typeEquipmentOrArConLabel[typeEquipmentOrArCon[data.type]]
        }`}
        icon='timeLineSvg'
        open={open}
        onClose={onClose}
        maxWidth='xl'
        sx={{
          width: '100%',
          zIndex: 5,
        }}
      >
        <Stack direction='row' justifyContent='space-between' mb={2}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
          >
            <Typography
              sx={{
                background: (theme) => theme.palette.background.paper,
                px: 1.5,
                borderRadius: 1,
                font: 'normal normal normal 12px/20px Noto Sans',
                mr: 1,
              }}
            >
              {'SELECIONAR TIPO'}
            </Typography>
            {' > '}
            <Typography
              sx={{
                background: (theme) => theme.palette.primary.dark,
                px: 1.5,
                borderRadius: 1,
                font: 'normal normal normal 12px/20px Noto Sans',
                ml: 1,
              }}
            >
              {typeEquipmentOrArConLabel[
                typeEquipmentOrArCon[
                  data?.type as keyof typeof typeEquipmentOrArCon
                ] as typeEquipmentOrArCon
              ].toUpperCase()}
            </Typography>
          </Stack>

          <Grid
            sx={{
              background: (theme) => theme.palette.background.paper,
              px: 1.5,
              borderRadius: 1,
              font: 'normal normal normal 12px/20px Noto Sans',
            }}
          >
            {`${currentMap?.nameArea.toUpperCase()} - ${data.dataSubarea.nomeSubArea.toUpperCase()}`}
          </Grid>
        </Stack>

        <Stack
          direction='column'
          alignItems='flex-start'
          justifyContent='space-between'
          spacing={2}
        >
          <Component
            data={data}
            type={data.type}
            nodes={nodes}
            onClick={onClick}
          />
        </Stack>
      </Modal>
    </>
  )
}
