import { Box } from '@mui/material'
import { ReactNode } from 'react'

import { ModeType } from 'types/mode'

interface Props {
  mode: ModeType
  type: ModeType
  children?: ReactNode
}

export function ModeContainer(props: Props) {
  const { mode, type, children } = props
  return (
    <>
      {mode === 'grid' && type === mode && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr'],
            gridTemplateAreas: [
              '"bottom-left""bottom-right""top""medium""heatmap""drybox"',
              '"bottom-left""bottom-right""top""medium""drybox"',
            ],
            gap: 3,
            '& #groupAirQuality': {
              flexWrap: ['nowrap', 'wrap'],
            },
          }}
        >
          {children}
        </Box>
      )}

      {mode === 'presentation' && type === mode && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 3,
            '& #groupAirQuality': {
              flexWrap: 'nowrap',
            },
          }}
        >
          {children}
        </Box>
      )}
    </>
  )
}
