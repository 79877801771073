import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const SubareaWrapper = styled('div')({
  position: 'relative',
  flex: 1,
})

export const SubareaRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  marginBottom: 4,
  '&:hover': {
    outline: `1px solid ${theme.palette.primary.main}`,
  },
}))
