import dayjs from 'dayjs'

import { httpClient } from 'services/api/httpClient'

export const listOrderServicePeriod = async (
  data: Date,
  periodType: string,
) => {
  let dtIni = dayjs(data)
  let dtFim = dayjs(data)
  const type = periodType as dayjs.OpUnitType

  if (!periodType.includes('day')) {
    dtIni = dayjs(data).startOf(type)
    dtFim = dayjs(data).endOf(type)
  }

  const response = await httpClient.get('/order-service/findperiod', {
    params: {
      dtIni: dtIni.format('YYYY-MM-DD'),
      dtFim: dtFim.format('YYYY-MM-DD'),
    },
  })
  return response.data
}
