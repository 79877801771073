import { Grid } from '@mui/material'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Input } from 'components/Input'
import { Loading } from 'components/Loading'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { getAllAreas } from 'services/area/getAllAreas'
import { createMeasuringArea } from 'services/measuring-area/createMeasuringArea'
import { updateMeasuringArea } from 'services/measuring-area/updateMeasuringArea'
import { shiftService } from 'services/shift.service'
import { CreateMeasuringAreaType } from 'types/measuring-area/create-measuringArea'
import { MeasuringAreaType } from 'types/measuring-area/measuringArea'
import { ModalType } from 'types/modal'

import { FormAreaModal } from './FormAreaModal'

interface Props extends ModalType {
  data: MeasuringAreaType | null
  isView?: boolean
}

export function FormMeasuringAreaModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const [selectedAreas, setSelectedAreas] = useState<
    Array<{ id: number; name: string }>
  >(
    data?.subAreas?.map((area) => ({
      name: area?.name,
      id: Number(area?.id?.value),
    })) ?? [],
  )
  const [openForm, setOpenForm] = useState(false)

  const {
    data: areas,
    isLoading: isLoadingAreas,
    refetch,
  } = useQuery('areas', () => getAllAreas(), {
    refetchOnWindowFocus: false,
  })

  const { data: shifts, isLoading: isLoadingShifts } = useQuery(
    'shifts-all',
    () => shiftService.list(),
    {
      refetchOnWindowFocus: false,
    },
  )

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CreateMeasuringAreaType>({
    defaultValues: {
      name: data?.name ?? '',
      shift: data?.shift?.entityId?.value
        ? data?.shift?.entityId?.value
        : undefined,
    },
  })

  const mutationCreate = useMutation(
    (newSensor: CreateMeasuringAreaType) => {
      return createMeasuringArea({
        shift: String(newSensor.shift),
        name: newSensor.name,
        subAreas: selectedAreas?.map((item) => String(item.id)) ?? [],
      })
    },
    {
      onSuccess: () => {
        toast.success(t('savedInformation'))
        reset()
        queryClient.invalidateQueries('measuring-areas')
        queryClient.invalidateQueries('all-areas')
        queryClient.invalidateQueries('all-area')
        onClose()
      },
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (newSensor: CreateMeasuringAreaType) => {
      return updateMeasuringArea(data?.id?.value ?? 0, {
        shift: String(newSensor.shift),
        name: newSensor.name,
        subAreas: selectedAreas?.map((item) => String(item.id)) ?? [],
      })
    },
    {
      onSuccess: () => {
        toast.success(t('savedInformation'))
        reset()
        queryClient.invalidateQueries('measuring-areas')
        queryClient.invalidateQueries('all-areas')
        queryClient.invalidateQueries('temperature-find-all-last-hour')
        queryClient.invalidateQueries('humidity-find-all-last-hour')
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'areas',
        })
        onClose()
      },
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateMeasuringAreaType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const handleSelectArea = () => {
    const findArea = areas?.find(
      (item) => String(item.id) === String(getValues('area')),
    )

    if (!findArea) return

    const alreadySelected = selectedAreas?.find(
      (item) => item.id === findArea.id,
    )

    if (alreadySelected) return

    setSelectedAreas((state) => [...state, findArea])
  }

  const title = data?.id ? t('area.edit') : t('area.new')
  // const title = data?.id ? t('measuringArea.edit') : t('measuringArea.new')
  return (
    <>
      <Modal
        maxWidth='sm'
        title={isView ? String(t('area.view')) : title}
        // title={isView ? String(t('measuringArea.view')) : title}
        open={open}
        onClose={onClose}
      >
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          {(isLoadingAreas || isLoadingShifts) && (
            <Grid item xs={12}>
              <Loading />
            </Grid>
          )}
          {!isLoadingAreas && !isLoadingShifts && (
            <>
              {/* <Grid item xs={12}>
                <Typography>{t('measuringArea.newDescription')}</Typography>
              </Grid> */}
              <Grid item xs={12}>
                <Input
                  name='name'
                  label={t('input.name') ?? ''}
                  control={control}
                  fullWidth
                  error={!!errors?.name}
                  errorMessage={errors?.name?.message}
                  required
                  disabled={isView}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name='shift'
                  label={t('input.shift')}
                  options={shifts}
                  control={control}
                  fullWidth
                  error={!!errors?.shift}
                  errorMessage={errors?.shift?.message}
                  required
                  disabled={isView}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Stack direction={'row'} spacing={3}>
                  <Select
                    name='area'
                    label={t('input.area')}
                    options={areas}
                    control={control}
                    fullWidth
                    error={!!errors?.shift}
                    errorMessage={errors?.shift?.message}
                    onChange={(event) => {
                      setValue('area', event.target.value as number)
                      handleSelectArea()
                    }}
                  />
                  {!isView && (
                    <IconButton
                      iconName='add'
                      variant='contained'
                      color='secondary'
                      title={t('button.title.add') ?? ''}
                      onClick={() => setOpenForm(true)}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {selectedAreas.map((item, index) => (
                  <Stack
                    key={index}
                    direction={'row'}
                    spacing={3}
                    justifyContent='space-between'
                    alignItems={'center'}
                    width='100%'
                    marginBottom={1}
                  >
                    <Typography>{item.name}</Typography>
                    <IconButton
                      iconName='delete'
                      variant='contained'
                      color='secondary'
                      onClick={() =>
                        setSelectedAreas((state) =>
                          state.filter((area) => area.id !== item.id),
                        )
                      }
                    />
                  </Stack>
                ))}
              </Grid> */}

              {!isView && (
                <Grid item xs={12}>
                  <ModalAction
                    onCancel={onClose}
                    isSubmit
                    disabledSubmit={
                      mutationCreate.isLoading || mutationUpdate.isLoading
                    }
                    isLoading={
                      mutationCreate.isLoading || mutationUpdate.isLoading
                    }
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Modal>

      {openForm && (
        <FormAreaModal
          open={openForm}
          onClose={() => {
            refetch()
            setOpenForm(false)
          }}
        />
      )}
    </>
  )
}
