import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

import { ReactComponent as OpeningIcon } from 'assets/icons/sensor-icons/abertura-svg.svg'
import { ReactComponent as BtnPanicIcon } from 'assets/icons/sensor-icons/btn-panic-svg.svg'
import { ReactComponent as EnergyIcon } from 'assets/icons/sensor-icons/energy-icon.svg'
import { ReactComponent as GasIcon } from 'assets/icons/sensor-icons/gas.svg'
import { ReactComponent as HumidityIcon } from 'assets/icons/sensor-icons/humidity-icon.svg'
import { ReactComponent as TemperatureHumidityIcon } from 'assets/icons/sensor-icons/temperature_humidity.svg'
import { ReactComponent as TemperatureIcon } from 'assets/icons/sensor-icons/temperature-icon.svg'
import { ReactComponent as WaterIcon } from 'assets/icons/sensor-icons/water.svg'

export const getDescriptionBySensorType = (type: SensorTypeEnum) => {
  const descriptions = {
    [SensorTypeEnum.ELECTRIC]: 'Energia',
    [SensorTypeEnum.TEMP]: 'Temperatura',
    [SensorTypeEnum.HUM]: 'Umidade',
    [SensorTypeEnum.WATER]: 'Água',
    [SensorTypeEnum.AIR]: 'Gás',
    [SensorTypeEnum.TEMP_HUM]: 'Temp e umid',
    [SensorTypeEnum.ALL]: 'Todos',
    [SensorTypeEnum.OPENING]: 'Abertura',
    [SensorTypeEnum.BTN_PANIC]: 'Alerta e Botão de Pânico',
  }

  return descriptions[type]
}

export const getIconBySensorType = (type: SensorTypeEnum) => {
  const icons = {
    [SensorTypeEnum.ELECTRIC]: EnergyIcon,
    [SensorTypeEnum.ALL]: TemperatureIcon,
    [SensorTypeEnum.TEMP]: TemperatureIcon,
    [SensorTypeEnum.HUM]: HumidityIcon,
    [SensorTypeEnum.WATER]: WaterIcon,
    [SensorTypeEnum.AIR]: GasIcon,
    [SensorTypeEnum.TEMP_HUM]: TemperatureHumidityIcon,
    [SensorTypeEnum.BTN_PANIC]: BtnPanicIcon,
    [SensorTypeEnum.OPENING]: OpeningIcon,
  }

  return icons[type]
}
