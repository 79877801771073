import * as yup from 'yup'

const REQUIRED_MESSAGE = 'validations.required'

function validateModule(modules: any) {
  return modules.some(
    (module: any) =>
      module.value === 'BTN_PROCEDURE' || module.value === 'SENSOR_DOOR',
  )
}

export const schemaCreateNotification = () => {
  return yup.object({
    name: yup.string().required(REQUIRED_MESSAGE),
  })
}

export const schemaCreateNotificationUser = () => {
  return yup.object({
    message: yup.string().required(REQUIRED_MESSAGE),
    typeNotification: yup
      .array()
      .of(yup.string())
      .nullable()
      .min(1, REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
    users: yup.array().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    modules: yup.array().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    sensors: yup.array().when('modules', {
      is: (modules: any) => validateModule(modules),
      then: yup.array().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      otherwise: yup.array().nullable().notRequired(),
    }),
    notificationAction: yup.array().when('modules', {
      is: (modules: any) => validateModule(modules),
      then: yup.array().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      otherwise: yup.array().nullable().notRequired(),
    }),
  })
}
