/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Toggle } from 'components/Toggle'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { createProcedures } from 'services/procedures/createProcedures'
import { updateProcedures } from 'services/procedures/updateProcedures'
import { ModalType } from 'types/modal'
import { CreateProceduresType } from 'types/procedures/create-procedures'

import { schemaCreateProcedure } from '../validations/create-procedure.validation'

interface Props extends ModalType {
  data: any | null
  isView?: boolean
}

export function FormProcedureModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CreateProceduresType>({
    resolver: yupResolver(schemaCreateProcedure()),
    defaultValues: {
      name: data?.name ?? '',
      description: data?.description ?? '',
      status: data?.status ?? true,
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('procedures')
    queryClient.invalidateQueries('all-procedures')
    onClose()
  }

  const mutationCreate = useMutation(
    (newProcedures: CreateProceduresType) => {
      return createProcedures({
        name: newProcedures.name,
        description: newProcedures.description,
      })
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const mutationUpdate = useMutation(
    (newProcedures: CreateProceduresType) => {
      return updateProcedures(
        data?.id ?? 0,
        newProcedures?.file
          ? {
              name: newProcedures.name,
              description: newProcedures.description,
              file: newProcedures.file,
              idfile: data?.file?.id?.value,
            }
          : {
              name: newProcedures.name,
              description: newProcedures.description,
            },
      )
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        getErrorMessage(error)
      },
    },
  )

  const onSubmit: SubmitHandler<CreateProceduresType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('procedures.view')

    if (data?.id) return t('procedures.edit')

    return t('procedures.new')
  }

  const title = getTitle()

  return (
    <>
      <Modal title={title} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Grid item xs={12} sm={isView ? 8 : 12}>
            <Input
              name='name'
              label={t('input.name') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.name}
              errorMessage={errors?.name?.message}
              required
              isDisabled={isView}
            />
          </Grid>

          {isView && (
            <Grid item xs={12} sm={4}>
              <Toggle name={'status'} checked={data.status} disabled={true} />
            </Grid>
          )}

          <Grid item xs={8} sm={12}>
            <Input
              name='description'
              label={t('input.description') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.description}
              errorMessage={errors?.description?.message}
              required
              isDisabled={isView}
              multiline
              minRows={3}
            />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
