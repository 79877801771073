import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Icon, IconNamesProps } from 'components/Icon'

import { IconButton } from '../Button/IconButton'
interface Props extends Omit<DialogProps, 'title'> {
  title?: string | null
  description?: string | null
  fullWidth?: boolean
  hiddenTitle?: boolean
  showIconClose?: boolean
  onClose: (event: object, reason: 'backdropClick' | 'escapeKeyDown') => void
  children: React.ReactNode
  goBack?: () => void
  contentSx?: SxProps
  icon?: IconNamesProps
}

export function Modal({
  title,
  description,
  fullWidth = true,
  maxWidth = 'sm',
  open,
  onClose,
  showIconClose = false,
  hiddenTitle = false,
  children,
  goBack,
  icon,
  ...rest
}: Props) {
  const { t } = useTranslation('common')
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        // onClose={onClose}
        {...rest}
        sx={{
          ...rest?.sx,
        }}
      >
        {!hiddenTitle && (
          <Stack
            direction='row'
            width={icon ? '100%' : 'initial'}
            component='div'
            sx={{ m: 0, p: 3, pb: 0 }}
          >
            {icon ? (
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ width: '100%' }}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  // justifyContent='space-between'
                  spacing={2}
                >
                  <IconButton
                    variant='contained'
                    iconName='chevronLeft'
                    onClick={() =>
                      goBack ? goBack() : onClose({}, 'escapeKeyDown')
                    }
                  />
                  <Stack direction='column' spacing={0}>
                    <Typography
                      variant='h1'
                      sx={{ fontSize: ['1.125rem', '1.5rem'] }}
                    >
                      {title ?? t('modal.title')}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      sx={{ fontSize: ['0.75rem', '1rem'] }}
                    >
                      {description}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='center'
                  sx={{ mt: 2 }}
                >
                  <Icon name={icon} />
                </Stack>
              </Stack>
            ) : (
              <Stack direction='row' alignItems='center' spacing={2}>
                <IconButton
                  variant='contained'
                  iconName='chevronLeft'
                  onClick={() =>
                    goBack ? goBack() : onClose({}, 'escapeKeyDown')
                  }
                />
                <Stack direction='column' spacing={0}>
                  <Typography
                    variant='h1'
                    sx={{ fontSize: ['1.125rem', '1.5rem'] }}
                  >
                    {title ?? t('modal.title')}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontSize: ['0.75rem', '1rem'] }}
                  >
                    {description}
                  </Typography>
                </Stack>
              </Stack>
            )}

            {showIconClose ? (
              <Box>
                <IconButton
                  aria-label='close'
                  onClick={() => onClose({}, 'escapeKeyDown')}
                  iconName='close'
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    minWidth: 44,
                    minHeight: 44,
                  }}
                />
              </Box>
            ) : null}
          </Stack>
        )}
        <DialogContent
          sx={{
            paddingTop: 3,
            '&::-webkit-scrollbar': {
              width: 6,
              height: 6,
              background: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: (theme) => theme.palette.background.default,
            },
            ...rest.contentSx,
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </>
  )
}
