import { ForwardRefRenderFunction, useImperativeHandle, useState, forwardRef } from 'react';
import { CircularProgress } from '@mui/material'

export interface ILoadingMinus {
  setShow: (value: boolean) => void
}

const LoadingMinus: ForwardRefRenderFunction<ILoadingMinus, any> = (props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    setShow
  }))

  if (!show) return <></>

  return (
    <div style={{ position: 'absolute', right: '10px', bottom: '10px', zIndex: 99999  }}>
      <CircularProgress aria-label='circularProgress' size={30} thickness={2} />
    </div>
  )
}

export default forwardRef(LoadingMinus)
