import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { STATUS } from 'helpers/status'
import { StatusScheduleType } from 'types/status-schedule'

interface Props {
  type: StatusScheduleType | string
}

export function Status({ type }: Props) {
  const { t } = useTranslation('common')
  return (
    <Chip
      label={t(`status.${type.toLowerCase()}`)}
      color='default'
      variant='filled'
      size='small'
      sx={{
        '& span': {
          fontWeight: 'normal',
          textTransform: 'capitalize ',
        },
        '&::before': {
          backgroundColor:
            STATUS.COLORS[type.toUpperCase() as StatusScheduleType],
        },
        color: STATUS.COLORS[type.toUpperCase() as StatusScheduleType],
        backgroundColor:
          STATUS.COLORS_ALPHA[type.toUpperCase() as StatusScheduleType],
      }}
    />
  )
}
