import { yupResolver } from '@hookform/resolvers/yup'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandMore } from '@mui/icons-material'
import { AccordionDetails, Grid, Stack, Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { AccordionSummary } from 'components/Accordion/styles'
import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Checkbox } from 'components/Checkbox'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { uuid } from 'helpers/uuid'
import { schemaShift } from 'pages/map/validations/shift.validation'
import { shiftService } from 'services/shift.service'
import { ModalType } from 'types/modal'
import { CreateIntervalType } from 'types/shift/create-interval'
import { CreateShiftType } from 'types/shift/create-shift'
import { ShiftType } from 'types/shift/shift'
import { ShiftFormType } from 'types/shift/shift-form'
import { UpdateShiftType } from 'types/shift/update-shift'

import { FormRegisterIntervalModal } from './FormRegisterIntervalModal'

interface Props extends ModalType {
  data: ShiftType | null
  isView?: boolean
}
export function FormShiftModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const [openRegisterIntervals, setOpenRegisterIntervals] = useState<
    string | boolean
  >(false)

  const isExists = (id: number) => {
    return data?.days?.some((item) => Number(item.entityId?.value) === id)
  }
  const getInterval = (id: number) => {
    const interval: any[] = []
    data?.days
      .find((item) => Number(item.entityId?.value) === id)
      ?.intervals?.forEach((item) => {
        interval.push({
          _id: uuid(),
          name: item.name,
          hourInitial: item.hourInitial,
          hourFinal: item.hourFinal,
        })
      })
    return interval
  }
  const getDaysWeek = () => {
    const daysWeek = []
    for (let i = 0; i < 7; i++) {
      daysWeek.push({
        entityId: i + 1,
        name: t(`weekDays.${i}`),
        hourInitial:
          data?.days.find((item) => Number(item.entityId?.value) === i + 1)
            ?.hourInitial ?? '00:00',
        hourFinal:
          data?.days.find((item) => Number(item.entityId?.value) === i + 1)
            ?.hourFinal ?? '00:00',
        checked: isExists(i + 1),
        intervals: getInterval(i + 1),
      })
    }
    return daysWeek
  }
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ShiftFormType>({
    resolver: yupResolver(schemaShift()),
    defaultValues: {
      name: '',
      weekDays: [],
    },
  })

  const mutationCreate = useMutation(
    (data: CreateShiftType) => {
      return shiftService.create(data)
    },
    {
      onSuccess: () => {
        toast.success(t('alert.success'))
        reset()
        queryClient.invalidateQueries('shifts-all')
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === 'shifts' ||
            query.queryKey[0] === 'shifts-list',
        })
        onClose()
      },
      onError(error: any) {
        toast.error(error?.response?.data?.errors?.[0]?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (date: UpdateShiftType) => {
      return shiftService.update(Number(data?.entityId?.value), date)
    },
    {
      onSuccess: () => {
        toast.success(t('alert.success'))
        reset()
        queryClient.invalidateQueries('shifts')
        queryClient.invalidateQueries('shifts-all')
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'shifts',
        })
        onClose()
      },
      onError(error: any) {
        toast.error(error?.response?.data?.errors?.[0]?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<ShiftFormType> = (formData) => {
    let validateWeekDays = true

    formData.weekDays.forEach((item) => {
      const formatoHora = 'HH:mm'

      // Parse as horas usando Moment.js
      const horaInicial = moment(item.hourInitial, formatoHora)
      const horaFinal = moment(item.hourFinal, formatoHora)

      // Verifique se a hora final é maior que a hora inicial
      if (horaInicial.isAfter(horaFinal)) {
        validateWeekDays = false
      } else {
        item.intervals?.forEach((interval) => {
          const intervalHoraInicial = moment(interval.hourInitial, formatoHora)
          const intervalHoraFinal = moment(interval.hourFinal, formatoHora)

          if (intervalHoraInicial.isAfter(intervalHoraFinal)) {
            validateWeekDays = false
          }
        })
      }
    })

    if (!validateWeekDays) {
      toast.warn(t('validateWeekDays'))
      return
    }

    if (!data?.entityId?.value) {
      mutationCreate.mutate(arrangeValues(formData))
      return
    }
    mutationUpdate.mutate(arrangeValues(formData))
  }
  const title = data?.entityId?.value ? t('shift.edit') : t('shift.new')
  const arrangeValues = (values: ShiftFormType) => {
    const { name } = values
    const result: CreateShiftType = {
      name,
      days: getChecked(values),
    }
    return result
  }
  const getIntervals = (
    value?: Array<{
      name: string
      hourInitial: string
      hourFinal: string
      _id?: string
    }>,
  ) => {
    return value?.map((item) => {
      return {
        name: item.name,
        hourInitial: item.hourInitial,
        hourFinal: item.hourFinal,
      }
    })
  }
  const getChecked = (value: ShiftFormType) => {
    const { weekDays } = value
    const newArray = weekDays?.filter((item) => item.checked)
    return newArray.map((item) => {
      return {
        entityId: Number(item.entityId),
        hourInitial: item.hourInitial,
        hourFinal: item.hourFinal,
        intervals: getIntervals(item.intervals),
      }
    })
  }

  const handleIntervals = (intervalsData: CreateIntervalType) => {
    setOpenRegisterIntervals(false)

    const parseWeekDays = getValues('weekDays')?.map((item) =>
      intervalsData.repeat?.find((repeatDay) => repeatDay.value === item.name)
        ? {
            ...item,
            hasInterval: true,
            checked: true,
            intervals: intervalsData.intervals,
          }
        : item,
    )
    setValue('weekDays', parseWeekDays)
  }

  const handleRemoveInterval = (currentDay: string, intervalId?: string) => {
    const getWeekDays = getValues('weekDays')

    const filterWeekDaysInterval = getWeekDays.map((item) => {
      if (item.name === currentDay) {
        const filterIntervals = item?.intervals?.filter(
          (interval) => interval?._id !== intervalId,
        )
        return {
          ...item,
          hasInterval: !!filterIntervals?.length,
          intervals: filterIntervals ?? [],
        }
      }
      return item
    })

    setValue('weekDays', filterWeekDaysInterval)
  }

  const handleAddInterval = (currentDay: string) => {
    const getWeekDays = getValues('weekDays')

    const filterWeekDaysInterval = getWeekDays.map((item) => {
      if (item.name === currentDay) {
        return {
          ...item,
          intervals: item.intervals?.length
            ? [
                ...item.intervals,
                {
                  name: '',
                  hourInitial: '00:00',
                  hourFinal: '00:00',
                  _id: uuid(),
                },
              ]
            : [
                {
                  name: '',
                  hourInitial: '00:00',
                  hourFinal: '00:00',
                  _id: uuid(),
                },
              ],
        }
      }
      return item
    })

    setValue('weekDays', filterWeekDaysInterval)
  }
  useEffect(() => {
    reset({
      name: data?.name ?? '',
      weekDays: getDaysWeek(),
    })
  }, [reset])
  return (
    <>
      <Modal
        maxWidth='sm'
        title={isView ? String(t('shift.view')) : title}
        open={open}
        onClose={onClose}
      >
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12}>
            <Input
              name='name'
              label={t('input.shiftName') ?? ''}
              control={control}
              fullWidth
              error={!!errors?.name}
              errorMessage={errors?.name?.message}
              disabled={isView}
              required
            />
          </Grid>

          {watch('weekDays')?.map((day, index) => (
            <Grid item xs={12} key={index}>
              <MuiAccordion
                defaultExpanded={!!getValues(`weekDays.${index}.checked`)}
                sx={{
                  '&.MuiPaper-root.MuiPaper-elevation': {
                    background: (theme) =>
                      `${theme.palette.background.paper} !important`,
                  },
                  '& .MuiButtonBase-root': {
                    alignItems: 'center',
                  },
                  boxShadow: 'none',
                  padding: 0,
                  border: 'none !important',
                  '&::before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingRight: 1,
                    margin: '0 !important',
                    '& .MuiAccordionSummary-content': {
                      marginTop: '0px !important',
                      marginBottom: '0px !important',
                      marginLeft: '0px !important',
                      paddingLeft: '0px !important',
                    },
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      marginTop: '0px !important',
                      marginBottom: '0px !important',
                    },
                  }}
                >
                  <Checkbox
                    control={control}
                    name={`weekDays.[${index}].checked`}
                    disabled={isView}
                  />
                  <Typography
                    sx={{
                      width: 'auto',
                      flexShrink: 0,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {t(day.name)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 0 }}
                >
                  <Stack direction={'row'} spacing={2}>
                    <Input
                      name={`weekDays.[${index}].hourInitial`}
                      type='time'
                      label={t('input.startTime') ?? ''}
                      control={control}
                      fullWidth
                      error={!!errors?.weekDays?.[index]?.hourInitial}
                      errorMessage={
                        errors?.weekDays?.[index]?.hourInitial?.message
                      }
                      disabled={isView}
                    />
                    <Input
                      name={`weekDays.[${index}].hourFinal`}
                      type='time'
                      label={t('input.endTime') ?? ''}
                      control={control}
                      fullWidth
                      error={!!errors?.weekDays?.[index]?.hourFinal}
                      errorMessage={
                        errors?.weekDays?.[index]?.hourFinal?.message
                      }
                      disabled={isView}
                    />
                    {/* <Box>
                      <Toggle
                        name={`weekDays.[${index}].hasInterval`}
                        label='Intervalos'
                        checked={Boolean(
                          watch('weekDays')?.[index]?.hasInterval,
                        )}
                        onChange={(_, checked) => {
                          setValue(`weekDays.${index}.hasInterval`, checked)
                          handleCurrentDaySetInterval(checked, day.name)
                        }}
                      />
                    </Box> */}
                  </Stack>

                  {!!day?.intervals?.length &&
                    day.intervals.map((interval, indexInterval) => (
                      <Stack
                        key={`${day.name}-${interval?._id}`}
                        direction={'row'}
                        spacing={2}
                        marginTop={3}
                        alignItems='center'
                      >
                        <Stack direction={'row'} flex={1}>
                          <Input
                            name={`weekDays.[${index}].intervals.[${indexInterval}].name`}
                            control={control}
                            fullWidth
                            error={
                              !!errors?.weekDays?.[index]?.intervals?.[
                                indexInterval
                              ]?.name
                            }
                            errorMessage={
                              errors?.weekDays?.[index]?.intervals?.[
                                indexInterval
                              ]?.name?.message
                            }
                            disabled={isView}
                          />
                          <Input
                            name={`weekDays.[${index}].intervals.[${indexInterval}].hourInitial`}
                            type='time'
                            control={control}
                            fullWidth
                            error={
                              !!errors?.weekDays?.[index]?.intervals?.[
                                indexInterval
                              ]?.hourInitial
                            }
                            errorMessage={
                              errors?.weekDays?.[index]?.intervals?.[
                                indexInterval
                              ]?.hourInitial?.message
                            }
                            disabled={isView}
                          />
                          <Input
                            name={`weekDays.[${index}].intervals.[${indexInterval}].hourFinal`}
                            type='time'
                            control={control}
                            fullWidth
                            error={
                              !!errors?.weekDays?.[index]?.intervals?.[
                                indexInterval
                              ]?.hourFinal
                            }
                            errorMessage={
                              errors?.weekDays?.[index]?.intervals?.[
                                indexInterval
                              ]?.hourFinal?.message
                            }
                            disabled={isView}
                          />
                        </Stack>

                        {!isView && (
                          <IconButton
                            iconName='delete'
                            variant='contained'
                            color='secondary'
                            title={t('button.title.delete') ?? ''}
                            onClick={() =>
                              handleRemoveInterval(day.name, interval?._id)
                            }
                          />
                        )}
                      </Stack>
                    ))}

                  {!isView && (
                    <Stack direction={'row'} spacing={2} marginTop={2}>
                      <Button
                        variant='contained'
                        startIcon={<Icon name='add' />}
                        onClick={() => handleAddInterval(day.name)}
                      >
                        {t('button.title.addInterval')}
                      </Button>
                    </Stack>
                  )}
                </AccordionDetails>
              </MuiAccordion>
            </Grid>
          ))}

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>

      {openRegisterIntervals && (
        <FormRegisterIntervalModal
          data={null}
          fixedDay={String(openRegisterIntervals)}
          open={!!openRegisterIntervals}
          onClose={() => setOpenRegisterIntervals(false)}
          onSubmitSuccess={handleIntervals}
        />
      )}
    </>
  )
}
