/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { getConsumedEnergyFromProduced } from 'pages/dashboard/mappers/graphic-energy-produced-from-consumed.mapper'
import { dashboardGenerateQuantityProduced } from 'services/goalAndParameter/quantityProduced/dashboardGenerateQuantityProduced'
import { dashboardGenerate as energyDashboard } from 'services/invoice/energy/dashboardGenerate'

interface Props {
  isPreview?: boolean
}

export function useEnergyCostPerQuantityProducedChart(props: Props) {
  const { isPreview } = props

  const { t, i18n } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())

  const [period, setPeriod] = useState<PeriodType>('year')
  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data: dataEnergyCost, refetch: refetchEnergyCost } = useQuery({
    queryKey: ['dashboardConsumptionEnergy', date],
    queryFn: () => energyDashboard(String(dayjs(date).format('YYYY'))),
    select: (data) => {
      return data?.results || []
    },
  })

  const { data: dataQuantityProduced, refetch: refetchQuantityProduced } =
    useQuery({
      queryKey: ['dashboardGenerateQuantityProduced', date],
      queryFn: () =>
        dashboardGenerateQuantityProduced(String(dayjs(date).format('YYYY'))),
      select: (data) => {
        return data?.results || []
      },
    })

  useEffect(() => {
    const timeInMinutes = 5 * 60000 // 5 minute in milliseconds
    const intervalId = setInterval(() => {
      refetchQuantityProduced()
      refetchEnergyCost()
    }, timeInMinutes)

    return () => {
      clearInterval(intervalId) // Limpa o intervalo quando o componente é desmontado
    }
  }, [])

  const { energyCost, quantityProduced } = getConsumedEnergyFromProduced(
    dataEnergyCost || [],
    dataQuantityProduced || [],
  )

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview ? true : false,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.yAxisID === 'y2')
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(context.parsed.y)

            // let label = context.dataset.label || ''

            return `${context.parsed.y}`
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          display: isPreview ? false : true,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value + 'k'
          },
        },
      },
      y2: {
        position: 'right',
        ticks: {
          display: isPreview ? false : true,
          callback: function (value) {
            return 'R$' + value
          },
        },
      },
      x: {
        ticks: {
          display: isPreview ? false : true,
        },
      },
    },
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      labels: [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ],
      datasets: [
        {
          type: 'line' as const,
          label: t('chart.energy.energyCost'),
          yAxisID: 'y2',
          data: energyCost,
          backgroundColor: 'transparent',
          borderColor: '#FF6F00',
          tension: 0,
        },
        {
          type: 'bar' as const,
          label: t('chart.energy.totalProduced'),
          data: quantityProduced,
          borderRadius: 20,
          backgroundColor: createGradient(
            chart.ctx,
            chart.chartArea,
            colors.energy[0],
          ),
        },
      ],
    }

    setChartData(chartData)
  }, [i18n.language, date, dataQuantityProduced, dataEnergyCost])

  return {
    isDesktop,
    date,
    options,
    setDate,
    chartData,
    chartRef,
    t,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
  }
}
