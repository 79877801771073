import { Box, Stack, Step, StepLabel, Stepper } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IStepperFormNotificationProps {
  activeStep: number
  setActiveStep: Dispatch<SetStateAction<number>>
  steps: string[]
}

export const StepperFormNotification = ({
  activeStep,
  setActiveStep,
  steps,
}: IStepperFormNotificationProps) => {
  const [skipped, setSkipped] = useState(new Set<number>())

  const { t } = useTranslation('common')

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <>
      <Box sx={{ width: '100%', mb: 4 }}>
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{ width: 300, fontSize: 12 }}
          >
            {steps?.map((label, index) => {
              const stepProps: { completed?: boolean } = {}
              const labelProps: {
                optional?: React.ReactNode
              } = {}
              if (isStepSkipped(index)) {
                stepProps.completed = false
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{t(label)}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Stack>
      </Box>
    </>
  )
}
