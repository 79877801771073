import styled from '@emotion/styled'

export const EquipmentNodeItemWrapper = styled.div<{
  bgColor?: string
}>(
  {
    height: '22px',
    width: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    '&:hover ~ * ': {
      display: 'block',
    },
  },
  ({ bgColor }) => ({
    background: bgColor,
  }),
)

export const SmallBall = styled.div({
  height: '7px',
  width: '7px',
  background: '#689f20',
  borderRadius: '50%',
})

export const DetailsBox = styled.div({
  background: '#0c0c25',
  marginTop: 6,
  display: 'none',
  padding: 10,
  borderRadius: 6,
  boxShadow: '0 0 0 1px #66009d',
})
