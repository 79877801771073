import { Box, Stack, Typography, useTheme } from '@mui/material'

import EquipmentVariableLightImg from 'assets/icons/light/equipment-light.svg'
import AirConditioningLightImg from 'assets/icons/light/icon-single-registration-light.svg'
import AirConditioningImg from 'assets/icons/maps/ar-condicionado-variavel.svg'
import EquipmentVariableImg from 'assets/icons/maps/equipment-variavel.svg'
import { MouseOnPopover } from 'components/Popover'
import { Toggle } from 'components/Toggle'
import { TEXT_TITLE_14 } from 'constants/textTypes'
import { useMapSubarea } from 'store/map-subarea.store'

export const Variables = () => {
  const theme = useTheme()
  const { isAirConditioning, toggleIsAirConditioning } = useMapSubarea()

  const isDark = theme.palette.mode === 'dark'

  return (
    <>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          width: '100%',
          bgcolor: (theme) => theme.palette.background.paper,
          borderRadius: 2,
          px: 2,
          pt: 2,
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            width: '100%',
          }}
        >
          <Typography
            sx={{
              ...TEXT_TITLE_14,
              fontSize: 18,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Variável
          </Typography>

          <MouseOnPopover
            textPopover='Alterne entre Equipamentos/ Sensores e Ar condicionado/ sensores para melhor posicionar esses equipamentos e organizá-los em tela para futuro monitoramento'
            sx={{
              width: 350,
              '& .MuiTypography-root': {
                font: 'normal normal normal 10px/18px Noto Sans',
                textAlign: 'center',
                p: 1,
                color: theme.palette.text.secondary,
              },
            }}
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            width: '100%',
            py: 1,
          }}
        >
          <Typography
            sx={{
              font: `normal normal ${
                isAirConditioning ? 'normal' : 'bold'
              } 10px/18px Noto Sans`,
              textDecoration: isAirConditioning ? 'none' : 'underline',
              mr: 1,
            }}
          >
            Equipamentos e sensores
          </Typography>
          <Toggle
            name='toggle'
            label={''}
            checked={isAirConditioning}
            onChange={toggleIsAirConditioning}
          />
          <Typography
            sx={{
              font: `normal normal ${
                !isAirConditioning ? 'normal' : 'bold'
              } 10px/18px Noto Sans`,
              textDecoration: !isAirConditioning ? 'none' : 'underline',
              ml: 1,
            }}
          >
            Ar condicionado e sensores
          </Typography>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: '100%',
            height: 150,
          }}
        >
          <Box
            component='img'
            src={
              isAirConditioning
                ? isDark
                  ? AirConditioningImg
                  : AirConditioningLightImg
                : isDark
                ? EquipmentVariableImg
                : EquipmentVariableLightImg
            }
            width={150}
          />
        </Stack>
      </Stack>
    </>
  )
}
