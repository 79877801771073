export const TEXT_TITLE_14 = {
  fontSize: 14,
  fontWeight: 700,
  lineHeigth: '19.07px',
  align: 'center',
}

export const TEXT_SUBTEXT = {
  fontSize: 13,
  fontWeight: 400,
  lineHeigth: '14px',
}
