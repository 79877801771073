import { httpClient } from 'services/api/httpClient'
import { CreateNotificationType, NotificationType } from 'types/notification'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'

const create = (data: CreateNotificationType) => {
  return httpClient.post('/notification', data)
}

const update = (id: number, data: CreateNotificationType) => {
  return httpClient.put(`/notification/${id}`, data)
}

type NewType = PaginationType<NotificationType>
export const findAll = async ({
  page = 1,
  search = '',
  columnSort,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  const response = await httpClient.get('/notification', {
    params: {
      page: page - 1,
      columnSort,
      numberPerPage,
      ...(search?.length ? { filter: search } : {}),
    },
  })
  const { total } = response.data
  const { perPage } = response.data
  const { items } = response.data

  return {
    items,
    currentPage: page,
    perPage: perPage,
    total: total,
  } as PaginationResponseType
}

export const findAllByUser = async ({
  page = 1,
  search = '',
  columnSort,
  numberPerPage = 10,
  type = 'all',
}: PaginationParamType & {
  type: 'all' | 'unread' | 'starred'
}): Promise<NewType> => {
  const response = await httpClient.get(
    type === 'starred'
      ? '/notification/notification-starred-by-user'
      : type === 'unread'
      ? '/notification/notification-unread-by-user'
      : '/notification/notification-by-user',
    {
      params: {
        page: page - 1,
        columnSort,
        numberPerPage,
        ...(search?.length ? { filter: search } : {}),
      },
    },
  )
  const { total } = response.data
  const { perPage } = response.data
  const { items } = response.data

  return {
    items,
    currentPage: page,
    perPage: perPage,
    total: total,
  } as PaginationResponseType
}

export const notificationService = {
  create,
  findAll,
  update,
  findAllByUser,
}
