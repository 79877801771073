/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material'
import { Fragment } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { MaskedInputNumber } from 'components/Input/mask/MaskedInputNumber'
import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { convertToValidTime, formatTime } from 'helpers/date.helper'
import { uuid } from 'helpers/uuid'
import { createAllLevel } from 'services/level/createLevel'
import { scalabilityService } from 'services/scalability.service'
import { CreateLevelType } from 'types/level/create-level'
import { ModalType } from 'types/modal'

type LevelType = {
  id?: number
  name: string
  value: string
  type: 'hours' | 'minutes'
  _id: string
}

type Form = {
  level: LevelType[]
}

interface Props extends ModalType {
  data: any | null
  isView?: boolean
}

export function FormLevelModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const {
    // control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      level: data ?? [],
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('scalability')
    queryClient.invalidateQueries('all-level')
    onClose()
  }

  const mutationUpdate = useMutation(
    (update: CreateLevelType[]) => {
      const id = data?.[0]?._id
      return scalabilityService.updateLevel(id, update)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )
  const mutationCreate = useMutation(
    (data: CreateLevelType[]) => {
      return createAllLevel(data)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )
  const arrangeData = (data: Form) => {
    const result: CreateLevelType[] = data.level.map((item: LevelType) => ({
      id: item.id ?? null,
      name: item.name,
      time: formatTime(Number(item.value), item.type),
    }))

    return result
  }
  const onSubmit: SubmitHandler<Form> = (formData) => {
    mutationCreate.mutate(arrangeData(formData))
  }

  const handleAdd = () => {
    const getLevel = getValues('level')

    setValue(
      'level',
      getLevel?.length
        ? [
            ...getLevel,
            {
              _id: uuid(),
              name: `Nível ${getLevel.length + 1}`,
              value: '',
              type: 'minutes',
            },
          ]
        : [
            {
              _id: uuid(),
              name: 'Nível 1',
              value: '',
              type: 'minutes',
            },
          ],
    )
  }

  return (
    <>
      <Modal title={t('scalability.newLevel')} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          {watch('level')?.map((item, index: number) => (
            <Fragment key={item._id}>
              <Grid item xs={12}>
                {item.name}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name={`level.[${index}].value`}
                  label={t('input.value') ?? ''}
                  // control={control}
                  fullWidth
                  value={getValues('level')?.[index]?.value ?? ''}
                  onChange={(e) => {
                    setValue(
                      `level.${index}.value`,
                      convertToValidTime(
                        Number(e.target.value),
                        getValues('level')?.[index]?.type ?? 'minutes',
                      ),
                    )
                  }}
                  error={!!errors?.level?.[index]?.value}
                  errorMessage={errors?.level?.[index]?.value?.message}
                  required
                  InputProps={{
                    inputComponent: MaskedInputNumber as any,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  name={`level.[${index}].type`}
                  label={t('input.type') ?? ''}
                  options={[
                    { id: 'minutes', name: 'Minutos' },
                    { id: 'hours', name: 'Horas' },
                  ]}
                  error={!!errors?.level?.[index]?.type}
                  // control={control}
                  value={getValues('level')?.[index]?.type ?? ''}
                  onChange={(e) => {
                    setValue(
                      `level.${index}.type`,
                      e.target.value as 'hours' | 'minutes',
                    )
                    setValue(
                      `level.${index}.value`,
                      convertToValidTime(
                        Number(getValues('level')?.[index]?.value),
                        e.target.value as 'hours' | 'minutes',
                      ),
                    )
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Fragment>
          ))}

          <Grid item xs={12}>
            <Button
              variant='contained'
              startIcon={<Icon name='add' />}
              onClick={handleAdd}
            >
              {t('button.title.newLevel')}
            </Button>
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
