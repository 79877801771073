import React, { createContext, ReactNode, useContext, useState } from 'react'

import { LoadingLottieFile } from 'components/Loading/LoadingLottieFile'

interface LoadingContextData {
  loading: boolean
  setLoading: (loading: boolean) => void
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
)

export const useLoading = () => {
  return useContext(LoadingContext)
}

interface LoadingProviderProps {
  children: ReactNode
}

export function LoadingProvider({ children }: LoadingProviderProps) {
  const [loading, setLoading] = useState(false)
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {loading && <LoadingLottieFile />}
      {children}
    </LoadingContext.Provider>
  )
}
