import { BatteryAlert, LocationOn, QueryBuilder } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import IMG_AREA from 'assets/images/area.svg'
import IMG_PIN from 'assets/images/pin.svg'
import { formatDate } from 'pages/btnPanicAlert/hooks/useAlertPanicScreen'

type Props = {
  onClick: () => void
  type: 'area' | 'pin'
  item: any
}

export function AlertPanicCard({ onClick, type, item }: Props) {
  const { t } = useTranslation('common')
  return (
    <Box
      sx={{
        minWidth: '512px',
        cursor: 'pointer',
        width: '512px',
        height: '196px',
        backgroundColor: 'rgba(222,12,12,0.15)',
        borderRadius: '6px',
        borderLeftWidth: '10px',
        borderLeftStyle: 'solid',
        borderLeftColor: '#FF0000',
        padding: '20px',
        '&:hover': {
          backgroundColor: 'rgba(222,12,12,0.25)',
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          backgroundImage: () => {
            return type === 'area' ? `url(${IMG_AREA})` : `url(${IMG_PIN})`
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundSize: 'contain',
        }}
      >
        <Typography variant={'h6'}>
          {' '}
          {item?.equipment?.name ?? 'S/N'}
        </Typography>
        <Typography variant={'caption'}>
          <strong>{t('panicMonitoring.card.type')}:</strong>{' '}
          {item?.equipment?.mobile ? 'Móvel' : 'Fixo'}
        </Typography>
        <Stack spacing={0.9}>
          <Stack direction={'row'} mt={2} alignItems={'center'} spacing={1}>
            <LocationOn color={'error'} />
            <Typography variant={'caption'}>
              <strong>{t('panicMonitoring.card.location')}:</strong>{' '}
              {item?.area?.name ?? 'S/N'}
            </Typography>
          </Stack>
          <Stack direction={'row'} mt={2} alignItems={'center'} spacing={1}>
            <QueryBuilder color={'error'} />
            <Typography variant={'caption'}>
              <strong>{t('panicMonitoring.card.lastUpdate')}:</strong>{' '}
              {formatDate(item?.date, false) ?? 'D/N'}
            </Typography>
          </Stack>
          <Stack direction={'row'} mt={2} alignItems={'center'} spacing={1}>
            <BatteryAlert color={'error'} />
            <Typography variant={'caption'}>
              <strong>{t('panicMonitoring.card.levelBattery')}:</strong>{' '}
              {item?.battery ? `${item?.battery}%` : '--'}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
