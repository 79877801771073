import { httpClient } from 'services/api/httpClient'
import { MeasuringAreaType } from 'types/measuring-area/measuringArea'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'

type NewType = PaginationType<MeasuringAreaType>

export const getMeasuringAreas = async ({
  page = 1,
  search = '',
  columnSort,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  if (search?.length) {
    const { data } = await httpClient.post<PaginationResponseType>(
      '/measuring-area/findcustom',
      { filter: { name: search, email: search } },
      {
        params: {
          search,
          page: page - 1,
          columnSort,
          numberPerPage,
        },
      },
    )

    return {
      items: data?.items ?? [],
      currentPage: page,
      perPage: data?.perPage,
      total: data?.total,
    }
  }

  const { data } = await httpClient.get<PaginationResponseType>(
    'measuring-area',
    {
      params: {
        page: page - 1,
        columnSort,
        numberPerPage,
      },
    },
  )

  return {
    items: data?.items ?? [],
    currentPage: page,
    perPage: data?.perPage,
    total: data?.total,
  }
}
