import { createContext, ReactNode, useEffect, useState } from 'react'

import { ENVIRONMENT } from 'constants/environment'
import { SensorOption } from 'types/sensor/sensor'

interface SensorsMenuContextType {
  sensors: SensorOption[]
  onChangeSensorView: (sensor: SensorOption) => void
  onHideSensors: (hide: boolean) => void
}

const SensorsMenuContext = createContext<SensorsMenuContextType>(
  {} as SensorsMenuContextType,
)

interface SensorsMenuProviderProps {
  children: ReactNode
}

function SensorsMenuProvider({ children }: SensorsMenuProviderProps) {
  const [sensors, setSensors] = useState<SensorOption[]>(() => {
    const storedStateAsJSON = localStorage.getItem(
      `@${ENVIRONMENT.APP_NAME}:sensor-state-1.0.1`,
    )

    if (storedStateAsJSON) {
      return JSON.parse(storedStateAsJSON)
    }

    return ['WATER', 'ELECTRIC', 'AIR', 'TEMP', 'TEMP_HUM', 'HUM']
  })

  useEffect(() => {
    const stateJSON = JSON.stringify(sensors)

    localStorage.setItem(
      `@${ENVIRONMENT.APP_NAME}:sensor-state-1.0.1`,
      stateJSON,
    )
  }, [sensors])

  const onChangeSensorView = (sensor: SensorOption) => {
    const isRemove = sensors.find((item) => item === sensor)

    if (isRemove) {
      setSensors((state) => state.filter((item) => item !== sensor))
      return
    }

    setSensors((state) => [...state, sensor])
  }

  const onHideSensors = (hide: boolean) => {
    setSensors(
      hide ? [] : ['WATER', 'ELECTRIC', 'AIR', 'TEMP', 'TEMP_HUM', 'HUM'],
    )
  }

  const value = {
    sensors,
    onChangeSensorView,
    onHideSensors,
  }

  return (
    <SensorsMenuContext.Provider value={value}>
      {children}
    </SensorsMenuContext.Provider>
  )
}

export { SensorsMenuContext, SensorsMenuProvider }
