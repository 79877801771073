import { Link, Menu, Stack, Typography } from '@mui/material'
import { findModule } from 'core/enums/Module'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SockJS from 'sockjs-client'
import { over } from 'stompjs'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Loading } from 'components/Loading'
import { mainMenu, MenuProps } from 'configs/menuConfig'
import { ENVIRONMENT } from 'constants/environment'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'
import { notificationService } from 'services/notification.service'
import { useAuthStore } from 'store/auth.store'
import { NotificationType } from 'types/notification'

import { NotificationItem } from './NotificationItem'

let stompClient: any = null

const url = `${ENVIRONMENT.API_URL}${
  ENVIRONMENT.API_URL?.endsWith('/') ? 'stompws/' : '/stompws/'
}`

export function Notifications() {
  const { t } = useTranslation('common')
  const { user } = useAuthStore((state) => state)
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [hasNotifications, setHasNotifications] = useState(false)
  const open = Boolean(anchorEl)

  const [activeButton, setActiveButton] = useState<
    'all' | 'unread' | 'starred'
  >('unread')

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['basic-notifications', activeButton],
    queryFn: () =>
      notificationService.findAllByUser({ page: 1, type: activeButton }),
    staleTime: 0,
    cacheTime: 0,
  })
  const notificationsItems = data?.items

  useEffect(() => {
    const Sock = new SockJS(url)
    // const Sock = new SockJS('http://localhost:8080/api/v1/stompws')
    stompClient = over(Sock)
    stompClient?.connect(
      {},
      () => {
        setTimeout(function () {
          stompClient?.subscribe('/topic/public', (message: any) => {
            const data: string[] = JSON.parse(message.body)
            if (data?.find((item) => Number(item) === user?.id)) {
              setHasNotifications(true)
              refetch()
            }
          })
        }, 500)
      },
      (error: any) => {
        console.error('error not connected with websocket:', error)
      },
    )
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setHasNotifications(false)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getRoute = (menus: MenuProps[], module: string, route: string) => {
    let findRoute = route

    if (findRoute?.length > 0) return findRoute

    menus?.forEach((menu) => {
      if (findRoute?.length > 0) return findRoute

      findRoute =
        menu?.module && module?.toLowerCase() === menu?.module?.toLowerCase()
          ? menu?.route
          : findRoute

      if (findRoute?.length > 0) return findRoute

      if (menu?.submenu && findRoute?.length <= 0) {
        findRoute = getRoute(menu.submenu, module, findRoute) ?? findRoute
      }
    })

    return findRoute
  }

  const handleRead = async (item: NotificationType) => {
    try {
      await httpClient.put(`/notification/read/${item?.id.value}`)
      refetch()
    } catch (error: any) {
      getErrorMessage(error)
    }
  }

  const handleViewModule = async (item: NotificationType) => {
    const route = getRoute(mainMenu, item?.feature?.name ?? '', '')

    if (!route?.length) {
      return toast.warning('Módulo não encontrado.')
    }

    handleRead(item)
    handleClose()
    navigate(route)
  }

  return (
    <>
      <IconButton
        title={t('button.title.notifications') ?? ''}
        variant={hasNotifications ? 'contained' : 'outlined'}
        color={open && !hasNotifications ? 'primary' : 'error'}
        iconName={
          open || hasNotifications ? 'notifications' : 'notificationsNone'
        }
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      />

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: 8,
            width: 400,
            height: 450,
          },
        }}
        sx={{
          '& .MuiPaper-root': {
            background: (theme) => theme.palette.background.paper,
            '&::-webkit-scrollbar': {
              width: 6,
              height: 6,
              background: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: (theme) => theme.palette.background.default,
            },
          },
          '& ul': {
            padding: 0,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack spacing={3} paddingY={3} paddingBottom={0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            spacing={3}
            paddingX={2}
          >
            <Stack spacing={3}>
              <Typography variant='h1'>{t('notification.title')}</Typography>

              <Stack direction='row' spacing={3}>
                <Button
                  variant={activeButton === 'all' ? 'contained' : 'outlined'}
                  onClick={() => setActiveButton('all')}
                  sx={{ minWidth: 60 }}
                >
                  {t('notification.all')}
                </Button>

                <Button
                  variant={activeButton === 'unread' ? 'contained' : 'outlined'}
                  onClick={() => setActiveButton('unread')}
                >
                  {t('notification.unread')}
                </Button>
              </Stack>
            </Stack>

            <IconButton
              variant='outlined'
              iconName='close'
              onClick={handleClose}
            />
          </Stack>

          <Stack
            spacing={3}
            paddingBottom={3}
            sx={{
              display: 'block',
              overflowY: 'auto',
              maxHeight: 285,
              width: '100%',
              '&::-webkit-scrollbar': {
                width: 6,
                height: 6,
                background: 'transparent',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                background: (theme) => theme.palette.background.default,
              },
            }}
          >
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              paddingX={2}
            >
              <Typography
                variant='body1'
                sx={{
                  fontStyle: 'italic',
                  fontWeight: 300,
                }}
              >
                {/* {t('notification.new')} */}
              </Typography>

              <Link
                component={RouterLink}
                to='/notifications'
                color='inherit'
                underline='hover'
                onClick={handleClose}
              >
                <Typography variant='body2' fontWeight={300}>
                  {t('notification.seeAll')}
                </Typography>
              </Link>
            </Stack>

            {(isLoading || isFetching) && (
              <Loading
                mode='linear'
                sx={{
                  marginTop: 0,
                  padding: 0,
                }}
              />
            )}
            {notificationsItems?.map((notification) => (
              <NotificationItem
                key={notification.id?.value}
                icon={undefined}
                message={notification.message}
                module={
                  notification?.feature?.name
                    ? findModule(notification?.feature?.name)
                    : ''
                }
                createdAt={notification.creation}
                onClick={() => handleViewModule(notification)}
              />
              // <NotificationItem
              //   key={notification.id}
              //   icon={notification?.sensor?.type}
              //   message={notification.message}
              //   module={notification.sensor?.name ?? ''}
              //   createdAt={notification.createdAt}
              // />
            ))}

            {/* <Stack spacing={3} paddingX={2}>
              <Typography
                variant='body1'
                sx={{
                  fontStyle: 'italic',
                  fontWeight: 300,
                }}
              >
                {t('notification.previous')}
              </Typography>
            </Stack>

            <NotificationItem
              message='Sensor XXX está com a temperatura acima do normal.'
              module='Monitoramento de sensores'
              createdAt='2021-10-10T15:00:00.000Z'
            />

            <NotificationItem
              message='Sensor XXX está com a temperatura acima do normal.'
              module='Monitoramento de sensores'
              createdAt='2021-10-10T15:00:00.000Z'
            /> */}
          </Stack>
        </Stack>
      </Menu>
    </>
  )
}
