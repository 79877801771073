import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import {
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

import { Actions } from 'components/Actions'
import { DatePeriod } from 'components/DatePeriod'
import { Autocomplete } from 'components/Select/Autocomplete'
import { httpClientDownloadExcel } from 'helpers/httpClientDownloadExcel'
import { useIndoorVSOutdoorAmbientTemperatureChart } from 'pages/dashboard/hooks/temperature/useIndoorVSOutdoorAmbientTemperatureChart'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
)

interface Props {
  isPreview?: boolean
}

export function IndoorVSOutdoorAmbientTemperatureChart(props: Props) {
  const { isPreview } = props
  const {
    options,
    date,
    setDate,
    chartRef,
    chartData,
    t,
    period,
    setPeriod,
    setGeneratingFile,
    generatingFile,
    areas,
    setSelectArea1,
    setSelectArea2,
    setAreaName1,
    setAreaName2,
    areaId1,
    areaId2,
    year,
    month,
  } = useIndoorVSOutdoorAmbientTemperatureChart({ isPreview })

  return (
    <Box
      sx={{
        gridArea: 'medium',
        borderRadius: 1,
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        maxWidth: '100vw',
        minHeight: 200,
        padding: 2,
        background: (theme) => theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        position: 'relative',
      }}
    >
      <Backdrop
        open={generatingFile}
        sx={{
          zIndex: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='inherit' />
        <Typography variant='caption'>{t('generating file')}</Typography>
      </Backdrop>

      <Stack
        direction={['column', 'row']}
        justifyContent={['space-between']}
        alignItems={'flex-start'}
        marginBottom={3}
        flexWrap='wrap'
      >
        <Typography
          variant='h2'
          fontSize='1.125rem'
          fontWeight='bold'
          whiteSpace={['normal', isPreview ? 'normal' : 'nowrap']}
          marginBottom={1}
        >
          {t('chart.temperature.indoorVSOutdoorAmbientTemperature')}
        </Typography>

        {!isPreview && (
          <Stack
            direction={['column', 'row']}
            justifyContent={'flex-end'}
            alignItems={['flex-end', 'center']}
            spacing={2}
            width={['100%', 'auto']}
            marginLeft={'auto'}
          >
            <Stack
              direction={['column', 'row']}
              justifyContent={'flex-end'}
              alignItems={['flex-end', 'center']}
              spacing={2}
              width={['100%', 'auto']}
              flexWrap='wrap'
              gap={2}
            >
              <Box minWidth={200} width={['100%', 'auto']}>
                <DatePeriod
                  date={date}
                  defaultPeriod={period}
                  periods={['day', 'month', 'year']}
                  onChangeDate={setDate}
                  onChangePeriod={(value) => setPeriod(value)}
                />
              </Box>

              <Box minWidth={200} width={['100%', 'auto']}>
                {!!areas?.find((item) => item.value === areaId1) && (
                  <Autocomplete
                    name='areas'
                    placeholder={t('input.selectAreaIndoor')}
                    value={areas?.find((item) => item.value === areaId1)}
                    options={
                      areas
                        ? [...areas].sort((a, b) =>
                            a.label.localeCompare(b.label),
                          )
                        : []
                    }
                    onChange={(value: any) => {
                      setSelectArea1(value?.value as string)
                      setAreaName1(value?.label as string)
                    }}
                  />
                )}
              </Box>

              <Box minWidth={200} width={['100%', 'auto']}>
                <Autocomplete
                  name='areas'
                  placeholder={t('input.selectAreaOutdoor')}
                  options={
                    areas
                      ? [...areas].sort((a, b) =>
                          a.label.localeCompare(b.label),
                        )
                      : []
                  }
                  onChange={(item: any) => {
                    setSelectArea2(item?.value as string)
                    setAreaName2(item?.label as string)
                  }}
                />
              </Box>
            </Stack>

            <Stack paddingTop={[2, 0]}>
              <Actions
                color='inherit'
                title='button.title.export'
                options={[
                  {
                    type: 'excel',
                    disabled: !areaId1 || !areaId2,
                    action: () =>
                      httpClientDownloadExcel({
                        url: '/dashboard-temperature-and-humidity/internal-vs-external-ambient/export-excel',
                        fileName: 'temperature-ambiente-interno-vs-externo',
                        params: {
                          year,
                          month: period === 'month' ? month : null,
                          internalAreaId: areaId1,
                          externalAreaId: areaId2,
                        },
                        setGeneratingFile,
                      }),
                  },
                ]}
              />
            </Stack>
          </Stack>
        )}
      </Stack>

      <Chart
        type='bar'
        ref={chartRef}
        options={options}
        data={chartData}
        height={120}
      />
    </Box>
  )
}
