/* eslint-disable quotes */
import { Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loading } from 'components/Loading'
import { uuid } from 'helpers/uuid'

import { AreaMedicaoContent } from './components/contents/AreaMedicaoContent'
import { CalendarioManutencaoContent } from './components/contents/CalendarioManutencaoContent'
import { ClienteContent } from './components/contents/ClienteContent'
import { DashboardEnergyConsumoContent } from './components/contents/DashboardEnergyConsumoContent'
import { DashboardEnergyContratadoConsumidoContent } from './components/contents/DashboardEnergyContratadoConsumidoContent'
import { DashboardEnergyCustoEnergiaQuantidadeProduzidaContent } from './components/contents/DashboardEnergyCustoEnergiaQuantidadeProduzidaContent'
import { DashboardEnergyGeneralConsumptionContent } from './components/contents/DashboardEnergyGeneralConsumptionContent'
import { DashboardEnergyGrandezaContent } from './components/contents/DashboardEnergyGrandezaContent'
import { DashboardGasQualidadeArContent } from './components/contents/DashboardGasQualidadeArContent'
import { DashboardGasTempoRealContent } from './components/contents/DashboardGasTempoRealContent'
import { DashboardTemperaturaAreaContent } from './components/contents/DashboardTemperaturaAreaContent'
import { DashboardTemperaturaInternoExternoContent } from './components/contents/DashboardTemperaturaInternoExternoContent'
import { DashboardTemperaturaTempoRealContent } from './components/contents/DashboardTemperaturaTempoRealContent'
import { DashboardWaterContent } from './components/contents/DashboardWaterContent'
import { DashboardWaterGeneralConsumptionContent } from './components/contents/DashboardWaterGeneralConsumptionContent'
import { DashboardWaterGeneralConsumptionTargetContent } from './components/contents/DashboardWaterGeneralConsumptionTargetContent'
import { DashboardWaterPressureContent } from './components/contents/DashboardWaterPressureContent'
import { DashboardWaterTankLevelContent } from './components/contents/DashboardWaterTankLevelContent'
import { DashboardWaterTankLevelRealTimeContent } from './components/contents/DashboardWaterTankLevelRealTimeContent'
import { EquipamentosContent } from './components/contents/EquipamentosContent'
import { EscalabilidadeContent } from './components/contents/EscalabilidadeContent'
import { FaturasAguaContent } from './components/contents/FaturasAguaContent'
import { FaturasContratosContent } from './components/contents/FaturasContratosContent'
import { FaturasEnergiaContent } from './components/contents/FaturasEnergiaContent'
import { FaturasMercadoLivreContent } from './components/contents/FaturasMercadoLivreContent'
import { MetasContent } from './components/contents/MetasContent'
import { MonitoramentoSensoresContent } from './components/contents/MonitoramentoSensoresContent'
import { MontarMapaContent } from './components/contents/MontarMapaContent'
import { MyAccountContent } from './components/contents/MyAccountContent'
import { NotificacoesContent } from './components/contents/NotificacoesContent'
import { ParametrosContent } from './components/contents/ParametrosContent'
import { PreOSContent } from './components/contents/PreOSContent'
import { ProfileContent } from './components/contents/ProfileContent'
import { QuantidadeProduzidaContent } from './components/contents/QuantidadeProduzidaContent'
import { SensoresContent } from './components/contents/SensoresContent'
import { TurnoContent } from './components/contents/TurnoContent'
import { TutorialSistemaContent } from './components/contents/TutorialSistemaContent'
import { UsersContent } from './components/contents/UsersContent'
import { Tutorial, TutorialType } from './components/Tutorial'
import {
  areaMedicaoImg,
  calendarioManutencaoImg,
  clienteImg,
  configuracoesImg,
  controleAcessoImg,
  controleAcessoPerfilImg,
  controleAcessoUsuariosImg,
  dashboardEnergyConsumptionImg,
  dashboardEnergyContratadoConsumidoImg,
  dashboardEnergyCustoEnergiaProduzidaImg,
  dashboardEnergyGeneralConsumptionImg,
  dashboardEnergyGrandezaEletricaImg,
  dashboardEnergyImg,
  dashboardGasImg,
  dashboardGasQualidadeArImg,
  dashboardGasTempoRealImg,
  dashboardTemperaturaAreaImg,
  dashboardTemperaturaImg,
  dashboardTemperaturaInternoExternoImg,
  dashboardTemperaturaTempoRealImg,
  dashboardWaterGeneralConsumptionImg,
  dashboardWaterGeneralConsumptionTargetImg,
  dashboardWaterImg,
  dashboardWaterPressureImg,
  dashboardWaterTankLevelImg,
  dashboardWaterTankLevelRealtimeImg,
  equipamentosImg,
  escalabilidadeImg,
  faturasAguaImg,
  faturasContratosImg,
  faturasEnergiaImg,
  faturasMercadoLivreImg,
  manutencaoImg,
  mapaImg,
  metaParametrosImg,
  monitoramentoSensorsImg,
  montarMapaImg,
  notificacoesImg,
  parametrosImg,
  preOsImg,
  quantidadeProduzidaImg,
  sensoresImg,
  turnoImg,
} from './components/tutorial.images'

const dataApi: TutorialType[] = [
  {
    id: '706af01b-c30e-478f-86f2-d163c2c0d213',
    name: 'Dashboard',
    content: null,
    image: null,
    topics: [
      {
        id: '8d15f831-04a3-441c-80fe-aec4886f6685',
        name: 'Dashboard de água',
        content: <DashboardWaterContent />,
        image: dashboardWaterImg,
        topics: [
          {
            id: uuid(),
            name: 'Consumo geral, fatura e meta',
            content: <DashboardWaterGeneralConsumptionContent />,
            image: dashboardWaterGeneralConsumptionImg,
          },
          {
            id: uuid(),
            name: 'Vazão da água (última hora)',
            content: <DashboardWaterPressureContent />,
            image: dashboardWaterPressureImg,
          },
          {
            id: uuid(),
            name: 'Consumo Vs Meta',
            content: <DashboardWaterGeneralConsumptionTargetContent />,
            image: dashboardWaterGeneralConsumptionTargetImg,
          },
          {
            id: uuid(),
            name: `Nível da caixa d'água`,
            content: <DashboardWaterTankLevelContent />,
            image: dashboardWaterTankLevelImg,
          },
          {
            id: uuid(),
            name: `Nível da caixa d’água (tempo real)`,
            content: <DashboardWaterTankLevelRealTimeContent />,
            image: dashboardWaterTankLevelRealtimeImg,
          },
        ],
      },
      {
        id: 'c79041d6-6315-4b4d-8de7-402636bca0fe',
        name: 'Dashboard de Energia',
        content: null,
        image: dashboardEnergyImg,
        topics: [
          {
            id: 'c79041d6-6315-4b4d-8de7-402636bca0f1',
            name: 'Consumo geral, fatura e meta',
            content: <DashboardEnergyGeneralConsumptionContent />,
            image: dashboardEnergyGeneralConsumptionImg,
          },
          {
            id: 'c79041d6-6315-4b4d-8de7-402636bca0f2',
            name: 'Consumo medido',
            content: <DashboardEnergyConsumoContent />,
            image: dashboardEnergyConsumptionImg,
          },
          {
            id: 'c79041d6-6315-4b4d-8de7-402636bca0f3',
            name: 'Grandezas Elétricas',
            content: <DashboardEnergyGrandezaContent />,
            image: dashboardEnergyGrandezaEletricaImg,
          },
          {
            id: 'c79041d6-6315-4b4d-8de7-402636bca0f4',
            name: 'Contratado Vs Consumido',
            content: <DashboardEnergyContratadoConsumidoContent />,
            image: dashboardEnergyContratadoConsumidoImg,
          },
          // {
          //   id: 'c79041d6-6315-4b4d-8de7-402636bca0f4',
          //   name: 'Queda de Energia',
          //   content: <DashboardEnergyQuedaEnergiaContent />,
          //   image: dashboardEnergyQuedaEnergiaImg,
          // },
          // {
          //   id: 'c79041d6-6315-4b4d-8de7-402636bca0f4',
          //   name: 'Tempo de queda de energia',
          //   content: <DashboardEnergyTempoQuedaEnergiaContent />,
          //   image: dashboardEnergyTempoQuedaEnergiaImg,
          // },
          {
            id: 'c79041d6-6315-4b4d-8de7-402636bca0f4',
            name: 'Custo de energia por quantidade produzida',
            content: <DashboardEnergyCustoEnergiaQuantidadeProduzidaContent />,
            image: dashboardEnergyCustoEnergiaProduzidaImg,
          },
        ],
      },
      {
        id: '300016c6-55f1-453d-83e8-330d55982550',
        name: 'Dashboard de Gás',
        content: null,
        image: dashboardGasImg,
        topics: [
          {
            id: '300016c6-55f1-453d-83e8-330d55982551',
            name: 'Qualidade do Ar',
            content: <DashboardGasQualidadeArContent />,
            image: dashboardGasQualidadeArImg,
          },
          {
            id: '300016c6-55f1-453d-83e8-330d55982552',
            name: 'Qualidade do ar (Tempo real)',
            content: <DashboardGasTempoRealContent />,
            image: dashboardGasTempoRealImg,
          },
          // {
          //   id: '300016c6-55f1-453d-83e8-330d55982553',
          //   name: 'Consumo de gás de cozinha',
          //   content: <DashboardGasCozinhaContent />,
          //   image: dashboardGasCozinhaImg,
          // },
        ],
      },
      {
        id: '96f11856-a3f9-4205-8e7e-3dedb0da9e86',
        name: 'Dashboard de Temperatura',
        content: null,
        image: dashboardTemperaturaImg,
        topics: [
          {
            id: '96f11856-a3f9-4205-8e7e-3dedb0da9e831',
            name: 'Temperatura e umidade por área',
            content: <DashboardTemperaturaAreaContent />,
            image: dashboardTemperaturaAreaImg,
          },
          {
            id: '96f11856-a3f9-4205-8e7e-3dedb0da9e832',
            name: 'Temperatura ambiente interno Vs Externo ',
            content: <DashboardTemperaturaInternoExternoContent />,
            image: dashboardTemperaturaInternoExternoImg,
          },
          {
            id: '96f11856-a3f9-4205-8e7e-3dedb0da9e833',
            name: 'Temperatura e Umidade em tempo real',
            content: <DashboardTemperaturaTempoRealContent />,
            image: dashboardTemperaturaTempoRealImg,
          },
        ],
      },
    ],
  },
  {
    id: '8aefcaef-5c42-4b5b-8ced-1b057d729eee',
    name: 'Mapa',
    content:
      'No Módulo de Mapa são realizados os cadastros de montar o mapa, áreas de medição e de turnos.',
    image: mapaImg,
    topics: [
      {
        id: '070b0581-4ec1-44d4-82eb-a132b898cd70',
        name: 'Monitoramento de Sensores',
        content: <MonitoramentoSensoresContent />,
        image: monitoramentoSensorsImg,
      },
      {
        id: '3c850e82-2161-489c-8969-53a669bbec3d',
        name: 'Montar Mapa',
        content: <MontarMapaContent />,
        image: montarMapaImg,
      },
      {
        id: '4c2c80cb-be8f-4f08-8e58-5dae27c3099b',
        name: 'Turnos',
        content: <TurnoContent />,
        image: turnoImg,
      },
      {
        id: '95742866-eb8c-4eee-8f35-2658078f9ea6',
        name: 'Áreas de Medição',
        content: <AreaMedicaoContent />,
        image: areaMedicaoImg,
      },
    ],
  },
  {
    id: '55f2d4ae-6f4d-4989-8e0d-3e8859b2531d',
    name: 'Configurações',
    content:
      'No Módulo de Mapa são realizados os cadastros de sensores, equipamentos, escalabilidades (e níveis de escalabilidade), notificação, metas e parâmetros (e quantidade produzida) e faturas (de água, energia, mercado livre e contratos). ',
    image: configuracoesImg,
    topics: [
      {
        id: '7d72df70-5487-4a53-8e8b-915e8d95b4f0',
        name: 'Sensores',
        content: <SensoresContent />,
        image: sensoresImg,
      },
      {
        id: 'f4fe4076-10c2-4c7d-8b54-804500702606',
        name: 'Equipamentos',
        content: <EquipamentosContent />,
        image: equipamentosImg,
      },
      {
        id: '0a90be70-ed58-412a-8be4-cd4b44c8788b',
        name: 'Escalabilidade',
        content: <EscalabilidadeContent />,
        image: escalabilidadeImg,
      },
      {
        id: '2d199fdd-501a-4c0e-85aa-d8c8e020e930',
        name: 'Notificações',
        content: <NotificacoesContent />,
        image: notificacoesImg,
      },
      {
        id: 'd2489944-f037-4b67-87cb-959749f91ea3',
        name: 'Metas e Parâmetros',
        content: null,
        image: null,
        topics: [
          {
            id: 'd2489944-f037-4b67-87cb-959749f91ea31',
            name: 'Metas',
            content: <MetasContent />,
            image: metaParametrosImg,
          },
          {
            id: 'd2489944-f037-4b67-87cb-959749f91ea32',
            name: 'Parâmetros',
            content: <ParametrosContent />,
            image: parametrosImg,
          },
          {
            id: 'd2489944-f037-4b67-87cb-959749f91ea33',
            name: 'Quantidade Produzida',
            content: <QuantidadeProduzidaContent />,
            image: quantidadeProduzidaImg,
          },
        ],
      },
      {
        id: 'a2b4b28a-c1e0-45b8-8a04-6a7c50a46235',
        name: 'Faturas',
        content: `Nesse submódulo, são realizados os cadastros das faturas de água, energia, mercado livre e contratos.
        Os dados desse submódulo serão utilizados no Dashboard, então sugerimos que sempre verifique mensalmente se os cadastros das faturas já foram realizados.
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente o seu tipo de perfil não permite o acesso. Caso necessite o uso desse submódulo, solicite ao administrador do sistema.        
        `,
        image: null,
        topics: [
          {
            id: 'a2b4b28a-c1e0-45b8-8a04-6a7c50a462351',
            name: 'Faturas Água',
            content: <FaturasAguaContent />,
            image: faturasAguaImg,
          },
          {
            id: 'a2b4b28a-c1e0-45b8-8a04-6a7c50a462352',
            name: 'Faturas Energia',
            content: <FaturasEnergiaContent />,
            image: faturasEnergiaImg,
          },
          {
            id: 'a2b4b28a-c1e0-45b8-8a04-6a7c50a462353',
            name: 'Faturas do Mercado Livre',
            content: <FaturasMercadoLivreContent />,
            image: faturasMercadoLivreImg,
          },
          {
            id: 'a2b4b28a-c1e0-45b8-8a04-6a7c50a462354',
            name: 'Faturas de Contratos',
            content: <FaturasContratosContent />,
            image: faturasContratosImg,
          },
        ],
      },
    ],
  },
  {
    id: '691d0400-94ad-49bc-8aad-57b2fe8b4e56',
    name: 'Manutenção',
    content:
      'No Módulo de Manutenção são realizados os cadastros de pré OS e Calendário de Manutenção.',
    image: manutencaoImg,
    topics: [
      {
        id: '1f4b33b5-7b27-4a8d-8987-0f8a5a282c79',
        name: 'Pré OS',
        content: <PreOSContent />,
        image: preOsImg,
      },
      {
        id: '57937146-48aa-4554-8337-39e7c43437cc',
        name: 'Calendário de manutenção',
        content: <CalendarioManutencaoContent />,
        image: calendarioManutencaoImg,
      },
    ],
  },
  {
    id: '851d439d-0d78-49a1-81b6-c6aaa4b97a82',
    name: 'Controle de Acesso',
    content:
      'No Módulo de Controle de Acesso são realizados os cadastros dos usuários e dos perfis de acesso ao sistema. Nesse Módulo tem os submódulos de Perfis e Usuários, onde só pode ser acessado pelo administrador do sistema ou usuários autorizados.',
    image: controleAcessoImg,
    topics: [
      {
        id: '851d439d-0d78-49a1-81b6-c6aaa4b97a821',
        name: 'Perfil',
        content: <ProfileContent />,
        image: controleAcessoPerfilImg,
      },
      {
        id: '851d439d-0d78-49a1-81b6-c6aaa4b97a822',
        name: 'Usuários',
        content: <UsersContent />,
        image: controleAcessoUsuariosImg,
      },
    ],
  },
  {
    id: '6adfb609-6370-4126-84f9-cd336e8fa756',
    name: 'Cliente',
    content: <ClienteContent />,
    image: clienteImg,
  },
  {
    id: '6adfb609-6370-4126-84f9-cd336e8fa42s',
    name: 'Minhas Informações',
    content: <MyAccountContent />,
    image: null,
  },
  {
    id: '6adfb609-6370-4126-84f9-cd336e8fa42H',
    name: 'Tutorial do Sistema',
    content: <TutorialSistemaContent />,
    image: null,
  },
]

export function TutorialSearchPage() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchValue = search?.replace('?q=', '')?.replace('?topicId=', '')

  const findItemById = (
    item: TutorialType,
    search: string,
  ): TutorialType | undefined => {
    if (item.id?.toString()?.includes(search)) {
      return item
    }

    if (item.topics) {
      for (const subItem of item.topics) {
        const foundItem = findItemById(subItem, search)
        if (foundItem) {
          return foundItem
        }
      }
    }

    return undefined
  }

  const findTutorialById = (search?: string): TutorialType | undefined => {
    if (!search) return dataApi[0]

    const findTutorial = dataApi.find(
      (tutorial) => tutorial.id?.toString()?.includes(search),
    )

    if (findTutorial) return findTutorial

    for (const tutorial of dataApi) {
      if (tutorial.topics) {
        const foundItem = findItemById(tutorial, search)
        if (foundItem) {
          return foundItem
        }
      }
    }

    return undefined
  }

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['tutorial-search', searchValue],
    queryFn: () => findTutorialById(searchValue),
  })

  useEffect(() => {
    if (!search?.length) {
      navigate('/system-tutorial')
    }
  }, [search])

  return (
    <Stack spacing={3} paddingTop={3}>
      {(isLoading || isFetching) && <Loading mode='circular' />}

      {!isLoading && !data && (
        <Typography variant='h1'>Nenhum resultado encontrado</Typography>
      )}

      {!isLoading && data && <Tutorial data={data} />}
    </Stack>
  )
}
