import { Box, Menu, Stack, Typography, useTheme } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { NodeProps } from 'reactflow'

import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Icon, IconNamesProps } from 'components/Icon'
import { Restricted } from 'components/Restricted'
import { Sensor } from 'components/Sensor'
import { SensorSchemeImage } from 'components/Sensor/SensorScheme/SensorSchemeImage'
import { WavePulse } from 'components/WavePulse'
import { sensorColors } from 'configs/sensorColors'
import { listTypeEquipmentIcons } from 'constants/maps'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { getIconByEquipmentType } from 'helpers/equipment-type.helper'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { httpClient } from 'services/api/httpClient'
import { useMapStore } from 'store/map.store'
import { SensorOption, SensorType } from 'types/sensor/sensor'

import ParameterAlert from '../ParameterAlert'
import { Handles } from './Handles'
import { SensorData } from './SensorData'
import { EquipmentNodeItemWrapper } from './style'

export function SquareFeeder(props: NodeProps) {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const { loadingMap, setLoadingMap, setHiddenFeeders, hiddenFeeders } =
    useMapStore((state) => state)

  const [hidden, setHidden] = useState(
    hiddenFeeders?.find((hf) => hf.id === props.id)?.hidden ?? false,
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const icon = getIconByEquipmentType(
    EquipmentTypeEnum[
      props.data?.equipment.type as keyof typeof EquipmentTypeEnum
    ],
  )

  // Busca os dados do primeiro sensor do array de sensores
  const { data } = useQuery({
    queryKey: [
      'sensor',
      props.data?.equipment?.sensors?.[0]?.sensor?.id?.value,
    ],
    queryFn: () => {
      if (!props.data?.equipment?.sensors?.[0]?.sensor?.id?.value)
        return Promise.resolve(undefined)

      if (
        props.data?.equipment?.sensors?.[0]?.sensor?.type?.toUpperCase() !==
        'WATER'
      ) {
        return httpClient.get<any[]>(
          `/sensor/datarecord/${props.data?.equipment?.sensors?.[0]?.sensor?.id?.value}`,
        )
      }

      if (
        props.data?.equipment?.sensors?.[0]?.sensor?.type?.toUpperCase() ===
        'WATER'
      ) {
        return httpClient.get<any[]>(
          `/sensor/datarecord/water/${props.data?.equipment?.sensors?.[0]?.sensor?.id?.value}`,
        )
      }

      return Promise.resolve(undefined)
    },
    select: (data) => data?.data?.[0],
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    httpClient
      .delete(
        `/linked-area/${
          props.data?.area?.areaID?.value ??
          props.data?.linkedAreaId ??
          props.data?.id
        }`,
      )
      .then(() => {
        toast.success(t('information.delete.success'))
        props.data?.onDelete(props.id)
      })
      .catch((error) => {
        getErrorMessage(error)
      })
  }

  return (
    <>
      <Box
        sx={{
          background: (theme) => theme.palette.primary.main,

          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: props.selected
            ? theme.palette.primary.main
            : 'transparent',
          borderRadius: 1,
        }}
      >
        {props.data?.showStatus && props.data?.validShiftRangeTime && (
          <WavePulse
            status={props.data?.equipment?.sensors?.[0]?.sensor?.status}
            sx={{
              top: 6,
              right: -1,
            }}
          />
        )}
        <EquipmentNodeItemWrapper bgColor={'#c3c3c3'}>
          <img src={icon} alt='' height={15} width={15} onClick={handleClick} />
        </EquipmentNodeItemWrapper>
        <Box
          sx={{
            position: 'relative',
            filter: props.data?.validShiftRangeTime
              ? undefined
              : 'grayscale(100%)', // verificar o turno, caso não tenha turno ativo, para a hora e dia atual, desabilitar o sensor
          }}
        >
          {/* Exibir alerta em caso do sensor fora do parametro */}
          {props.data?.equipment?.sensors?.[0]?.sensor?.status &&
            props.data?.validShiftRangeTime && (
              <ParameterAlert
                parameters={props.data?.parameters}
                sensor={props.data?.equipment?.sensors?.[0]}
                value={data?.value}
                temperature={
                  data?.valueTemp
                    ? Number(Number(data?.valueTemp || 0).toFixed(0))
                    : undefined
                }
                humidity={
                  data?.valueHum
                    ? Number(Number(data?.valueHum || 0).toFixed(0))
                    : undefined
                }
              />
            )}
        </Box>

        <Handles />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id={props.id}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mb: 1.5,
            paddingX: [1, 2],
            '&.MuiPaper-root': {
              background: (theme) =>
                `${theme.palette.secondary.main} !important`,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Typography sx={{ opacity: 0.8 }}>{t('map.feeder')}</Typography>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={1}
          minWidth={180}
        >
          <Typography variant='body1'>{props.data?.equipment?.name}</Typography>

          <Box
            sx={{
              height: 10,
              width: 10,
              borderRadius: 5,
              background: (theme) =>
                props.data?.equipment?.status
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          />
        </Stack>

        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={1}
        >
          <Typography variant='body1'>
            {t('map.cod')}.: {props.data?.equipment?.code}
          </Typography>
          <Typography variant='body1'>|</Typography>

          <Typography variant='body1'>
            {t('map.mod')}.: {props.data?.equipment?.model}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={1}
        >
          <Typography variant='body1'>
            {t('map.area')}: {props.data?.area?.name}
          </Typography>
          {/* <Typography variant='body1'>|</Typography>

          <Typography variant='body1'>
            Mod.: {props.data?.equipment?.model}
          </Typography> */}
        </Stack>

        {/* <Typography sx={{ opacity: 0.8 }}>Temperatura ideal: 29c</Typography> */}

        <Stack
          direction={'column'}
          spacing={1}
          paddingY={1}
          borderTop={'1px solid #eee'}
          borderBottom={
            props.data?.onCreateOS || props.data?.onDelete
              ? '1px solid #eee'
              : undefined
          }
          marginY={1}
        >
          {props.data?.equipment?.file?.url && (
            <SensorSchemeImage
              src={props.data?.equipment?.file?.url}
              title='Ver foto'
            />
          )}
          {props.data?.equipment?.sensors?.map((sensor: SensorType) => (
            <Stack
              key={sensor?.id?.value}
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              <SensorData
                sensor={sensor}
                validShiftRangeTime={props.data?.validShiftRangeTime}
              />
            </Stack>
          ))}
        </Stack>

        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignContent={'center'}
          spacing={1}
        >
          <Restricted
            module={ModuleEnum.MAIN_CALENDAR}
            permission={PermissionEnum.CREATE}
          >
            {props?.data?.onCreateOS && (
              <Button
                variant='text'
                size='small'
                color='inherit'
                sx={{
                  minWidth: 'auto',
                }}
                onClick={() => props?.data?.onCreateOS()}
              >
                <Typography sx={{ textDecoration: 'underline' }}>
                  {t('map.createOs')}
                </Typography>
              </Button>
            )}
          </Restricted>

          {props.data?.onDelete && (
            <IconButton
              iconName='delete'
              size='small'
              variant='text'
              color='error'
              onClick={() => {
                sweetAlert({
                  onSuccess: () => handleDelete(),
                })
              }}
            />
          )}
        </Stack>
      </Menu>
    </>
  )
}
