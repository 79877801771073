import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { Container } from 'components/Container'

import { Header } from './components/Header'
import { TutorialMenu } from './components/TutorialMenu'

export function TutorialLayout() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      <Header marginBottom={0} paddingLeft={isDesktop ? 30 : 0} />

      <TutorialMenu isDesktop={isDesktop} />

      <Box component='main'>
        <Stack spacing={3} marginLeft={isDesktop ? 30 : 0} paddingBottom={3}>
          <Container>
            <Outlet />
          </Container>
        </Stack>
      </Box>
    </>
  )
}
