import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { SensorIconType } from 'components/Sensor'
import { ENVIRONMENT } from 'constants/environment'
import { ModuleEnum } from 'constants/modules'
import { useAuthStore } from 'store/auth.store'

import { DashboardEnergy } from './components/charts/DashboardEnergy'
import { DashboardGas } from './components/charts/DashboardGas'
import { DashboardSensor } from './components/charts/DashboardSensor'
import { DashboardTemperature } from './components/charts/DashboardTemperature'
import { DashboardWater } from './components/charts/DashboardWater'
import { DashboardHeader } from './components/DashboardHeader'

const dashboardModules = [
  {
    module: ModuleEnum.DASH_WATER,
    element: DashboardWater,
    icon: 'WATER',
  },
  {
    module: ModuleEnum.DASH_ENERGY,
    element: DashboardEnergy,
    icon: 'ENERGY',
  },
  {
    module: ModuleEnum.DASH_GAS,
    element: DashboardGas,
    icon: 'GAS',
  },
  {
    module: ModuleEnum.DASH_TEMPERATURE_HUMIDITY,
    element: DashboardTemperature,
    icon: 'TEMPERATURE_HUMIDITY',
  },
  {
    module: ModuleEnum.DASH,
    element: DashboardSensor,
    icon: 'SENSOR',
  },
]

export type DashboardOptionType = {
  active: SensorIconType
  mode: 'grid' | 'presentation'
}

export type DashboardActionType = {
  type: 'active' | 'mode'
  value: string
}

export function DashboardPage() {
  const { user } = useAuthStore((state) => state)

  const [mode, setMode] = useState<'grid' | 'presentation'>('grid')
  const [buttons, setButtons] = useState<SensorIconType[]>([])
  const [activeButtonIndex, setActiveButtonIndex] = useState(() => {
    const selectDashboard = localStorage.getItem(
      `@${ENVIRONMENT.APP_NAME}:active.dashboard`,
    )

    if (selectDashboard) {
      return Number(selectDashboard)
    }

    return 0
  })
  const [dashboards, setDashboards] = useState<React.ElementType[]>([])

  useEffect(() => {
    function loadButtons() {
      const dashboards: React.ElementType[] = []
      const dashboardsIcons: SensorIconType[] = []

      dashboardModules.forEach((dashboard) => {
        if (user && user?.profile?.name?.toLowerCase()?.includes('admin')) {
          dashboards.push(dashboard.element)
          dashboardsIcons.push(dashboard.icon as SensorIconType)
        } else if (
          user?.profile?.permissions?.filter(
            (item) => item?.feature?.name?.includes(dashboard.module),
          )?.length
        ) {
          dashboards.push(dashboard.element)
          dashboardsIcons.push(dashboard.icon as SensorIconType)
        }
      })

      setDashboards(dashboards)
      setButtons(dashboardsIcons)
    }

    loadButtons()
  }, [dashboardModules, user])

  const handleMode = (value: 'grid' | 'presentation') => {
    setMode(value)
  }

  const Dashboard = dashboards[activeButtonIndex]

  const handleSelectButton = (index: number) => {
    setActiveButtonIndex(index)
    localStorage.setItem(
      `@${ENVIRONMENT.APP_NAME}:active.dashboard`,
      index.toString(),
    )
  }

  return (
    <Stack>
      <DashboardHeader
        mode={mode}
        onMode={handleMode}
        buttons={buttons}
        activeButtonIndex={activeButtonIndex}
        onClickButton={(index) => handleSelectButton(index)}
      />

      {!!dashboards.length && <Dashboard mode={mode} />}
    </Stack>
  )
}
