const Modules: any = {
  DASH_WATER: 'DASHBOARD DE ÁGUA',
  DASH_ENERGY: 'DASHBOARD DE ENERGIA',
  DASH_GAS: 'DASHBOARD DE GÁS',
  DASH_TEMPERATURE: 'DASHBOARD DE TEMPERATURA',
  DASH_TEMPERATURE_HUMIDITY: 'DASHBOARD DE TEMPERATURA E UMIDADE',
  DASH_HUMIDITY: 'DASHBOARD DE UMIDADE',
  DASH_ADVANCED: 'DASHBOARD AVANÇADO',

  /** CONF: Configuração */
  CONF_SENSOR: 'CONFIGURAÇÃO DE SENSORES',
  CONF_EQUIPMENT: 'CONFIGURAÇÃO DE EQUIPAMENTOS',
  CONF_CONTROL_BRANCH_OFFICE: 'CONFIGURAÇÃO DE CONTROLE DE FILIAIS',
  CONF_ALERTS: 'CONFIGURAÇÃO DE ALERTAS',
  CONF_NOTIFICATIONS: 'CONFIGURAÇÃO DE NOTIFICAÇÕES',
  CONF_GOAL: 'CONFIGURAÇÃO DE METAS',
  CONF_PARAMETER: 'CONFIGURAÇÃO DE PARÂMETROS',
  CONF_INVOICE: 'CONFIGURAÇÃO DE FATURAMENTO',
  CONF_MAP: 'CONFIGURAÇÃO DE MAPA',
  CONF_MAP_WATER: 'CONFIGURAÇÃO DE MAPA DE ÁGUA',
  CONF_MAP_ENERGY: 'CONFIGURAÇÃO DE MAPA DE ENERGIA',
  CONF_MAP_GAS: 'CONFIGURAÇÃO DE MAPA DE GÁS',
  CONF_MAP_TEMPERATURE: 'CONFIGURAÇÃO DE MAPA DE TEMPERATURA',
  CONF_MAP_TEMPERATURE_HUMIDITY:
    'CONFIGURAÇÃO DE MAPA DE TEMPERATURA E UMIDADE',
  CONF_MAP_HUMIDITY: 'CONFIGURAÇÃO DE MAPA DE UMIDADE',

  /** MON: monitoring */
  MON_WATER: 'MONITORAMENTO DE ÁGUA',
  MON_ENERGY: 'MONITORAMENTO DE ENERGIA',
  MON_GAS: 'MONITORAMENTO DE GÁS',
  MON_TEMPERATURE: 'MONITORAMENTO DE TEMPERATURA',
  MON_TEMPERATURE_HUMIDITY: 'MONITORAMENTO DE TEMPERATURA E UMIDADE',
  MON_HUMIDITY: 'MONITORAMENTO DE UMIDADE',

  /** MAIN: maintenance */
  MAIN_CALENDAR: 'CALENDÁRIO',
  MAIN_CONFIG_SCALING: 'CONFIGURAÇÃO DE ESCALONAMENTO',

  /** ACC: access control */
  ACC_USER: 'USUÁRIO',
  ACC_PROFILE: 'PERFIL',

  /** CLI: client */
  CLI_CLIENT: 'CLIENTE',

  /** BTN: button */
  BTN_PROCEDURE: 'ALERTA E BOTÃO DO PÂNICO',
  BTN_MONITORING: 'MONITORAMENTO DO BOTÃO DO PÂNICO',

  GOAL_WATER: 'META DE ÁGUA',
  GOAL_ENERGY: 'META DE ENERGIA',
  GOAL_GAS: 'META DE GÁS',
  PARAMETER_TEMPERATURE: 'PARÂMETRO DE TEMPERATURA',
  PARAMETER_HUMIDITY: 'PARÂMETRO DE UMIDADE',
  PARAMETER_TEMPERATURE_HUMIDITY: 'PARÂMETRO DE TEMPERATURA E UMIDADE',
  MAP_MONITORING: 'MONITORAMENTO DE SENSORES',
  MAP_MOUNT_MAP: 'MONTAR MAPA',
  MAP_SHIFTS: 'TURNOS',
  MAP_MEASUREMENT_AREA: 'ÁREA DE MEDIÇÃO',
  MAIN_PRE_OS: 'PRÉ-OS',
  SENSOR_DOOR: 'SENSOR DE PORTA',
}

const findModule = (module: string) => {
  return Modules[module]
}

export { Modules, findModule }
