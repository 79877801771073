import { Grid, Stack, Typography } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { FanSpeedEnum } from 'core/enums/FanSpeedEnum'
import { ModeEnum } from 'core/enums/ModeEnum'
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { IconButton } from 'components/Button/IconButton'
import { Select } from 'components/Select'
import { Autocomplete } from 'components/Select/Autocomplete'
import { AutocompleteLabel } from 'components/Select/AutocompleteLabel'
import { Textarea } from 'components/Textarea'
import { getTemperatureList } from 'constants/temperatures'
import { getAllEquipmentByType } from 'services/equipments/getAllEquipmentByType'
import { CreateControllerType } from 'types/equipment/create-equipment'

interface Props {
  control: Control<CreateControllerType>
  nestIndex: number
  isView?: boolean
  errors: FieldErrors<CreateControllerType>
  setValue: UseFormSetValue<CreateControllerType>
  getValues?: UseFormGetValues<CreateControllerType>
  watch: UseFormWatch<CreateControllerType>
  clearErrors: UseFormClearErrors<CreateControllerType>
  removeGroup: (index: number) => void
}

export const ControllerNestedCommands = ({
  control,
  nestIndex,
  isView,
  clearErrors,
  errors,
  getValues,
  setValue,
  watch,
  removeGroup,
}: Props) => {
  const { t } = useTranslation('common')

  const {
    fields: commandsFields,
    append: appendCommand,
    remove: removeCommand,
  } = useFieldArray({
    control,
    name: `groupController.${nestIndex}.commands`,
  })

  const addCommandsAndAirConditioners = () => {
    appendCommand({
      mode: '',
      fan: '',
      temperature: 16,
      command: '',
      id: null,
    })
  }

  function showButtonDeleteCommand({
    index,
    isView,
  }: {
    index: number
    isView?: boolean
  }) {
    return index !== 0 && !isView
  }

  const { data = [] } = useQuery({
    queryKey: 'equipmentsArcon',
    queryFn: () => getAllEquipmentByType(EquipmentTypeEnum.AIR_CONDITIONING),
    select: (data) => {
      return data.map((equipment) => ({
        id: equipment.id.value,
        name: equipment.name,
      }))
    },
  })

  return (
    <>
      <Grid
        item
        container
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        width={'100%'}
        minWidth={300}
      >
        <Grid item xs={nestIndex !== 0 ? 10 : 12}>
          <AutocompleteLabel
            name={`groupController[${nestIndex}].airConditioners`}
            label={String(t('input.selectAirConditioner'))}
            placeholder={t('equipment.selectAirConditioners') ?? ''}
            options={
              data?.map((item: any) => ({
                label: item.name,
                value: item.id,
              })) ?? []
            }
            isMulti
            control={control}
            error={!!errors?.groupController?.[nestIndex]?.airConditioners}
            errorMessage={
              errors?.groupController?.[nestIndex]?.airConditioners?.message
            }
            value={watch(`groupController.${nestIndex}.airConditioners`)}
            onChange={(newValue) => {
              clearErrors(`groupController.${nestIndex}.airConditioners`)
              setValue(
                `groupController.${nestIndex}.airConditioners`,
                (newValue as Array<{ value: string }>) ?? [],
              )
            }}
            required
            isDisabled={isView}
          />
        </Grid>
        {nestIndex !== 0 && (
          <Grid item xs={2}>
            <Stack
              direction={'row'}
              alignItems='flex-end'
              justifyContent={'center'}
            >
              <IconButton
                iconName='delete'
                variant={'contained'}
                onClick={() => removeGroup(nestIndex)}
              />
            </Stack>
          </Grid>
        )}
      </Grid>

      {commandsFields.map((command, commandIndex) => (
        <Grid
          item
          container
          xs={12}
          key={command.id}
          direction={'row'}
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Grid
            item
            direction={'row'}
            xs={12}
            justifyContent={'space-between'}
            alignContent={'center'}
            mb={1}
          >
            <Stack
              direction={'row'}
              alignItems={'space-between'}
              width={'100%'}
              justifyContent={'space-between'}
            >
              <Typography variant='body1' textAlign={'left'} fontSize={'11px'}>
                {t('equipment.command')} {commandIndex + 1}
              </Typography>
              {showButtonDeleteCommand({ index: commandIndex, isView }) && (
                <IconButton
                  size={'small'}
                  iconName='delete'
                  variant={'contained'}
                  onClick={() => removeCommand(commandIndex)}
                />
              )}
            </Stack>
          </Grid>
          <Grid
            item
            container
            direction='row'
            alignItems='flex-start'
            justifyContent={'flex-start'}
            spacing={2}
            mb={2}
            xs={12}
          >
            <Grid item xs={4}>
              <Select
                name={`groupController[${nestIndex}].commands[${commandIndex}].mode`}
                label={t('equipment.mode')}
                options={Object.values(ModeEnum).map((mode) => ({
                  id: mode,
                  name: String(t(`equipment.controller.mode.${mode}`)),
                }))}
                control={control}
                fullWidth
                error={
                  !!errors?.groupController?.[nestIndex]?.commands?.[
                    commandIndex
                  ]?.mode
                }
                errorMessage={
                  errors?.groupController?.[nestIndex]?.commands?.[commandIndex]
                    ?.mode?.message
                }
                required
                disabled={isView}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                name={`groupController[${nestIndex}].commands[${commandIndex}].fan`}
                label={'Fan'}
                options={Object.values(FanSpeedEnum).map((mode) => ({
                  id: mode,
                  name: String(t(`equipment.controller.fan.${mode}`)),
                }))}
                control={control}
                fullWidth
                error={
                  !!errors?.groupController?.[nestIndex]?.commands?.[
                    commandIndex
                  ]?.fan
                }
                errorMessage={
                  errors?.groupController?.[nestIndex]?.commands?.[commandIndex]
                    ?.fan
                }
                required
                disabled={isView}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                name={`groupController[${nestIndex}].commands[${commandIndex}].temperature`}
                label={t('equipment.temperature')}
                options={getTemperatureList()}
                control={control}
                fullWidth
                error={
                  !!errors?.groupController?.[nestIndex]?.commands?.[
                    commandIndex
                  ]?.temperature
                }
                errorMessage={
                  errors?.groupController?.[nestIndex]?.commands?.[commandIndex]
                    ?.temperature?.message
                }
                required
                disabled={isView}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Textarea
              name={`groupController[${nestIndex}].commands[${commandIndex}].command`}
              label={String(t('equipment.command'))}
              placeholder={t('equipment.command') ?? ''}
              value={command.command}
              control={control}
              minRows={3}
              maxLength={200}
              disabled={isView}
              error={
                !!errors?.groupController?.[nestIndex]?.commands?.[commandIndex]
                  ?.command
              }
              errorMessage={
                errors?.groupController?.[nestIndex]?.commands?.[commandIndex]
                  ?.command?.message
              }
              required
            />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Stack direction='row' alignItems='center' justifyContent={'center'}>
          <IconButton
            size={'small'}
            iconName='add'
            variant={'contained'}
            onClick={() => addCommandsAndAirConditioners()}
            disabled={isView}
          />
        </Stack>
      </Grid>
    </>
  )
}
