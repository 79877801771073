import { Stack, StackProps } from '@mui/material'

interface MyAccountItemRootProps extends StackProps {
  children: React.ReactNode
}

export function MyAccountItemRoot({
  children,
  ...rest
}: MyAccountItemRootProps) {
  return (
    <Stack
      direction={'row'}
      pb={2}
      mb={2}
      borderBottom={(theme) => `1px solid ${theme.palette.secondary.light}`}
      spacing={2}
      {...rest}
    >
      {children}
    </Stack>
  )
}
