export function DashboardEnergyContratadoConsumidoContent() {
  return (
    <>
      <p>
        Nesse gráfico são mostrados os comparativos de meses anteriores em
        relação aos custos de compra de energia pelo mercado livre de energia.
        Pode-se realizar o filtro nesse gráfico por semana, mês e ano,
        selecionando o período.
      </p>
      <p>
        Os dados disponibilizados nesse gráfico são cadastrados em faturas de
        mercado livre, o cadastro deve ser realizado em Configurações {'->'}{' '}
        Faturas {'->'} Mercado Livre, selecione Configurações nesse tutorial
        para saber mais.
      </p>
    </>
  )
}
