import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { HeaderAction } from 'components/HeaderAction'
import { Table, TableActionType } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { ViewAllSensors } from 'pages/setting/components/ViewAllSensors'
import { listEquipments } from 'services/equipments/listEquipments'
import { EquipmentType } from 'types/equipment/equipment'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

import { EquipmentTypeEnum } from '../../core/enums/EquipmentTypeEnum'
import { FormEquipmentModal } from './components/FormEquipmentModal'
import { equipmentsMapper } from './mappers/equipments.mapper'

const NUMBER_PER_PAGE = 10

export function EquipmentPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const queryClient = useQueryClient()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelected] = useState<
    (Omit<EquipmentType, 'id'> & { id?: number | any }) | null
  >(null)

  const [isViewAllSensor, setIsViewAllSensor] = useState(false)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<EquipmentType>,
    Error
  >({
    queryKey: ['equipments', page, search, orderBy, order],
    queryFn: () =>
      listEquipments({
        page,
        search,
        columnSort: order,
        columnName: orderBy,
        numberPerPage: NUMBER_PER_PAGE,
      }),
  })

  const equipments = equipmentsMapper(
    data?.items ?? [],
    setSelected,
    setIsViewAllSensor,
  )

  const handleOpen = () => setOpenForm(true)

  const handleCancel = () => {
    setOpenForm(false)
    setOpenFormView(false)
    setSelected(null)
    setIsViewAllSensor(false)
  }

  const handleEdit = (item: EquipmentType) => {
    setSelected(item)
    handleOpen()
  }

  const handleView = (item: EquipmentType) => {
    setSelected(item)
    setOpenFormView(true)
  }

  const handleDuplicate = (item: EquipmentType) => {
    setSelected({
      name: item.name,
      model: item.model,
      type: item.type,
      procedures: item.procedures,
      brand: item.brand,
      code: item.code,
      feeder: item.feeder,
      gps: false,
      mobile: false,
      file: item.file,
      sensors: [],
      specifications: item.specifications,
      cameras: item.cameras,
    })
    handleOpen()
  }

  const handleDelete = (item: EquipmentType) => {
    let route = 'equipment'
    if (item.type === EquipmentTypeEnum.CONTROLLER) route = 'control'

    httpDelete(`/${route}/${item?.id?.value || item?.id}`, () => {
      refetch()
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'equipments' ||
          query.queryKey[0] === 'all-sensors-equipments' ||
          query.queryKey[0] === 'all-equipments',
      })
      toast.success(t('savedInformation'))
    })
  }

  const columns: ColumnType[] = [
    {
      id: 'feeder',
      label: t('table.equipment.feederName'),
    },
    {
      id: 'filePhoto',
      label: t('table.equipment.photo'),
    },
    {
      id: 'name',
      label: t('table.equipment.name'),
    },
    {
      id: 'typeName',
      label: t('table.equipment.equipmentTypeName'),
    },
    {
      id: 'code',
      label: t('table.equipment.code'),
    },
    {
      id: 'brand',
      label: t('table.equipment.brand'),
    },
    {
      id: 'sensors',
      label: t('table.equipment.muiSensor'),
    },
  ]

  const tableActions: TableActionType<any>[] = [
    {
      type: 'duplicate',
      onClick: (row) => handleDuplicate(row),
      module: ModuleEnum.CONF_PROCEDURE,
      permission: PermissionEnum.CREATE,
    },
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.CONF_PROCEDURE,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.CONF_PROCEDURE,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.CONF_PROCEDURE,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <>
      <HeaderAction
        onCreate={handleOpen}
        onSearch={(search) => {
          setPage(1)
          setSearch(search)
        }}
      />

      <Table<EquipmentType>
        columns={columns}
        rows={equipments}
        orderBy={orderBy}
        order={order}
        onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
        onOrderBy={(newOrderBy) => {
          if (newOrderBy !== 'file' && newOrderBy !== 'sensors')
            setOrderBy(newOrderBy)

          if (newOrderBy === 'typeName') setOrderBy('type')

          return
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.total,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView) && (
        <FormEquipmentModal
          data={selected}
          isView={openFormView}
          open={openForm || openFormView}
          onClose={handleCancel}
        />
      )}

      {isViewAllSensor && (
        <ViewAllSensors
          data={selected}
          open={isViewAllSensor}
          onClose={handleCancel}
        />
      )}
    </>
  )
}
