import { Stack } from '@mui/material'

import { Menu } from 'components/Menu'
import { useMenu } from 'hooks/useMenu'

export function MapPage() {
  const { activeMenu } = useMenu()

  return (
    <Stack>
      <Menu options={activeMenu?.submenu ?? []} />
    </Stack>
  )
}
