import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { TableActionType } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { branchService } from 'services/branch.service'
import { DataBranchType } from 'types/branch/branch'
import { PaginationType } from 'types/pagination'
import { ColumnType } from 'types/table/column'

export function useBranches() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [openFormEdit, setOpenFormEdit] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [selected, setSelected] = useState<DataBranchType | null>(null)

  const { data, isLoading, isFetching, error, refetch } = useQuery<
    PaginationType<DataBranchType>,
    Error
  >({
    queryKey: ['list-branches', page, search, orderBy],
    queryFn: () =>
      branchService.listBranches({ page, search, columnSort: orderBy }),
    select: (data) => {
      return (
        {
          ...data,
          items: data.items?.map((item) => ({
            ...item,
            utilities: item.utilities.map((utility) => ({
              id: utility.utility.id.value,
              name: utility.utility.name,
            })),
          })),
        } ?? []
      )
    },
  })

  const branches = data?.items ?? []

  function handleCreate() {
    setOpenForm(true)
  }

  function handleCancel() {
    setOpenForm(false)
    setOpenFormView(false)
    setOpenFormEdit(false)
    setSelected(null)
  }

  function handleEdit(item: DataBranchType) {
    setSelected(item)
    setOpenFormEdit(true)
  }

  function handleView(item: DataBranchType) {
    setSelected(item)
    setOpenFormView(true)
  }

  function handleDelete(item: DataBranchType) {
    httpDelete(`/company/${item.id.value}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'unit',
      label: t('units.unit'),
    },
    {
      id: 'city',
      label: t('units.city'),
    },
    {
      id: 'state',
      label: t('units.state'),
    },
    {
      id: 'address',
      label: t('units.address'),
    },
    {
      id: 'zipCode',
      label: t('units.zipCode'),
    },
    {
      id: 'utilities',
      label: t('units.utilities'),
    },
  ]

  const tableActions: TableActionType<DataBranchType>[] = [
    {
      type: 'show',
      onClick: (row) => handleView(row),
      module: ModuleEnum.ACC_UNIT,
      permission: PermissionEnum.VIEW,
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
      module: ModuleEnum.ACC_UNIT,
      permission: PermissionEnum.EDIT,
    },
    {
      type: 'delete',
      module: ModuleEnum.ACC_UNIT,
      permission: PermissionEnum.DELETE,
      onClick: (row) =>
        sweetAlert({
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return {
    t,
    openForm,
    setOpenForm,
    openFormView,
    setOpenFormView,
    page,
    setPage,
    search,
    setSearch,
    orderBy,
    setOrderBy,
    columns,
    handleCreate,
    handleDelete,
    handleEdit,
    handleView,
    handleCancel,
    selected,
    setSelected,
    openFormEdit,
    setOpenFormEdit,
    sweetAlert,
    data,
    branches,
    isLoading,
    isFetching,
    error,
    tableActions,
  }
}
