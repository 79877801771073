import { Box, Stack } from '@mui/material'
import React, { useState } from 'react'

import { CardButton } from '../../CardButton'
import { DashboardProgressTimeRefresh } from '../../DashboardProgressTimeRefresh'
import { PreviousAndNextButtons } from '../../PreviousAndNextButtons'
import { IndoorVSOutdoorAmbientTemperatureChart } from './IndoorVSOutdoorAmbientTemperatureChart'
import { ModeContainer } from './ModeContainer'
import { RealTimeHumidityChart } from './RealTimeHumidityChart'
import { RealTimeTemperatureChart } from './RealTimeTemperatureChart'
import { TemperatureHumidityByAreaChart } from './TemperatureHumidityByAreaChart'

interface Props {
  mode: 'grid' | 'presentation'
}

const charts = [
  { component: TemperatureHumidityByAreaChart },
  { component: IndoorVSOutdoorAmbientTemperatureChart },
  { component: RealTimeTemperatureChart },
  { component: RealTimeHumidityChart },
]

export function DashboardTemperature(props: Props) {
  const { mode } = props
  const [currentChart, setCurrentChart] = useState<number>(1)

  const handlePrevious = () =>
    setCurrentChart((state) => (state <= 1 ? 1 : state - 1))

  const handleNext = () =>
    setCurrentChart((state) =>
      state >= charts?.length ? charts?.length : state + 1,
    )

  const ChartPresentation = charts[currentChart - 1]?.component

  return (
    <>
      <DashboardProgressTimeRefresh timeInMinutes={5} />

      <ModeContainer mode={mode} type='grid'>
        {charts.map((Chart, index) => (
          <React.Fragment key={index}>
            <Chart.component />
          </React.Fragment>
        ))}
      </ModeContainer>

      <ModeContainer mode={mode} type='presentation'>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            borderRadius: 1,
            width: '100%',
            minHeight: [200, 500],
            overflowX: ['auto', 'unset'],
            background: (theme) => theme.palette.background.paper,
            paddingX: [0, 3],
          }}
        >
          <ChartPresentation />

          <PreviousAndNextButtons
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </Box>

        <Stack
          flex={1}
          direction='row'
          spacing={3}
          sx={{
            overflowX: 'auto',
            paddingBottom: 1,
            '&::-webkit-scrollbar': {
              width: 6,
              height: 6,
              background: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: (theme) => theme.palette.background.default,
            },
          }}
        >
          {charts.map((Chart, index) => (
            <React.Fragment key={index}>
              <CardButton onClick={() => setCurrentChart(index + 1)}>
                <Chart.component isPreview />
              </CardButton>
            </React.Fragment>
          ))}
        </Stack>
      </ModeContainer>
    </>
  )
}
