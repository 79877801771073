export function DashboardGasTempoRealContent() {
  return (
    <>
      <p>
        Nesse gráfico são informados os tipos de gazes que estão sendo liberados
        em tempo real. Nesse gráfico pode ser filtrado por semana, mês e ano
        selecionando o período.
      </p>
      <p>
        Os dados obtidos desse gráfico são obtidos diretamente pelo sensor de
        gás, caso esse gráfico não esteja disponibilizando o valor, será
        necessário realizar o cadastro dos sensores em Configurações {'->'}{' '}
        Sensores, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
