export function DashboardWaterGeneralConsumptionContent() {
  return (
    <>
      <p>
        Nesse gráfico é possível verificar o comparativo entre o consumo (barras
        azuis ) em metros cúbicos, a fatura relacionada àquele mês (barra clara
        ) e o custo geral da água (a linha roxa ).
      </p>
      <p>
        Nesse gráfico, podem ser selecionados os dados por ano, por mês e por
        semana. Os dados relacionados de consumo e faturas são cadastrados em
        Configurações {'->'} Faturas {'->'} Água, selecione Configurações nesse
        tutorial para saber mais.
      </p>
      <p>
        Os dados disponibilizados sobre custo geral de água é um cálculo
        realizado conforme o cadastro de faturas, por meio da divisão entre
        faturas e consumo.
      </p>
    </>
  )
}
