export function DashboardEnergyConsumoContent() {
  return (
    <>
      <p>
        Nesse gráfico é possível verificar o comparativo de consumo de energia
        por setores. São mostradas as metas para cada setor, se o consumo passou
        da meta ou se ainda não alcançou. Nesse gráfico é possível filtrar o
        consumo por meio dos filtros de semana, mês e ano com seleção de datas.
        Conforme o cadastro de setores medidos, são disponibilizadas cores
        diferenciadas. Também podem ser realizados os comparativos entre o
        consumo de cada linha de produção. Para verificar por linha:
      </p>
      <p>
        <strong>Passo 1:</strong> selecione setor o filtro da direita
      </p>
      <p>
        <strong>Passo 2:</strong> selecione dois ou mais tipos de linhas dentro
        do setor produção.
      </p>
      <p>
        Os dados disponibilizados de meta são cadastrados em Configurações{' '}
        {'->'} Metas e Parâmetros {'->'} Metas, selecione Configurações nesse
        tutorial para saber mais.
      </p>
      <p>
        Os dados relacionados a setores devem ser cadastrados em Mapa {'->'}{' '}
        Áreas, selecione Mapa nesse tutorial para saber mais.
      </p>
      <p>
        Os dados disponibilizados de consumo de água são cadastrados em faturas
        de água, o cadastro deve ser realizado em Configurações {'->'} Faturas{' '}
        {'->'} Energia, selecione Configurações nesse tutorial para saber mais.
      </p>
    </>
  )
}
