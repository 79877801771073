import { useTheme } from '@mui/material'
import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'

import {
  getDescriptionByEquipmentType,
  getIconByEquipmentType,
} from 'helpers/equipment-type.helper'

import { NodeDetailing } from '../NodeDetailing'
import { EquipmentNodeItemWrapper } from './styles'

type Props = {
  data: {
    label: string
    handleClick?: () => void
    type: EquipmentTypeEnum
    code: string
    manufacturer?: {
      name: string
    }
  }
}

export const EquipmentNode = ({ data }: Props) => {
  const theme = useTheme()
  const icon = getIconByEquipmentType(data.type)

  return (
    <div onClick={data.handleClick}>
      <EquipmentNodeItemWrapper bgColor={theme.palette.primary.main}>
        <img src={icon} alt={data.label} />
      </EquipmentNodeItemWrapper>
      <NodeDetailing
        label={data.label}
        metaData={[
          {
            label: 'Tipo',
            value: getDescriptionByEquipmentType(data.type),
          },
          {
            label: 'Code',
            value: data.code,
          },
          {
            label: 'Fabricante',
            value: data.manufacturer?.name ?? 'não identificado',
          },
        ]}
      />
    </div>
  )
}
