/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Toggle } from 'components/Toggle'
import { userService } from 'services/user.service'
import { UsersType } from 'types/user/user'

export function usersMapper(users: UsersType[]) {
  const queryClient = useQueryClient()

  const handleToggleStatus = async (id: string) => {
    try {
      await userService.updateStatus(id)
      queryClient.invalidateQueries('users')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  return users.map((item) => ({
    ...item,
    id: item?.entityId?.value,
    profileName: item?.profile?.name ?? '',
    muiStatus: (
      <Toggle
        name='toggle'
        checked={item.active}
        // eslint-disable-next-line no-console
        onChange={() => {
          handleToggleStatus(
            item?.entityId?.value ?? item?.id?.value ?? item?.id,
          )
        }}
      />
    ),
  }))
}
