import { useLocation } from 'react-router-dom'

import { mainMenu } from 'configs/menuConfig'
import { getRoutesAndNames } from 'helpers/getRoutesAndNames'
import { useAuthStore } from 'store/auth.store'

export function useMenu() {
  const menus = mainMenu
  const { pathname } = useLocation()
  const { user } = useAuthStore((state) => state)

  // Filter menus by user permissions
  const filterMenus = menus?.filter((item) => {
    return item.module
      ? !!user?.profile?.permissions?.filter(
          (permission: any) =>
            permission?.feature?.name
              ?.toUpperCase()
              ?.includes(item?.module?.toUpperCase()),
        )?.length || user?.profile?.name?.toLowerCase()?.includes('admin')
      : true
  })

  // Filter submenus by user permissions
  const filterSubmenus = filterMenus?.map((menu) => {
    if (menu.submenu) {
      const submenu = menu.submenu?.filter((item) => {
        return item.module
          ? !!user?.profile?.permissions?.filter(
              (permission: any) =>
                permission?.feature?.name
                  ?.toUpperCase()
                  ?.includes(item?.module?.toUpperCase()),
            )?.length || user?.profile?.name?.toLowerCase()?.includes('admin')
          : true
      })
      return { ...menu, submenu }
    }
    return menu
  })

  const activeMenu = filterSubmenus?.find((menu) =>
    pathname.includes(menu.route),
  )

  const routes = getRoutesAndNames(filterSubmenus)

  return { menus: filterSubmenus, activeMenu, routes }
}
