import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderAction } from 'components/HeaderAction'
import { Tabs } from 'components/Tabs'
import { ModuleEnum, PermissionEnum } from 'constants/modules'

import { InvoiceContract } from './components/invoice/InvoiceContract'
import { InvoiceEnergy } from './components/invoice/InvoiceEnergy'
import { InvoiceMercadoLivre } from './components/invoice/InvoiceMercadoLivre'
import { InvoiceWater } from './components/invoice/InvoiceWater'

export function InvoicePage() {
  const { t } = useTranslation('common')
  const [search, setSearch] = useState('')
  const [tab, setTab] = useState(1)
  const [openForm, setOpenForm] = useState(false)

  const tabOptions = [
    {
      label: t('water'),
      value: 1,
    },
    {
      label: t('energy'),
      value: 2,
    },
    {
      label: 'Mercado livre',
      value: 3,
    },
    {
      label: t('contracts'),
      value: 4,
    },
  ]

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const onButtonTitle = (tab: number) => {
    if (tab === 4) return t('newContract')

    const findTab = tabOptions.find((item) => item.value === tab)

    if (findTab) {
      return t('newInvoiceFrom', {
        type: findTab?.label?.toLocaleLowerCase(),
      })
    }

    return null
  }

  return (
    <>
      <HeaderAction
        onCreate={() => setOpenForm(true)}
        // onSearch={(search) => setSearch(search)}
        buttonTitle={onButtonTitle(tab)}
        module={ModuleEnum.CONF_INVOICE}
        permission={PermissionEnum.CREATE}
      />

      <Tabs options={tabOptions} value={tab} onChange={handleChangeTab}>
        <InvoiceWater
          currentTab={tab}
          index={1}
          search={search}
          openForm={openForm}
          onCloseForm={() => setOpenForm(false)}
        />
        <InvoiceEnergy
          currentTab={tab}
          index={2}
          search={search}
          openForm={openForm}
          onCloseForm={() => setOpenForm(false)}
        />
        <InvoiceMercadoLivre
          currentTab={tab}
          index={3}
          search={search}
          openForm={openForm}
          onCloseForm={() => setOpenForm(false)}
        />
        <InvoiceContract
          currentTab={tab}
          index={4}
          search={search}
          openForm={openForm}
          onCloseForm={() => setOpenForm(false)}
        />
      </Tabs>
    </>
  )
}
