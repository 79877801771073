import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { EquipmentType } from 'types/equipment/equipment'

import { FormNotificationModal } from './components/FormNotificationModal'
import { useNotification } from './hooks/useNotification'

export function NotificationPage() {
  const {
    tableActions,
    openForm,
    openFormView,
    selectedNotification,
    columns,
    notifications,
    data,
    handleCancel,
    handleOpen,
    setSearch,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    isFetching,
    isLoading,
    error,
    page,
    setPage,
  } = useNotification()

  return (
    <>
      <HeaderAction
        onCreate={handleOpen}
        onSearch={(search) => setSearch(search)}
        module={ModuleEnum.CONF_NOTIFICATIONS}
        permission={PermissionEnum.CREATE}
      />

      <Table<EquipmentType>
        columns={columns}
        rows={notifications}
        orderBy={orderBy}
        order={order}
        onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
        onOrderBy={(newOrderBy) => {
          if (newOrderBy !== 'moduleMapper' && newOrderBy !== 'usersMapper')
            setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.total,
          onChangePage: setPage,
        }}
      />

      {(openForm || openFormView) && (
        <FormNotificationModal
          data={selectedNotification}
          isView={openFormView}
          open={openForm || openFormView}
          onClose={handleCancel}
        />
      )}
    </>
  )
}
