import { Grid, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import MonitorLightImg from 'assets/icons/light/select-unit-light.svg'
import MonitorImg from 'assets/icons/monitor-unidade.svg'
import { Button } from 'components/Button'
import { IconButton } from 'components/Button/IconButton'
import { Select } from 'components/Select'
import { TEXT_TITLE_14, TEXT_SUBTEXT } from 'constants/textTypes'
import { IBranchList } from 'services/branch.service'
import { useMapStore } from 'store/map.store'

interface IProps {
  data?: IBranchList
}

export function BranchSidebar({ data }: Readonly<IProps>) {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { t } = useTranslation('common')

  const isDark = theme.palette.mode === 'dark'

  const {
    setCurrentCompany,
    currentCompany,
    sidebarVisibility,
    toggleSidebarVisibility,
    maps,
  } = useMapStore()

  const [unit, setUnit] = useState({
    id: { value: '' },
    name: '',
  })

  const verifyIfExistsArea = () => {
    if (maps && maps.length === 0) {
      toast.info(t('mapPanic.message.info'))
    }
  }

  return (
    <Stack
      direction='column'
      sx={{
        width: '100%',
        maxWidth: !currentCompany ? 280 : sidebarVisibility ? 280 : 30,
        zIndex: 1,
        background: (theme) => theme.palette.background.default,
        borderRight: '2px solid',
        borderColor: (theme) => theme.palette.secondary.light,
        p: 2,
        position: 'relative',
        transition: 'max-width 0.3s',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: !currentCompany
            ? 'initial'
            : sidebarVisibility
            ? 'initial'
            : 'none',
        }}
      >
        <Grid item xs={12}>
          <Select
            label='Selecione uma unidade'
            name='branch'
            options={data?.map((branch) => ({
              id: branch.id.value,
              name: branch.name,
            }))}
            onChange={(event) => {
              setUnit({
                id: { value: String(event.target.value) },
                name: event.target.name,
              })
            }}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction='row' alignItems='center' justifyContent='center'>
            <Button
              variant='contained'
              sx={{ width: '100%', mb: 2 }}
              onClick={() => {
                setCurrentCompany(unit.id.value)
                queryClient.invalidateQueries('company-map')
                verifyIfExistsArea()
              }}
            >
              Procurar mapas
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack direction='column' alignItems='center' justifyContent='center'>
            <Stack
              component='img'
              src={isDark ? MonitorImg : MonitorLightImg}
              alt='icone de monitor com gps na tela'
              width={154}
            />

            <Typography
              sx={{
                ...TEXT_TITLE_14,
                color: (theme) => theme.palette.primary.light,
                mb: 1,
              }}
            >
              Selecionar uma área
            </Typography>
            <Typography
              sx={{
                ...TEXT_SUBTEXT,
                color: (theme) => theme.palette.text.disabled,
                textAlign: 'center',
              }}
            >
              Para a definição de um novo mapa e suas propriedades, selecione
              uma de suas unidades para fazer o vínculo, bem como visualizar os
              mapas já criado para essa unidade.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <IconButton
        size='small'
        variant='contained'
        iconName={sidebarVisibility ? 'chevronRight' : 'chevronLeft'}
        sx={{
          width: 24,
          position: 'absolute',
          right: 0,
          bottom: 60,
          transform: 'translateX(50%)',
        }}
        onClick={toggleSidebarVisibility}
      />
    </Stack>
  )
}
