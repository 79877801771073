import { CreateProceduresType } from 'types/procedures/create-procedures'

import { httpClient } from '../api/httpClient'

export const createProcedures = (data: CreateProceduresType) => {
  const { ...rest } = data
  const formData = new FormData()

  formData.append('request', JSON.stringify(rest))

  return httpClient.post('/procedures/create', formData)
}
