import { createTheme, darkScrollbar } from '@mui/material'

import type {} from '@mui/x-date-pickers/themeAugmentation'

export const theme = createTheme({
  typography: {
    button: {
      textTransform: 'capitalize',
      fontSize: 14,
    },
    fontFamily: [
      'Noto Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontSize: 14,
    },
    h1: {
      fontSize: 24,
      fontWeight: 'bold',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        root: {
          minWidth: 118,
          fontSize: 14,
          borderRadius: '6px',
          boxShadow: 'none',
          fontWeight: 400,
        },
        sizeLarge: {
          minHeight: 44,
        },
        outlined: {
          borderWidth: 2,
          ':hover': {
            borderWidth: 2,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            top: -4,
          },
          '& input': {
            padding: '12px',
          },
          '& fieldset': {
            borderWidth: 2,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '12px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          fontWeight: 400,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderWidth: 2,
        },
        outlinedSuccess: {
          background: '#EBF9F1',
          color: '#3EB46D',
        },
        outlinedError: {
          background: '#FFF2F9',
          color: '#DE4D53',
        },
        outlinedWarning: {
          background: '#FFF9DE',
          color: '#F19A4C',
        },
        outlinedInfo: {
          color: '#4fc3f7',
          background: '#c7eeff',
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {},
      },
    },
  },
})

export const themeDark = createTheme({
  ...theme,
  palette: {
    mode: 'dark',
    background: {
      default: '#0C0C25',
      paper: '#1D1E42',
    },
    primary: {
      main: '#66009D',
      dark: '#440069',
    },
    secondary: {
      light: '#26264F',
      main: '#1D1E42',
    },
    success: {
      main: '#71D197',
    },
    warning: {
      main: '#F19A4C',
    },
    error: {
      main: '#DE4D53',
    },
    info: {
      main: '#F777BD',
    },
  },
  components: {
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: () => ({
        body: darkScrollbar(),
      }),
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backdropFilter: 'blur(4px)',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& > div:not(.MuiBackdrop-invisible)': {
            backdropFilter: 'blur(4px)',
            background: '#03031c7d',
            '& .MuiPaper-root': {
              background: '#0C0C25', // #141432
              boxShadow: 'none',
              border: '1px solid #1D1E42',
            },
          },
          '& .MuiPaper-root': {
            background: '#0C0C25 !important',
          },
        },
      },
    },
    MuiButton: {
      ...theme.components?.MuiButton,
      styleOverrides: {
        ...theme.components?.MuiButton?.styleOverrides,
        contained: {
          ':disabled': {
            background: '#1D1E42',
          },
        },
        outlined: {
          color: '#FFF',
          borderWidth: 2,
          borderColor: 'transparent',
          background: '#141432',
          ':hover': {
            borderWidth: 2,
          },
          ':disabled': {
            borderColor: '#141432',
            background: '#14143270',
            borderWidth: 2,
          },
        },
      },
    },
    MuiTextField: {
      ...theme.components?.MuiTextField,
      styleOverrides: {
        ...theme.components?.MuiTextField?.styleOverrides,
        root: {
          '&:hover input.Mui-disabled': {
            background: 'transparent !important',
          },
          '& .MuiInputBase-root': { borderRadius: 6, background: '#141432' },
          '& label': {
            top: -4,
            '&.MuiFormLabel-filled, &.Mui-focused': {
              top: 0,
              textTransform: 'uppercase',
            },
          },
          '& input': {
            padding: '12px',
          },
          '& fieldset': {
            borderWidth: 2,
            borderColor: '#66009D',
          },
          '&:hover fieldset': {
            borderColor: '#66009D !important',
          },
          '&:hover .MuiInputBase-root': {
            background: '#26264F',
          },
          '&:hover input': {
            background: 'transparent',
          },
          '& .MuiInputBase-colorSuccess': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#71D197',
            },
            '&:hover fieldset': {
              borderColor: '#71D197 !important',
            },
            '& input': {
              background: 'transparent',
            },
          },
          '& .MuiInputBase-colorError': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#DE4D53',
            },
            '&:hover fieldset': {
              borderColor: '#DE4D53 !important',
            },
            '& input': {
              background: 'transparent',
            },
          },
          '& .Mui-disabled': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#555567',
            },
            '&:hover fieldset': {
              borderColor: '#555567 !important',
            },
            '&:hover input': {
              background: 'inherit',
            },
          },
          '&:hover .Mui-disabled': {
            background: 'transparent',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 60,
          '& fieldset': {
            borderWidth: 2,
            borderColor: '#66009D',
          },
          '&:hover fieldset': {
            borderColor: '#66009D !important',
          },
          '&:hover .MuiSelect-select': {
            background: '#141432',
          },
          '&:hover input': {
            background: 'transparent',
          },
          '&.Mui-error': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#DE4D53',
            },
            '&:hover fieldset': {
              borderColor: '#DE4D53 !important',
            },
          },
          '&.Mui-disabled': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#555567',
            },
            '&:hover fieldset': {
              borderColor: '#555567 !important',
            },
            '&:hover input': {
              background: 'inherit',
            },
          },
          '&.Mui-disabled .MuiSelect-select': {
            background: 'inherit',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '12px',
          background: '#141432',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-track': {
            border: '2px solid #e0e0e0',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#26264F',
        },
        tooltip: {
          background: '#26264F',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#14143285',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            background: '#1D1E42',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingLeft: 8,
          position: 'relative',
          '&::before': {
            position: 'absolute',
            left: 8,
            content: '""',
            width: 6,
            height: 6,
            borderRadius: 6,
            background: '#FFF',
          },

          '&.MuiChip-colorError.MuiChip-outlined': {
            background: '#FFF2F9',
            '&::before': {
              background: '#DE4D53',
            },
          },

          '&.MuiChip-colorWarning.MuiChip-outlined': {
            background: '#FFF9DE',
            '&::before': {
              background: '#F19A4C',
            },
          },

          '&.MuiChip-colorSuccess.MuiChip-outlined': {
            background: '#EBF9F1',
            '&::before': {
              background: '#3EB46D',
            },
          },
        },
        outlinedPrimary: {
          background: '#F4F2FF',
          '&::before': {
            background: '#66009D',
          },
        },
      },
    },
  },
})

export const themeLight = createTheme({
  ...theme,
  palette: {
    mode: 'light',
    background: {
      default: '#F3F5F7',
    },
    primary: {
      main: '#66009D',
      dark: '#440069',
    },
    secondary: {
      light: '#EAEBEA',
      main: '#b6b9b6',
    },
    success: {
      main: '#71D197',
    },
    warning: {
      main: '#F19A4C',
    },
    error: {
      main: '#DE4D53',
    },
    info: {
      main: '#F777BD',
    },
  },
  components: {
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: () => ({
        body: darkScrollbar(),
      }),
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backdropFilter: 'blur(4px)',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& > div:not(.MuiBackdrop-invisible)': {
            backdropFilter: 'blur(4px)',
            background: '#EAEBEA7d',
            '& .MuiPaper-root': {
              background: '#F3F5F7',
              boxShadow: 'none',
              border: '1px solid #b6b9b6',
            },
            '&.MuiPaper-root': {
              background: '#EAEBEA',
            },
          },
          '& .MuiPaper-root': {
            background: '#EAEBEA',
          },
        },
      },
    },
    MuiButton: {
      ...theme.components?.MuiButton,
      styleOverrides: {
        ...theme.components?.MuiButton?.styleOverrides,
        contained: {},
        outlined: {
          borderWidth: 2,
          ':hover': {
            borderWidth: 2,
          },
          ':disabled': {
            borderColor: '#E0E1E0',
            background: '#E0E1E070',
            borderWidth: 2,
          },
        },
      },
    },
    MuiTextField: {
      ...theme.components?.MuiTextField,
      styleOverrides: {
        ...theme.components?.MuiTextField?.styleOverrides,
        root: {
          '&:hover input.Mui-disabled': {
            background: 'transparent !important',
          },
          '& .MuiInputBase-root': {
            borderRadius: 6,
            background: '#F3F5F7',
            border: '1px solid #EAEBEA',
          },
          '& label': {
            top: -4,
            '&.MuiFormLabel-filled, &.Mui-focused': {
              top: 0,
              textTransform: 'uppercase',
            },
          },
          '& input': {
            padding: '12px',
          },
          '& fieldset': {
            borderWidth: 2,
            borderColor: '#66009D',
          },
          '&:hover fieldset': {
            borderColor: '#66009D !important',
          },
          '&:hover .MuiInputBase-root': {
            background: '#EAEBEA',
          },
          '&:hover input': {
            background: 'transparent',
          },
          '& .MuiInputBase-colorSuccess': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#71D197',
            },
            '&:hover fieldset': {
              borderColor: '#71D197 !important',
            },
            '& input': {
              background: 'transparent',
            },
          },
          '& .MuiInputBase-colorError': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#DE4D53',
            },
            '&:hover fieldset': {
              borderColor: '#DE4D53 !important',
            },
            '& input': {
              background: 'transparent',
            },
          },
          '& .Mui-disabled': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#555567',
            },
            '&:hover fieldset': {
              borderColor: '#555567 !important',
            },
            '&:hover input': {
              background: 'inherit',
            },
          },
          '&:hover .Mui-disabled': {
            background: 'transparent',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 60,
          '& fieldset': {
            borderWidth: 2,
            borderColor: '#66009D',
          },
          '&:hover fieldset': {
            borderColor: '#66009D !important',
          },
          '&:hover input': {
            background: 'transparent',
          },
          '&.Mui-error': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#DE4D53',
            },
            '&:hover fieldset': {
              borderColor: '#DE4D53 !important',
            },
          },
          '&.Mui-disabled': {
            '& fieldset': {
              borderWidth: 2,
              borderColor: '#555567',
            },
            '&:hover fieldset': {
              borderColor: '#555567 !important',
            },
            '&:hover input': {
              background: 'inherit',
            },
          },
          '&.Mui-disabled .MuiSelect-select': {
            background: 'inherit',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '12px',
          border: '1px solid #EAEBEA',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-track': {
            border: '2px solid #e0e0e0',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#26264F',
        },
        tooltip: {
          background: '#26264F',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#eaeaea85',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            background: '#1D1E42',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingLeft: 8,
          position: 'relative',
          '&::before': {
            position: 'absolute',
            left: 8,
            content: '""',
            width: 6,
            height: 6,
            borderRadius: 6,
            background: '#FFF',
          },

          '&.MuiChip-colorError.MuiChip-outlined': {
            background: '#FFF2F9',
            '&::before': {
              background: '#DE4D53',
            },
          },

          '&.MuiChip-colorWarning.MuiChip-outlined': {
            background: '#FFF9DE',
            '&::before': {
              background: '#F19A4C',
            },
          },

          '&.MuiChip-colorSuccess.MuiChip-outlined': {
            background: '#EBF9F1',
            '&::before': {
              background: '#3EB46D',
            },
          },
        },
        outlinedPrimary: {
          background: '#F4F2FF',
          '&::before': {
            background: '#66009D',
          },
        },
      },
    },
  },
})
