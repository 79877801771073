import { Box } from '@mui/material'

import screen1 from 'assets/images/prints/dashboard-water-screen-1.png'
import screen2 from 'assets/images/prints/dashboard-water-screen-2.png'
import { Icon } from 'components/Icon'

export function DashboardWaterContent() {
  return (
    <>
      <p>
        No Painel de Controle é possível verificar o consumo das utilidades,
        verificação das áreas e análise dos dados obtidos por meio dos sensores
        disponibilizados na fábrica.
      </p>

      <p>
        No Dashboard, é possível ter dois tipos de visualização dos gráficos.
        Clicando no botão “Modo de Grade” <Icon name='grid' />, no canto
        superior direito abaixo do cabeçalho, os gráficos são mostrados
        lado-a-lado com o intuito de ter uma visualização geral dos dados.{' '}
      </p>

      <Box component='img' src={screen1} width='100%' />

      <p>
        Clicando no mesmo botão, que será alterado para o nome de “Modo
        Apresentação” <Icon name='presentation' />, os gráficos serão mostrados
        em formato de apresentação, facilitando a visualização deles de forma
        mais individual. Para visualizar o próximo gráfico, basta clicar em
        próximo <Icon name='chevronRight' />.
      </p>

      <p>
        Todos os gráficos podem ser exportados para Excel e Power Point com os
        dados que estão disponíveis naquela tela, ou seja, primeiro deve-se
        filtrar o gráfico e depois exportar, para exportar clique nos três
        pontos <Icon name='moreVert' /> e selecione o formato de arquivo que
        deseja exportar.
      </p>

      <Box component='img' src={screen2} width='100%' />

      <p>
        Os gráficos também exibem os dados de acordo com as datas que são
        selecionadas nos campos de filtro por data . O Dashboard é separado em
        quatro utilidades, conforme elas são selecionados os gráficos se
        modificam.
      </p>
    </>
  )
}
