import { Chip, Stack, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { HeaderAction } from 'components/HeaderAction'
import { Restricted } from 'components/Restricted'
import { Select } from 'components/Select'
import { Table, TableActionType } from 'components/Table'
import { RowAction } from 'components/Table/RowAction'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { typesOfSensorsOptions } from 'constants/typesOfSensorsOptions'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { sensorService } from 'services/sensor.service'
import { PaginationType } from 'types/pagination'
import { ModeOption, SensorType } from 'types/sensor/sensor'
import { ColumnType } from 'types/table/column'

import { FormSensorModal } from './components/FormSensorModal'

export function SensorPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selectedSensor, setSelectedSensor] = useState<SensorType | null>(null)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [searchType, setSearchType] = useState('')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [orderBy, setOrderBy] = useState('name')

  const querySensor: UseQueryResult<
    PaginationType<SensorType>,
    Error
  > = useQuery<PaginationType<SensorType>, Error>({
    queryKey: ['sensors', page, search, orderBy, searchType, order],
    queryFn: () =>
      sensorService.findAll({
        page,
        search,
        type: searchType,
        columnSort: order,
        columnName: orderBy,
      }),
    select: (data) => ({
      ...data,
      items: data?.items?.map((item) => ({
        ...item,
        idValue: typeof item.id !== 'number' ? item?.id?.value : item?.id,
        typeOfSensorName: t(`sensor.${item.type}`),
        muiStatus: item.status,
      })),
    }),
  })

  const onOpenForm = () => setOpenForm(true)

  const handleCreate = () => onOpenForm()

  const handleCancel = () => {
    setOpenForm(false)
    setOpenFormView(false)
    setSelectedSensor(null)
  }

  const handleEdit = (item: SensorType) => {
    setSelectedSensor(item)
    onOpenForm()
  }

  const handleView = (item: SensorType) => {
    setSelectedSensor(item)
    setOpenFormView(true)
  }

  const handleDuplicate = (item: SensorType) => {
    setSelectedSensor({
      name: item.name,
      type: item.type,
      status: true,
      address: '',
      dataUnifier: false,
      mode: ModeOption.SENSOR,
      time: 0,
    })
    onOpenForm()
  }

  const callback = () => {
    toast.success(t('savedInformation'))
    querySensor.refetch()
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'sensors' ||
        query.queryKey[0] === 'all-sensors-equipments' ||
        query.queryKey[0] === 'all-sensors',
    })
  }

  const mutationDelete = useMutation((id: number) =>
    httpDelete(`/sensor/${id}`, callback),
  )

  const handleDelete = (item: SensorType) => {
    toast.promise(mutationDelete.mutateAsync(Number(item.id?.value)), {
      pending: t('deleting'),
    })
  }

  const handleHistoric = (item: SensorType) => {
    if (!item) return
    navigate(`/settings/create-sensors/historic?id=${item.id?.value}`, {
      state: {
        sensor: {
          id: item.id?.value,
          name: item.name,
        },
      },
    })
  }

  const columns: ColumnType[] = [
    {
      id: 'unit',
      label: t('table.sensor.unit'),
      align: 'center',
    },
    {
      id: 'name',
      label: t('table.sensor.name'),
      align: 'center',
    },
    {
      id: 'type',
      label: t('table.sensor.utility'),
      align: 'center',
    },
    {
      id: 'mode',
      label: t('input.communication'),
      align: 'center',
    },
    {
      id: 'address',
      label: t('table.sensor.address'),
      align: 'center',
    },
    {
      id: 'status',
      label: t('table.sensor.status'),
      align: 'center',
    },
  ]

  const tableActions: TableActionType<SensorType>[] = [
    {
      type: 'add',
      title: 'Duplicar',
      onClick: (row) => handleDuplicate(row),
    },
    {
      type: 'historic',
      onClick: (row) => handleHistoric(row),
    },
    {
      type: 'show',
      onClick: (row) => handleView(row),
    },
    {
      type: 'edit',
      onClick: (row) => handleEdit(row),
    },
    {
      type: 'delete',
      onClick: (row) =>
        sweetAlert({
          title: t('titleErrorBase').toString(),
          text: t('textErrorBase').toString(),
          onSuccess: () => handleDelete(row),
        }),
    },
  ]

  return (
    <>
      <HeaderAction
        onCreate={handleCreate}
        onSearch={(search) => {
          setPage(1)
          setSearch(search)
        }}
        module={ModuleEnum.CONF_SENSOR}
        permission={PermissionEnum.CREATE}
      >
        <Stack
          marginRight={[0, 2]}
          marginTop={[2, 0]}
          width={'100%'}
          maxWidth={['100%', 400]}
        >
          <Select
            label={t('table.sensor.utility')}
            options={[
              ...(typesOfSensorsOptions?.map((item) => ({
                name: t(item.id) ?? '',
                id: item.id,
              })) ?? []),
              {
                name: t('all') ?? '',
                id: '',
              },
            ]}
            onChange={(value) => {
              setSearchType(value.target.value as string)
              setPage(1)
            }}
            name='typeOfSensorName'
            sx={{ '& fieldset': { borderColor: 'transparent !important' } }}
          />
        </Stack>
      </HeaderAction>

      <Table<SensorType>
        columns={columns}
        isLoading={querySensor.isLoading}
        isFetching={querySensor.isFetching}
        onOrderBy={(newOrderBy) => setOrderBy(newOrderBy)}
        onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
        order={order}
        orderBy={orderBy}
        actions={tableActions}
        error={querySensor.error}
        pagination={{
          page: querySensor.data?.currentPage ?? page,
          limit: querySensor.data?.perPage,
          count: querySensor.data?.total,
          onChangePage: setPage,
        }}
      >
        {!!querySensor.data?.items?.length &&
          querySensor.data?.items?.map((row) => {
            return (
              <TableRow hover key={row?.id?.value}>
                <TableCell sx={{ border: 'none' }}>{row?.keyUnique}</TableCell>
                <TableCell sx={{ border: 'none' }}>{row.name}</TableCell>
                <TableCell sx={{ border: 'none' }}>
                  {t(`sensor.${row.type}`)}
                </TableCell>
                <TableCell sx={{ border: 'none' }}>{row.mode}</TableCell>
                <TableCell sx={{ border: 'none' }}>
                  {row.address ?? row?.ip}
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                  {row.status ? (
                    <Chip
                      label={t('on')}
                      color='success'
                      variant='outlined'
                      size='small'
                    />
                  ) : (
                    <Chip
                      label={t('off')}
                      color='error'
                      variant='outlined'
                      size='small'
                    />
                  )}
                </TableCell>
                <TableCell align='right' sx={{ border: 'none' }}>
                  <RowAction.Root>
                    <Restricted
                      module={ModuleEnum.CONF_SENSOR}
                      permission={PermissionEnum.CREATE}
                    >
                      <RowAction.Button
                        icon='duplicate'
                        onClick={() => handleDuplicate(row)}
                      />
                    </Restricted>
                    <Restricted
                      module={ModuleEnum.CONF_SENSOR}
                      permission={PermissionEnum.VIEW}
                    >
                      <RowAction.Button
                        icon='historic'
                        onClick={() => handleHistoric(row)}
                      />
                    </Restricted>
                    <Restricted
                      module={ModuleEnum.CONF_SENSOR}
                      permission={PermissionEnum.VIEW}
                    >
                      <RowAction.Button
                        icon='show'
                        onClick={() => handleView(row)}
                      />
                    </Restricted>
                    <Restricted
                      module={ModuleEnum.CONF_SENSOR}
                      permission={PermissionEnum.EDIT}
                    >
                      <RowAction.Button
                        icon='edit'
                        onClick={() => handleEdit(row)}
                      />
                    </Restricted>

                    <Restricted
                      module={ModuleEnum.CONF_SENSOR}
                      permission={PermissionEnum.DELETE}
                    >
                      <RowAction.Button
                        icon='delete'
                        onClick={() => {
                          sweetAlert({
                            title: t('titleErrorBase').toString(),
                            text: t('textErrorBase').toString(),
                            confirmButtonText: t('yes').toString(),
                            cancelButtonText: t('no').toString(),
                            onSuccess: () => handleDelete(row),
                          })
                        }}
                      />
                    </Restricted>
                  </RowAction.Root>
                </TableCell>
              </TableRow>
            )
          })}
      </Table>

      {(openForm || openFormView) && (
        <FormSensorModal
          data={selectedSensor}
          isView={openFormView}
          open={openForm || openFormView}
          onClose={handleCancel}
        />
      )}
    </>
  )
}
