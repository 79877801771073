import { EquipmentTypeEnum } from 'core/enums/EquipmentTypeEnum'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'

import { httpClient } from 'services/api/httpClient'
import { SubareaType } from 'types/subarea/subarea'

type ParamsLinkedAreaType = {
  idMapImageCompany: string
  type?: string
  feeder?: boolean
}

type ParamsByMapId = {
  mapId: string
  typesEquipment?: EquipmentTypeEnum[]
  sensorTypes?: SensorTypeEnum[]
}

const baseUrl = '/linked-area'

const allLinkedArea = async ({
  idMapImageCompany,
  type,
  feeder,
}: ParamsLinkedAreaType) => {
  const newParams: ParamsLinkedAreaType = {
    idMapImageCompany,
  }

  if (type) {
    newParams.type = type
  }
  if (feeder) {
    newParams.feeder = feeder
  }

  const response = await httpClient.get(`${baseUrl}/all/old`, {
    params: newParams,
  })
  return response.data
}

const findAllByMapId = (params: ParamsByMapId) => {
  return httpClient
    .get<SubareaType[]>(`${baseUrl}/all/equipment/sensor/map`, {
      params: {
        idMapImageCompany: params.mapId,
        ...(params.sensorTypes && {
          typesSensor: params.sensorTypes?.join(','),
        }),
        ...(params.typesEquipment && {
          typesEquipment: params.typesEquipment?.join(','),
        }),
      },
    })
    .then((response) => response.data)
}

const getSensorAlertDate = async (id: number): Promise<ResponseType> => {
    const { data } = await httpClient.get(`sensor-alert/last?sensorId=${id}`)
    return data
  }

export const linkedAreaService = {
  allLinkedArea,
  findAllByMapId,
  getSensorAlertDate,
}
