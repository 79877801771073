import { Stack, Typography, useTheme } from '@mui/material'
import { SensorTypeEnum } from 'core/enums/SensorTypeEnum'
import { FC, useState } from 'react'

import { Accordion } from 'components/Accordion'
import { accordionStyle } from 'components/ModalProgrammingAirConditioning/styles'
import { SensorIconType } from 'components/Sensor'
import { SensorIconButton } from 'components/Sensor/SensorIconButton'

type Props = {
  data: Array<{
    label: string
    value: string
  }>
  sensorType: SensorTypeEnum
}

export const EquipmentMetaData: FC<Props> = ({ data, sensorType }) => {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        title={
          <Stack
            flexWrap={'wrap'}
            flex={1}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Stack flex={1} flexDirection={'row'} alignItems={'center'} gap={1}>
              <SensorIconButton icon={sensorType as SensorIconType} />
              <Typography>Energia</Typography>
            </Stack>
            <Stack
              sx={{
                borderRadius: '6px',
                padding: '4px 8px',
                border: '1px solid #527e21',
                marginRight: 2,
              }}
            >
              <Typography>Ligado</Typography>
            </Stack>
          </Stack>
        }
        sx={{
          ...accordionStyle,
          '& > div': {
            ...accordionStyle['& > div'],
            background: `${theme.palette.background.paper} !important`,
          },
        }}
      >
        <Stack flexDirection={'row'} gap={2}>
          {data.map((row) => (
            <div key={row.label}>
              <Typography color={'#9118D3'} fontSize={'10px'}>
                {row.label}
              </Typography>
              <Typography fontWeight={600}>{row.value}</Typography>
            </div>
          ))}
        </Stack>
      </Accordion>
    </div>
  )
}
