import { httpClient } from 'services/api/httpClient'
import { PaginationType } from 'types/pagination'
import { UnitType } from 'types/unit/unit'

type NewType = PaginationType<UnitType>

export const findAll = async (
  page = 1,
  search = '',
  orderBy?: string,
): Promise<NewType> => {
  const response = await httpClient.get('/unit', {
    params: {
      page,
      search,
      orderBy,
    },
  })
  const { total } = response.data
  const { items } = response.data
  const { perPage } = response.data
  const results = items?.filter((item: UnitType) =>
    item.name.toUpperCase().includes(search.toUpperCase()),
  )

  const offset = page - 1
  const limit = perPage
  return {
    results: results.slice(offset * limit, offset * limit + limit),
    limit,
    count: total,
  }
}

export const list = async () => {
  const response = await httpClient.get('/unit', {
    params: {
      page: 0,
      numberPerPage: 10000,
    },
  })

  return (
    response?.data?.items?.map((item: UnitType) => ({
      id: item?.id?.value ?? item?.id,
      name: item.name,
    })) ?? []
  )
}

export const unitService = {
  findAll,
  list,
}
