/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { validationUser } from 'services/authentication/validationUser'

import { schemaCreatePassword } from '../validations/create-password.validation'

interface DataInitial {
  accessKey: string
  login: string
}
export function useCreatePassword(props: DataInitial) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { code } = useParams()
  const { state } = useLocation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [registrationDone, setRegistrationDone] = useState(false)

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ password: string; passwordConfirmation: string }>({
    resolver: yupResolver(schemaCreatePassword()),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })

  // useEffect(() => {
  //   if (!state?.accessKey || !code) navigate('/first-access')
  // }, [state])

  const mutation = useMutation(
    (payload: any) => {
      return validationUser(props.accessKey, payload)
    },
    {
      onSuccess: () => {
        toast.success('Senha criada com sucesso!')
        setRegistrationDone(true)
      },
      onError: () => {
        toast.error('Erro ao criar senha!')
      },
    },
  )

  const onSubmit: SubmitHandler<{
    password: string
    passwordConfirmation: string
  }> = (formData) => {
    setIsSubmitting(true)
    const request = {
      login: props.login,
      password: formData.password,
    }
    mutation.mutate(request)

    setIsSubmitting(false)
  }

  const calculatePasswordStrength = () => {
    // Aqui você pode implementar sua lógica para calcular a força da senha
    // Por exemplo, contar o número de caracteres, verificar se contém números e caracteres especiais, etc.

    const password = watch('password')

    if (password?.length < 3) return 0

    if (password?.length < 8) return 30

    return 50 // retorna um valor entre 0 e 100
  }

  return {
    control,
    handleSubmit,
    onSubmit,
    errors,
    t,
    navigate,
    isSubmitting,
    calculatePasswordStrength,
    registrationDone,
  }
}
