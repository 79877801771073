import { Grid, Stack, Typography } from '@mui/material'
import {
  Control,
  FieldErrors,
  UseFieldArrayReturn,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Button/IconButton'
import { Input } from 'components/Input'
import { InputFile } from 'components/Input/InputFile'
import { PhotoExpand } from 'components/Photo/Expand'
import { Select } from 'components/Select'
import { Toggle } from 'components/Toggle'
import { CreateEquipmentType } from 'types/equipment/create-equipment'
import { ID } from 'types/id'

import { SensorTypeEnum } from '../../../../core/enums/SensorTypeEnum'

interface Props {
  control: Control<CreateEquipmentType>
  isView?: boolean
  errors: FieldErrors<CreateEquipmentType>
  setValue: UseFormSetValue<CreateEquipmentType>
  useFieldSpecification: UseFieldArrayReturn<
    CreateEquipmentType,
    'specifications'
  >
  sensors?: { id: ID; name: string; type: string }[]
  getValues: UseFormGetValues<CreateEquipmentType>
  watch: UseFormWatch<CreateEquipmentType>
  file?: {
    name: string
    url: string
  }
}

export function FormWaterTankScreen({
  control,
  isView,
  errors,
  setValue,
  useFieldSpecification,
  sensors,
  getValues,
  watch,
  file,
}: Props) {
  const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction='row'
          alignItems='flex-start'
          spacing={2}
          justifyContent='left'
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper + '99',
            padding: '8px',
            borderRadius: '6px',
          }}
        >
          <Stack>
            <Toggle
              name='feeder'
              control={control}
              label={t('input.feeder')}
              disabled={isView}
            />
          </Stack>
          <Stack>
            <Typography
              variant='body2'
              color='textSecondary'
              sx={{ alignSelf: 'center' }}
            >
              {t('feederHelp')}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Input
          name='name'
          label={t('input.name')}
          control={control}
          fullWidth
          error={!!errors?.name}
          errorMessage={errors?.name?.message}
          isDisabled={isView}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='code'
          label={t('input.code')}
          control={control}
          fullWidth
          error={!!errors?.code}
          errorMessage={errors?.code?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='model'
          label={t('input.model') ?? ''}
          control={control}
          fullWidth
          error={!!errors?.model}
          errorMessage={errors?.model?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          name='brand'
          label={t('input.brand') ?? ''}
          control={control}
          fullWidth
          error={!!errors?.brand}
          errorMessage={errors?.brand?.message}
          isDisabled={isView}
          required
        />
      </Grid>
      {!isView && (
        <Grid item xs={12} sm={6}>
          <InputFile
            defaultValue={file?.name}
            onChange={(file) => {
              setValue('file', file)
            }}
            onDelete={() => {
              setValue('file', null)
            }}
          />
        </Grid>
      )}
      {isView && !!file?.url && (
        <Grid item xs={12} sm={6}>
          <PhotoExpand url={file?.url} />
        </Grid>
      )}

      <Stack
        spacing={2}
        sx={{
          marginLeft: '16px',
          marginTop: '16px',
          width: '100%',
          padding: '8px',
          backgroundColor: (theme) => theme.palette.background.paper + '99',
        }}
      >
        <Grid item xs={12}>
          <Typography variant='caption'>
            {t('equipment.specification')}
          </Typography>
        </Grid>
        {useFieldSpecification.fields?.map((field, index) => (
          <Grid item xs={12} key={index}>
            <Stack direction='row' alignItems='flex-start' spacing={3}>
              <Input
                name={`specifications.[${index}].key`}
                label={t('input.name') ?? ''}
                control={control}
                fullWidth
                isDisabled={isView}
                error={!!errors?.specifications?.[index]?.key}
                errorMessage={errors?.specifications?.[index]?.key?.message}
                required
              />
              <Input
                name={`specifications.[${index}].value`}
                label={t('input.value') ?? ''}
                control={control}
                fullWidth
                isDisabled={isView}
                error={!!errors?.specifications?.[index]?.value}
                errorMessage={errors?.specifications?.[index]?.value?.message}
                required
              />

              {index === 0 && !isView ? (
                <IconButton
                  variant='contained'
                  color={'secondary'}
                  iconName='add'
                  title={t('button.title.add') ?? ''}
                  onClick={() =>
                    useFieldSpecification.append({
                      key: '',
                      value: undefined,
                    })
                  }
                />
              ) : (
                <IconButton
                  variant='contained'
                  iconName='delete'
                  color={'error'}
                  title={t('button.title.delete') ?? ''}
                  disabled={isView}
                  onClick={() => useFieldSpecification.remove(index)}
                />
              )}
            </Stack>
          </Grid>
        ))}
      </Stack>
      {watch('sensors')?.map((_, index) => (
        <Grid item xs={12} key={index}>
          <Stack direction='row' alignItems='flex-start' spacing={3}>
            <Select
              name={`sensors.[${index}].sensor`}
              label={t('input.selectSensor') ?? ''}
              options={
                sensors
                  ?.filter((i) => i?.type?.includes(SensorTypeEnum.WATER))
                  ?.map((sensor) => ({
                    name: sensor.name,
                    id: String(sensor.id),
                  })) ?? []
              }
              control={control}
              fullWidth
              disabled={isView}
              required
            />
            <Toggle
              name={`sensors.[${index}].waterInlet`}
              label='Entrada de água'
              disabled={isView}
              checked={getValues('sensors')?.[index]?.waterInlet}
              control={control}
              onChange={(e: any) => {
                const checked = e.target.checked

                setValue(`sensors.${index}.waterInlet`, checked)
                setValue(`sensors.${index}.waterOutlet`, !checked)
              }}
            />
            <Toggle
              name={`sensors.[${index}].waterOutlet`}
              checked={getValues('sensors')?.[index]?.waterOutlet}
              control={control}
              label='Saída de água'
              disabled={isView}
              onChange={(e: any) => {
                const checked = e.target.checked

                setValue(`sensors.${index}.waterInlet`, !checked)
                setValue(`sensors.${index}.waterOutlet`, checked)
              }}
            />

            {index === 0 && !isView ? (
              <IconButton
                variant='outlined'
                iconName='add'
                title={t('button.title.add') ?? ''}
                onClick={() =>
                  setValue('sensors', [
                    ...getValues('sensors'),
                    {
                      sensor: '',
                      waterInlet: false,
                      waterOutlet: false,
                      floor: '',
                    },
                  ])
                }
              />
            ) : (
              <IconButton
                variant='outlined'
                iconName='delete'
                title={t('button.title.delete') ?? ''}
                disabled={isView}
                onClick={() =>
                  setValue(
                    'sensors',
                    getValues('sensors')?.filter(
                      (_, indexDelete) => indexDelete !== index,
                    ),
                  )
                }
              />
            )}
          </Stack>
        </Grid>
      ))}
    </>
  )
}
