import { Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useQuery, UseQueryResult } from 'react-query'

import { Modal } from 'components/Modal'
import { Table } from 'components/Table'
import {
  SensorDataRecordService,
  ValueType,
} from 'services/sensor-data-record/sensor-data-record.service'
import { ModalType } from 'types/modal'
import { PaginationType } from 'types/pagination'
import { DataRecordSensorType } from 'types/sensor/sensor'
import { ColumnType } from 'types/table/column'

interface Props extends ModalType {
  data?: any
}
export function ModalSensorsTemperature({ data, onClose, open }: Props) {
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
  const [orderBy, setOrderBy] = useState('sensorName')

  const queryDataRecordSenor: UseQueryResult<
    PaginationType<DataRecordSensorType>,
    Error
  > = useQuery<PaginationType<DataRecordSensorType>, Error>({
    queryKey: ['sensorsByTemp', page, orderBy, order, data?.id],
    queryFn: () =>
      SensorDataRecordService.findAllDataSensorByAreaAndValueType({
        page,
        columnSort: order,
        columnName: orderBy,
        areaId: data?.id,
        valueType: ValueType.TEMPERATURE,
      }),
    enabled: !!data?.id,
  })

  const columns: ColumnType[] = [
    {
      id: 'sensorName',
      label: 'Sensor',
    },
    {
      id: 'lastValue',
      label: 'Temperatura Atual (°C)',
    },
    {
      id: 'maxValue',
      label: 'Temperatura Máxima (°C)',
    },
    {
      id: 'minValue',
      label: 'Temperatura Mínima (°C)',
    },
  ]

  return (
    <Modal
      title={'Sensores de Temperatura' + (data?.name ? ` - ${data?.name}` : '')}
      open={open}
      onClose={onClose}
      maxWidth={'lg'}
    >
      <Stack mb={2}>
        <Typography variant='subtitle1'>
          Média atual da area: <strong>{data?.value.toFixed(2).replace('.', ',')} C° </strong>
        </Typography>
      </Stack>
      <Table<DataRecordSensorType>
        columns={columns}
        rows={(columns.findIndex(col => col.id === 'lastValue' || col.id === 'maxValue' || col.id === 'minValue') !== -1) ?
          (queryDataRecordSenor.data?.items || []).map(item => {
            if (typeof item === 'object' && item.lastValue !== undefined) {
              return {
                ...item,
                lastValue: typeof item.lastValue === 'string' ? item.lastValue.replace('.', ',') : item.lastValue,
                maxValue: typeof item.maxValue === 'number' ? item.maxValue.toFixed(2).replace('.', ',') : item.maxValue,
                minValue: typeof item.minValue === 'number' ? item.minValue.toFixed(2).replace('.', ',') : item.minValue
              };
            } else {
              return item;
            }
          }) :
          queryDataRecordSenor.data?.items || []
        }
        isLoading={queryDataRecordSenor.isLoading}
        isFetching={queryDataRecordSenor.isFetching}
        error={queryDataRecordSenor.error}
        pagination={{
          page: queryDataRecordSenor.data?.currentPage ?? page,
          limit: queryDataRecordSenor.data?.perPage,
          count: queryDataRecordSenor.data?.total,
          onChangePage: setPage,
        }}
      />
    </Modal>
  )
}
