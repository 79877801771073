import { sensorDoorMock } from 'mocks/pre-os.mock'

export interface SensorDoor {
  id: number
  local: {
    rua: string
    bairro: string
    cep: string
  }
  openingTime: number
  qtyTimesOpenings: {
    qty: number
    type: string
  }
  typeDoorRule: string
}

export async function getSensorDoorList(): Promise<SensorDoor[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(sensorDoorMock)
    }, 2000)
  })
}
