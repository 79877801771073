import { Box } from '@mui/material'
import { FC } from 'react'

import { BASE_URL_IMAGES } from 'constants/baseUrlImages'

type Props = {
  data: {
    fileName: string
  }
}

export const PanicBackgroundNode: FC<Props> = ({ data }) => {
  return (
    <Box
      component='img'
      src={data.fileName ? `${BASE_URL_IMAGES}${data.fileName}` : undefined}
      sx={{
        height: 'auto',
        width: 'auto',
        opacity: 0.6,
      }}
    />
  )
}
