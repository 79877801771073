import { AxiosResponse } from 'axios'

import { MonitoringOcorrenciaAlert } from 'types/monitoring-ocorrencia-alert'
import { PaginationParamType } from 'types/pagination'

import { httpClient } from './api/httpClient'

const create = (data: MonitoringOcorrenciaAlert) => {
  return httpClient.post('/monitoring-ocorrencia-alert/create', null, {
    params: {
      description: data?.description,
      actionTaken: data?.actionTaken,
      triggerDate: data?.triggerDate,
      linkedAreaID: data?.linkedAreaID,
      proceduresID: data?.proceduresID,
    },
  })
}

interface PaginatedResponse<T> {
  currentPage: number
  perPage: number
  total: number
  items: T[]
}
const allLinkedAreaAlert = async (): Promise<any> => {
  const response = await httpClient.get('/linked-area/equipment-btn-panic')
  return response.data
}

const monitoringOcorrenciaAlertHistory = async ({
  page,
  columnName,
  columnSort,
  numberPerPage,
  search,
}: PaginationParamType): Promise<any> => {
  const { data }: AxiosResponse<PaginatedResponse<any>> = await httpClient.get(
    '/monitoring-ocorrencia-alert/history',
    {
      params: {
        page: page - 1,
        columnName,
        // columnSort,
        numberPerPage,
        search,
      },
    },
  )
  return data
}

export const monitoringOcorrenciaAlertService = {
  create,
  allLinkedAreaAlert,
  monitoringOcorrenciaAlertHistory,
}
