export function DashboardEnergyGeneralConsumptionContent() {
  return (
    <>
      <p>
        Nesse gráfico é possível verificar o comparativo entre o consumo (barras
        amarelas ) em kilo wats, a fatura relacionada àquele mês (barra laranja
        ) e o custo geral da energia (a laranja roxa ) e a meta (linha amarela
        ). Nesse gráfico, podem ser selecionados os dados por ano, por mês e por
        semana.
      </p>
      <p>
        Os dados disponibilizados de consumo e fatura são mostrados conforme o
        cadastro de faturas de energia em Configurações {'->'} Faturas {'->'}{' '}
        Energia, selecione Configurações nesse tutorial para saber mais.
      </p>
      <p>
        Os dados disponibilizados de meta são cadastrados em Configurações{' '}
        {'->'} Metas e Parâmetros {'->'} Metas, selecione Configurações nesse
        tutorial para saber mais.
      </p>
      <p>
        Os dados de custo geral de energia são obtidos por meio do cálculo de
        fatura dividido pelo consumo.{' '}
      </p>
    </>
  )
}
