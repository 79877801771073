import {
  Accordion as MuiAccordion,
  AccordionDetails,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'

import { AccordionSummary } from './styles'

interface Props {
  title: string | ReactNode
  expanded: boolean
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void
  children?: ReactNode
  sx?: SxProps
}

export function Accordion({ title, expanded, onChange, children, sx }: Props) {
  return (
    <>
      <MuiAccordion
        expanded={expanded}
        onChange={onChange}
        sx={{
          background: 'transparent !important',
          boxShadow: 'none',
          padding: 0,
          border: 'none !important',
          '&::before': {
            display: 'none',
          },
          ...sx,
        }}
      >
        <AccordionSummary
          aria-controls={`${title}-content`}
          id={`${title}-header`}
        >
          {typeof title === 'string' ? <Typography>{title}</Typography> : title}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Stack spacing={1}>{children}</Stack>
        </AccordionDetails>
      </MuiAccordion>
    </>
  )
}
