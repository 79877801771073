const energy = [
  {
    start: '#FFB300',
    end: '#FFD933',
  },
  {
    start: '#DA683F',
    end: '#F6A498',
  },
  {
    start: '#FFAD3F',
    end: '#FFF599',
  },
  {
    start: '#F24949',
    end: '#F78F83',
  },
  {
    start: '#DA683F',
    end: '#F6A498',
  },
  {
    start: '#F24949',
    end: '#F6A498',
  },
  {
    start: '#F24949',
    end: '#DA683F',
  },
  {
    start: '#FFD933',
    end: '#DA683F',
  },
  {
    start: '#DA683F',
    end: '#FFB300',
  },
  {
    start: '#DA683F',
    end: '#FFD933',
  },
  {
    start: '#F6A498',
    end: '#FFD933',
  },
  {
    start: '#F6A498',
    end: '#FFAD3F',
  },
  {
    start: '#F6A498',
    end: '#FFF599',
  },
  {
    start: '#DA683F',
    end: '#FFF599',
  },
  {
    start: '#FFB300',
    end: '#FFF599',
  },
  {
    start: '#FFAD3F',
    end: '#FFF599',
  },
  {
    start: '#FFF599',
    end: '#F6A498',
  },
]
const gas = [
  {
    start: '#A85AFF',
    end: '#A85AFF',
  },
  {
    start: '#EC91FA',
    end: '#EC91FA',
  },
  {
    start: '#6268DA',
    end: '#6268DA',
  },
  {
    start: '#7952FF4D',
    end: '#7952FF4D',
  },
  {
    start: '#CC00FF',
    end: '#CC00FF',
  },
  {
    start: '#0165B1',
    end: '#0165B1',
  },
  {
    start: '#D9534F',
    end: '#DA683F',
  },
  {
    start: '#00D5FF',
    end: '#00D5FF',
  },
  {
    start: '#A8BDFF',
    end: '#A8BDFF',
  },
  {
    start: '#7BE0FF',
    end: '#7BE0FF',
  },
  {
    start: '#EC91FA',
    end: '#EC91FA',
  },
  {
    start: '#6268DA',
    end: '#6268DA',
  },
  {
    start: '#141432',
    end: '#141432',
  },
  {
    start: '#FF7BA3',
    end: '#FF7BA3',
  },
]
const temperature = [
  {
    start: '#2973F5',
    end: '#6FB3F7',
  },
  {
    start: '#81FFB5',
    end: '#41B16F',
  },
  {
    start: '#3463FE',
    end: '#2A62FF',
  },
  {
    start: '#1DA3B8',
    end: '#7EC6D1',
  },
  {
    start: '#CC00FF',
    end: '#CC00FF',
  },
  {
    start: '#0165B1',
    end: '#0165B1',
  },
  {
    start: '#D9534F',
    end: '#DA683F',
  },
]
export const sensor = [
  {
    start: '#81FFB5',
    end: '#81FFB5',
  },
  {
    start: '#FB262F',
    end: '#FB262F',
  },
  {
    start: '#FFE88E',
    end: '#FFE88E',
  },
]

export const colors = {
  energy,
  gas,
  temperature,
  sensor,
}
