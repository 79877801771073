/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Toggle } from 'components/Toggle'
import { getMonthFromNumber } from 'helpers/date.helper'
import { updateParameterStatus } from 'services/goalAndParameter/parameter/updateParameterStatus'
import { QuantityProducedType } from 'types/goalAndParameter/quantity-produced'

export function quantityProducedMapper(data: QuantityProducedType[]) {
  const queryClient = useQueryClient()

  const handleToggleStatus = async (id: string) => {
    try {
      await updateParameterStatus(id)
      queryClient.invalidateQueries('quantity-produced')
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  return data.map((item) => ({
    ...item,
    id: typeof item?.id !== 'number' ? item?.id?.value : item?.id,
    formattedYear: String(item.year),
    formattedMonth: getMonthFromNumber(Number(item.month)),
    muiStatus: (
      <Toggle
        name='toggle'
        checked={item.status}
        onChange={() =>
          handleToggleStatus(
            typeof item?.id !== 'number' ? item?.id?.value : item?.id,
          )
        }
      />
    ),
  }))
}
