import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { TypeLinkedArea } from 'constants/subarea'
import { getAllEquipmentsByType } from 'services/equipments/getAllEquipments'
import { ColumnType } from 'types/table/column'

import { equipmentMapper } from '../mappers/equipmentBtn.mapper'

export const useEquipmentBtnPanic = () => {
  const { t } = useTranslation('common')

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')

  const { data, error, isLoading, isFetching, refetch } = useQuery<any, Error>({
    queryKey: ['equipment-list-btn', page, search, orderBy, order],
    queryFn: () =>
      getAllEquipmentsByType({
        types: TypeLinkedArea.BTN_PANIC,
        page,
        columnName: orderBy,
        columnSort: order,
        search,
      }),
    staleTime: 1000 * 60, // 1 minute
    cacheTime: 1000 * 60, // 1 minute
  })

  const sensorBtnPanic = equipmentMapper(data?.items ?? [])

  const columns: ColumnType[] = [
    {
      id: 'createdAt',
      label: 'Data de Criação',
    },
    {
      id: 'file',
      label: 'Imagem',
    },
    {
      id: 'equipment',
      label: 'Equipamento',
    },
    {
      id: 'typeEquipment',
      label: 'Tipo de Equipamento',
    },
    {
      id: 'code',
      label: 'Código',
    },
    {
      id: 'manufacturer',
      label: 'Fabricante',
    },
    {
      id: 'statusMui',
      label: 'Status do Sensor',
    },
  ]

  return {
    columns,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    search,
    setSearch,
    page,
    setPage,
    sensorBtnPanic,
    error,
    isLoading,
    isFetching,
    refetch,
    data,
  }
}
