import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { IconButton } from 'components/Button/IconButton'
import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { AlertPanicCard } from 'pages/btnPanicAlert/components/AlertPanicCard'
import { FormAlertPanicModal } from 'pages/btnPanicAlert/components/FormAlertPanicModal'
import {
  AlertPanicCarouselContent,
  AlertPanicCarouselWrapper,
} from 'pages/btnPanicAlert/components/screen/styles'
import {
  useAlertPanicScreen,
  formatDate,
} from 'pages/btnPanicAlert/hooks/useAlertPanicScreen'
import { monitoringOcorrenciaAlertService } from 'services/monitoring-ocorrencia-alert.service'
import { PaginationType } from 'types/pagination'

export function AlertPanicScreen() {
  const [selectedItem, setSelectedItem] = useState(null)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState('creation')
  const [order, setOrder] = useState<'ASC' | 'DESC'>('DESC')

  const { t } = useTranslation('common')
  const { columns, openForm, handleOpen, handleClose, handleView, formView } =
    useAlertPanicScreen()
  const UPDATE_INTERVAL = 5000
  const { data: linkedAreaAlert, refetch: refetchLinkedAreaAlert } = useQuery({
    queryKey: ['linked-area', 'alert'],
    queryFn: async () => {
      return await monitoringOcorrenciaAlertService.allLinkedAreaAlert()
    },
  })

  const {
    data: historyAlert,
    refetch: refetchHistoryAlert,
    isLoading,
    isFetching,
    error,
  } = useQuery<PaginationType<any>, Error>({
    queryKey: [
      'history',
      'monitoring-ocorrencia-alert',
      page,
      search,
      orderBy,
      order,
    ],
    queryFn: async () => {
      return await monitoringOcorrenciaAlertService.monitoringOcorrenciaAlertHistory(
        {
          page,
          columnName: orderBy,
          columnSort: order,
          numberPerPage: 5,
          search,
        },
      )
    },
  })

  const handleItemClick = (item: any) => {
    setSelectedItem(item)
    handleOpen()
  }

  const handleCloseModal = () => {
    handleClose()
    refetchLinkedAreaAlert()
    refetchHistoryAlert()
  }

  const updateData = () => {
    refetchLinkedAreaAlert()
    refetchHistoryAlert()
  }

  useEffect(() => {
    const intervalId = setInterval(updateData, UPDATE_INTERVAL)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <Stack
      width={'100%'}
      sx={{
        paddingTop: 3,
        paddingLeft: 2,
        paddingRight: 2,
      }}
      spacing={2}
    >
      <Stack>
        <Typography variant={'subtitle2'}>
          {t('panicMonitoring.activated')}
        </Typography>
      </Stack>
      <AlertPanicCarouselWrapper>
        <AlertPanicCarouselContent>
          {linkedAreaAlert?.map(
            (item: any) =>
              item != null && (
                <AlertPanicCard
                  key={item?.id}
                  onClick={() => handleItemClick(item)}
                  type={'area'}
                  item={item}
                />
              ),
          )}
        </AlertPanicCarouselContent>
      </AlertPanicCarouselWrapper>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={2}
      >
        <Typography variant={'subtitle2'}>
          {t('panicMonitoring.historic')}
        </Typography>
        <HeaderAction
          onSearch={(search) => setSearch(search)}
          sx={{ maxWidth: 300 }}
        />
      </Stack>

      <Stack>
        <Table<any>
          columns={columns}
          rows={
            historyAlert?.items?.length
              ? historyAlert?.items?.map((row: any) => ({
                  triggerDate: row?.triggerDate
                    ? formatDate(row?.triggerDate, true)
                    : '-',
                  location: row?.findLinkedAreaDTO?.area?.name,
                  name: row?.findLinkedAreaDTO?.equipment?.name ?? 'S/N',
                  type: row?.findLinkedAreaDTO?.equipment?.alertType
                    ? t(
                        `panicMonitoring.typeAlert.${row?.findLinkedAreaDTO?.equipment?.alertType}`,
                      )
                    : '-',
                  actions: (
                    <IconButton
                      variant={'contained'}
                      iconName={'visibility'}
                      onClick={() => {
                        handleItemClick(row)
                        handleView()
                      }}
                      key={row.entityId.value}
                    />
                  ),
                }))
              : []
          }
          order={order}
          orderBy={orderBy}
          onOrderBy={(newOrderBy) => setOrderBy(newOrderBy)}
          onToggleOrder={() => setOrder(order === 'ASC' ? 'DESC' : 'ASC')}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          pagination={{
            page: page ?? historyAlert?.currentPage,
            limit: historyAlert?.perPage,
            count: historyAlert?.total,
            onChangePage: setPage,
          }}
        />
      </Stack>

      {openForm && (
        <FormAlertPanicModal
          open={openForm}
          onClose={handleCloseModal}
          data={selectedItem}
          isView={formView}
        />
      )}
    </Stack>
  )
}
