import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { httpDelete } from 'helpers/httpDelete'
import { useSweetAlert } from 'hooks/useSweetAlert'
import { getPreOS } from 'services/pre-os/getPreOS'
import { PaginationType } from 'types/pagination'
import { PreOSType } from 'types/pre-os/pre-os'
import { ColumnType } from 'types/table/column'

import { FormPreOSModal } from './components/FormPreOSModal'
import { preOSMapper } from './mappers/pre-os.mapper'

export function PreOSPage() {
  const { t } = useTranslation('common')
  const { sweetAlert } = useSweetAlert()

  const [openForm, setOpenForm] = useState(false)
  const [openFormView, setOpenFormView] = useState(false)
  const [selected, setSelectedClient] = useState<PreOSType | null>(null)

  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState(true)
  const [orderBy, setOrderBy] = useState('name')

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    PaginationType<PreOSType>,
    Error
  >(['pre-os', page, search, orderBy], () => getPreOS(page, search, orderBy), {
    refetchOnWindowFocus: false,
  })

  const sensors = preOSMapper(data?.results ?? [])

  const onClearSelected = () => setSelectedClient(null)

  const handleOpenCreate = () => setOpenForm(true)

  const handleCancelForm = () => {
    setOpenForm(false)
    setOpenFormView(false)
    onClearSelected()
  }

  const handleEditClient = (item: PreOSType) => {
    setSelectedClient(item)
    handleOpenCreate()
  }

  const handleViewClient = (item: PreOSType) => {
    setSelectedClient(item)
    setOpenFormView(true)
    setOpenForm(true)
  }

  const handleDelete = (item: PreOSType) => {
    httpDelete(`/order-service/${item.entityId?.value}`, refetch)
  }

  const columns: ColumnType[] = [
    {
      id: 'photo',
      label: t('table.preOS.photo'),
    },
    {
      id: 'formattedOccurrenceDate',
      label: t('table.preOS.date'),
    },
    {
      id: 'location',
      label: t('table.preOS.localization'),
    },
    {
      id: 'client',
      label: t('table.preOS.client'),
    },
    {
      id: 'nameEquipment',
      label: t('table.preOS.equipment'),
    },
    {
      id: 'serialNumber',
      label: t('table.preOS.serialNumber'),
    },
    {
      id: 'typeFormatted',
      label: t('table.preOS.type'),
    },
    {
      id: 'shortDescription',
      label: t('table.preOS.description'),
    },
    {
      id: 'muiStatus',
      label: 'Status',
    },
  ]

  return (
    <>
      <HeaderAction onSearch={(search) => setSearch(search)} />

      <Table<PreOSType>
        columns={columns}
        rows={sensors}
        order={order ? 'ASC' : 'DESC'}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrder((state) => (newOrderBy === orderBy ? !state : true))
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={[
          {
            type: 'show',
            onClick: (row) => handleViewClient(row),
            module: ModuleEnum.MAIN_CONFIG_SCALING,
            permission: PermissionEnum.VIEW,
          },
          {
            type: 'check',
            onClick: (row) => handleEditClient(row),
            module: ModuleEnum.MAIN_CONFIG_SCALING,
            permission: PermissionEnum.EDIT,
          },
          {
            type: 'delete',
            module: ModuleEnum.MAIN_CONFIG_SCALING,
            permission: PermissionEnum.DELETE,
            onClick: (row) =>
              sweetAlert({
                onSuccess: () => handleDelete(row),
              }),
          },
        ]}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      />

      {openForm && (
        <FormPreOSModal
          data={selected}
          isView={openFormView}
          open={openForm}
          onClose={handleCancelForm}
        />
      )}
    </>
  )
}
