import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import {
  CalendarPickerView,
  DesktopDatePicker,
  MobileDatePicker,
} from '@mui/x-date-pickers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DatePickerWeek } from 'components/DatePicker/Week/DatePickerWeek'
import InputBase from 'components/Input/InputBase'
import { RadioGroup } from 'components/Radio/RadioGroup'

export type PeriodType = 'day' | 'week' | 'month' | 'year'

const views = {
  day: ['day'],
  week: ['day'],
  month: ['month'],
  year: ['year'],
}

const inputFormat = {
  day: 'DD/MM/YYYY',
  week: 'DD/MM/YYYY',
  month: 'MMMM',
  year: 'YYYY',
}

interface Props {
  date: Date | null
  periods: Array<PeriodType>
  defaultPeriod?: PeriodType
  onChangeDate: (date: Date | null) => void
  onChangePeriod?: (type: PeriodType) => void
  customViews?: CalendarPickerView[]
}

export function DatePeriod(props: Props) {
  const {
    date,
    periods,
    defaultPeriod = 'week',
    onChangeDate,
    onChangePeriod,
    customViews,
  } = props
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation('common')
  const [value, setValue] = useState(defaultPeriod)

  const handleChange = (newValue: Date | null) => {
    onChangeDate(newValue)
  }

  return (
    <Stack
      direction={['column', 'row']}
      justifyContent={['flex-start', 'flex-end']}
      alignItems={'center'}
      width={'100%'}
    >
      <Stack
        direction={'row'}
        spacing={1}
        alignItems='center'
        justifyContent={'flex-end'}
      >
        <RadioGroup
          row
          name='radioGroup'
          value={value}
          onChange={(event) => {
            onChangePeriod?.(
              event.target.value as 'day' | 'week' | 'month' | 'year',
            )
            setValue(event.target.value as 'day' | 'week' | 'month' | 'year')
          }}
          options={periods.map((item) => ({
            id: item,
            name: t(`input.${item}`) ?? '',
          }))}
          sx={{
            justifyContent: ['flex-start', 'flex-end'],
          }}
        />
      </Stack>

      <Box marginTop={[2, 0]} marginLeft={[0, 2]} width={['100%', 'auto']}>
        {(value?.includes('month') ||
          value.includes('year') ||
          value.includes('day')) &&
          value && (
            <>
              {isDesktop ? (
                <DesktopDatePicker
                  label={t('input.period')}
                  views={customViews || (views[value] as CalendarPickerView[])}
                  disableMaskedInput
                  inputFormat={inputFormat[value]}
                  value={date}
                  openTo={value !== 'week' ? value : 'day'}
                  onChange={handleChange}
                  renderInput={(params) => <InputBase fullWidth {...params} />}
                />
              ) : (
                <MobileDatePicker
                  label={t('input.period')}
                  views={customViews || (views[value] as CalendarPickerView[])}
                  disableMaskedInput
                  inputFormat={inputFormat[value]}
                  value={date}
                  openTo={value !== 'week' ? value : 'day'}
                  onChange={handleChange}
                  renderInput={(params) => <InputBase fullWidth {...params} />}
                />
              )}
            </>
          )}

        {value?.includes('week') && (
          <DatePickerWeek
            defaultDate={date ?? undefined}
            onChange={(value) => handleChange(value ? new Date(value) : null)}
          />
        )}
      </Box>
    </Stack>
  )
}
