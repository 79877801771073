import dayjs from 'dayjs'

import { PeriodType } from 'components/DatePeriod'
import { httpClient } from 'services/api/httpClient'

const getAllGasAirQuality = async (
  startDate?: Date | string,
  endDate?: Date | string,
  filter?: PeriodType,
  areaId?: string,
): Promise<any> => {
  // if yer null or undefined, set year to current year
  if (areaId === undefined) {
    return []
  }
  if (filter === 'year') {
    const year = startDate ? dayjs(startDate).year() : ''
    const response = await httpClient.get(
      `/dashboard-gas/air-quality/by-year/${year}/${areaId}`,
    )
    return response.data
  }
  if (filter === 'month') {
    const date = endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
    const response = await httpClient.get(
      `/dashboard-gas/air-quality/by-month/${date}/${areaId}`,
    )
    return response.data
  }
  if (filter === 'week') {
    const response = await httpClient.get(
      `/dashboard-gas/air-quality/by-week/${startDate}/${endDate}/${areaId}`,
    )
    return response.data
  }
  return []
}

const getAllGasConsumption = async (
  startDate?: Date | string,
  endDate?: Date | string,
  filter?: PeriodType,
  areaId?: string,
): Promise<any> => {
  // if yer null or undefined, set year to current year
  if (areaId === undefined) {
    return []
  }
  if (filter === 'year') {
    const year = startDate ? dayjs(startDate).year() : ''
    const response = await httpClient.get(
      `/dashboard-gas/cooking-gas-consumption/by-year/${year}/${areaId}`,
    )
    return response.data
  }
  if (filter === 'month') {
    const date = endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
    const response = await httpClient.get(
      `/dashboard-gas/cooking-gas-consumption/by-month/${date}/${areaId}`,
    )
    return response.data
  }
  if (filter === 'week') {
    const response = await httpClient.get(
      `/dashboard-gas/cooking-gas-consumption/by-week/${startDate}/${endDate}/${areaId}`,
    )
    return response.data
  }
  return []
}

const getAllByRealTime = async (areaId: string): Promise<any[]> => {
  const response = await httpClient.get(
    `/dashboard-gas/real-time-by-area/${areaId}`,
  )
  return response.data
}

export { getAllByRealTime, getAllGasAirQuality, getAllGasConsumption }
