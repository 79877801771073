/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, DialogActions, Grid } from '@mui/material'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { httpClient } from 'services/api/httpClient'
import { featureService } from 'services/feature/feature.service'
import { notificationSettingService } from 'services/notification/notification-setting.service'
import { sensorService } from 'services/sensor.service'
import { ID } from 'types/id'
import { ModalType } from 'types/modal'
import {
  CreateNotificationSettingType,
  NotificationSettingFormType,
} from 'types/notification-setting'
import { UsersType } from 'types/user/user'

import { findModule } from '../../../core/enums/Module'
import {
  schemaCreateNotification,
  schemaCreateNotificationUser,
} from '../validations/create-notification.validation'
import { FormNotification } from './FormNotification'

interface Props extends ModalType {
  data: any | null
  isView?: boolean
}

const formSchema = [schemaCreateNotification, schemaCreateNotificationUser]

export function FormNotificationModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const [activeStep, setActiveStep] = useState(0)
  const [activeStepView, setActiveStepView] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [sensorsSelected, setSensorsSelected] = useState([])

  // functions for stepper
  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStepView)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStepView)
    }

    setActiveStepView((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStepView((prevActiveStep) => prevActiveStep - 1)
  }

  const { data: sensors } = useQuery({
    queryKey: ['sensors'],
    queryFn: () => sensorService.listAllOpeningType(),
  })

  const queries = useQueries([
    {
      queryKey: 'list-users',
      queryFn: () => {
        return httpClient.get<UsersType[]>(
          '/user-account/find-all-except-clients',
        )
      },
    },
    {
      queryKey: 'list-modules',
      queryFn: () => {
        return featureService.getAllFeature()
      },
    },
  ])

  const { data: types } = useQuery({
    queryKey: 'list--types',
    queryFn: () => {
      return httpClient.get<any[]>('/notification-setting/find-types')
    },
    select: (data) => {
      return data?.data?.map((item) => ({
        id: item?.id?.value,
        name: t(item?.name?.toLowerCase() ?? ''),
      }))
    },
  })

  const { data: actions } = useQuery({
    queryKey: 'list-actions',
    queryFn: () => {
      return httpClient.get<any[]>('/notification-setting/find-actions')
    },
    select: (data) => {
      return data?.data?.map((item) => ({
        id: item?.id?.value,
        name: t(item?.name?.toLowerCase() ?? ''),
      }))
    },
  })

  const { data: modulesData } = queries[1]
  const { data: userData } = queries[0]

  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<NotificationSettingFormType>({
    resolver: yupResolver(formSchema[activeStepView]()),
    defaultValues: {
      name: data?.name ?? undefined,
      status: data !== null ? data?.status : true,
      typeNotification: data?.typesNotification?.map(
        (notification: ID) => notification.value,
      ) ?? ['3'],
      message: data?.message ?? '',
      modules:
        data?.modules.map((item: any) => ({
          value: item,
          label: findModule(item),
        })) ?? [],
      users:
        data?.users.map((user: any) => ({
          value: user?.entityId?.value ?? user?.id,
          label: user?.name,
        })) ?? [],
      notificationAction:
        data?.notificationAction.map((action: ID) => action.value) ?? [],
      sensors:
        data?.sensors.map((sensor: any) => {
          return {
            value: sensor.sensor.id.value,
            label: sensor.sensor.name,
          }
        }) ?? [],
    },
  })

  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('notifications')
    onClose()
  }

  const arrangeValues = (data: NotificationSettingFormType) => {
    const result: CreateNotificationSettingType = {
      name: data.name,
      message: data.message,
      notificationTypesID: data.typeNotification,
      usersID: data.users.map((item) => {
        if (typeof item.value === 'string') {
          return item.value
        }
        return item.value?.value
      }),
      modules: data.modules.map((item) => item.value),
      notificationActionsID: data.notificationAction,
      sensors:
        data.sensors?.map((sensor) => ({
          sensor: sensor.value,
          waterInlet: sensor.waterInlet ?? false,
          waterOutlet: sensor.waterOutlet ?? false,
          floor: sensor.floor ?? '',
        })) ?? [],
    }

    return result
  }

  const mutationCreate = useMutation(
    (formData: NotificationSettingFormType) => {
      return notificationSettingService.create(arrangeValues(formData))
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (formData: NotificationSettingFormType) => {
      return notificationSettingService.update(
        Number(data?.id.value) ?? 0,
        arrangeValues(formData),
      )
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<NotificationSettingFormType> = (formData) => {
    if (!data?.id) {
      mutationCreate.mutate(formData)
      return
    }

    mutationUpdate.mutate(formData)
  }

  const getTitle = () => {
    if (isView) return t('notifications.view')

    if (data?.id) return t('notifications.edit')

    return t('notifications.new')
  }

  const title = getTitle()

  return (
    <>
      <Modal
        title={title}
        open={open}
        onClose={onClose}
        goBack={activeStep !== 0 ? () => setActiveStep(0) : undefined}
      >
        <Grid container spacing={2} component='form' id='notification'>
          <FormNotification
            activeStep={activeStepView}
            setActiveStep={setActiveStepView}
            isView={isView}
            actions={actions}
            modulesData={modulesData}
            types={types}
            userData={userData}
            errors={errors}
            control={control}
            clearErrors={clearErrors}
            setValue={setValue}
            watch={watch}
            sensorSelected={sensorsSelected}
            setSensorsSelected={setSensorsSelected}
            sensors={sensors}
          />

          {!isView ? (
            <Grid item xs={12}>
              <DialogActions
                sx={{
                  '&>:not(:first-of-type)': { marginLeft: 2 },
                  justifyContent: ['space-between', 'flex-end'],
                }}
              >
                {activeStepView === 0 ? (
                  <>
                    <Button
                      variant='outlined'
                      type={'button'}
                      onClick={() => {
                        setActiveStep(0)
                        reset()
                      }}
                    >
                      {t('button.title.back')}
                    </Button>
                    <Button
                      variant='contained'
                      form='notification'
                      type='submit'
                      disabled={
                        mutationCreate.isLoading || mutationUpdate.isLoading
                      }
                      onClick={handleSubmit(handleNext)}
                    >
                      {mutationCreate.isLoading || mutationUpdate.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('button.title.save')
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant='outlined'
                      type={'button'}
                      onClick={() => {
                        handleBack()
                      }}
                    >
                      {t('button.title.back')}
                    </Button>
                    <Button
                      variant='contained'
                      form='notification'
                      type={'submit'}
                      disabled={
                        mutationCreate.isLoading || mutationUpdate.isLoading
                      }
                      onClick={handleSubmit(onSubmit)}
                    >
                      {mutationCreate.isLoading || mutationUpdate.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('button.title.save')
                      )}
                    </Button>
                  </>
                )}
              </DialogActions>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <DialogActions
                sx={{
                  '&>:not(:first-of-type)': { marginLeft: 2 },
                  justifyContent: ['space-between', 'flex-end'],
                }}
              >
                {activeStepView > 0 && (
                  <Button
                    variant='outlined'
                    type={'button'}
                    onClick={handleBack}
                  >
                    {t('button.title.back')}
                  </Button>
                )}
                {activeStepView < 1 && (
                  <Button
                    variant='outlined'
                    type={'button'}
                    form='notification'
                    onClick={handleNext}
                  >
                    {t('button.title.next')}
                  </Button>
                )}
              </DialogActions>
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
