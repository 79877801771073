import { Grid, Stack, Typography } from '@mui/material'

import { IconButton } from 'components/Button/IconButton'
import { useMapStore } from 'store/map.store'

interface IProps {
  children: React.ReactNode
}
export const SidebarMapMonitoring = ({ children }: IProps) => {
  const { sidebarVisibility, toggleSidebarVisibility } = useMapStore()
  const enableSidebar = sidebarVisibility
  const iconBottom = sidebarVisibility ? 'chevronLeft' : 'chevronRight'

  return (
    <>
      <Stack
        direction='column'
        sx={{
          width: '100%',
          minWidth: enableSidebar ? 280 : 30,
          maxWidth: enableSidebar ? 280 : 30,
          height: '100%',
          zIndex: 1,
          background: (theme) => theme.palette.background.default,
          borderRight: '2px solid',
          borderColor: (theme) => theme.palette.secondary.light,
          p: 2,
          position: 'relative',
          transition: 'max-width 0.3s',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            display: enableSidebar ? 'initial' : 'none',
          }}
        >
          {children}
        </Stack>
        <IconButton
          size='small'
          variant='contained'
          iconName={iconBottom}
          sx={{
            width: 24,
            position: 'absolute',
            right: 0,
            bottom: 60,
            transform: 'translateX(50%)',
            zIndex: 1200,
          }}
          onClick={toggleSidebarVisibility}
        />
      </Stack>
    </>
  )
}
