import dayjs from 'dayjs'

export function getWeekDays(date: Date | null, format?: string) {
  const value = dayjs(date)

  const start = value.startOf('week')
  const end = value.endOf('week')

  const dates = []
  dates.push(start)
  Array(6)
    .fill(null)
    .forEach((_, index) => {
      const newDate = start.add(index + 1, 'day')

      if (
        newDate.isBetween(start, end, null, '[]') ||
        newDate.isSame(end, 'day')
      ) {
        dates.push(newDate)
      }
    })

  if (format) {
    return dates.map((item) => item.format(format))
  }

  return dates
}
