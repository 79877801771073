import { ArrowRight } from '@mui/icons-material'
import { Stack } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

import { IconButton } from 'components/Button/IconButton'

import { PermissionType } from '../types/permission.type'
import { CustomCheckbox } from './CustomCheckbox'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  backgroundColor: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 0,
  },
}))
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(6),
  paddingTop: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

interface Props {
  children: React.ReactNode
  permission: PermissionType
  onCheckboxChange: (checkboxId: string, isChecked: boolean) => void
  disabled?: boolean
}

export function PermissionGroup({
  children,
  permission,
  onCheckboxChange,
  disabled,
}: Props) {
  const [expanded, setExpanded] = useState(permission.checked)

  const handleChange = (newExpanded: boolean) => {
    setExpanded(newExpanded)
  }

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        aria-controls={`panel${permission.id}-content`}
        id={`panel${permission.id}-header`}
      >
        <Stack alignItems={'center'} justifyContent={'center'} mr={1}>
          <IconButton
            variant='text'
            size='small'
            color='inherit'
            icon={<ArrowRight />}
            sx={{
              transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
            onClick={() => handleChange(!expanded)}
          />
        </Stack>
        <CustomCheckbox
          item={permission}
          onCheckboxChange={onCheckboxChange}
          disabled={disabled}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Stack>{children}</Stack>
      </AccordionDetails>
    </Accordion>
  )
}
