import styled from '@emotion/styled'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Stack, Typography } from '@mui/material'
import { FC, ReactNode, useState } from 'react'

type Props = {
  title: string
  children: ReactNode
  isAccordion?: boolean
  innerApendRight?: ReactNode
}

const AccordionWrapper = styled.div<{ open?: boolean }>(
  {
    transition: 'max-height 0.1s',
  },
  ({ open }) => ({
    maxHeight: open ? 800 : 0,
    overflow: open ? 'initial' : 'hidden',
  }),
)

export const MonitoringSidebarCard: FC<Props> = ({
  title,
  children,
  isAccordion,
  innerApendRight,
}) => {
  const [open, setOpen] = useState(true)

  const AccordionIconControl = open
    ? KeyboardArrowDownIcon
    : KeyboardArrowUpIcon

  return (
    <Stack
      borderRadius={'6px'}
      padding={'10px'}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        marginBottom={'10px'}
      >
        <Typography fontSize={16} fontWeight={700}>
          {title}
        </Typography>
        {isAccordion ? (
          <AccordionIconControl
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
        ) : (
          innerApendRight
        )}
      </Stack>
      <AccordionWrapper open={open}>{children}</AccordionWrapper>
    </Stack>
  )
}
