/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { Modal } from 'components/Modal'
import { ModalAction } from 'components/Modal/ModalAction'
import { Select } from 'components/Select'
import { Autocomplete } from 'components/Select/Autocomplete'
import { getLevel } from 'services/level/getLevel'
import { scalabilityService } from 'services/scalability.service'
import { userService } from 'services/user.service'
import { LevelType } from 'types/level/level'
import { ModalType } from 'types/modal'
import { CreateScalabilityType } from 'types/scalability/create-scalability'
import { ScalabilityType } from 'types/scalability/scalability'

import { schemaCreateScalability } from '../validations/create-scalability.validation'

interface Props extends ModalType {
  data: ScalabilityType | null
  isView?: boolean
}

export function FormScalabilityModal({ data, isView, onClose, open }: Props) {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { data: levels } = useQuery({
    queryKey: 'all-level',
    queryFn: () => getLevel(),
  })
  const { data: listUsers } = useQuery({
    queryKey: 'all-users',
    queryFn: () => userService.list(),
  })

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schemaCreateScalability),
    defaultValues: {
      level: data?.level?.id?.value
        ? String(data?.level?.id?.value)
        : undefined,
      users:
        data?.scalabilities.map((scalability) => {
          return {
            value: scalability.user.id.value,
            label: String(scalability.user.name),
          }
        }) ?? [],
    },
  })
  const onSetSuccessfully = () => {
    toast.success(t('savedInformation'))
    reset()
    queryClient.invalidateQueries('scalability')
    onClose()
  }
  const arrangeData = (data: any) => {
    const result: CreateScalabilityType = {
      levelId: data?.level,
      userIds: data?.users.map((user: any) => user.value),
    }
    return result
  }

  const mutationCreate = useMutation(
    (create: CreateScalabilityType) => {
      return scalabilityService.create(create)
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const mutationUpdate = useMutation(
    (update: any) => {
      const updateData = (data: any) => {
        const result: any = {
          users: data?.users.map((user: any) => user.value),
        }
        return result
      }
      return scalabilityService.updateByLevel(
        Number(data?.level?.id.value),
        updateData(update),
      )
    },
    {
      onSuccess: onSetSuccessfully,
      onError(error: Error) {
        toast.error(error?.message)
      },
    },
  )

  const onSubmit: SubmitHandler<any> = (formData) => {
    if (!data?.level.id.value) {
      mutationCreate.mutate(arrangeData(formData))
      return
    }
    mutationUpdate.mutate(formData)
  }
  const getTitle = () => {
    if (isView) return t('scalability.view')

    if (data?.level.id.value) return t('scalability.edit')

    return t('scalability.new')
  }
  const title = getTitle()
  return (
    <>
      <Modal title={title} open={open} onClose={onClose}>
        <Grid
          container
          spacing={3}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item xs={12}>
            <Select
              name='level'
              label={t('input.selectLevel') ?? ''}
              options={
                levels?.results.map((level: LevelType) => ({
                  id: level?.entityId?.value,
                  name: level?.name,
                })) ?? []
              }
              control={control}
              fullWidth
              error={!!errors?.level}
              errorMessage={errors?.level?.message}
              required
              disabled={!!data?.level.id.value}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body2' mb={1}>
              {t('input.selectUsers')}
            </Typography>
            <Autocomplete
              name='users'
              placeholder={t('input.select')}
              options={
                listUsers?.map((user) => ({
                  label: user.name,
                  value: String(user.id),
                })) ?? []
              }
              isMulti
              onChange={(value) => {
                setValue('users', value)
              }}
              value={watch('users')}
              required
            />
          </Grid>

          {!isView && (
            <Grid item xs={12}>
              <ModalAction
                onCancel={onClose}
                isSubmit
                isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
    </>
  )
}
