import { HeaderAction } from 'components/HeaderAction'
import { Table } from 'components/Table'
import { ModuleEnum, PermissionEnum } from 'constants/modules'
import { ProfileType } from 'types/profile/profile'

import { useProfilesPage } from './hooks/useProfilesPage'

export function ProfilesPage() {
  const {
    setSearch,
    columns,
    page,
    setPage,
    orderBy,
    setOrderBy,
    data,
    profiles,
    error,
    isLoading,
    isFetching,
    handleCreate,
    tableActions,
  } = useProfilesPage()

  return (
    <>
      <HeaderAction
        onCreate={handleCreate}
        // onSearch={(search) => setSearch(search)}
        module={ModuleEnum.ACC_PROFILE}
        permission={PermissionEnum.CREATE}
      />

      <Table<ProfileType>
        columns={columns}
        rows={profiles}
        orderBy={orderBy}
        onOrderBy={(newOrderBy) => {
          setOrderBy(newOrderBy)
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={tableActions}
        error={error}
        pagination={{
          page,
          limit: data?.limit,
          count: data?.count,
          onChangePage: setPage,
        }}
      />
    </>
  )
}
