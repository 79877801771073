export enum AlertTypeEquipment {
  PANIC = 'PANIC',
  EMERGENCY = 'EMERGENCY',
  WARNING = 'WARNING',
}

export const getAllAlertTypeEquipment = () => {
  return [
    AlertTypeEquipment.PANIC,
    AlertTypeEquipment.EMERGENCY,
    AlertTypeEquipment.WARNING,
  ]
}

export const findNameAlertTypeEquipment = (type: string) => {
  return getAllAlertTypeEquipment().find((alert) => alert === type)
}
