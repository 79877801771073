import { IconButton } from 'components/Button/IconButton'

export function QuantidadeProduzidaContent() {
  return (
    <>
      <p>
        Nesse submódulo, são realizados os cadastros das quantidades produzidas
        da fábrica. Os dados desse submódulo serão utilizados no Dashboard,
        então sugerimos que sempre verifique mensalmente se os cadastros das
        quantidades produzidas já foram realizados.
      </p>
      <p>
        Caso esse submódulo não apareça no seu sistema atualmente, provavelmente
        o seu tipo de perfil não permite o acesso. Caso necessite o uso desse
        submódulo, solicite ao administrador do sistema.
      </p>
      <p>
        <strong>o Cadastrar de Quantidade Produzida</strong>
      </p>

      <p>Para realizar o cadastro de uma nova quantidade produzida, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Quantidade Produzida”
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “+ Novo” .
      </p>
      <p>
        <strong>Passo 6:</strong> Preencha os campos: quantidade produzida,
        status de ativo ou inativo, ano e mês que foi produzido.
      </p>
      <p>
        <strong>Passo 7:</strong> Selecione Salvar.{' '}
      </p>
      <p>Importante: Esses dados afetam nos valores do dashboard. </p>
      <p>
        <strong>o Editar Quantidade Produzida</strong>
      </p>
      <p>Para realizar a edição de uma quantidade produzida, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Clicar no botão “Quantidade Produzida”
      </p>
      <p>
        <strong>Passo 5:</strong> Selecionar a quantidade produzida que vai
        editar dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 6:</strong> Clicar no botão “Editar”{' '}
        <IconButton iconName='edit' variant='text' color='inherit' />.
      </p>
      <p>
        <strong>Passo 7:</strong> Edite os campos: quantidade produzida, status
        de ativo ou inativo, ano e mês que foi produzido.
      </p>
      <p>
        <strong>Passo 8:</strong> Selecione Salvar.{' '}
      </p>
      <p>
        <strong>o Excluir Quantidade Produzida</strong>
      </p>

      <p>Para realizar a exclusão de uma quantidade produzida, basta:</p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a quantidade produzida que vai
        excluir dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Excluir”{' '}
        <IconButton iconName='delete' variant='text' color='inherit' />.
      </p>
      <p>
        Uma Mensagem aparecerá na tela perguntando se realmente deseja
        excluí-la, selecione sim e a quantidade produzida será permanentemente
        excluída do sistema.
      </p>
      <p>
        <strong>o Visualizar Quantidade Produzida</strong>
      </p>
      <p>
        Para realizar visualizar os dados de uma quantidade produzida, basta:
      </p>
      <p>
        <strong>Passo 1:</strong> Acesse o Menu lateral
      </p>
      <p>
        <strong>Passo 2:</strong> Selecione o módulo de Configurações
      </p>
      <p>
        <strong>Passo 3:</strong> Selecione o submódulo de Metas e Parâmetros
      </p>
      <p>
        <strong>Passo 4:</strong> Selecionar a quantidade produzida que vai
        visualizar dentro da listagem e selecionar o botão de ações{' '}
        <IconButton iconName='moreVert' variant='text' color='inherit' />
      </p>
      <p>
        <strong>Passo 5:</strong> Clicar no botão “Visualizar”{' '}
        <IconButton iconName='show' variant='text' color='inherit' />.
      </p>
      <p>
        Os dados que aparecerão serão os dados que foram preenchidos no momento
        do cadastro, sendo eles: quantidade produzida, status de ativo ou
        inativo, ano e mês que foi produzido.
      </p>
    </>
  )
}
