import { Stack, SxProps } from '@mui/material'

export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  currentTab: number
  sx?: SxProps
}

export function TabPanel(props: TabPanelProps) {
  const { children, currentTab, index, sx, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={currentTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {currentTab === index && <Stack sx={{ pt: 2, ...sx }}>{children}</Stack>}
    </div>
  )
}
