import styled from '@emotion/styled'

export const TemperatureHumidityLabel = styled.div<{
  bgColor: string
  borderColor: string
}>(
  {
    padding: '10px',
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    cursor: 'pointer',
    borderRadius: '6px',
    transition: '0.3s',
  },
  ({ borderColor, bgColor }) => ({
    backgroundColor: bgColor,
    '&:hover': {
      boxShadow: `0 0 0 2px ${borderColor}`,
    },
  }),
)
