/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery, useTheme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { DashboardTooltipSuffixEnum } from 'core/enums/DashboardTooltipSuffixEnum'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'

import { PeriodType } from 'components/DatePeriod'
import { colors } from 'helpers/colors'
import { createGradient } from 'helpers/createGradient'
import { formatNumbersDashboard } from 'helpers/formatNumbersDashboard'
import { getMonthDays } from 'helpers/getMonthDays'
import { getWeekDays } from 'helpers/getWeekDays'
import { getAllAreasBySensorType } from 'services/area/getAllAreas'
import { getAllEnergyConsumption } from 'services/dashboard/energyDashboard.service'

import { SensorTypeEnum } from '../../../../core/enums/SensorTypeEnum'

interface Props {
  isPreview?: boolean
}

export function useConsumptionChart(props: Props) {
  const { isPreview } = props
  const { t, i18n } = useTranslation('common')
  const queryClient = useQueryClient()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  })
  const [date, setDate] = useState<Date | null>(new Date())
  const [selectedAreas, setSelectedAreas] = useState<any[]>([])
  const [areaId, setSelectArea] = useState<string | Array<string>>()

  const [period, setPeriod] = useState<PeriodType>('year')
  const [generatingFile, setGeneratingFile] = useState(false)

  // Obter o primeiro dia do ano
  const startDate = dayjs(date).startOf(period).format('YYYY-MM-DD')
  // Obter o último dia do ano
  const endDate = dayjs(date).endOf(period).format('YYYY-MM-DD')

  const { data: energyConsumption } = useQuery({
    queryKey: ['energyConsumption', period, startDate, endDate, areaId],
    queryFn: async () => {
      return await getAllEnergyConsumption(
        startDate,
        endDate,
        period,
        areaId ?? undefined,
      )
    },
  })

  const { data: areas } = useQuery({
    queryKey: 'all-areas-energy',
    queryFn: async () => {
      return await getAllAreasBySensorType(SensorTypeEnum.ELECTRIC)
    },
    select: (data) => {
      return data?.map((item) => ({
        label: item.name,
        value: String(item.id.value),
      }))
    },
  })

  useEffect(() => {
    if (selectedAreas.length > 0) {
      const areaIds = selectedAreas.map((area) => area?.value)
      setSelectArea(areaIds)
      queryClient.invalidateQueries('energyConsumption')
    }
  }, [selectedAreas])

  useEffect(() => {
    if (areas?.length) {
      setSelectedAreas([areas[0]])
    }
  }, [areas])

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: isDesktop && !isPreview,
        position: 'bottom' as const,
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          font: {
            size: 14,
          },
          borderRadius: 12,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return formatNumbersDashboard({
              context,
              suffix: DashboardTooltipSuffixEnum.KILOWATT_HOUR,
            })
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          display: !isPreview,
          // Include a dollar sign in the ticks
          callback: function (value) {
            return value + ' kWh'
          },
        },
      },
      x: {
        ticks: {
          display: !isPreview,
        },
      },
    },
  }
  const getDataDashboard = (id: string) => {
    if (period === 'year') {
      const months = Array(12).fill(0)
      energyConsumption
        ?.find((item) => item.areaId === Number(id))
        ?.values?.map((item) => {
          months[Number(item.month) - 1] = item?.value
        })
      return months
    }
    if (period === 'month') {
      const days = Array(getMonthDays(date).length).fill(0)
      energyConsumption
        ?.find((item) => item.areaId === Number(id))
        ?.values?.map((item) => {
          days[Number(item.day) - 1] = item?.value
        })
      return days
    }
    if (period === 'week') {
      const days = getWeekDays(date, 'DD')
      const weekDays = Array(days.length).fill(0)
      energyConsumption
        ?.find((item) => item.areaId === Number(id))
        ?.values?.map((item) => {
          if (item.day) {
            const index = days.findIndex((day) => day === item.day)
            weekDays[index] = item?.value
          }
        })
      return weekDays
    }
  }

  const getLabels = useCallback(() => {
    if (period === 'year') {
      return [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december'),
      ]
    }

    if (period === 'month') {
      return getMonthDays(date, 'DD')
    }

    return getWeekDays(date, 'DD/MMM')
  }, [period, date])

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const labels = getLabels()
    const chartData = {
      labels,
      datasets: selectedAreas?.map((area, index) => ({
        type: 'bar' as const,
        label: area?.label,
        data: getDataDashboard(area?.value),
        borderRadius: 20,
        backgroundColor: createGradient(
          chart.ctx,
          chart.chartArea,
          colors.energy[index > colors.energy.length ? 0 : index],
        ),
      })),
    }

    setChartData(chartData)
  }, [i18n.language, date, energyConsumption, selectedAreas])

  return {
    isDesktop,
    date,
    options,
    setDate,
    chartData,
    chartRef,
    t,
    selectedAreas,
    setSelectedAreas,
    areas,
    period,
    setPeriod,
    generatingFile,
    setGeneratingFile,
    startDate,
    endDate,
    areaId,
  }
}
