import { Grid, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/Input'
import { AreaType } from 'types/area/area'
import { CreateParameterType } from 'types/goalAndParameter/create-parameter'

import { CompanyAreaSubAreaForm } from '../CompanyAreaSubAreaFom'

type Props = {
  control: Control<CreateParameterType>
  errors: FieldErrors
  areas: AreaType[]
  isView?: boolean
  watch: UseFormWatch<CreateParameterType>
}

export const TemperatureForm: FC<Props> = (props) => {
  const { t } = useTranslation('common')

  return (
    <>
      <Grid item xs={12} sm={12}>
        <CompanyAreaSubAreaForm
          control={props.control}
          errors={props.errors}
          watch={props.watch}
          isView={props.isView}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction={'row'} alignItems='center' spacing={2}>
          <Typography whiteSpace='nowrap'>{t('input.minimum')}</Typography>
          <Input
            name='temperatureMin'
            label={'°C'}
            type='number'
            control={props.control}
            fullWidth
            error={!!props.errors?.temperatureMin}
            errorMessage={props.errors?.temperatureMin?.message}
            required
            isDisabled={props.isView}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction={'row'} alignItems='center' spacing={2}>
          <Typography whiteSpace='nowrap'>{t('input.maximum')}</Typography>
          <Input
            name='temperatureMax'
            label={'°C'}
            type='number'
            control={props.control}
            fullWidth
            error={!!props.errors?.temperatureMax}
            errorMessage={props.errors?.temperatureMax?.message}
            required
            isDisabled={props.isView}
          />
        </Stack>
      </Grid>
    </>
  )
}
