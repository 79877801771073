import { httpClient } from 'services/api/httpClient'
import {
  PaginationParamType,
  PaginationResponseType,
  PaginationType,
} from 'types/pagination'
import { ProceduresType } from 'types/procedures/procedures'

type NewType = PaginationType<ProceduresType>

export const listProcedures = async ({
  page = 1,
  search = '',
  columnSort,
  columnName,
  numberPerPage = 10,
}: PaginationParamType): Promise<NewType> => {
  const { data } = search?.length
    ? await httpClient.get<PaginationResponseType>('/procedures/findcustom', {
        params: {
          page: page - 1,
          columnSort,
          columnName,
          numberPerPage,
          name: search,
        },
      })
    : await httpClient.get<PaginationResponseType>('/procedures', {
        params: {
          page: page - 1,
          columnSort,
          columnName,
          numberPerPage,
        },
      })

  return {
    items: data?.items ?? [],
    currentPage: data?.currentPage,
    perPage: data?.perPage,
    total: data?.total,
  }
}

export const listProceduresSelect = async () => {
  const { data } = await httpClient.get<PaginationResponseType>('/procedures')

  return {
    items: data?.items ?? [],
    currentPage: data?.currentPage,
    perPage: data?.perPage,
    total: data?.total,
  }
}
