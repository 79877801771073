import { Stack, Typography } from '@mui/material'

import { IconButton } from 'components/Button/IconButton'
import { IconNamesProps } from 'components/Icon'
import { formatRelativeTime } from 'helpers/formatRelativeTime'

interface Props {
  icon?: IconNamesProps | undefined
  message: string
  module: string
  createdAt: string | Date
  onClick?: () => void
}

export function NotificationItem({
  icon,
  message,
  module,
  createdAt,
  onClick,
}: Props) {
  const formattedDate = formatRelativeTime(createdAt)
  return (
    <Stack
      sx={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        '&:hover': {
          backgroundColor: 'background.paper',
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      {icon && <IconButton variant='contained' color='error' iconName={icon} />}

      <Stack marginLeft={2}>
        <Typography variant='body2'>{message}</Typography>
        <Typography variant='subtitle2' color='primary'>
          {module} - {formattedDate}
        </Typography>
      </Stack>
    </Stack>
  )
}
